import React from "react";
import { Grid2 as Grid, Stack, Typography } from "@mui/material";
import PanelExAutocomplete from "../../shared/PanelExAutocomplete";
import ValfirmAdditionalStatusIcon from "../../shared/ValfirmAdditionalStatusIcon";
import { escapedSpecialCharacters } from "../../../utilities/stringUtil";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import {
  replaceValfirmUpdateExistingValfirmTextChange,
  replaceValfirmSetExistingValfirm,
  replaceValfirmUpdateNewValfirmTextChange,
  replaceValfirmSetNewValfirm,
} from "../../../store/action/actionPanel";

export default function RemoveValfirm() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    card: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    stack: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    formGroup: {
      fontSize: "18px",
      fontWeight: "500",
      paddingTop: "12px",
    },
    formRow: {
      paddingTop: "22px",
      "& .MuiTypography-root": {
        paddingBottom: "12px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
    allocationText: {
      width: "100%",
      marginBottom: "10px",

      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
        "& .MuiTypography-root": {
          padding: 0,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #00000061",
        borderRadius: 4,
      },
    },
    notTrading: {
      color: theme.palette.text.disabled,
    },
    atCapacity: {
      color: theme.colors.error.darker,
    },
  }));
  const { classes } = useStyles();

  const { replaceValfirm } = useSelector((state: RootState) => {
    return {
      replaceValfirm: state.panel.bulkUpdate.changeTypeReplaceValfirm,
    };
  });

  const handleExistingValfirmInputChange = (input: string) => {
    if (input !== replaceValfirm.existingValfirmText) {
      dispatch(replaceValfirmUpdateExistingValfirmTextChange(input));
    }
  };

  const handleUpdateExistingValfirm = (orgId: number, orgName: string) => {
    dispatch(replaceValfirmSetExistingValfirm(orgId, orgName));
  };

  const handleNewValfirmInputChange = (input: string) => {
    if (input !== replaceValfirm.newValfirmText) {
      dispatch(
        replaceValfirmUpdateNewValfirmTextChange(
          input,
          replaceValfirm.existingValfirmId
        )
      );
    }
  };

  const handleUpdateNewValfirm = (orgId: number, orgName: string) => {
    dispatch(replaceValfirmSetNewValfirm(orgId, orgName));
  };

  const getStatusStyle = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return classes.notTrading;
      case "ATCAPACITY":
        return classes.atCapacity;
    }
  };

  return (
    <Grid
      size={12}
      id="replace-valfirm-form"
      data-testid="replace-valfirm-form"
      className={classes.card}
    >
      <Typography
        id="replace-valfirm-form-title"
        data-testid="replace-valfirm-form-title"
        variant="h4"
      >
        Replace Valuation Firm
      </Typography>

      <Grid container size={12} className={classes.formGroup}>
        <Stack spacing={1} direction="row" className={classes.stack}>
          <Grid size={6} className={classes.formRow}>
            <Typography
              id="replace-existing-valfirm-label"
              data-testid="replace-existing-valfirm-label"
              variant="h4"
            >
              Existing Valuation Firm
            </Typography>
            <PanelExAutocomplete
              id="replace-existing-valfirm"
              data-testid="replace-existing-valfirm"
              style={{ width: "100%" }}
              options={replaceValfirm.existingValfirmSuggestion}
              value={replaceValfirm.existingValfirmText}
              popperMaxHeight={400}
              getOptionLabel={(option: any) =>
                option.name || replaceValfirm.existingValfirmText || ""
              }
              placeholder="Enter Valuation Firm"
              onInputChange={(value: any) => {
                handleExistingValfirmInputChange(value);
              }}
              renderOption={(props: any, option: any) => {
                const { id, name, additionalStatus } = option;
                const regex = new RegExp(
                  escapedSpecialCharacters(replaceValfirm.existingValfirmText),
                  "gi"
                );
                const response = name.replace(regex, function (str: any) {
                  return "<span style='font-weight:500'>" + str + "</span>";
                });
                return (
                  <li
                    {...props}
                    data-testid={`replace-existing-valfirm-label-${id}`}
                    id={`replace-existing-valfirm-label-${id}`}
                    onClick={(ev) => {
                      handleUpdateExistingValfirm(id, name);
                      ev.stopPropagation();
                    }}
                  >
                    <ValfirmAdditionalStatusIcon
                      test-dataid={`additional-status-icon-${id}`}
                      additionalStatus={additionalStatus}
                    />
                    <div
                      className={getStatusStyle(additionalStatus)}
                      dangerouslySetInnerHTML={{ __html: `${response}` }}
                    ></div>
                  </li>
                );
              }}
            />
          </Grid>

          <Grid size={6} className={classes.formRow}>
            <Typography
              id="replace-new-valfirm-label"
              data-testid="replace-new-valfirm-label"
              variant="h4"
            >
              With New Valuation Firm
            </Typography>
            <PanelExAutocomplete
              id="replace-new-valfirm"
              data-testid="replace-new-valfirm"
              style={{ width: "100%" }}
              options={replaceValfirm.newValfirmSuggestion}
              value={replaceValfirm.newValfirmText}
              popperMaxHeight={400}
              getOptionLabel={(option: any) =>
                option.name || replaceValfirm.newValfirmText || ""
              }
              placeholder="Enter Valuation Firm"
              isDisabled={!replaceValfirm.existingValfirmId}
              onInputChange={(value: any) => {
                handleNewValfirmInputChange(value);
              }}
              renderOption={(props: any, option: any) => {
                const { id, name } = option;
                const regex = new RegExp(
                  escapedSpecialCharacters(replaceValfirm.newValfirmText),
                  "gi"
                );
                const response = name.replace(regex, function (str: any) {
                  return "<span style='font-weight:500'>" + str + "</span>";
                });
                return (
                  <li
                    {...props}
                    data-testid={`replace-new-valfirm-label-${id}`}
                    id={`replace-new-valfirm-label-${id}`}
                    onClick={(ev) => {
                      handleUpdateNewValfirm(id, name);
                      ev.stopPropagation();
                    }}
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: `${response}` }}
                    ></div>
                  </li>
                );
              }}
            />
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}
