import React from "react";
import {
  Divider,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useDispatch, useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { RootState } from "../../store/staticReducers/reducers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  filterLocationChange,
  addFilterLocation,
  deleteFilterLocation,
  changeFilterCountry,
  updateFeeStatusFilter,
} from "../../store/action/actionFee";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

export default function FeeFilterOptions() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    select: {
      borderRadius: "4px",
      marginRight: "4px",
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      alignItems: "center",
    },
    bubble: {
      display: "flex",
      margin: "3px",
      padding: "3px 4px",
      alignItems: "center",
      borderRadius: "16px",
      background: grey[200],
    },
    bubbleText: {
      paddingLeft: "4px",
      color: theme.palette.text.primary,
    },
    bubbleIcon: {
      padding: "4px",
      "& .MuiSvgIcon-root": {
        color: `${theme.colors.grey.main}`,
        width: "16px",
        height: "16px",
      },
    },
    filterTitle: {
      color: theme.palette.text.primary,
    },
    autocomplete: {
      padding: "2px 0px 2px 0px",
      width: "550px",
      height: "38px",
      background: "#FFFFFF",
      borderRadius: "4px",
      "&:focus": {
        border: "2px solid #3399FF",
      },
      "& .MuiTextField-root": {
        marginTop: "-3px",
      },
      "& .MuiInputBase-root": {
        height: "38px",
      },
      "& .MuiOutlinedInput-root": {
        paddingRight: "9px !important",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
    infoIcon: {
      width: "18px",
      height: "18px",
      marginLeft: "4px",
    },
  }));
  const { classes } = useStyles();

  const {
    countryId,
    locationText,
    locationSuggestion,
    locations,
    feeSetStatus,
  } = useSelector((state: RootState) => {
    return {
      countryId: state.fee.filterOptions.countryId || 1,
      locationText: state.fee.filterOptions.locationText || "",
      locationSuggestion: state.fee.filterOptions.locationSuggestion || [],
      locations: state.fee.filterOptions.locations || [],
      feeSetStatus: state.fee.filterOptions.feeSetStatus || "In Use",
    };
  });

  const handleCountryChange = (event: SelectChangeEvent) => {
    dispatch(changeFilterCountry(event.target.value as string));
  };

  const handleLocationInputChange = (input: string) => {
    if (input !== locationText) {
      dispatch(filterLocationChange(input, countryId));
    }
  };

  const createBubble = (
    id: any,
    displayText: string,
    type: string,
    locationCountryId: number,
    index: number
  ) => {
    return (
      <Grid
        key={`grid-${type}-${index}`}
        data-testid={`grid-${type}-${index}`}
        className={classes.bubble}
      >
        <Typography variant="body3Strong" className={classes.bubbleText}>
          {displayText}
        </Typography>
        <IconButton
          id={`btn-delete-${type}-${index}`}
          data-testid={`btn-delete-${type}-${index}`}
          className={classes.bubbleIcon}
          onClick={(ev) => {
            handleDeleteLocation(id, locationCountryId, type);
            ev.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  };

  const handleAddLocation = (location: any) => {
    dispatch(addFilterLocation(location));
  };

  const handleDeleteLocation = (
    id: number,
    countryId: number,
    type: string
  ) => {
    dispatch(deleteFilterLocation(id, countryId, type));
  };

  const handleFeeStatusChange = (value: string) => {
    dispatch(updateFeeStatusFilter(value));
  };

  return (
    <Grid
      container
      size={12}
      sx={{ paddingTop: "10px", paddingBottom: "10px" }}
    >
      <Grid size={12} sx={{ padding: "0px 80px 3px 80px" }}>
        <Typography
          id="lbl-location"
          data-testid="lbl-location"
          variant="subtitle2"
          className={classes.filterTitle}
        >
          Location
        </Typography>
      </Grid>
      <Grid size={12} sx={{ padding: "0px 80px 13px 80px" }}>
        <Stack direction="row" className={classes.stack}>
          <Select
            labelId="location-filter-country-label"
            id="location-filter-country"
            data-testid="location-filter-country"
            value={countryId}
            className={classes.select}
            onChange={handleCountryChange}
            sx={{ maxHeight: "38px" }}
            variant="outlined"
          >
            <MenuItem value={"1"}>AU</MenuItem>
            <MenuItem value={"2"}>NZ</MenuItem>
          </Select>
          <PanelExAutocomplete
            id="location-filter"
            data-testid="location-filter"
            options={locationSuggestion}
            style={{ width: "100%" }}
            value={locationText}
            getOptionLabel={(option: any) => option.name || locationText || ""}
            placeholder="Search States, Regions or Postcodes"
            onInputChange={(value: any) => {
              handleLocationInputChange(value);
            }}
            renderOption={(props: any, option: any) => {
              const { id, name, type, countryName, isExisting } = option;
              const displayName =
                type === "Country"
                  ? [name, type].join(" ")
                  : [countryName, type, name].join(" ");
              const regex = new RegExp(
                escapedSpecialCharacters(locationText),
                "gi"
              );
              const response = displayName.replace(regex, function (str: any) {
                return "<span style='font-weight:500'>" + str + "</span>";
              });
              return (
                <li
                  id={`add-location-label-${id}`}
                  data-testid={`add-location-label-${id}`}
                  {...props}
                  style={{ justifyContent: "space-between" }}
                  onClick={(ev) => {
                    !isExisting && handleAddLocation(option);
                    ev.stopPropagation();
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: `${response}` }}
                  ></div>
                  <AddCircleIcon
                    id={`add-location-icon-${id}`}
                    data-testid={`add-location-icon-${id}`}
                    style={{
                      ...(!isExisting
                        ? {
                            color: "#757575",
                          }
                        : {
                            color: "rgba(0, 0, 0, 0.12)",
                          }),
                    }}
                  />
                </li>
              );
            }}
          />
        </Stack>
      </Grid>
      {locations.length > 0 && (
        <Grid
          container
          size={12}
          data-testid="grid-location-bubble"
          sx={{ padding: "0px 80px" }}
        >
          {locations.map((location: any, index: number) =>
            createBubble(
              location.id,
              location.name,
              location.type,
              location.countryId,
              index
            )
          )}
        </Grid>
      )}
      <Grid size={12} sx={{ paddingTop: "10px" }}>
        <Divider />
      </Grid>
      <Grid size={12} sx={{ padding: "12px 80px 3px 80px" }}>
        <Stack direction="row" className={classes.stack}>
          <Typography variant="subtitle2" className={classes.filterTitle}>
            Fee Status
          </Typography>
          <Tooltip
            id={"filter-status-tooltip"}
            data-testid={"filter-status-tooltip"}
            title='"In Use" means the set is attached to a live panel'
            placement="right"
            arrow
          >
            <InfoOutlinedIcon
              id={"filter-status-tooltip-icon"}
              data-testid={"filter-status-tooltip-icon"}
              className={classes.infoIcon}
              sx={{ color: theme.colors.grey.light }}
            />
          </Tooltip>
        </Stack>
      </Grid>
      <Grid size={12} sx={{ padding: "0px 80px 3px 80px" }}>
        <Select
          labelId="filter-status-label"
          id="filter-status"
          data-testid="filter-status"
          value={feeSetStatus}
          className={classes.select}
          onChange={(e: SelectChangeEvent) =>
            handleFeeStatusChange(e.target.value as string)
          }
          sx={{
            width: "250px",
            maxHeight: "38px",
          }}
        >
          <MenuItem value={"In Use"}>In Use</MenuItem>
          <MenuItem value={"Not Used"}>Not Used</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
}
