import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  addAllBulkUpdateLocation,
  addBulkUpdateLocation,
  deleteAllBulkUpdateLocation,
  deleteBulkUpdateLocation,
  getAllocationsLocations,
  updateBulkUpdateLocationText,
} from "../../../store/action/actionPanel";
import { RootState } from "../../../store/staticReducers/reducers";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const groupBy = (array: any[], key: string) => {
  return array.reduce((result: { [key: string]: any[] }, currentValue: any) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export default function SelectLocations() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: "14px",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    textBox: {
      background: theme.palette.common.white,
      paddingBottom: "8px",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 0px",
        height: "22px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #panel-name-helper-text": {
        textTransform: "none",
      },
    },
  }));

  const {
    locationSuggestion,
    locations,
    locationText,
    allocations,
    changeType,
    changeAllocation,
    removeValfirm,
    addValfirm,
    replaceValfirm,
  } = useSelector((state: RootState) => {
    return {
      locationSuggestion: state.panel.bulkUpdate.locationSuggestion,
      locations: state.panel.bulkUpdate.locations,
      locationText: state.panel.bulkUpdate.locationText,
      allocations: state.panel.bulkUpdate.allocations,
      changeType: state.panel.bulkUpdate.changeType,
      changeAllocation: state.panel.bulkUpdate.changeTypeChangeAlloc,
      removeValfirm: state.panel.bulkUpdate.changeTypeRemoveValfirm,
      addValfirm: state.panel.bulkUpdate.changeTypeAddValfirm,
      replaceValfirm: state.panel.bulkUpdate.changeTypeReplaceValfirm,
    };
  });

  const valfirmIdToBulkUpdate = (() => {
    switch (changeType) {
      case "replace-valfirm":
        return replaceValfirm.existingValfirmId;
      case "change-allocation":
        return changeAllocation.valfirmId;
      case "remove-valfirm":
        return removeValfirm.valfirmId;
      case "add-valfirm":
        return addValfirm.valfirmId;
    }
  })();

  const [expandIds, setExpandIds] = useState<number[]>([]);

  useEffect(() => {
    if (allocations.length > 0) {
      const allocRevIds = allocations.map((alloc: any) =>
        alloc.selectedVersion === "active"
          ? alloc.allocActiveRevId
          : alloc.allocDraftRevId
      );
      dispatch(
        getAllocationsLocations(
          allocRevIds,
          changeType === "add-valfirm" ? 0 : valfirmIdToBulkUpdate
        )
      );
    }
  }, [dispatch, allocations, valfirmIdToBulkUpdate, changeType]);

  const filteredLocations = locationSuggestion.filter((loc: any) => {
    return loc.name.toLowerCase().includes(locationText.toLowerCase());
  });

  const postcodes = filteredLocations.filter(
    (loc: any) => loc.type === "Postcode"
  );
  const groupedPostcodes = groupBy(postcodes, "parentId");
  const otherLocations = filteredLocations.filter(
    (loc: any) => loc.type !== "Postcode"
  );

  const getLocationName = (countryName: string, type: string, name: string) => {
    return [countryName, type === "Country" ? null : type + " " + name]
      .filter(Boolean)
      .join(" ");
  };

  const handleLocationTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updateBulkUpdateLocationText(event.target.value));
  };

  const handleSelectAll = (parentId: number) => {
    const parent = groupedPostcodes[parentId];
    const addLocations = parent.filter(
      (loc: any) =>
        !locations.find((l: any) => l.id === loc.id && l.type === loc.type)
    );
    if (addLocations.length > 0) {
      dispatch(addAllBulkUpdateLocation(addLocations));
    }
  };

  const handleClearSelected = (parentId: number) => {
    dispatch(deleteAllBulkUpdateLocation(groupedPostcodes[parentId]));
  };

  const isDisabledClearSelected = (parentId: number) => {
    const postcodes = groupedPostcodes[parentId];
    const list = locations.filter((l: any) => {
      return postcodes.find((p: any) => p.id === l.id && p.type === l.type);
    });
    return list.length === 0;
  };

  const { classes } = useStyles();

  return (
    <Grid
      container
      size={12}
      id="select-locations"
      data-testid="select-locations"
      sx={{ width: "100%", maxWidth: "1080px", padding: "0px 10px 0px 10px" }}
    >
      <Typography
        id="title-select-locations"
        data-testid="title-select-locations"
        variant="h1"
        paddingBottom={"24px"}
      >
        Select Locations
      </Typography>
      <Grid container size={12} className={classes.card}>
        <Typography
          id="location-label"
          data-testid="location-label"
          variant="h4"
          paddingBottom={"4px"}
        >
          On Locations
        </Typography>
        <Typography
          id="location-label-desc"
          data-testid="location-label-desc"
          variant="body3"
          paddingBottom={"8px"}
        >
          Panels are configured at Country, State, Regional and Postcodes.
        </Typography>
        <TextField
          id="location-filter"
          data-testid="location-filter"
          className={classes.textBox}
          placeholder="Search Location to Filter"
          variant="outlined"
          fullWidth={true}
          value={locationText}
          onChange={handleLocationTextChange}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    id="location-filter-start-icon"
                    data-testid="location-filter-start-icon"
                  />
                </InputAdornment>
              ),
            },
          }}
        />
        <Grid size={12} paddingBottom={"12px"}>
          {otherLocations.map((loc: any) => {
            return (
              (loc.type !== "Region" ||
                (!Object.keys(groupedPostcodes).includes(String(loc.id)) &&
                  loc.type === "Region")) && (
                <Grid size={12} key={`other-${loc.type}-${loc.id}`}>
                  <FormControlLabel
                    id={`checkbox-other-${loc.id}`}
                    data-testid={`checkbox-other-${loc.id}`}
                    sx={{ height: "46px" }}
                    label={
                      <Typography
                        id={`other-label-${loc.id}`}
                        data-testid={`other-label-${loc.id}`}
                        variant="h5"
                      >
                        {getLocationName(loc.countryName, loc.type, loc.name)}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        onChange={() => {
                          locations.find(
                            (l: any) => l.id === loc.id && l.type === loc.type
                          )
                            ? dispatch(
                                deleteBulkUpdateLocation(loc.id, loc.type)
                              )
                            : dispatch(addBulkUpdateLocation(loc));
                        }}
                      />
                    }
                    checked={
                      locations.find(
                        (l: any) => l.id === loc.id && l.type === loc.type
                      ) !== undefined
                    }
                  />
                  <Divider />
                </Grid>
              )
            );
          })}
        </Grid>
        {Object.values(groupedPostcodes).map((parent: any) => (
          <Grid size={12} key={`group-${parent[0].parentId}`}>
            <Stack direction={"column"} paddingBottom={"12px"}>
              <Grid size={12} alignItems={"center"}>
                {otherLocations.filter(
                  (o: any) =>
                    o.id === parent[0].parentId &&
                    o.type === parent[0].parentType
                ).length > 0 ? (
                  <FormControlLabel
                    id={`checkbox-parent-${parent[0].parentId}`}
                    data-testid={`checkbox-parent-${parent[0].parentId}`}
                    sx={{ height: "46px" }}
                    label={
                      <Typography
                        id={`parent-label-${parent[0].parentId}`}
                        data-testid={`parent-label-${parent[0].parentId}`}
                        variant="h5"
                      >
                        {getLocationName(
                          parent[0].countryName,
                          "Region",
                          parent[0].parentName
                        )}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        onChange={() => {
                          const parentLocation = locationSuggestion.find(
                            (other: any) =>
                              other.id === parent[0].parentId &&
                              other.type === parent[0].parentType
                          );
                          locations.find(
                            (l: any) =>
                              l.id === parentLocation.id &&
                              l.type === parentLocation.type
                          )
                            ? dispatch(
                                deleteBulkUpdateLocation(
                                  parentLocation.id,
                                  parentLocation.type
                                )
                              )
                            : dispatch(addBulkUpdateLocation(parentLocation));
                        }}
                      />
                    }
                    checked={
                      locations.find(
                        (l: any) =>
                          l.id === parent[0].parentId &&
                          l.type === parent[0].parentType
                      ) !== undefined
                    }
                  />
                ) : (
                  <Typography
                    id={`parent-label-${parent[0].parentId}`}
                    data-testid={`parent-label-${parent[0].parentId}`}
                    variant="h5"
                    sx={{
                      color: theme.palette.text.secondary,
                      height: "46px",
                      alignContent: "center",
                    }}
                  >
                    {getLocationName(
                      parent[0].countryName,
                      "Region",
                      parent[0].parentName
                    )}
                  </Typography>
                )}
                <Divider />
              </Grid>
              {parent
                .slice(
                  0,
                  parent.length > 5 && !expandIds.includes(parent[0].parentId)
                    ? 5
                    : parent.length
                )
                .map((loc: any, index: number) => (
                  <Grid container key={`${loc.id}-${index}`}>
                    <Grid size={8}>
                      <FormControlLabel
                        id={`checkbox-postcode-${loc.id}`}
                        data-testid={`checkbox-postcode-${loc.id}`}
                        label={
                          <Typography
                            id={`postcode-label-${loc.id}`}
                            data-testid={`postcode-label-${loc.id}`}
                            variant="h6"
                          >
                            {getLocationName(
                              loc.countryName,
                              loc.type,
                              loc.name
                            )}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            onChange={() => {
                              locations.find(
                                (l: any) =>
                                  l.id === loc.id && l.type === loc.type
                              )
                                ? dispatch(
                                    deleteBulkUpdateLocation(loc.id, loc.type)
                                  )
                                : dispatch(addBulkUpdateLocation(loc));
                            }}
                          />
                        }
                        checked={
                          locations.find(
                            (l: any) => l.id === loc.id && l.type === loc.type
                          ) !== undefined
                        }
                      />
                    </Grid>
                    <Grid size={4} display={"flex"} justifyContent={"end"}>
                      {index === 0 && (
                        <Stack direction={"row"} spacing={2}>
                          <Button
                            id={`btn-clear-selected-${parent[0].parentId}`}
                            data-testid={`btn-clear-selected-${parent[0].parentId}`}
                            variant="text"
                            color="primary"
                            size="small"
                            disabled={isDisabledClearSelected(
                              parent[0].parentId
                            )}
                            onClick={() =>
                              handleClearSelected(parent[0].parentId)
                            }
                          >
                            <Typography variant={"body2Strong"}>
                              Clear All
                            </Typography>
                          </Button>
                          <Button
                            id={`btn-select-all-${parent[0].parentId}`}
                            data-testid={`btn-select-all-${parent[0].parentId}`}
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={() => handleSelectAll(parent[0].parentId)}
                          >
                            <Typography variant={"body2Strong"}>
                              Select All
                            </Typography>
                          </Button>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                ))}
              {parent.length > 5 && !expandIds.includes(parent[0].parentId) && (
                <Grid size={12} height={"30px"}>
                  <Button
                    id={`btn-more-${parent[0].parentId}`}
                    data-testid={`btn-more-${parent[0].parentId}`}
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() =>
                      setExpandIds([...expandIds, parent[0].parentId])
                    }
                  >
                    <Typography variant={"body3Strong"}>
                      + {parent.length - 5} Postcode(s)
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
