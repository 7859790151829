import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  getFeeVersionComparisonDetails,
  showVersionsCompare,
  clearVersionsToCompare,
} from "../../store/action/actionFee";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";

export default function FeeVersionComparison() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    valfirmGrid: {
      width: "100%",
      "& .MuiTableCell-root": {
        padding: "5px 13px 5px 13px",
        height: "33px",
      },
      "& .MuiFormControl-root": {
        height: "auto",
      },
    },
    version: {
      fontSize: "13px",
      fontWeight: 600,
      backgroundColor: theme.colors.alpha.white["100"],
    },
    location: {
      fontSize: "14px",
      fontWeight: 600,
      "&.MuiTableCell-root": {
        padding: "13px",
        height: "50px",
      },
    },
    locationRow: {
      backgroundColor: theme.colors.neutral.lightest,
      height: "47px",
      fontSize: "13px",
    },
    fee: {
      fontSize: "13px",
    },
    noDiffText: {
      fontSize: "16px",
      padding: "32px 24px 32px 24px",
    },
  }));
  const { classes } = useStyles();

  const {
    feeId,
    versionCompare,
    showVersionCompare,
    versionCompareDetails,
    versionCompareLoading,
  } = useSelector((state: RootState) => {
    const feeSet = state.fee.feeSet;
    return {
      feeId: feeSet.feePanelId,
      versionCompare: state.fee.feeVersion.versionCompare,
      showVersionCompare: state.fee.feeVersion.showVersionCompare,
      versionCompareDetails: state.fee.feeVersion.versionCompareDetails || {},
      versionCompareLoading:
        state.fee.feeVersion.versionCompareLoading || false,
    };
  });

  useEffect(() => {
    if (!!feeId && versionCompare.length === 2) {
      dispatch(getFeeVersionComparisonDetails(feeId, versionCompare));
    }
  }, [dispatch, versionCompare]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeVersionCompare = () => {
    dispatch(clearVersionsToCompare());
    dispatch(showVersionsCompare(false));
  };

  const toLocaleNumberFormat = (input: any) => {
    return Number(input).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };
  const differenceCount = versionCompareDetails.differenceCount;
  const locations = versionCompareDetails.locations || [];

  const renderLocationRows = (
    locationName: any,
    leftExist: any,
    rightExist: any,
    leftSum: any,
    rightSum: any,
    index: any
  ) => {
    return (
      <TableRow key={`location-row-${index}`} className={classes.locationRow}>
        <TableCell
          component="td"
          scope="row"
          data-testid={`compare-location-name-${index}`}
          className={classes.location}
        >
          {locationName}
        </TableCell>
        <TableCell
          component="td"
          data-testid={`compare-location-left-${index}`}
        >
          {leftExist ? "" : "N/A"}
        </TableCell>
        <TableCell
          component="td"
          data-testid={`compare-location-right-${index}`}
        >
          {leftExist ? (rightExist ? "" : "Removed") : "Added"}
        </TableCell>
      </TableRow>
    );
  };

  const renderFeeRows = (fees: any, rightExist: any, locationIndex: any) => {
    return fees.map((fee: any, index2: number) => {
      const { feeBracketName, leftFee, rightFee } = fee;
      return (
        <TableRow
          key={`fee-row-${locationIndex}-${index2}`}
          className={classes.fee}
        >
          <TableCell
            component="td"
            scope="row"
            data-testid={`compare-fee-bracket-${locationIndex}-${index2}`}
          >
            {feeBracketName}
          </TableCell>
          <TableCell
            component="td"
            data-testid={`compare-fee-left-${locationIndex}-${index2}`}
          >
            {leftFee !== null ? "$" + toLocaleNumberFormat(leftFee) : "N/A"}
          </TableCell>
          <TableCell
            component="td"
            data-testid={`compare-fee-right-${locationIndex}-${index2}`}
          >
            {rightExist
              ? rightFee !== null
                ? "$" + toLocaleNumberFormat(rightFee)
                : leftFee !== null
                ? "Removed"
                : "N/A"
              : "N/A"}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <PanelExDialog
      id="version-compare-dialog"
      data-testid="version-compare-dialog"
      open={showVersionCompare}
      title={`${
        versionCompareLoading
          ? ""
          : differenceCount > 1
          ? "Comparison: " + differenceCount + " locations with differences"
          : differenceCount > 0
          ? "Comparison: " + differenceCount + " location with differences"
          : "Comparison: No differences found"
      }`}
      showCancel={false}
      submitText="Done"
      onClose={closeVersionCompare}
      onSubmit={closeVersionCompare}
      maxWidth="sm"
    >
      {versionCompareLoading ? (
        <Grid size={12}>
          <Box
            data-testid="version-compare-progress"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "10px",
            maxWidth: "sm",
          }}
        >
          {differenceCount > 0 ? (
            <FormControl
              sx={{
                mt: 2,
                mb: 2,
                minWidth: 500,
                minHeight: 591,
                maxHeight: 591,
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  className={classes.valfirmGrid}
                  data-testid="version-compare-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        className={classes.version}
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        data-testid={"compare-version-0"}
                        className={classes.version}
                      >
                        v{versionCompareDetails.leftVersionId}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        data-testid={"compare-version-1"}
                        className={classes.version}
                      >
                        v{versionCompareDetails.rightVersionId}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {locations
                      .filter((location: any) => location.isDifferent)
                      .map((location: any, index: number) => {
                        const {
                          locationName,
                          leftExist,
                          rightExist,
                          leftSum,
                          rightSum,
                          fees,
                        } = location;
                        const locationRows = renderLocationRows(
                          locationName,
                          leftExist,
                          rightExist,
                          leftSum,
                          rightSum,
                          index
                        );
                        const feeRows = renderFeeRows(fees, rightExist, index);

                        return (
                          <React.Fragment key={`location-${index}`}>
                            {locationRows}
                            {feeRows}
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </FormControl>
          ) : (
            <FormControl
              sx={{
                mt: 2,
                mb: 2,
                minWidth: 500,
                minHeight: 100,
                maxHeight: 100,
              }}
            >
              <Typography
                className={classes.noDiffText}
                data-testid="version-compare-no-diff"
              >
                No differences found between versions{" "}
                {versionCompareDetails.leftVersionId} &{" "}
                {versionCompareDetails.rightVersionId}
              </Typography>
            </FormControl>
          )}
        </Box>
      )}
    </PanelExDialog>
  );
}
