import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import {
  Button,
  Divider,
  Grid2 as Grid,
  Tab,
  Tabs,
  Box,
  Typography,
  useTheme,
} from "@mui/material";

import Settings from "./Settings";
import PanelActivationLogs from "./PanelActivationLogs";
import Versions from "./Versions";
import { makeStyles } from "tss-react/mui";
import {
  changePanelTab,
  showPanelSetForm,
} from "../../../store/action/actionPanel";
import EditIcon from "@mui/icons-material/Edit";
import PanelSetForm from "../PanelSetForm";

export default function PanelSettings(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    setTabs: {
      alignItems: "center",
      padding: "4px 6px",
      background: theme.colors.grey.lightest,
      height: "44px",
      borderRadius: "4px",

      "& .MuiTabs-indicator": {
        height: "0px",
      },
      "& .MuiTabs-flexContainer": {
        padding: "0px 6px",
        justifyContent: "space-between",
      },
      "& .MuiTab-root": {
        color: theme.colors.grey.dark,
        padding: "7px 6px",
        height: "30px",
        minHeight: "30px",
        borderRadius: "4px",

        "&.Mui-selected": {
          background: theme.palette.common.white,
          color: theme.palette.tertiary.main,
        },
      },
    },
  }));
  const { classes } = useStyles();

  const panelVersionId = props.panelVersionId;
  useEffect(() => {
    dispatch(changePanelTab(0));
  }, [dispatch, panelVersionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    panelTab,
    panelSet,
    panelVersionDetail,
    openPanelSetForm,
    requestedActivationLog,
    roleName,
    readOnly,
  } = useSelector((state: RootState) => {
    return {
      panelTab: state.panel.panelTab,
      panelVersionDetail: state.panel.panelVersionDetail,
      panelSet: state.panel.panelSet,
      openPanelSetForm: state.panel.openPanelSetForm,
      requestedActivationLog: state.panel.latestPanelActivationLog,
      roleName: state.tokenDetails.userContactDetails.roleName,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changePanelTab(newValue));
  };
  const { panelSetId } = panelSet;
  const renderTabPanel = (index: number) => {
    switch (index) {
      case 1:
        return (
          <PanelActivationLogs
            panelSetId={panelSetId}
            panelVersionId={panelVersionId}
          />
        );
      case 2:
        return <Versions panelSetVersionId={panelVersionId} />;
      default:
        return <Settings />;
    }
  };

  const openEditPanelSet = () => {
    dispatch(showPanelSetForm(true, false));
  };

  const checkEditable = () => {
    if (panelVersionDetail.editable) {
      if (requestedActivationLog.panelSetRevId === panelVersionId) {
        return (
          requestedActivationLog.status === "Queued" ||
          requestedActivationLog.status === "Processed"
        );
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Grid
      container
      id="panel-settings-component"
      data-testid="panel-settings-component"
      sx={{ padding: "16px 16px 8px 16px" }}
    >
      <Grid container size={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Grid container size={6}>
          <Typography
            id="panel-settings-title"
            data-testid="panel-settings-title"
            variant="h3"
            component="h3"
          >
            Panel Settings
          </Typography>
        </Grid>
        <Grid size={6}>
          <Box display="flex" justifyContent="flex-end">
            <Grid container display="flex" justifyContent="flex-end">
              <Grid sx={{ paddingRight: "15px", alignContent: "center" }}>
                {roleName === "Admin" && !readOnly && (
                  <Button
                    id="btn-edit-panel"
                    data-testid="btn-edit-panel"
                    size="small"
                    variant="contained"
                    onClick={() => {
                      openEditPanelSet();
                    }}
                    startIcon={<EditIcon />}
                    sx={{ paddingLeft: "15px" }}
                    disabled={checkEditable()}
                  >
                    Edit Settings
                  </Button>
                )}
              </Grid>
              <Grid>
                <Tabs
                  className={classes.setTabs}
                  value={panelTab}
                  onChange={handleTabChange}
                  sx={{ width: "390px" }}
                >
                  <Tab
                    data-testid="panel-tab-settings"
                    id="panel-settings"
                    label="Panel Settings"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    data-testid="panel-tab-history"
                    id="panel-history"
                    label="Activations"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    data-testid="panel-tab-versions"
                    id="panel-tab-versions"
                    label="Versions"
                    sx={{ textTransform: "none" }}
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Grid container sx={{ width: "100%", paddingTop: "10px" }}>
        <Grid size={12}>{renderTabPanel(panelTab)}</Grid>
      </Grid>
      {openPanelSetForm && <PanelSetForm />}
    </Grid>
  );
}
