import {
  FETCH_USERS,
  SHOW_SEARCH_NEW_USER,
  SEARCH_NEW_USER,
  SHOW_EDIT_USER,
  FETCH_USER_DETAIL,
  UPDATE_ROLE_EDIT_USER,
  UPDATE_FIRST_NAME_EDIT_USER,
  UPDATE_LAST_NAME_EDIT_USER,
  UPDATE_EMAIL_EDIT_USER,
  ADD_FUNDER_EDIT_USER,
  ADD_CLIENT_EDIT_USER,
  DELETE_FUNDER_EDIT_USER,
  DELETE_CLIENT_EDIT_USER,
  UPDATE_ORG_TEXT_CHANGE,
  UPDATE_USER,
  SHOW_ADD_NEW_USER,
  ADD_NEW_USER,
  UPDATE_IS_NAB,
  UPDATE_IS_READONLY,
  REMOVE_USER,
  REACTIVATE_USER,
} from "../staticSagas/sagaUser";

export function getUsers(searchText, currentPage, pageSize) {
  return {
    type: FETCH_USERS,
    payload: {
      searchText,
      currentPage,
      pageSize,
    },
  };
}
export function getUserDetail(userId) {
  return {
    type: FETCH_USER_DETAIL,
    payload: {
      userId,
    },
  };
}
export function showEditUser(userId, showEditUser) {
  return {
    type: SHOW_EDIT_USER,
    payload: {
      userId,
      showEditUser,
    },
  };
}

export function updateUserRoleEditUser(roleName, isEdit) {
  return {
    type: UPDATE_ROLE_EDIT_USER,
    payload: {
      roleName,
      isEdit,
    },
  };
}
export function updateFirstNameEditUser(firstName, isEdit) {
  return {
    type: UPDATE_FIRST_NAME_EDIT_USER,
    payload: {
      firstName,
      isEdit,
    },
  };
}
export function updateIsNab(isNab, isEdit) {
  return {
    type: UPDATE_IS_NAB,
    payload: {
      isNab,
      isEdit,
    },
  };
}
export function updateIsReadOnly(isReadOnly, isEdit) {
  return {
    type: UPDATE_IS_READONLY,
    payload: {
      isReadOnly,
      isEdit,
    },
  };
}
export function updateLastNameEditUser(lastName, isEdit) {
  return {
    type: UPDATE_LAST_NAME_EDIT_USER,
    payload: {
      lastName,
      isEdit,
    },
  };
}
export function updateEmailFormEditUser(email, isEdit) {
  return {
    type: UPDATE_EMAIL_EDIT_USER,
    payload: {
      email,
      isEdit,
    },
  };
}
export function addFunderEditUser(orgId, orgName, isEdit) {
  return {
    type: ADD_FUNDER_EDIT_USER,
    payload: {
      orgId,
      orgName,
    },
    isEdit,
  };
}

export function addClientEditUser(orgId, orgName, isEdit) {
  return {
    type: ADD_CLIENT_EDIT_USER,
    payload: {
      orgId,
      orgName,
    },
    isEdit,
  };
}

export function removeFunderEditUser(orgId, isEdit) {
  return {
    type: DELETE_FUNDER_EDIT_USER,
    payload: {
      orgId,
      isEdit,
    },
  };
}

export function removeClientEditUser(orgId, isEdit) {
  return {
    type: DELETE_CLIENT_EDIT_USER,
    payload: {
      orgId,
      isEdit,
    },
  };
}
export function updateOrgTextChange(text, orgTypeOption, isEdit) {
  return {
    type: UPDATE_ORG_TEXT_CHANGE,
    payload: {
      orgTypeOption,
      text,
      isEdit,
    },
  };
}

export function updateUser(userDetail) {
  return {
    type: UPDATE_USER,
    payload: {
      userDetail,
    },
  };
}

export function showSearchNewUser(show) {
  return {
    type: SHOW_SEARCH_NEW_USER,
    show,
  };
}
export function searchNewUser(email) {
  return {
    type: SEARCH_NEW_USER,
    email,
  };
}

export function showAddNewUser(show) {
  return {
    type: SHOW_ADD_NEW_USER,
    show,
  };
}

export function addNewUser(user) {
  return {
    type: ADD_NEW_USER,
    payload: user,
  };
}
export function removeUser(userId) {
  return {
    type: REMOVE_USER,
    payload: {
      userId,
    },
  };
}
export function reActivateUser(userId) {
  return {
    type: REACTIVATE_USER,
    payload: {
      userId,
    },
  };
}
