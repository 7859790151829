import axios from "../../utilities/axiosInstance";

export const fetchUsers = (searchText, currentPage, pageSize) => {
  const url = `/api/users?q=${encodeURIComponent(
    searchText
  )}&currentPage=${currentPage}&pageSize=${pageSize}`;
  return axios.get(url);
};

export const fetchUserByEmail = (email) => {
  const url = `/api/users/search?email=${encodeURIComponent(email)}`;
  return axios.get(url);
};
export const fetchUserDetail = (userId) => {
  const url = `/api/users/${userId}`;
  return axios.get(url);
};

export const fetchUserOrgSuggestion = (text, orgType) => {
  const url = `/api/orgs/${orgType}/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const updateUserDetails = (userId, userDetail) => {
  const url = `/api/users/${userId}/update`;
  return axios.post(url, userDetail);
};

export const createNewUser = (userDetail) => {
  const url = `/api/users/newuser`;
  return axios.post(url, userDetail);
};
export const deActivateUser = (userId) => {
  const url = `/api/users/${userId}/remove`;
  return axios.get(url);
};
export const activateUser = (userId) => {
  const url = `/api/users/${userId}/reactivate`;
  return axios.get(url);
};
