import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  SvgIcon,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import SelectVersion from "./SelectVersion";
import MakeChanges from "./MakeChanges";
import PreviewData from "./PreviewData";
import SelectLocations from "./SelectLocations";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ReactComponent as ListAltAddIcon } from "../../../img/listAltAdd.svg";
import { ReactComponent as EditDocumentIcon } from "../../../img/editDocument.svg";
import {
  backToPreviousStep,
  cancelBulkUpdate,
  getBulkUpdateInfo,
  setPreviewData,
  setSelectVersion,
  setBulkUpdateSaveAsDraft,
  setSelectLocations,
} from "../../../store/action/actionPanel";

export default function Index() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    title: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 24px",
    },
    changeType: {
      display: "flex",
      justifyContent: "center",
      boxShadow:
        "0px 1px 14px 0px #0000001F, 0px 5px 8px 0px #00000024, 0px 3px 5px -1px #00000033",
    },
    changeTypeContent: {
      width: "100%",
      maxWidth: "1080px",
      padding: "8px 16px",
    },
    changeTypePreviewDataContent: {
      maxWidth: "100%",
      padding: "8px 60px",
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      width: "100%",
      padding: "0",
      background: theme.colors.neutral.lightest,
    },
    previewDataContent: {
      background: "#FFFFFF",
    },
    stepperContainer: {
      background: theme.colors.neutral.lightest,
    },
    stepper: {
      padding: "25px 0px 80px 0px",
    },
    button: {
      "&.Mui-disabled": {
        color: theme.palette.text.disabled,
        "& .MuiButton-endIcon": {
          color: theme.palette.text.disabled,
        },
      },
    },
    outlinedButton: {
      border: "1px solid rgba(51, 153, 255, 0.5)",
      borderRadius: "4px",
    },
    allocationLabel: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.4px",
      textAlign: "left",
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
    },
  }));
  const { classes } = useStyles();

  const {
    allocations,
    isOpenBulkUpdate,
    bulkUpdateStep,
    changeType,
    distributionMethod,
    replaceValfirm,
    addValfirm,
    removeValfirm,
    changeAllocation,
    previewData,
    isIncludeBackup,
    addAnotherBulkUpdate,
    selectedPanels,
    locations,
    previewPanelIds,
  } = useSelector((state: RootState) => {
    return {
      allocations: state.panel.bulkUpdate.allocations || [],
      isOpenBulkUpdate: state.panel.bulkUpdate.isOpenBulkUpdate,
      bulkUpdateStep: state.panel.bulkUpdate.bulkUpdateStep,
      changeType: state.panel.bulkUpdate.changeType || "",
      replaceValfirm: state.panel.bulkUpdate.changeTypeReplaceValfirm,
      distributionMethod: state.panel.bulkUpdate.distributionMethod,
      addValfirm: state.panel.bulkUpdate.changeTypeAddValfirm,
      removeValfirm: state.panel.bulkUpdate.changeTypeRemoveValfirm,
      changeAllocation: state.panel.bulkUpdate.changeTypeChangeAlloc,
      previewData: state.panel.bulkUpdate.previewData || [],
      isIncludeBackup: state.panel.bulkUpdate.isIncludeBackup,
      addAnotherBulkUpdate:
        state.panel.bulkUpdate.addAnotherBulkUpdate || false,
      selectedPanels: state.panel.selectedPanels || [],
      locations: state.panel.bulkUpdate.locations,
      previewPanelIds: state.panel.bulkUpdate.previewPanelIds,
    };
  });

  const allocRevIdsToBulkUpdate = allocations.map((allocation: any) => {
    const { allocActiveRevId, allocDraftRevId, selectedVersion } = allocation;
    const version = selectedVersion || (allocDraftRevId ? "draft" : "active");
    return version === "draft" ? allocDraftRevId : allocActiveRevId;
  });

  useEffect(() => {
    if (selectedPanels.length > 0) {
      dispatch(getBulkUpdateInfo(selectedPanels));
    }
  }, [dispatch, selectedPanels]);

  const steps = [
    "Select Version",
    "Make Changes",
    "Select Locations",
    "Review Changes",
  ];

  const renderBulkUpdateStep = (step: number) => {
    switch (step) {
      case 0:
        return <SelectVersion />;
      case 1:
        return <MakeChanges />;
      case 2:
        return <SelectLocations />;
      case 3:
        return <PreviewData />;
    }
  };

  const enableLocations = () => {
    if (changeType === "add-valfirm") {
      const { valfirmId, allocation } = addValfirm;
      return (
        distributionMethod !== "" &&
        valfirmId &&
        ((distributionMethod !== "evenly-all" && allocation > 0) ||
          distributionMethod === "evenly-all")
      );
    } else if (changeType === "replace-valfirm") {
      const { existingValfirmId, newValfirmId } = replaceValfirm;
      return existingValfirmId && newValfirmId;
    } else if (changeType === "remove-valfirm") {
      const { valfirmId } = removeValfirm;
      return distributionMethod !== "" && valfirmId;
    } else if (changeType === "change-allocation") {
      const { valfirmId, allocation, changeAllocationBy } = changeAllocation;
      return (
        distributionMethod !== "" &&
        valfirmId &&
        allocation &&
        changeAllocationBy !== ""
      );
    } else {
      return false;
    }
  };

  const handlePreviewData = () => {
    const commonData = {
      allocations,
      allocRevIds: allocRevIdsToBulkUpdate,
      changeType,
    };
    let data: any = {};
    if (changeType === "replace-valfirm") {
      data = {
        ...commonData,
        valfirmId: replaceValfirm.existingValfirmId,
        valfirmName: replaceValfirm.existingValfirmText,
        replaceValfirmId: replaceValfirm.newValfirmId,
        replaceValfirmName: replaceValfirm.newValfirmText,
        locations,
      };
    } else if (changeType === "add-valfirm") {
      data = {
        ...commonData,
        distributionMethod: distributionMethod,
        isIncludeBackup,
        valfirmId: addValfirm.valfirmId,
        valfirmName: addValfirm.valfirmText,
        locations,
        allocation: addValfirm.allocation,
      };
    } else if (changeType === "remove-valfirm") {
      data = {
        ...commonData,
        distributionMethod: distributionMethod,
        isIncludeBackup,
        valfirmId: removeValfirm.valfirmId,
        valfirmName: removeValfirm.valfirmText,
        locations,
      };
    } else if (changeType === "change-allocation") {
      data = {
        ...commonData,
        distributionMethod: distributionMethod,
        isIncludeBackup,
        valfirmId: changeAllocation.valfirmId,
        valfirmName: changeAllocation.valfirmText,
        locations,
        allocation: changeAllocation.allocation,
        changeAllocationBy: changeAllocation.changeAllocationBy,
      };
    }
    dispatch(setPreviewData(data));
  };

  const handleSaveAsDraft = (addAnotherBulkUpdate: boolean) => {
    const data = {
      allocations,
      previewData,
      addAnotherBulkUpdate,
      changeType,
      distributionMethod,
      previewPanelIds,
      selectedPanels,
      locations,
    };
    dispatch(setBulkUpdateSaveAsDraft(data));
  };

  const handleSelectVersion = () => {
    const activeCount = allocations.filter(
      (allocation: any) => allocation.selectedVersion === "active"
    ).length;
    const draftCount = allocations.filter(
      (allocation: any) => allocation.selectedVersion === "draft"
    ).length;
    dispatch(setSelectVersion({ active: activeCount, draft: draftCount }));
  };

  const handleCancelBulkUpdate = () => {
    const activeCount = allocations.filter(
      (allocation: any) => allocation.selectedVersion === "active"
    ).length;
    const draftCount = allocations.filter(
      (allocation: any) => allocation.selectedVersion === "draft"
    ).length;
    switch (bulkUpdateStep) {
      case 0:
        dispatch(
          cancelBulkUpdate("Select Versions", {
            allocationVersionCount: {
              active: activeCount,
              draft: draftCount,
            },
          })
        );
        break;
      case 1:
        dispatch(
          cancelBulkUpdate("Make Changes", { changeType, distributionMethod })
        );
        break;
      case 2:
        dispatch(
          cancelBulkUpdate("Select Locations", {
            locationCount: locations.length,
          })
        );
        break;
      case 3:
        dispatch(
          cancelBulkUpdate("Review Changes", {
            previewPanels:
              previewPanelIds.length + " of " + selectedPanels.length,
          })
        );
        break;
    }
  };

  const handleSelectLocations = () => {
    dispatch(setSelectLocations(changeType, distributionMethod));
  };

  const handlePreviousStep = (step: number) => {
    switch (step) {
      case 0:
        dispatch(
          backToPreviousStep(step, {
            step: "Back to Select Versions",
            changeType,
            distributionMethod,
          })
        );
        break;
      case 1:
        dispatch(
          backToPreviousStep(step, {
            step: "Back to Make Changes",
            locationCount: locations.length,
          })
        );
        break;
      case 2:
        dispatch(
          backToPreviousStep(step, {
            step: "Back to Select Locations",
            previewPanels:
              previewPanelIds.length + " of " + selectedPanels.length,
          })
        );
        break;
    }
  };

  return (
    <Dialog
      id="bulk-update-dialog"
      data-testid="bulk-update-dialog"
      open={isOpenBulkUpdate}
      scroll="paper"
      fullScreen={true}
    >
      <DialogTitle className={classes.title}>
        <Grid container size={12}>
          <Stack spacing={1} direction={"row"} className={classes.stack}>
            <Typography
              id="bulk-update-title"
              data-testid="bulk-update-title"
              variant="h3"
            >
              Bulk Update
            </Typography>
          </Stack>
        </Grid>
      </DialogTitle>
      <DialogContent
        id="bulk-update-content"
        data-testid="bulk-update-content"
        dividers={true}
        className={`${classes.content} ${
          bulkUpdateStep === 3 ? classes.previewDataContent : null
        }`}
      >
        <Grid container size={12} sx={{ width: "100%" }}>
          <Grid
            size={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: theme.colors.neutral.lightest,
            }}
          >
            <Stepper
              id="bulk-update-stepper"
              data-testid="bulk-update-stepper"
              activeStep={bulkUpdateStep}
              className={classes.stepper}
              sx={{
                width: "100%",
                maxWidth: "1080px",
              }}
            >
              {steps.map((label, index) => {
                return (
                  <Step
                    key={label}
                    id={`step-${index}`}
                    data-testid={`step-${index}`}
                    sx={{
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: theme.palette.text.primary,
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        fontWeight: "400 !important",
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: theme.palette.text.primary,
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: theme.palette.common.white,
                      },
                      "& .MuiStepLabel-root .MuiStepIcon-text": {
                        fill: theme.palette.common.white,
                      },
                      "& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active, .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed":
                        {
                          color: theme.colors.primary.dark,
                        },
                    }}
                  >
                    <StepLabel>
                      <Typography variant={"body2"}>{label}</Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid
            size={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {renderBulkUpdateStep(bulkUpdateStep)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.changeType}>
        <Grid
          container
          size={12}
          className={`${classes.changeTypeContent} ${
            bulkUpdateStep === 3 ? classes.changeTypePreviewDataContent : null
          }`}
        >
          <Grid size={6}>
            <Stack spacing={2} direction="row">
              {bulkUpdateStep === 1 && (
                <Button
                  id="btn-back-version"
                  data-testid="btn-back-version"
                  variant="text"
                  onClick={() => handlePreviousStep(0)}
                  startIcon={<ArrowBackIcon />}
                  disabled={addAnotherBulkUpdate}
                  sx={{ color: theme.palette.primary.dark }}
                >
                  Back to Select Versions
                </Button>
              )}
              {bulkUpdateStep === 2 && (
                <Button
                  id="btn-back-change"
                  data-testid="btn-back-change"
                  variant="text"
                  onClick={() => handlePreviousStep(1)}
                  startIcon={<ArrowBackIcon />}
                  sx={{ color: theme.palette.primary.dark }}
                >
                  Back to Make Changes
                </Button>
              )}
              {bulkUpdateStep === 3 && (
                <>
                  <Button
                    id="btn-back-locations"
                    data-testid="btn-back-locations"
                    variant="text"
                    onClick={() => handlePreviousStep(2)}
                    startIcon={<ArrowBackIcon />}
                    sx={{ color: theme.palette.primary.dark }}
                  >
                    Back to Select Locations
                  </Button>
                  {/*
                    <Button
                      id="btn-export"
                      data-testid="btn-export"
                      variant="text"
                      startIcon={<FileDownloadOutlinedIcon />}
                    >
                      Export
                    </Button>
                  */}
                </>
              )}
            </Stack>
          </Grid>
          <Grid size={6} display={"flex"} justifyContent={"end"}>
            <Stack spacing={2} direction="row">
              <Button
                id="btn-cancel"
                data-testid="btn-cancel"
                variant="text"
                sx={{ color: theme.palette.primary.dark }}
                onClick={handleCancelBulkUpdate}
              >
                Cancel
              </Button>
              {bulkUpdateStep === 0 && (
                <Button
                  id="btn-make-change"
                  data-testid="btn-make-change"
                  variant="contained"
                  className={classes.button}
                  endIcon={<ArrowForwardIcon />}
                  onClick={handleSelectVersion}
                >
                  Make Changes
                </Button>
              )}
              {bulkUpdateStep === 1 && (
                <Button
                  id="btn-select-locations"
                  data-testid="btn-select-locations"
                  variant="contained"
                  className={classes.button}
                  endIcon={<ArrowForwardIcon />}
                  onClick={handleSelectLocations}
                  disabled={!enableLocations()}
                >
                  Select Locations
                </Button>
              )}
              {bulkUpdateStep === 2 && (
                <Button
                  id="btn-preview-data"
                  data-testid="btn-preview-data"
                  variant="contained"
                  className={classes.button}
                  endIcon={<ArrowForwardIcon />}
                  disabled={locations.length <= 0}
                  onClick={handlePreviewData}
                >
                  Review Changes
                </Button>
              )}
              {bulkUpdateStep === 3 && (
                <>
                  {/*
                  <Button
                    id="btn-activate"
                    data-testid="btn-activate"
                    variant="outlined"
                  >
                    Activate
                  </Button>
                  */}
                  <Button
                    id="btn-add"
                    data-testid="btn-add"
                    sx={{ width: "340px" }}
                    variant="outlined"
                    className={classes.outlinedButton}
                    startIcon={
                      <SvgIcon>
                        <ListAltAddIcon />
                      </SvgIcon>
                    }
                    disabled={previewData.length === 0}
                    onClick={() => handleSaveAsDraft(true)}
                  >
                    Save Draft & Add Another Bulk Update
                  </Button>
                  <Button
                    id="btn-save-draft"
                    data-testid="btn-save-draft"
                    sx={{ width: "230px" }}
                    variant="contained"
                    disabled={previewData.length === 0}
                    startIcon={
                      <SvgIcon>
                        <EditDocumentIcon />
                      </SvgIcon>
                    }
                    onClick={() => handleSaveAsDraft(false)}
                  >
                    Confirm All as Draft
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
