import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import {
  deleteFunderPanelForm,
  deleteClientPanelForm,
  addPropTypePanelForm,
  deletePropTypePanelForm,
  addServiceTypePanelForm,
  deleteServiceTypePanelForm,
  updatePanelNamePanelForm,
  updatePanelNotesPanelForm,
  updatePanelTypePanelForm,
  updatePanelOrderPanelForm,
  updateUseRulexPanelForm,
  updateBracketMinPanelForm,
  updateBracketMaxPanelForm,
  createPanelSet,
  editPanelSet,
  showPanelSetForm,
  updatePanelInUsePanelForm,
  addFunderPanelForm,
  addClientPanelForm,
} from "../../store/action/actionPanel";
import {
  Box,
  FormControl,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import {
  updatePropTypeTextChangePanelForm,
  updateServiceTypeTextChangePanelForm,
  updateFunderTextChangePanelForm,
  updateClientTextChangePanelForm,
} from "../../store/action/actionUI";
import { grey } from "@mui/material/colors";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  }
);
export default function PanelSetForm() {
  const dispatch = useDispatch();
  const useStyles = makeStyles()(() => ({
    gridContainer: {
      display: "flex",
      flexDirection: "row",
      height: "auto",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    fixedHeight: {
      minHeight: "146px",
    },
    stack: {
      width: "100%",
      alignItems: "left",
    },
    textBox: {
      background: theme.palette.common.white,
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #panel-name-helper-text": {
        textTransform: "none",
      },
    },
    fixWidth: {
      width: "165px",
    },
    label: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
      lineHeight: "24px",
    },
    infoIcon: {
      width: "18px",
      height: "18px",
      marginLeft: "4px",
    },
    select: {
      borderRadius: "4px",
      marginRight: "4px",
    },
    useRulexSwitch: {
      "& span": {
        fontSize: "12px",
        fontWeight: 500,
      },
    },
    bubble: {
      display: "flex",
      margin: "3px",
      padding: "3px 4px",
      alignItems: "center",
      borderRadius: "16px",
      background: grey[200],
    },
    bubbleText: {
      paddingLeft: "4px",
      color: theme.palette.text.primary,
    },
    bubbleIcon: {
      padding: "4px",
      "& .MuiSvgIcon-root": {
        color: `${theme.colors.grey.main}`,
        width: "16px",
        height: "16px",
      },
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.error.main,
      },
    },
  }));
  const theme = useTheme();
  const { classes } = useStyles();

  const {
    openPanelSetForm,
    panelSetRevId,
    name,
    notes,
    panelType,
    order,
    useRuleEx,
    statusBool,
    bracketMin,
    bracketMax,
    panelSetFunderDtoSet,
    panelSetClientDtoSet,
    panelSetPropertyTypeDto,
    panelSetServiceDtoSet,
    funderSearchText,
    funderSuggestion,
    clientSearchText,
    clientSuggestion,
    propTypeSearchText,
    propTypeSuggestions,
    serviceTypeSearchText,
    serviceTypeSuggestions,
    panelSetForm,
    error,
  } = useSelector((state: RootState) => {
    return {
      openPanelSetForm: state.panel.openPanelSetForm,
      panelSetRevId: state.panel.panelSetForm.panelSetRevId || 0,
      name: state.panel.panelSetForm.name || "",
      notes: state.panel.panelSetForm.notes || "",
      panelType: state.panel.panelSetForm.panelType || "Normal",
      order: state.panel.panelSetForm.order || "",
      useRuleEx: state.panel.panelSetForm.useRuleEx || false,
      statusBool: state.panel.panelSetForm.statusBool || false,
      bracketMin: state.panel.panelSetForm.bracketMin || "",
      bracketMax: state.panel.panelSetForm.bracketMax || "",
      panelSetFunderDtoSet: state.panel.panelSetForm.panelSetFunderDtoSet,
      panelSetClientDtoSet: state.panel.panelSetForm.panelSetClientDtoSet,
      panelSetPropertyTypeDto: state.panel.panelSetForm.panelSetPropertyTypeDto,
      panelSetServiceDtoSet: state.panel.panelSetForm.panelSetServiceDtoSet,
      panelSetForm: state.panel.panelSetForm,
      error: state.panel.panelSetForm.error,
      funderSearchText: state.ui.panelFormSuggestions.funderSearchText || "",
      funderSuggestion: state.ui.panelFormSuggestions.funderSuggestion || [],
      clientSearchText: state.ui.panelFormSuggestions.clientSearchText || "",
      clientSuggestion: state.ui.panelFormSuggestions.clientSuggestion || [],
      propTypeSearchText: state.ui.panelFormSuggestions.propTypeSearchText,
      propTypeSuggestions:
        state.ui.panelFormSuggestions.propTypeSuggestion || [],
      serviceTypeSearchText:
        state.ui.panelFormSuggestions.serviceTypeSearchText,
      serviceTypeSuggestions:
        state.ui.panelFormSuggestions.serviceTypeSuggestion || [],
    };
  });

  const panelSubmitEnabled =
    name !== "" &&
    !(
      bracketMin &&
      bracketMax &&
      parseInt(bracketMax) < parseInt(bracketMin)
    ) &&
    (panelSetFunderDtoSet.length > 0 || panelSetClientDtoSet.length > 0) &&
    panelSetServiceDtoSet.length > 0;

  const handlePropTypeInputChange = (input: string) => {
    if (input !== propTypeSearchText) {
      dispatch(updatePropTypeTextChangePanelForm(input));
    }
  };

  const handleAddPropType = (id: number, name: string) => {
    dispatch(addPropTypePanelForm(id, name));
  };

  const handleDeletePropType = (id: number) => {
    dispatch(deletePropTypePanelForm(id));
  };

  const handleServiceTypeInputChange = (input: string) => {
    if (input !== serviceTypeSearchText) {
      dispatch(updateServiceTypeTextChangePanelForm(input));
    }
  };

  const handleAddServiceType = (id: number, name: string) => {
    dispatch(addServiceTypePanelForm(id, name));
  };

  const handleDeleteServiceType = (id: number) => {
    dispatch(deleteServiceTypePanelForm(id));
  };

  const handleCreatePanelSet = () => {
    dispatch(createPanelSet(panelSetForm));
  };

  const handleEditPanelSet = () => {
    dispatch(editPanelSet(panelSetForm));
  };

  const handlePanelNameChange = (value: string) => {
    dispatch(updatePanelNamePanelForm(value));
  };
  const handlePanelNotesChange = (value: string) => {
    dispatch(updatePanelNotesPanelForm(value));
  };
  const handlePanelTypeChange = (value: string) => {
    dispatch(updatePanelTypePanelForm(value));
  };
  const handlePanelOrderChange = (value: string) => {
    dispatch(updatePanelOrderPanelForm(value));
  };
  const handleUseRulexChange = (value: boolean) => {
    dispatch(updateUseRulexPanelForm(value));
  };
  const handlePanelInUseChange = (value: boolean) => {
    dispatch(updatePanelInUsePanelForm(value));
  };
  const handleBracketMinChange = (value: string) => {
    dispatch(updateBracketMinPanelForm(value));
  };
  const handleBracketMaxChange = (value: string) => {
    dispatch(updateBracketMaxPanelForm(value));
  };

  const handleFunderInputChange = (input: string) => {
    if (input !== funderSearchText) {
      dispatch(updateFunderTextChangePanelForm(input, "Funder"));
    }
  };
  const handleClientInputChange = (input: string) => {
    if (input !== clientSearchText) {
      dispatch(updateClientTextChangePanelForm(input, "Client"));
    }
  };
  const handleUpdateFunder = (orgId: number, orgName: string) => {
    dispatch(addFunderPanelForm(orgId, orgName));
  };
  const handleUpdateClient = (orgId: number, orgName: string) => {
    dispatch(addClientPanelForm(orgId, orgName));
  };

  const handleDeleteFunder = (orgId: number) => {
    dispatch(deleteFunderPanelForm(orgId));
  };
  const handleDeleteClient = (orgId: number) => {
    dispatch(deleteClientPanelForm(orgId));
  };
  const closePanelSetForm = () => {
    dispatch(showPanelSetForm(false, false));
  };

  const createBubble = (
    type: string,
    id: any,
    displayText: string,
    index: number
  ) => {
    return (
      <Grid
        key={`grid-${type}-${index}`}
        data-testid={`grid-${type}-${index}`}
        className={classes.bubble}
      >
        <Typography variant="body3Strong" className={classes.bubbleText}>
          {displayText}
        </Typography>
        <IconButton
          id={`btn-delete-${type}-${index}`}
          data-testid={`btn-delete-${type}-${index}`}
          className={classes.bubbleIcon}
          onClick={(ev) => {
            if (type === "funder") {
              handleDeleteFunder(id);
            } else if (type === "client") {
              handleDeleteClient(id);
            } else if (type === "prop-type") {
              handleDeletePropType(id);
            } else if (type === "service-type") {
              handleDeleteServiceType(id);
            }
            ev.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <PanelExDialog
      id="panelSet-form-dialog"
      data-testid="panelSet_form-dialog"
      open={openPanelSetForm}
      title={`${panelSetRevId ? "Edit Panel Setting" : "Create New Panel"}`}
      submitText={`${
        panelSetRevId ? "Update Panel Setting" : "Create New Panel"
      }`}
      submitEnabled={panelSubmitEnabled}
      onClose={closePanelSetForm}
      onSubmit={panelSetRevId > 0 ? handleEditPanelSet : handleCreatePanelSet}
      showAction={true}
    >
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "5",
          padding: "20px",
          paddingTop: "5px",
          minWidth: 620,
          minHeight: 400,
          background: "rgba(247, 247, 250, 1)",
        }}
      >
        <Grid container spacing={2} sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Grid size={6}>
            <TextField
              className={`${classes.textBox} ${name ? null : classes.error}`}
              id="panel-name"
              data-testid="panel-name"
              label="Enter Panel Name"
              size="small"
              value={name}
              fullWidth={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePanelNameChange(e.target.value as string)
              }
              error={!!error}
              helperText={error}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              className={classes.textBox}
              id="panel-note"
              data-testid="panel-note"
              label="Enter Notes"
              size="small"
              value={notes}
              fullWidth={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePanelNotesChange(e.target.value as string)
              }
            />
          </Grid>

          <Grid size={6}>
            <Grid
              container
              size={12}
              className={`${classes.gridContainer} ${classes.fixedHeight}`}
            >
              <Stack spacing={1} direction="row" className={classes.stack}>
                <FormControl fullWidth size="small">
                  <Select
                    labelId="panel-type-label"
                    id="panel-type"
                    data-testid="panel-type"
                    value={panelType}
                    className={classes.select}
                    onChange={(e: SelectChangeEvent) =>
                      handlePanelTypeChange(e.target.value as string)
                    }
                  >
                    <MenuItem value={"Normal"}>Fee Based Panel</MenuItem>
                    <MenuItem value={"Quote"}>Quote Panel</MenuItem>
                  </Select>
                </FormControl>
                <Tooltip title="Fee Based Panel utilises configured fees for each location. Quote Panel has no set fees. It requests a quote from valuation firms on the panel.">
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    sx={{ color: theme.colors.grey.light }}
                  />
                </Tooltip>
                <TextField
                  className={classes.textBox}
                  id="panel-order"
                  data-testid="panel-order"
                  label="Panel Order"
                  placeholder="Enter Panel Order"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  value={order}
                  fullWidth={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const input = e.target.value;
                    const regex = /^[0-9\b]+$/;
                    if (
                      input === "" ||
                      (regex.test(input) && parseInt(input) < 100)
                    ) {
                      handlePanelOrderChange(input);
                    }
                  }}
                />
                <Tooltip title="Panel Priority - A lower number signifies a higher priority panel">
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    sx={{ color: theme.colors.grey.light }}
                  />
                </Tooltip>
              </Stack>
              <Stack spacing={1} direction="row" className={classes.stack}>
                <Grid container size={12}>
                  <Grid size={6}>
                    <Stack
                      spacing={1}
                      direction="row"
                      className={classes.stack}
                    >
                      <Typography className={classes.label} variant="h4">
                        Panel In Use
                      </Typography>
                      <FormControlLabel
                        data-testid="panel-in-use"
                        id="panel-in-use"
                        className={classes.useRulexSwitch}
                        control={
                          <Switch
                            checked={statusBool}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handlePanelInUseChange(
                                e.target.checked as boolean
                              )
                            }
                          />
                        }
                        label={statusBool ? "Yes" : "No"}
                      />
                    </Stack>
                  </Grid>
                  <Grid size={6}>
                    <Stack
                      spacing={1}
                      direction="row"
                      className={classes.stack}
                    >
                      <Typography className={classes.label} variant="h4">
                        Use Rulex
                      </Typography>
                      <FormControlLabel
                        data-testid="use-rulex"
                        id="use-rulex"
                        className={classes.useRulexSwitch}
                        control={
                          <Switch
                            checked={useRuleEx}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleUseRulexChange(e.target.checked as boolean)
                            }
                          />
                        }
                        label={useRuleEx ? "Yes" : "No"}
                      />
                      <Tooltip title="RulEx is an allocation methodology based on an external reference file, using it will ignore any linked Allocation.">
                        <InfoOutlinedIcon
                          className={classes.infoIcon}
                          sx={{ color: theme.colors.grey.light }}
                        />
                      </Tooltip>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Grid size={6}>
            <Grid
              container
              size={12}
              className={`${classes.gridContainer} ${classes.fixedHeight}`}
            >
              <Stack spacing={1} direction="row" className={classes.stack}>
                <Typography className={classes.label} variant="h4">
                  Estimated Property Value Range
                </Typography>
                <Tooltip title="Estimated Property Value Range defines the property value for which this panel is to be applicable">
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    sx={{ color: theme.colors.info.dark }}
                  />
                </Tooltip>
              </Stack>

              <Stack spacing={1} direction="row" className={classes.stack}>
                <Tooltip title="Lower >=" placement="bottom-start">
                  <>
                    <TextField
                      className={classes.textBox}
                      id="panel-value-from"
                      data-testid="panel-value-from"
                      label="From"
                      placeholder="Any"
                      size="small"
                      value={bracketMin}
                      fullWidth={true}
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const input = e.target.value;
                        const regex = /^[0-9\b]+$/;
                        if (input === "" || regex.test(input)) {
                          handleBracketMinChange(input);
                        }
                      }}
                    />
                  </>
                </Tooltip>
                <Tooltip title="Upper <" placement="bottom-start">
                  <>
                    <TextField
                      className={`${classes.textBox} ${
                        parseInt(bracketMax) < parseInt(bracketMin)
                          ? classes.error
                          : null
                      }`}
                      id="panel-value-to"
                      data-testid="panel-value-to"
                      label="To"
                      placeholder="Enter Value"
                      size="small"
                      value={bracketMax}
                      fullWidth={true}
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const input = e.target.value;
                        const regex = /^[0-9\b]+$/;
                        if (input === "" || regex.test(input)) {
                          handleBracketMaxChange(input);
                        }
                      }}
                    />
                  </>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>

          <Grid size={12}>
            <Grid container size={12} className={classes.gridContainer}>
              <Stack spacing={1} direction="row" className={classes.stack}>
                <Typography
                  className={`${classes.label} ${classes.fixWidth}`}
                  variant="h4"
                >
                  Funders
                </Typography>
                <PanelExAutocomplete
                  id="org-funder-filter"
                  style={{ width: "100%" }}
                  validInput={
                    panelSetFunderDtoSet.length > 0 ||
                    panelSetClientDtoSet.length > 0
                  }
                  options={funderSuggestion}
                  value={funderSearchText}
                  popperMaxHeight={295}
                  getOptionLabel={(option: any) =>
                    option.name || funderSearchText || ""
                  }
                  placeholder="Search for Funders"
                  onInputChange={(value: any) => {
                    handleFunderInputChange(value);
                  }}
                  renderOption={(props: any, option: any) => {
                    const { id, name } = option;

                    const isExisting =
                      panelSetFunderDtoSet.find((v: any) => v.orgId === id) !==
                      undefined;
                    const regex = new RegExp(
                      escapedSpecialCharacters(funderSearchText),
                      "gi"
                    );
                    const response = name.replace(regex, function (str: any) {
                      return "<span style='font-weight:500'>" + str + "</span>";
                    });
                    return (
                      !isExisting && (
                        <li
                          {...props}
                          data-testid={`add-funder-label-${id}`}
                          id={`add-funder-label-${id}`}
                          onClick={(ev) => {
                            !isExisting && handleUpdateFunder(id, name);
                            ev.stopPropagation();
                          }}
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: `${response}` }}
                          ></div>
                          <AddCircleIcon
                            data-testid={`add-funder-icon-${id}`}
                            id={`add-funder-icon-${id}`}
                            style={{
                              ...{ color: "#757575" },
                            }}
                          />
                        </li>
                      )
                    );
                  }}
                />
              </Stack>
              <Grid container size={12}>
                {panelSetFunderDtoSet.map((org: any, index: number) =>
                  createBubble("funder", org.orgId, org.orgName, index)
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid size={12}>
            <Grid container size={12} className={classes.gridContainer}>
              <Grid container size={12}>
                <Stack spacing={1} direction="row" className={classes.stack}>
                  <Typography
                    className={`${classes.label} ${classes.fixWidth}`}
                    variant="h4"
                  >
                    Clients
                  </Typography>
                  <PanelExAutocomplete
                    id="org-client-filter"
                    style={{ width: "100%" }}
                    validInput={
                      panelSetFunderDtoSet.length > 0 ||
                      panelSetClientDtoSet.length > 0
                    }
                    options={clientSuggestion}
                    value={clientSearchText}
                    popperMaxHeight={295}
                    getOptionLabel={(option: any) =>
                      option.name || clientSearchText || ""
                    }
                    placeholder="Search for Clients"
                    onInputChange={(value: any) => {
                      handleClientInputChange(value);
                    }}
                    renderOption={(props: any, option: any) => {
                      const { id, name } = option;

                      const isExisting =
                        panelSetClientDtoSet.find(
                          (v: any) => v.orgId === id
                        ) !== undefined;
                      const regex = new RegExp(
                        escapedSpecialCharacters(clientSearchText),
                        "gi"
                      );
                      const response = name.replace(regex, function (str: any) {
                        return (
                          "<span style='font-weight:500' >" + str + "</span>"
                        );
                      });
                      return (
                        !isExisting && (
                          <li
                            {...props}
                            data-testid={`add-client-label-${id}`}
                            id={`add-client-label-${id}`}
                            onClick={(ev) => {
                              !isExisting && handleUpdateClient(id, name);
                              ev.stopPropagation();
                            }}
                            style={{ justifyContent: "space-between" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${response}`,
                              }}
                            ></div>
                            <AddCircleIcon
                              data-testid={`add-client-icon-${id}`}
                              id={`add-client-icon-${id}`}
                              style={{
                                ...{ color: "#757575" },
                              }}
                            />
                          </li>
                        )
                      );
                    }}
                  />
                </Stack>
                <Grid container size={12}>
                  {panelSetClientDtoSet.map((org: any, index: number) =>
                    createBubble("client", org.orgId, org.orgName, index)
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid size={12}>
            <Grid container size={12} className={classes.gridContainer}>
              <Grid container size={12}>
                <Stack spacing={1} direction="row" className={classes.stack}>
                  <Typography
                    className={`${classes.label} ${classes.fixWidth}`}
                    variant="h4"
                  >
                    Property Types
                  </Typography>
                  <PanelExAutocomplete
                    id="prop-type"
                    style={{ width: "100%" }}
                    options={propTypeSuggestions}
                    value={propTypeSearchText}
                    getOptionLabel={(option: any) =>
                      option.name || propTypeSearchText || ""
                    }
                    placeholder="Search Property Type"
                    onInputChange={(value: any) => {
                      handlePropTypeInputChange(value);
                    }}
                    renderOption={(props: any, option: any) => {
                      const { id, name } = option;
                      const isExistingPropType =
                        panelSetPropertyTypeDto.find(
                          (v: any) => v.propertyTypeId === id
                        ) !== undefined;
                      const regex = new RegExp(
                        escapedSpecialCharacters(propTypeSearchText),
                        "gi"
                      );
                      const response = name.replace(regex, function (str: any) {
                        return (
                          "<span style='font-weight:500'>" + str + "</span>"
                        );
                      });
                      return (
                        !isExistingPropType && (
                          <li
                            {...props}
                            data-testid={`add-prop-type-label-${id}`}
                            id={`add-prop-type-label-${id}`}
                            onClick={(ev) => {
                              !isExistingPropType &&
                                handleAddPropType(id, name);
                              ev.stopPropagation();
                            }}
                            style={{ justifyContent: "space-between" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${response}`,
                              }}
                            ></div>
                            <AddCircleIcon
                              data-testid={`add-prop-type-icon-${id}`}
                              id={`add-prop-type-icon-${id}`}
                              style={{
                                ...{ color: "#757575" },
                              }}
                            />
                          </li>
                        )
                      );
                    }}
                  />
                </Stack>
              </Grid>
              <Grid container size={12}>
                {panelSetPropertyTypeDto.map((propType: any, index: number) =>
                  createBubble(
                    "prop-type",
                    propType.propertyTypeId,
                    propType.propertyTypeName,
                    index
                  )
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid size={12}>
            <Grid container size={12} className={classes.gridContainer}>
              <Grid container size={12}>
                <Stack spacing={1} direction="row" className={classes.stack}>
                  <Typography
                    className={`${classes.label} ${classes.fixWidth}`}
                    variant="h4"
                  >
                    Service Types
                  </Typography>

                  <PanelExAutocomplete
                    id="service-type"
                    style={{ width: "100%" }}
                    validInput={panelSetServiceDtoSet.length > 0}
                    options={serviceTypeSuggestions}
                    value={serviceTypeSearchText}
                    getOptionLabel={(option: any) =>
                      option.name || serviceTypeSearchText || ""
                    }
                    placeholder="Search Service Type"
                    onInputChange={(value: any) => {
                      handleServiceTypeInputChange(value);
                    }}
                    renderOption={(props: any, option: any) => {
                      const { id, name } = option;
                      const isExistingServiceType =
                        panelSetServiceDtoSet.find(
                          (v: any) => v.serviceId === id
                        ) !== undefined;
                      const regex = new RegExp(
                        escapedSpecialCharacters(serviceTypeSearchText),
                        "gi"
                      );
                      const response = name.replace(regex, function (str: any) {
                        return (
                          "<span style='font-weight:500'>" + str + "</span>"
                        );
                      });
                      return (
                        !isExistingServiceType && (
                          <li
                            {...props}
                            data-testid={`add-service-type-label-${id}`}
                            id={`add-service-type-label-${id}`}
                            onClick={(ev) => {
                              !isExistingServiceType &&
                                handleAddServiceType(id, name);
                              ev.stopPropagation();
                            }}
                            style={{ justifyContent: "space-between" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${response}`,
                              }}
                            ></div>
                            <AddCircleIcon
                              data-testid={`add-service-type-icon-${id}`}
                              id={`add-service-type-icon-${id}`}
                              style={{
                                ...{ color: "#757575" },
                              }}
                            />
                          </li>
                        )
                      );
                    }}
                  />
                </Stack>
              </Grid>
              {panelSetServiceDtoSet.length > 0 && (
                <Grid container size={12} sx={{ marginTop: "16px" }}>
                  {panelSetServiceDtoSet.map(
                    (serviceType: any, index: number) =>
                      createBubble(
                        "service-type",
                        serviceType.serviceId,
                        serviceType.serviceName,
                        index
                      )
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PanelExDialog>
  );
}
