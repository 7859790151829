import {
  SUCCEED_CREATE_PANEL_SET,
  FAIL_CREATE_PANEL_SET,
  ERROR_CREATE_PANEL_SET,
  CHANGE_PANEL_TAB,
  SUCCEED_FETCH_PANEL_SET,
  SUCCEED_FETCH_PANEL_VERSION_DETAIL,
  ATTEMPT_FETCH_PANEL_SET,
  ATTEMPT_FETCH_PANELS,
  SUCCEED_FETCH_PANELS,
  FAIL_FETCH_PANELS,
  SHOW_PANEL_SET_FORM,
  UPDATE_PANEL_NAME_PANEL_FORM,
  UPDATE_PANEL_NOTES_PANEL_FORM,
  UPDATE_PANEL_TYPE_PANEL_FORM,
  UPDATE_PANEL_ORDER_PANEL_FORM,
  UPDATE_USE_RULEX_PANEL_FORM,
  UPDATE_BRACKET_MIN_PANEL_FORM,
  UPDATE_BRACKET_MAX_PANEL_FORM,
  ADD_PROP_TYPE_PANEL_FORM,
  DELETE_PROP_TYPE_PANEL_FORM,
  ADD_SERVICE_TYPE_PANEL_FORM,
  DELETE_SERVICE_TYPE_PANEL_FORM,
  ADD_FUNDER_PANEL_FORM,
  ADD_CLIENT_PANEL_FORM,
  DELETE_FUNDER_PANEL_FORM,
  DELETE_CLIENT_PANEL_FORM,
  SUCCEED_EDIT_PANEL_SET,
  FAIL_EDIT_PANEL_SET,
  ERROR_EDIT_PANEL_SET,
  ATTEMPT_FETCH_ALLOCATION_SUGGESTION,
  SUCCEED_FETCH_ALLOCATION_SUGGESTION,
  FAIL_FETCH_ALLOCATION_SUGGESTION,
  CLEAR_ALLOCATION_SUGGESTION,
  SUCCEED_UPDATE_PANEL_ALLOCATION,
  FAIL_FETCH_PANEL_ACTIVATION_LOGS,
  ATTEMPT_FETCH_PANEL_ACTIVATION_LOGS,
  SUCCEED_FETCH_PANEL_ACTIVATION_LOGS,
  CHANGE_PANEL_VERSION_ID,
  SUCCEED_FETCH_LATEST_PANEL_ACTIVATION_LOG,
  FAIL_FETCH_LATEST_PANEL_ACTIVATION_LOG,
  ATTEMPT_FETCH_FEE_SUGGESTION,
  SUCCEED_FETCH_FEE_SUGGESTION,
  FAIL_FETCH_FEE_SUGGESTION,
  CLEAR_FEE_SUGGESTION,
  SUCCEED_UPDATE_PANEL_FEE,
  ATTEMPT_FETCH_PANEL_VERSIONS,
  SUCCEED_FETCH_PANEL_VERSIONS,
  FAIL_FETCH_PANEL_VERSIONS,
  SUCCEED_UPDATE_PANEL_VERSION_NOTES,
  ATTEMPT_COPY_PANEL_VERSION,
  SUCCEED_COPY_PANEL_VERSION,
  FAIL_COPY_PANEL_VERSION,
  SUCCEED_SCHEDULE_PANEL_VERSION_ACTIVATION,
  FAIL_SCHEDULE_PANEL_VERSION_ACTIVATION,
  SUCCEED_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION,
  UPDATE_PANEL_IN_USE_PANEL_FORM,
  SUCCEED_DISCARD_PANEL_VERSION,
  CLEAR_VERSIONS_TO_COMPARE,
  SAVE_VERSIONS_TO_COMPARE,
  SHOW_VERSIONS_COMPARISON,
  ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS,
  FAIL_FETCH_VERSIONS_COMPARISON_DETAILS,
  SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS,
  OPEN_FILTER,
  FAIL_FETCH_FILTER_NAME_SUGGESTION,
  SUCCEED_FETCH_FILTER_NAME_SUGGESTION,
  ATTEMPT_FETCH_ORG_SUGGESTION_FILTER,
  SUCCEED_FETCH_ORG_SUGGESTION_FILTER,
  FAIL_FETCH_ORG_SUGGESTION_FILTER,
  CLEAR_ORG_SUGGESTION_FILTER,
  ADD_VALFIRM_FILTER,
  DELETE_VALFIRM_FILTER,
  DELETE_CLIENT_FILTER,
  ADD_CLIENT_FILTER,
  ADD_FUNDER_FILTER,
  DELETE_FUNDER_FILTER,
  ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_FILTER,
  SUCCEED_FETCH_PROP_TYPE_SUGGESTION_FILTER,
  CLEAR_PROP_TYPE_SUGGESTION_FILTER,
  FAIL_FETCH_PROP_TYPE_SUGGESTION_FILTER,
  ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_FILTER,
  SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_FILTER,
  CLEAR_SERVICE_TYPE_SUGGESTION_FILTER,
  FAIL_FETCH_SERVICE_TYPE_SUGGESTION_FILTER,
  ADD_PROP_TYPE_FILTER,
  DELETE_PROP_TYPE_FILTER,
  ADD_SERVICE_TYPE_FILTER,
  DELETE_SERVICE_TYPE_FILTER,
  UPDATE_PANEL_TYPE_FILTER,
  UPDATE_BRACKET_MAX_FILTER,
  UPDATE_BRACKET_MIN_FILTER,
  UPDATE_SHOW_MORE_FILTER,
  ATTEMPT_FETCH_FILTER_NAME_SUGGESTION,
  CLEAR_FILTER_NAME_SUGGESTION,
  CLEAR_ALL_FILTER,
  COPY_FILTER,
  ADD_ALLOCATION_LOCATION_FILTER,
  DELETE_ALLOCATION_LOCATION_FILTER,
  ATTEMPT_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION,
  SUCCEED_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION,
  FAIL_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION,
  CLEAR_FILTER_ALLOCATION_NAME_SUGGESTION,
  CHANGE_ALLOCATION_FILTER_COUNTRY,
  ATTEMPT_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION,
  SUCCEED_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION,
  FAIL_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION,
  CLEAR_FILTER_ALLOCATION_LOCATION_SUGGESTION,
  ADD_FEE_LOCATION_FILTER,
  DELETE_FEE_LOCATION_FILTER,
  ATTEMPT_FETCH_FILTER_FEE_NAME_SUGGESTION,
  SUCCEED_FETCH_FILTER_FEE_NAME_SUGGESTION,
  FAIL_FETCH_FILTER_FEE_NAME_SUGGESTION,
  CLEAR_FILTER_FEE_NAME_SUGGESTION,
  CHANGE_FEE_FILTER_COUNTRY,
  ATTEMPT_FETCH_FILTER_FEE_LOCATION_SUGGESTION,
  SUCCEED_FETCH_FILTER_FEE_LOCATION_SUGGESTION,
  FAIL_FETCH_FILTER_FEE_LOCATION_SUGGESTION,
  CLEAR_FILTER_FEE_LOCATION_SUGGESTION,
  SUCCEED_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION,
  UPDATE_PANEL_STATUS_FILTER,
  SAVE_PANEL_TO_BULK_UPDATE,
  OPEN_BULK_UPDATE,
  BULK_UPDATE_BACK_TO_PREVIOUS,
  BULK_UPDATE_SELECT_VERSION,
  CANCEL_BULK_UPDATE,
  ATTEMPT_FETCH_BULK_UPDATE_INFO,
  SUCCEED_FETCH_BULK_UPDATE_INFO,
  FAIL_FETCH_BULK_UPDATE_INFO,
  UPDATE_BULK_UPDATE_SELECTED_VERSION,
  SET_BULK_UPDATE_CHANGE_TYPE,
  REPLACE_VALFIRM_FETCH_EXISTING_VALFIRM_SUGGESTION,
  REPLACE_VALFIRM_SET_EXISTING_VALFIRM,
  ATTEMPT_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION,
  SUCCEED_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION,
  REPLACE_VALFIRM_SET_NEW_VALFIRM,
  ATTEMPT_FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
  SUCCEED_FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
  SET_DISTRIBUTION_METHOD,
  SET_IS_INCLUDE_BACKUP,
  ADD_VALFIRM_SET_ALLOCATION,
  ATTEMPT_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION,
  SUCCEED_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION,
  ADD_VALFIRM_SET_VALFIRM,
  REMOVE_VALFIRM_SET_VALFIRM,
  REMOVE_VALFIRM_FETCH_SUGGESTION,
  CHANGE_ALLOCATION_FETCH_VALFIRM_SUGGESTION,
  CHANGE_ALLOCATION_SET_VALFIRM,
  CHANGE_ALLOCATION_SET_ALLOCATION,
  CHANGE_ALLOCATION_SET_BY,
  ATTEMPT_BULK_UPDATE_PREVIEW_DATA,
  SUCCEED_BULK_UPDATE_PREVIEW_DATA,
  FAIL_BULK_UPDATE_PREVIEW_DATA,
  SUCCEED_BULK_UPDATE_SAVE_AS_DRAFT,
  CLEAR_BULK_UPDATE_SAVE_AS_DRAFT,
  FAIL_BULK_UPDATE_SAVE_AS_DRAFT,
  ATTEMPT_BULK_UPDATE_SAVE_AS_DRAFT,
  BULK_UPDATE_SELECT_LOCATIONS,
  SUCCEED_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
  FAIL_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
  UPDATE_BULK_UPDATE_LOCATION_TEXT,
  ADD_BULK_UPDATE_LOCATION,
  DELETE_BULK_UPDATE_LOCATION,
  ADD_ALL_BULK_UPDATE_LOCATION,
  DELETE_ALL_BULK_UPDATE_LOCATION,
  SET_SELECTED_PANEL,
  OPEN_ASSOCIATED_PANELS,
  SUCCEED_FETCH_ASSOCIATED_PANELS,
  FAIL_FETCH_ASSOCIATED_PANELS,
  ADD_PREVIEW_PANEL_ID,
} from "../staticSagas/sagaPanel";
import { SUCCEED_RESET_PANEL_ALLOCATION } from "../staticSagas/sagaAllocation";
import { SUCCEED_RESET_PANEL_FEE } from "../staticSagas/sagaFee";
import { UPDATE_SEARCH_OPTION } from "../staticSagas/sagaUI";

const initialPanelSet = {
  panelSetId: 0,
  panelSetRevId: 0,
  active: false,
  bracketMin: 0,
  bracketMax: 0,
  lastUsed: null,
  panelType: "Normal",
  name: "",
  percentChange: 0,
  processOrder: 0,
  version: 0,
  allocationPanelId: 0,
  allocationPanelRevId: 0,
  feePanelId: 0,
  feePanelRevId: 0,
  useRuleEx: false,
  editableRevId: 0,
};
const initialNewPanelSet = {
  panelSetRevId: 0,
  name: "",
  notes: "",
  panelType: "Normal",
  order: "",
  useRuleEx: false,
  statusBool: false,
  bracketMin: "",
  bracketMax: "",
  panelSetFunderDtoSet: [],
  panelSetClientDtoSet: [],
  panelSetPropertyTypeDto: [],
  panelSetServiceDtoSet: [],
  loading: false,
  newPanelId: 0,
  error: null,
  funderSearchText: null,
  funderSuggestion: [],
  clientSearchText: null,
  clientSuggestion: [],
};
const initialVersionCompare = {
  versionCompare: [],
  versionCompareDetails: {},
  showVersionCompare: false,
  versionCompareLoading: false,
};
const initialPanelVersion = {
  panelVersions: [],
  panelVersionCount: 0,
  currentPage: 0,
  pageSize: 15,
  loading: false,
  ...initialVersionCompare,
};
const initialFilterOptions = {
  nameText: "",
  nameSuggestion: [],
  countryId: 1,
  status: "In Use",
  isFiltered: false,
  isOpenFilter: false,
  funderText: "",
  funderSuggestion: [],
  funders: [],
  clientText: "",
  clientSuggestion: [],
  clients: [],
  valfirmText: "",
  valfirmSuggestion: [],
  valfirms: [],
  keywordValfirms: [],
  propTypeSearchText: "",
  propTypeSuggestion: [],
  propertyTypes: [],
  serviceTypeSearchText: "",
  serviceTypeSuggestion: [],
  serviceTypes: [],
  panelType: "Any",
  bracketMin: "",
  bracketMax: "",
  showMore: false,
  allocationNameText: "",
  allocationNameSuggestion: [],
  allocationCountryId: 1,
  allocationLocationText: "",
  allocationLocationSuggestion: [],
  allocationLocations: [],
  feeNameText: "",
  feeNameSuggestion: [],
  feeCountryId: 1,
  feeLocationText: "",
  feeLocationSuggestion: [],
  feeLocations: [],
};

const initialFilteredOptions = {
  name: "",
  status: "In Use",
  nameSuggestion: [],
  countryId: 1,
  funderText: "",
  funderSuggestion: [],
  funders: [],
  clientText: "",
  clientSuggestion: [],
  clients: [],
  valfirmText: "",
  valfirmSuggestion: [],
  valfirms: [],
  keywordValfirms: [],
  propTypeSearchText: "",
  propTypeSuggestion: [],
  propertyTypes: [],
  serviceTypeSearchText: "",
  serviceTypeSuggestion: [],
  serviceTypes: [],
  panelType: "Any",
  bracketMin: "",
  bracketMax: "",
  showMore: false,
  allocationNameText: "",
  allocationNameSuggestion: [],
  allocationCountryId: 1,
  allocationLocationText: "",
  allocationLocationSuggestion: [],
  allocationLocations: [],
  feeNameText: "",
  feeNameSuggestion: [],
  feeCountryId: 1,
  feeLocationText: "",
  feeLocationSuggestion: [],
  feeLocations: [],
};

export const initialChangeTypeChangeAlloc = {
  valfirmText: "",
  valfirmSuggestion: [],
  valfirmId: 0,
  changeAllocationBy: "",
  allocation: "",
};
export const initialChangeTypeAddValfirm = {
  valfirmText: "",
  valfirmSuggestion: [],
  valfirmId: 0,
  allocation: "",
};
export const initialChangeTypeRemoveValfirm = {
  valfirmText: "",
  valfirmSuggestion: [],
  valfirmId: 0,
};
export const initialChangeTypeReplaceValfirm = {
  existingValfirmText: "",
  existingValfirmSuggestion: [],
  existingValfirmId: null,
  newValfirmText: "",
  newValfirmSuggestion: [],
  newValfirmId: null,
};
const initialBulkUpdate = {
  allocations: [],
  isOpenBulkUpdate: false,
  bulkUpdateStep: 0,
  existingValfirms: [],
  changeType: "",
  changeTypeChangeAlloc: initialChangeTypeChangeAlloc,
  changeTypeAddValfirm: initialChangeTypeAddValfirm,
  changeTypeRemoveValfirm: initialChangeTypeRemoveValfirm,
  changeTypeReplaceValfirm: initialChangeTypeReplaceValfirm,
  isIncludeBackup: false,
  distributionMethod: "",
  versionLoading: false,
  previewLoading: false,
  previewData: [],
  success: false,
  addAnotherBulkUpdate: false,
  locationText: "",
  locationSuggestion: [],
  locations: [],
  isOpenAssociatedPanels: false,
  associatedPanels: [],
  previewPanelIds: [],
};

export const initialState = {
  panels: [],
  currentPage: 0,
  pageSize: 15,
  openPanelSetForm: false,
  panelSet: initialPanelSet,
  panelVersionDetail: {},
  panelVersionId: 0,
  openBackdrop: false,
  latestPanelActivationLog: {},
  panelVersion: initialPanelVersion,
  activationLog: {
    activationLogs: [],
    activationLogCount: 0,
    currentPage: 0,
    pageSize: 15,
    sortBy: "requested",
    sortDir: "desc",
    loading: false,
  },
  panelTab: 0,
  panelSetForm: initialNewPanelSet,
  filterOptions: initialFilterOptions,
  filteredOptions: initialFilteredOptions,
  allocSearchText: "",
  allocSuggestion: [],
  feeSearchText: "",
  feeSuggestion: [],
  selectedPanels: [],
  bulkUpdate: initialBulkUpdate,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SEARCH_OPTION:
      return {
        ...state,
        filterOptions: initialFilterOptions,
        filteredOptions: initialFilteredOptions,
      };
    case ATTEMPT_FETCH_PANELS:
      return {
        ...state,
        loading: true,
        statusFilter: action.data.status,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        sortBy: action.data.sortBy,
        sortDir: action.data.sortDir,
        filterOptions: {
          ...state.filterOptions,
          isFiltered: action.data.isFiltered,
        },
      };
    case SUCCEED_FETCH_PANELS:
      return {
        ...state,
        panels: action.data.panels,
        panelCount: action.data.totalCount,
        loading: false,
        filteredOptions: {
          ...state.filteredOptions,
          name: action.data.name,
          status: action.data.status,
          nameSuggestion: action.data.nameSuggestion,
          countryId: action.data.countryId,
          funderText: action.data.funderText,
          funderSuggestion: action.data.funderSuggestion,
          funders: action.data.funders,
          clientText: action.data.clientText,
          clientSuggestion: action.data.clientSuggestion,
          clients: action.data.clients,
          valfirmText: action.data.valfirmText,
          valfirmSuggestion: action.data.valfirmSuggestion,
          valfirms: action.data.valfirms,
          keywordValfirms: action.data.keywordValfirms,
          propTypeSearchText: action.data.propTypeSearchText,
          propTypeSuggestion: action.data.propTypeSuggestion,
          propertyTypes: action.data.propertyTypes,
          serviceTypeSearchText: action.data.serviceTypeSearchText,
          serviceTypeSuggestion: action.data.serviceTypeSuggestion,
          serviceTypes: action.data.serviceTypes,
          panelType: action.data.panelType,
          bracketMin: action.data.bracketMin,
          bracketMax: action.data.bracketMax,
          showMore: action.data.showMore,
          allocationNameText: action.data.allocationNameText,
          allocationNameSuggestion: action.data.allocationNameSuggestion,
          allocationCountryId: action.data.allocationCountryId,
          allocationLocationText: action.data.allocationLocationText,
          allocationLocationSuggestion:
            action.data.allocationLocationSuggestion,
          allocationLocations: action.data.allocationLocations,
          feeNameText: action.data.feeNameText,
          feeNameSuggestion: action.data.feeNameSuggestion,
          feeCountryId: action.data.feeCountryId,
          feeLocationText: action.data.feeLocationText,
          feeLocationSuggestion: action.data.feeLocationSuggestion,
          feeLocations: action.data.feeLocations,
        },
      };
    case FAIL_FETCH_PANELS:
      return {
        ...state,
        panels: [],
        panelCount: 0,
      };
    case SUCCEED_CREATE_PANEL_SET:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          newPanelId: action.payload.id,
        },
        openPanelSetForm: false,
        panelVersionId: action.payload.panelSetRevId,
      };
    case FAIL_CREATE_PANEL_SET:
      return {
        ...state,
        panelSetForm: initialNewPanelSet,
      };
    case ERROR_CREATE_PANEL_SET:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          error: action.error,
        },
      };
    case SHOW_PANEL_SET_FORM:
      let panelSetForm = initialNewPanelSet;
      if (action.payload.open) {
        panelSetForm = action.payload.isNewPanel
          ? initialNewPanelSet
          : state.panelVersionDetail;
      }
      return {
        ...state,
        openPanelSetForm: action.payload.open,
        panelSetForm,
      };
    case UPDATE_PANEL_NAME_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          name: action.payload,
        },
      };
    case UPDATE_PANEL_NOTES_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          notes: action.payload,
        },
      };
    case UPDATE_PANEL_TYPE_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelType: action.payload,
        },
      };
    case UPDATE_PANEL_ORDER_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          order: action.payload,
        },
      };
    case UPDATE_USE_RULEX_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          useRuleEx: action.payload,
        },
      };
    case UPDATE_PANEL_IN_USE_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          statusBool: action.payload,
        },
      };
    case UPDATE_BRACKET_MIN_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          bracketMin: action.payload,
        },
      };
    case UPDATE_BRACKET_MAX_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          bracketMax: action.payload,
        },
      };
    case ADD_PROP_TYPE_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetPropertyTypeDto: [
            ...state.panelSetForm.panelSetPropertyTypeDto,
            action.payload,
          ],
        },
      };
    case DELETE_PROP_TYPE_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetPropertyTypeDto:
            state.panelSetForm.panelSetPropertyTypeDto.filter(
              (propType) => propType.propertyTypeId !== action.propertyTypeId
            ),
        },
      };
    case ADD_SERVICE_TYPE_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetServiceDtoSet: [
            ...state.panelSetForm.panelSetServiceDtoSet,
            action.payload,
          ],
        },
      };
    case DELETE_SERVICE_TYPE_PANEL_FORM:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetServiceDtoSet:
            state.panelSetForm.panelSetServiceDtoSet.filter(
              (service) => service.serviceId !== action.serviceId
            ),
        },
      };
    case ADD_FUNDER_PANEL_FORM: {
      const { orgId, value } = action.payload;
      const isEdit =
        state.panelSetForm.panelSetFunderDtoSet.find(
          (v) => v.orgId === orgId
        ) !== undefined;
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetFunderDtoSet: isEdit
            ? state.panelSetForm.panelSetFunderDtoSet.map((v) =>
                v.orgId === orgId
                  ? {
                      ...v,
                      value: value,
                    }
                  : v
              )
            : [...state.panelSetForm.panelSetFunderDtoSet, action.payload],
        },
      };
    }
    case ADD_CLIENT_PANEL_FORM: {
      const { orgId, value } = action.payload;
      const isEdit =
        state.panelSetForm.panelSetClientDtoSet.find(
          (v) => v.orgId === orgId
        ) !== undefined;
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetClientDtoSet: isEdit
            ? state.panelSetForm.panelSetClientDtoSet.map((v) =>
                v.orgId === orgId
                  ? {
                      ...v,
                      value: value,
                    }
                  : v
              )
            : [...state.panelSetForm.panelSetClientDtoSet, action.payload],
        },
      };
    }
    case DELETE_FUNDER_PANEL_FORM: {
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetFunderDtoSet: state.panelSetForm.panelSetFunderDtoSet.filter(
            (firm) => firm.orgId !== action.payload.orgId
          ),
        },
      };
    }
    case DELETE_CLIENT_PANEL_FORM: {
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          panelSetClientDtoSet: state.panelSetForm.panelSetClientDtoSet.filter(
            (firm) => firm.orgId !== action.payload.orgId
          ),
        },
      };
    }
    case SUCCEED_EDIT_PANEL_SET:
      return {
        ...state,
        panelVersionDetail: {
          ...state.panelVersionDetail,
          ...action.payload,
        },
        panelSetForm: initialNewPanelSet,
        openPanelSetForm: false,
      };
    case FAIL_EDIT_PANEL_SET:
      return {
        ...state,
        panelSetForm: initialNewPanelSet,
      };
    case ERROR_EDIT_PANEL_SET:
      return {
        ...state,
        panelSetForm: {
          ...state.panelSetForm,
          error: action.error,
        },
      };
    case CHANGE_PANEL_TAB:
      return {
        ...state,
        panelTab: action.index,
      };
    case ATTEMPT_FETCH_PANEL_SET:
      return {
        ...state,
        panelSet: initialPanelSet,
        panelVersionDetail: {},
        panelSetForm: initialNewPanelSet,
        panelVersionId: 0,
        selectedPanels: [],
      };
    case SUCCEED_FETCH_PANEL_SET:
      return {
        ...state,
        panelSet: action.data,
        panelVersionId: action.data.panelSetRevId,
        bulkUpdate: initialBulkUpdate,
      };
    case SUCCEED_FETCH_PANEL_VERSION_DETAIL: {
      const latestActivationLogId = Math.max(
        ...action.data.panelSetActivationLogs.map(
          (p) => p.panelSetActivationLogId
        ),
        0
      );
      return {
        ...state,
        panelVersionDetail: {
          ...action.data,
          latestActivationLogId,
        },
      };
    }
    case ATTEMPT_FETCH_ALLOCATION_SUGGESTION:
      return {
        ...state,
        allocSearchText: action.text,
      };
    case SUCCEED_FETCH_ALLOCATION_SUGGESTION:
      return {
        ...state,
        allocSuggestion: action.data,
      };
    case FAIL_FETCH_ALLOCATION_SUGGESTION:
      return {
        ...state,
        allocSuggestion: [],
      };
    case CLEAR_ALLOCATION_SUGGESTION:
      return {
        ...state,
        allocSuggestion: [],
      };
    case SUCCEED_UPDATE_PANEL_ALLOCATION:
      return {
        ...state,
        panelVersionDetail: action.data,
      };
    case SUCCEED_RESET_PANEL_ALLOCATION:
      return {
        ...state,
        panelVersionDetail: {
          ...state.panelVersionDetail,
          allocationSummaryDto: null,
        },
      };
    case ATTEMPT_FETCH_PANEL_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
        panelVersion: {
          ...state.panelVersion,
          ...initialVersionCompare,
        },
      };
    case SUCCEED_FETCH_PANEL_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: false,
          activationLogs: action.data.activationLogs,
          activationLogCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_PANEL_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: false,
          activationLogs: [],
          activationLogCount: 0,
        },
      };
    case CHANGE_PANEL_VERSION_ID:
      return {
        ...state,
        panelVersionId: action.id,
      };
    case SUCCEED_FETCH_LATEST_PANEL_ACTIVATION_LOG:
      const { status, panelSetRevId } = action.data;
      return {
        ...state,
        latestPanelActivationLog: action.data,
        panelSet: {
          ...state.panelSet,
          panelSetRevId:
            status === "Processed"
              ? panelSetRevId
              : state.panelSet.panelSetRevId,
          editableRevId:
            status === "Processed" &&
            state.panelSet.editableRevId === panelSetRevId
              ? 0
              : state.panelSet.editableRevId,
        },
      };
    case FAIL_FETCH_LATEST_PANEL_ACTIVATION_LOG:
      return {
        ...state,
        latestPanelActivationLog: {},
      };
    case ATTEMPT_FETCH_FEE_SUGGESTION:
      return {
        ...state,
        feeSearchText: action.text,
      };
    case SUCCEED_FETCH_FEE_SUGGESTION:
      return {
        ...state,
        feeSuggestion: action.data,
      };
    case FAIL_FETCH_FEE_SUGGESTION:
      return {
        ...state,
        feeSuggestion: [],
      };
    case CLEAR_FEE_SUGGESTION:
      return {
        ...state,
        feeSuggestion: [],
      };
    case SUCCEED_UPDATE_PANEL_FEE:
      return {
        ...state,
        panelVersionDetail: action.data,
      };
    case SUCCEED_RESET_PANEL_FEE:
      return {
        ...state,
        panelVersionDetail: {
          ...state.panelVersionDetail,
          feeSummaryDto: null,
        },
      };
    case ATTEMPT_FETCH_PANEL_VERSIONS:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
      };
    case SUCCEED_FETCH_PANEL_VERSIONS:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          loading: false,
          panelVersions: action.data.panelRevs,
          panelVersionCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_PANEL_VERSIONS:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          loading: false,
          panelVersions: [],
          panelVersionCount: 0,
        },
      };
    case SUCCEED_UPDATE_PANEL_VERSION_NOTES:
      const panelVersions = state.panelVersion.panelVersions.map((version) => {
        if (version.panelSetRevId === action.data.panelVersionId) {
          version.notes = action.data.notes;
        }
        return version;
      });
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          panelVersions,
        },
      };
    case ATTEMPT_COPY_PANEL_VERSION:
      return {
        ...state,
        openBackdrop: true,
      };
    case SUCCEED_COPY_PANEL_VERSION:
      return {
        ...state,
        panelVersionId: action.data.panelVersionId,
        panelTab: 0,
        panelSet: {
          ...state.panelSet,
          editableRevId: action.data.panelVersionId,
        },
        openBackdrop: false,
      };
    case FAIL_COPY_PANEL_VERSION:
      return {
        ...state,
        openBackdrop: false,
      };
    case SUCCEED_SCHEDULE_PANEL_VERSION_ACTIVATION:
      return {
        ...state,
        latestPanelActivationLog: action.data,
      };
    case FAIL_SCHEDULE_PANEL_VERSION_ACTIVATION:
      return {
        ...state,
        latestPanelActivationLog: {},
      };
    case SUCCEED_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION:
      return {
        ...state,
        latestPanelActivationLog: {
          ...state.latestPanelActivationLog,
          status: "Canceled",
        },
      };
    case SUCCEED_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION:
      return {
        ...state,
        latestPanelActivationLog: {
          ...state.latestPanelActivationLog,
          status: "Acknowledged",
        },
      };
    case SUCCEED_DISCARD_PANEL_VERSION:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          panelVersions: state.panelVersion.panelVersions.filter(
            (version) => version.panelSetRevId !== action.data.panelSetRevId
          ),
          panelVersionCount: state.panelVersion.panelVersionCount - 1,
        },
        panelVersionId: state.panelSet.panelSetRevId,
        panelSet: {
          ...state.panelSet,
          hasEditable: false,
          editableRevId: null,
        },
      };
    case SAVE_VERSIONS_TO_COMPARE: {
      const { panelVersionId, selected } = action.payload;
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          versionCompare: selected
            ? [...state.panelVersion.versionCompare, panelVersionId]
            : [
                ...state.panelVersion.versionCompare.filter(
                  (v) => v !== panelVersionId
                ),
              ],
        },
      };
    }
    case CLEAR_VERSIONS_TO_COMPARE: {
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          versionCompare: [],
        },
      };
    }
    case SHOW_VERSIONS_COMPARISON:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          showVersionCompare: action.show,
        },
      };
    case ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          versionCompareLoading: true,
          versionCompareDetails: {},
        },
      };
    case SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          versionCompareLoading: false,
          versionCompareDetails: action.data,
        },
      };
    case FAIL_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        panelVersion: {
          ...state.panelVersion,
          versionCompareLoading: false,
          versionCompareDetails: {},
        },
      };

    case ATTEMPT_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: action.data,
        },
      };
    case FAIL_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: [],
        },
      };
    case CLEAR_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: [],
        },
      };

    case CLEAR_ALL_FILTER:
      return {
        ...state,
        filterOptions: {
          ...initialFilterOptions,
          isFiltered: state.filterOptions.isFiltered,
          isOpenFilter: state.filterOptions.isOpenFilter,
        },
      };
    case OPEN_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          isOpenFilter: action.isOpen,
        },
      };
    case ATTEMPT_FETCH_ORG_SUGGESTION_FILTER:
      if (action.orgType === "Funder") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            funderText: action.text,
          },
        };
      } else if (action.orgType === "Client") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            clientText: action.text,
          },
        };
      } else {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            valfirmText: action.text,
          },
        };
      }
    case SUCCEED_FETCH_ORG_SUGGESTION_FILTER:
      if (action.orgType === "Funder") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            funderSuggestion: action.data,
          },
        };
      } else if (action.orgType === "Client") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            clientSuggestion: action.data,
          },
        };
      } else {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            valfirmSuggestion: action.data,
          },
        };
      }
    case FAIL_FETCH_ORG_SUGGESTION_FILTER:
      if (action.orgType === "Funder") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            funderSuggestion: [],
          },
        };
      } else if (action.orgType === "Client") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            clientSuggestion: [],
          },
        };
      } else {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            valfirmSuggestion: [],
          },
        };
      }
    case CLEAR_ORG_SUGGESTION_FILTER:
      if (action.orgType === "Funder") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            funderSuggestion: [],
          },
        };
      } else if (action.orgType === "Client") {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            clientSuggestion: [],
          },
        };
      } else {
        return {
          ...state,
          filterOptions: {
            ...state.filterOptions,
            valfirmSuggestion: [],
          },
        };
      }
    case ADD_FUNDER_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          funders: [...state.filterOptions.funders, action.payload],
        },
      };
    case DELETE_FUNDER_FILTER: {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          funders: state.filterOptions.funders.filter(
            (firm) => firm.id !== action.payload.id
          ),
        },
      };
    }
    case ADD_CLIENT_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          clients: [...state.filterOptions.clients, action.payload],
        },
      };
    case DELETE_CLIENT_FILTER: {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          clients: state.filterOptions.clients.filter(
            (firm) => firm.id !== action.payload.id
          ),
        },
      };
    }
    case ADD_VALFIRM_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirms: [...state.filterOptions.valfirms, action.payload],
          keywordValfirms:
            action.payload.id === 0
              ? state.filterOptions.valfirmSuggestion
              : state.filterOptions.keywordValfirms,
        },
      };
    case DELETE_VALFIRM_FILTER: {
      let suggestion = state.filterOptions.valfirmSuggestion;
      const newValfirms = state.filterOptions.valfirms.filter(
        (firm) => firm.id !== action.payload.id
      );
      if (action.payload.id === 0 && newValfirms.length > 0) {
        suggestion.unshift({
          id: 0,
          name: 'Add "' + state.filterOptions.valfirmText + '"',
        });
      }
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirms: newValfirms,
          valfirmSuggestion: suggestion,
          keywordValfirms:
            action.payload.id === 0 ? [] : state.filterOptions.keywordValfirms,
        },
      };
    }
    case ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          propTypeSearchText: action.text,
        },
      };
    case SUCCEED_FETCH_PROP_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          propTypeSuggestion: action.data,
        },
      };
    case FAIL_FETCH_PROP_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          propTypeSuggestion: [],
        },
      };
    case CLEAR_PROP_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          propTypeSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          serviceTypeSearchText: action.text,
        },
      };
    case SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          serviceTypeSuggestion: action.data,
        },
      };
    case FAIL_FETCH_SERVICE_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          serviceTypeSuggestion: [],
        },
      };
    case CLEAR_SERVICE_TYPE_SUGGESTION_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          serviceTypeSuggestion: [],
        },
      };

    case ADD_PROP_TYPE_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          propertyTypes: [...state.filterOptions.propertyTypes, action.payload],
        },
      };
    case DELETE_PROP_TYPE_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          propertyTypes: state.filterOptions.propertyTypes.filter(
            (prop) => prop.id !== action.id
          ),
        },
      };
    case ADD_SERVICE_TYPE_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          serviceTypes: [...state.filterOptions.serviceTypes, action.payload],
        },
      };
    case DELETE_SERVICE_TYPE_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          serviceTypes: state.filterOptions.serviceTypes.filter(
            (service) => service.id !== action.id
          ),
        },
      };
    case UPDATE_PANEL_TYPE_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          panelType: action.payload,
        },
      };
    case UPDATE_PANEL_STATUS_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          panelSetStatus: action.payload,
        },
      };
    case UPDATE_BRACKET_MIN_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          bracketMin: action.payload,
        },
      };
    case UPDATE_BRACKET_MAX_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          bracketMax: action.payload,
        },
      };
    case UPDATE_SHOW_MORE_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          showMore: action.payload,
        },
      };
    case COPY_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameText: state.filteredOptions.name,
          status: state.filteredOptions.status,
          nameSuggestion: state.filteredOptions.nameSuggestion,
          countryId: state.filteredOptions.countryId,
          funderText: state.filteredOptions.funderText,
          funderSuggestion: state.filteredOptions.funderSuggestion,
          funders: state.filteredOptions.funders,
          clientText: state.filteredOptions.clientText,
          clientSuggestion: state.filteredOptions.clientSuggestion,
          clients: state.filteredOptions.clients,
          valfirmText: state.filteredOptions.valfirmText,
          valfirmSuggestion: state.filteredOptions.valfirmSuggestion,
          valfirms: state.filteredOptions.valfirms,
          keywordValfirms: state.filteredOptions.keywordValfirms,
          propTypeSearchText: state.filteredOptions.propTypeSearchText,
          propTypeSuggestion: state.filteredOptions.propTypeSuggestion,
          propertyTypes: state.filteredOptions.propertyTypes,
          serviceTypeSearchText: state.filteredOptions.serviceTypeSearchText,
          serviceTypeSuggestion: state.filteredOptions.serviceTypeSuggestion,
          serviceTypes: state.filteredOptions.serviceTypes,
          panelType: state.filteredOptions.panelType,
          bracketMin: state.filteredOptions.bracketMin,
          bracketMax: state.filteredOptions.bracketMax,
          showMore: state.filteredOptions.showMore,
          allocationNameText: state.filteredOptions.allocationNameText,
          allocationNameSuggestion:
            state.filteredOptions.allocationNameSuggestion,
          allocationCountryId: state.filteredOptions.allocationCountryId,
          allocationLocationText: state.filteredOptions.allocationLocationText,
          allocationLocationSuggestion:
            state.filteredOptions.allocationLocationSuggestion,
          allocationLocations: state.filteredOptions.allocationLocations,
          feeNameText: state.filteredOptions.feeNameText,
          feeNameSuggestion: state.filteredOptions.feeNameSuggestion,
          feeCountryId: state.filteredOptions.feeCountryId,
          feeLocationText: state.filteredOptions.feeLocationText,
          feeLocationSuggestion: state.filteredOptions.feeLocationSuggestion,
          feeLocations: state.filteredOptions.feeLocations,
        },
      };

    case ATTEMPT_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationNameText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationNameSuggestion: action.data,
        },
      };
    case FAIL_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationNameSuggestion: [],
        },
      };
    case CLEAR_FILTER_ALLOCATION_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationNameSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationLocationText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION: {
      const existingKeys = state.filterOptions.allocationLocations.map(
        (v) => v.id + "-" + v.type + "-" + v.countryId
      );

      const suggestion = action.data.map((v) => {
        const locationIdNew = v.id + "-" + v.type + "-" + v.countryId;
        return {
          ...v,
          isExisting: existingKeys.includes(locationIdNew),
        };
      });

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationLocationSuggestion: suggestion,
        },
      };
    }
    case FAIL_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationLocationSuggestion: [],
        },
      };
    case CLEAR_FILTER_ALLOCATION_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationLocationSuggestion: [],
        },
      };

    case ADD_ALLOCATION_LOCATION_FILTER: {
      const updatedSuggestion =
        state.filterOptions.allocationLocationSuggestion.map((v) => {
          return {
            ...v,
            isExisting:
              v.id === action.location.id &&
              v.type === action.location.type &&
              v.countryId === action.location.countryId
                ? true
                : v.isExisting,
          };
        });
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationLocations: [
            ...state.filterOptions.allocationLocations,
            { ...action.location, isExisting: true },
          ],
          allocationLocationSuggestion: updatedSuggestion,
        },
      };
    }
    case DELETE_ALLOCATION_LOCATION_FILTER: {
      const deletedSuggestion =
        state.filterOptions.allocationLocationSuggestion.map((v) => {
          return {
            ...v,
            isExisting:
              v.id === action.payload.id &&
              v.type === action.payload.type &&
              v.countryId === action.payload.countryId
                ? false
                : v.isExisting,
          };
        });
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationLocations: state.filterOptions.allocationLocations.filter(
            (location) =>
              location.countryId !== action.payload.countryId ||
              location.type !== action.payload.type ||
              location.id !== action.payload.id
          ),
          allocationLocationSuggestion: deletedSuggestion,
        },
      };
    }
    case CHANGE_ALLOCATION_FILTER_COUNTRY:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationCountryId: action.countryId,
          allocationLocationText: "",
          allocationLocationSuggestion: [],
        },
      };

    case ATTEMPT_FETCH_FILTER_FEE_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeNameText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_FEE_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeNameSuggestion: action.data,
        },
      };
    case FAIL_FETCH_FILTER_FEE_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeNameSuggestion: [],
        },
      };
    case CLEAR_FILTER_FEE_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeNameSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_FILTER_FEE_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeLocationText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_FEE_LOCATION_SUGGESTION:
      const existingKeys = state.filterOptions.feeLocations.map(
        (v) => v.id + "-" + v.type + "-" + v.countryId
      );

      const suggestion = action.data.map((v) => {
        const locationIdNew = v.id + "-" + v.type + "-" + v.countryId;
        return {
          ...v,
          isExisting: existingKeys.includes(locationIdNew),
        };
      });

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeLocationSuggestion: suggestion,
        },
      };
    case FAIL_FETCH_FILTER_FEE_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeLocationSuggestion: [],
        },
      };
    case CLEAR_FILTER_FEE_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeLocationSuggestion: [],
        },
      };
    case ADD_FEE_LOCATION_FILTER:
      const updatedSuggestion = state.filterOptions.feeLocationSuggestion.map(
        (v) => {
          return {
            ...v,
            isExisting:
              v.id === action.location.id &&
              v.type === action.location.type &&
              v.countryId === action.location.countryId
                ? true
                : v.isExisting,
          };
        }
      );
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeLocations: [
            ...state.filterOptions.feeLocations,
            { ...action.location, isExisting: true },
          ],
          feeLocationSuggestion: updatedSuggestion,
        },
      };
    case DELETE_FEE_LOCATION_FILTER:
      const deletedSuggestion = state.filterOptions.feeLocationSuggestion.map(
        (v) => {
          return {
            ...v,
            isExisting:
              v.id === action.payload.id &&
              v.type === action.payload.type &&
              v.countryId === action.payload.countryId
                ? false
                : v.isExisting,
          };
        }
      );
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeLocations: state.filterOptions.feeLocations.filter(
            (location) =>
              location.countryId !== action.payload.countryId ||
              location.type !== action.payload.type ||
              location.id !== action.payload.id
          ),
          feeLocationSuggestion: deletedSuggestion,
        },
      };
    case CHANGE_FEE_FILTER_COUNTRY:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeCountryId: action.countryId,
          feeLocationText: "",
          feeLocationSuggestion: [],
        },
      };
    case SAVE_PANEL_TO_BULK_UPDATE: {
      const { panelIds } = action.payload;

      //panelIds already in selectedPanels
      const existingPanels = state.selectedPanels.filter((panel) =>
        panelIds.includes(panel.panelSetId)
      );

      //new panelIds not in selectedPanels, copy from panels
      const newPanels = state.panels.filter(
        (panel) =>
          panelIds.includes(panel.panelSetId) &&
          !existingPanels.some(
            (selected) => selected.panelSetId === panel.panelSetId
          )
      );

      //add new, when panelIds not in selectedPanels nor panels
      panelIds.forEach((id) => {
        const isInExisting = existingPanels.some(
          (panel) => panel.panelSetId === id
        );
        const isInNewPanels = newPanels.some(
          (panel) => panel.panelSetId === id
        );

        if (!isInExisting && !isInNewPanels) {
          const newPanel = { panelSetId: id };
          newPanels.push(newPanel);
        }
      });

      const updatedSelectedPanels = [...existingPanels, ...newPanels];

      return {
        ...state,
        selectedPanels: updatedSelectedPanels,
      };
    }
    case SET_SELECTED_PANEL: {
      return {
        ...state,
        selectedPanels: [action.panel],
      };
    }
    case OPEN_BULK_UPDATE: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          isOpenBulkUpdate: action.isOpen,
        },
      };
    }
    case BULK_UPDATE_SELECT_VERSION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          bulkUpdateStep: 1,
        },
      };
    }
    case BULK_UPDATE_SELECT_LOCATIONS: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          bulkUpdateStep: 2,
        },
      };
    }
    case ATTEMPT_BULK_UPDATE_PREVIEW_DATA: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          bulkUpdateStep: 3,
          previewLoading: true,
          previewData: [],
        },
      };
    }
    case SUCCEED_BULK_UPDATE_PREVIEW_DATA: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          previewLoading: false,
          previewData: action.data,
        },
      };
    }
    case FAIL_BULK_UPDATE_PREVIEW_DATA: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          previewLoading: false,
          previewData: [],
        },
      };
    }
    case BULK_UPDATE_BACK_TO_PREVIOUS: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          bulkUpdateStep: action.step,
        },
      };
    }
    case CANCEL_BULK_UPDATE: {
      return {
        ...state,
        selectedPanels: [],
        bulkUpdate: initialBulkUpdate,
      };
    }
    case ATTEMPT_FETCH_BULK_UPDATE_INFO: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          versionLoading: true,
        },
      };
    }
    case SUCCEED_FETCH_BULK_UPDATE_INFO: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          allocations: action.data,
          versionLoading: false,
        },
      };
    }
    case FAIL_FETCH_BULK_UPDATE_INFO: {
      return {
        ...state,
        selectedPanels: [],
        bulkUpdate: initialBulkUpdate,
      };
    }
    case UPDATE_BULK_UPDATE_SELECTED_VERSION: {
      const { allocationPanelId, selectedVersion } = action;
      const updatedAllocations = state.bulkUpdate.allocations.map(
        (allocation) =>
          allocation.allocationPanelId === allocationPanelId
            ? { ...allocation, selectedVersion: selectedVersion }
            : allocation
      );
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          allocations: updatedAllocations,
          changeType: "",
          changeTypeChangeAlloc: initialChangeTypeChangeAlloc,
          changeTypeAddValfirm: initialChangeTypeAddValfirm,
          changeTypeRemoveValfirm: initialChangeTypeRemoveValfirm,
          changeTypeReplaceValfirm: initialChangeTypeReplaceValfirm,
        },
      };
    }
    case SET_BULK_UPDATE_CHANGE_TYPE: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeType: action.payload,
          changeTypeChangeAlloc: initialChangeTypeChangeAlloc,
          changeTypeAddValfirm: initialChangeTypeAddValfirm,
          changeTypeRemoveValfirm: initialChangeTypeRemoveValfirm,
          changeTypeReplaceValfirm: initialChangeTypeReplaceValfirm,
        },
      };
    }
    case ATTEMPT_FETCH_BULK_UPDATE_EXISTING_VALFIRMS: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          existingValfirms: [],
        },
      };
    }
    case SUCCEED_FETCH_BULK_UPDATE_EXISTING_VALFIRMS: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          existingValfirms: action.data,
        },
      };
    }
    case REPLACE_VALFIRM_FETCH_EXISTING_VALFIRM_SUGGESTION: {
      let suggestions = [];
      if (action.text.length > 2) {
        suggestions = state.bulkUpdate.existingValfirms.filter((v) =>
          v.name.toLowerCase().includes(action.text.toLowerCase())
        );
      }
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeReplaceValfirm: {
            ...initialChangeTypeReplaceValfirm,
            existingValfirmText: action.text,
            existingValfirmSuggestion: suggestions,
          },
        },
      };
    }
    case REPLACE_VALFIRM_SET_EXISTING_VALFIRM: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeReplaceValfirm: {
            ...initialChangeTypeReplaceValfirm,
            existingValfirmId: action.orgId,
            existingValfirmText: action.orgName,
            existingValfirmSuggestion: [],
          },
        },
      };
    }
    case ATTEMPT_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeReplaceValfirm: {
            ...state.bulkUpdate.changeTypeReplaceValfirm,
            newValfirmText: action.text,
            newValfirmSuggestion: [],
            newValfirmId: null,
          },
        },
      };
    }
    case SUCCEED_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeReplaceValfirm: {
            ...state.bulkUpdate.changeTypeReplaceValfirm,
            newValfirmSuggestion: action.data,
          },
        },
      };
    }
    case REPLACE_VALFIRM_SET_NEW_VALFIRM: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeReplaceValfirm: {
            ...state.bulkUpdate.changeTypeReplaceValfirm,
            newValfirmId: action.orgId,
            newValfirmText: action.orgName,
            newValfirmSuggestion: [],
          },
        },
      };
    }
    case SET_DISTRIBUTION_METHOD: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          distributionMethod: action.payload,
          changeTypeAddValfirm: {
            ...state.bulkUpdate.changeTypeAddValfirm,
            allocation:
              action.payload === "evenly-all"
                ? ""
                : state.bulkUpdate.changeTypeAddValfirm.allocation,
          },
        },
      };
    }
    case SET_IS_INCLUDE_BACKUP: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          isIncludeBackup: action.payload,
        },
      };
    }
    case ADD_VALFIRM_SET_ALLOCATION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeAddValfirm: {
            ...state.bulkUpdate.changeTypeAddValfirm,
            allocation: action.payload,
          },
        },
      };
    }
    case ATTEMPT_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeAddValfirm: {
            ...state.bulkUpdate.changeTypeAddValfirm,
            valfirmText: action.text,
            valfirmSuggestion: [],
            valfirmId: 0,
          },
        },
      };
    }
    case SUCCEED_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeAddValfirm: {
            ...state.bulkUpdate.changeTypeAddValfirm,
            valfirmSuggestion: action.data,
          },
        },
      };
    }
    case ADD_VALFIRM_SET_VALFIRM: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeAddValfirm: {
            ...state.bulkUpdate.changeTypeAddValfirm,
            valfirmId: action.orgId,
            valfirmText: action.orgName,
          },
        },
      };
    }
    case REMOVE_VALFIRM_FETCH_SUGGESTION: {
      let suggestions = [];
      if (action.text.length > 2) {
        suggestions = state.bulkUpdate.existingValfirms.filter((v) =>
          v.name.toLowerCase().includes(action.text.toLowerCase())
        );
      }
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeRemoveValfirm: {
            ...initialChangeTypeRemoveValfirm,
            valfirmText: action.text,
            valfirmSuggestion: suggestions,
          },
        },
      };
    }
    case REMOVE_VALFIRM_SET_VALFIRM: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeRemoveValfirm: {
            ...state.bulkUpdate.changeTypeRemoveValfirm,
            valfirmId: action.orgId,
            valfirmText: action.orgName,
            valfirmSuggestion: [],
          },
        },
      };
    }
    case CHANGE_ALLOCATION_FETCH_VALFIRM_SUGGESTION: {
      let suggestions = [];
      if (action.text.length > 2) {
        suggestions = state.bulkUpdate.existingValfirms.filter((v) =>
          v.name.toLowerCase().includes(action.text.toLowerCase())
        );
      }
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeChangeAlloc: {
            ...state.bulkUpdate.changeTypeChangeAlloc,
            valfirmText: action.text,
            valfirmId: 0,
            valfirmSuggestion: suggestions,
          },
        },
      };
    }
    case CHANGE_ALLOCATION_SET_VALFIRM: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeChangeAlloc: {
            ...state.bulkUpdate.changeTypeChangeAlloc,
            valfirmId: action.orgId,
            valfirmText: action.orgName,
            valfirmSuggestion: [],
          },
        },
      };
    }
    case CHANGE_ALLOCATION_SET_BY: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeChangeAlloc: {
            ...state.bulkUpdate.changeTypeChangeAlloc,
            changeAllocationBy: action.payload,
          },
        },
      };
    }
    case CHANGE_ALLOCATION_SET_ALLOCATION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          changeTypeChangeAlloc: {
            ...state.bulkUpdate.changeTypeChangeAlloc,
            allocation: action.payload,
          },
        },
      };
    }
    case ATTEMPT_BULK_UPDATE_SAVE_AS_DRAFT: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          bulkUpdateLoading: true,
        },
      };
    }
    case SUCCEED_BULK_UPDATE_SAVE_AS_DRAFT: {
      const allocNewDraftInfo = action.allocNewDraftInfo;
      const bulkUpdateState = action.addAnotherBulkUpdate
        ? {
            isOpenBulkUpdate: true,
            bulkUpdateStep: 1,
            addAnotherBulkUpdate: true,
            allocations: state.bulkUpdate.allocations.map((allocation) => {
              const newDraftInfo = allocNewDraftInfo.find(
                (a) => a.allocationPanelId === allocation.allocationPanelId
              );
              allocation.allocDraftRevId = newDraftInfo.allocDraftRevId;
              allocation.allocDraftLastModified =
                newDraftInfo.allocDraftLastModified;
              allocation.allocDraftLastModifiedBy =
                newDraftInfo.allocDraftLastModifiedBy;
              allocation.selectedVersion = "draft";
              return allocation;
            }),
          }
        : {
            addAnotherBulkUpdate: false,
          };

      return {
        ...state,
        bulkUpdate: {
          ...initialBulkUpdate,
          ...bulkUpdateState,
          success: true,
          focusPanels: true,
        },
      };
    }
    case CLEAR_BULK_UPDATE_SAVE_AS_DRAFT: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          focusPanels: false,
        },
      };
    }
    case FAIL_BULK_UPDATE_SAVE_AS_DRAFT: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          bulkUpdateLoading: false,
          previewData: [],
          success: false,
          focusPanels: false,
        },
      };
    }
    case SUCCEED_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          locationSuggestion: action.data,
        },
      };
    }
    case FAIL_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          locationSuggestion: [],
        },
      };
    }
    case UPDATE_BULK_UPDATE_LOCATION_TEXT: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          locationText: action.text,
        },
      };
    }
    case ADD_BULK_UPDATE_LOCATION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          locations: [...state.bulkUpdate.locations, action.location],
        },
      };
    }
    case DELETE_BULK_UPDATE_LOCATION:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          locations: state.bulkUpdate.locations.filter(
            (loc) => loc.id !== action.id || loc.type !== action.locationType
          ),
        },
      };
    case ADD_ALL_BULK_UPDATE_LOCATION: {
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          locations: [...state.bulkUpdate.locations, ...action.locations],
        },
      };
    }
    case DELETE_ALL_BULK_UPDATE_LOCATION: {
      let newLocations = [];
      state.bulkUpdate.locations.forEach((loc) => {
        action.locations.find(
          (del) => del.id === loc.id && del.type === loc.type
        ) === undefined && newLocations.push(loc);
      });
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          locations: newLocations,
        },
      };
    }
    case SUCCEED_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          associatedPanels: action.data.panels,
        },
      };
    case FAIL_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          associatedPanels: [],
        },
      };
    case OPEN_ASSOCIATED_PANELS:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          isOpenAssociatedPanels: action.isOpen,
        },
      };
    case ADD_PREVIEW_PANEL_ID:
      return {
        ...state,
        bulkUpdate: {
          ...state.bulkUpdate,
          previewPanelIds: [...state.bulkUpdate.previewPanelIds, action.id],
        },
      };
    case "persist/REHYDRATE":
      // clear error on refresh. but weird rehydrate errors come in all the time, so don't clear it on those
      return {
        ...state,
      };
    default:
      return state;
  }
}
