import {
  FETCH_ALLOCATIONS,
  FETCH_ALLOCATION_SET,
  FETCH_ALLOCATION_DETAIL,
  RESET_PAGINATION,
  UPDATE_LOCATION_TEXT_CHANGE,
  FETCH_ALLOCATION_VERSIONS,
  EXPORT_ALLOCATION_VERSION,
  UPDATE_ALLOCATION_VERSION_NOTES,
  UPDATE_ALLOCATION_DETAIL,
  IMPORT_ALLOCATION_VERSION,
  RESET_VALIDATION_ERRORS,
  OPEN_IMPORT_MODAL,
  CHANGE_ALLOCATION_TAB,
  CHANGE_ALLOCATION_VERSION_ID,
  FETCH_ALLOCATION_VERSION_DETAIL,
  SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  COPY_ALLOCATION_VERSION,
  FETCH_ALLOCATION_ACTIVATION_LOGS,
  CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  UPDATE_LOCATION_TEXT_CHANGE_NEW_ALLOCATION,
  UPDATE_LOCATION_OPTION_NEW_ALLOCATION,
  SET_VERSIONS_TO_COMPARE,
  RESET_VERSIONS_TO_COMPARE,
  SHOW_VERSIONS_COMPARISON,
  SHOW_CREATE_NEW_ALLOCATION,
  CREATE_NEW_ALLOCATION,
  FETCH_VERSIONS_COMPARISON_DETAILS,
  REMOVE_ALLOCATION_LOCATION,
  REMOVE_ALLOCATION_LOCATION_NEW_ALLOCATION,
  FETCH_LATEST_ALLOCATION_ACTIVATION_LOG,
  UPDATE_LOCATION_NEW_ALLOCATION,
  UPDATE_ISQUOTE_NEW_ALLOCATION,
  UPDATE_ALLOCATIONAME_NEW_ALLOCATION,
  UPDATE_ISSCORECARD_NEW_ALLOCATION,
  UPDATE_NOOFQUOTES_NEW_ALLOCATION,
  UPDATE_ALLOCATION_NAME,
  UPDATE_ISSCORECARD_EDIT_ALLOCATION,
  UPDATE_NOOFQUOTES_EDIT_ALLOCATION,
  UPDATE_ISQUOTE_EDIT_ALLOCATION,
  OPEN_ALLOCATION_SET_NAME_MODAL,
  RESET_UPDATE_ALLOCATION_NAME_ERROR,
  DISCARD_ALLOCATION_VERSION,
  RESET_PANEL_ALLOCATION,
  FETCH_ASSOCIATED_PANELS,
  FILTER_NAME_CHANGE,
  FILTER_LOCATION_CHANGE,
  ADD_FILTER_LOCATION,
  DELETE_FILTER_LOCATION,
  CHANGE_FILTER_COUNTRY,
  FILTER_CLEAR_ALL,
  ADD_FILTER_VALFIRM,
  DELETE_FILTER_VALFIRM,
  FILTER_VALFIRM_CHANGE,
  OPEN_FILTER,
  COPY_FILTER,
  UPDATE_ALLOCATION_STATUS_FILTER,
  ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  EXPORT_MULTI_ALLOCATIONS,
  ADD_VALFIRM_SELECTED_VALFIRMS,
  DELETE_VALFIRM_SELECTED_VALFIRMS,
  SET_ADD_VALFIRM_TEXT_SELECTED_VALFIRMS,
  ADD_LOCATION_SELECTED_LOCATIONS,
  CLEAR_ALL_SELECTED_VALFIRMS_LOCATIONS,
  FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
  DELETE_LOCATION_SELECTED_LOCATIONS,
} from "../staticSagas/sagaAllocation";

export function getAllocations(filter) {
  return {
    type: FETCH_ALLOCATIONS,
    payload: {
      filter,
    },
  };
}

export function resetPagination() {
  return {
    type: RESET_PAGINATION,
  };
}

export function getAllocationSet(allocationId, allocationName) {
  return { type: FETCH_ALLOCATION_SET, allocationId, allocationName };
}

export function getLatestAllocationActivationLog(allocationId) {
  return { type: FETCH_LATEST_ALLOCATION_ACTIVATION_LOG, allocationId };
}

export function getAllocationDetail(
  allocationId,
  allocRevId,
  valfirmFilter,
  locationFilter,
  currentPage,
  pageSize
) {
  return {
    type: FETCH_ALLOCATION_DETAIL,
    allocationId,
    allocRevId,
    currentPage,
    pageSize,
    locationFilter,
    valfirmFilter,
  };
}

export function updateLocationTextChange(
  text,
  option,
  allocationId,
  allocRevId,
  isExisting
) {
  return {
    type: UPDATE_LOCATION_TEXT_CHANGE,
    text,
    option,
    allocationId,
    allocRevId,
    isExisting,
  };
}

export function updateLocationOptionNewAllocation(option) {
  return { type: UPDATE_LOCATION_OPTION_NEW_ALLOCATION, option };
}
export function updateLocationTextChangeNewAllocation(text, option) {
  return {
    type: UPDATE_LOCATION_TEXT_CHANGE_NEW_ALLOCATION,
    text,
    option,
  };
}
export function getAllocationVersions(allocationId, currentPage, pageSize) {
  return {
    type: FETCH_ALLOCATION_VERSIONS,
    payload: {
      allocationId,
      currentPage,
      pageSize,
    },
  };
}
export function exportAllocationVersion(
  allocationId,
  allocationVersionId,
  allocationSetName
) {
  return {
    type: EXPORT_ALLOCATION_VERSION,
    payload: {
      allocationId,
      allocationVersionId,
      allocationSetName,
    },
  };
}

export function updateAllocationVersionNotes(
  allocationId,
  allocationVersionId,
  notes
) {
  return {
    type: UPDATE_ALLOCATION_VERSION_NOTES,
    payload: {
      allocationId,
      allocationVersionId,
      notes,
    },
  };
}

export function scheduleAllocationVersionActivation(
  allocationPanelId,
  allocationVersionId,
  activateDate
) {
  return {
    type: SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
    payload: {
      allocationPanelId,
      allocationVersionId,
      activateDate,
    },
  };
}

export function updateAllocationDetail(
  allocationId,
  allocationVersionId,
  location,
  valfirms
) {
  return {
    type: UPDATE_ALLOCATION_DETAIL,
    payload: {
      allocationId,
      allocationVersionId,
      location,
      valfirms,
    },
  };
}

export function createNewAllocationSet(newAllocationSet) {
  return {
    type: CREATE_NEW_ALLOCATION,
    payload: {
      newAllocationSet,
    },
  };
}
export function updateLocationNewAllocationSet(
  allocationId,
  allocationVersionId,
  location,
  valfirms
) {
  return {
    type: UPDATE_LOCATION_NEW_ALLOCATION,
    payload: {
      allocationId,
      allocationVersionId,
      location,
      valfirms,
    },
  };
}
export function importAllocationVersion(id, name, file, isRedirect = false) {
  return {
    type: IMPORT_ALLOCATION_VERSION,
    payload: {
      id,
      name,
      file,
      isRedirect,
    },
  };
}

export function resetValidationErrors() {
  return { type: RESET_VALIDATION_ERRORS };
}

export function openImportModal(isOpen) {
  return {
    type: OPEN_IMPORT_MODAL,
    data: {
      isOpen,
    },
  };
}

export function changeAllocationTab(index) {
  return {
    type: CHANGE_ALLOCATION_TAB,
    index,
  };
}

export function changeAllocationVersionId(id) {
  return {
    type: CHANGE_ALLOCATION_VERSION_ID,
    id,
  };
}

export function getAllocationVersionDetail(allocationId, allocRevId) {
  return {
    type: FETCH_ALLOCATION_VERSION_DETAIL,
    payload: {
      allocationId,
      allocRevId,
    },
  };
}

export function copyAllocationVersion(allocationId, allocationVersionId) {
  return {
    type: COPY_ALLOCATION_VERSION,
    payload: {
      allocationId,
      allocationVersionId,
    },
  };
}

export function getAllocationActivationLogs(
  allocationId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) {
  return {
    type: FETCH_ALLOCATION_ACTIVATION_LOGS,
    payload: {
      allocationId,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
    },
  };
}

export function cancelAllocationActivationLog(
  allocationId,
  allocationPanelActivationLogId,
  allocationPanelActivationVersionId
) {
  return {
    type: CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
    payload: {
      allocationId,
      allocationPanelActivationLogId,
      allocationPanelActivationVersionId,
    },
  };
}

export function setVersionsToCompare(
  allocationVersionId,
  selected,
  count,
  width
) {
  return {
    type: SET_VERSIONS_TO_COMPARE,
    payload: {
      allocationVersionId,
      selected,
      count,
      width,
    },
  };
}

export function clearVersionsToCompare() {
  return {
    type: RESET_VERSIONS_TO_COMPARE,
  };
}

export function showVersionsCompare(show) {
  return {
    type: SHOW_VERSIONS_COMPARISON,
    show,
  };
}

export function showCreateNewAllocationSet(show) {
  return {
    type: SHOW_CREATE_NEW_ALLOCATION,
    show,
  };
}

export function getAllocationVersionComparisonDetails(
  allocationId,
  versionsList
) {
  return {
    type: FETCH_VERSIONS_COMPARISON_DETAILS,
    payload: {
      allocationId,
      versionsList,
    },
  };
}

export function removeAllocationLocation(
  allocationId,
  allocationRevId,
  allocationFilterId,
  locationName,
  valfirmFilter,
  locationFilter,
  pageSize
) {
  return {
    type: REMOVE_ALLOCATION_LOCATION,
    payload: {
      allocationId,
      allocationRevId,
      allocationFilterId,
      locationName,
      valfirmFilter,
      locationFilter,
      pageSize,
    },
  };
}
export function removeAllocationLocationForNewAllocation(
  locationId,
  locationName
) {
  return {
    type: REMOVE_ALLOCATION_LOCATION_NEW_ALLOCATION,
    payload: {
      locationId,
      locationName,
    },
  };
}

export function updateNoOfQuotesNewAllocation(noOfQuotes) {
  return {
    type: UPDATE_NOOFQUOTES_NEW_ALLOCATION,
    payload: {
      noOfQuotes,
    },
  };
}

export function updateIsScoreCardNewAllocation(isScoreCard) {
  return {
    type: UPDATE_ISSCORECARD_NEW_ALLOCATION,
    payload: {
      isScoreCard,
    },
  };
}

export function updateAllcationNameNewAllocation(allocationName) {
  return {
    type: UPDATE_ALLOCATIONAME_NEW_ALLOCATION,
    payload: {
      allocationName,
    },
  };
}

export function updateIsQuoteNewAllocation(isQuote) {
  return {
    type: UPDATE_ISQUOTE_NEW_ALLOCATION,
    payload: {
      isQuote,
    },
  };
}

export function updateAllocationSetName(allocationId, name) {
  return {
    type: UPDATE_ALLOCATION_NAME,
    payload: {
      allocationPanelId: Number(allocationId),
      name,
    },
  };
}

export function updateIsScoreCardAllocation(
  allocationId,
  allocationVersionId,
  isScoreCard
) {
  return {
    type: UPDATE_ISSCORECARD_EDIT_ALLOCATION,
    payload: {
      allocationId,
      allocationVersionId,
      isScoreCard,
    },
  };
}

export function updateNoOfQuotesAllocation(
  allocationId,
  allocationVersionId,
  noOfQuotes
) {
  return {
    type: UPDATE_NOOFQUOTES_EDIT_ALLOCATION,
    payload: {
      allocationId,
      allocationVersionId,
      noOfQuotes,
    },
  };
}

export function updateIsQuoteAllocation(
  allocationId,
  allocationVersionId,
  isQuote
) {
  return {
    type: UPDATE_ISQUOTE_EDIT_ALLOCATION,
    payload: {
      allocationId,
      allocationVersionId,
      isQuote,
    },
  };
}

export function openAllocationSetNameModal(isOpen) {
  return {
    type: OPEN_ALLOCATION_SET_NAME_MODAL,
    data: {
      isOpen,
    },
  };
}

export function resetUpdateNameError() {
  return {
    type: RESET_UPDATE_ALLOCATION_NAME_ERROR,
  };
}

export function discardAllocationVersion(allocationId, allocRevId) {
  return {
    type: DISCARD_ALLOCATION_VERSION,
    payload: {
      allocationId,
      allocRevId,
    },
  };
}

export function resetPanelAllocation(
  allocationId,
  allocationName,
  panelId,
  panelVersionId
) {
  return {
    type: RESET_PANEL_ALLOCATION,
    payload: {
      allocationId,
      allocationName,
      panelId,
      panelVersionId,
    },
  };
}

export function getAssociatedPanels(
  allocationId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) {
  return {
    type: FETCH_ASSOCIATED_PANELS,
    payload: {
      allocationId,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
    },
  };
}

export function filterNameChange(text) {
  return { type: FILTER_NAME_CHANGE, text };
}

export function filterLocationChange(text, countryId) {
  return {
    type: FILTER_LOCATION_CHANGE,
    text,
    countryId,
  };
}

export function addFilterLocation(location) {
  return {
    type: ADD_FILTER_LOCATION,
    location,
  };
}

export function deleteFilterLocation(id, countryId, type) {
  return {
    type: DELETE_FILTER_LOCATION,
    payload: {
      id,
      countryId,
      type,
    },
  };
}

export function changeFilterCountry(countryId) {
  return {
    type: CHANGE_FILTER_COUNTRY,
    countryId,
  };
}

export function clearFilter() {
  return {
    type: FILTER_CLEAR_ALL,
  };
}

export function addFilterValFirm(valfirm) {
  return {
    type: ADD_FILTER_VALFIRM,
    valfirm,
  };
}

export function filterValFirmChange(text) {
  return {
    type: FILTER_VALFIRM_CHANGE,
    text,
  };
}
export function deleteFilterValFirm(id) {
  return {
    type: DELETE_FILTER_VALFIRM,
    payload: {
      id,
    },
  };
}

export function openFilter(isOpen) {
  return {
    type: OPEN_FILTER,
    isOpen,
  };
}

export function copyFilter() {
  return {
    type: COPY_FILTER,
  };
}

export function updateAllocationStatusFilter(allocStatus) {
  return {
    type: UPDATE_ALLOCATION_STATUS_FILTER,
    payload: allocStatus,
  };
}

export function acknowledgeAllocationActivationLog(
  allocationId,
  allocationPanelActivationLogId,
  allocationPanelActivationVersionId
) {
  return {
    type: ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
    payload: {
      allocationId,
      allocationPanelActivationLogId,
      allocationPanelActivationVersionId,
    },
  };
}

export function exportMultiAllocations(allocationIds) {
  return {
    type: EXPORT_MULTI_ALLOCATIONS,
    payload: {
      allocationIds,
    },
  };
}

export function getAllocationVersionExistingValfirms(allocationId, allocRevId) {
  return {
    type: FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
    allocationId,
    allocRevId,
  };
}

export function setAddValfirmText(text) {
  return {
    type: SET_ADD_VALFIRM_TEXT_SELECTED_VALFIRMS,
    text,
  };
}

export function addValfirmSelectedValfirms(valfirm) {
  return {
    type: ADD_VALFIRM_SELECTED_VALFIRMS,
    valfirm,
  };
}

export function deleteValfirmSelectedValfirms(valfirm) {
  return {
    type: DELETE_VALFIRM_SELECTED_VALFIRMS,
    valfirm,
  };
}

export function addLocationSelectedLocations(location) {
  return {
    type: ADD_LOCATION_SELECTED_LOCATIONS,
    location,
  };
}

export function deleteLocationSelectedLocations(location) {
  return {
    type: DELETE_LOCATION_SELECTED_LOCATIONS,
    location,
  };
}

export function clearAllSelectedValfirmsLocations() {
  return {
    type: CLEAR_ALL_SELECTED_VALFIRMS_LOCATIONS,
  };
}
