import {
  ATTEMPT_FETCH_ALLOCATIONS,
  SUCCEED_FETCH_ALLOCATIONS,
  FAIL_FETCH_ALLOCATIONS,
  ATTEMPT_FETCH_ALLOCATION_SET,
  SUCCEED_FETCH_ALLOCATION_SET,
  FAIL_FETCH_ALLOCATION_SET,
  RESET_PAGINATION,
  ATTEMPT_FETCH_ALLOCATION_DETAIL,
  SUCCEED_FETCH_ALLOCATION_DETAIL,
  FAIL_FETCH_ALLOCATION_DETAIL,
  ATTEMPT_FETCH_LOCATION_SUGGESTION,
  SUCCEED_FETCH_LOCATION_SUGGESTION,
  FAIL_FETCH_LOCATION_SUGGESTION,
  CLEAR_LOCATION_SUGGESTION,
  ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION,
  SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION,
  FAIL_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION,
  CLEAR_LOCATION_SUGGESTION_NEW_ALLOCATION,
  UPDATE_LOCATION_OPTION_NEW_ALLOCATION,
  ATTEMPT_FETCH_ALLOCATION_VERSIONS,
  SUCCEED_FETCH_ALLOCATION_VERSIONS,
  FAIL_FETCH_ALLOCATION_VERSIONS,
  SUCCEED_UPDATE_ALLOCATION_VERSION_NOTES,
  SUCCEED_UPDATE_ALLOCATION_DETAIL,
  SUCCEED_IMPORT_ALLOCATION_VERSION,
  FAIL_VALIDATION_IMPORT_ALLOCATION_VERSION,
  RESET_VALIDATION_ERRORS,
  OPEN_IMPORT_MODAL,
  CHANGE_ALLOCATION_TAB,
  CHANGE_ALLOCATION_VERSION_ID,
  SUCCEED_FETCH_ALLOCATION_VERSION_DETAIL,
  SUCCEED_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  FAIL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  ATTEMPT_COPY_ALLOCATION_VERSION,
  SUCCEED_COPY_ALLOCATION_VERSION,
  FAIL_COPY_ALLOCATION_VERSION,
  ATTEMPT_FETCH_ALLOCATION_ACTIVATION_LOGS,
  SUCCEED_FETCH_ALLOCATION_ACTIVATION_LOGS,
  FAIL_FETCH_ALLOCATION_ACTIVATION_LOGS,
  SUCCEED_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  SAVE_VERSIONS_TO_COMPARE,
  CLEAR_VERSIONS_TO_COMPARE,
  SHOW_VERSIONS_COMPARISON,
  SHOW_CREATE_NEW_ALLOCATION,
  UPDATE_LOCATION_NEW_ALLOCATION,
  ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS,
  SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS,
  FAIL_FETCH_VERSIONS_COMPARISON_DETAILS,
  SUCCEED_REMOVE_ALLOCATION_LOCATION,
  REMOVE_ALLOCATION_LOCATION_NEW_ALLOCATION,
  SUCCEED_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG,
  FAIL_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG,
  UPDATE_ISQUOTE_NEW_ALLOCATION,
  UPDATE_ALLOCATIONAME_NEW_ALLOCATION,
  UPDATE_ISSCORECARD_NEW_ALLOCATION,
  UPDATE_NOOFQUOTES_NEW_ALLOCATION,
  SUCCEED_CREATE_NEW_ALLOCATION,
  FAIL_CREATE_NEW_ALLOCATION,
  ERROR_CREATE_NEW_ALLOCATION,
  SUCCEED_UPDATE_ISQUOTE_EDIT_ALLOCATION,
  SUCCEED_UPDATE_ISSCORECARD_EDIT_ALLOCATION,
  SUCCEED_UPDATE_NOOFQUOTES_EDIT_ALLOCATION,
  OPEN_ALLOCATION_SET_NAME_MODAL,
  SUCCEED_UPDATE_ALLOCATION_NAME,
  FAIL_DUPLICATE_UPDATE_ALLOCATION_NAME,
  RESET_UPDATE_ALLOCATION_NAME_ERROR,
  SUCCEED_DISCARD_ALLOCATION_VERSION,
  ATTEMPT_IMPORT_ALLOCATION_VERSION,
  ATTEMPT_FETCH_ASSOCIATED_PANELS,
  SUCCEED_FETCH_ASSOCIATED_PANELS,
  FAIL_FETCH_ASSOCIATED_PANELS,
  ATTEMPT_FETCH_FILTER_NAME_SUGGESTION,
  SUCCEED_FETCH_FILTER_NAME_SUGGESTION,
  FAIL_FETCH_FILTER_NAME_SUGGESTION,
  CLEAR_FILTER_NAME_SUGGESTION,
  SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION,
  FAIL_FETCH_FILTER_LOCATION_SUGGESTION,
  CLEAR_FILTER_LOCATION_SUGGESTION,
  ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION,
  ADD_FILTER_LOCATION,
  DELETE_FILTER_LOCATION,
  CHANGE_FILTER_COUNTRY,
  FILTER_CLEAR_ALL,
  SUCCEED_FETCH_FILTER_VALFIRM_SUGGESTION,
  FAIL_FETCH_FILTER_VALFIRM_SUGGESTION,
  CLEAR_FILTER_VALFIRM_SUGGESTION,
  ATTEMPT_FETCH_FILTER_VALFIRM_SUGGESTION,
  ADD_FILTER_VALFIRM,
  DELETE_FILTER_VALFIRM,
  OPEN_FILTER,
  COPY_FILTER,
  UPDATE_ALLOCATION_STATUS_FILTER,
  SUCCEED_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  ADD_VALFIRM_SELECTED_VALFIRMS,
  DELETE_VALFIRM_SELECTED_VALFIRMS,
  SET_ADD_VALFIRM_TEXT_SELECTED_VALFIRMS,
  ADD_LOCATION_SELECTED_LOCATIONS,
  DELETE_LOCATION_SELECTED_LOCATIONS,
  CLEAR_ALL_SELECTED_VALFIRMS_LOCATIONS,
  SUCCEED_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
  FAIL_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
} from "../staticSagas/sagaAllocation";
import {
  SUCCEED_BULK_UPDATE_SAVE_AS_DRAFT,
  SUCCEED_FETCH_PANEL_VERSION_DETAIL,
} from "../staticSagas/sagaPanel";

import { UPDATE_SEARCH_OPTION } from "../staticSagas/sagaUI";

const initialAllocationDetail = {
  locations: [],
  locationCount: 0,
  currentPage: 0,
  pageSize: 15,
  searchOption: 1,
  editedLocation: null,
  searchText: "",
  locationSuggestion: [],
  selectedLocations: [],
  valfirmText: "",
  existingValfirms: [],
  selectedValfirms: [],
  filteredOptions: {
    selectedValfirms: [],
    selectedLocations: [],
  },
};
const initialVersionCompare = {
  versionCompare: [],
  versionCompareDetails: {},
  showVersionCompare: false,
  versionCompareLoading: false,
};
const initialAllocationVersion = {
  allocationVersions: [],
  allocationVersionCount: 0,
  currentPage: 0,
  pageSize: 15,
  loading: false,
  ...initialVersionCompare,
};
const initialUpdateName = {
  isOpen: false,
  message: null,
};
const initialAllocationSet = {
  allocPanelId: 0,
  allocPanelFilterId: null,
  allocRevId: 0,
  postCodeId: null,
  regionId: null,
  stateId: null,
  countryId: null,
  name: "",
  allocationPanelRevisionBulkRow: null,
  parentAllocationPanelRevisionBulkRow: null,
  requestedActivationLog: {},
  quote: false,
  maxProviders: 0,
  lastModified: null,
  notes: "",
  status: "",
  hasEditable: false,
  editableRevId: 0,
};
const initialNewAllocationSet = {
  searchOption: null,
  searchText: null,
  suggestion: [],
  isScoreCard: false,
  isQuote: false,
  noOfQuotes: 1,
  allocationName: "",
  allocationVersionCount: 0,
  currentPage: 0,
  pageSize: 15,
  loading: false,
  locations: [],
  newAllocationId: 0,
  error: null,
  allocationCountryId: null,
};
const initialAssociatedPanel = {
  panels: [],
  panelCount: 0,
  currentPage: 0,
  pageSize: 15,
  sortBy: "name",
  sortDir: "asc",
  loading: false,
};
const initialFilterOptions = {
  nameText: "",
  nameSuggestion: [],
  countryId: 1,
  locationText: "",
  locationSuggestion: [],
  locations: [],
  allocationSetStatus: "In Use",
  valfirmText: "",
  valfirmSuggestion: [],
  valfirms: [],
  keywordValfirms: [],
  isFiltered: false,
  isOpenFilter: false,
};
const initialFilteredOptions = {
  name: "",
  locations: [],
  valfirms: [],
  status: "In Use",
  countryId: 1,
  locationText: "",
  valfirmText: "",
  nameSuggestion: [],
  locationSuggestion: [],
  valfirmSuggestion: [],
  keywordValfirms: [],
};

export const initialState = {
  allocations: [],
  allocationCount: 0,
  allocationSet: initialAllocationSet,
  updateName: initialUpdateName,
  newAllocationSet: initialNewAllocationSet,
  latestAllocationActivationLog: {},
  allocationVersionDetail: {
    quote: false,
    maxProviders: 1,
    allowAutoUpdate: false,
    latestActivationLogId: 0,
  },
  statusFilter: "Active",
  currentPage: 0,
  pageSize: 15,
  allocationDetail: initialAllocationDetail,
  loading: false,
  allocationVersion: initialAllocationVersion,
  activationLog: {
    activationLogs: [],
    activationLogCount: 0,
    currentPage: 0,
    pageSize: 15,
    sortBy: "requested",
    sortDir: "desc",
    loading: false,
  },
  associatedPanel: initialAssociatedPanel,
  allocationTab: 0,
  isUploading: false,
  isOpenImportModal: false,
  validationErrors: [],
  allocationVersionId: 0,
  showCreateNewAllocationSet: false,
  openBackdrop: false,
  filterOptions: initialFilterOptions,
  filteredOptions: initialFilteredOptions,
  latestVersionLocationCount: 0,
  reloadDetail: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SEARCH_OPTION:
      return {
        ...state,
        filterOptions: initialFilterOptions,
        filteredOptions: initialFilteredOptions,
      };
    case ATTEMPT_FETCH_ALLOCATIONS:
      return {
        ...state,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        loading: true,
        sortBy: action.data.sortBy,
        sortDir: action.data.sortDir,
        filterOptions: {
          ...state.filterOptions,
          isFiltered: action.data.isFiltered,
        },
      };
    case SUCCEED_FETCH_ALLOCATIONS:
      return {
        ...state,
        allocations: action.data.allocationPanels,
        allocationCount: action.data.totalCount,
        loading: false,
        filteredOptions: {
          ...state.filteredOptions,
          name: action.data.name,
          locations: action.data.locations,
          valfirms: action.data.valfirms,
          status: action.data.status,
          countryId: action.data.countryId,
          locationText: action.data.locationText,
          valfirmText: action.data.valfirmText,
          nameSuggestion: action.data.nameSuggestion,
          locationSuggestion: action.data.locationSuggestion,
          valfirmSuggestion: action.data.valfirmSuggestion,
          keywordValfirms: action.data.keywordValfirms,
        },
      };
    case FAIL_FETCH_ALLOCATIONS:
      return {
        ...state,
        allocations: [],
        allocationCount: 0,
        loading: false,
      };
    case ATTEMPT_FETCH_ALLOCATION_SET:
      return {
        ...state,
        allocationSet: initialAllocationSet,
        allocationDetail: initialAllocationDetail,
        newAllocationSet: initialNewAllocationSet,
      };
    case SUCCEED_FETCH_ALLOCATION_SET:
      return {
        ...state,
        allocationSet: action.data,
        allocationVersion: {
          ...state.allocationVersion,
          allocationVersionCount: 0,
        },
      };
    case SUCCEED_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG:
      const { status, allocationPanelRevId } = action.data;
      return {
        ...state,
        latestAllocationActivationLog: action.data,
        allocationSet: {
          ...state.allocationSet,
          allocRevId:
            status === "Processed"
              ? allocationPanelRevId
              : state.allocationSet.allocRevId,
          hasEditable:
            status === "Processed" &&
            allocationPanelRevId === state.allocationSet.editableRevId
              ? false
              : state.allocationSet.hasEditable,
          editableRevId:
            status === "Processed" &&
            allocationPanelRevId === state.allocationSet.editableRevId
              ? 0
              : state.allocationSet.editableRevId,
        },
      };
    case FAIL_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG:
      return {
        ...state,
        latestAllocationActivationLog: {},
      };
    case FAIL_FETCH_ALLOCATION_SET:
      return {
        ...state,
        allocationSet: initialAllocationSet,
      };
    case RESET_PAGINATION:
      return {
        ...state,
        currentPage: 0,
        pageSize: 15,
      };
    case ATTEMPT_FETCH_ALLOCATION_VERSIONS:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
      };
    case SUCCEED_FETCH_ALLOCATION_VERSIONS:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          loading: false,
          allocationVersions: action.data.allocationPanelRevs,
          allocationVersionCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_ALLOCATION_VERSIONS:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          loading: false,
          allocationVersions: [],
          allocationVersionCount: 0,
        },
      };
    case ATTEMPT_FETCH_ALLOCATION_DETAIL:
      return {
        ...state,
        loading: true,
        allocationDetail: {
          ...state.allocationDetail,
          locations: [],
          locationCount: 0,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          editedLocation: null,
        },
        allocationVersion: {
          ...state.allocationVersion,
          ...initialVersionCompare,
        },
      };
    case SUCCEED_FETCH_ALLOCATION_DETAIL:
      return {
        ...state,
        loading: false,
        allocationDetail: {
          ...state.allocationDetail,
          locations: action.data.allocationDetails,
          locationCount: action.data.totalCount,
          filteredOptions: {
            selectedValfirms: state.allocationDetail.selectedValfirms,
            selectedLocations: state.allocationDetail.selectedLocations,
          },
        },
        latestVersionLocationCount:
          action.payload.locationFilter === "" &&
          action.payload.countryFilter === 0
            ? action.data.totalCount
            : state.latestVersionLocationCount,
      };
    case FAIL_FETCH_ALLOCATION_DETAIL:
      return {
        ...state,
        loading: false,
        allocationDetail: {
          ...state.allocationDetail,
          locations: [],
          locationCount: 0,
        },
      };
    case ATTEMPT_FETCH_LOCATION_SUGGESTION:
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          searchText: action.text,
        },
      };
    case SUCCEED_FETCH_LOCATION_SUGGESTION:
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          locationSuggestion: action.data,
        },
      };
    case FAIL_FETCH_LOCATION_SUGGESTION:
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          locationSuggestion: [],
        },
      };
    case CLEAR_LOCATION_SUGGESTION:
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          locationSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          searchText: action.text,
        },
      };
    case SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION:
      const existingLocKeys = state.newAllocationSet.locations.map(
        (v) => v.locationId
      );

      let newSuggestions = action.data.map((v) => {
        let locationIdNew;
        if (v.type === "Postcode") {
          locationIdNew = v.id + "-null-null-" + v.countryId;
        } else if (v.type === "Region") {
          locationIdNew = "null" + "-" + v.id + "-null-" + v.countryId;
        } else if (v.type === "State") {
          locationIdNew = "null-null-" + v.id + "-" + v.countryId;
        } else {
          locationIdNew = "null-null-null-" + v.countryId;
        }
        return {
          ...v,
          isExisting: existingLocKeys.includes(locationIdNew),
        };
      });
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          suggestion: newSuggestions,
        },
      };
    case FAIL_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          suggestion: [],
        },
      };
    case CLEAR_LOCATION_SUGGESTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          suggestion: [],
        },
      };
    case UPDATE_LOCATION_OPTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          searchOption: action.option,
          searchText: null,
          suggestion: [],
        },
      };
    case SUCCEED_UPDATE_ALLOCATION_VERSION_NOTES:
      const allocationVersions = state.allocationVersion.allocationVersions.map(
        (version) => {
          if (
            version.allocationPanelRevId === action.data.allocationVersionId
          ) {
            version.notes = action.data.notes;
          }
          return version;
        }
      );
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          allocationVersions,
        },
      };
    case SUCCEED_SCHEDULE_ALLOCATION_VERSION_ACTIVATION:
      return {
        ...state,
        latestAllocationActivationLog: action.data,
      };
    case FAIL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION:
      return {
        ...state,
        latestAllocationActivationLog: {},
      };
    case ATTEMPT_IMPORT_ALLOCATION_VERSION:
      return {
        ...state,
        isUploading: true,
      };
    case SUCCEED_IMPORT_ALLOCATION_VERSION:
      let editableVersionCount = 0;
      let allocationVersionCount =
        state.allocationVersion.allocationVersionCount;
      const newAllocationVersions =
        state.allocationVersion.allocationVersions.filter((version) => {
          if (!version.editable) {
            return version;
          }
        });
      editableVersionCount =
        state.allocationVersion.allocationVersions.length -
        newAllocationVersions.length;
      newAllocationVersions.unshift(action.data.allocationPanelRev);
      if (newAllocationVersions.length > state.allocationVersion.pageSize) {
        newAllocationVersions.pop();
      }
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          allocationVersions:
            newAllocationVersions.length === 0
              ? [action.data.allocationPanelRev]
              : newAllocationVersions,
          allocationVersionCount:
            allocationVersionCount - editableVersionCount + 1,
        },
        allocationSet: {
          ...state.allocationSet,
          hasEditable: true,
        },
        isUploading: false,
      };
    case FAIL_VALIDATION_IMPORT_ALLOCATION_VERSION:
      return {
        ...state,
        validationErrors: action.data.validationErrors,
        isUploading: false,
      };
    case RESET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: [],
      };
    case OPEN_IMPORT_MODAL:
      return {
        ...state,
        isOpenImportModal: action.data.isOpen,
      };
    case CHANGE_ALLOCATION_TAB:
      return {
        ...state,
        allocationTab: action.index,
      };
    case CHANGE_ALLOCATION_VERSION_ID:
      return {
        ...state,
        allocationVersionId: action.id,
      };
    case SUCCEED_UPDATE_ALLOCATION_DETAIL: {
      const updatedLocation = action.payload;
      const otherLocationDetails = state.allocationDetail.locations.filter(
        (location) =>
          location.allocationPanelFilterId !==
          updatedLocation.allocationPanelFilterId
      );
      return {
        ...state,
        loading: false,
        allocationDetail: {
          ...state.allocationDetail,
          locations: [updatedLocation, ...otherLocationDetails],
          locationCount: otherLocationDetails.length + 1,
          editedLocation: updatedLocation.allocationPanelFilterId,
          locationSuggestion: [],
          searchText: "",
        },
        latestVersionLocationCount: otherLocationDetails.length + 1,
      };
    }
    case SUCCEED_FETCH_ALLOCATION_VERSION_DETAIL: {
      const latestActivationLogId = Math.max(
        ...action.data.allocationPanelActivationLogs.map(
          (p) => p.allocationPanelActivationLogId
        ),
        0
      );
      return {
        ...state,
        allocationVersionDetail: {
          ...action.data,
          latestActivationLogId,
        },
        reloadDetail: false,
      };
    }
    case ATTEMPT_COPY_ALLOCATION_VERSION:
      return {
        ...state,
        openBackdrop: true,
      };
    case SUCCEED_COPY_ALLOCATION_VERSION:
      return {
        ...state,
        allocationVersionId: action.data.allocationVersionId,
        allocationTab: 0,
        allocationSet: {
          ...state.allocationSet,
          hasEditable: true,
          editableRevId: action.data.allocationVersionId,
        },
        openBackdrop: false,
      };
    case FAIL_COPY_ALLOCATION_VERSION:
      return {
        ...state,
        openBackdrop: false,
      };
    case ATTEMPT_FETCH_ALLOCATION_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
        allocationVersion: {
          ...state.allocationVersion,
          ...initialVersionCompare,
        },
      };
    case SUCCEED_FETCH_ALLOCATION_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: false,
          activationLogs: action.data.activationLogs,
          activationLogCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_ALLOCATION_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: false,
          activationLogs: [],
          activationLogCount: 0,
        },
      };
    case SUCCEED_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION:
      return {
        ...state,
        latestAllocationActivationLog: {
          ...state.latestAllocationActivationLog,
          status: "Canceled",
        },
      };
    case SUCCEED_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION:
      return {
        ...state,
        latestAllocationActivationLog: {
          ...state.latestAllocationActivationLog,
          status: "Acknowledged",
        },
      };
    case SAVE_VERSIONS_TO_COMPARE: {
      const { allocationVersionId, selected } = action.payload;
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          versionCompare: selected
            ? [...state.allocationVersion.versionCompare, allocationVersionId]
            : [
                ...state.allocationVersion.versionCompare.filter(
                  (v) => v !== allocationVersionId
                ),
              ],
        },
      };
    }
    case CLEAR_VERSIONS_TO_COMPARE: {
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          versionCompare: [],
        },
      };
    }
    case SHOW_VERSIONS_COMPARISON:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          showVersionCompare: action.show,
        },
      };
    case SHOW_CREATE_NEW_ALLOCATION:
      return {
        ...state,
        showCreateNewAllocationSet: action.show,
        newAllocationSet: initialNewAllocationSet,
      };
    case UPDATE_LOCATION_NEW_ALLOCATION:
      const { id, name, type, countryId, countryName } =
        action.payload.location;
      let newLocation = {
        allocationPanelFilterId: 0,
        allocationPanelId: 0,
        allocationPanelRevId: 0,
        ignoreAllocation: 0,
        countryId,
        countryName,
        stateId: null,
        stateName: null,
        regionId: null,
        regionName: null,
        postcodeId: null,
        postcodeName: null,
      };
      if (type === "Postcode") {
        newLocation = {
          ...newLocation,
          postcodeId: id,
          postcodeName: name,
        };
      } else if (type === "Region") {
        newLocation = {
          ...newLocation,
          regionId: id,
          regionName: name,
        };
      } else {
        newLocation = {
          ...newLocation,
          stateId: id,
          stateName: name,
        };
      }

      const locationId =
        newLocation.postcodeId +
        "-" +
        newLocation.regionId +
        "-" +
        newLocation.stateId +
        "-" +
        newLocation.countryId;

      const allocations = action.payload.valfirms.map(function (item) {
        return {
          allocationPanelAllocationsId: 0,
          allocation: item.value,
          orgId: item.valfirmId,
          orgName: item.valfirmName,
        };
      });

      const otherLocationDetails = state.newAllocationSet.locations.filter(
        (location) => location.locationId !== locationId
      );
      const allocationCountryId =
        state.newAllocationSet.locations?.first?.countryId || countryId;
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          locations: [
            {
              ...newLocation,
              locationId,
              allocations,
            },
            ...otherLocationDetails,
          ],
          locationCount: otherLocationDetails.length + 1,
          editedLocation: locationId,
          suggestion: [],
          searchText: null,
          allocationCountryId: allocationCountryId,
        },
      };

    case ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          versionCompareLoading: true,
          versionCompareDetails: {},
        },
      };
    case SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          versionCompareLoading: false,
          versionCompareDetails: action.data,
        },
      };
    case FAIL_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          versionCompareLoading: false,
          versionCompareDetails: {},
        },
      };
    case SUCCEED_REMOVE_ALLOCATION_LOCATION:
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          locations: action.data.allocationDetails,
          locationCount: action.data.totalCount,
        },
        latestVersionLocationCount:
          action.payload.locationFilter === "" && action.payload.countryId === 0
            ? action.data.totalCount
            : state.latestVersionLocationCount,
      };
    case REMOVE_ALLOCATION_LOCATION_NEW_ALLOCATION: {
      const newLocations = state.newAllocationSet.locations.filter(
        (location) => location.locationId !== action.payload.locationId
      );
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          locations: newLocations,
          allocationCountryId:
            newLocations.length > 0
              ? state.newAllocationSet.allocationCountryId
              : null,
        },
      };
    }
    case UPDATE_ISQUOTE_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          isQuote: action.payload.isQuote,
        },
      };

    case UPDATE_ALLOCATIONAME_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          allocationName: action.payload.allocationName,
          error: null,
        },
      };
    case UPDATE_ISSCORECARD_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          isScoreCard: action.payload.isScoreCard,
        },
      };
    case UPDATE_NOOFQUOTES_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          noOfQuotes: action.payload.noOfQuotes,
        },
      };
    case SUCCEED_CREATE_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          newAllocationId: action.payload.id,
        },
        showCreateNewAllocationSet: false,
        allocationVersionId: action.payload.revId,
      };
    case FAIL_CREATE_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: initialNewAllocationSet,
      };
    case ERROR_CREATE_NEW_ALLOCATION:
      return {
        ...state,
        newAllocationSet: {
          ...state.newAllocationSet,
          error: action.error,
        },
      };
    case SUCCEED_UPDATE_ISQUOTE_EDIT_ALLOCATION:
      return {
        ...state,
        allocationVersionDetail: {
          ...state.allocationVersionDetail,
          quote: action.data.isQuote,
          maxProviders: 0,
        },
      };
    case SUCCEED_UPDATE_ISSCORECARD_EDIT_ALLOCATION:
      return {
        ...state,
        allocationVersionDetail: {
          ...state.allocationVersionDetail,
          allowAutoUpdate: action.data.isScoreCard,
        },
      };
    case SUCCEED_UPDATE_NOOFQUOTES_EDIT_ALLOCATION:
      return {
        ...state,
        allocationVersionDetail: {
          ...state.allocationVersionDetail,
          maxProviders: action.data.maxProviders,
        },
      };
    case OPEN_ALLOCATION_SET_NAME_MODAL:
      return {
        ...state,
        updateName: {
          ...state.updateName,
          isOpen: action.data.isOpen,
        },
      };
    case SUCCEED_UPDATE_ALLOCATION_NAME:
      return {
        ...state,
        allocationSet: {
          ...state.allocationSet,
          name: action.data.name,
        },
        updateName: {
          ...state.updateName,
          isOpen: false,
          message: "",
        },
      };
    case FAIL_DUPLICATE_UPDATE_ALLOCATION_NAME:
      return {
        ...state,
        updateName: {
          ...state.updateName,
          message: action.data.message,
        },
      };
    case RESET_UPDATE_ALLOCATION_NAME_ERROR:
      return {
        ...state,
        updateName: {
          ...state.updateName,
          message: null,
        },
      };
    case SUCCEED_DISCARD_ALLOCATION_VERSION:
      return {
        ...state,
        allocationVersion: {
          ...state.allocationVersion,
          allocationVersions: state.allocationVersion.allocationVersions.filter(
            (version) => version.allocationPanelRevId !== action.data.allocRevId
          ),
          allocationVersionCount:
            state.allocationVersion.allocationVersionCount - 1,
        },
        allocationVersionId: state.allocationSet.allocRevId,
        allocationSet: {
          ...state.allocationSet,
          hasEditable: false,
          editableRevId: null,
        },
      };
    case SUCCEED_FETCH_PANEL_VERSION_DETAIL: {
      return {
        ...initialState,
        allocationVersionId: state.reloadDetail ? state.allocationVersionId : 0,
      };
    }
    case ATTEMPT_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        associatedPanel: {
          ...state.associatedPanel,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
      };
    case SUCCEED_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        associatedPanel: {
          ...state.associatedPanel,
          loading: false,
          panels: action.data.panels,
          panelCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        associatedPanel: {
          ...state.associatedPanel,
          loading: false,
          panels: [],
          panelCount: 0,
        },
      };
    case ATTEMPT_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: action.data,
        },
      };
    case FAIL_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: [],
        },
      };
    case CLEAR_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION:
      const existingKeys = state.filterOptions.locations.map(
        (v) => v.id + "-" + v.type + "-" + v.countryId
      );

      const suggestion = action.data.map((v) => {
        const locationIdNew = v.id + "-" + v.type + "-" + v.countryId;
        return {
          ...v,
          isExisting: existingKeys.includes(locationIdNew),
        };
      });

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationSuggestion: suggestion,
        },
      };
    case FAIL_FETCH_FILTER_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationSuggestion: [],
        },
      };
    case CLEAR_FILTER_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationSuggestion: [],
        },
      };
    case ADD_FILTER_LOCATION:
      const updatedSuggestion = state.filterOptions.locationSuggestion.map(
        (v) => {
          return {
            ...v,
            isExisting:
              v.id === action.location.id &&
              v.type === action.location.type &&
              v.countryId === action.location.countryId
                ? true
                : v.isExisting,
          };
        }
      );
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locations: [
            ...state.filterOptions.locations,
            { ...action.location, isExisting: true },
          ],
          locationSuggestion: updatedSuggestion,
        },
      };
    case DELETE_FILTER_LOCATION:
      const deletedSuggestion = state.filterOptions.locationSuggestion.map(
        (v) => {
          return {
            ...v,
            isExisting:
              v.id === action.payload.id &&
              v.type === action.payload.type &&
              v.countryId === action.payload.countryId
                ? false
                : v.isExisting,
          };
        }
      );
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locations: state.filterOptions.locations.filter(
            (location) =>
              location.countryId !== action.payload.countryId ||
              location.type !== action.payload.type ||
              location.id !== action.payload.id
          ),
          locationSuggestion: deletedSuggestion,
        },
      };
    case CHANGE_FILTER_COUNTRY:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          countryId: action.countryId,
          locationText: "",
          locationSuggestion: [],
        },
      };
    case FILTER_CLEAR_ALL:
      return {
        ...state,
        filterOptions: {
          ...initialFilterOptions,
          isFiltered: state.filterOptions.isFiltered,
          isOpenFilter: state.filterOptions.isOpenFilter,
        },
      };
    case ATTEMPT_FETCH_FILTER_VALFIRM_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirmText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_VALFIRM_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirmSuggestion: action.data,
        },
      };
    case FAIL_FETCH_FILTER_VALFIRM_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirmSuggestion: [],
        },
      };
    case CLEAR_FILTER_VALFIRM_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirmSuggestion: [],
        },
      };
    case ADD_FILTER_VALFIRM:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirms: [...state.filterOptions.valfirms, action.valfirm],
          keywordValfirms:
            action.valfirm.id === 0
              ? state.filterOptions.valfirmSuggestion
              : state.filterOptions.keywordValfirms,
        },
      };
    case DELETE_FILTER_VALFIRM:
      let deletedValfirmSuggestion = state.filterOptions.valfirmSuggestion;
      const newValfirms = state.filterOptions.valfirms.filter(
        (valfirm) => valfirm.id !== action.payload.id
      );
      if (action.payload.id === 0 && newValfirms.length > 0) {
        deletedValfirmSuggestion.unshift({
          id: 0,
          name: 'Add "' + state.filterOptions.valfirmText + '"',
        });
      }
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          valfirms: newValfirms,
          valfirmSuggestion: deletedValfirmSuggestion,
          keywordValfirms:
            action.id === 0 ? [] : state.filterOptions.keywordValfirms,
        },
      };
    case OPEN_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          isOpenFilter: action.isOpen,
        },
      };
    case COPY_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameText: state.filteredOptions.name,
          allocationSetStatus: state.filteredOptions.status,
          locations: state.filteredOptions.locations,
          valfirms: state.filteredOptions.valfirms,
          keywordValfirms: state.filteredOptions.keywordValfirms,
          countryId: state.filteredOptions.countryId,
          locationText: state.filteredOptions.locationText,
          valfirmText: state.filteredOptions.valfirmText,
          nameSuggestion: state.filteredOptions.nameSuggestion,
          locationSuggestion: state.filteredOptions.locationSuggestion,
          valfirmSuggestion: state.filteredOptions.valfirmSuggestion,
        },
      };
    case UPDATE_ALLOCATION_STATUS_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          allocationSetStatus: action.payload,
        },
      };
    case SUCCEED_BULK_UPDATE_SAVE_AS_DRAFT: {
      const newAllocDraftRevId =
        (action.allocNewDraftInfo?.length === 1 &&
          action.allocNewDraftInfo?.[0]?.allocDraftRevId) ||
        null;
      const updateState = action.addAnotherBulkUpdate
        ? {}
        : {
            reloadDetail: true,
            allocationVersionId:
              newAllocDraftRevId || state.allocationVersionId,
            allocationTab: 0,
          };
      return {
        ...state,
        ...updateState,
      };
    }
    case SUCCEED_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          existingValfirms: action.data,
        },
      };
    }
    case FAIL_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          existingValfirms: [],
        },
      };
    }
    case SET_ADD_VALFIRM_TEXT_SELECTED_VALFIRMS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          valfirmText: action.text,
        },
      };
    }
    case ADD_VALFIRM_SELECTED_VALFIRMS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          selectedValfirms: [
            ...state.allocationDetail.selectedValfirms,
            action.valfirm,
          ],
        },
      };
    }
    case DELETE_VALFIRM_SELECTED_VALFIRMS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          selectedValfirms: state.allocationDetail.selectedValfirms.filter(
            (valfirm) => valfirm.id !== action.valfirm.id
          ),
        },
      };
    }
    case ADD_LOCATION_SELECTED_LOCATIONS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          selectedLocations: [
            ...state.allocationDetail.selectedLocations,
            action.location,
          ],
        },
      };
    }
    case DELETE_LOCATION_SELECTED_LOCATIONS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          selectedLocations: state.allocationDetail.selectedLocations.filter(
            (loc) => loc.id !== action.location.id
          ),
        },
      };
    }
    case CLEAR_ALL_SELECTED_VALFIRMS_LOCATIONS: {
      return {
        ...state,
        allocationDetail: {
          ...state.allocationDetail,
          locationSuggestion: [],
          selectedValfirms: [],
          selectedLocations: [],
          valfirmText: "",
          searchText: "",
        },
      };
    }
    case "persist/REHYDRATE":
      // clear error on refresh. but weird rehydrate errors come in all the time, so don't clear it on those
      return {
        ...state,
      };
    default:
      return state;
  }
}
