import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  getFeeDetail,
  updateLocationOption,
  updateLocationTextChange,
  getLatestFeeActivationLog,
  removeFeeLocation,
  updateFeeBrackets,
} from "../../store/action/actionFee";
import {
  updateSelectedFees,
  updateSelectedFeeLocation,
} from "../../store/action/actionUI";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid2 as Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridCellParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";

import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import LocationModal from "./LocationModal";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import PanelExDialog from "../shared/PanelExDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";
import SearchIcon from "@mui/icons-material/Search";

interface ExpandMoreProps extends IconButtonProps {
  expand: string;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: theme.colors.grey.main,
  transform: expand === "false" ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface FeeDetailProps {
  feeVersionId: number;
}

export default function FeeDetail(props: FeeDetailProps) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    button: {
      minWidth: "150px",
      color: theme.palette.common.white,
      "& .MuiButton-startIcon": {
        color: theme.palette.common.white,
      },
      marginLeft: "4px",
    },
    select: {
      borderRadius: "4px",
      marginRight: "4px",
      width: "90px",
      paddingBottom: "0px",
    },
    detailsHeader: {
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.15px",
        color: "rgba(0,0,0,0.8)",
      },
    },
    detailsGrid: {
      width: "100%",
      cursor: "pointer",
      "& .MuiDataGrid-withBorderColor": {
        border: "0px",
      },
      "& .MuiDataGrid-cell": {
        padding: "0px",
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "0px",
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "0px",
      },
    },
    card: {
      width: "100%",
      padding: "12px 0 0 0",
      background: "#FAFBFC",
    },
    cardContent: {
      padding: "0px",
      background: theme.palette.common.white,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "0px",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      "&:last-child": {
        paddingBottom: "0px",
      },
    },
    locationHeader: {
      background: "#f5f5f5",
    },
    locationIcon: {
      background: "#f5f5f5",
    },
    expandHeader: {
      height: "32px",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 400,
        color: theme.palette.common.black,
        padding: "6px 12px",
      },
    },
    expandIcon: {
      height: "32px",
      display: "flex",
      justifyContent: "end",
      paddingRight: "12px",
    },
    feeCardContent: {
      padding: "0px",
      "&:last-child": {
        padding: "0px",
      },
    },
    feeGrid: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "12px",
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    feeValue: {
      textAlign: "right",
      paddingRight: "12px",
      display: "flex",
      justifyContent: "end",
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      justifyContent: "flex-end",
    },
    feeBracketCard: {
      width: "100%",
      marginTop: "12px",
      padding: "0px",
    },
    feeBracketCardContent: {
      background: theme.palette.info.lighter,
      paddingBottom: "24px",
    },
    feeBracketLocationHeader: {
      background: theme.palette.info.lighter,
    },
    feeBracketLocationIcon: {
      background: theme.palette.info.lighter,
    },
    feeBracketGridHeader: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "12px",
      "& .MuiTypography-root": {
        paddingLeft: "8px",
        color: theme.colors.grey.main,
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
      },
    },
    feeBracketGrid: {
      borderTop: "0px",
      padding: "12px",
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    feeText: {
      width: "100%",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    feeBracketMessage: {
      height: "327px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiTypography-root": {
        "&:first-of-type": {
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "28px",
          color: theme.colors.grey.dark,
        },
        "&:last-of-type": {
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: theme.colors.grey.main,
          marginTop: "20px",
        },
      },
    },
    feeBracketGridAction: {
      paddingTop: "0px",
      "& .MuiGrid-item": {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    feeDetailMessage: {
      width: "380px",
      marginTop: "85px",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",

      "& .MuiTypography-root": {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colors.grey.main,

        "&:first-of-type": {
          paddingBottom: "22px",
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "28px",
          color: theme.colors.grey.dark,
        },
        "&:last-of-type": {
          paddingTop: "8px",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "21px",
          color: theme.colors.grey.light,
        },
      },
    },
    searchButton: {
      minWidth: "38px",
      height: "38px",
      marginLeft: "4px",
      backgroundColor: "#3399FF",
      borderRadius: "4px",
      color: "#FFFFFF",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
    },
  }));
  const { classes } = useStyles();

  const feeVersionId = props.feeVersionId;
  const {
    feeSet,
    feeId,
    loading,
    requestedActivationLog,
    latestVersionLocationCount,
    readOnly,
  } = useSelector((state: RootState) => {
    const feeSet = state.fee.feeSet;
    return {
      feeSet: feeSet,
      feeId: feeSet.feePanelId,
      loading: state.fee.loading,
      requestedActivationLog: state.fee.latestFeeActivationLog,
      latestVersionLocationCount: state.fee.latestVersionLocationCount,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const { editable } = useSelector((state: RootState) => {
    const feeVersionDetail = state.fee.feeVersionDetail;
    return {
      editable:
        feeSet.feeRevId != feeVersionId &&
        feeVersionDetail.editable &&
        !feeVersionDetail.schedularRun &&
        !feeVersionDetail.discard &&
        requestedActivationLog &&
        requestedActivationLog.status !== "Queued",
    };
  });

  useEffect(() => {
    if (!!feeId && !!feeVersionId) {
      dispatch(
        getFeeDetail(feeId, feeVersionId, 0, paginationModel.pageSize, "", 1)
      );
    }
    paginationModel.page = 0;
    setPaginationModel(paginationModel);
  }, [dispatch, feeId, feeVersionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    locations,
    rowCount,
    currentPage,
    pageSize,
    searchOption,
    searchText,
    suggestions,
    editedLocation,
    selectedLocation,
    feePanelBrackets,
  } = useSelector((state: RootState) => {
    return {
      locations: state.fee.feeDetail.locations,
      rowCount: state.fee.feeDetail.locationCount,
      currentPage: state.fee.feeDetail.currentPage,
      pageSize: state.fee.feeDetail.pageSize,
      searchOption: state.fee.feeDetail.searchOption,
      searchText: state.fee.feeDetail.searchText || "",
      suggestions: state.fee.feeDetail.suggestion,
      editedLocation: state.fee.feeDetail.editedLocation,
      selectedLocation: state.ui.location,
      feePanelBrackets: state.fee.feeDetail.feePanelBrackets,
    };
  });

  useEffect(() => {
    if (feeId) {
      dispatch(getLatestFeeActivationLog(feeId));
    }
  }, [dispatch, feeId]);

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    dispatch(
      getFeeDetail(
        feeId,
        feeVersionId,
        newPage,
        newPageSize,
        searchText,
        searchOption
      )
    );
  };

  const [expanded, setExpanded] = React.useState(true);
  const [selectedLocationId, setSelectedLocationId] = React.useState(null);
  const [isLocationModalOpen, setLocationModalOpen] = React.useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [selectedLocationName, setSelectedLocationName] = React.useState("");

  useEffect(() => {
    if (editedLocation) {
      setExpanded(true);
      setSelectedLocationId(editedLocation);
    }
  }, [editedLocation]);

  useEffect(() => {
    if (locations.length > 0) {
      setExpanded(true);
      setSelectedLocationId(locations[0].feePanelFilterId);
    }
  }, [locations]);

  const handleExpandClick = (locationId: any) => {
    setSelectedLocationId(locationId);
    if (selectedLocationId === locationId) {
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    dispatch(updateLocationOption(event.target.value as string));
  };

  const handleSuggestionSelect = (suggestion: any) => {
    dispatch(
      getFeeDetail(feeId, feeVersionId, 0, 15, suggestion.name, searchOption)
    );
  };

  const handleInputChange = (input: string) => {
    if (input !== searchText) {
      dispatch(
        updateLocationTextChange(
          input,
          searchOption,
          feeId,
          feeVersionId,
          !editable
        )
      );
    }
  };

  const handleTextFieldKeyDown = (input: any) => {
    dispatch(getFeeDetail(feeId, feeVersionId, 0, 15, input, searchOption));
  };

  const handleEditLocation = (
    id: number,
    type: string,
    name: string,
    isExisting: boolean,
    countryId: number,
    countryName: string,
    feePanelFilterId: any
  ) => {
    dispatch(
      updateSelectedFeeLocation(
        id,
        type,
        name,
        isExisting,
        countryId,
        countryName,
        feePanelFilterId,
        feeVersionId
      )
    );
    if (isExisting) {
      //copy existing fees to ui state
      locations
        .filter((loc: any) => loc.feePanelFilterId === feePanelFilterId)
        .map((location: any) => {
          const fees = location.fees.reduce((acc: any, v: any) => {
            acc.push({
              feePanelFeesId: v.feePanelFeesId,
              feePanelBracketId: v.feePanelBracketId,
              fee: Number(v.fee).toFixed(2),
              lower: v.lower,
              upper: v.upper,
              isEdited: null,
            });
            return acc;
          }, []);
          dispatch(updateSelectedFees(fees));
        });
    }
    setLocationModalOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      flex: 8,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const feePanelFilterId = params.row.feePanelFilterId;
        const index =
          params.api.getRowIndexRelativeToVisibleRows(feePanelFilterId);
        const shouldExpandCard =
          expanded && selectedLocationId === feePanelFilterId;
        const {
          postcodeId,
          postcodeName,
          regionId,
          regionName,
          stateId,
          stateName,
          countryName,
          countryId,
          fees,
        } = params.row;
        const type = postcodeName
          ? "Postcode"
          : regionName
          ? "Region"
          : stateName
          ? "State"
          : "Country";
        const name = postcodeName || regionName || stateName || countryName;
        const id = postcodeId || regionId || stateId || countryId;

        const locationName = [
          countryName,
          type === "Country" ? null : type + " " + name,
        ]
          .filter(Boolean)
          .join(" ");

        return (
          <>
            <Card
              key={`card-${index}`}
              data-testid={`card-${index}`}
              className={classes.card}
            >
              <CardContent className={classes.cardContent}>
                <Grid container key={`location-${index}`}>
                  <Grid
                    size={10}
                    className={`${classes.expandHeader} ${classes.locationHeader}`}
                  >
                    <Typography
                      variant="body2"
                      data-testid={`location-${index}`}
                    >
                      {locationName}
                    </Typography>
                  </Grid>
                  <Grid
                    size={2}
                    className={`${classes.expandIcon} ${classes.locationIcon}`}
                  >
                    {editable && !readOnly && (
                      <div>
                        <IconButton
                          id={`btn-del-loc-${index}`}
                          data-testid={`btn-del-loc-${index}`}
                          sx={{ padding: "5px", marginRight: "4px" }}
                          onClick={(ev) => {
                            setOpenRemove(true);
                            setSelectedLocationName(locationName);
                            setSelectedLocationId(feePanelFilterId);
                            ev.stopPropagation();
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              color: `${theme.colors.grey.main}`,
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          id={`btn-edit-loc-${index}`}
                          data-testid={`btn-edit-loc-${index}`}
                          sx={{ padding: "5px", marginRight: "4px" }}
                          onClick={(ev) => {
                            handleEditLocation(
                              id,
                              type,
                              name,
                              true,
                              countryId,
                              countryName,
                              feePanelFilterId
                            );
                            ev.stopPropagation();
                          }}
                        >
                          <EditIcon
                            color="primary"
                            sx={{ width: "18px", height: "18px" }}
                          />
                        </IconButton>
                      </div>
                    )}
                    <ExpandMore
                      sx={{ padding: "2px" }}
                      data-testid={`expand-${index}`}
                      expand={shouldExpandCard.toString()}
                      aria-expanded={shouldExpandCard}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Grid>
                </Grid>

                <Collapse in={shouldExpandCard} timeout="auto" unmountOnExit>
                  <CardContent className={classes.feeCardContent}>
                    {fees
                      .sort((a: any, b: any) => a.lower - b.lower)
                      .map((fee: any) => (
                        <Grid
                          container
                          key={`fee-${index}-${fee.feePanelFeesId}`}
                          className={classes.feeGrid}
                        >
                          <Grid
                            size={8}
                            key={`fee-name-${fee.feePanelBracketId}`}
                            data-testid={`fee-name-${fee.feePanelBracketId}`}
                          >
                            <Typography>
                              ${" "}
                              {fee.lower === 0
                                ? "Any"
                                : toLocaleNumberFormat(fee.lower, false)}{" "}
                              - $ {toLocaleNumberFormat(fee.upper, false)}
                            </Typography>
                          </Grid>
                          <Grid
                            size={4}
                            key={`fee-value-${fee.feePanelBracketId}`}
                            data-testid={`fee-value-${fee.feePanelBracketId}`}
                            className={classes.feeValue}
                          >
                            <Typography>
                              $ {toLocaleNumberFormat(fee.fee, true)} + GST
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </CardContent>
                </Collapse>
              </CardContent>
            </Card>
          </>
        );
      },
    },
  ];

  const handleRemoveLocation = () => {
    dispatch(
      removeFeeLocation(
        feeId,
        feeVersionId,
        selectedLocationId,
        selectedLocationName,
        pageSize,
        searchText,
        searchOption
      )
    );
    setOpenRemove(false);
  };

  const [feeBracketExpanded, setFeeBracketExpanded] = React.useState(false);
  const initialFeeBracket: any = [
    {
      index: 0,
      lower: 0,
      upper: null,
      added: false,
    },
  ];
  const [feeBracketsLocal, setFeeBracketsLocal] =
    React.useState(initialFeeBracket);

  const handleFeeBracketExpandClick = () => {
    setFeeBracketExpanded(!feeBracketExpanded);
  };

  const updateLower = (index: number, lower: any) => {
    setFeeBracketsLocal(
      feeBracketsLocal.map((b: any) =>
        b.index === index ? { ...b, lower: lower } : b
      )
    );
  };
  const updateUpper = (index: number, upper: any) => {
    setFeeBracketsLocal(
      feeBracketsLocal.map((b: any) =>
        b.index === index
          ? { ...b, upper: upper }
          : b.index === index + 1
          ? { ...b, lower: parseInt(upper) }
          : b
      )
    );
  };

  const addFeeBracket = (index: number) => {
    setFeeBracketsLocal([
      ...feeBracketsLocal.map((b: any) =>
        b.index === index
          ? {
              ...b,
              lower: parseInt(b.lower),
              upper: parseInt(b.upper),
              added: true,
            }
          : b
      ),
      {
        index: index + 1,
        lower: parseInt(feeBracketsLocal[index].upper) + 1,
        upper: null,
        added: false,
      },
    ]);
  };

  const deleteFeeBracket = (index: number) => {
    setFeeBracketsLocal(
      feeBracketsLocal
        .filter((b: any) => b.index !== index)
        .map((b: any) =>
          b.index === index + 1
            ? {
                ...b,
                index: parseInt(b.index) - 1,
                lower:
                  index === 0 ? 0 : parseInt(feeBracketsLocal[index - 1].upper),
              }
            : b.index > index
            ? { ...b, index: parseInt(b.index) - 1 }
            : b
        )
    );
  };

  const handleUpdateFeeBrackets = () => {
    const addedFeeBrackets = feeBracketsLocal.filter((b: any) => b.added);
    dispatch(updateFeeBrackets(feeId, feeVersionId, addedFeeBrackets));
  };

  const feeBracketReadonly = !editable || feePanelBrackets.length > 0;
  const feeBrackets = feeBracketReadonly ? feePanelBrackets : feeBracketsLocal;

  const toLocaleNumberFormat = (input: any, hasDecimal: boolean) => {
    return hasDecimal
      ? Number(input).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : Number(input).toLocaleString();
  };
  const toLocaleInputFormat = (input: any) => {
    return input.toLocaleString();
  };

  const anchorEl =
    document.getElementById("location-filter-country") || undefined;

  return (
    <Box sx={{ display: "flex", width: "100%" }} data-testid="fee-detail">
      <Grid container size={12}>
        {loading ? (
          <Grid size={12}>
            <Box
              data-testid="fee-detail-progress"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container size={12}>
            <Grid size={5} className={classes.detailsHeader}>
              <Typography data-testid="fee-detail-title">
                {rowCount} Locations
              </Typography>
            </Grid>
            <Grid size={7}>
              <Stack direction="row" className={classes.stack}>
                <Select
                  labelId="location-filter-country-label"
                  id="location-filter-country"
                  data-testid="location-filter-country"
                  value={searchOption}
                  className={classes.select}
                  onChange={handleCountryChange}
                  variant={"outlined"}
                >
                  <MenuItem value={"1"}>AU</MenuItem>
                  <MenuItem value={"2"}>NZ</MenuItem>
                </Select>
                <PanelExAutocomplete
                  id="location-filter"
                  options={suggestions}
                  value={searchText}
                  getOptionLabel={(option: any) =>
                    option.name || searchText || ""
                  }
                  placeholder="Search Location"
                  onChange={(value: string | null) => {
                    handleSuggestionSelect(value);
                  }}
                  onInputChange={(value: any) => {
                    handleInputChange(value);
                  }}
                  onKeyDown={handleTextFieldKeyDown}
                  anchor={anchorEl}
                  popperWidth={495}
                  popperPlacement={"bottom-start"}
                  showResetIcon={true}
                  onReset={() => {
                    handleInputChange("");
                    handleTextFieldKeyDown("");
                  }}
                  renderOption={(props: any, option: any) => {
                    const {
                      id,
                      name,
                      type,
                      countryId,
                      countryName,
                      isExisting,
                    } = option;
                    const displayName =
                      type === "Country"
                        ? [name, type].join(" ")
                        : [countryName, type, name].join(" ");
                    const regex = new RegExp(
                      escapedSpecialCharacters(searchText),
                      "gi"
                    );
                    const response = displayName.replace(
                      regex,
                      function (str: any) {
                        return (
                          "<span style='font-weight:500'>" + str + "</span>"
                        );
                      }
                    );
                    return editable ? (
                      !isExisting && (
                        <li
                          data-testid={`add-location-label-${id}`}
                          id={`add-location-label-${id}`}
                          {...props}
                          style={{ justifyContent: "space-between" }}
                          onClick={(ev) => {
                            handleEditLocation(
                              id,
                              type,
                              name,
                              isExisting,
                              countryId,
                              countryName,
                              null
                            );
                            ev.stopPropagation();
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: `${response}` }}
                          />
                          <AddCircleIcon
                            data-testid={`add-location-icon-${id}`}
                            id={`add-location-icon-${id}`}
                            style={{ color: "#757575" }}
                          />
                        </li>
                      )
                    ) : (
                      <li
                        data-testid={`add-location-label-${id}`}
                        {...props}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: `${response}` }}
                        ></div>
                        <AddCircleIcon
                          data-testid={`add-location-icon-${id}`}
                          style={{ color: "rgba(0, 0, 0, 0.12)" }}
                        />
                      </li>
                    );
                  }}
                />
                <Button
                  id="search-button"
                  data-testid="search-button"
                  variant="contained"
                  size="small"
                  className={classes.searchButton}
                  startIcon={<SearchIcon />}
                  onClick={() => handleTextFieldKeyDown(searchText)}
                />
              </Stack>
            </Grid>

            <Grid size={12}>
              <Card
                id="card-fee-bracket"
                data-testid="card-fee-bracket"
                className={classes.feeBracketCard}
              >
                <CardContent
                  className={`${classes.cardContent} ${classes.feeBracketCardContent}`}
                >
                  <Grid container onClick={handleFeeBracketExpandClick}>
                    <Grid
                      size={10}
                      className={`${classes.expandHeader} ${classes.feeBracketLocationHeader}`}
                    >
                      <Typography
                        variant="body2"
                        data-testid="fee-bracket-title"
                      >
                        Estimated Property Value Range
                      </Typography>
                    </Grid>
                    <Grid
                      size={2}
                      className={`${classes.expandIcon} ${classes.feeBracketLocationIcon}`}
                    >
                      <ExpandMore
                        sx={{ padding: "2px" }}
                        data-testid="expand-fee-bracket"
                        expand={feeBracketExpanded.toString()}
                        aria-expanded={feeBracketExpanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Grid>
                  </Grid>

                  <Collapse
                    in={feeBracketExpanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <CardContent className={classes.feeCardContent}>
                      {feeBrackets &&
                        (editable || feePanelBrackets.length > 0) && (
                          <React.Fragment>
                            <Grid
                              container
                              id="fee-bracket-grid-header"
                              data-testid="fee-bracket-grid-header"
                              className={classes.feeBracketGridHeader}
                            >
                              <Grid size={3}>
                                <Typography>{"Lower >="} </Typography>
                              </Grid>
                              <Grid size={3}>
                                <Typography>{"Upper <"}</Typography>
                              </Grid>
                            </Grid>

                            {feeBrackets
                              .sort((a: any, b: any) => a.lower - b.lower)
                              .map((fee: any, index: number) => (
                                <Grid
                                  container
                                  key={`fee-bracket-${index}`}
                                  className={`${classes.feeGrid} ${classes.feeBracketGrid}`}
                                >
                                  <Grid
                                    size={3}
                                    key={`fee-lower-${index}`}
                                    data-testid={`fee-lower-${index}`}
                                  >
                                    <TextField
                                      id={`fee-lower-value-${index}`}
                                      data-testid={`fee-lower-value-${index}`}
                                      className={classes.feeText}
                                      label="From"
                                      placeholder={
                                        fee.lower ? fee.lower.toString() : "Any"
                                      }
                                      disabled={
                                        feeBracketReadonly ||
                                        index != 0 ||
                                        fee.added
                                      }
                                      value={
                                        fee.lower
                                          ? toLocaleInputFormat(fee.lower)
                                          : ""
                                      }
                                      onChange={(event: any) => {
                                        const input = event.target.value;
                                        const regex = /^[0-9\b]+$/;
                                        if (input === "" || regex.test(input)) {
                                          updateLower(index, input);
                                        }
                                      }}
                                      slotProps={{
                                        input: {
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    size={3}
                                    sx={{ paddingLeft: "5px" }}
                                    key={`fee-upper-${index}`}
                                    data-testid={`fee-upper-${index}`}
                                  >
                                    <TextField
                                      id={`fee-upper-value-${index}`}
                                      data-testid={`fee-upper-value-${index}`}
                                      className={classes.feeText}
                                      label="To"
                                      disabled={feeBracketReadonly || fee.added}
                                      placeholder="Enter value"
                                      value={
                                        fee.upper
                                          ? toLocaleInputFormat(fee.upper)
                                          : ""
                                      }
                                      onChange={(event: any) => {
                                        const input = event.target.value;
                                        const regex = /^[0-9\b]+$/;
                                        if (input === "" || regex.test(input)) {
                                          updateUpper(index, input);
                                        }
                                      }}
                                      slotProps={{
                                        input: {
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    size={3}
                                    sx={{ paddingLeft: "5px", width: "32px" }}
                                    key={`fee-action-${index}`}
                                    data-testid={`fee-action-${index}`}
                                  >
                                    {feeBracketReadonly ? null : fee.added ? (
                                      <IconButton
                                        id={`fee-delete-${index}`}
                                        data-testid={`fee-delete-${index}`}
                                        disabled={
                                          index < feeBrackets.length - 2
                                        }
                                        onClick={() => deleteFeeBracket(index)}
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        id={`fee-add-${index}`}
                                        data-testid={`fee-add-${index}`}
                                        onClick={() => addFeeBracket(index)}
                                        disabled={
                                          fee.upper
                                            ? parseInt(fee.upper) < fee.lower
                                            : true
                                        }
                                      >
                                        <AddOutlinedIcon />
                                      </IconButton>
                                    )}
                                  </Grid>
                                </Grid>
                              ))}
                            {!feeBracketReadonly ? (
                              feeBrackets.filter((b: any) => b.added).length >
                              0 ? (
                                <Grid
                                  container
                                  className={`${classes.feeGrid} ${classes.feeBracketGrid} ${classes.feeBracketGridAction}`}
                                >
                                  <Grid size={6}>
                                    <Button
                                      id="btn-add-brackets"
                                      data-testid="btn-add-brackets"
                                      className={classes.button}
                                      variant="contained"
                                      onClick={handleUpdateFeeBrackets}
                                    >
                                      Done
                                    </Button>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Box
                                  id="fee-bracket-message"
                                  data-testid="fee-bracket-message"
                                  className={classes.feeBracketMessage}
                                >
                                  <Typography>
                                    Create an Estimated Property Value Range
                                  </Typography>
                                  <Typography>
                                    Set up a range to determine valuation fees.
                                  </Typography>
                                </Box>
                              )
                            ) : null}
                          </React.Fragment>
                        )}
                    </CardContent>
                  </Collapse>
                </CardContent>
              </Card>
            </Grid>

            {rowCount > 0 ? (
              <Grid size={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: 400,
                    width: "100%",
                  }}
                >
                  <DataGridPro
                    data-testid="tbl-fee-details"
                    className={classes.detailsGrid}
                    getRowId={(row) => row.feePanelFilterId}
                    rows={locations}
                    rowCount={rowCount}
                    getRowHeight={() => "auto"}
                    columns={columns}
                    columnHeaderHeight={0}
                    pagination
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={[15, 20, 50]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                    onCellClick={(
                      params: GridCellParams,
                      event: MuiEvent<React.MouseEvent>
                    ) => {
                      handleExpandClick(params.row.feePanelFilterId);
                      event.defaultMuiPrevented = true;
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              <Grid
                size={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  id="fee-details-message"
                  data-testid="fee-details-message"
                  className={classes.feeDetailMessage}
                >
                  {latestVersionLocationCount === 0 ? (
                    <>
                      <RequestQuoteOutlinedIcon
                        sx={{
                          width: "65px",
                          height: "65px",
                          color: theme.colors.grey.lighter,
                        }}
                        id="fee-details-message-icon"
                        data-testid="fee-details-message-icon"
                      />
                      <Typography>You have not set any fee</Typography>
                      <Typography>
                        Add a location to set valuation fee.
                      </Typography>
                      <Typography>
                        Valuation fees may vary depending on the property's
                        estimated value and location.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography paddingBottom={"5px"}>
                        No results found.
                      </Typography>
                      <Button
                        id="back-to-search"
                        data-testid="back-to-search"
                        variant="text"
                        onClick={() => {
                          handleInputChange("");
                          handleTextFieldKeyDown("");
                        }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back To Search Result
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            )}

            {selectedLocation && isLocationModalOpen && (
              <LocationModal
                feeId={feeId}
                feeVersionId={feeVersionId}
                location={selectedLocation}
                open={isLocationModalOpen}
                setOpen={setLocationModalOpen}
              />
            )}
          </Grid>
        )}
      </Grid>
      <PanelExDialog
        id="remove-dialog"
        data-testid="remove-dialog"
        open={openRemove}
        title={`Are you sure you want to remove ${selectedLocationName}?`}
        submitText="Yes, Remove"
        submitEnabled={true}
        onClose={() => setOpenRemove(false)}
        onSubmit={handleRemoveLocation}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
        >
          <Typography variant="body1">
            Removing this location will remove any associated fee amounts. This
            action cannot be undone.
          </Typography>
        </Box>
      </PanelExDialog>
    </Box>
  );
}
