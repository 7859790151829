import axios from "../../utilities/axiosInstance";

export const fetchPanelSet = (panelId) => {
  const url = `/api/panels/${panelId}`;
  return axios.get(url);
};

export const fetchPanelVersionDetail = (panelId, panelVersionId) => {
  const url = `/api/panels/${panelId}/versions/${panelVersionId}`;
  return axios.get(url);
};

export const fetchPanels = (filter) => {
  const url = `/api/panels`;
  return axios.post(url, filter);
};

export const createNewPanelSetAPI = (panelDetail) => {
  const url = `/api/panels/newPanel`;
  return axios.post(url, panelDetail);
};

export const updatePanelSet = (panelDetail) => {
  const url = `/api/panels/${panelDetail.id}/versions/${panelDetail.panelSetRevId}/update`;
  return axios.post(url, panelDetail);
};

export const fetchAllocationSuggestion = (text) => {
  const url = `/api/allocations/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const updatePanelAllocationAPI = (
  allocationId,
  panelId,
  panelVersionId,
  userId
) => {
  const url = `/api/panels/${panelId}/versions/${panelVersionId}/updateAllocation`;
  return axios.post(url, { userId: userId, allocationId: allocationId });
};

export const resetPanelAllocationAPI = (panelId, panelVersionId, userId) => {
  const url = `/api/panels/${panelId}/versions/${panelVersionId}/resetAllocation`;
  return axios.post(url, { value: userId });
};

export const fetchPanelActivationLogs = (
  panelSetId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) => {
  const url = `/api/panels/${panelSetId}/activationLogs?currentPage=${currentPage}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
  return axios.get(url);
};

export const fetchLatestPanelActivationLog = (panelSetId) => {
  const url = `/api/panels/${panelSetId}/latestActivationLog`;
  return axios.get(url);
};

export const fetchFeeSuggestion = (text) => {
  const url = `/api/fees/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const updatePanelFeeAPI = (feeId, panelId, panelVersionId, userId) => {
  const url = `/api/panels/${panelId}/versions/${panelVersionId}/updateFee`;
  return axios.post(url, { userId: userId, feeId: feeId });
};

export const fetchPanelVersions = (panelSetId, currentPage, pageSize) => {
  const url = `/api/panels/${panelSetId}/versions?currentPage=${currentPage}&pageSize=${pageSize}`;
  return axios.get(url);
};

export const fetchUpdatePanelVersionNotes = (
  panelSetId,
  panelVersionId,
  userId,
  notes
) => {
  const url = `/api/panels/${panelSetId}/versions/${panelVersionId}/updateNotes`;
  return axios.post(url, { userId: userId, notes: notes });
};

export const copyVersion = (panelId, panelVersionId, userId) => {
  const url = `/api/panels/${panelId}/versions/${panelVersionId}/copy`;
  return axios.post(url, { value: userId });
};

export const resetPanelFeeAPI = (panelId, panelVersionId, userId) => {
  const url = `/api/panels/${panelId}/versions/${panelVersionId}/resetFee`;
  return axios.post(url, { value: userId });
};

export const schedulePanelVersionActivationApi = (
  panelId,
  panelRevId,
  userId,
  scheduled
) => {
  const url = `/api/panels/${panelId}/versions/${panelRevId}/schedulePanelActivation`;
  return axios.post(url, { userId: userId, scheduled: scheduled });
};

export const cancelPanelActivationLog = (
  panelId,
  panelActivationLogId,
  userId
) => {
  const url = `/api/panels/${panelId}/activationLogs/${panelActivationLogId}/cancel`;
  return axios.post(url, { value: userId });
};

export const fetchDiscardPanelVersion = (panelSetId, panelSetRevId, userId) => {
  const url = `/api/panels/${panelSetId}/versions/${panelSetRevId}/discard`;
  return axios.post(url, { value: userId });
};

export const fetchPanelVersionComparisonDetails = (panelId, versionsList) => {
  const url = `/api/panels/${panelId}/versions/compare`;
  return axios.post(url, versionsList);
};

export const acknowledgePanelActivationLog = (
  panelId,
  panelActivationLogId,
  userId
) => {
  const url = `/api/panels/${panelId}/activationLogs/${panelActivationLogId}/acknowledge`;
  return axios.post(url, { value: userId });
};

export const exportPanels = (filter) => {
  const url = `/api/panels/export`;
  return axios.post(url, filter, { responseType: "blob" });
};

export const fetchBulkUpdateInfo = (panelSets) => {
  const url = `/api/panels/bulkUpdate`;
  return axios.post(url, { panels: panelSets });
};
