import React, { useState, HTMLAttributes, ReactNode } from "react";
import { Chip, InputAdornment, Popper, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import SearchIcon from "@mui/icons-material/Search";

interface PanelExAutocomplete2Props {
  id: string;
  options: any;
  value?: any;
  inputValue?: string;
  isDisabled?: boolean;
  validInput?: boolean;
  showBorder?: boolean;
  showIcon?: boolean;
  limitTags?: number;
  placeholder?: string;
  anchor?: HTMLElement;
  popperWidth?: number;
  popperMaxHeight?: number;
  popperPlacement?: string;
  isFilterOptions?: boolean;
  getOptionLabel?: (value: any) => string;
  onChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: any
  ) => ReactNode;
}

const PanelExAutocomplete2: React.FC<PanelExAutocomplete2Props> = ({
  id,
  options,
  value,
  inputValue,
  isDisabled = false,
  validInput = true,
  showBorder = true,
  showIcon = true,
  limitTags = -1,
  placeholder,
  anchor,
  popperWidth = 0,
  popperMaxHeight = 0,
  popperPlacement = "bottom",
  getOptionLabel,
  onChange,
  onInputChange,
  renderOption,
}) => {
  const theme = useTheme();
  const anchorRef = React.useRef();
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles()(() => ({
    autocomplete: {
      padding: "0px",
      minHeight: "38px",
      background: "#FFFFFF",
      border: "1px solid",
      borderColor: theme.colors.grey.light,
      borderRadius: "4px",
      "&:focus": {
        border: "2px solid #3399FF",
      },
      "& .MuiInputBase-root": {
        minHeight: "36px",
      },
      "& .MuiOutlinedInput-root": {
        paddingTop: "3px",
        paddingBottom: "3px",
        borderRadius: "4px",
        paddingRight: "9px !important",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
      "& #users-filter-input": {
        fontSize: "13px",
      },
      "& .MuiAutocomplete-tag": {
        overflow: "hidden",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "18px",
      },
      "& .MuiChip-root": {
        height: "26px",
        backgroundColor: "#EEEEEE",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "18px",
      },
      "& .MuiIcon-root": { mt: -2 },
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiTextField-root": {
        borderColor: theme.palette.error.main,
      },
    },
    noBorder: {
      "& .MuiTextField-root": {
        border: "0px",
      },
    },
    disabled: {
      borderColor: theme.colors.grey.lighter,
      "& .MuiInputAdornment-root": {
        color: theme.colors.grey.lighter,
      },
    },

    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "3px",
      margin: "0px",
      zIndex: "5",

      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
        width: `${popperWidth === 0 ? "100%" : popperWidth + "px"}`,

        "& .MuiAutocomplete-listbox": {
          maxHeight: `${popperMaxHeight > 0 && popperMaxHeight + "px"}`,
        },
      },
    },
  }));
  const { classes } = useStyles();
  return (
    <Autocomplete
      ref={anchorRef}
      id={`${id}-input`}
      data-testid={`${id}-input`}
      multiple
      limitTags={limitTags}
      popupIcon={null}
      getLimitTagsText={(more) => `+${more} more | `}
      fullWidth
      className={`${classes.autocomplete} ${validInput ? null : classes.error} 
      ${showBorder ? null : classes.noBorder} ${
        isDisabled ? classes.disabled : null
      }`}
      disableClearable
      freeSolo
      disablePortal
      autoHighlight
      disableCloseOnSelect
      options={options}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newValue) => {
        onInputChange && onInputChange(newValue);
        event.stopPropagation();
      }}
      onChange={(event: any, newValue, reason, detail) => {
        if (reason === "removeOption") {
          onChange && onChange(detail?.option);
        }
        event.stopPropagation();
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(e, r) => {
        r !== "toggleInput" && setOpen(false);
        e.preventDefault();
      }}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params: any) => (
        <TextField
          {...params}
          id={`${id}-textfield`}
          data-testid={`${id}-textfield`}
          placeholder={placeholder}
          slotProps={{
            input: {
              ...params.InputProps,
              type: "search",
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    {showIcon && (
                      <SearchIcon
                        id={`${id}-textfield-start-icon`}
                        data-testid={`${id}-textfield-start-icon`}
                      />
                    )}
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            },
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { id, name } = option;
          const label = name || option.inputValue || "";
          return (
            <Chip
              {...getTagProps({ index })}
              label={label}
              title={label}
              id={`${id}-chip`}
              data-testid={`${id}-chip`}
            />
          );
        })
      }
      PopperComponent={(props: any) => (
        <Popper
          data-testid={`${id}-popper`}
          {...props}
          className={classes.popper}
          placement={popperPlacement}
          anchorEl={anchor || anchorRef.current}
          modifiers={[
            {
              name: "preventOverflow",
              options: {
                padding: 25,
              },
            },
          ]}
        ></Popper>
      )}
    />
  );
};

export default PanelExAutocomplete2;
