import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import { updateFeeBracketsFeeNewFee } from "../../store/action/actionUI";
import { updateLocationNewFeeSet } from "../../store/action/actionFee";
import {
  Box,
  FormControl,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";

interface LocationModalProps {
  feeId: number;
  feeVersionId: number;
  location: LocationProps;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

interface LocationProps {
  id: number;
  type: string;
  name: string;
  isExisting?: boolean;
  countryId: number;
  countryName: string;
}

const LocationModalNewFee: React.FC<LocationModalProps> = ({
  feeId,
  feeVersionId,
  location,
  open,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    feeGrid: {
      width: "100%",
      cursor: "pointer",
      "& .MuiTableCell-root": {
        padding: "8px",
        height: "50px",
      },
      "& .MuiFormControl-root": {
        height: "auto",
      },
    },
    feeHeader: {
      fontSize: "13px",
      fontWeight: 500,
    },
    feeText: {
      width: "100%",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.error.main,
      },
    },
  }));
  const { classes } = useStyles();

  const { fees, feePanelBrackets } = useSelector((state: RootState) => {
    return {
      fees: state.ui.newFee.fees || [],
      feePanelBrackets: state.fee.newFeeSet.feePanelBrackets || [],
    };
  });

  let hasValueInHigherBracket = false;
  const feesSorted =
    feePanelBrackets &&
    feePanelBrackets
      .sort((a: any, b: any) => b.lower - a.lower)
      .map((bracket: any) => {
        const { lower, upper } = bracket;
        const { fee } =
          fees.find((f: any) => f.lower === lower && f.upper === upper) || {};

        const isInvalid =
          [null, undefined].includes(fee) && hasValueInHigherBracket;
        hasValueInHigherBracket =
          hasValueInHigherBracket || ![null, undefined].includes(fee);
        return {
          fee,
          lower,
          upper,
          isInvalid,
        };
      })
      .reverse();

  const hasValue =
    feesSorted.filter((f: any) => ![null, undefined].includes(f.fee)).length >
    0;

  const handleUpdateFee = (lower: string, upper: string, fee: any) => {
    dispatch(updateFeeBracketsFeeNewFee(lower, upper, fee));
  };

  const handleSubmit = () => {
    dispatch(updateLocationNewFeeSet(feeId, feeVersionId, location, fees));
    setOpen(false);
  };

  const displayName =
    location.type === "Country"
      ? location.name
      : [location.countryName, location.type, location.name].join(" ");

  const toLocaleNumberFormat = (input: any) => {
    return Number(input).toLocaleString();
  };

  return (
    <PanelExDialog
      id="location-dialog"
      data-testid="location-dialog"
      open={open}
      title={"Add to New Fee: " + displayName}
      submitText={location.isExisting ? "Update Fee" : "Add Location"}
      cancelText={"Cancel and Go Back"}
      onClose={() => setOpen(false)}
      onSubmit={handleSubmit}
      submitEnabled={
        feesSorted &&
        feesSorted.filter((f: any) => f.isInvalid).length === 0 &&
        hasValue
      }
      showAction={true}
    >
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px 20px 30px",
        }}
      >
        <FormControl
          sx={{
            mt: 2,
            mb: 2,
            minWidth: 120,
            minHeight: 591,
            maxHeight: 591,
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.feeGrid} data-testid="fee-table">
              <TableBody>
                {feePanelBrackets && (
                  <TableRow
                    key="fee-table-header-row"
                    className={classes.feeHeader}
                  >
                    <TableCell sx={{ width: "50%" }}>Value Range</TableCell>
                    <TableCell sx={{ width: "50%" }}>Valuation Fees</TableCell>
                  </TableRow>
                )}
                {feesSorted &&
                  feesSorted.map((fee: any, index: any) => {
                    const { lower, upper, fee: value, isInvalid } = fee;
                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          id={`fee-table-name-${index}`}
                          data-testid={`fee-table-name-${index}`}
                          className={`${isInvalid ? classes.error : null}`}
                        >
                          $ {lower === 0 ? "Any" : toLocaleNumberFormat(lower)}{" "}
                          - $ {toLocaleNumberFormat(upper)}
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            id={`fee-table-value-${index}`}
                            data-testid={`fee-table-value-${index}`}
                            className={`${classes.feeText} ${
                              isInvalid ? classes.error : null
                            }`}
                            placeholder="Enter Valuation Fee"
                            onClick={(e: any) => e.stopPropagation()}
                            value={value || ""}
                            onChange={(event: any) => {
                              const input = event.target.value;
                              const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
                              if (
                                input === "" ||
                                (regex.test(input) && input < 10000000)
                              ) {
                                handleUpdateFee(
                                  lower,
                                  upper,
                                  input === "" ? null : input
                                );
                              }
                            }}
                            slotProps={{
                              input: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    + GST
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </FormControl>
      </Box>
    </PanelExDialog>
  );
};

export default LocationModalNewFee;
