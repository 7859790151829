import {
  UPDATE_SEARCH_OPTION,
  ATTEMPT_FETCH_SUGGESTION,
  SUCCEED_FETCH_SUGGESTION,
  FAIL_FETCH_SUGGESTION,
  CLEAR_SUGGESTION,
  FETCH_OPEN_SNACKBAR,
  FETCH_CLOSE_SNACKBAR,
  FETCH_OPEN_ACTION_SNACKBAR,
  FETCH_CLOSE_ACTION_SNACKBAR,
  UPDATE_SELECTED_LOCATION,
  UPDATE_SELECTED_LOCATION_NEW_ALLOCATION,
  UPDATE_SELECTED_ALLOCATIONS,
  UPDATE_SELECTED_ALLOCATIONS_NEW_ALLOCATION,
  ATTEMPT_FETCH_ORG_SUGGESTION,
  SUCCEED_FETCH_ORG_SUGGESTION,
  FAIL_FETCH_ORG_SUGGESTION,
  CLEAR_ORG_SUGGESTION,
  SUCCEED_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
  FAIL_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
  CLEAR_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
  ATTEMPT_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
  UPDATE_VALFIRM_ALLOCATION,
  DELETE_VALFIRM_ALLOCATION,
  UPDATE_VALFIRM_ALLOCATION_NEW_ALLOCATION,
  DELETE_VALFIRM_ALLOCATION_NEW_ALLOCATION,
  UPDATE_SELECTED_FEES,
  UPDATE_SELECTED_FEE_LOCATION,
  UPDATE_FEE_BRACKET_FEE,
  UPDATE_SELECTED_LOCATION_NEW_FEE,
  UPDATE_FEE_BRACKET_FEE_NEW_FEE,
  UPDATE_SELECTED_FEES_NEW_FEE,
  OPEN_FAIL_ACTIVATION_CANCEL,
  ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM,
  SUCCEED_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM,
  FAIL_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM,
  CLEAR_PROP_TYPE_SUGGESTION_PANEL_FORM,
  ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
  SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
  FAIL_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
  CLEAR_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
  ATTEMPT_FETCH_FUNDER_SUGGESTION_PANEL_FORM,
  CLEAR_FUNDER_SUGGESTION_PANEL_FORM,
  FAIL_FETCH_FUNDER_SUGGESTION_PANEL_FORM,
  SUCCEED_FETCH_FUNDER_SUGGESTION_PANEL_FORM,
  ATTEMPT_FETCH_CLIENT_SUGGESTION_PANEL_FORM,
  CLEAR_CLIENT_SUGGESTION_PANEL_FORM,
  FAIL_FETCH_CLIENT_SUGGESTION_PANEL_FORM,
  SUCCEED_FETCH_CLIENT_SUGGESTION_PANEL_FORM,
  ATTEMPT_FETCH_NEW_LOCATION_SUGGESTION,
  SUCCEED_FETCH_NEW_LOCATION_SUGGESTION,
  FAIL_FETCH_NEW_LOCATION_SUGGESTION,
  CLEAR_NEW_LOCATION_SUGGESTION,
  RESET_LOCATION_SELECTION,
} from "../staticSagas/sagaUI";
import {
  SHOW_CREATE_NEW_FEE,
  SUCCEED_FETCH_FEES,
} from "../staticSagas/sagaFee";
import {
  SHOW_PANEL_SET_FORM,
  SUCCEED_FETCH_PANELS,
} from "../staticSagas/sagaPanel";
import {
  SUCCEED_FETCH_ALLOCATIONS,
  SUCCEED_UPDATE_ALLOCATION_DETAIL,
} from "../staticSagas/sagaAllocation";

const initDuration = 3000;
const initialActionSnackbar = {
  open: false,
  message: "",
  variant: "",
  actionType: "",
};
const initPanelFormSuggestions = {
  propTypeSearchText: "",
  propTypeSuggestion: [],
  serviceTypeSearchText: "",
  serviceTypeSuggestion: [],
  funderSearchText: "",
  funderSuggestion: [],
  clientSearchText: "",
  clientSuggestion: [],
};
export const initialState = {
  searchOption: "panels",
  searchText: "",
  suggestion: [],
  snackbar: {
    open: false,
    duration: initDuration,
    message: "",
    variant: "",
    actionType: null,
    isIcon: false,
    showLoading: false,
    id: null,
  },
  actionSnackbar: initialActionSnackbar,
  orgSearchText: null,
  orgSuggestion: [],
  locationSearchText: null,
  locationSuggestion: [],
  location: null,
  valfirms: [],
  fees: [],
  newAllocation: {
    orgSearchText: null,
    orgSuggestion: [],
    location: null,
    valfirms: [],
  },
  newFee: {
    location: null,
    fees: [],
  },
  panelFormSuggestions: initPanelFormSuggestions,
  openFailCancel: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUCCEED_FETCH_ALLOCATIONS:
    case SUCCEED_FETCH_FEES:
    case SUCCEED_FETCH_PANELS:
      return {
        ...state,
        searchText: action.data.name,
      };
    case UPDATE_SEARCH_OPTION:
      return {
        ...state,
        searchOption: action.option,
        searchText: "",
        suggestion: [],
      };
    case ATTEMPT_FETCH_SUGGESTION:
      return {
        ...state,
        searchText: action.text,
      };
    case SUCCEED_FETCH_SUGGESTION:
      return {
        ...state,
        suggestion: action.data,
      };
    case FAIL_FETCH_SUGGESTION:
      return {
        ...state,
        suggestion: [],
      };
    case CLEAR_SUGGESTION:
      return {
        ...state,
        suggestion: [],
      };
    case FETCH_CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
          message: "",
          variant: "",
        },
      };
    case FETCH_OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: true,
          message: action.payload.message,
          variant: action.payload.variant,
          isIcon: action.payload.isIcon,
          showLoading: action.payload.showLoading,
          duration: action.payload.duration || initDuration,
          actionType: action.payload.actionType || null,
          id: action.payload.id,
        },
      };
    case FETCH_CLOSE_ACTION_SNACKBAR:
      return {
        ...state,
        actionSnackbar: initialActionSnackbar,
      };
    case FETCH_OPEN_ACTION_SNACKBAR:
      return {
        ...state,
        actionSnackbar: {
          ...state.actionSnackbar,
          open: true,
          message: action.payload.message,
          variant: action.payload.variant,
          actionType: action.payload.actionType || null,
        },
      };
    case UPDATE_SELECTED_LOCATION: {
      const { type: locationType, name, countryName } = action.payload;
      const searchText =
        locationType === "Country"
          ? name
          : [countryName, locationType, name].join(" ");
      return {
        ...state,
        locationSearchText: searchText,
        location: action.payload,
        orgSuggestion: [],
        orgSearchText: "",
        valfirms: [], //clear valfirm list for the location
      };
    }
    case UPDATE_SELECTED_LOCATION_NEW_ALLOCATION:
      let locationIdNew;
      const { type, id, countryId } = action.payload;
      if (type === "Postcode") {
        locationIdNew = id + "-null-null-" + countryId;
      } else if (type === "Region") {
        locationIdNew = "null-" + id + "-null-" + countryId;
      } else if (type === "State") {
        locationIdNew = "null-null-" + id + "-" + countryId;
      } else {
        locationIdNew = "null-null-null-" + countryId;
      }
      return {
        ...state,
        newAllocation: {
          location: action.payload,
          orgSuggestion: [],
          orgSearchText: "",
          valfirms: [], //clear valfirm list for the location
          editedLocation: locationIdNew,
        },
      };
    case UPDATE_SELECTED_ALLOCATIONS:
      return {
        ...state,
        valfirms: action.payload, //copy existing valfirm list for the location to edit
      };
    case UPDATE_SELECTED_ALLOCATIONS_NEW_ALLOCATION:
      return {
        ...state,
        newAllocation: {
          ...state.newAllocation,
          orgSuggestion: [],
          orgSearchText: "",
          valfirms: action.payload, //copy existing valfirm list for the location to edit
        },
      };
    case ATTEMPT_FETCH_ORG_SUGGESTION:
      return {
        ...state,
        orgSearchText: action.text,
      };
    case SUCCEED_FETCH_ORG_SUGGESTION:
      return {
        ...state,
        orgSuggestion: action.data,
      };
    case FAIL_FETCH_ORG_SUGGESTION:
      return {
        ...state,
        orgSuggestion: [],
      };
    case CLEAR_ORG_SUGGESTION:
      return {
        ...state,
        orgSuggestion: [],
      };
    case ATTEMPT_FETCH_NEW_LOCATION_SUGGESTION:
      return {
        ...state,
        locationSearchText: action.text,
      };
    case SUCCEED_FETCH_NEW_LOCATION_SUGGESTION:
      return {
        ...state,
        locationSuggestion: action.data,
      };
    case FAIL_FETCH_NEW_LOCATION_SUGGESTION:
      return {
        ...state,
        locationSuggestion: [],
      };
    case CLEAR_NEW_LOCATION_SUGGESTION:
      return {
        ...state,
        locationSuggestion: [],
      };
    case RESET_LOCATION_SELECTION:
      return {
        ...state,
        locationSearchText: "",
        locationSuggestion: [],
        orgSearchText: "",
        orgSuggestion: [],
        location: null,
        valfirms: [],
      };
    case SUCCEED_UPDATE_ALLOCATION_DETAIL:
      return {
        ...state,
        locationSearchText: "",
        locationSuggestion: [],
        orgSearchText: "",
        orgSuggestion: [],
        location: null,
        valfirms: [],
      };
    case ATTEMPT_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocation: {
          ...state.newAllocation,
          orgSearchText: action.text,
        },
      };

    case SUCCEED_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocation: {
          ...state.newAllocation,
          orgSuggestion: action.data,
        },
      };
    case FAIL_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocation: {
          ...state.newAllocation,
          orgSuggestion: [],
        },
      };
    case CLEAR_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION:
      return {
        ...state,
        newAllocation: {
          ...state.newAllocation,
          orgSuggestion: [],
        },
      };

    case UPDATE_VALFIRM_ALLOCATION: {
      const { valfirmId, value } = action.payload;
      const isEdit =
        state.valfirms.find((v) => v.valfirmId === valfirmId) !== undefined;

      return {
        ...state,
        valfirms: isEdit
          ? state.valfirms.map((v) =>
              v.valfirmId === valfirmId
                ? {
                    ...v,
                    value: value,
                    isEdited: !!v.allocationPanelAllocationsId, //keep false for new valfirm
                    isDeleted: false,
                  }
                : v
            )
          : [...state.valfirms, action.payload],
      };
    }
    case DELETE_VALFIRM_ALLOCATION: {
      const { allocationPanelAllocationsId, valfirmId } = action.payload;
      return {
        ...state,
        valfirms: !!allocationPanelAllocationsId
          ? state.valfirms.map((v) =>
              v.allocationPanelAllocationsId === allocationPanelAllocationsId
                ? {
                    ...v,
                    isEdited: false,
                    isDeleted: true,
                  }
                : v
            )
          : [...state.valfirms.filter((v) => v.valfirmId !== valfirmId)],
      };
    }
    case UPDATE_VALFIRM_ALLOCATION_NEW_ALLOCATION: {
      const { valfirmId, value } = action.payload;
      const isEdit =
        state.newAllocation.valfirms.find((v) => v.valfirmId === valfirmId) !==
        undefined;

      return {
        ...state,
        newAllocation: {
          ...state.newAllocation,
          valfirms: isEdit
            ? state.newAllocation.valfirms.map((v) =>
                v.valfirmId === valfirmId
                  ? {
                      ...v,
                      value: value,
                      isEdited: !!v.allocationPanelAllocationsId, //keep false for new valfirm
                      isDeleted: false,
                    }
                  : v
              )
            : [...state.newAllocation.valfirms, action.payload],
        },
      };
    }
    case DELETE_VALFIRM_ALLOCATION_NEW_ALLOCATION: {
      return {
        ...state,
        newAllocation: {
          ...state.newAllocation,

          valfirms: state.newAllocation.valfirms.filter(
            (firm) => firm.valfirmId !== action.payload.valfirmId
          ),
        },
      };
    }
    case UPDATE_SELECTED_FEES:
      return {
        ...state,
        fees: action.payload, //copy existing fees list for the location to edit
      };
    case UPDATE_SELECTED_FEE_LOCATION:
      return {
        ...state,
        location: action.payload,
        fees: [], //clear fees list for the location
      };
    case UPDATE_FEE_BRACKET_FEE: {
      const { feePanelFeesId, feePanelBracketId, fee } = action.payload;
      const isEdited =
        !!feePanelFeesId ||
        state.fees.find((f) => f.feePanelBracketId === feePanelBracketId) !==
          undefined;

      return {
        ...state,
        fees: isEdited
          ? state.fees.map((v) =>
              v.feePanelBracketId === feePanelBracketId
                ? {
                    ...v,
                    fee,
                    isEdited, //keep true for updated or deleted fee bracket
                  }
                : v
            )
          : [...state.fees, action.payload],
      };
    }
    case SHOW_CREATE_NEW_FEE:
      return {
        ...state,
        newFee: {
          location: null,
          fees: [],
        },
      };
    case UPDATE_SELECTED_LOCATION_NEW_FEE: {
      let locationIdNew;
      const { type, id, countryId } = action.payload;
      if (type === "Postcode") {
        locationIdNew = id + "-null-null-" + countryId;
      } else if (type === "Region") {
        locationIdNew = "null-" + id + "-null-" + countryId;
      } else if (type === "State") {
        locationIdNew = "null-null-" + id + "-" + countryId;
      } else {
        locationIdNew = "null-null-null-" + countryId;
      }
      return {
        ...state,
        newFee: {
          location: action.payload,
          fees: [], //clear fee values for the location
          editedLocation: locationIdNew,
        },
      };
    }
    case UPDATE_SELECTED_FEES_NEW_FEE:
      return {
        ...state,
        newFee: {
          ...state.newFee,
          fees: action.payload, //copy existing fees list for the location to edit
        },
      };
    case UPDATE_FEE_BRACKET_FEE_NEW_FEE: {
      const { lower, upper, fee } = action.payload;
      const isEdited =
        state.newFee.fees.find(
          (f) => f.lower === lower && f.upper === upper
        ) !== undefined;

      return {
        ...state,
        newFee: {
          ...state.newFee,
          fees: isEdited
            ? state.newFee.fees.map((v) =>
                v.lower === lower && v.upper === upper
                  ? {
                      ...v,
                      fee,
                      isEdited, //keep true for updated or deleted fee bracket
                    }
                  : v
              )
            : [...state.newFee.fees, action.payload],
        },
      };
    }
    case SHOW_PANEL_SET_FORM:
      return {
        ...state,
        panelFormSuggestions: initPanelFormSuggestions,
      };
    case ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          propTypeSearchText: action.text,
        },
      };
    case SUCCEED_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          propTypeSuggestion: action.data,
        },
      };
    case FAIL_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          propTypeSuggestion: [],
        },
      };
    case CLEAR_PROP_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          propTypeSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          serviceTypeSearchText: action.text,
        },
      };
    case SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          serviceTypeSuggestion: action.data,
        },
      };
    case FAIL_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          serviceTypeSuggestion: [],
        },
      };
    case CLEAR_SERVICE_TYPE_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          serviceTypeSuggestion: [],
        },
      };
    case OPEN_FAIL_ACTIVATION_CANCEL:
      const newState = {
        ...state,
        openFailCancel: action.payload.openFailCancel,
      };
      return {
        ...newState,
      };
    case ATTEMPT_FETCH_FUNDER_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          funderSearchText: action.text,
        },
      };
    case SUCCEED_FETCH_FUNDER_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          funderSuggestion: action.data,
        },
      };
    case FAIL_FETCH_FUNDER_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          funderSuggestion: [],
        },
      };
    case CLEAR_FUNDER_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          funderSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_CLIENT_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          clientSearchText: action.text,
        },
      };
    case SUCCEED_FETCH_CLIENT_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          clientSuggestion: action.data,
        },
      };
    case FAIL_FETCH_CLIENT_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          clientSuggestion: [],
        },
      };
    case CLEAR_CLIENT_SUGGESTION_PANEL_FORM:
      return {
        ...state,
        panelFormSuggestions: {
          ...state.panelFormSuggestions,
          clientSuggestion: [],
        },
      };
    default:
      return state;
  }
}
