import { alpha, createTheme, darken } from "@mui/material";
import { grey } from "@mui/material/colors";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

const themeColors = {
  primary: "#3399ff",
  neutral: "#757575",
  secondary: "#6E759F",
  success: "#4caf50",
  warning: "#ff9800",
  error: "#e31b0c",
  info: grey[600],
  black: "#000000",
  white: "#ffffff",
  grey: grey[500],
  draft: "#d7ea11",
  tertiary: "#006ccb",
};

const colors = {
  gradients: {
    blue1: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
    blue2: "linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)",
    blue3: "linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)",
    blue4: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)",
    blue5: "linear-gradient(135deg, #97ABFF 10%, #123597 100%)",
    orange1: "linear-gradient(135deg, #FCCF31 0%, #F55555 100%)",
    orange2: "linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)",
    orange3: "linear-gradient(120deg, #f6d365 0%, #fda085 100%)",
    purple1: "linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)",
    purple3: "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
    pink1: "linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)",
    pink2: "linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)",
    green1: "linear-gradient(135deg, #FFF720 0%, #3CD500 100%)",
    green2: "linear-gradient(to bottom, #00b09b, #96c93d)",
    black1: "linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)",
    black2: "linear-gradient(60deg, #29323c 0%, #485563 100%)",
  },
  shadows: {
    success:
      "0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)",
    error:
      "0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)",
    info: "0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)",
    primary:
      "0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)",
    warning:
      "0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)",
    card: "0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)",
    cardSm:
      "0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)",
    cardLg:
      "0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)",
  },
  layout: {
    general: {
      bodyBg: "#f2f5f9",
    },
    sidebar: {
      background: themeColors.white,
      textColor: themeColors.secondary,
      dividerBg: "#f2f5f9",
      menuItemColor: "#242E6F",
      menuItemColorActive: themeColors.primary,
      menuItemBg: themeColors.white,
      menuItemBgActive: "#f2f5f9",
      menuItemIconColor: alpha(themeColors.secondary, 0.3),
      menuItemIconColorActive: themeColors.primary,
      menuItemHeadingColor: darken(themeColors.secondary, 0.3),
    },
  },
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    trueWhite: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      100: themeColors.black,
    },
  },
  primary: {
    lightest: "#eef6ff",
    lighter: alpha("#3399ff", 0.5),
    light: "#79c9ff",
    main: themeColors.primary,
    dark: "#1166bb",
    darker: "#134C86",
  },
  secondary: {
    lightest: alpha(themeColors.secondary, 0.1),
    lighter: alpha(themeColors.secondary, 0.25),
    light: alpha(themeColors.secondary, 0.85),
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2),
    darker: darken(themeColors.secondary, 0.2),
  },
  success: {
    lightest: "#DDFDE9",
    lighter: "#A1FFBD",
    light: "#00e676",
    main: themeColors.success,
    dark: "#3b873e",
    darker: "#1e4620",
  },
  warning: {
    lightest: "#fff5e9",
    lighter: "#ffe8ae",
    light: "#ffb547",
    main: themeColors.warning,
    dark: "#c77700",
    darker: "#5f2b01",
  },
  error: {
    lightest: "#fee9e8",
    lighter: "#f88078",
    light: "#f44336",
    main: themeColors.error,
    dark: "#a02925",
    darker: "#571917",
  },
  info: {
    lightest: grey[100],
    lighter: "#eaf4fc",
    light: grey[400],
    main: grey[600],
    dark: grey[800],
    darker: grey[900],
  },
  neutral: {
    lightest: "#f7f7fa",
    lighter: "#e0e0e0",
    light: "#9e9e9e",
    main: themeColors.neutral,
    dark: "#424242",
    darker: "#212121",
  },
  grey: {
    lightest: alpha(themeColors.black, 0.02),
    lighter: alpha(themeColors.black, 0.12),
    light: alpha(themeColors.black, 0.38),
    main: alpha(themeColors.black, 0.6),
    dark: alpha(themeColors.black, 0.8),
    darker: alpha(themeColors.black, 0.87),
  },
  draft: {
    lightest: "#f8ffbb",
    lighter: "#f7ffa4",
    light: "#f4ff81",
    main: themeColors.draft,
    dark: "#afb42b",
    darker: "#434a00",
  },
  tertiary: {
    lightest: "#eef6ff",
    lighter: "#8c9eff",
    light: "#536dfe",
    main: themeColors.tertiary,
    dark: "#0c2bd6",
    darker: "#001488",
  },
};

const fontFamily = {
  display: "Poppins",
  body: "Source Sans Pro",
};

const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

const lineHeight = {
  s: "18px",
  m: "24px",
  l: "32px",
  xl: "36px",
  xxl: "38px",
};

const fontSize = {
  "25": "11px",
  "50": "13px",
  "75": "14px",
  "100": "16px",
  "200": "18px",
  "300": "20px",
  "400": "22px",
  "500": "24px",
  "600": "26px",
  "700": "30px",
  "800": "33px",
};

export const CLTheme = createTheme({
  // direction: i18n.dir(),
  colors: {
    gradients: {
      blue1: colors.gradients.blue1,
      blue2: colors.gradients.blue2,
      blue3: colors.gradients.blue3,
      blue4: colors.gradients.blue4,
      blue5: colors.gradients.blue5,
      orange1: colors.gradients.orange1,
      orange2: colors.gradients.orange2,
      orange3: colors.gradients.orange3,
      purple1: colors.gradients.purple1,
      purple3: colors.gradients.purple3,
      pink1: colors.gradients.pink1,
      pink2: colors.gradients.pink2,
      green1: colors.gradients.green1,
      green2: colors.gradients.green2,
      black1: colors.gradients.black1,
      black2: colors.gradients.black2,
    },
    shadows: {
      success: colors.shadows.success,
      error: colors.shadows.error,
      primary: colors.shadows.primary,
      info: colors.shadows.info,
      warning: colors.shadows.warning,
    },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white,
      },
      trueWhite: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white,
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black,
      },
    },
    neutral: {
      lightest: colors.neutral.lightest,
      lighter: colors.neutral.lighter,
      light: colors.neutral.light,
      main: colors.neutral.main,
      dark: colors.neutral.dark,
      darker: colors.neutral.darker,
    },
    grey: {
      lightest: colors.grey.lightest,
      lighter: colors.grey.lighter,
      light: colors.grey.light,
      main: colors.grey.main,
      dark: colors.grey.dark,
      darker: colors.grey.darker,
    },
    success: {
      lightest: colors.success.lightest,
      lighter: colors.success.lighter,
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      darker: colors.success.darker,
    },
    draft: {
      lightest: colors.draft.lightest,
      lighter: colors.draft.lighter,
      light: colors.draft.light,
      main: colors.draft.main,
      dark: colors.draft.dark,
      darker: colors.draft.darker,
    },
    warning: {
      lightest: colors.warning.lightest,
      lighter: colors.warning.lighter,
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      darker: colors.warning.darker,
    },
    error: {
      lightest: colors.error.lightest,
      lighter: colors.error.lighter,
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      darker: colors.error.darker,
    },
    tertiary: {
      lightest: colors.tertiary.lightest,
      lighter: colors.tertiary.lighter,
      light: colors.tertiary.light,
      main: colors.tertiary.main,
      dark: colors.tertiary.dark,
      darker: colors.tertiary.darker,
    },
    primary: {
      lightest: colors.primary.lightest,
      lighter: colors.primary.lighter,
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
      darker: colors.primary.darker,
    },
    info: {
      lightest: colors.info.lightest,
      lighter: colors.info.lighter,
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      darker: colors.info.darker,
    },
    secondary: {
      lightest: colors.secondary.lightest,
      lighter: colors.secondary.lighter,
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
      darker: colors.secondary.darker,
    },
  },
  header: {
    height: "80px",
    background: colors.alpha.white[100],
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main,
  },
  spacing: 9,
  palette: {
    common: {
      black: colors.alpha.black[100],
      white: colors.alpha.white[100],
    },
    mode: "light",
    draft: {
      lightest: colors.draft.lightest,
      lighter: colors.draft.lighter,
      light: colors.draft.light,
      main: colors.draft.main,
      dark: colors.draft.dark,
      darker: colors.draft.darker,
    },
    primary: {
      lightest: colors.primary.lightest,
      lighter: colors.primary.lighter,
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
      darker: colors.primary.darker,
    },
    secondary: {
      lightest: colors.secondary.lighter,
      lighter: colors.secondary.lighter,
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
      darker: colors.secondary.darker,
    },
    error: {
      lightest: colors.error.lightest,
      lighter: colors.error.lighter,
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      darker: colors.error.darker,
      contrastText: colors.alpha.white[100],
    },
    success: {
      lightest: colors.success.lightest,
      lighter: colors.success.lighter,
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      darker: colors.success.darker,
      contrastText: colors.alpha.white[100],
    },
    info: {
      lightest: colors.info.lightest,
      lighter: colors.info.lighter,
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      darker: colors.info.darker,
      contrastText: colors.alpha.white[100],
    },
    warning: {
      lightest: colors.warning.lightest,
      lighter: colors.warning.lighter,
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      darker: colors.warning.darker,
      contrastText: colors.alpha.white[100],
    },
    neutral: {
      lightest: colors.neutral.lightest,
      lighter: colors.neutral.lighter,
      light: colors.neutral.light,
      main: colors.neutral.main,
      dark: colors.neutral.dark,
      darker: colors.neutral.darker,
    },
    tertiary: {
      lightest: colors.tertiary.lightest,
      lighter: colors.tertiary.lighter,
      light: colors.tertiary.light,
      main: colors.tertiary.main,
      dark: colors.tertiary.dark,
      darker: colors.tertiary.darker,
    },
    text: {
      primary: alpha(colors.grey.darker, 0.87),
      secondary: alpha(colors.grey.main, 0.6),
      disabled: alpha(colors.grey.light, 0.38),
    },
    background: {
      paper: colors.alpha.white[100],
      default: colors.layout.general.bodyBg,
    },
    action: {
      active: colors.alpha.black[100],
      hover: colors.primary.lighter,
      hoverOpacity: 0.1,
      selected: colors.alpha.black[10],
      selectedOpacity: 0.1,
      disabled: colors.alpha.black[50],
      disabledBackground: colors.alpha.black[5],
      disabledOpacity: 0.38,
      focus: colors.alpha.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12,
    },
    tonalOffset: 0.5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 905,
      md: 1240,
      lg: 1600,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": ["Poppins"],
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: "bold",
        },
        colorDefault: {
          background: colors.alpha.black[30],
          color: colors.alpha.white[100],
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        avatar: {
          background: colors.alpha.black[10],
          fontSize: 13,
          color: colors.alpha.black[70],
          fontWeight: "bold",

          "&:first-of-type": {
            border: 0,
            background: "transparent",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: 13,
          fontWeight: "bold",
          transition: "all .2s",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: colors.alpha.black[10],
          border: 0,
          height: 1,
        },
        vertical: {
          height: "auto",
          width: 1,

          "&.MuiDivider-flexItem.MuiDivider-fullWidth": {
            height: "auto",
          },
          "&.MuiDivider-absolute.MuiDivider-fullWidth": {
            height: "100%",
          },
        },
        withChildren: {
          "&:before, &:after": {
            border: 0,
          },
        },
        wrapper: {
          background: colors.alpha.white[100],
          fontWeight: "bold",
          height: 24,
          lineHeight: "24px",
          marginTop: -12,
          color: "inherit",
          textTransform: "uppercase",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          "&.MuiIconButton-root": {
            padding: 8,
          },
        },
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          border: 0,
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: themeColors.white,
          },
          ".even.MuiDataGrid-row": {
            backgroundColor: "#f9f9f9",
          },
          "& .MuiDataGrid-row:hover, &.Mui-hovered": {
            backgroundColor: colors.info.lighter,
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
          "& .even.MuiDataGrid-row:hover, &.Mui-hovered": {
            backgroundColor: colors.info.lighter,
            "@media (hover: none)": {
              backgroundColor: colors.neutral.lighter,
            },
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: colors.primary.lightest,
          },
          "& .MuiDataGrid-cell:focus": {
            outlineWidth: 0,
          },
          "& ul": {
            lineHeight: "normal",
            width: "100%",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
          },
          "& .MuiCheckbox-root.Mui-disabled": {
            background: colors.alpha.black[5],
            colors: colors.grey.lighter,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: "none",

          "&.MuiButton-contained": {
            color: colors.alpha.white[100],
            backgroundColor: colors.primary.main,

            "& .MuiButton-startIcon,.MuiButton-endIcon": {
              color: colors.alpha.white[100],
            },
            "&.Mui-disabled": {
              backgroundColor: colors.grey.lighter,
              pointerEvents: "auto",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 4,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          alignItems: "center",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "div",
          subtitle2: "div",
          body1: "div",
          body2: "div",
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "22px",
          letter: "0.16px",
          height: "22px",
          marginBottom: "16px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: colors.tertiary.main,
          },
          "& .MuiTab-root": {
            fontFamily: fontFamily.display,
            fontWeight: fontWeight.semiBold,
            fontSize: fontSize["50"],
            lineHeight: lineHeight.s,

            "&.Mui-selected": {
              color: colors.tertiary.main,
            },
          },
        },
      },
    },
    MuiSnackbar: {
      variants: [
        {
          props: { variant: "info" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: "#323232",
            },
          },
        },
        {
          props: { variant: "success" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: "#4CAF50",
            },
          },
        },
        {
          props: { variant: "error" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: "#F44336",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          "& .MuiSnackbarContent-root": {
            borderRadius: "4px",
            boxShadow:
              "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
          },

          "& .MuiSnackbarContent-message": {
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          color: alpha(colors.grey.darker, 0.87),
          fontFamily: fontFamily.display,
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: colors.tertiary.main,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.grey.dark,
          borderRadius: "5px",
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.semiBold,
      fontSize: fontSize["700"],
      lineHeight: lineHeight.xxl,
    },
    h2: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.medium,
      fontSize: fontSize["600"],
      lineHeight: lineHeight.xxl,
    },
    h3: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.medium,
      fontSize: fontSize["300"],
      lineHeight: lineHeight.l,
    },
    h4: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.medium,
      fontSize: fontSize["200"],
      lineHeight: lineHeight.m,
    },
    h5: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.medium,
      fontSize: fontSize["100"],
      lineHeight: lineHeight.m,
    },
    h6: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.regular,
      fontSize: fontSize["100"],
      lineHeight: lineHeight.m,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.regular,
      fontSize: fontSize["75"],
      lineHeight: lineHeight.m,
    },
    button: {
      fontFamily: fontFamily.display,
      fontWeight: 500,
    },
    caption: {
      fontSize: 13,
      textTransform: "uppercase",
      color: colors.alpha.black[50],
    },
    subtitle1: {
      fontSize: 14,
      color: colors.alpha.black[70],
    },
    subtitle2: {
      fontWeight: fontWeight.regular,
      fontSize: 15,
      color: colors.alpha.black[70],
    },
    overline: {
      fontSize: 13,
      fontWeight: fontWeight.bold,
      textTransform: "uppercase",
    },
    body1Text: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeight.light,
      fontSize: fontSize["200"],
      lineHeight: lineHeight.xxl,
    },
    body2Strong: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.medium,
      fontSize: fontSize["75"],
      lineHeight: lineHeight.m,
    },
    body3: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.regular,
      fontSize: fontSize["50"],
      lineHeight: lineHeight.s,
    },
    body3Strong: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.medium,
      fontSize: fontSize["50"],
      lineHeight: lineHeight.s,
    },
    infoText: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeight.regular,
      fontSize: fontSize["75"],
      lineHeight: lineHeight.s,
    },
    small: {
      fontFamily: fontFamily.display,
      fontWeight: fontWeight.regular,
      fontSize: fontSize["25"],
      lineHeight: lineHeight.s,
    },
  },
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
});
