import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import { makeStyles } from "tss-react/mui";
import { RootState } from "../../store/staticReducers/reducers";
import {
  changeFeeTab,
  changeFeeVersionId,
  getFeeActivationLogs,
  getLatestFeeActivationLog,
} from "../../store/action/actionFee";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { Tooltip } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import PanelExDialog from "../shared/PanelExDialog";

interface FeeActivationsProps {
  feeId: number;
  feeVersionId: number;
}

export default function FeeActivationLogs(props: FeeActivationsProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const timezone = moment.tz.guess();
  const timezoneAbbr = moment().tz(timezone).format("z");
  const feeId = props.feeId;
  const feeVersionId = props.feeVersionId;

  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleErrorClose = () => {
    setErrorMessage("");
    setOpenError(false);
  };

  const useStyles = makeStyles()(() => ({
    versionsHeader: {
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.15px",
        color: "rgba(0,0,0,0.8)",
      },
    },
    versionId: {
      color: theme.palette.primary.main,
    },
    errorButton: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  }));
  const { classes } = useStyles();

  const {
    activationLogs,
    loading,
    rowCount,
    currentPage,
    pageSize,
    sortBy,
    sortDir,
    feeVersionDetail,
  } = useSelector((state: RootState) => {
    return {
      activationLogs: state.fee.activationLog.activationLogs,
      loading: state.fee.activationLog.loading,
      rowCount: state.fee.activationLog.activationLogCount || 0,
      currentPage: state.fee.activationLog.currentPage || 0,
      pageSize: state.fee.activationLog.pageSize || 15,
      sortBy: state.fee.activationLog.sortBy || "requested",
      sortDir: state.fee.activationLog.sortDir || "desc",
      feeVersionDetail: state.fee.feeVersionDetail,
    };
  });

  useEffect(() => {
    if (feeId) {
      dispatch(getLatestFeeActivationLog(feeId));
    }
  }, [dispatch, feeId]);

  const columns: GridColDef[] = [
    {
      field: "feePanelRevId",
      headerName: "ID",
      width: 90,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={`Load to preview v${params.row.feePanelRevId}`}
              arrow
            >
              <span
                data-testid={`id-${params.row.feePanelRevId}`}
                id={`id-${params.row.feePanelRevId}`}
                className={classes.versionId}
                onClick={() => {
                  dispatch(changeFeeVersionId(params.row.feePanelRevId));
                  dispatch(changeFeeTab(0));
                }}
              >
                {params.row.feePanelRevId}
              </span>
            </Tooltip>
            {params.row.feePanelRevId === feeVersionId &&
              params.row.feePanelActivationLogId ===
                feeVersionDetail.latestActivationLogId && (
                <RemoveRedEye
                  data-testid="icon-selected-id"
                  id="icon-selected-id"
                  sx={{ paddingLeft: "8px", color: theme.palette.primary.main }}
                />
              )}
          </>
        );
      },
    },
    {
      field: "requested",
      headerName: "Requested",
      width: 230,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "scheduled",
      headerName: "Scheduled",
      width: 240,
      editable: false,
      sortable: false,
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "processed",
      headerName: "Processed",
      width: 240,
      editable: false,
      sortable: false,
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "user",
      headerName: "User",
      width: 230,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.user
          ? params.row.user.first + " " + params.row.user.last
          : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const feePanelActivationLogId = params.row.feePanelActivationLogId;
        const index = params.api.getRowIndexRelativeToVisibleRows(
          feePanelActivationLogId
        );
        return params.row.status === "Error" ||
          params.row.status === "Acknowledged" ? (
          <>
            <div
              className={classes.errorButton}
              data-testid={`btn-error-${index}`}
              id={`btn-error-${index}`}
              onClick={(e) => {
                setErrorMessage(params.row.errorMessage);
                setOpenError(true);
                e.preventDefault();
              }}
            >
              Error
            </div>
          </>
        ) : (
          params.row.status
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: sortBy,
      sort: sortDir,
    },
  ]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchFeeActivationLogs(feeId, newPage, newPageSize, sortBy, sortDir);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const field =
      newSortModel[0].field === "feePanelRevId"
        ? "fee_panel_rev_id"
        : newSortModel[0].field;
    const sort = newSortModel[0].sort || "desc";
    setSortModel(newSortModel);
    setPaginationModel({ page: 0, pageSize: pageSize });
    fetchFeeActivationLogs(feeId, 0, pageSize, field, sort);
  };

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const fetchFeeActivationLogs = (
    id: number,
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDir: string
  ) => {
    return dispatch(
      getFeeActivationLogs(id, currentPage, pageSize, sortBy, sortDir)
    );
  };

  useEffect(() => {
    fetchFeeActivationLogs(feeId, currentPage, pageSize, sortBy, sortDir);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: 400,
          width: "100%",
          "& .status-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.55)",
            color: "#1a3e72",
            fontWeight: "600",
          },
        }}
      >
        <DataGridPro
          loading={loading}
          style={{ cursor: "pointer" }}
          className="tbl-activation-logs"
          getRowId={(row) => row.feePanelActivationLogId}
          rows={activationLogs}
          rowCount={rowCountState}
          columns={columns}
          disableColumnFilter={true}
          pagination
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[15, 20, 50]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : ""
          }
        />
      </Box>
      <PanelExDialog
        id="errorMessage-dialog"
        data-testid="errorMessage-dialog"
        open={openError}
        title="Error"
        cancelText="Close"
        submitEnabled={false}
        showSubmit={false}
        onClose={handleErrorClose}
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            padding: "20px",
          }}
        >
          {errorMessage}
        </Box>
      </PanelExDialog>
    </>
  );
}
