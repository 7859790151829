import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  getPanelVersionComparisonDetails,
  showVersionsCompare,
  clearVersionsToCompare,
} from "../../store/action/actionPanel";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";

import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import { styled, useTheme } from "@mui/material/styles";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ExpandMoreProps extends IconButtonProps {
  expand: string;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: theme.colors.grey.main,
  transform: expand === "false" ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function PanelVersionComparison() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    gridContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    stack: {
      width: "100%",
    },
    card: {
      width: "100%",
      padding: "0px",
      borderRadius: "6px",
      background: theme.palette.common.white,
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
    },
    cardContent: {
      padding: "0px",
      background: theme.palette.common.white,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "0px",
      "&:last-child": {
        paddingBottom: "0px",
      },
    },
    cardHeader: {
      padding: "13px",
    },
    cardHeaderContent: {
      height: "32px",
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: theme.colors.grey.darker,
      },
    },
    cardHeaderValue: {
      height: "32px",
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        color: theme.colors.grey.darker,
      },
    },
    cardIcon: {
      justifyContent: "end",
      paddingRight: "12px",
    },
    cardChildContent: {
      padding: "0px",
      "&:last-child": {
        padding: "0px",
      },
    },
    contentGrid: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "13px",
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "24px",
        color: theme.colors.grey.darker,
      },
    },
    noDiffText: {
      fontSize: "16px",
      padding: "32px 24px 32px 24px",
    },
    locationHeader: {
      backgroundColor: theme.palette.neutral.lightest,
      "& .MuiTypography-root": {
        lineHeight: "21px",
      },
    },
    locationContent: {
      "&:first-of-type": {
        borderTop: "0px",
      },
      "& .MuiTypography-root": {
        fontWeight: "300",
        fontSize: "13px",
        lineHeight: "22px",
        color: theme.colors.grey.darker,
      },
    },
    settingsContent: {
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "21px",
        color: theme.colors.grey.darker,
      },
    },
  }));
  const { classes } = useStyles();

  const {
    panelId,
    versionCompare,
    showVersionCompare,
    versionCompareDetails,
    versionCompareLoading,
  } = useSelector((state: RootState) => {
    const panelSet = state.panel.panelSet;
    return {
      panelId: panelSet.panelSetId,
      versionCompare: state.panel.panelVersion.versionCompare,
      showVersionCompare: state.panel.panelVersion.showVersionCompare,
      versionCompareDetails: state.panel.panelVersion.versionCompareDetails,
      versionCompareLoading: state.panel.panelVersion.versionCompareLoading,
    };
  });

  useEffect(() => {
    if (!!panelId && versionCompare.length === 2) {
      dispatch(getPanelVersionComparisonDetails(panelId, versionCompare));
    }
  }, [dispatch, versionCompare]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeVersionCompare = () => {
    dispatch(clearVersionsToCompare());
    dispatch(showVersionsCompare(false));
  };

  const [expanded, setExpanded] = React.useState(false);
  const [selectedCardId, setSelectedCardId] = React.useState(null);
  const handleExpandClick = (cardId: any) => {
    setSelectedCardId(cardId);
    if (selectedCardId === cardId) {
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
  };

  const {
    leftVersionId,
    rightVersionId,
    differenceCount,
    settings = [],
    funders = [],
    clients = [],
    propertyTypes = [],
    services = [],
    allocations = {},
    fees = {},
  } = versionCompareDetails;

  const settingsDiffCount = settings.filter(
    (setting: any) => setting.isDifferent
  ).length;
  const fundersDiffCount = funders.filter(
    (funder: any) => funder.isDifferent
  ).length;
  const clientsDiffCount = clients.filter(
    (client: any) => client.isDifferent
  ).length;
  const propTypesDiffCount = propertyTypes.filter(
    (type: any) => type.isDifferent
  ).length;
  const servicesDiffCount = services.filter(
    (service: any) => service.isDifferent
  ).length;
  const allocationsDiffCount = allocations?.differenceCount || 0;
  const feesDiffCount = fees?.differenceCount || 0;

  const toLocaleNumberFormat = (input: any) => {
    return Number(input).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const renderTable = (
    name: any,
    leftVersion: any,
    rightVersion: any,
    contentList: any,
    index: any
  ) => {
    const cardIndex = "card-" + index;
    return (
      <Card
        key={cardIndex}
        id={cardIndex}
        data-testid={cardIndex}
        className={classes.card}
        onClick={() => handleExpandClick(cardIndex)}
      >
        <CardContent className={classes.cardContent}>
          <Grid
            container
            key={`content-${index}`}
            className={classes.cardHeader}
          >
            <Grid size={6} className={classes.cardHeaderContent}>
              <Typography variant="body2" data-testid={`content-name-${index}`}>
                {name}
              </Typography>
            </Grid>
            <Grid size={3} className={classes.cardHeaderValue}>
              <Typography variant="body2" data-testid={`content-left-${index}`}>
                v{leftVersion}
              </Typography>
            </Grid>
            <Grid size={3} className={classes.cardHeaderValue}>
              <Typography
                variant="body2"
                data-testid={`content-right-${index}`}
              >
                v{rightVersion}
              </Typography>
            </Grid>
          </Grid>

          <CardContent className={classes.cardChildContent}>
            {contentList
              .filter((content: any) => content.isDifferent)
              .map((content: any, contentIndex: any) => (
                <Grid
                  container
                  key={`content-${index}-${contentIndex}`}
                  className={`${classes.contentGrid} ${classes.settingsContent}`}
                >
                  <Grid
                    size={6}
                    key={`name-${index}-${contentIndex}`}
                    data-testid={`name-${index}-${contentIndex}`}
                  >
                    <Typography variant="body2Strong">
                      {content.name}
                    </Typography>
                  </Grid>
                  <Grid
                    size={3}
                    key={`left-value-${index}-${contentIndex}`}
                    data-testid={`left-value-${index}-${contentIndex}`}
                  >
                    <Typography variant="body2Strong">
                      {content.leftValue}
                    </Typography>
                  </Grid>
                  <Grid
                    size={3}
                    key={`right-value-${index}-${contentIndex}`}
                    data-testid={`right-value-${index}-${contentIndex}`}
                  >
                    <Typography variant="body2Strong">
                      {content.rightValue}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </CardContent>
        </CardContent>
      </Card>
    );
  };

  const renderAllocLocationRows = (
    locationName: any,
    leftExist: any,
    rightExist: any,
    leftSum: any,
    rightSum: any,
    index: any
  ) => {
    return (
      <Grid
        container
        key={`alloc-location-${index}`}
        className={`${classes.contentGrid} ${classes.locationHeader}`}
      >
        <Grid
          size={6}
          key={`alloc-location-name-${index}`}
          data-testid={`alloc-location-name-${index}`}
        >
          <Typography>{locationName}</Typography>
        </Grid>
        <Grid
          size={3}
          key={`alloc-left-value-${index}`}
          data-testid={`alloc-left-value-${index}`}
        >
          <Typography>{leftExist ? leftSum + "% Allocated" : "N/A"}</Typography>
        </Grid>
        <Grid
          size={3}
          key={`alloc-right-value-${index}`}
          data-testid={`alloc-right-value-${index}`}
        >
          <Typography>
            {leftExist
              ? rightExist
                ? rightSum + "% Allocated"
                : "Removed"
              : "Added"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderAllocationRows = (
    allocations: any,
    rightExist: any,
    locationIndex: any
  ) => {
    return allocations.map((allocation: any, index2: number) => {
      const { orgName, leftAllocation, rightAllocation } = allocation;
      return (
        <Grid
          container
          key={`alloc-org-${locationIndex}-${index2}`}
          className={`${classes.contentGrid} ${classes.locationContent}`}
        >
          <Grid
            size={6}
            key={`alloc-org-name-${locationIndex}-${index2}`}
            data-testid={`alloc-org-name-${locationIndex}-${index2}`}
          >
            <Typography>{orgName}</Typography>
          </Grid>
          <Grid
            size={3}
            key={`alloc-left-value-${locationIndex}-${index2}`}
            data-testid={`alloc-left-value-${locationIndex}-${index2}`}
          >
            <Typography>
              {leftAllocation !== null ? leftAllocation + "%" : "N/A"}
            </Typography>
          </Grid>
          <Grid
            size={3}
            key={`alloc-right-value-${locationIndex}-${index2}`}
            data-testid={`alloc-right-value-${locationIndex}-${index2}`}
          >
            <Typography>
              {rightExist
                ? rightAllocation !== null
                  ? rightAllocation + "%"
                  : "Removed"
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  const renderAllocationCard = (name: any, allocations: any, index: any) => {
    const cardIndex = "card-" + index;
    const shouldExpandCard = expanded && selectedCardId === cardIndex;
    const {
      leftVersionId,
      rightVersionId,
      leftAllocationName,
      rightAllocationName,
      locations,
    } = allocations;

    return (
      <Card
        key={cardIndex}
        id={cardIndex}
        data-testid={cardIndex}
        className={classes.card}
        onClick={() => handleExpandClick(cardIndex)}
      >
        <CardContent className={classes.cardContent}>
          <Grid
            container
            key={`content-${index}`}
            className={classes.cardHeader}
          >
            <Grid size={6} className={classes.cardHeaderContent}>
              <Typography variant="body2" data-testid={`content-name-${index}`}>
                {name}
              </Typography>
            </Grid>
            <Grid size={3} className={classes.cardHeaderValue}>
              <Typography variant="body2" data-testid={`content-left-${index}`}>
                {leftVersionId
                  ? `${leftAllocationName} v${leftVersionId}`
                  : "None Linked"}
              </Typography>
            </Grid>
            <Grid size={2} className={classes.cardHeaderValue}>
              <Typography
                variant="body2"
                data-testid={`content-right-${index}`}
              >
                {rightVersionId
                  ? `${rightAllocationName} v${rightVersionId}`
                  : "None Linked"}
              </Typography>
            </Grid>
            <Grid
              size={1}
              className={`${classes.cardHeaderValue} ${classes.cardIcon}`}
            >
              {!!leftVersionId && !!rightVersionId && (
                <ExpandMore
                  sx={{ padding: "2px" }}
                  data-testid={`expand-${index}`}
                  expand={shouldExpandCard.toString()}
                  aria-expanded={shouldExpandCard}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              )}
            </Grid>
          </Grid>

          <Collapse in={shouldExpandCard} timeout="auto" unmountOnExit>
            <CardContent className={classes.cardChildContent}>
              {locations &&
                locations
                  .filter((location: any) => location.isDifferent)
                  .map((location: any, contentIndex: any) => {
                    const {
                      locationName,
                      leftExist,
                      rightExist,
                      leftSum,
                      rightSum,
                      allocations,
                    } = location;
                    const locationRows = renderAllocLocationRows(
                      locationName,
                      leftExist,
                      rightExist,
                      leftSum,
                      rightSum,
                      contentIndex
                    );
                    const allocationRows = renderAllocationRows(
                      allocations,
                      rightExist,
                      contentIndex
                    );

                    return (
                      <React.Fragment
                        key={`location-alloc-${index}-${contentIndex}`}
                      >
                        {locationRows}
                        {allocationRows}
                      </React.Fragment>
                    );
                  })}
            </CardContent>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  const renderFeeLocationRows = (
    locationName: any,
    leftExist: any,
    rightExist: any,
    index: any
  ) => {
    return (
      <Grid
        container
        key={`fee-location-${index}`}
        className={`${classes.contentGrid} ${classes.locationHeader}`}
      >
        <Grid
          size={6}
          key={`fee-location-name-${index}`}
          data-testid={`fee-location-name-${index}`}
        >
          <Typography>{locationName}</Typography>
        </Grid>
        <Grid
          size={3}
          key={`fee-left-value-${index}`}
          data-testid={`fee-left-value-${index}`}
        >
          <Typography>{leftExist ? "" : "N/A"}</Typography>
        </Grid>
        <Grid
          size={3}
          key={`fee-right-value-${index}`}
          data-testid={`fee-right-value-${index}`}
        >
          <Typography>
            {leftExist ? (rightExist ? "" : "Removed") : "Added"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderFeeRows = (fees: any, rightExist: any, locationIndex: any) => {
    return fees.map((fee: any, index2: number) => {
      const { feeBracketName, leftFee, rightFee } = fee;
      return (
        <Grid
          container
          key={`fee-bracket-${locationIndex}-${index2}`}
          className={`${classes.contentGrid} ${classes.locationContent}`}
        >
          <Grid
            size={6}
            key={`fee-bracket-name-${locationIndex}-${index2}`}
            data-testid={`fee-bracket-name-${locationIndex}-${index2}`}
          >
            <Typography>{feeBracketName}</Typography>
          </Grid>
          <Grid
            size={3}
            key={`fee-left-value-${locationIndex}-${index2}`}
            data-testid={`fee-left-value-${locationIndex}-${index2}`}
          >
            <Typography>
              {leftFee !== null ? "$" + toLocaleNumberFormat(leftFee) : "N/A"}
            </Typography>
          </Grid>
          <Grid
            size={3}
            key={`fee-right-value-${locationIndex}-${index2}`}
            data-testid={`fee-right-value-${locationIndex}-${index2}`}
          >
            <Typography>
              {rightExist
                ? rightFee !== null
                  ? "$" + toLocaleNumberFormat(rightFee)
                  : leftFee !== null
                  ? "Removed"
                  : "N/A"
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  const renderFeeCard = (name: any, fees: any, index: any) => {
    const cardIndex = "card-" + index;
    const shouldExpandCard = expanded && selectedCardId === cardIndex;
    const {
      leftVersionId,
      rightVersionId,
      leftFeeName,
      rightFeeName,
      locations,
    } = fees;

    return (
      <Card
        key={cardIndex}
        id={cardIndex}
        data-testid={cardIndex}
        className={classes.card}
        onClick={() => handleExpandClick(cardIndex)}
      >
        <CardContent className={classes.cardContent}>
          <Grid
            container
            key={`content-${index}`}
            className={classes.cardHeader}
          >
            <Grid size={6} className={classes.cardHeaderContent}>
              <Typography variant="body2" data-testid={`content-name-${index}`}>
                {name}
              </Typography>
            </Grid>
            <Grid size={3} className={classes.cardHeaderValue}>
              <Typography variant="body2" data-testid={`content-left-${index}`}>
                {leftVersionId
                  ? `${leftFeeName} v${leftVersionId}`
                  : "None Linked"}
              </Typography>
            </Grid>
            <Grid size={2} className={classes.cardHeaderValue}>
              <Typography
                variant="body2"
                data-testid={`content-right-${index}`}
              >
                {rightVersionId
                  ? `${rightFeeName} v${rightVersionId}`
                  : "None Linked"}
              </Typography>
            </Grid>
            <Grid
              size={1}
              className={`${classes.cardHeaderValue} ${classes.cardIcon}`}
            >
              {!!leftVersionId && !!rightVersionId && (
                <ExpandMore
                  sx={{ padding: "2px" }}
                  data-testid={`expand-${index}`}
                  expand={shouldExpandCard.toString()}
                  aria-expanded={shouldExpandCard}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              )}
            </Grid>
          </Grid>

          <Collapse in={shouldExpandCard} timeout="auto" unmountOnExit>
            <CardContent className={classes.cardChildContent}>
              {locations &&
                locations
                  .filter((location: any) => location.isDifferent)
                  .map((location: any, contentIndex: any) => {
                    const { locationName, leftExist, rightExist, fees } =
                      location;
                    const locationRows = renderFeeLocationRows(
                      locationName,
                      leftExist,
                      rightExist,
                      index + "-" + contentIndex
                    );
                    const feeRows = renderFeeRows(
                      fees,
                      rightExist,
                      contentIndex
                    );

                    return (
                      <React.Fragment
                        key={`location-fee-${index}-${contentIndex}`}
                      >
                        {locationRows}
                        {feeRows}
                      </React.Fragment>
                    );
                  })}
            </CardContent>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  return (
    <PanelExDialog
      id="version-compare-dialog"
      data-testid="version-compare-dialog"
      open={showVersionCompare}
      title={`${
        versionCompareLoading
          ? ""
          : differenceCount > 1
          ? "Comparison: " + differenceCount + " differences found"
          : differenceCount === 1
          ? "Comparison: " + differenceCount + " difference found"
          : "Comparison: No differences found"
      }`}
      showCancel={false}
      submitText="Done"
      onClose={closeVersionCompare}
      onSubmit={closeVersionCompare}
      maxWidth="lg"
    >
      {versionCompareLoading ? (
        <Grid size={12}>
          <Box
            data-testid="version-compare-progress"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />
          </Box>
        </Grid>
      ) : differenceCount > 0 ? (
        <FormControl
          sx={{
            width: "100%",
            mt: 0,
            mb: 0,
            padding: "10px",
            backgroundColor: theme.palette.info.lighter,
          }}
        >
          <Grid
            container
            size={12}
            data-testid={"version-compare-result"}
            className={classes.gridContainer}
          >
            <Stack spacing={1} direction="column" className={classes.stack}>
              {settingsDiffCount > 0 ? (
                <React.Fragment>
                  {renderTable(
                    "Panel Settings (" + settingsDiffCount + ")",
                    leftVersionId,
                    rightVersionId,
                    settings,
                    0
                  )}
                </React.Fragment>
              ) : null}

              {fundersDiffCount > 0 ? (
                <Grid size={12}>
                  {renderTable(
                    "Funders (" + fundersDiffCount + ")",
                    leftVersionId,
                    rightVersionId,
                    funders,
                    1
                  )}
                </Grid>
              ) : null}

              {clientsDiffCount > 0 ? (
                <Grid size={12}>
                  {renderTable(
                    "Clients (" + clientsDiffCount + ")",
                    leftVersionId,
                    rightVersionId,
                    clients,
                    2
                  )}
                </Grid>
              ) : null}

              {propTypesDiffCount > 0 ? (
                <Grid size={12}>
                  {renderTable(
                    "Property Types (" + propTypesDiffCount + ")",
                    leftVersionId,
                    rightVersionId,
                    propertyTypes,
                    3
                  )}
                </Grid>
              ) : null}

              {servicesDiffCount > 0 ? (
                <Grid size={12}>
                  {renderTable(
                    "Service Types (" + servicesDiffCount + ")",
                    leftVersionId,
                    rightVersionId,
                    services,
                    4
                  )}
                </Grid>
              ) : null}

              {allocationsDiffCount > 0 ? (
                <Grid size={12}>
                  {renderAllocationCard(
                    "Allocation (" + allocationsDiffCount + ")",
                    allocations,
                    5
                  )}
                </Grid>
              ) : null}

              {feesDiffCount > 0 ? (
                <Grid size={12}>
                  {renderFeeCard("Fee (" + feesDiffCount + ")", fees, 6)}
                </Grid>
              ) : null}
            </Stack>
          </Grid>
        </FormControl>
      ) : (
        <FormControl
          sx={{
            mt: 2,
            mb: 2,
            minWidth: 500,
            minHeight: 100,
            maxHeight: 100,
          }}
        >
          <Typography
            className={classes.noDiffText}
            data-testid="version-compare-no-diff"
          >
            No differences found between versions{" "}
            {versionCompareDetails.leftVersionId} &{" "}
            {versionCompareDetails.rightVersionId}
          </Typography>
        </FormControl>
      )}
    </PanelExDialog>
  );
}
