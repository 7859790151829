import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import PanelExBreadcrumbs from "../shared/PanelExBreadcrumbs";
import PanelExSnackbar from "../shared/PanelExSnackbar";
import {
  Button,
  Divider,
  FormControl,
  Grid2 as Grid,
  Tab,
  Tabs,
  Box,
  Typography,
  Toolbar,
  useTheme,
  TextField,
  CircularProgress,
  Backdrop,
  Tooltip,
} from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";

import DialogContentText from "@mui/material/DialogContentText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs, { Dayjs } from "dayjs";
import {
  getFeeSet,
  changeFeeTab,
  getFeeVersionDetail,
  openFeeSetNameModal,
  updateFeeSetName,
  resetUpdateNameError,
  cancelFeeActivationLog,
  openImportModal,
  resetValidationErrors,
  importFeeVersion,
  resetPanelFee,
  copyFeeVersion,
  scheduleFeeVersionActivation,
  acknowledgeFeeActivationLog,
} from "../../store/action/actionFee";

import FeeDetail from "./FeeDetail";
import FeeVersions from "./FeeVersions";
import FeeActivationLogs from "./FeeActivationLogs";
import moment from "moment-timezone";
import { makeStyles } from "tss-react/mui";
import EditIcon from "@mui/icons-material/Edit";
import PanelExDialog from "../shared/PanelExDialog";
import ActivationStatusbar from "../shared/ActivationStatusbar";
import FileUploadModal from "../modal/FileUploadModal";
import VersionStatusbar from "../shared/VersionStatusbar";
import { openFailActivationCancel } from "../../store/action/actionUI";
import FeesIcon from "@mui/icons-material/RequestQuoteOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import FeePanels from "./FeePanels";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";

export default function FeeSet(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    versionDetailLabel: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "14px",
    },
    versionDetailSwitchLabel: {
      "& span": {
        fontSize: "12px",
        fontWeight: 500,
      },
    },
    versionDetailText: {
      width: "150px",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    feeSetName: {
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #textField-name-helper-text": {
        textTransform: "none",
      },
    },
    setTabs: {
      alignItems: "center",
      padding: "4px 6px",
      background: theme.colors.grey.lightest,
      height: "44px",
      borderRadius: "4px",

      "& .MuiTabs-indicator": {
        height: "0px",
      },
      "& .MuiTabs-flexContainer": {
        padding: "0px 6px",
        justifyContent: "space-between",
      },
      "& .MuiTab-root": {
        color: theme.colors.grey.dark,
        padding: "7px 6px",
        height: "30px",
        minHeight: "30px",
        borderRadius: "4px",

        "&.Mui-selected": {
          background: theme.palette.common.white,
          color: theme.palette.tertiary.main,
        },
      },
    },
    embedContent: {
      padding: "0px 0px 0px 0px",
      maxWidth: "100%",
    },
    mainContent: {
      maxWidth: "calc(100vw)",
    },
    unlinkButton: {
      "& .MuiButton-startIcon": {
        "& .MuiSvgIcon-root": {
          fontSize: "32px",
        },
      },
    },
  }));
  const { classes } = useStyles();

  const { feeId, panelId, panelVersionId, panelEditable } = props;
  const isEmbedded = !!props.panelId;

  useEffect(() => {
    dispatch(changeFeeTab(0));
    dispatch(getFeeSet(feeId));
  }, [dispatch, feeId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    feeVersion,
    feeDetail,
    activationLog,
    feeTab,
    feeRevId,
    feeVersionDetail,
    feeSet,
    updateName,
    requestedActivationLog,
    validationErrors,
    isOpenImportModal,
    isUploading,
    openBackdrop,
    openFailCancel,
    associatedPanel,
    roleName,
    readOnly,
  } = useSelector((state: RootState) => {
    return {
      feeVersion: state.fee.feeVersion,
      feeDetail: state.fee.feeDetail,
      activationLog: state.fee.activationLog,
      feeTab: state.fee.feeTab,
      feeRevId: state.fee.feeVersionId,
      feeVersionDetail: state.fee.feeVersionDetail,
      feeSet: state.fee.feeSet,
      requestedActivationLog: state.fee.latestFeeActivationLog,
      updateName: state.fee.updateName,
      validationErrors: state.fee.validationErrors,
      isOpenImportModal: state.fee.isOpenImportModal,
      isUploading: state.fee.isUploading,
      openBackdrop: state.fee.openBackdrop,
      openFailCancel: state.ui.openFailCancel || false,
      associatedPanel: state.fee.associatedPanel,
      roleName: state.tokenDetails.userContactDetails.roleName,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const { feeRevId: activeVersionId, editableRevId } = feeSet;
  const feeVersionId = feeRevId || activeVersionId || editableRevId || 0;
  const {
    scheduled,
    lastErrorTime,
    status: activateStatus,
    feePanelRevId: activateRevId,
  } = requestedActivationLog;

  useEffect(() => {
    if (feeVersionId !== 0) {
      dispatch(getFeeVersionDetail(feeId, feeVersionId));
    }
  }, [dispatch, feeVersionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const versionLabel = feeVersion.feeVersionCount
    ? `Versions(${feeVersion.feeVersionCount})`
    : "Versions";
  const locationLabel = feeDetail.locationCount
    ? `Locations(${feeDetail.locationCount})`
    : "Locations";
  const activationLogLabel = activationLog.activationLogCount
    ? `Activations(${activationLog.activationLogCount})`
    : "Activations";
  const assocPanelLabel = associatedPanel.panelCount
    ? `Associated Panels(${associatedPanel.panelCount})`
    : "Associated Panels";

  const [activateDate, setActivateDate] = React.useState<Dayjs | null>(dayjs());

  const [feeSetName, setFeeSetName] = React.useState(feeSet.name);
  const [submitEnable, setSubmitEnable] = React.useState(false);
  const [openUnlinkFee, setOpenUnlinkFee] = React.useState(false);
  const [openActivation, setOpenActivation] = useState(false);
  const [minDateTime, setMinDateTime] = React.useState<Dayjs | undefined>(
    dayjs().set("second", 0).set("millisecond", 0)
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeFeeTab(newValue));
  };

  const renderTabPanel = (index: number) => {
    const { feePanelId } = feeSet;
    if (isEmbedded) {
      switch (index) {
        case 1:
          return <FeePanels />;
        case 2:
          return (
            <FeeActivationLogs feeId={feePanelId} feeVersionId={feeVersionId} />
          );
        case 3:
          return <FeeVersions feeVersionId={feeVersionId} />;
        default:
          return <FeeDetail feeVersionId={feeVersionId} />;
      }
    } else {
      switch (index) {
        case 1:
          return (
            <FeeActivationLogs feeId={feePanelId} feeVersionId={feeVersionId} />
          );
        case 2:
          return <FeeVersions feeVersionId={feeVersionId} />;
        default:
          return <FeeDetail feeVersionId={feeVersionId} />;
      }
    }
  };

  const handleCancelSchedule = (
    activationLogId: number,
    activationVersionId: number
  ) => {
    dispatch(
      cancelFeeActivationLog(feeId, activationLogId, activationVersionId)
    );
  };

  const handleAcknowledgeSchedule = (
    activationLogId: number,
    activationVersionId: number
  ) => {
    dispatch(
      acknowledgeFeeActivationLog(feeId, activationLogId, activationVersionId)
    );
  };

  const showActivationStatusbar = (feeRevId: number, status: string) => {
    let show = false;
    if (
      feeRevId === editableRevId &&
      (status === "Queued" || status === "Error")
    ) {
      show = true;
    }
    return show;
  };

  const getFeeVersionStatus = (
    activeVersionId: number,
    versionId: number,
    editable: boolean,
    schedularRun: boolean,
    discard: boolean
  ) => {
    let status = "Archived";
    if (versionId === activeVersionId) {
      status = "Active";
    } else if (editable && !schedularRun && !discard) {
      if (activateStatus === "Queued" && versionId === activateRevId) {
        status = "Scheduled";
      } else if (activateStatus === "Error" && activateRevId === versionId) {
        status = "Failed";
      } else {
        status = "Draft";
      }
    }
    return status;
  };

  const {
    feePanelRevId: versionId,
    lastModified,
    editable,
    schedularRun,
    discard,
    feePanelActivationLogs,
  } = feeVersionDetail;

  const versionStatus = getFeeVersionStatus(
    activeVersionId,
    versionId,
    editable,
    schedularRun,
    discard
  );

  const fetchVersionTags = () => {
    const timezone = moment.tz.guess();
    const timezoneAbbr = moment().tz(timezone).format("z");
    const lastActivated =
      versionStatus === "Active" &&
      feePanelActivationLogs &&
      feePanelActivationLogs.length
        ? feePanelActivationLogs.find((log: any) => log.status === "Processed")
            .processed
        : null;
    let datetimeFormatted = moment(lastModified)
      .tz(timezone)
      .format("DD-MM-YYYY hh:mm:ss A");
    let dateType = "Last Modified";
    if (versionStatus === "Active") {
      dateType = "Processed";
      datetimeFormatted = moment(lastActivated)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    } else if (versionStatus === "Scheduled") {
      dateType = "For Date";
      datetimeFormatted = moment(scheduled)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    } else if (versionStatus === "Failed") {
      dateType = "Missed Date";
      datetimeFormatted = moment(lastErrorTime)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    }

    return (
      <VersionStatusbar
        versionId={versionId}
        versionStatus={versionStatus}
        datetimeFormatted={datetimeFormatted + " " + timezoneAbbr}
        versionType="Fee"
        dateType={dateType}
      />
    );
  };

  const handleNameModalClose = () => {
    setFeeSetName(feeSet.name);
    dispatch(openFeeSetNameModal(false));
    dispatch(resetUpdateNameError());
    setSubmitEnable(false);
  };

  const handleUpdateName = () => {
    dispatch(updateFeeSetName(feeId, feeSetName));
  };

  const handleFileUpload = (file: File) => {
    dispatch(importFeeVersion(feeSet.feePanelId, feeSet.name, file));
  };

  const handleClearValidationError = () => {
    dispatch(resetValidationErrors());
  };

  const handleVersionTabChange = () => {
    dispatch(changeFeeTab(2));
  };

  const handleCloseFailActivationCancel = () => {
    dispatch(openFailActivationCancel(false));
  };

  const handleFeeUnlink = () => {
    dispatch(resetPanelFee(feeId, feeSetName, panelId, panelVersionId));
    setOpenUnlinkFee(false);
  };

  const handleCreateDraft = () => {
    dispatch(copyFeeVersion(feeId, activeVersionId));
  };

  const handleOpenActivation = () => {
    setMinDateTime(dayjs().set("second", 0).set("millisecond", 0));
    setActivateDate(dayjs().set("second", 0).set("millisecond", 0));
    setOpenActivation(true);
  };

  const changeDates = (newValue: any) => {
    if (newValue != null) {
      setActivateDate(newValue);
    }
  };

  const handleFeeActivation = () => {
    if (activateDate != null) {
      setActivateDate(
        activateDate
          .set("second", 0)
          .set("milliseconds", 0)
          .set("millisecond", 0)
      );
    }
    dispatch(scheduleFeeVersionActivation(feeId, feeVersionId, activateDate));
    setOpenActivation(false);
  };

  return (
    <Grid
      container
      size={12}
      sx={{ display: "flex", width: "100%", justifyContent: "center" }}
      data-testid="fee-set"
    >
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, padding: "27px 45px 27px 45px" }}
        className={`${isEmbedded ? classes.embedContent : classes.mainContent}`}
      >
        {!isEmbedded && (
          <React.Fragment>
            <Toolbar variant="dense" />
            <Grid
              container
              sx={{
                paddingBottom: "3px",
                height: "47px",
                alignItems: "center",
              }}
            >
              <Grid size={6}>
                <PanelExBreadcrumbs currentPage={feeSet.name} />
              </Grid>
              <Grid size={6} sx={{ display: "flex", justifyContent: "end" }}>
                {requestedActivationLog &&
                  showActivationStatusbar(
                    requestedActivationLog.feePanelRevId,
                    requestedActivationLog.status
                  ) && (
                    <ActivationStatusbar
                      activationLogId={
                        requestedActivationLog.feePanelActivationLogId
                      }
                      versionId={requestedActivationLog.feePanelRevId}
                      activationStatus={requestedActivationLog.status}
                      scheduledDatetime={requestedActivationLog.scheduled}
                      failedDatetime={
                        requestedActivationLog.lastErrorTime || ""
                      }
                      onCancelSchedule={handleCancelSchedule}
                      onAcknowledgeSchedule={handleAcknowledgeSchedule}
                      onMoveTab={handleVersionTabChange}
                      onCloseFailModal={handleCloseFailActivationCancel}
                      openFailModal={openFailCancel}
                      readOnly={false}
                    />
                  )}
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        )}
        {isEmbedded && (
          <Grid container size={12}>
            <Grid size={5}></Grid>
            {roleName === "Admin" && !readOnly && (
              <Grid size={2} display="flex" justifyContent="center">
                <Tooltip
                  id={"unlink-tooltip"}
                  data-testid={"unlink-tooltip"}
                  title="Unlink Fee from Panel"
                  placement="bottom"
                  arrow
                >
                  <span>
                    <Button
                      id="btn-fee-unlink"
                      data-testid="btn-fee-unlink"
                      startIcon={<LinkIcon />}
                      variant="text"
                      disabled={!panelEditable}
                      onClick={() => setOpenUnlinkFee(true)}
                      className={classes.unlinkButton}
                    >
                      Unlink From Panel
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
            {requestedActivationLog &&
              showActivationStatusbar(
                requestedActivationLog.feePanelRevId,
                requestedActivationLog.status
              ) && (
                <Grid size={5} display="flex" justifyContent="right">
                  <ActivationStatusbar
                    activationLogId={
                      requestedActivationLog.feePanelActivationLogId
                    }
                    versionId={requestedActivationLog.feePanelRevId}
                    activationStatus={requestedActivationLog.status}
                    scheduledDatetime={requestedActivationLog.scheduled}
                    failedDatetime={requestedActivationLog.lastErrorTime || ""}
                    onCancelSchedule={handleCancelSchedule}
                    onAcknowledgeSchedule={handleAcknowledgeSchedule}
                    onMoveTab={handleVersionTabChange}
                    onCloseFailModal={handleCloseFailActivationCancel}
                    openFailModal={openFailCancel}
                    readOnly={false}
                  />
                </Grid>
              )}
          </Grid>
        )}
        <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Grid container size={6} sx={{ display: "flex" }}>
            <FeesIcon
              sx={{ width: "24px", height: "24px" }}
              id="fee-title-icon"
              data-testid="fee-title-icon"
            />
            <Typography
              id="fee-set-title"
              data-testid="fee-set-title"
              variant="h3"
              component="h3"
            >
              {feeSet.name}
            </Typography>
            {!readOnly && (
              <Button
                id="btn-update-name"
                data-testid="btn-update-name"
                size="small"
                onClick={() => {
                  setFeeSetName(feeSet.name);
                  dispatch(openFeeSetNameModal(true));
                }}
                startIcon={<EditIcon />}
                sx={{ paddingLeft: "15px" }}
              />
            )}
          </Grid>
          <Grid
            container
            size={6}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            {!readOnly && (
              <Grid sx={{ textAlign: "center" }}>
                <Button
                  id="btn-fee-import"
                  data-testid="btn-fee-import"
                  onClick={() => dispatch(openImportModal(true))}
                  startIcon={<FileUploadOutlined />}
                >
                  Import
                </Button>
              </Grid>
            )}
            {versionStatus === "Active" && !feeSet.hasEditable ? (
              <Grid
                sx={{
                  paddingLeft: "14px",
                  borderLeft: `1px solid ${theme.colors.grey.lighter}`,
                }}
              >
                {!readOnly && (
                  <Button
                    id="btn-fee-create-draft"
                    data-testid="btn-fee-create-draft"
                    size="small"
                    onClick={() => handleCreateDraft()}
                    startIcon={<EditIcon />}
                  >
                    Create Fee Draft
                  </Button>
                )}
              </Grid>
            ) : versionStatus === "Draft" || versionStatus === "Failed" ? (
              <Grid
                sx={{
                  paddingLeft: "14px",
                  borderLeft: `1px solid ${theme.colors.grey.lighter}`,
                }}
              >
                {!readOnly && (
                  <Button
                    id="btn-fee-activate"
                    data-testid="btn-fee-activate"
                    size="small"
                    variant="contained"
                    onClick={() => handleOpenActivation()}
                    startIcon={<PowerSettingsNewIcon />}
                  >
                    Activate Fee
                  </Button>
                )}
              </Grid>
            ) : null}
            <Grid>{fetchVersionTags()}</Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Grid size={5}></Grid>
          <Grid size={7}>
            <Box display="flex" justifyContent="flex-end">
              <Tabs
                className={classes.setTabs}
                value={feeTab}
                onChange={handleTabChange}
                sx={{ width: `${isEmbedded ? "550px" : "390px"}` }}
              >
                <Tab
                  data-testid="fee-tab-locations"
                  id="fee-tab-locations"
                  label={locationLabel}
                  sx={{ textTransform: "none" }}
                />
                {isEmbedded && (
                  <Tab
                    data-testid="fee-tab-panels"
                    id="fee-tab-panels"
                    label={assocPanelLabel}
                    sx={{ textTransform: "none" }}
                  />
                )}
                <Tab
                  data-testid="fee-tab-activations"
                  id="fee-tab-activations"
                  label={activationLogLabel}
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  data-testid="fee-tab-versions"
                  id="fee-tab-versions"
                  label={versionLabel}
                  sx={{ textTransform: "none" }}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
        <Divider />

        <Grid container sx={{ paddingTop: "10px" }}>
          <Grid size={12}>{renderTabPanel(feeTab)}</Grid>
        </Grid>
      </Box>
      <PanelExSnackbar />
      <Backdrop
        id="backdrop"
        data-testid="backdrop"
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PanelExDialog
        id="name-dialog"
        data-testid="name-dialog"
        open={updateName.isOpen}
        title="Edit Fee Name"
        submitText="Update"
        submitEnabled={submitEnable}
        onClose={handleNameModalClose}
        onSubmit={handleUpdateName}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            padding: "20px",
          }}
        >
          <TextField
            id="textField-name"
            data-testid="textField-name"
            className={classes.feeSetName}
            fullWidth={true}
            value={feeSetName}
            error={!!updateName.message}
            helperText={updateName.message}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newName = event.target.value;
              setFeeSetName(newName);
              if (
                !submitEnable &&
                newName.toLowerCase().trim() !==
                  feeSet.name.toLowerCase().trim()
              ) {
                setSubmitEnable(true);
              }
              if (
                submitEnable &&
                newName.toLowerCase().trim() ===
                  feeSet.name.toLowerCase().trim()
              ) {
                setSubmitEnable(false);
              }
              if (updateName.message) {
                dispatch(resetUpdateNameError());
              }
            }}
          />
        </Box>
      </PanelExDialog>
      <FileUploadModal
        title="Import Fee File"
        submitText="Import Fee File"
        open={isOpenImportModal}
        onClose={() => {
          dispatch(resetValidationErrors());
          dispatch(openImportModal(false));
        }}
        onFileUpload={handleFileUpload}
        validationErrors={validationErrors}
        clearValidationError={handleClearValidationError}
        isUploading={isUploading}
      />
      <PanelExDialog
        id="unlink-fee-dialog"
        data-testid="unlink-fee-dialog"
        open={openUnlinkFee}
        title={`Unlink ${feeSet.name}`}
        submitText="Yes Unlink From Panel"
        submitEnabled={true}
        onClose={() => setOpenUnlinkFee(false)}
        onSubmit={handleFeeUnlink}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
          data-testid="unlink-fee-dialog-child"
          id="unlink-fee-dialog-child"
        >
          <Typography variant="body1">
            Are you sure you want to unlink from panel?
          </Typography>
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="activate-dialog"
        data-testid="activate-dialog"
        open={openActivation}
        title={`Activate version ${feeVersionId}`}
        submitText="Agree and Activate"
        submitEnabled={true}
        onClose={() => setOpenActivation(false)}
        onSubmit={handleFeeActivation}
        showAction={versionStatus !== "Archived"}
        maxWidth="xs"
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "10px",
            maxWidth: "sm",
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 420 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1}>
                <Grid size={6}>
                  <DesktopDateTimePicker
                    sx={{ mt: 0, minWidth: 240 }}
                    data-testid="fee-activate-dialog-date"
                    format="DD-MM-YYYY hh:mm a"
                    value={activateDate}
                    onChange={(newValue) => changeDates(newValue)}
                    minDateTime={minDateTime}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </FormControl>
          <DialogContentText
            id="fee-activate-dialog-text"
            data-testid="fee-activate-dialog-text"
          >
            <br />I agree and acknowledge that all the fee details are verified
            and correct
          </DialogContentText>
        </Box>
      </PanelExDialog>
    </Grid>
  );
}
