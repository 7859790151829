import axios from "../../utilities/axiosInstance";

export const fetchAllocations = (filter) => {
  const url = `/api/allocations`;
  return axios.post(url, filter);
};

export const fetchAllocationSet = (allocationId) => {
  const url = `/api/allocations/${allocationId}`;
  return axios.get(url);
};

export const fetchAllocationDetail = (
  allocationId,
  allocRevId,
  valfirmFilter,
  locationFilter,
  currentPage,
  pageSize
) => {
  const formData = {
    valfirms: valfirmFilter,
    locations: locationFilter,
    currentPage,
    pageSize,
  };
  const url = `/api/allocations/${allocationId}/versions/${allocRevId}/locations`;
  return axios.post(url, formData);
};

export const fetchAllocationVersions = (
  allocationSetId,
  currentPage,
  pageSize
) => {
  const url = `/api/allocations/${allocationSetId}/versions?currentPage=${currentPage}&pageSize=${pageSize}`;
  return axios.get(url);
};

export const fetchExportAllocationVersion = (
  allocationId,
  allocationVersionId
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationVersionId}/export`;
  return axios.get(url, {
    responseType: "blob",
  });
};

export const fetchLocationSuggestionByVersionId = (
  text,
  option,
  allocationId,
  allocRevId,
  isExisting
) => {
  const url = `/api/locations/suggestionbyallocationversionid?q=${encodeURIComponent(
    text
  )}&country=${option}&allocationId=${allocationId}&allocRevId=${allocRevId}&isExisting=${isExisting}`;
  return axios.get(url);
};

export const fetchUpdateAllocationVersionNotes = (
  allocationId,
  allocationVersionId,
  userId,
  notes
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationVersionId}/updateNotes`;
  return axios.post(url, { userId: userId, notes: notes });
};

export const scheduleAllocationVersionActivationApi = (
  allocationId,
  allocationRevId,
  userId,
  scheduled
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationRevId}/scheduleAllocationActivation`;
  return axios.post(url, { userId: userId, scheduled: scheduled });
};

export const updateAllocationDetails = (
  allocationId,
  allocationVersionId,
  userId,
  locationDetail
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationVersionId}/locations`;
  return axios.put(url, { userId: userId, locationDetail: locationDetail });
};

export const updateAllocationNameAPI = (allocationId, allocationName) => {
  const url = `/api/allocations/${allocationId}/updateName`;
  return axios.post(url, { allocationName: allocationName });
};

export const updateAllowAutoUpdate = (
  allocationId,
  allocationPanelRevId,
  userId,
  allowAutoUpdate
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationPanelRevId}/updateAllowAutoUpdate`;
  return axios.post(url, { userId: userId, allowAutoUpdate: allowAutoUpdate });
};

export const updateMaxProviders = (
  allocationId,
  allocationPanelRevId,
  userId,
  maxProviders
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationPanelRevId}/updateMaxProviders`;
  return axios.post(url, { userId: userId, maxProviders: maxProviders });
};

export const updateQuote = (
  allocationId,
  allocationPanelRevId,
  userId,
  quote
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationPanelRevId}/updateQuote`;
  return axios.post(url, { userId: userId, quote: quote });
};

export const createNewAllocationSetAPI = (locationDetail) => {
  const url = `/api/allocations/newallocation`;
  return axios.post(url, locationDetail);
};

export const importAllocationFile = (param, config) => {
  let formData = new FormData();
  formData.append("id", param.id);
  formData.append("userId", param.userId);
  formData.append("file", param.file);
  return axios.post(`/api/allocations/${param.id}/import`, formData, config);
};

export const fetchAllocationVersionDetail = (allocationId, allocRevId) => {
  const url = `/api/allocations/${allocationId}/versions/${allocRevId}`;
  return axios.get(url);
};

export const copyVersion = (allocationId, allocationVersionId, userId) => {
  const url = `/api/allocations/${allocationId}/versions/${allocationVersionId}/copy`;
  return axios.post(url, { value: userId });
};

export const fetchAllocationActivationLogs = (
  allocationId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) => {
  const url = `/api/allocations/${allocationId}/activationLogs?currentPage=${currentPage}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
  return axios.get(url);
};

export const fetchCancelAllocationActivationLog = (
  allocationId,
  allocationPanelActivationLogId,
  userId
) => {
  const url = `/api/allocations/${allocationId}/activationLogs/${allocationPanelActivationLogId}/cancel`;
  return axios.post(url, { value: userId });
};

export const fetchAllocationVersionComparisonDetails = (
  allocationId,
  versionsList
) => {
  const url = `/api/allocations/${allocationId}/versions/compare`;
  return axios.post(url, versionsList);
};

export const fetchRemoveAllocationLocation = (
  allocationId,
  allocationRevId,
  allocationFilterId,
  valfirmFilter,
  locationFilter,
  userId,
  pageSize
) => {
  const formData = {
    valfirms: valfirmFilter,
    locations: locationFilter,
    pageSize,
    userId,
  };
  const url = `/api/allocations/${allocationId}/versions/${allocationRevId}/locations/${allocationFilterId}/delete`;
  return axios.post(url, formData);
};

export const fetchLatestAllocationActivationLog = (allocationId) => {
  const url = `/api/allocations/${allocationId}/latestActivationLog`;
  return axios.get(url);
};

export const fetchDiscardAllocationVersion = (
  allocationId,
  allocRevId,
  userId
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocRevId}/discard`;
  return axios.post(url, { value: userId });
};

export const fetchAssociatedPanels = (
  allocationId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) => {
  const url =
    `/api/allocations/${allocationId}/panels` +
    `?currentPage=${currentPage}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
  return axios.get(url);
};

export const acknowledgeAllocationActivationLog = (
  allocationId,
  allocationPanelActivationLogId,
  userId
) => {
  const url = `/api/allocations/${allocationId}/activationLogs/${allocationPanelActivationLogId}/acknowledge`;
  return axios.post(url, { value: userId });
};

export const fetchExistingValfirms = (allocationId, allocRevId) => {
  const url = `/api/allocations/${allocationId}/versions/${allocRevId}/valfirms`;
  return axios.get(url);
};

export const fetchExistingValfirmsByRevIds = (allocRevIds) => {
  const url = `/api/allocations/valfirmsByRevIds`;
  return axios.post(url, { values: allocRevIds });
};

/* not used
export const fetchValfirmExistingLocationSuggestions = (
  text,
  allocationId,
  allocRevId,
  valfirmId
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocRevId}/valfirm/${valfirmId}/locations/suggestion?q=${encodeURIComponent(
    text
  )}`;
  return axios.get(url);
};

export const fetchExistingLocationSuggestions = (
  text,
  allocationId,
  allocRevId
) => {
  const url = `/api/allocations/${allocationId}/versions/${allocRevId}/locations/suggestion?q=${encodeURIComponent(
    text
  )}`;
  return axios.get(url);
};
*/

export const fetchBulkUpdatePreviewData = (data) => {
  const url = `/api/allocations/bulkupdate/preview`;
  return axios.post(url, data);
};

export const saveBulkUpdate = (data) => {
  const url = `/api/allocations/bulkupdate/save`;
  return axios.post(url, data);
};

export const fetchExportMultiAllocations = (allocationIds) => {
  const url = `/api/allocations/export`;
  return axios.post(url, allocationIds, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchAllocationsLocations = (allocRevIds, valfirmId) => {
  const url = `/api/allocations/locations`;
  return axios.post(url, { allocRevIds, valfirmId });
};
