import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import PanelExBreadcrumbs from "../shared/PanelExBreadcrumbs";
import PanelExSnackbar from "../shared/PanelExSnackbar";
import ActivationStatusbar from "../shared/ActivationStatusbar";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  Tab,
  Tabs,
  Box,
  Switch,
  Typography,
  Toolbar,
  useTheme,
  TextField,
  CircularProgress,
  Backdrop,
  Tooltip,
} from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DialogContentText from "@mui/material/DialogContentText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import {
  getAllocationSet,
  importAllocationVersion,
  resetValidationErrors,
  openImportModal,
  changeAllocationTab,
  getAllocationVersionDetail,
  cancelAllocationActivationLog,
  updateIsScoreCardAllocation,
  updateIsQuoteAllocation,
  updateNoOfQuotesAllocation,
  openAllocationSetNameModal,
  updateAllocationSetName,
  resetUpdateNameError,
  clearVersionsToCompare,
  copyAllocationVersion,
  scheduleAllocationVersionActivation,
  resetPanelAllocation,
  acknowledgeAllocationActivationLog,
  exportAllocationVersion,
} from "../../store/action/actionAllocation";

import AllocationDetail from "./AllocationDetail";
import AllocationVersions from "./AllocationVersions";
import FileUploadModal from "../modal/FileUploadModal";
import moment from "moment-timezone";
import { makeStyles } from "tss-react/mui";
import AllocationActivationLogs from "./AllocationActivationLogs";
import EditIcon from "@mui/icons-material/Edit";
import PanelExDialog from "../shared/PanelExDialog";
import VersionStatusbar from "../shared/VersionStatusbar";
import { openFailActivationCancel } from "../../store/action/actionUI";
import AllocationsIcon from "@mui/icons-material/ReplyAllOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AllocationPanels from "./AllocationPanels";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import {
  openBulkUpdate,
  setSelectedPanel,
} from "../../store/action/actionPanel";
import BulkUpdate from "../panel/BulkUpdate";

export default function AllocationSet(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    scheduledBox: {
      display: "flex",
      padding: "6px 6px 6px 6px",
      alignItems: "center",
    },
    statusBox: {
      display: "flex",
      height: "20px",
      padding: "2px 8px 2px 8px",
      border: `1px solid ${theme.colors.grey.lighter}`,
      color: theme.palette.common.black,
      letterSpacing: "0.5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    contentBox: {
      display: "flex",
      color: theme.palette.common.black,
      letterSpacing: "0.16px",
      marginLeft: "5px",
      marginRight: "5px",
      height: "28px",
      padding: "0px 6px 0px 4px",
      alignItems: "center",
    },
    versionDetailLabel: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "14px",
    },
    versionDetailSwitchLabel: {
      "& span": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.4px",
      },
    },
    versionDetailText: {
      width: "150px",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    allocationSetName: {
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #textField-name-helper-text": {
        textTransform: "none",
      },
    },
    setTabs: {
      alignItems: "center",
      padding: "4px 6px",
      background: theme.colors.grey.lightest,
      height: "44px",
      borderRadius: "4px",

      "& .MuiTabs-indicator": {
        height: "0px",
      },
      "& .MuiTabs-flexContainer": {
        padding: "0px 6px",
        justifyContent: "space-between",
      },
      "& .MuiTab-root": {
        color: theme.colors.grey.dark,
        padding: "7px 6px",
        height: "30px",
        minHeight: "30px",
        borderRadius: "4px",

        "&.Mui-selected": {
          background: theme.palette.common.white,
          color: theme.palette.tertiary.main,
        },
      },
    },
    embedContent: {
      padding: "0px 0px 0px 0px",
      maxWidth: "100%",
    },
    mainContent: {
      maxWidth: "calc(100vw)",
    },
    unlinkButton: {
      "& .MuiButton-startIcon": {
        "& .MuiSvgIcon-root": {
          fontSize: "32px",
        },
      },
    },
  }));
  const { classes } = useStyles();

  const { allocationId, panelId, panelVersionId, panelEditable } = props;
  const isEmbedded = !!panelId;

  useEffect(() => {
    dispatch(changeAllocationTab(0));
    dispatch(getAllocationSet(allocationId));
  }, [dispatch, allocationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    allocationVersion,
    allocationDetail,
    activationLog,
    validationErrors,
    isOpenImportModal,
    isUploading,
    allocationTab,
    allocVersionId,
    allocationVersionDetail,
    allocationSet,
    requestedActivationLog,
    updateName,
    openBackdrop,
    openFailCancel,
    associatedPanel,
    roleName,
    readOnly,
    bulkUpdate,
    isOpenBulkUpdate,
    reloadDetail,
    panelAllocId,
  } = useSelector((state: RootState) => {
    return {
      allocationVersion: state.allocation.allocationVersion,
      allocationDetail: state.allocation.allocationDetail,
      activationLog: state.allocation.activationLog,
      validationErrors: state.allocation.validationErrors,
      isOpenImportModal: state.allocation.isOpenImportModal || false,
      isUploading: state.allocation.isUploading || false,
      allocationTab: state.allocation.allocationTab || 0,
      allocVersionId: state.allocation.allocationVersionId,
      allocationVersionDetail: state.allocation.allocationVersionDetail,
      allocationSet: state.allocation.allocationSet,
      requestedActivationLog: state.allocation.latestAllocationActivationLog,
      updateName: state.allocation.updateName,
      openBackdrop: state.allocation.openBackdrop || false,
      openFailCancel: state.ui.openFailCancel || false,
      associatedPanel: state.allocation.associatedPanel,
      roleName: state.tokenDetails.userContactDetails.roleName,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
      bulkUpdate: state.config.featureFlags.bulkUpdate,
      isOpenBulkUpdate: state.panel.bulkUpdate.isOpenBulkUpdate,
      reloadDetail: state.allocation.reloadDetail,
      panelAllocId: state.panel.panelSet.allocationPanelId || 0,
    };
  });
  const { allocRevId: activeVersionId, editableRevId } = allocationSet;
  const allocationVersionId =
    allocVersionId || activeVersionId || editableRevId || 0;

  const {
    scheduled,
    processed,
    lastErrorTime,
    status: activateStatus,
    allocationPanelRevId: activateRevId,
  } = requestedActivationLog;

  useEffect(() => {
    if (allocationVersionId !== 0 || !!reloadDetail) {
      dispatch(getAllocationVersionDetail(allocationId, allocationVersionId));
    }
  }, [dispatch, allocationVersionId, reloadDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  const versionLabel = allocationVersion.allocationVersionCount
    ? `Versions(${allocationVersion.allocationVersionCount})`
    : "Versions";
  const locationLabel = allocationDetail.locationCount
    ? `Locations(${allocationDetail.locationCount})`
    : "Locations";
  const activationLogLabel = activationLog.activationLogCount
    ? `Activations(${activationLog.activationLogCount})`
    : "Activations";
  const assocPanelLabel = associatedPanel.panelCount
    ? `Associated Panels(${associatedPanel.panelCount})`
    : "Associated Panels";

  const [openActivation, setOpenActivation] = useState(false);
  const [activateDate, setActivateDate] = React.useState<Dayjs | undefined>(
    dayjs()
  );
  const [minDateTime, setMinDateTime] = React.useState<Dayjs | undefined>(
    dayjs().set("second", 0).set("millisecond", 0)
  );

  const [allocationSetName, setAllocationSetName] = React.useState(
    allocationSet.name
  );
  const [submitEnable, setSubmitEnable] = React.useState(false);
  const [openUnlinkAllocation, setOpenUnlinkAllocation] = React.useState(false);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeAllocationTab(newValue));
  };

  const handleFileUpload = (file: File) => {
    dispatch(
      importAllocationVersion(
        allocationSet.allocPanelId,
        allocationSet.name,
        file
      )
    );
  };

  const handleIsScoreCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateIsScoreCardAllocation(
        allocationId,
        allocationVersionId,
        event.target.checked as boolean
      )
    );
  };

  const handleIsQuoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateIsQuoteAllocation(
        allocationId,
        allocationVersionId,
        event.target.checked as boolean
      )
    );
  };

  const handleNoOfQuotesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updateNoOfQuotesAllocation(
        allocationId,
        allocationVersionId,
        event.target.valueAsNumber
      )
    );
  };

  const renderTabPanel = (index: number) => {
    const { allocPanelId } = allocationSet;
    if (isEmbedded) {
      switch (index) {
        case 1:
          return <AllocationPanels />;
        case 2:
          return (
            <AllocationActivationLogs
              allocationId={allocPanelId}
              allocationVersionId={allocationVersionId}
            />
          );
        case 3:
          return (
            <AllocationVersions allocationVersionId={allocationVersionId} />
          );
        default:
          return <AllocationDetail allocationVersionId={allocationVersionId} />;
      }
    } else {
      switch (index) {
        case 1:
          return (
            <AllocationActivationLogs
              allocationId={allocPanelId}
              allocationVersionId={allocationVersionId}
            />
          );
        case 2:
          return (
            <AllocationVersions allocationVersionId={allocationVersionId} />
          );
        default:
          return <AllocationDetail allocationVersionId={allocationVersionId} />;
      }
    }
  };

  const handleCancelSchedule = (
    activationLogId: number,
    activationVersionId: number
  ) => {
    dispatch(
      cancelAllocationActivationLog(
        allocationId,
        activationLogId,
        activationVersionId
      )
    );
  };

  const handleAcknowledgeSchedule = (
    activationLogId: number,
    activationVersionId: number
  ) => {
    dispatch(
      acknowledgeAllocationActivationLog(
        allocationId,
        activationLogId,
        activationVersionId
      )
    );
  };

  const showActivationStatusbar = (allocRevId: number, status: string) => {
    let show = false;
    if (
      allocRevId === editableRevId &&
      (status === "Queued" || status === "Error")
    ) {
      show = true;
    }
    return show;
  };

  const getAllocationVersionStatus = (
    activeVersionId: number,
    versionId: number,
    editable: boolean,
    schedularRun: boolean,
    discard: boolean
  ) => {
    let status = "Archived";
    if (versionId === activeVersionId) {
      status = "Active";
    } else if (editable && !schedularRun && !discard) {
      if (activateStatus === "Queued" && versionId === activateRevId) {
        status = "Scheduled";
      } else if (activateStatus === "Error" && activateRevId === versionId) {
        status = "Failed";
      } else {
        status = "Draft";
      }
    }
    return status;
  };

  const {
    allocationPanelRevId: versionId,
    lastModified,
    editable,
    schedularRun,
    discard,
    allowAutoUpdate,
    quote,
    maxProviders,
  } = allocationVersionDetail;

  const versionStatus = getAllocationVersionStatus(
    activeVersionId,
    versionId,
    editable,
    schedularRun,
    discard
  );

  const fetchVersionTags = () => {
    const timezone = moment.tz.guess();
    const timezoneAbbr = moment().tz(timezone).format("z");

    let datetimeFormatted = moment(lastModified)
      .tz(timezone)
      .format("DD-MM-YYYY hh:mm:ss A");
    let dateType = "Last Modified";
    if (versionStatus === "Active") {
      dateType = "Processed";
      datetimeFormatted = moment(processed)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    } else if (versionStatus === "Scheduled") {
      dateType = "For Date";
      datetimeFormatted = moment(scheduled)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    } else if (versionStatus === "Failed") {
      dateType = "Missed Date";
      datetimeFormatted = moment(lastErrorTime)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    }

    return (
      <VersionStatusbar
        versionId={versionId}
        versionStatus={versionStatus}
        datetimeFormatted={datetimeFormatted + " " + timezoneAbbr}
        versionType="Allocation"
        dateType={dateType}
      />
    );
  };

  const handleNameModalClose = () => {
    setAllocationSetName(allocationSet.name);
    dispatch(openAllocationSetNameModal(false));
    dispatch(resetUpdateNameError());
    setSubmitEnable(false);
  };

  const handleUpdateName = () => {
    dispatch(updateAllocationSetName(allocationId, allocationSetName));
  };
  const fetchVersionDetails = () => {
    const disableEdit = ["Archived", "Active"].includes(versionStatus);
    return (
      <Grid
        container
        sx={{ width: "357px", fontSize: "12px" }}
        data-testid="version-detail-bar"
        id="version-detail-bar"
      >
        <Grid sx={{ width: "76px" }}>
          <Typography className={classes.versionDetailLabel}>
            Scorecard
          </Typography>

          <FormControlLabel
            data-testid="version-score"
            id="version-score"
            className={classes.versionDetailSwitchLabel}
            control={
              <Switch
                checked={allowAutoUpdate}
                onChange={handleIsScoreCardChange}
              />
            }
            label={!allowAutoUpdate ? "No" : "Yes"}
            disabled={roleName !== "Admin" || disableEdit || readOnly}
          />
        </Grid>
        <Grid sx={{ width: "109px", marginLeft: "5px" }}>
          <Typography className={classes.versionDetailLabel}>
            Quote Weightings
          </Typography>
          <FormControlLabel
            data-testid="version-quote"
            id="version-quote"
            className={classes.versionDetailSwitchLabel}
            control={<Switch checked={quote} onChange={handleIsQuoteChange} />}
            label={!quote ? "No" : "Yes"}
            disabled={roleName !== "Admin" || disableEdit || readOnly}
          />
        </Grid>
        <Grid sx={{ width: "152px", marginLeft: "5px" }}>
          {quote && (
            <TextField
              className={classes.versionDetailText}
              id="version-max-provider"
              data-testid="version-max-provider"
              label="No of Firms to Quote"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 1, max: 20 } }}
              inputMode={"numeric"}
              type={"number"}
              placeholder="Enter Number"
              value={maxProviders}
              disabled={roleName !== "Admin" || disableEdit || readOnly}
              onChange={handleNoOfQuotesChange}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const handleClearValidationError = () => {
    dispatch(resetValidationErrors());
  };

  const handleVersionTabChange = () => {
    dispatch(changeAllocationTab(2));
  };

  const handleCloseFailActivationCancel = () => {
    dispatch(openFailActivationCancel(false));
  };

  const handleAllocUnlink = () => {
    dispatch(
      resetPanelAllocation(
        allocationId,
        allocationSetName,
        panelId,
        panelVersionId
      )
    );
    setOpenUnlinkAllocation(false);
  };

  const handleCreateDraft = () => {
    dispatch(clearVersionsToCompare());
    dispatch(copyAllocationVersion(allocationId, activeVersionId));
  };

  const changeDates = (newValue: any) => {
    if (newValue != null) {
      setActivateDate(newValue);
    }
  };

  const handleAllocationActivation = () => {
    if (activateDate != null) {
      setActivateDate(
        activateDate
          .set("second", 0)
          .set("milliseconds", 0)
          .set("millisecond", 0)
      );
    }
    dispatch(
      scheduleAllocationVersionActivation(
        allocationId,
        allocationVersionId,
        activateDate
      )
    );
    setOpenActivation(false);
  };

  const handleOpenActivation = () => {
    dispatch(clearVersionsToCompare());
    setMinDateTime(dayjs().set("second", 0).set("millisecond", 0));
    setActivateDate(dayjs().set("second", 0).set("millisecond", 0));
    setOpenActivation(true);
  };

  return (
    <Grid
      container
      size={12}
      sx={{ display: "flex", width: "100%", justifyContent: "center" }}
      data-testid="allocation-set"
    >
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, padding: "27px 45px 27px 45px" }}
        className={`${isEmbedded ? classes.embedContent : classes.mainContent}`}
      >
        {!isEmbedded && (
          <React.Fragment>
            <Toolbar variant="dense" />
            <Grid
              container
              sx={{
                paddingBottom: "3px",
                height: "47px",
                alignItems: "center",
              }}
            >
              <Grid size={6}>
                <PanelExBreadcrumbs currentPage={allocationSet.name} />
              </Grid>
              <Grid size={6} sx={{ display: "flex", justifyContent: "end" }}>
                {requestedActivationLog &&
                  showActivationStatusbar(
                    requestedActivationLog.allocationPanelRevId,
                    requestedActivationLog.status
                  ) && (
                    <ActivationStatusbar
                      activationLogId={
                        requestedActivationLog.allocationPanelActivationLogId
                      }
                      versionId={requestedActivationLog.allocationPanelRevId}
                      activationStatus={requestedActivationLog.status}
                      scheduledDatetime={requestedActivationLog.scheduled}
                      failedDatetime={
                        requestedActivationLog.lastErrorTime || ""
                      }
                      onCancelSchedule={handleCancelSchedule}
                      onAcknowledgeSchedule={handleAcknowledgeSchedule}
                      onMoveTab={handleVersionTabChange}
                      onCloseFailModal={handleCloseFailActivationCancel}
                      openFailModal={openFailCancel}
                      readOnly={readOnly}
                    />
                  )}
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        )}
        <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
          {isEmbedded && (
            <Grid container size={12}>
              <Grid size={5}></Grid>
              <Grid size={2} display="flex" justifyContent="center">
                {roleName === "Admin" && !readOnly && (
                  <Tooltip
                    id={"unlink-tooltip"}
                    data-testid={"unlink-tooltip"}
                    title="Unlink Allocation from Panel"
                    placement="bottom"
                    arrow
                  >
                    <span>
                      <Button
                        id="btn-alloc-unlink"
                        data-testid="btn-alloc-unlink"
                        variant="text"
                        disabled={!panelEditable}
                        onClick={() => setOpenUnlinkAllocation(true)}
                        startIcon={<LinkIcon sx={{ fontSize: 32 }} />}
                        className={classes.unlinkButton}
                      >
                        Unlink From Panel
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Grid>
              <Grid size={5} display="flex" justifyContent="right">
                {requestedActivationLog &&
                  showActivationStatusbar(
                    requestedActivationLog.allocationPanelRevId,
                    requestedActivationLog.status
                  ) && (
                    <ActivationStatusbar
                      activationLogId={
                        requestedActivationLog.allocationPanelActivationLogId
                      }
                      versionId={requestedActivationLog.allocationPanelRevId}
                      activationStatus={requestedActivationLog.status}
                      scheduledDatetime={requestedActivationLog.scheduled}
                      failedDatetime={
                        requestedActivationLog.lastErrorTime || ""
                      }
                      onCancelSchedule={handleCancelSchedule}
                      onAcknowledgeSchedule={handleAcknowledgeSchedule}
                      onMoveTab={handleVersionTabChange}
                      onCloseFailModal={handleCloseFailActivationCancel}
                      openFailModal={openFailCancel}
                      readOnly={readOnly}
                    />
                  )}
              </Grid>
            </Grid>
          )}
          <Grid size={5} sx={{ display: "flex" }}>
            <AllocationsIcon
              sx={{
                width: "24px",
                height: "24px",
                marginRight: "4px",
                transform: `rotate(-90deg)`,
              }}
              id="alloc-title-icon"
              data-testid="alloc-title-icon"
            />
            <Typography
              id="alloc-set-title"
              data-testid="alloc-set-title"
              variant="h3"
              component="h3"
            >
              {allocationSet.name}
              {!readOnly && (
                <Button
                  id="btn-update-name"
                  data-testid="btn-update-name"
                  size="small"
                  onClick={() => {
                    setAllocationSetName(allocationSet.name);
                    dispatch(openAllocationSetNameModal(true));
                  }}
                  startIcon={<EditIcon />}
                  sx={{ paddingLeft: "15px" }}
                />
              )}
            </Typography>
          </Grid>
          <Grid
            container
            size={7}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            {isEmbedded && bulkUpdate && !readOnly && (
              <Grid sx={{ textAlign: "center", paddingRight: "14px" }}>
                <Button
                  id="btn-bulk-update"
                  data-testid="btn-bulk-update"
                  disabled={panelAllocId === 0}
                  onClick={() => {
                    dispatch(
                      setSelectedPanel({
                        panelSetId: panelId,
                        allocationPanelId: allocationSet.allocPanelId,
                      })
                    );
                    dispatch(openBulkUpdate(true, "Panel Detail", 1));
                  }}
                  startIcon={<EditIcon />}
                >
                  Bulk Update
                </Button>
              </Grid>
            )}
            <Grid sx={{ textAlign: "center", paddingRight: "14px" }}>
              <Button
                id="btn-alloc-export"
                data-testid="btn-alloc-export"
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={() => {
                  dispatch(
                    exportAllocationVersion(
                      allocationSet.allocPanelId,
                      allocationVersionId,
                      allocationSet.name
                    )
                  );
                }}
              >
                Export
              </Button>
            </Grid>
            <Grid sx={{ textAlign: "center", paddingRight: "14px" }}>
              {!readOnly && (
                <Button
                  id="btn-alloc-import"
                  data-testid="btn-alloc-import"
                  onClick={() => dispatch(openImportModal(true))}
                  startIcon={<FileUploadOutlined />}
                >
                  Import
                </Button>
              )}
            </Grid>
            {versionStatus === "Active" && !allocationSet.hasEditable ? (
              <Grid
                sx={{
                  paddingLeft: "14px",
                  borderLeft: `1px solid ${theme.colors.grey.lighter}`,
                }}
              >
                {!readOnly && (
                  <Button
                    id="btn-alloc-create-draft"
                    data-testid="btn-alloc-create-draft"
                    size="small"
                    onClick={() => handleCreateDraft()}
                    startIcon={<EditIcon />}
                  >
                    Create Allocation Draft
                  </Button>
                )}
              </Grid>
            ) : versionStatus === "Draft" || versionStatus === "Failed" ? (
              <Grid
                sx={{
                  paddingLeft: "14px",
                  borderLeft: `1px solid ${theme.colors.grey.lighter}`,
                }}
              >
                {!readOnly && (
                  <Button
                    id="btn-alloc-activate"
                    data-testid="btn-alloc-activate"
                    size="small"
                    variant="outlined"
                    onClick={() => handleOpenActivation()}
                    startIcon={<PowerSettingsNewIcon />}
                  >
                    Activate Allocation
                  </Button>
                )}
              </Grid>
            ) : null}
            <Grid>{fetchVersionTags()}</Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Grid size={5}>{fetchVersionDetails()}</Grid>
          <Grid size={7}>
            <Box display="flex" justifyContent="flex-end">
              <Tabs
                className={classes.setTabs}
                value={allocationTab}
                onChange={handleTabChange}
                sx={{ width: `${isEmbedded ? "550px" : "390px"}` }}
              >
                <Tab
                  data-testid="allocation-tab-locations"
                  id="allocation-tab-locations"
                  label={locationLabel}
                  sx={{ textTransform: "none" }}
                />
                {isEmbedded && (
                  <Tab
                    data-testid="allocation-tab-panels"
                    id="allocation-tab-panels"
                    label={assocPanelLabel}
                    sx={{ textTransform: "none" }}
                  />
                )}
                <Tab
                  data-testid="allocation-tab-activations"
                  id="allocation-tab-activations"
                  label={activationLogLabel}
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  data-testid="allocation-tab-versions"
                  id="allocation-tab-versions"
                  label={versionLabel}
                  sx={{ textTransform: "none" }}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
        <Divider />

        <Grid container sx={{ paddingTop: "10px" }}>
          {renderTabPanel(allocationTab)}
        </Grid>
      </Box>
      <PanelExSnackbar />
      <Backdrop
        id="backdrop"
        data-testid="backdrop"
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FileUploadModal
        open={isOpenImportModal}
        onClose={() => {
          dispatch(resetValidationErrors());
          dispatch(openImportModal(false));
        }}
        onFileUpload={handleFileUpload}
        validationErrors={validationErrors}
        clearValidationError={handleClearValidationError}
        isUploading={isUploading}
      />
      <PanelExDialog
        id="name-dialog"
        data-testid="name-dialog"
        open={updateName.isOpen}
        title="Edit Allocation Name"
        submitText="Update"
        submitEnabled={submitEnable}
        onClose={handleNameModalClose}
        onSubmit={handleUpdateName}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            padding: "20px",
          }}
        >
          <TextField
            id="textField-name"
            data-testid="textField-name"
            className={classes.allocationSetName}
            fullWidth={true}
            value={allocationSetName}
            error={!!updateName.message}
            helperText={updateName.message}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newName = event.target.value;
              setAllocationSetName(newName);
              if (
                newName.toLowerCase().trim() !==
                allocationSet.name.toLowerCase().trim()
              ) {
                setSubmitEnable(true);
              } else {
                setSubmitEnable(false);
              }

              if (updateName.message) {
                dispatch(resetUpdateNameError());
              }
            }}
          />
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="activate-dialog"
        data-testid="activate-dialog"
        open={openActivation}
        title={`Activate version ${allocationVersionId}`}
        submitText="Agree and Activate"
        submitEnabled={true}
        onClose={() => setOpenActivation(false)}
        onSubmit={handleAllocationActivation}
        showAction={versionStatus !== "Archived"}
        maxWidth="xs"
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "10px",
            maxWidth: "sm",
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 420 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1}>
                <Grid size={6}>
                  <DesktopDateTimePicker
                    sx={{ mt: 0, minWidth: 240 }}
                    data-testid="alloc-activate-dialog-date"
                    format="DD-MM-YYYY hh:mm a"
                    value={activateDate}
                    onChange={(newValue) => changeDates(newValue)}
                    minDateTime={minDateTime}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </FormControl>
          <DialogContentText
            id="alloc-activate-dialog-text"
            data-testid="alloc-activate-dialog-text"
          >
            <br />I agree and acknowledge that all the allocation details are
            verified and correct
          </DialogContentText>
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="unlink-allocation-dialog"
        data-testid="unlink-allocation-dialog"
        open={openUnlinkAllocation}
        title={`Unlink ${allocationSet.name}`}
        submitText="Yes Unlink From Panel"
        submitEnabled={true}
        onClose={() => setOpenUnlinkAllocation(false)}
        onSubmit={handleAllocUnlink}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
          data-testid="unlink-allocation-dialog-child"
          id="unlink-allocation-dialog-child"
        >
          <Typography variant="body1">
            Are you sure you want to unlink from panel?
          </Typography>
        </Box>
      </PanelExDialog>
      {isOpenBulkUpdate && <BulkUpdate />}
    </Grid>
  );
}
