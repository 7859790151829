import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  removeAllocationLocationForNewAllocation,
  showCreateNewAllocationSet,
  updateLocationOptionNewAllocation,
  updateLocationTextChangeNewAllocation,
  updateNoOfQuotesNewAllocation,
  updateIsQuoteNewAllocation,
  updateAllcationNameNewAllocation,
  updateIsScoreCardNewAllocation,
  createNewAllocationSet,
} from "../../store/action/actionAllocation";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  FormControl,
  Grid2 as Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import LocationModal from "./LocationModalNewAllocation";
import {
  updateSelectedAllocationsNewAllocation,
  updateSelectedLocationNewAllocation,
} from "../../store/action/actionUI";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplyAllOutlined from "@mui/icons-material/ReplyAllOutlined";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

interface ExpandMoreProps extends IconButtonProps {
  expand: string;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: theme.colors.grey.main,
  transform: expand === "false" ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CreateNewAllocationSet() {
  const dispatch = useDispatch();
  const useStyles = makeStyles()(() => ({
    select: {
      borderRadius: "4px",
      marginRight: "4px",
    },
    versionDetailLabel: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "14px",
    },
    Locationlabel: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    versionDetailSwitchLabel: {
      "& span": {
        fontSize: "12px",
        fontWeight: 500,
      },
    },
    allocationSetName: {
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #allocation-set-name-helper-text": {
        textTransform: "none",
      },
    },
    versionDetailText: {
      width: "150px",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    detailsGrid: {
      width: "100%",
      cursor: "pointer",
      "& .MuiDataGrid-withBorderColor": {
        border: "0px",
      },
      "& .MuiDataGrid-cell": {
        padding: "0px",
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "0px",
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "0px",
      },
    },
    allocationGrid: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "12px",
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      justifyContent: "flex-end",
    },
    allocationValue: {
      paddingRight: "12px",
      display: "flex",
      justifyContent: "end",
    },
    locationHeader: {
      height: "32px",
      background: "#f5f5f5",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 400,
        color: theme.palette.common.black,
        padding: "6px 12px",
      },
    },
    allocationCardContent: {
      padding: "0px",
      "&:last-child": {
        padding: "0px",
      },
    },
    locationIcon: {
      height: "32px",
      background: "#f5f5f5",
      display: "flex",
      justifyContent: "end",
      paddingRight: "12px",
    },
    card: {
      width: "100%",
      padding: "12px 0 0 0",
      background: "#FAFBFC",
    },
    cardContent: {
      padding: "0px",
      background: theme.palette.common.white,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "0px",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      "&:last-child": {
        paddingBottom: "0px",
      },
    },
    allocationDetailMessage: {
      width: "335px",
      marginTop: "10px",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",

      "& .MuiTypography-root": {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colors.grey.main,

        "&:first-of-type": {
          paddingBottom: "22px",
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "28px",
          color: theme.colors.grey.dark,
        },
      },
    },
  }));
  const {
    locations,
    currentPage,
    pageSize,
    newAllocSearchOption,
    newAllocSearchText,
    newAllocSuggestions,
    openCreateAllocationSet,
    editedLocation,
    isQuote,
    noOfQuotes,
    isScoreCard,
    allocationName,
    newAllocationSet,
    error,
    allocationCountryId,
  } = useSelector((state: RootState) => {
    return {
      locations: state.allocation.newAllocationSet.locations || [],
      currentPage: state.allocation.newAllocationSet.currentPage || 0,
      pageSize: state.allocation.newAllocationSet.pageSize || 15,
      newAllocSearchOption:
        state.allocation.newAllocationSet.allocationCountryId ||
        state.allocation.newAllocationSet.searchOption ||
        1,
      newAllocSearchText: state.allocation.newAllocationSet.searchText || "",
      newAllocSuggestions: state.allocation.newAllocationSet.suggestion || [],
      openCreateAllocationSet: state.allocation.showCreateNewAllocationSet,
      editedLocation: state.allocation.newAllocationSet.editedLocation,
      isQuote: state.allocation.newAllocationSet.isQuote,
      noOfQuotes: state.allocation.newAllocationSet.noOfQuotes,
      isScoreCard: state.allocation.newAllocationSet.isScoreCard,
      allocationName: state.allocation.newAllocationSet.allocationName || "",
      newAllocationSet: state.allocation.newAllocationSet,
      error: state.allocation.newAllocationSet.error,
      allocationCountryId:
        state.allocation.newAllocationSet.allocationCountryId,
    };
  });
  const theme = useTheme();
  const { classes } = useStyles();
  const [openRemove, setOpenRemove] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedLocationId, setSelectedLocationId] = React.useState(null);
  const [isLocationModalOpen, setLocationModalOpen] = React.useState(false);
  const [selectedLocationName, setSelectedLocationName] = React.useState("");

  const createNewAllocationSubmitEnabled =
    locations.length >= 1 && allocationName.length >= 3;
  const closeCreateNewAllocationSet = () => {
    dispatch(showCreateNewAllocationSet(false));
  };
  const handleCreateAllocationSet = () => {
    dispatch(createNewAllocationSet(newAllocationSet));
  };
  const handleAllocationNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updateAllcationNameNewAllocation(event.target.value));
  };
  const handleNoOfQuotesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updateNoOfQuotesNewAllocation(event.target.valueAsNumber));
  };

  const handleIsScoreCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updateIsScoreCardNewAllocation(event.target.checked as boolean));
  };

  const handleIsQuoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateIsQuoteNewAllocation(event.target.checked as boolean));
  };
  useEffect(() => {
    if (editedLocation) {
      setExpanded(editedLocation);
      setSelectedLocationId(editedLocation);
    }
  }, [editedLocation]);

  const handleCountryChange = (event: SelectChangeEvent) => {
    dispatch(updateLocationOptionNewAllocation(event.target.value as string));
  };

  const handleInputChange = (input: string) => {
    if (input !== newAllocSearchText) {
      dispatch(
        updateLocationTextChangeNewAllocation(input, newAllocSearchOption)
      );
    }
  };

  const { selectedLocation } = useSelector((state: RootState) => {
    return {
      selectedLocation: state.ui.newAllocation
        ? state.ui.newAllocation.location
          ? state.ui.newAllocation.location
          : ""
        : "",
    };
  });

  const handleExpandClick = (locationId: any) => {
    setSelectedLocationId(locationId);
    if (selectedLocationId === locationId) {
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
  };

  const handleEditLocation = (
    id: number,
    type: string,
    name: string,
    isExisting: boolean,
    countryId: number,
    countryName: string,
    locationId: any
  ) => {
    dispatch(
      updateSelectedLocationNewAllocation(
        id,
        type,
        name,
        isExisting,
        countryId,
        countryName,
        locationId
      )
    );
    locations
      .filter((loc: any) => loc.locationId === locationId)
      .map((location: any) => {
        const allocations = location.allocations.reduce((acc: any, v: any) => {
          acc.push({
            allocationPanelAllocationsId: v.allocationPanelAllocationsId,
            valfirmId: v.orgId,
            valfirmName: v.orgName,
            value: v.allocation,
            isEdited: null,
            isDeleted: null,
          });
          return acc;
        }, []);
        dispatch(updateSelectedAllocationsNewAllocation(allocations));
      });
    setLocationModalOpen(true);
  };

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
  };

  const handleRemoveLocation = () => {
    dispatch(
      removeAllocationLocationForNewAllocation(
        selectedLocationId,
        selectedLocationName
      )
    );
    setOpenRemove(false);
  };

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      flex: 8,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const locationId = params.row.locationId;
        const index = params.api.getRowIndexRelativeToVisibleRows(locationId);
        const shouldExpandCard = expanded && selectedLocationId === locationId;
        const {
          postcodeId,
          postcodeName,
          regionId,
          regionName,
          stateId,
          stateName,
          countryName,
          countryId,
          allocations,
        } = params.row;
        const type = postcodeName
          ? "Postcode"
          : regionName
          ? "Region"
          : stateName
          ? "State"
          : "Country";
        const name = postcodeName || regionName || stateName || countryName;
        const id = postcodeId || regionId || stateId || countryId;

        const locationName = [
          countryName,
          type === "Country" ? null : type + " " + name,
        ]
          .filter(Boolean)
          .join(" ");

        return (
          <>
            <Card
              key={`card-${index}`}
              data-testid={`card-${index}`}
              className={classes.card}
            >
              <CardContent className={classes.cardContent}>
                <Grid container key={`location-${index}`}>
                  <Grid size={10} className={classes.locationHeader}>
                    <Typography
                      variant="body2"
                      data-testid={`location-${index}`}
                    >
                      {locationName}
                    </Typography>
                  </Grid>
                  <Grid size={2} className={classes.locationIcon}>
                    <div>
                      <IconButton
                        id={`btn-del-loc-${index}`}
                        data-testid={`btn-del-loc-${index}`}
                        sx={{ padding: "5px", marginRight: "4px" }}
                        onClick={(ev) => {
                          setOpenRemove(true);
                          setSelectedLocationName(locationName);
                          setSelectedLocationId(locationId);
                          ev.stopPropagation();
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            color: `${theme.colors.grey.main}`,
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        id={`btn-edit-org-${index}`}
                        data-testid={`btn-edit-org-${index}`}
                        sx={{ padding: "5px", marginRight: "4px" }}
                        onClick={(ev) => {
                          handleEditLocation(
                            id,
                            type,
                            name,
                            true,
                            countryId,
                            countryName,
                            locationId
                          );
                          ev.stopPropagation();
                        }}
                      >
                        <EditIcon
                          color="primary"
                          sx={{ width: "18px", height: "18px" }}
                        />
                      </IconButton>
                    </div>

                    <ExpandMore
                      sx={{ padding: "2px" }}
                      data-testid={`expand-${index}`}
                      expand={shouldExpandCard.toString()}
                      aria-expanded={shouldExpandCard}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Grid>
                </Grid>

                <Collapse in={shouldExpandCard} timeout="auto" unmountOnExit>
                  <CardContent className={classes.allocationCardContent}>
                    {allocations.map((alloc: any) => (
                      <Grid
                        container
                        key={`allocation-${index}-${alloc.orgId}`}
                        className={classes.allocationGrid}
                      >
                        <Grid
                          size={10}
                          key={`alloc-name-${alloc.orgId}`}
                          data-testid={`alloc-name-${alloc.orgId}`}
                        >
                          <Typography>{alloc.orgName}</Typography>
                        </Grid>
                        <Grid
                          size={2}
                          key={`alloc-value-${alloc.orgId}`}
                          data-testid={`alloc-value-${alloc.orgId}`}
                          className={classes.allocationValue}
                        >
                          <Typography>{alloc.allocation} %</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </CardContent>
                </Collapse>
              </CardContent>
            </Card>
          </>
        );
      },
    },
  ];

  return (
    <PanelExDialog
      id="create-allocationSet-dialog"
      data-testid="create-allocationSet-dialog"
      open={openCreateAllocationSet}
      title={`New Allocation`}
      submitText="Create Allocation"
      submitEnabled={createNewAllocationSubmitEnabled}
      onClose={closeCreateNewAllocationSet}
      onSubmit={handleCreateAllocationSet}
      showAction={true}
    >
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "5",
          padding: "20px",
          paddingTop: "5px",
          minWidth: 620,
          minHeight: 400,
        }}
      >
        <FormControl sx={{ mt: 0 }}>
          <Grid container spacing={1}>
            <Grid size={6}></Grid>
          </Grid>
        </FormControl>

        <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Grid size={5}>
            <TextField
              className={classes.allocationSetName}
              id="allocation-set-name"
              data-testid="allocation-set-name"
              label=""
              size="small"
              value={allocationName}
              onChange={handleAllocationNameChange}
              fullWidth={true}
              error={!!error}
              helperText={error}
            />
          </Grid>
          <Grid size={7}>
            <Stack direction="row" className={classes.stack}>
              <Select
                labelId="location-filter-country-label"
                id="location-filter-country"
                data-testid="location-filter-country"
                value={newAllocSearchOption}
                className={classes.select}
                onChange={handleCountryChange}
                variant="outlined"
                disabled={!!allocationCountryId}
              >
                <MenuItem value={"1"}>AU</MenuItem>
                <MenuItem value={"2"}>NZ</MenuItem>
              </Select>
              <PanelExAutocomplete
                id="location-filter"
                options={newAllocSuggestions}
                value={newAllocSearchText}
                getOptionLabel={(option: any) =>
                  option.name || newAllocSearchText || ""
                }
                placeholder="Search Location"
                onInputChange={(value: any) => {
                  handleInputChange(value);
                }}
                renderOption={(props: any, option: any) => {
                  const { id, name, type, countryId, countryName, isExisting } =
                    option;
                  const displayName =
                    type === "Country"
                      ? [name, type].join(" ")
                      : [countryName, type, name].join(" ");
                  const regex = new RegExp(
                    escapedSpecialCharacters(newAllocSearchText),
                    "gi"
                  );
                  const response = displayName.replace(
                    regex,
                    function (str: any) {
                      return "<span style='font-weight:500'>" + str + "</span>";
                    }
                  );
                  return (
                    <li
                      {...props}
                      data-testid={`add-location-label-${id}`}
                      id={`add-location-label-${id}`}
                      onClick={(ev) => {
                        !isExisting &&
                          handleEditLocation(
                            id,
                            type,
                            name,
                            isExisting,
                            countryId,
                            countryName,
                            "locationId"
                          );
                        ev.stopPropagation();
                      }}
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: `${response}` }}
                      ></div>
                      <AddCircleIcon
                        data-testid={`add-location-icon-${id}`}
                        id={`add-location-icon-${id}`}
                        style={{
                          ...(!isExisting
                            ? {
                                color: "#757575",
                              }
                            : {
                                color: "rgba(0, 0, 0, 0.12)",
                              }),
                        }}
                      />
                    </li>
                  );
                }}
              />
            </Stack>
          </Grid>
          <Grid
            container
            size={12}
            sx={{ fontSize: "12px", paddingTop: "10px" }}
            data-testid="version-detail-bar"
            id="version-detail-bar"
          >
            <Grid sx={{ width: "76px" }}>
              <Typography className={classes.versionDetailLabel}>
                Scorecard
              </Typography>
              <FormControlLabel
                data-testid="version-score"
                id="version-score"
                className={classes.versionDetailSwitchLabel}
                control={
                  <Switch
                    checked={isScoreCard}
                    onChange={handleIsScoreCardChange}
                  />
                }
                label={!isScoreCard ? "No" : "Yes"}
              />
            </Grid>
            <Grid sx={{ width: "109px", marginLeft: "5px" }}>
              <Typography className={classes.versionDetailLabel}>
                Quote Weightings
              </Typography>
              <FormControlLabel
                data-testid="version-quote"
                id="version-quote"
                className={classes.versionDetailSwitchLabel}
                control={
                  <Switch checked={isQuote} onChange={handleIsQuoteChange} />
                }
                label={!isQuote ? "No" : "Yes"}
              />
            </Grid>
            <Grid sx={{ width: "152px", marginLeft: "5px" }}>
              {isQuote && (
                <TextField
                  className={classes.versionDetailText}
                  id="version-max-provider"
                  data-testid="version-max-provider"
                  label="No of Firms to Quote"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={"number"}
                  placeholder="Enter Number"
                  onChange={handleNoOfQuotesChange}
                  value={noOfQuotes}
                />
              )}
            </Grid>
          </Grid>
          <Grid size={12}>
            <Typography
              data-testid="allocation-detail-title"
              className={classes.Locationlabel}
            >
              {locations.length} Locations
            </Typography>
          </Grid>

          {locations.length > 0 ? (
            <Grid size={12} sx={{ minHeight: 400, width: "100%" }}>
              <Box>
                <DataGridPro
                  data-testid="tbl-allocation-details"
                  className={classes.detailsGrid}
                  getRowId={(row) => row.locationId}
                  rows={locations}
                  rowCount={locations.length}
                  getRowHeight={() => "auto"}
                  columns={columns}
                  pagination
                  columnHeaderHeight={0}
                  paginationModel={paginationModel}
                  paginationMode="client"
                  onPaginationModelChange={handlePaginationModelChange}
                  pageSizeOptions={[15, 20, 50]}
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                  onCellClick={(
                    params: GridCellParams,
                    event: MuiEvent<React.MouseEvent>
                  ) => {
                    handleExpandClick(params.row.locationId);
                    event.defaultMuiPrevented = true;
                  }}
                />
              </Box>
            </Grid>
          ) : (
            <Grid
              size={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                id="allocation-details-message"
                data-testid="allocation-details-message"
                className={classes.allocationDetailMessage}
              >
                <ReplyAllOutlined
                  sx={{
                    width: "45px",
                    height: "64px",
                    transform: `rotate(-90deg)`,
                    color: theme.colors.grey.lighter,
                  }}
                  id="allocation-details-message-icon"
                  data-testid="allocation-details-message-icon"
                />
                <Typography>Get Started by Adding a Location</Typography>
                <Typography>
                  Allocate valuation jobs to valuation firms based on their
                  coverage area.
                </Typography>
              </Box>
            </Grid>
          )}
          <PanelExDialog
            id="remove-dialog"
            data-testid="remove-dialog"
            open={openRemove}
            title={`Are you sure you want to remove ${selectedLocationName}?`}
            submitText="Yes, Remove"
            submitEnabled={true}
            onClose={() => setOpenRemove(false)}
            onSubmit={handleRemoveLocation}
            showAction={true}
            maxWidth="sm"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: "5",
                padding: "32px 24px 32px 24px",
              }}
            >
              <Typography variant="body1">
                Removing this location will remove any associated valuation
                firms. This action cannot be undone.
              </Typography>
            </Box>
          </PanelExDialog>
          {selectedLocation && (
            <LocationModal
              allocationId={0}
              allocationVersionId={0}
              location={selectedLocation}
              open={isLocationModalOpen}
              setOpen={setLocationModalOpen}
            />
          )}
        </Grid>
      </Box>
    </PanelExDialog>
  );
}
