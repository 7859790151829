import reducerToken from "./reducerToken";
import ui from "./reducerUI";
import allocation from "./reducerAllocation";
import fee from "./reducerFee";
import panel from "./reducerPanel";
import user from "./reducerUser";
import launchDarkly from "./reducerLaunchDarkly";
export interface RootState {
  tokenDetails: any;
  ui: any;
  allocation: any;
  fee: any;
  panel: any;
  user: any;
  config: any;
}

export default {
  tokenDetails: reducerToken,
  ui,
  allocation,
  fee,
  panel,
  user,
  config: launchDarkly,
};
