import { call, put, select, takeLeading, takeLatest } from "redux-saga/effects";
import {
  fetchFees,
  fetchFeeSet,
  fetchFeeDetail,
  fetchFeeVersionDetail,
  fetchLatestFeeActivationLog,
  fetchFeeVersions,
  fetchFeeActivationLogs,
  fetchLocationSuggestionByVersionId,
  fetchUpdateFeeVersionNotes,
  fetchUpdateFeeSetName,
  updateFeeDetails,
  fetchRemoveFeeLocation,
  fetchDiscardFeeVersion,
  updateFeeBrackets,
  scheduleFeeVersionActivationApi,
  fetchExportFeeVersion,
  copyVersion,
  fetchCancelFeeActivationLog,
  fetchFeeVersionComparisonDetails,
  importFeeFile,
  createNewFeeSetAPI,
  fetchAssociatePanels,
  acknowledgeFeeActivationLog,
  fetchExportMultiFees,
} from "../apis/apiFee";
import { getUserId } from "./selectors";
import {
  OPEN_ACTION_SNACKBAR,
  OPEN_SNACKBAR,
  OPEN_FAIL_ACTIVATION_CANCEL,
} from "./sagaUI";
import moment from "moment-timezone";
import { fetchLocationSuggestion, fetchSearchSuggestion } from "../apis/api";
import { resetPanelFeeAPI } from "../apis/apiPanel";

export const RESET_PAGINATION = "fee/RESET_PAGINATION";
export const FETCH_FEES = "fee/FETCH_FEES";
export const ATTEMPT_FETCH_FEES = "fee/ATTEMPT_FETCH_FEES";
export const SUCCEED_FETCH_FEES = "fee/SUCCEED_FETCH_FEES";
export const FAIL_FETCH_FEES = "fee/FAIL_FETCH_FEES";

export const FETCH_FEE_SET = "fee/FETCH_FEE_SET";
export const ATTEMPT_FETCH_FEE_SET = "fee/ATTEMPT_FETCH_FEE_SET";
export const SUCCEED_FETCH_FEE_SET = "fee/SUCCEED_FETCH_FEE_SET";
export const FAIL_FETCH_FEE_SET = "fee/FAIL_FETCH_FEE_SET";

export const FETCH_FEE_DETAIL = "fee/FETCH_FEE_DETAIL";
export const ATTEMPT_FETCH_FEE_DETAIL = "fee/ATTEMPT_FETCH_FEE_DETAIL";
export const SUCCEED_FETCH_FEE_DETAIL = "fee/SUCCEED_FETCH_FEE_DETAIL";
export const FAIL_FETCH_FEE_DETAIL = "fee/FAIL_FETCH_FEE_DETAIL";

export const CHANGE_FEE_TAB = "fee/CHANGE_FEE_TAB";
export const CHANGE_FEE_VERSION_ID = "fee/CHANGE_FEE_VERSION_ID";

export const FETCH_FEE_VERSION_DETAIL = "fee/FETCH_FEE_VERSION_DETAIL";
export const ATTEMPT_FETCH_FEE_VERSION_DETAIL =
  "fee/ATTEMPT_FETCH_FEE_VERSION_DETAIL";
export const SUCCEED_FETCH_FEE_VERSION_DETAIL =
  "fee/SUCCEED_FETCH_FEE_VERSION_DETAIL";
export const FAIL_FETCH_FEE_VERSION_DETAIL =
  "fee/FAIL_FETCH_FEE_VERSION_DETAIL";

export const FETCH_LATEST_FEE_ACTIVATION_LOG =
  "fee/FETCH_LATEST_FEE_ACTIVATION_LOG";
export const ATTEMPT_FETCH_LATEST_FEE_ACTIVATION_LOG =
  "fee/ATTEMPT_FETCH_LATEST_FEE_ACTIVATION_LOG";
export const SUCCEED_FETCH_LATEST_FEE_ACTIVATION_LOG =
  "fee/SUCCEED_FETCH_LATEST_FEE_ACTIVATION_LOG";
export const FAIL_FETCH_LATEST_FEE_ACTIVATION_LOG =
  "fee/FAIL_FETCH_LATEST_FEE_ACTIVATION_LOG";

export const FETCH_FEE_VERSIONS = "fee/FETCH_FEE_VERSIONS";
export const ATTEMPT_FETCH_FEE_VERSIONS = "fee/ATTEMPT_FETCH_FEE_VERSIONS";
export const SUCCEED_FETCH_FEE_VERSIONS = "fee/SUCCEED_FETCH_FEE_VERSIONS";
export const FAIL_FETCH_FEE_VERSIONS = "fee/FAIL_FETCH_FEE_VERSIONS";

export const FETCH_FEE_ACTIVATION_LOGS = "fee/FETCH_FEE_ACTIVATION_LOGS";
export const ATTEMPT_FETCH_FEE_ACTIVATION_LOGS =
  "fee/ATTEMPT_FETCH_FEE_ACTIVATION_LOGS";
export const SUCCEED_FETCH_FEE_ACTIVATION_LOGS =
  "fee/SUCCEED_FETCH_FEE_ACTIVATION_LOGS";
export const FAIL_FETCH_FEE_ACTIVATION_LOGS =
  "fee/FAIL_FETCH_FEE_ACTIVATION_LOGS";

export const UPDATE_LOCATION_OPTION = "fee/UPDATE_LOCATION_OPTION";
export const UPDATE_LOCATION_TEXT_CHANGE = "fee/UPDATE_LOCATION_TEXT_CHANGE";
export const ATTEMPT_FETCH_LOCATION_SUGGESTION =
  "fee/ATTEMPT_FETCH_LOCATION_SUGGESTION";
export const SUCCEED_FETCH_LOCATION_SUGGESTION =
  "fee/SUCCEED_FETCH_LOCATION_SUGGESTION";
export const FAIL_FETCH_LOCATION_SUGGESTION =
  "fee/FAIL_FETCH_LOCATION_SUGGESTION";
export const CLEAR_LOCATION_SUGGESTION = "fee/CLEAR_FETCH_LOCATION_SUGGESTION";

export const UPDATE_FEE_VERSION_NOTES = "fee/UPDATE_FEE_VERSION_NOTES";
export const ATTEMPT_UPDATE_FEE_VERSION_NOTES =
  "fee/ATTEMPT_UPDATE_FEE_VERSION_NOTES";
export const SUCCEED_UPDATE_FEE_VERSION_NOTES =
  "fee/SUCCEED_UPDATE_FEE_VERSION_NOTES";
export const FAIL_UPDATE_FEE_VERSION_NOTES =
  "fee/FAIL_UPDATE_FEE_VERSION_NOTES";

export const UPDATE_FEE_SET_NAME = "fee/UPDATE_FEE_SET_NAME";
export const ATTEMPT_UPDATE_FEE_SET_NAME = "fee/ATTEMPT_UPDATE_FEE_SET_NAME";
export const SUCCEED_UPDATE_FEE_SET_NAME = "fee/SUCCEED_UPDATE_FEE_SET_NAME";
export const FAIL_DUPLICATE_UPDATE_FEE_SET_NAME =
  "fee/FAIL_DUPLICATE_UPDATE_FEE_SET_NAME";
export const FAIL_UPDATE_FEE_SET_NAME = "fee/FAIL_UPDATE_FEE_SET_NAME";
export const RESET_UPDATE_NAME_ERROR = "fee/RESET_UPDATE_NAME_ERROR";

export const OPEN_FEE_SET_NAME_MODAL = "fee/OPEN_FEE_SET_NAME_MODAL";

export const UPDATE_FEE_DETAIL = "fee/UPDATE_FEE_DETAIL";
export const ATTEMPT_UPDATE_FEE_DETAIL = "fee/ATTEMPT_UPDATE_FEE_DETAIL";
export const SUCCEED_UPDATE_FEE_DETAIL = "fee/SUCCEED_UPDATE_FEE_DETAIL";
export const FAIL_UPDATE_FEE_DETAIL = "fee/FAIL_UPDATE_FEE_DETAIL";

export const REMOVE_FEE_LOCATION = "fee/REMOVE_FEE_LOCATION";
export const ATTEMPT_REMOVE_FEE_LOCATION = "fee/ATTEMPT_REMOVE_FEE_LOCATION";
export const SUCCEED_REMOVE_FEE_LOCATION = "fee/SUCCEED_REMOVE_FEE_LOCATION";
export const FAIL_REMOVE_FEE_LOCATION = "fee/FAIL_REMOVE_FEE_LOCATION";

export const DISCARD_FEE_VERSION = "fee/DISCARD_FEE_VERSION";
export const ATTEMPT_DISCARD_FEE_VERSION = "fee/ATTEMPT_DISCARD_FEE_VERSION";
export const SUCCEED_DISCARD_FEE_VERSION = "fee/SUCCEED_DISCARD_FEE_VERSION";
export const FAIL_DISCARD_FEE_VERSION = "fee/FAIL_DISCARD_FEE_VERSION";

export const UPDATE_FEE_BRACKETS = "fee/UPDATE_FEE_BRACKETS";
export const ATTEMPT_UPDATE_FEE_BRACKETS = "fee/ATTEMPT_UPDATE_FEE_BRACKETS";
export const SUCCEED_UPDATE_FEE_BRACKETS = "fee/SUCCEED_UPDATE_FEE_BRACKETS";
export const FAIL_UPDATE_FEE_BRACKETS = "fee/FAIL_UPDATE_FEE_BRACKETS";

export const SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/SCHEDULE_FEE_VERSION_ACTIVATION";
export const ATTEMPT_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/ATTEMPT_SCHEDULE_FEE_VERSION_ACTIVATION";
export const SUCCEED_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/SUCCEED_SCHEDULE_FEE_VERSION_ACTIVATION";
export const FAIL_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/FAIL_SCHEDULE_FEE_VERSION_ACTIVATION";

export const EXPORT_FEE_VERSION = "fee/EXPORT_FEE_VERSION";
export const ATTEMPT_EXPORT_FEE_VERSION = "fee/ATTEMPT_EXPORT_FEE_VERSION";
export const SUCCEED_EXPORT_FEE_VERSION = "fee/SUCCEED_EXPORT_FEE_VERSION";
export const FAIL_EXPORT_FEE_VERSION = "fee/FAIL_EXPORT_FEE_DETAIL";

export const COPY_FEE_VERSION = "fee/COPY_FEE_VERSION";
export const ATTEMPT_COPY_FEE_VERSION = "fee/ATTEMPT_COPY_FEE_VERSION";
export const SUCCEED_COPY_FEE_VERSION = "fee/SUCCEED_COPY_FEE_VERSION";
export const FAIL_COPY_FEE_VERSION = "fee/FAIL_COPY_FEE_VERSION";

export const CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION";
export const ATTEMPT_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/ATTEMPT_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION";
export const SUCCEED_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/SUCCEED_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION";
export const FAIL_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/FAIL_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION";

export const SET_VERSIONS_TO_COMPARE = "fee/SET_VERSIONS_TO_COMPARE";
export const SAVE_VERSIONS_TO_COMPARE = "fee/SAVE_VERSIONS_TO_COMPARE";
export const RESET_VERSIONS_TO_COMPARE = "fee/RESET_VERSIONS_TO_COMPARE";
export const CLEAR_VERSIONS_TO_COMPARE = "fee/CLEAR_VERSIONS_TO_COMPARE";
export const SHOW_VERSIONS_COMPARISON = "fee/SHOW_VERSIONS_COMPARISON";

export const FETCH_VERSIONS_COMPARISON_DETAILS =
  "fee/FETCH_VERSIONS_COMPARISON_DETAILS";
export const ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS =
  "fee/ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS";
export const SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS =
  "fee/SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS";
export const FAIL_FETCH_VERSIONS_COMPARISON_DETAILS =
  "fee/FAIL_FETCH_VERSIONS_COMPARISON_DETAILS";

export const OPEN_IMPORT_MODAL = "fee/OPEN_IMPORT_MODAL";
export const IMPORT_FEE_VERSION = "fee/IMPORT_FEE_VERSION";
export const ATTEMPT_IMPORT_FEE_VERSION = "fee/ATTEMPT_IMPORT_FEE_VERSION";
export const SUCCEED_IMPORT_FEE_VERSION = "fee/SUCCEED_IMPORT_FEE_VERSION";
export const FAIL_VALIDATION_IMPORT_FEE_VERSION =
  "fee/FAIL_VALIDATION_IMPORT_FEE_VERSION";
export const FAIL_IMPORT_FEE_VERSION = "fee/FAIL_IMPORT_FEE_VERSION";
export const RESET_VALIDATION_ERRORS = "fee/RESET_VALIDATION_ERRORS";

export const SHOW_CREATE_NEW_FEE = "fee/SHOW_CREATE_NEW_FEE";
export const UPDATE_FEENAME_NEW_FEE = "fee/UPDATE_FEENAME_NEW_FEE";
export const UPDATE_LOCATION_OPTION_NEW_FEE =
  "fee/UPDATE_LOCATION_OPTION_NEW_FEE";
export const UPDATE_LOCATION_NEW_FEE = "fee/UPDATE_LOCATION_NEW_FEE";
export const REMOVE_FEE_LOCATION_NEW_FEE = "fee/REMOVE_FEE_LOCATION_NEW_FEE";
export const CREATE_NEW_FEE = "fee/CREATE_NEW_FEE";

export const UPDATE_LOCATION_TEXT_CHANGE_NEW_FEE =
  "fee/UPDATE_LOCATION_TEXT_CHANGE_NEW_FEE";
export const ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_FEE =
  "fee/ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_FEE";
export const SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_FEE =
  "fee/SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_FEE";
export const FAIL_FETCH_LOCATION_SUGGESTION_NEW_FEE =
  "fee/FAIL_FETCH_LOCATION_SUGGESTION_NEW_FEE";
export const CLEAR_LOCATION_SUGGESTION_NEW_FEE =
  "fee/CLEAR_FETCH_LOCATION_SUGGESTION_NEW_FEE";

export const UPDATE_FEE_BRACKETS_NEW_FEE = "fee/UPDATE_FEE_BRACKETS_NEW_FEE";

export const ATTEMPT_CREATE_NEW_FEE = "fee/ATTEMPT_CREATE_NEW_FEE";
export const ERROR_CREATE_NEW_FEE = "fee/ERROR_CREATE_NEW_FEE";
export const FAIL_CREATE_NEW_FEE = "fee/FAIL_CREATE_NEW_FEE";
export const SUCCEED_CREATE_NEW_FEE = "fee/SUCCEED_CREATE_NEW_FEE";

export const RESET_PANEL_FEE = "fee/RESET_PANEL_FEE";
export const ATTEMPT_RESET_PANEL_FEE = "panel/ATTEMPT_RESET_PANEL_FEE";
export const SUCCEED_RESET_PANEL_FEE = "panel/SUCCEED_RESET_PANEL_FEE";
export const FAIL_RESET_PANEL_FEE = "panel/FAIL_RESET_PANEL_FEE";

export const FETCH_ASSOCIATED_PANELS = "fee/FETCH_ASSOCIATED_PANELS";
export const ATTEMPT_FETCH_ASSOCIATED_PANELS =
  "fee/ATTEMPT_FETCH_ASSOCIATED_PANELS";
export const SUCCEED_FETCH_ASSOCIATED_PANELS =
  "fee/SUCCEED_FETCH_ASSOCIATED_PANELS";
export const FAIL_FETCH_ASSOCIATED_PANELS = "fee/FAIL_FETCH_ASSOCIATED_PANELS";

export const FILTER_NAME_CHANGE = "fee/FILTER_NAME_CHANGE";
export const ATTEMPT_FETCH_FILTER_NAME_SUGGESTION =
  "fee/ATTEMPT_FETCH_FILTER_NAME_SUGGESTION";
export const SUCCEED_FETCH_FILTER_NAME_SUGGESTION =
  "fee/SUCCEED_FETCH_FILTER_NAME_SUGGESTION";
export const FAIL_FETCH_FILTER_NAME_SUGGESTION =
  "fee/FAIL_FETCH_FILTER_NAME_SUGGESTION";
export const CLEAR_FILTER_NAME_SUGGESTION = "fee/CLEAR_FILTER_NAME_SUGGESTION";

export const CHANGE_FILTER_COUNTRY = "fee/CHANGE_FILTER_COUNTRY";
export const FILTER_LOCATION_CHANGE = "fee/FILTER_LOCATION_CHANGE";
export const ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION =
  "fee/ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION";
export const SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION =
  "fee/SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION";
export const FAIL_FETCH_FILTER_LOCATION_SUGGESTION =
  "fee/FAIL_FETCH_FILTER_LOCATION_SUGGESTION";
export const CLEAR_FILTER_LOCATION_SUGGESTION =
  "fee/CLEAR_FILTER_LOCATION_SUGGESTION";
export const ADD_FILTER_LOCATION = "fee/ADD_FILTER_LOCATION";
export const DELETE_FILTER_LOCATION = "fee/DELETE_FILTER_LOCATION";
export const CLEAR_ALL_FILTER = "fee/CLEAR_ALL_FILTER";
export const OPEN_FILTER = "fee/OPEN_FILTER";
export const COPY_FILTER = "fee/COPY_FILTER";
export const UPDATE_FEE_STATUS_FILTER = "fee/UPDATE_FEE_STATUS_FILTER";

export const ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION";
export const ATTEMPT_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/ATTEMPT_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION";
export const SUCCEED_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/SUCCEED_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION";
export const FAIL_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION =
  "fee/FAIL_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION";

export const EXPORT_MULTI_FEES = "fee/EXPORT_MULTI_FEES";
export const ATTEMPT_EXPORT_MULTI_FEES = "fee/ATTEMPT_EXPORT_MULTI_FEES";
export const SUCCEED_EXPORT_MULTI_FEES = "fee/SUCCEED_EXPORT_MULTI_FEES";
export const FAIL_EXPORT_MULTI_FEES = "fee/FAIL_EXPORT_MULTI_FEES";
export const CLOSE_EXPORT_MULTI_FEES = "fee/CLOSE_EXPORT_MULTI_FEES";

export function* getFees(param) {
  let objAttempt = {
    type: ATTEMPT_FETCH_FEES,
    data: {
      currentPage: param.payload.filter.currentPage,
      pageSize: param.payload.filter.pageSize,
      isFiltered: param.payload.filter.isFiltered,
      sortBy: param.payload.filter.sortBy,
      sortDir: param.payload.filter.sortDir,
    },
  };
  if (param.payload.filter.isFiltered) {
    objAttempt["meta"] = {
      analytics: {
        feeName: param.payload.filter.name,
        feeStatus: param.payload.filter.status,
        feeLocations: param.payload.filter.locations
          .map((loc) => loc.name)
          .join(","),
      },
    };
  }
  yield put(objAttempt);
  try {
    const { data: response } = yield call(fetchFees, param.payload.filter);
    yield put({
      type: SUCCEED_FETCH_FEES,
      data: {
        ...response,
        name: param.payload.filter.name,
        status: param.payload.filter.status,
        locations: param.payload.filter.locations,
        nameSuggestion: param.payload.filter.nameSuggestion,
        countryId: param.payload.filter.countryId,
        locationText: param.payload.filter.locationText,
        locationSuggestion: param.payload.filter.locationSuggestion,
        sortBy: param.payload.filter.sortBy,
        sortDir: param.payload.filter.sortDir,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FEES,
    });
  }
}

export function* getFeeSet(param) {
  yield put({
    type: ATTEMPT_FETCH_FEE_SET,
  });

  try {
    const { data: detail } = yield call(fetchFeeSet, param.feeId);
    yield put({
      type: SUCCEED_FETCH_FEE_SET,
      data: {
        ...detail,
      },
      meta: {
        analytics: {
          feeSetId: detail.feePanelId,
          feeSetName: detail.name,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FEE_SET,
      meta: {
        analytics: {
          feeSetId: param.feeId,
          feeSetName: param.feeName,
        },
      },
    });
  }
}

export function* getFeeDetail(param) {
  yield put({
    type: ATTEMPT_FETCH_FEE_DETAIL,
    data: {
      currentPage: param.currentPage,
      pageSize: param.pageSize,
    },
  });

  try {
    const { data: detail } = yield call(
      fetchFeeDetail,
      param.feeId,
      param.feeRevId,
      param.currentPage,
      param.pageSize,
      param.locationFilter,
      param.countryFilter
    );
    yield put({
      type: SUCCEED_FETCH_FEE_DETAIL,
      data: {
        ...detail,
      },
      payload: {
        ...param,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FEE_DETAIL,
    });
  }
}

export function* getFeeVersionDetail(param) {
  yield put({ type: ATTEMPT_FETCH_FEE_VERSION_DETAIL });

  try {
    const { data: detail } = yield call(
      fetchFeeVersionDetail,
      param.payload.feeId,
      param.payload.feeRevId
    );
    yield put({
      type: SUCCEED_FETCH_FEE_VERSION_DETAIL,
      data: {
        ...detail,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({ type: FAIL_FETCH_FEE_VERSION_DETAIL });
  }
}

export function* getLatestFeeActivationLog(param) {
  yield put({
    type: ATTEMPT_FETCH_LATEST_FEE_ACTIVATION_LOG,
  });

  try {
    const { data: detail } = yield call(
      fetchLatestFeeActivationLog,
      param.feeId
    );

    yield put({
      type: SUCCEED_FETCH_LATEST_FEE_ACTIVATION_LOG,
      data: {
        ...detail,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_LATEST_FEE_ACTIVATION_LOG,
    });
  }
}

export function* getFeeVersions(param) {
  yield put({
    type: ATTEMPT_FETCH_FEE_VERSIONS,
    data: {
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
    },
  });
  try {
    const { data: response } = yield call(
      fetchFeeVersions,
      param.payload.feeId,
      param.payload.currentPage,
      param.payload.pageSize
    );
    yield put({
      type: SUCCEED_FETCH_FEE_VERSIONS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FEE_VERSIONS,
    });
  }
}

export function* getFeeActivationLogs(param) {
  yield put({
    type: ATTEMPT_FETCH_FEE_ACTIVATION_LOGS,
    data: {
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
      sortBy: param.payload.sortBy,
      sortDir: param.payload.sortDir,
    },
  });
  try {
    const { data: response } = yield call(
      fetchFeeActivationLogs,
      param.payload.feeId,
      param.payload.currentPage,
      param.payload.pageSize,
      param.payload.sortBy,
      param.payload.sortDir
    );
    yield put({
      type: SUCCEED_FETCH_FEE_ACTIVATION_LOGS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FEE_ACTIVATION_LOGS,
    });
  }
}

export function* updateLocationTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_LOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestionByVersionId,
        data.text,
        data.option,
        data.feeId,
        data.feeRevId,
        data.isExisting
      );

      yield put({
        type: SUCCEED_FETCH_LOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_LOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_LOCATION_SUGGESTION,
    });
  }
}

export function* updateFeeVersionNotes(param) {
  yield put({ type: ATTEMPT_UPDATE_FEE_VERSION_NOTES });
  try {
    const feeVersionId = param.payload.feeVersionId;
    const feeId = param.payload.feeId;
    const userId = yield select(getUserId);
    const notes = param.payload.notes;

    yield call(fetchUpdateFeeVersionNotes, feeId, feeVersionId, userId, notes);
    yield put({
      type: SUCCEED_UPDATE_FEE_VERSION_NOTES,
      data: {
        feeVersionId,
        notes,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({ type: FAIL_UPDATE_FEE_VERSION_NOTES });
  }
}

export function* updateFeeSetName(param) {
  yield put({ type: ATTEMPT_UPDATE_FEE_SET_NAME });
  try {
    const feeId = param.payload.feeId;
    const name = param.payload.name;

    const { data: response } = yield call(fetchUpdateFeeSetName, feeId, name);

    if (response.isUpdated) {
      yield put({
        type: SUCCEED_UPDATE_FEE_SET_NAME,
        data: {
          name,
        },
        meta: {
          analytics: {
            feeId,
            name,
          },
        },
      });
    } else {
      yield put({
        type: FAIL_DUPLICATE_UPDATE_FEE_SET_NAME,
        data: {
          message: response.message,
        },
        meta: {
          analytics: {
            feeId,
            name,
          },
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_FEE_SET_NAME,
      meta: {
        analytics: {
          feeId: param.payload.feeId,
          name: param.payload.name,
        },
      },
    });
  }
}

export function* updateFeeDetail(param) {
  yield put({
    type: ATTEMPT_UPDATE_FEE_DETAIL,
  });
  const { feeId, feeVersionId, location, fees } = param.payload;
  const userId = yield select(getUserId);
  const displayName =
    location &&
    (location.type === "Country"
      ? location.name
      : [location.countryName, location.type, location.name].join(" "));

  try {
    const locationDetail = {
      feePanelId: feeId,
      feePanelRevId: feeVersionId,
      feePanelFilterId: location.feePanelFilterId,
      userId,
      countryId: location.countryId,
      countryName: location.countryName,
      stateId: location.type === "State" ? location.id : null,
      stateName: location.type === "State" ? location.name : null,
      regionId: location.type === "Region" ? location.id : null,
      regionName: location.type === "Region" ? location.name : null,
      postcodeId: location.type === "Postcode" ? location.id : null,
      postcodeName: location.type === "Postcode" ? location.name : null,
      fees: fees.map((b) => {
        return {
          feePanelFeesId: b.feePanelFeesId,
          fee: b.fee,
          feePanelBracketId: b.feePanelBracketId,
          lower: b.lower,
          upper: b.upper,
          isEdited: b.isEdited,
        };
      }),
    };

    const { data: updatedLocation } = yield call(
      updateFeeDetails,
      feeId,
      feeVersionId,
      userId,
      locationDetail
    );

    yield put({
      type: SUCCEED_UPDATE_FEE_DETAIL,
      payload: updatedLocation,
      meta: {
        analytics: {
          versionId: feeVersionId,
          location: displayName,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully Updated " + displayName,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_FEE_DETAIL,
      meta: {
        analytics: {
          versionId: feeVersionId,
          location: displayName,
        },
      },
    });
  }
}

export function* removeFeeLocation(param) {
  yield put({ type: ATTEMPT_REMOVE_FEE_LOCATION });
  try {
    const feeFilterId = param.payload.feeFilterId;
    const userId = yield select(getUserId);
    const { data: detail } = yield call(
      fetchRemoveFeeLocation,
      param.payload.feeId,
      param.payload.feeRevId,
      feeFilterId,
      userId,
      param.payload.pageSize,
      param.payload.locationFilter,
      param.payload.countryId
    );
    yield put({
      type: SUCCEED_REMOVE_FEE_LOCATION,
      data: {
        ...detail,
      },
      payload: {
        ...param.payload,
      },
      meta: {
        analytics: {
          versionId: param.payload.feeRevId,
          location: param.payload.locationName,
        },
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully Removed " + param.payload.locationName,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_REMOVE_FEE_LOCATION,
      meta: {
        analytics: {
          versionId: param.payload.feeRevId,
          location: param.payload.locationName,
        },
      },
    });
  }
}

export function* discardFeeVersion(param) {
  yield put({ type: ATTEMPT_DISCARD_FEE_VERSION });
  try {
    const feeRevId = param.payload.feeRevId;
    const userId = yield select(getUserId);
    yield call(fetchDiscardFeeVersion, param.payload.feeId, feeRevId, userId);
    yield put({
      type: SUCCEED_DISCARD_FEE_VERSION,
      data: {
        feeRevId,
      },
      meta: {
        analytics: {
          versionId: feeRevId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_DISCARD_FEE_VERSION,
      meta: {
        analytics: {
          versionId: param.payload.feeRevId,
        },
      },
    });
  }
}

export function* saveFeeBrackets(param) {
  yield put({ type: ATTEMPT_UPDATE_FEE_BRACKETS });
  const { feeId, feeRevId, feeBrackets } = param.payload;
  try {
    const userId = yield select(getUserId);
    const { data: feeBracketList } = yield call(
      updateFeeBrackets,
      feeId,
      feeRevId,
      feeBrackets,
      userId
    );
    yield put({
      type: SUCCEED_UPDATE_FEE_BRACKETS,
      payload: feeBracketList,
      meta: {
        analytics: {
          versionId: feeRevId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_FEE_BRACKETS,
      meta: {
        analytics: {
          versionId: feeRevId,
        },
      },
    });
  }
}

export function* scheduleFeeVersionActivation(param) {
  const { feeId, feeVersionId, activateDate } = param.payload;
  const userId = yield select(getUserId);
  yield put({
    type: ATTEMPT_SCHEDULE_FEE_VERSION_ACTIVATION,
  });
  try {
    const { data } = yield call(
      scheduleFeeVersionActivationApi,
      feeId,
      feeVersionId,
      userId,
      activateDate
    );

    const timezone = moment.tz.guess();
    const scheduled = moment(data.scheduled)
      .tz(timezone)
      .format("DD-MM-YYYY hh:mm:ss A");

    yield put({
      type: SUCCEED_SCHEDULE_FEE_VERSION_ACTIVATION,
      data,
      meta: {
        analytics: {
          versionId: param.payload.feeVersionId,
          scheduledDatetime: scheduled,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message:
          "Successfully Scheduled Version " +
          feeVersionId +
          " for " +
          scheduled,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_SCHEDULE_FEE_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: param.payload.feeVersionId,
          scheduledDatetime: activateDate[`$d`].toString(),
        },
      },
    });
  }
}

export function* exportFeeVersion(param) {
  yield put({ type: ATTEMPT_EXPORT_FEE_VERSION });

  try {
    const { data: response } = yield call(
      fetchExportFeeVersion,
      param.payload.feeId,
      param.payload.feeVersionId
    );
    const fileName =
      param.payload.feeSetName + "-V" + param.payload.feeVersionId + ".xlsx";
    // Create a temporary URL for the file blob
    const url = window.URL.createObjectURL(new Blob([response]));
    const element = document.createElement("a");
    element.style.display = "none";
    element.setAttribute("href", url);
    element.setAttribute("download", fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    yield put({
      type: SUCCEED_EXPORT_FEE_VERSION,
      meta: {
        analytics: {
          feeSetId: param.payload.feeId,
          feeSetName: param.payload.feeSetName,
          versionId: param.payload.feeVersionId,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully downloaded " + fileName,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_EXPORT_FEE_VERSION,
      meta: {
        analytics: {
          feeSetId: param.payload.feeId,
          feeSetName: param.payload.feeSetName,
          versionId: param.payload.feeVersionId,
        },
      },
    });
  }
}

export function* copyFeeVersion(param) {
  yield put({ type: ATTEMPT_COPY_FEE_VERSION });
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      copyVersion,
      param.payload.feeId,
      param.payload.feeVersionId,
      userId
    );
    yield put({
      type: SUCCEED_COPY_FEE_VERSION,
      data: {
        feeVersionId: response.feeVersionId,
      },
      meta: {
        analytics: {
          feeSetId: param.payload.feeId,
          versionId: param.payload.feeVersionId,
          newVersionId: response.feeVersionId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_COPY_FEE_VERSION,
      meta: {
        analytics: {
          feeSetId: param.payload.feeId,
          versionId: param.payload.feeVersionId,
        },
      },
    });
  }
}

export function* cancelScheduleFeeVersionActivation(param) {
  yield put({ type: ATTEMPT_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION });
  const { feeId, feePanelActivationLogId, feePanelActivationVersionId } =
    param.payload;
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      fetchCancelFeeActivationLog,
      feeId,
      feePanelActivationLogId,
      userId
    );
    if (response.canceled) {
      yield put({
        type: SUCCEED_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION,
        data: {
          feePanelActivationLogId,
        },
        meta: {
          analytics: {
            versionId: feePanelActivationVersionId,
            logId: feePanelActivationLogId,
          },
        },
      });
    } else {
      yield put({
        type: OPEN_FAIL_ACTIVATION_CANCEL,
        payload: {
          openFailCancel: true,
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: feePanelActivationVersionId,
          logId: feePanelActivationLogId,
        },
      },
    });
  }
}

export function* setVersionsToCompare(param) {
  yield put({ type: SAVE_VERSIONS_TO_COMPARE, payload: param.payload });
  const count = param.payload.count;
  const message =
    count === 1
      ? "1 Version Selected | Select Another Version To Compare"
      : count + " Versions Selected";

  yield put({
    type: OPEN_ACTION_SNACKBAR,
    payload: {
      message,
      variant: "info",
      isIcon: true,
      actionType: count === 2 ? "compareFeeVersions" : "",
      width: param.payload.width,
    },
  });
}

export function* clearVersionsToCompare() {
  yield put({ type: CLEAR_VERSIONS_TO_COMPARE });
}

export function* getFeeVersionComparisonDetails(param) {
  yield put({
    type: ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS,
  });

  try {
    const { data: detail } = yield call(
      fetchFeeVersionComparisonDetails,
      param.payload.feeId,
      param.payload.versionsList
    );
    yield put({
      type: SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS,
      data: {
        ...detail,
      },
      meta: {
        analytics: {
          version1: param.payload.versionsList[0],
          version2: param.payload.versionsList[1],
        },
      },
    });
  } catch (e) {
    yield put({
      type: FAIL_FETCH_VERSIONS_COMPARISON_DETAILS,
      meta: {
        analytics: {
          version1: param.payload.versionsList[0],
          version2: param.payload.versionsList[1],
        },
      },
    });
  }
}

export function* importFeeVersion(param) {
  yield put({ type: ATTEMPT_IMPORT_FEE_VERSION });

  try {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    param.payload.userId = yield select(getUserId);

    const { data: response } = yield call(
      importFeeFile,
      param.payload,
      axiosConfig
    );
    const validationErrors = response.validationErrors;
    if (validationErrors && validationErrors.length > 0) {
      yield put({
        type: FAIL_VALIDATION_IMPORT_FEE_VERSION,
        data: {
          validationErrors,
        },
        meta: {
          analytics: {
            feeSetId: param.payload.id,
            feeSetName: param.payload.name,
          },
        },
      });
    } else {
      yield put({ type: RESET_VALIDATION_ERRORS });
      const feePanelRev = response.feePanelRev;
      yield put({
        type: SUCCEED_IMPORT_FEE_VERSION,
        data: {
          feePanelRev,
        },
        meta: {
          analytics: {
            feeSetId: param.payload.id,
            feeSetName: param.payload.name,
            versionId: feePanelRev.feePanelRevId,
          },
        },
      });
      yield put({ type: OPEN_IMPORT_MODAL, data: { isOpen: false } });
      yield put({
        type: CHANGE_FEE_VERSION_ID,
        id: feePanelRev.feePanelRevId,
      });
      if (param.payload.isRedirect) {
        yield put({
          type: OPEN_SNACKBAR,
          payload: {
            message: "Successfully Imported " + param.payload.file.name,
            variant: "info",
            isIcon: true,
            duration: 5000,
            actionType: "redirectFee",
            id: param.payload.id,
          },
        });
      } else {
        yield put({ type: CHANGE_FEE_TAB, index: 2 });
        yield put({
          type: OPEN_SNACKBAR,
          payload: {
            message: "Successfully Imported " + param.payload.file.name,
            variant: "info",
            isIcon: true,
            duration: 5000,
            actionType: "previewDetailFee",
            id: param.payload.id,
          },
        });
      }
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_IMPORT_FEE_VERSION,
      meta: {
        analytics: {
          feeSetId: param.payload.id,
          feeSetName: param.payload.name,
        },
      },
    });
  }
}

export function* updateLocationTextChangeNewFee(data) {
  yield put({
    type: ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_FEE,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestion,
        data.text,
        data.option
      );

      yield put({
        type: SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_FEE,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_LOCATION_SUGGESTION_NEW_FEE,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_LOCATION_SUGGESTION_NEW_FEE,
    });
  }
}

export function* createNewFeeSet(param) {
  yield put({
    type: ATTEMPT_CREATE_NEW_FEE,
  });

  const userId = yield select(getUserId);
  const { feeName, feePanelBrackets, locations } = param.payload;
  try {
    const feeDetails = {
      name: feeName,
      userId,
      feePanelBrackets,
      locations,
    };

    const { data: updatedDetails } = yield call(createNewFeeSetAPI, feeDetails);
    yield put({
      type: SUCCEED_CREATE_NEW_FEE,
      payload: updatedDetails,
      meta: {
        analytics: {
          feeSetId: updatedDetails.id,
          feeSetName: feeName,
          versionId: updatedDetails.revId,
        },
      },
    });
  } catch (err) {
    console.warn(err);
    if (err.response.data.message === "Name Already Exists") {
      yield put({
        type: ERROR_CREATE_NEW_FEE,
        error: "Please select another name as this one exists",
        meta: {
          analytics: {
            feeSetName: feeName,
            message: err.response.data.message,
          },
        },
      });
    } else {
      yield put({
        type: FAIL_CREATE_NEW_FEE,
        meta: {
          analytics: {
            feeSetName: feeName,
          },
        },
      });
    }
  }
}

export function* resetPanelFee(param) {
  const { feeId, feeName, panelId, panelVersionId } = param.payload;
  yield put({
    type: ATTEMPT_RESET_PANEL_FEE,
  });

  try {
    const userId = yield select(getUserId);
    yield call(resetPanelFeeAPI, panelId, panelVersionId, userId);
    yield put({
      type: SUCCEED_RESET_PANEL_FEE,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          feeId,
          feeName,
        },
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully unlinked from Panel",
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_RESET_PANEL_FEE,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          feeId,
          feeName,
        },
      },
    });
  }
}

export function* getAssociatedPanels(param) {
  yield put({
    type: ATTEMPT_FETCH_ASSOCIATED_PANELS,
    data: {
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
      sortBy: param.payload.sortBy,
      sortDir: param.payload.sortDir,
    },
  });
  try {
    const { data: response } = yield call(
      fetchAssociatePanels,
      param.payload.feeId,
      param.payload.currentPage,
      param.payload.pageSize,
      param.payload.sortBy,
      param.payload.sortDir
    );
    yield put({
      type: SUCCEED_FETCH_ASSOCIATED_PANELS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ASSOCIATED_PANELS,
    });
  }
}

export function* filterNameChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_NAME_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchSearchSuggestion,
        data.text,
        "fees"
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_NAME_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_NAME_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_NAME_SUGGESTION,
    });
  }
}

export function* filterLocationChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestion,
        data.text,
        data.countryId
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_LOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_LOCATION_SUGGESTION,
    });
  }
}

export function* acknowledgeScheduleFeeVersionActivation(param) {
  yield put({ type: ATTEMPT_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION });
  const { feeId, feePanelActivationLogId, feePanelActivationVersionId } =
    param.payload;
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      acknowledgeFeeActivationLog,
      feeId,
      feePanelActivationLogId,
      userId
    );
    if (response.updated) {
      yield put({
        type: SUCCEED_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION,
        data: {
          feePanelActivationLogId,
        },
        meta: {
          analytics: {
            versionId: feePanelActivationVersionId,
            logId: feePanelActivationLogId,
          },
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: feePanelActivationVersionId,
          logId: feePanelActivationLogId,
        },
      },
    });
  }
}

export function* exportMultiFees(param) {
  yield put({ type: ATTEMPT_EXPORT_MULTI_FEES });

  try {
    if (param.payload.feeIds.length > 0) {
      const { data: response } = yield call(
        fetchExportMultiFees,
        param.payload.feeIds
      );
      const timezone = moment.tz.guess();
      const currentDate = moment.tz(new Date(), timezone).format("DD/MM/YYYY");

      const fileName = "Multi Fee Download " + currentDate + ".xlsx";

      // Create a temporary URL for the file blob
      const url = window.URL.createObjectURL(new Blob([response]));
      const element = document.createElement("a");
      element.style.display = "none";
      element.setAttribute("href", url);
      element.setAttribute("download", fileName);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      yield put({
        type: SUCCEED_EXPORT_MULTI_FEES,
        meta: {
          analytics: {
            feeSetIds: param.payload.feeIds,
          },
        },
      });

      yield put({
        type: OPEN_SNACKBAR,
        payload: {
          message: "Successfully downloaded " + fileName,
          variant: "info",
          isIcon: true,
        },
      });
    } else {
      yield put({
        type: OPEN_SNACKBAR,
        payload: {
          message: "No fees selected for download",
          variant: "info",
          isIcon: false,
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_EXPORT_MULTI_FEES,
      meta: {
        analytics: {
          feeSetIds: param.payload.feeIds,
        },
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Failed download " + e.message,
        variant: "info",
        isIcon: true,
      },
    });
  }
}

export default function* sagaFee() {
  yield takeLatest(FETCH_FEES, getFees);
  yield takeLeading(FETCH_FEE_SET, getFeeSet);
  yield takeLatest(FETCH_FEE_DETAIL, getFeeDetail);
  yield takeLatest(FETCH_FEE_VERSION_DETAIL, getFeeVersionDetail);
  yield takeLatest(FETCH_LATEST_FEE_ACTIVATION_LOG, getLatestFeeActivationLog);
  yield takeLatest(FETCH_FEE_VERSIONS, getFeeVersions);
  yield takeLatest(FETCH_FEE_ACTIVATION_LOGS, getFeeActivationLogs);
  yield takeLatest(UPDATE_LOCATION_TEXT_CHANGE, updateLocationTextChange);
  yield takeLeading(UPDATE_FEE_VERSION_NOTES, updateFeeVersionNotes);
  yield takeLeading(UPDATE_FEE_SET_NAME, updateFeeSetName);
  yield takeLeading(UPDATE_FEE_DETAIL, updateFeeDetail);
  yield takeLeading(REMOVE_FEE_LOCATION, removeFeeLocation);
  yield takeLeading(DISCARD_FEE_VERSION, discardFeeVersion);
  yield takeLeading(UPDATE_FEE_BRACKETS, saveFeeBrackets);
  yield takeLeading(
    SCHEDULE_FEE_VERSION_ACTIVATION,
    scheduleFeeVersionActivation
  );
  yield takeLeading(EXPORT_FEE_VERSION, exportFeeVersion);
  yield takeLeading(COPY_FEE_VERSION, copyFeeVersion);
  yield takeLeading(
    CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION,
    cancelScheduleFeeVersionActivation
  );
  yield takeLeading(SET_VERSIONS_TO_COMPARE, setVersionsToCompare);
  yield takeLeading(RESET_VERSIONS_TO_COMPARE, clearVersionsToCompare);
  yield takeLeading(
    FETCH_VERSIONS_COMPARISON_DETAILS,
    getFeeVersionComparisonDetails
  );
  yield takeLeading(IMPORT_FEE_VERSION, importFeeVersion);
  yield takeLatest(
    UPDATE_LOCATION_TEXT_CHANGE_NEW_FEE,
    updateLocationTextChangeNewFee
  );
  yield takeLeading(CREATE_NEW_FEE, createNewFeeSet);
  yield takeLeading(RESET_PANEL_FEE, resetPanelFee);
  yield takeLatest(FETCH_ASSOCIATED_PANELS, getAssociatedPanels);
  yield takeLatest(FILTER_NAME_CHANGE, filterNameChange);
  yield takeLatest(FILTER_LOCATION_CHANGE, filterLocationChange);
  yield takeLeading(
    ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION,
    acknowledgeScheduleFeeVersionActivation
  );
  yield takeLeading(EXPORT_MULTI_FEES, exportMultiFees);
}
