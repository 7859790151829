import { applyMiddleware, combineReducers, createStore, Reducer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware, { Saga, Task } from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./staticReducers/reducers";
import rootSaga from "./staticSagas/sagas";
import segmentMiddleware from "./middleware/segment";

const persistConfig = {
  key: "panel",
  storage,
  version: 2,
};

const sagaMiddleware = createSagaMiddleware({
  onError(error, errorInfo) {
    console.error(
      "errors reaching the root saga will terminate the whole saga tree",
      error,
      errorInfo
    );
  },
});

// reducers that would always be present with the application

function createReducer(asyncReducers?: Record<string, Reducer>) {
  return persistReducer(
    persistConfig,
    combineReducers({
      ...rootReducer,
      ...asyncReducers,
    })
  );
}

// runSaga is middleware.run function
// rootSaga is a your root saga for static saga
function createSagaInjector(runSaga: (saga: Saga) => Task, staticSaga: Saga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map<string, Task>();

  const injectSaga = (key: string, saga: Saga) => {
    // We won't run saga if it is already injected
    if (injectedSagas.has(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a statically loaded file,
  injectSaga("root", staticSaga);

  return injectSaga;
}

export default function configureStore(preloadedState = {}) {
  const enhancedMiddleware = applyMiddleware(sagaMiddleware, segmentMiddleware);

  // TODO: change when going to prod
  const composedEnhancers = composeWithDevTools(enhancedMiddleware);

  // Add a dictionary to keep track of the registered async reducers
  const store = createStore(createReducer(), preloadedState, composedEnhancers);
  const persistor = persistStore(store);

  const asyncReducers: Record<string, Reducer> = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  const injectReducer = (key: string, asyncReducer: Reducer) => {
    if (asyncReducers[key]) {
      return;
    }
    asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(asyncReducers));
    persistor.persist();
  };

  const injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

  return {
    store,
    persistor,
    injectReducer,
    injectSaga,
  };
}
