import {
  ATTEMPT_FETCH_TOKEN,
  EXPIRE_SESSION_ASYNC,
  FAIL_AUTHENTICATION,
  SUCCEED_FETCH_TOKEN,
  SUCCEED_REFRESH_TOKEN,
  FAIL_FETCH_TOKEN,
  SHOW_SESSION_EXPIRY_MODAL,
  CLOSE_FAIL_AUTHENTICATION,
} from "../../store/staticSagas/sagaToken";
import { getIn } from "../../utilities/objectUtils";

export const initialState = {
  claudToken: {
    access_token: null,
    refresh_token: null,
    acct_guid: null,
    usr_guid: null,
    error: false,
  },
  tokenContents: {},
  userContactDetails: {},
  launchDarklyApiKey: null,
  isLogout: false,
  showSessionExpiryModal: false,
  code: null,
  authenticationError: null,
  authenticationErrorMessage: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ATTEMPT_FETCH_TOKEN:
      return {
        ...state,
        claudToken: {
          access_token: null,
          refresh_token: null,
          acct_guid: null,
          usr_guid: null,
          error: false,
        },
        showSessionExpiryModal: false,
        code: null,
      };
    case SUCCEED_FETCH_TOKEN:
      return {
        ...state,
        claudToken: {
          access_token: action.data.claudToken.access_token,
          refresh_token: action.data.claudToken.refresh_token,
          error: false,
        },
        tokenContents: {
          ...action.data.tokenContents,
        },
        userContactDetails: action.data.claudToken.userContactDetails,
        launchDarklyApiKey: action.data.launchDarklyApiKey,
      };
    case SUCCEED_REFRESH_TOKEN:
      return {
        ...state,
        claudToken: {
          access_token: action.data.claudToken.access_token,
          refresh_token: action.data.claudToken.refresh_token,
        },
        tokenContents: {
          ...action.data.tokenContents,
        },
      };
    case FAIL_FETCH_TOKEN:
      return {
        ...state,
        claudToken: {
          access_token: null,
          refresh_token: null,
          acct_guid: null,
          usr_guid: null,
          error: true,
        },
        code: null,
      };
    case SHOW_SESSION_EXPIRY_MODAL:
      return {
        ...state,
        showSessionExpiryModal: true,
      };
    case EXPIRE_SESSION_ASYNC: {
      return {
        ...initialState,
        claudToken: initialState.claudToken,
        code: null,
      };
    }
    case FAIL_AUTHENTICATION:
      return {
        ...state,
        authenticationError: true,
        authenticationErrorMessage: action.payload,
      };
    case CLOSE_FAIL_AUTHENTICATION:
      return {
        ...state,
        authenticationError: false,
        authenticationErrorMessage: "",
      };
    case "persist/REHYDRATE":
      // clear error on refresh. but weird rehydrate errors come in all the time, so don't clear it on those
      return {
        ...state,
        ...getIn(action, "payload.tokenDetails"),
        authenticationError: state.authenticationError,
      };

    default:
      return state;
  }
}
