import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Breadcrumbs, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { RootState } from "../../store/staticReducers/reducers";
import { menus, Menu } from "../constants/ui";
import { updateSearchOption } from "../../store/action/actionUI";
import { resetPagination } from "../../store/action/actionAllocation";

type PanelExBreadCrumbsProps = {
  currentPage: string;
};

const PanelExBreadcrumbs: React.FC<PanelExBreadCrumbsProps> = ({
  currentPage,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const searchOption = useSelector((state: RootState) => state.ui.searchOption);
  const menu = menus[searchOption];

  const handleRedirectAction = (action: string, menu: Menu) => {
    if (action === "home") {
      dispatch(updateSearchOption(menu.value));
      dispatch(resetPagination());
      history.push("/panels");
    } else {
      history.push(menu.url);
    }
  };
  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        data-testid="breadcrumb"
        separator=">"
        sx={{ marginBottom: 0 }}
      >
        <Link
          component="button"
          data-testid="link-home"
          id="link-home"
          underline="none"
          onClick={() => {
            handleRedirectAction("home", menu);
          }}
        >
          <Typography color="inherit" variant="body3Strong">
            HOME
          </Typography>
        </Link>
        <Link
          component="button"
          data-testid="link-dataSet"
          id="link-dataSet"
          underline="none"
          onClick={() => {
            handleRedirectAction("list", menu);
          }}
        >
          <Typography color="inherit" variant="body3Strong">
            {menu.breadcrumb}
          </Typography>
        </Link>
        <Typography
          data-testid="currentPage"
          id="currentPage"
          color="inherit"
          variant="body3Strong"
        >
          {currentPage && currentPage.toUpperCase()}
        </Typography>
      </Breadcrumbs>
    </>
  );
};

export default PanelExBreadcrumbs;
