import React from "react";
import {
  Grid2 as Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PanelExAutocomplete from "../../shared/PanelExAutocomplete";
import { escapedSpecialCharacters } from "../../../utilities/stringUtil";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import {
  setAddValfirmAllocation,
  addValfirmTextChange,
  addValfirmSetValfirm,
} from "../../../store/action/actionPanel";
import ValfirmAdditionalStatusIcon from "../../shared/ValfirmAdditionalStatusIcon";

export default function AddValfirm() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    card: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    stack: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    formGroup: {
      fontSize: "18px",
      fontWeight: "500",
      paddingTop: "12px",
    },
    formRow: {
      paddingTop: "22px",
      "& .MuiTypography-root": {
        paddingBottom: "12px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
    allocationText: {
      width: "100%",
      marginBottom: "10px",

      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
        "& .MuiTypography-root": {
          padding: 0,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #00000061",
        borderRadius: 4,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          border: "1px solid #00000061",
        },
      },
    },
  }));
  const { classes } = useStyles();

  const { addValfirm, distributionMethod } = useSelector((state: RootState) => {
    return {
      addValfirm: state.panel.bulkUpdate.changeTypeAddValfirm,
      distributionMethod: state.panel.bulkUpdate.distributionMethod,
    };
  });

  const handleAddValfirmInputChange = (input: string) => {
    if (input !== addValfirm.valfirmText) {
      dispatch(addValfirmTextChange(input));
    }
  };

  return (
    <Grid
      container
      size={12}
      data-testid="add-valfirm-form"
      className={classes.card}
    >
      <Typography
        id="add-valfirm-form-title"
        data-testid="add-valfirm-form-title"
        variant="h4"
      >
        Add Valuation Firm
      </Typography>

      <Grid container size={12} className={classes.formGroup}>
        <Stack spacing={1} direction="row" className={classes.stack}>
          <Grid
            size={distributionMethod !== "evenly-all" ? 9 : 12}
            className={classes.formRow}
          >
            <Typography
              id="add-valfirm-label"
              data-testid="add-valfirm-label"
              variant="h4"
            >
              Valuation Firm
            </Typography>
            <PanelExAutocomplete
              id="add-valfirm"
              data-testid="add-valfirm"
              style={{ width: "100%", marginBottom: "10px" }}
              options={addValfirm.valfirmSuggestion}
              value={addValfirm.valfirmText}
              popperMaxHeight={400}
              getOptionLabel={(option: any) =>
                option.name || addValfirm.valfirmText || ""
              }
              placeholder="Enter Valuation Firm"
              onInputChange={(value: any) => {
                handleAddValfirmInputChange(value);
              }}
              renderOption={(props: any, option: any) => {
                const { id, name } = option;
                const regex = new RegExp(
                  escapedSpecialCharacters(addValfirm.valfirmText),
                  "gi"
                );
                const response = name.replace(regex, function (str: any) {
                  return "<span style='font-weight:500'>" + str + "</span>";
                });
                return (
                  <li
                    {...props}
                    data-testid={`add-valfirm-label-${id}`}
                    id={`add-valfirm-label-${id}`}
                    onClick={(ev) => {
                      dispatch(addValfirmSetValfirm(id, name));
                      ev.stopPropagation();
                    }}
                  >
                    <ValfirmAdditionalStatusIcon additionalStatus="NONE" />
                    <div
                      dangerouslySetInnerHTML={{ __html: `${response}` }}
                    ></div>
                  </li>
                );
              }}
            />
          </Grid>
          {distributionMethod !== "evenly-all" && (
            <Grid size={3} className={classes.formRow}>
              <Typography
                id="add-valfirm-allocation-label"
                data-testid="add-valfirm-allocation-label"
                variant="h4"
              >
                Allocation Percentage
              </Typography>
              <TextField
                id="add-valfirm-allocation"
                data-testid="add-valfirm-allocation"
                className={classes.allocationText}
                value={addValfirm.allocation}
                placeholder="Enter Percentage"
                onChange={(event: any) => {
                  const input = event.target.value;
                  const regex = /^[0-9\b]+$/;
                  if (
                    input === "" ||
                    (regex.test(input) && parseInt(input) < 101)
                  ) {
                    dispatch(setAddValfirmAllocation(input));
                  }
                }}
                disabled={distributionMethod === "evenly-all"}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  },
                }}
              />
            </Grid>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
