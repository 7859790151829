import {
  ATTEMPT_FETCH_USERS,
  SUCCEED_FETCH_USERS,
  FAIL_FETCH_USERS,
  SHOW_EDIT_USER,
  ATTEMPT_FETCH_USER_DETAIL,
  SUCCEED_FETCH_USER_DETAIL,
  FAIL_FETCH_USER_DETAIL,
  UPDATE_ROLE_EDIT_USER,
  UPDATE_FIRST_NAME_EDIT_USER,
  UPDATE_LAST_NAME_EDIT_USER,
  UPDATE_EMAIL_EDIT_USER,
  ADD_FUNDER_EDIT_USER,
  ADD_CLIENT_EDIT_USER,
  DELETE_FUNDER_EDIT_USER,
  DELETE_CLIENT_EDIT_USER,
  ATTEMPT_FETCH_ORG_SUGGESTION,
  SUCCEED_FETCH_ORG_SUGGESTION,
  FAIL_FETCH_ORG_SUGGESTION,
  ATTEMPT_UPDATE_USER,
  SUCCEED_UPDATE_USER,
  FAIL_UPDATE_USER,
  SHOW_SEARCH_NEW_USER,
  ATTEMPT_SEARCH_NEW_USER,
  SUCCEED_SEARCH_NEW_USER,
  FAIL_SEARCH_NEW_USER,
  SHOW_ADD_NEW_USER,
  ATTEMPT_ADD_NEW_USER,
  FAIL_ADD_NEW_USER,
  SUCCEED_ADD_NEW_USER,
  UPDATE_IS_NAB,
  SUCCEED_REACTIVATE_USER,
  SUCCEED_REMOVE_USER,
  UPDATE_IS_READONLY,
} from "../staticSagas/sagaUser";

const initialEditUser = {
  funderText: "",
  funderSuggestion: [],
  clientText: "",
  clientSuggestion: [],
  userId: 0,
  loading: false,
  error: "",
  userDetail: {
    appUserId: "",
    username: "",
    first: "",
    last: "",
    email: "",
    funderOrgDtoSet: [],
    clientOrgDtoSet: [],
    roleName: "",
    isUpdateUserDetails: false,
    isUpdateOrgs: false,
  },
};

const initialNewUser = {
  ...initialEditUser,
  searchLoading: false,
};
export const initialState = {
  loading: false,
  users: [],
  searchText: "",
  currentPage: 0,
  pageSize: 15,
  userCount: 0,
  suggestions: [],
  showSearchNewUser: false,
  showAddNewUser: false,
  newUser: initialNewUser,
  editUser: initialEditUser,
  showEditUser: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ATTEMPT_FETCH_USERS:
      return {
        ...state,
        loading: true,
        searchText: action.data.searchText,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
      };
    case SUCCEED_FETCH_USERS:
      return {
        ...state,
        users: action.data.users,
        userCount: action.data.totalCount,
        loading: false,
      };
    case FAIL_FETCH_USERS:
      return {
        ...state,
        users: [],
        userCount: 0,
        loading: false,
      };
    case SHOW_EDIT_USER:
      return {
        ...state,
        showEditUser: action.payload.showEditUser,
        userId: action.payload.userId,
        editUser: {
          ...state.editUser,
          userId: action.payload.userId,
          loading: false,
          error: "",
        },
      };
    case ATTEMPT_FETCH_USER_DETAIL:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          loading: true,
          funderText: "",
          clientText: "",
          funderSuggestion: [],
          clientSuggestion: [],
        },
      };
    case SUCCEED_FETCH_USER_DETAIL:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          userDetail: {
            ...action.data,
          },
          loading: false,
        },
      };
    case FAIL_FETCH_USER_DETAIL:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          loading: false,
        },
      };

    case UPDATE_ROLE_EDIT_USER: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let clientOrgDtoSet = user.userDetail.clientOrgDtoSet;
      let funderOrgDtoSet = user.userDetail.funderOrgDtoSet;
      let isUpdateOrgs = user.userDetail.isUpdateOrgs;
      let funderText = user.funderText;
      let funderSuggestion = user.funderSuggestion;
      let clientText = user.clientText;
      let clientSuggestion = user.clientSuggestion;
      if (action.payload.roleName === "Admin") {
        clientOrgDtoSet = [];
        funderOrgDtoSet = [];
        funderText = "";
        funderSuggestion = [];
        clientText = "";
        clientSuggestion = [];
        isUpdateOrgs = true;
      }
      let updatedUser = {
        ...user,
        funderText: funderText,
        funderSuggestion: funderSuggestion,
        clientText: clientText,
        clientSuggestion: clientSuggestion,
        userDetail: {
          ...user.userDetail,
          roleName: action.payload.roleName,
          funderOrgDtoSet: funderOrgDtoSet,
          clientOrgDtoSet: clientOrgDtoSet,
          isUpdateOrgs: isUpdateOrgs,
        },
      };

      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case UPDATE_FIRST_NAME_EDIT_USER: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          first: action.payload.firstName,
          isUpdateUserDetails: true,
        },
      };

      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case UPDATE_LAST_NAME_EDIT_USER: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          last: action.payload.lastName,
          isUpdateUserDetails: true,
        },
      };

      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case UPDATE_EMAIL_EDIT_USER: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          email: action.payload.email,
          isUpdateUserDetails: true,
        },
      };

      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case UPDATE_IS_NAB: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          isNab: action.payload.isNab,
          isUpdateUserDetails: true,
        },
      };
      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case UPDATE_IS_READONLY: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          isReadOnly: action.payload.isReadOnly,
          isUpdateUserDetails: true,
        },
      };
      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case ADD_FUNDER_EDIT_USER: {
      let user = action.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          funderOrgDtoSet: [...user.userDetail.funderOrgDtoSet, action.payload],
          isUpdateOrgs: true,
        },
      };

      return {
        ...state,
        editUser: action.isEdit ? updatedUser : state.editUser,
        newUser: action.isEdit ? state.newUser : updatedUser,
      };
    }
    case DELETE_FUNDER_EDIT_USER: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          funderOrgDtoSet: user.userDetail.funderOrgDtoSet.filter(
            (firm) => firm.orgId !== action.payload.orgId
          ),
          isUpdateOrgs: true,
        },
      };

      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case ADD_CLIENT_EDIT_USER: {
      let user = action.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          clientOrgDtoSet: [...user.userDetail.clientOrgDtoSet, action.payload],
          isUpdateOrgs: true,
        },
      };

      return {
        ...state,
        editUser: action.isEdit ? updatedUser : state.editUser,
        newUser: action.isEdit ? state.newUser : updatedUser,
      };
    }
    case DELETE_CLIENT_EDIT_USER: {
      let user = action.payload.isEdit ? state.editUser : state.newUser;
      let updatedUser = {
        ...user,
        userDetail: {
          ...user.userDetail,
          clientOrgDtoSet: user.userDetail.clientOrgDtoSet.filter(
            (firm) => firm.orgId !== action.payload.orgId
          ),
          isUpdateOrgs: true,
        },
      };

      return {
        ...state,
        editUser: action.payload.isEdit ? updatedUser : state.editUser,
        newUser: action.payload.isEdit ? state.newUser : updatedUser,
      };
    }
    case ATTEMPT_FETCH_ORG_SUGGESTION:
      if (action.orgType === "Funder") {
        return {
          ...state,
          editUser: action.isEdit
            ? {
                ...state.editUser,
                funderText: action.text,
              }
            : state.editUser,
          newUser: action.isEdit
            ? state.newUser
            : {
                ...state.newUser,
                funderText: action.text,
              },
        };
      } else if (action.orgType === "Client") {
        return {
          ...state,
          editUser: action.isEdit
            ? {
                ...state.editUser,
                clientText: action.text,
              }
            : state.editUser,
          newUser: action.isEdit
            ? state.newUser
            : {
                ...state.newUser,
                clientText: action.text,
              },
        };
      } else {
        return state;
      }
    case SUCCEED_FETCH_ORG_SUGGESTION:
      if (action.orgType === "Funder") {
        return {
          ...state,
          editUser: action.isEdit
            ? {
                ...state.editUser,
                funderSuggestion: action.data,
              }
            : state.editUser,
          newUser: action.isEdit
            ? state.newUser
            : {
                ...state.newUser,
                funderSuggestion: action.data,
              },
        };
      } else if (action.orgType === "Client") {
        return {
          ...state,
          editUser: action.isEdit
            ? {
                ...state.editUser,
                clientSuggestion: action.data,
              }
            : state.editUser,
          newUser: action.isEdit
            ? state.newUser
            : {
                ...state.newUser,
                clientSuggestion: action.data,
              },
        };
      } else {
        return state;
      }
    case FAIL_FETCH_ORG_SUGGESTION:
      if (action.orgType === "Funder") {
        return {
          ...state,
          editUser: action.isEdit
            ? {
                ...state.editUser,
                funderSuggestion: [],
              }
            : state.editUser,
          newUser: action.isEdit
            ? state.newUser
            : {
                ...state.newUser,
                funderSuggestion: [],
              },
        };
      } else if (action.orgType === "Client") {
        return {
          ...state,
          editUser: action.isEdit
            ? {
                ...state.editUser,
                clientSuggestion: [],
              }
            : state.editUser,
          newUser: action.isEdit
            ? state.newUser
            : {
                ...state.newUser,
                clientSuggestion: [],
              },
        };
      } else {
        return state;
      }
    case ATTEMPT_UPDATE_USER:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          error: "",
        },
      };
    case SUCCEED_UPDATE_USER: {
      let organisations = "";
      action.payload.funderOrgDtoSet.forEach(function (org) {
        organisations =
          organisations +
          (organisations.length > 0 ? "," + org.orgName : org.orgName);
      });
      action.payload.clientOrgDtoSet.forEach(function (org) {
        organisations =
          organisations +
          (organisations.length > 0 ? "," + org.orgName : org.orgName);
      });
      let editUser = {
        userId: action.payload.userId,
        first: action.payload.first,
        last: action.payload.last,
        email: action.payload.email,
        username: action.payload.username,
        organisations: organisations,
        roles: action.payload.roleName,
        isNab: action.payload.isNab,
        isReadOnly: action.payload.isReadOnly,
        isActive: action.payload.isActive,
      };
      let users = state.users.map((u) =>
        u.userId !== action.payload.userId ? u : editUser
      );
      return {
        ...state,
        editUser: {
          ...state.editUser,
          userDetail: {
            ...action.payload,
            loading: false,
          },
        },
        users: users,
        showEditUser: false,
      };
    }
    case FAIL_UPDATE_USER:
      return {
        ...state,
        editUser: {
          ...state.editUser,
          error: action.error,
        },
      };
    case SHOW_SEARCH_NEW_USER:
      return {
        ...state,
        showSearchNewUser: action.show,
        newUser: initialNewUser,
      };
    case ATTEMPT_SEARCH_NEW_USER:
      return {
        ...state,
        newUser: {
          ...initialNewUser,
          userDetail: {
            ...initialNewUser.userDetail,
            email: action.email,
          },
          searchLoading: true,
        },
        showAddNewUser: false,
      };
    case SUCCEED_SEARCH_NEW_USER:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          userDetail: {
            ...action.data,
            funderOrgDtoSet: [],
            clientOrgDtoSet: [],
          },
          email: action.data.email,
          searchLoading: false,
        },
        showSearchNewUser: action.data.isPanelUser,
        showAddNewUser: !action.data.isPanelUser,
      };
    case FAIL_SEARCH_NEW_USER:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          searchLoading: false,
        },
        showSearchNewUser: false,
        showAddNewUser: false,
      };
    case SHOW_ADD_NEW_USER:
      return {
        ...state,
        showAddNewUser: action.show,
        showSearchNewUser: false,
        showEditUser: false,
      };
    case ATTEMPT_ADD_NEW_USER:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          userDetail: {
            ...state.newUser.userDetail,
            loading: true,
          },
        },
      };
    case SUCCEED_ADD_NEW_USER:
      let organisations = "";
      action.payload.funderOrgDtoSet.forEach(function (org) {
        organisations =
          organisations +
          (organisations.length > 0 ? "," + org.orgName : org.orgName);
      });
      action.payload.clientOrgDtoSet.forEach(function (org) {
        organisations =
          organisations +
          (organisations.length > 0 ? "," + org.orgName : org.orgName);
      });

      let newUser = {
        userId: action.payload.userId,
        first: action.payload.first,
        last: action.payload.last,
        email: action.payload.email,
        username: action.payload.username,
        organisations: organisations,
        roles: action.payload.roleName,
        isNab: action.payload.isNab,
        isReadOnly: action.payload.isReadOnly,
        isActive: action.payload.isActive,
      };

      return {
        ...state,
        newUser: {
          ...state.newUser,
          userDetail: {
            ...action.payload,
            loading: false,
          },
        },
        users: [newUser, ...state.users],
        userCount: state.userCount + 1,
        showAddNewUser: false,
      };
    case FAIL_ADD_NEW_USER:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          error: action.error,
        },
      };
    case SUCCEED_REMOVE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.userId === action.payload.userId
            ? { ...user, isActive: false }
            : user
        ),
      };
    case SUCCEED_REACTIVATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.userId === action.payload.userId
            ? { ...user, isActive: true }
            : user
        ),
      };
    case "persist/REHYDRATE":
      // clear error on refresh. but weird rehydrate errors come in all the time, so don't clear it on those
      return {
        ...state,
      };
    default:
      return state;
  }
}
