import React from "react";
import {
  Grid2 as Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import PanelExAutocomplete from "../../shared/PanelExAutocomplete";
import { escapedSpecialCharacters } from "../../../utilities/stringUtil";
import {
  changeAllocationSetAllocation,
  changeAllocationSetBy,
  changeAllocationSetValfirm,
  changeAllocationValfirmTextChange,
} from "../../../store/action/actionPanel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import Select from "@mui/material/Select";
import ValfirmAdditionalStatusIcon from "../../shared/ValfirmAdditionalStatusIcon";

export default function ChangeAllocation() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    card: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    stack: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    formGroup: {
      fontSize: "18px",
      fontWeight: "500",
      paddingTop: "12px",
    },
    formRow: {
      paddingTop: "22px",
      "& .MuiTypography-root": {
        paddingBottom: "12px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
    allocationText: {
      width: "100%",
      marginBottom: "10px",

      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
        "& .MuiTypography-root": {
          padding: 0,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #00000061",
        borderRadius: 4,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          border: "1px solid #00000061",
        },
      },
    },
    select: {
      width: "100%",
      height: "38px",
      marginRight: "4px",
      border: "1px solid #00000061",
      borderRadius: "4px",
      marginBottom: "10px",

      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
    },
    notTrading: {
      color: theme.palette.text.disabled,
    },
    atCapacity: {
      color: theme.colors.error.darker,
    },
  }));
  const { classes } = useStyles();

  const { changeAllocation } = useSelector((state: RootState) => {
    return {
      changeAllocation: state.panel.bulkUpdate.changeTypeChangeAlloc,
    };
  });

  const getStatusStyle = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return classes.notTrading;
      case "ATCAPACITY":
        return classes.atCapacity;
    }
  };

  return (
    <Grid
      container
      size={12}
      data-testid="change-allocation-form"
      className={classes.card}
    >
      <Typography
        id="change-allocation-form-title"
        data-testid="change-allocation-form-title"
        variant="h4"
      >
        Change Allocation %
      </Typography>

      <Grid container size={12} className={classes.formGroup}>
        <Stack spacing={1} direction="row" className={classes.stack}>
          <Grid size={6} className={classes.formRow}>
            <Typography
              id="change-allocation-valfirm-label"
              data-testid="change-allocation-valfirm-label"
              variant="h4"
            >
              On Valuation Firm
            </Typography>
            <PanelExAutocomplete
              id="change-allocation-valfirm"
              data-testid="change-allocation-valfirm"
              style={{ width: "100%", marginBottom: "10px" }}
              options={changeAllocation.valfirmSuggestion}
              value={changeAllocation.valfirmText}
              popperMaxHeight={400}
              getOptionLabel={(option: any) =>
                option.name || changeAllocation.valfirmText || ""
              }
              placeholder="Enter Valuation Firm"
              onInputChange={(value: any) => {
                if (value !== changeAllocation.valfirmText) {
                  dispatch(changeAllocationValfirmTextChange(value));
                }
              }}
              renderOption={(props: any, option: any) => {
                const { id, name, additionalStatus } = option;
                const regex = new RegExp(
                  escapedSpecialCharacters(changeAllocation.valfirmText),
                  "gi"
                );
                const response = name.replace(regex, function (str: any) {
                  return "<span style='font-weight:500'>" + str + "</span>";
                });
                return (
                  <li
                    {...props}
                    data-testid={`change-allocation-valfirm-label-${id}`}
                    id={`change-allocation-label-valfirm-${id}`}
                    onClick={(ev) => {
                      dispatch(changeAllocationSetValfirm(id, name));
                      ev.stopPropagation();
                    }}
                  >
                    <ValfirmAdditionalStatusIcon
                      additionalStatus={additionalStatus}
                    />
                    <div
                      className={getStatusStyle(additionalStatus)}
                      dangerouslySetInnerHTML={{ __html: `${response}` }}
                    ></div>
                  </li>
                );
              }}
            />
          </Grid>
          <Grid size={3} className={classes.formRow}>
            <Typography
              id="change-allocation-by-label"
              data-testid="change-allocation-by-label"
              variant="h4"
            >
              By
            </Typography>
            <Select
              id="change-allocation-by"
              data-testid="change-allocation-by"
              value={changeAllocation.changeAllocationBy}
              className={classes.select}
              onChange={(event: any) => {
                dispatch(changeAllocationSetBy(event.target.value as string));
              }}
            >
              <MenuItem value={"increaseBy"}>Increase By</MenuItem>
              <MenuItem value={"reduceBy"}>Reduce By</MenuItem>
              <MenuItem value={"setTo"}>Set to</MenuItem>
            </Select>
          </Grid>
          <Grid size={3} className={classes.formRow}>
            <Typography
              id="change-allocation-allocation-label"
              data-testid="change-allocation-allocation-label"
              variant="h4"
            >
              Allocation Percentage
            </Typography>
            <TextField
              id="change-allocation-allocation"
              data-testid="change-allocation-allocation"
              className={classes.allocationText}
              value={changeAllocation.allocation}
              placeholder="Enter Percentage"
              onChange={(event: any) => {
                const input = event.target.value;
                const regex = /^[0-9\b]+$/;
                if (
                  input === "" ||
                  (regex.test(input) && parseInt(input) < 101)
                ) {
                  dispatch(changeAllocationSetAllocation(input));
                }
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}
