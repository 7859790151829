import React, { useEffect, useState } from "react";
import { Grid2 as Grid } from "@mui/material";
import Search from "../header/Search";
import { makeStyles } from "tss-react/mui";
import landing from "../../img/landing.jpg";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";

export default function PanelExLanding() {
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    landingContainer: {
      background:
        "linear-gradient(180deg, #4A7DA2 0%, #98B5C7 9.33%, #669BB9 11.33%, #3B84AD 22.33%, #215E83 55.83%, #002E4E 74.33%, #092D39 100%)",
    },
    landing: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${landing})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      height: "488px",
      width: "100%",
    },
    searchMessage: {
      fontWeight: 500,
      fontSize: "40px",
      lineHeight: "40px",
      letterSpacing: "-0.2px",
      color: theme.palette.common.white,
    },
  }));

  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const listenToScroll = () => {
    const heightToHideFrom = 220;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  const { isOpenFilter } = useSelector((state: RootState) => {
    return {
      isOpenFilter:
        state.fee.filterOptions.isOpenFilter ||
        state.allocation.filterOptions.isOpenFilter ||
        state.panel.filterOptions.isOpenFilter ||
        false,
    };
  });
  const { classes } = useStyles();
  return (
    <Grid container size={12} className={classes.landingContainer}>
      <Grid container size={12} className={classes.landing}>
        <Grid
          size={12}
          sx={{ height: "200px", display: "flex" }}
          justifyContent={"center"}
          alignItems={"flex-end"}
        >
          <span
            id="search-message"
            data-testid="search-message"
            className={classes.searchMessage}
          >
            Start your search here
          </span>
        </Grid>
        <Grid
          size={12}
          sx={{ height: "200px", display: "flex" }}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          {isVisible && !isOpenFilter && <Search />}
        </Grid>
      </Grid>
    </Grid>
  );
}
