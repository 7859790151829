import React, { useEffect } from "react";
import { Grid2 as Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getLatestPanelActivationLog } from "../../../store/action/actionPanel";

export default function Settings() {
  const dispatch = useDispatch();
  const useStyles = makeStyles()(() => ({
    gridContainer: {
      display: "flex",
      flexDirection: "row",
      height: "146px",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    customGridContainer: {
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",
      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    stack: {
      width: "100%",
    },
    infoIcon: {
      display: "flex",
      width: "18px",
      height: "18px",
      marginLeft: "4px",
    },
    setTabs: {
      alignItems: "center",
      padding: "4px 6px",
      background: theme.colors.grey.lightest,
      height: "44px",
      borderRadius: "4px",

      "& .MuiTabs-indicator": {
        height: "0px",
      },
      "& .MuiTabs-flexContainer": {
        padding: "0px 6px",
        justifyContent: "space-between",
      },
      "& .MuiTab-root": {
        color: theme.colors.grey.dark,
        padding: "7px 6px",
        height: "30px",
        minHeight: "30px",
        borderRadius: "4px",

        "&.Mui-selected": {
          background: theme.palette.common.white,
          color: theme.palette.tertiary.main,
        },
      },
    },
  }));
  const theme = useTheme();
  const { classes } = useStyles();

  const createItems = (
    type: string,
    id: any,
    displayText: string,
    index: number
  ) => {
    return (
      <Grid
        size={12}
        id={`header-${type}-${index}`}
        key={`header-key-${type}-${index}`}
      >
        <Typography
          id={`${type}-${index}`}
          data-testid={`${type}-${index}`}
          variant="h6"
          fontWeight={"normal"}
          color={theme.palette.text.primary}
        >
          {displayText}
        </Typography>
      </Grid>
    );
  };

  const { panelSet, panelVersionDetail } = useSelector((state: RootState) => {
    return {
      panelSet: state.panel.panelSet,
      panelVersionDetail: state.panel.panelVersionDetail,
      panelVersionId: state.panel.panelVersionId,
    };
  });

  const panelSetId = panelSet?.panelSetId;

  useEffect(() => {
    if (panelSetId) {
      dispatch(getLatestPanelActivationLog(panelSetId));
    }
  }, [dispatch, panelSetId, panelVersionDetail]);

  interface PropertyType {
    propertyTypeId: number;
    propertyTypeName: string;
  }

  interface ServiceType {
    serviceId: number;
    serviceName: string;
  }

  interface Org {
    orgId: number;
    orgName: string;
  }

  let funders;
  let halfLengthFunders = 0;

  if (panelVersionDetail.panelSetFunderDtoSet !== undefined) {
    funders = panelVersionDetail.panelSetFunderDtoSet.slice(0);
    funders.sort((a: Org, b: Org) =>
      a.orgName.toLowerCase().localeCompare(b.orgName.toLowerCase())
    );
    halfLengthFunders = Math.ceil(funders.length / 2);
    if (funders.length <= 5) {
      halfLengthFunders = funders.length;
    }
  }
  let clients;
  let halfLengthClients = 0;
  if (panelVersionDetail.panelSetClientDtoSet !== undefined) {
    clients = panelVersionDetail.panelSetClientDtoSet.slice(0);
    clients.sort((a: Org, b: Org) =>
      a.orgName.toLowerCase().localeCompare(b.orgName.toLowerCase())
    );
    halfLengthClients = Math.ceil(clients.length / 2);
    if (clients.length <= 5) {
      halfLengthClients = clients.length;
    }
  }
  let propertyTypes;
  let halfLengthPropertyTypes = 0;
  if (panelVersionDetail.panelSetPropertyTypeDto !== undefined) {
    propertyTypes = panelVersionDetail.panelSetPropertyTypeDto.slice(0);
    propertyTypes.sort((a: PropertyType, b: PropertyType) =>
      a.propertyTypeName
        .toLowerCase()
        .localeCompare(b.propertyTypeName.toLowerCase())
    );
    halfLengthPropertyTypes = Math.ceil(propertyTypes.length / 2);
    if (propertyTypes.length <= 5) {
      halfLengthPropertyTypes = propertyTypes.length;
    }
  }
  let serviceTypes;
  let halfLengthServiceTypes = 0;
  if (panelVersionDetail.panelSetServiceDtoSet !== undefined) {
    serviceTypes = panelVersionDetail.panelSetServiceDtoSet.slice(0);
    serviceTypes.sort((a: ServiceType, b: ServiceType) =>
      a.serviceName.toLowerCase().localeCompare(b.serviceName.toLowerCase())
    );
    halfLengthServiceTypes = Math.ceil(serviceTypes.length / 2);
    if (serviceTypes.length <= 5) {
      halfLengthServiceTypes = serviceTypes.length;
    }
  }

  return (
    <Grid container spacing={2} sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Grid size={6}>
        <Typography id="panel-name" data-testid="panel-name" variant="h4">
          {panelVersionDetail.name}
        </Typography>
      </Grid>
      <Grid size={6}>
        <Typography id="panel-notes" data-testid="panel-notes" variant="body3">
          Notes: {panelVersionDetail.notes}
        </Typography>
      </Grid>
      <Grid size={6}>
        <Grid container size={12} className={classes.gridContainer}>
          <Grid container size={12} sx={{ paddingBottom: "10px" }}>
            <Grid size={6}>
              <Grid container size={12}>
                <Grid>
                  <Typography
                    id="panel-type"
                    data-testid="panel-type"
                    variant="h4"
                  >
                    {panelVersionDetail.panelType === "Quote"
                      ? "Quote Panel"
                      : "Fee Based Panel"}
                  </Typography>
                </Grid>
                <Grid>
                  <Tooltip
                    id="tooltip-panel-type"
                    data-testid="tooltip-panel-type"
                    title="Fee Based Panel utilises configured fees for each location. Quote Panel has no set fees. It requests a quote from valuation firms on the panel."
                  >
                    <InfoOutlinedIcon
                      id="tooltip-icon-panel-type"
                      data-testid="tooltip-icon-panel-type"
                      className={classes.infoIcon}
                      sx={{ color: theme.colors.info.dark }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid size={12}>
                <Typography
                  id="label-panel-type"
                  data-testid="label-panel-type"
                  variant="body3"
                >
                  Panel Type
                </Typography>
              </Grid>
            </Grid>
            <Grid size={6}>
              <Grid container size={12}>
                <Grid>
                  <Typography
                    id="panel-order"
                    data-testid="panel-order"
                    variant="h4"
                  >
                    {panelVersionDetail.order}
                  </Typography>
                </Grid>
                <Grid>
                  <Tooltip
                    id="tooltip-panel-order"
                    data-testid="tooltip-panel-order"
                    title="Panel Priority - A lower number signifies a higher priority panel"
                  >
                    <InfoOutlinedIcon
                      id="tooltip-icon-panel-order"
                      data-testid="tooltip-icon-panel-order"
                      className={classes.infoIcon}
                      sx={{ color: theme.colors.info.dark }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid>
                <Typography
                  id="label-panel-order"
                  data-testid="label-panel-order"
                  variant="body3"
                >
                  Panel Order
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container size={12}>
            <Grid size={6}>
              <Grid container size={12}>
                <Grid>
                  <Typography
                    id="panel-in-use"
                    data-testid="panel-in-use"
                    variant="h4"
                  >
                    {panelVersionDetail.statusBool ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Typography
                  id="label-panel-in-use"
                  data-testid="label-panel-in-use"
                  variant="body3"
                >
                  Panel In Use
                </Typography>
              </Grid>
            </Grid>
            <Grid size={6}>
              <Grid container size={12}>
                <Grid>
                  <Typography
                    id="use-rulex"
                    data-testid="use-rulex"
                    variant="h4"
                  >
                    {panelVersionDetail.useRuleEx ? "Yes" : "No"} RulEx
                  </Typography>
                </Grid>
                <Grid>
                  <Tooltip
                    id="tooltip-use-rulex"
                    data-testid="tooltip-use-rulex"
                    title="RulEx is an allocation methodology based on an external reference file, using it will ignore any linked Allocation."
                  >
                    <InfoOutlinedIcon
                      id="tooltip-icon-use-rulex"
                      data-testid="tooltip-icon-use-rulex"
                      className={classes.infoIcon}
                      sx={{ color: theme.colors.grey.dark }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid>
                <Typography
                  id="label-use-rulex"
                  data-testid="label-use-rulex"
                  variant="body3"
                >
                  RulEx Usage
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={6}>
        <Grid container size={12} className={classes.gridContainer}>
          <Stack spacing={1} direction="row" className={classes.stack}>
            <Grid size={12}>
              <Grid container size={12}>
                <Grid>
                  <Typography
                    id="bracket-from-to"
                    data-testid="bracket-from-to"
                    variant="h4"
                  >
                    From ${" "}
                    <NumericFormat
                      displayType="text"
                      value={panelVersionDetail.bracketMin}
                      thousandSeparator
                    />{" "}
                    To ${" "}
                    <NumericFormat
                      displayType="text"
                      value={panelVersionDetail.bracketMax}
                      thousandSeparator
                    />
                  </Typography>
                </Grid>
                <Grid>
                  <Tooltip
                    id="tooltip-bracket"
                    data-testid="tooltip-bracket"
                    title="Estimated Property Value Range defines the property value for which this panel is to be applicable"
                  >
                    <InfoOutlinedIcon
                      id="tooltip-icon-bracket"
                      data-testid="tooltip-icon-bracket"
                      className={classes.infoIcon}
                      sx={{ color: theme.colors.info.dark }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid>
                <Typography
                  id="label-bracket"
                  data-testid="label-bracket"
                  variant="body3"
                >
                  Estimated Property Value Range
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        container
        size={12}
        spacing={2}
        style={{ alignItems: "flex-start", justifyContent: "space-between" }}
      >
        <Grid size={12}>
          <Grid container size={12} className={classes.customGridContainer}>
            <Grid
              container
              size={12}
              spacing={1}
              style={{ flexDirection: "row", alignItems: "flex-start" }}
            >
              <Grid
                size={6}
                id="section-funder-type-1"
                data-testid="section-funder-type-1"
              >
                {funders !== undefined
                  ? funders
                      .slice(0, halfLengthFunders)
                      .map((type: any, index: number) =>
                        createItems(
                          "funder-type",
                          type.orgId,
                          index + 1 != halfLengthFunders
                            ? type.orgName + ","
                            : type.orgName,
                          index
                        )
                      )
                  : ""}
              </Grid>
              <Grid size={6} data-testid="section-funder-type-2">
                {funders !== undefined
                  ? funders
                      .slice(halfLengthFunders, funders.length)
                      .map((type: any, index: number) =>
                        createItems(
                          "funder-type",
                          type.orgId,
                          halfLengthFunders + index + 1 != funders.length
                            ? type.orgName + ","
                            : type.orgName,
                          index
                        )
                      )
                  : ""}
              </Grid>
            </Grid>
            <Grid size={12}>
              <Typography
                id="label-property-type"
                data-testid="label-funder-type"
                variant={"body3"}
                color={theme.palette.text.secondary}
              >
                Funders
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Grid container size={12} className={classes.customGridContainer}>
            <Grid
              container
              size={12}
              spacing={1}
              style={{ flexDirection: "row", alignItems: "flex-start" }}
            >
              <Grid size={6} data-testid="section-client-type-1">
                {clients !== undefined
                  ? clients
                      .slice(0, halfLengthClients)
                      .map((type: any, index: number) =>
                        createItems(
                          "client-type",
                          type.orgId,
                          index + 1 != halfLengthClients
                            ? type.orgName + ","
                            : type.orgName,
                          index
                        )
                      )
                  : ""}
              </Grid>
              <Grid size={6} data-testid="section-client-type-2">
                {clients !== undefined
                  ? clients
                      .slice(halfLengthClients, clients.length)
                      .map((type: any, index: number) =>
                        createItems(
                          "client-type",
                          type.orgId,
                          halfLengthClients + index + 1 != clients.length
                            ? type.orgName + ","
                            : type.orgName,
                          index
                        )
                      )
                  : ""}
              </Grid>
            </Grid>
            <Grid size={12}>
              <Typography
                id="label-property-type"
                data-testid="label-client-type"
                variant={"body3"}
                color={theme.palette.text.secondary}
              >
                Clients
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid size={12}>
          <Grid container size={12} className={classes.customGridContainer}>
            <Grid
              container
              size={12}
              spacing={1}
              style={{ flexDirection: "row", alignItems: "flex-start" }}
            >
              <Grid size={6} data-testid="section-property-type-1">
                {propertyTypes !== undefined
                  ? propertyTypes
                      .slice(0, halfLengthPropertyTypes)
                      .map((type: any, index: number) =>
                        createItems(
                          "property-type",
                          type.propertyTypeId,
                          index + 1 != halfLengthPropertyTypes
                            ? type.propertyTypeName + ","
                            : type.propertyTypeName,
                          index
                        )
                      )
                  : ""}
              </Grid>
              <Grid size={6} data-testid="section-property-type-2">
                {propertyTypes !== undefined
                  ? propertyTypes
                      .slice(halfLengthPropertyTypes, propertyTypes.length)
                      .map((type: any, index: number) =>
                        createItems(
                          "property-type",
                          type.propertyTypeId,
                          halfLengthPropertyTypes + index + 1 !=
                            propertyTypes.length
                            ? type.propertyTypeName + ","
                            : type.propertyTypeName,
                          index
                        )
                      )
                  : ""}
              </Grid>
            </Grid>
            <Grid size={12}>
              <Typography
                id="label-property-type"
                data-testid="label-property-type"
                variant={"body3"}
                color={theme.palette.text.secondary}
              >
                Property Type
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid size={12}>
          <Grid container size={12} className={classes.customGridContainer}>
            <Grid
              container
              size={12}
              spacing={1}
              style={{ flexDirection: "row", alignItems: "flex-start" }}
            >
              <Grid size={6} data-testid="section-service-type-1">
                {serviceTypes !== undefined
                  ? serviceTypes
                      .slice(0, halfLengthServiceTypes)
                      .map((type: any, index: number) =>
                        createItems(
                          "service-type",
                          type.serviceId,
                          index + 1 != halfLengthServiceTypes
                            ? type.serviceName + ","
                            : type.serviceName,
                          index
                        )
                      )
                  : ""}
              </Grid>
              <Grid size={6} data-testid="section-service-type-2">
                {serviceTypes !== undefined
                  ? serviceTypes
                      .slice(halfLengthServiceTypes, serviceTypes.length)
                      .map((type: any, index: number) =>
                        createItems(
                          "service-type",
                          type.serviceId,
                          halfLengthServiceTypes + index + 1 !=
                            serviceTypes.length
                            ? type.serviceName + ","
                            : type.serviceName,
                          index
                        )
                      )
                  : ""}
              </Grid>
            </Grid>
            <Grid size={12}>
              <Typography
                id="label-service-type"
                data-testid="label-service-type"
                variant={"body3"}
                color={theme.palette.text.secondary}
              >
                Service Type
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
