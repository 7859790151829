import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import copyEditOutlined from "../../../img/copyEditOutlined.svg";
import { RemoveRedEye } from "@mui/icons-material";
import moment from "moment-timezone";
import {
  getPanelVersions,
  updatePanelVersionNotes,
  changePanelTab,
  changePanelVersionId,
  getLatestPanelActivationLog,
  copyPanelVersion,
  schedulePanelVersionActivation,
  discardPanelVersion,
  setVersionsToCompare,
} from "../../../store/action/actionPanel";
import { makeStyles } from "tss-react/mui";
import {
  Button,
  TextField,
  IconButton,
  Grid2 as Grid,
  Checkbox,
  Tooltip,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PanelExDialog from "../../shared/PanelExDialog";
import { useTheme } from "@mui/material/styles";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import DialogContentText from "@mui/material/DialogContentText";
import PanelVersionComparison from "../PanelVersionComparison";
import PanelExActionSnackbar from "../../shared/PanelExActionSnackbar";

interface PanelVersionsProps {
  panelSetVersionId: number;
}

export default function PanelVersions(props: PanelVersionsProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const timezone = moment.tz.guess();
  const timezoneAbbr = moment().tz(timezone).format("z");
  const panelVersionId = props.panelSetVersionId;

  const [openNotes, setOpenNotes] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [versionNote, setVersionNotes] = useState("");
  const [versionStatus, setVersionStatus] = useState("");
  const [openActivation, setOpenActivation] = useState(false);
  const [activateDate, setActivateDate] = React.useState<Dayjs | undefined>(
    dayjs()
  );
  const [minDateTime, setMinDateTime] = React.useState<Dayjs | undefined>(
    dayjs().set("second", 0).set("millisecond", 0)
  );
  const [openDiscard, setOpenDiscard] = useState(false);

  const useStyles = makeStyles()(() => ({
    versionsHeader: {
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.15px",
        color: "rgba(0,0,0,0.8)",
      },
    },
    versionId: {
      color: theme.palette.primary.main,
    },
  }));
  const { classes } = useStyles();

  const getVersionStatus = (
    panelStatus: boolean,
    panelSetRevId: number,
    editable: boolean,
    activateStatus?: string
  ) => {
    let status = "Archived";
    if (panelSetRevId === panelRevId) {
      status = "Active";
    } else if (editable) {
      if (activateStatus === "Queued" && panelSetRevId === activateRevId) {
        status = "Scheduled";
      } else if (
        activateStatus === "Error" &&
        activateRevId === panelSetRevId
      ) {
        status = "Failed";
      } else {
        status = "Draft";
      }
    }
    return status;
  };

  const {
    panelSet,
    panelVersions,
    versionLoading,
    rowCount,
    currentPage,
    pageSize,
    requestedActivationLog,
    versionCompare,
    showVersionCompare,
    roleName,
    readOnly,
  } = useSelector((state: RootState) => {
    return {
      panelSet: state.panel.panelSet,
      panelVersions: state.panel.panelVersion.panelVersions,
      versionLoading: state.panel.panelVersion.loading,
      rowCount: state.panel.panelVersion.panelVersionCount || 0,
      currentPage: state.panel.panelVersion.currentPage || 0,
      pageSize: state.panel.panelVersion.pageSize,
      requestedActivationLog: state.panel.latestPanelActivationLog,
      versionCompare: state.panel.panelVersion.versionCompare,
      showVersionCompare: state.panel.panelVersion.showVersionCompare || false,
      roleName: state.tokenDetails.userContactDetails.roleName,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const {
    scheduled: activateVersionDate,
    status: activateStatus,
    panelSetRevId: activateRevId,
  } = requestedActivationLog;

  const panelSetId = panelSet?.panelSetId;
  const panelRevId = panelSet?.panelSetRevId;
  const hasEditable = !!panelSet.editableRevId;

  useEffect(() => {
    fetchPanelVersions(panelSetId, currentPage, pageSize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (panelSetId) {
      dispatch(getLatestPanelActivationLog(panelSetId));
    }
  }, [dispatch, panelSetId]);

  const columns: GridColDef[] = [
    {
      field: "compare",
      headerName: "",
      width: 30,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const panelSetRevId = params.row.panelSetRevId;
        const index =
          params.api.getRowIndexRelativeToVisibleRows(panelSetRevId);
        const compareSelected =
          versionCompare.filter((v: any) => v === panelSetRevId).length > 0;
        return (
          <Checkbox
            id={`checkbox-compare-${index}`}
            data-testid={`checkbox-compare-${index}`}
            checked={compareSelected}
            disabled={versionCompare.length === 2 && !compareSelected}
            onChange={(ev) =>
              handleCompare(ev, panelSetRevId, versionCompare.length)
            }
          />
        );
      },
    },
    {
      field: "panelSetRevId",
      headerName: "ID",
      width: 95,
      editable: false,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={`Load to preview v${params.row.panelSetRevId}`}
              arrow
            >
              <span
                data-testid={`id-${params.row.panelSetRevId}`}
                id={`id-${params.row.panelSetRevId}`}
                className={classes.versionId}
                onClick={() => {
                  dispatch(changePanelVersionId(params.row.panelSetRevId));
                  dispatch(changePanelTab(0));
                }}
              >
                {params.row.panelSetRevId}
              </span>
            </Tooltip>
            {params.row.panelSetRevId === panelVersionId && (
              <RemoveRedEye
                data-testid="icon-selected-id"
                id="icon-selected-id"
                sx={{ paddingLeft: "8px", color: theme.palette.primary.main }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "active",
      headerName: "Status",
      width: 128,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const status = getVersionStatus(
          params.row.active,
          params.row.panelSetRevId,
          params.row.editable,
          activateStatus
        );
        const timezone = moment.tz.guess();
        const scheduled = moment(activateVersionDate)
          .tz(timezone)
          .format("DD-MM-YYYY hh:mm:ss A");
        return (
          <span style={{ display: "flex" }}>
            <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
              <li style={{ textAlign: "center" }}>{status}</li>
              {status === "Scheduled" && (
                <li style={{ textAlign: "center", fontSize: "12px" }}>
                  {scheduled}
                </li>
              )}
            </ul>
          </span>
        );
      },
    },
    {
      field: "allocationPanelName",
      headerName: "Allocation",
      width: 120,
      editable: false,
      sortable: false,
      align: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={`${params.row.allocationPanelName}`} arrow>
            <span>{params.row.allocationPanelName}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "feePanelName",
      headerName: "Fee",
      width: 100,
      editable: false,
      sortable: false,
      align: "left",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={`${params.row.feePanelName}`} arrow>
            <span>{params.row.feePanelName}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "lastModified",
      headerName: "Last Modified",
      minWidth: 220,
      editable: false,
      sortable: false,
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1.4,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        const panelSetRevId = row.panelSetRevId;
        const notes = params.row.notes;
        const index =
          params.api.getRowIndexRelativeToVisibleRows(panelSetRevId);
        const status = getVersionStatus(
          row.status,
          panelSetRevId,
          row.editable,
          activateStatus
        );
        return (
          <>
            {status !== "Archived" &&
              roleName === "Admin" &&
              !readOnly &&
              (notes ? (
                <EditIcon
                  data-testid={`btnOpenNotes-${index}`}
                  id={`btnOpenNotes-${index}`}
                  color="primary"
                  sx={{ paddingRight: "10px" }}
                  onClick={() => handleOpenNotes(panelSetRevId, notes, status)}
                />
              ) : (
                <Button
                  data-testid={`btnOpenNotes-${index}`}
                  id={`btnOpenNotes-${index}`}
                  onClick={() => handleOpenNotes(panelSetRevId, notes, status)}
                  variant="text"
                  size="small"
                  sx={{ padding: 0, minWidth: 0 }}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              ))}
            <span>{notes}</span>
          </>
        );
      },
    },
    {
      field: "parent",
      headerName: "Parent",
      width: 80,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return params.row.panelSetParentRevId ?? "";
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  useEffect(() => {
    setPaginationModel({ page: currentPage, pageSize: pageSize });
  }, [currentPage, pageSize]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchPanelVersions(panelSetId, newPage, newPageSize);
  };

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const fetchPanelVersions = (
    id: number,
    currentPage: number,
    pageSize: number
  ) => {
    return dispatch(getPanelVersions(id, currentPage, pageSize));
  };

  const handleCompare = (
    event: React.ChangeEvent<HTMLInputElement>,
    versionId: number,
    compareCount: number
  ) => {
    const isChecked = event.target.checked;
    dispatch(
      setVersionsToCompare(
        versionId,
        isChecked,
        isChecked ? compareCount + 1 : compareCount - 1
      )
    );
  };

  const handleOpenNotes = (
    panelSetRevId: number,
    notes: string,
    status: string
  ) => {
    setSelectedVersion(panelSetRevId);
    setVersionNotes(notes);
    setVersionStatus(status);
    setOpenNotes(true);
  };

  const handleNotesClose = () => {
    setSelectedVersion(0);
    setOpenNotes(false);
  };

  const handleUpdateNotes = () => {
    dispatch(updatePanelVersionNotes(panelSetId, selectedVersion, versionNote));
    setOpenNotes(false);
  };

  const handleCopyNEdit = (panelId: number, PanelVersionId: number) => {
    dispatch(copyPanelVersion(panelId, PanelVersionId));
  };

  const handleOpenActivation = (feeRevId: number) => {
    setSelectedVersion(feeRevId);
    setMinDateTime(dayjs().set("second", 0).set("millisecond", 0));
    setActivateDate(dayjs().set("second", 0).set("millisecond", 0));
    setOpenActivation(true);
  };

  const handleActivationClose = () => {
    setSelectedVersion(0);
    setOpenActivation(false);
  };

  const handleAllocationActivation = () => {
    if (activateDate != null) {
      setActivateDate(
        activateDate
          .set("second", 0)
          .set("milliseconds", 0)
          .set("millisecond", 0)
      );
    }
    dispatch(
      schedulePanelVersionActivation(panelSetId, selectedVersion, activateDate)
    );
    setOpenActivation(false);
  };

  const changeDates = (newValue: any) => {
    if (newValue != null) {
      setActivateDate(newValue);
    }
  };
  const handleOpenDiscard = (panelSetRevId: number) => {
    setSelectedVersion(panelSetRevId);
    setOpenDiscard(true);
  };

  const handleDiscardVersion = () => {
    dispatch(discardPanelVersion(panelSetId, selectedVersion));
    setOpenDiscard(false);
  };

  return (
    <>
      <Grid container size={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Grid size={12} className={classes.versionsHeader}>
          <Typography id="tbl-info" data-testid="tbl-info">
            {rowCount} Versions
          </Typography>
        </Grid>
      </Grid>
      <Grid
        size={12}
        display={"flex"}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: 400,
          width: "100%",
          "& .status-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.55)",
            color: "#1a3e72",
            fontWeight: "600",
          },
          "& .Active": {
            backgroundColor: theme.palette.success.lightest,
            "&:hover": {
              backgroundColor: `${theme.palette.success.lightest} !important`,
            },
          },
          "& .Scheduled": {
            backgroundColor: theme.palette.warning.lighter,
            "&:hover": {
              backgroundColor: `${theme.palette.warning.lighter} !important`,
            },
          },
          "& .Draft": {
            backgroundColor: theme.palette.draft.light,
            "&:hover": {
              backgroundColor: `${theme.palette.draft.light} !important`,
            },
          },
          "& .Failed": {
            backgroundColor: theme.palette.error.lightest,
            "&:hover": {
              backgroundColor: `${theme.palette.error.lightest} !important`,
            },
          },
        }}
      >
        <DataGridPro
          loading={versionLoading}
          style={{ cursor: "pointer" }}
          className="tblPanelVersions"
          getRowId={(row) => row.panelSetRevId}
          rows={panelVersions}
          rowCount={rowCountState}
          columns={
            roleName === "Admin"
              ? [
                  ...columns,
                  {
                    field: "action",
                    headerName: "",
                    editable: false,
                    sortable: false,
                    disableColumnMenu: true,
                    hideable: false,
                    width: 180,
                    renderCell: (params: GridRenderCellParams) => {
                      const panelSetRevId = params.row.panelSetRevId;
                      const index =
                        params.api.getRowIndexRelativeToVisibleRows(
                          panelSetRevId
                        );
                      let isDisabled = true;
                      const status = getVersionStatus(
                        params.row.active,
                        params.row.panelSetRevId,
                        params.row.editable,
                        activateStatus
                      );
                      if (status === "Draft" || status === "Failed") {
                        isDisabled = false;
                      }
                      const canDiscard =
                        (status === "Draft" || status === "Failed") &&
                        !!panelSet.panelSetRevId;
                      return (
                        !readOnly && (
                          <>
                            <IconButton
                              id={`btn-discard-${index}`}
                              data-testid={`btn-discard-${index}`}
                              aria-label="Discard"
                              disabled={!canDiscard}
                              color="primary"
                              onClick={(e) => {
                                handleOpenDiscard(panelSetRevId);
                                e.stopPropagation();
                              }}
                            >
                              <Tooltip title="Discard version">
                                <DeleteOutlineIcon sx={{ fontSize: 19 }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              id={`btn-version-copy-edit-${index}`}
                              data-testid={`btn-version-copy-edit-${index}`}
                              aria-label="Copy & Edit"
                              disabled={hasEditable}
                              onClick={(e) => {
                                handleCopyNEdit(panelSetId, panelSetRevId);
                                e.stopPropagation();
                              }}
                            >
                              <Tooltip title="Copy version">
                                <img src={copyEditOutlined} alt="" />
                              </Tooltip>
                            </IconButton>
                            <Tooltip
                              title={
                                !isDisabled && "Activate this panel version"
                              }
                            >
                              <span>
                                <Button
                                  id={`btn-active-${index}`}
                                  data-testid={`btn-active-${index}`}
                                  variant="text"
                                  disabled={isDisabled}
                                  onClick={() =>
                                    handleOpenActivation(panelSetRevId)
                                  }
                                >
                                  Activate
                                </Button>
                              </span>
                            </Tooltip>
                          </>
                        )
                      );
                    },
                  },
                ]
              : columns
          }
          disableColumnFilter={true}
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[15, 20, 50]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          getRowClassName={(params) => {
            const status = getVersionStatus(
              params.row.active,
              params.row.panelSetRevId,
              params.row.editable,
              activateStatus
            );
            return status !== "Archived"
              ? status
              : params.indexRelativeToCurrentPage % 2 === 0
              ? "even"
              : "";
          }}
          onCellClick={(
            params: GridCellParams,
            event: MuiEvent<React.MouseEvent>
          ) => {
            const row = params.row;
            const status = getVersionStatus(
              row.active,
              row.panelSetRevId,
              row.editable,
              activateStatus
            );
            if (
              params.field === "notes" &&
              row.notes !== "" &&
              row.notes !== null
            ) {
              handleOpenNotes(row.panelSetRevId, row.notes, status);
            }
            event.defaultMuiPrevented = true;
          }}
        />
      </Grid>

      <PanelExDialog
        id="notes-dialog"
        data-testid="notes-dialog"
        open={openNotes}
        title={`Notes for v${selectedVersion}`}
        submitText="Update"
        submitEnabled={!readOnly}
        onClose={handleNotesClose}
        onSubmit={handleUpdateNotes}
        showAction={versionStatus !== "Archived" && !readOnly ? true : false}
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            padding: "20px",
          }}
        >
          <TextField
            id="textarea-notes"
            data-testid="textarea-notes"
            multiline
            fullWidth={true}
            rows={4}
            value={versionNote || ""}
            disabled={versionStatus === "Archived"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setVersionNotes(event.target.value);
            }}
          />
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="activate-dialog"
        data-testid="activate-dialog"
        open={openActivation}
        title={`Activate version ${selectedVersion}`}
        submitText="Agree and Activate"
        submitEnabled={true}
        onClose={handleActivationClose}
        onSubmit={handleAllocationActivation}
        showAction={versionStatus !== "Archived"}
        maxWidth="xs"
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "10px",
            maxWidth: "sm",
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 420 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1}>
                <Grid size={6}>
                  <DesktopDateTimePicker
                    sx={{ mt: 0, minWidth: 210 }}
                    data-testid="alloc-activate-dialog-date"
                    format="DD-MM-YYYY hh:mm a"
                    value={activateDate}
                    onChange={(newValue) => changeDates(newValue)}
                    minDateTime={minDateTime}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </FormControl>
          <DialogContentText
            id="alloc-activate-dialog-text"
            data-testid="alloc-activate-dialog-text"
          >
            <br />I agree and acknowledge that all the allocation details are
            verified and correct
          </DialogContentText>
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="discard-dialog"
        data-testid="discard-dialog"
        open={openDiscard}
        title="Are you sure you want to discard this draft?"
        submitText="Yes, Discard"
        submitEnabled={true}
        onClose={() => setOpenDiscard(false)}
        onSubmit={handleDiscardVersion}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
        >
          <Typography variant="body1">
            Discarding this draft will remove it. A new draft will need to be
            created if required.
          </Typography>
        </Box>
      </PanelExDialog>
      {versionCompare.length > 0 && (
        <PanelExActionSnackbar width={550} readOnly={readOnly} />
      )}
      {showVersionCompare && <PanelVersionComparison />}
    </>
  );
}
