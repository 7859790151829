import React, { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import Box from "@mui/material/Box";
import PanelExDialog from "./PanelExDialog";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

interface ActivationStatusBarProps {
  activationLogId: number;
  versionId: number;
  activationStatus: string;
  scheduledDatetime: number;
  failedDatetime: number;
  onCancelSchedule: (activationLogId: number, versionId: number) => void;
  onAcknowledgeSchedule: (activationLogId: number, versionId: number) => void;
  onMoveTab: () => void;
  onCloseFailModal: () => void;
  openFailModal: boolean;
  readOnly: boolean;
}

export const handleClose = (event: any, reason: any, closeHandler: any) => {
  if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
    return;
  closeHandler();
};

export default function ActivationStatusbar(props: ActivationStatusBarProps) {
  const {
    activationLogId,
    activationStatus,
    versionId,
    scheduledDatetime,
    failedDatetime,
    readOnly,
  } = props;

  const theme = useTheme();
  const timezone = moment.tz.guess();
  const timezoneAbbr = moment().tz(timezone).format("z");
  const scheduled = moment(scheduledDatetime)
    .tz(timezone)
    .format("DD-MM-YYYY hh:mm:ss A");
  const failed = moment(failedDatetime)
    .tz(timezone)
    .format("DD-MM-YYYY hh:mm:ss A");

  const [openCancel, setOpenCancel] = useState(false);

  const useStyles = makeStyles()(() => ({
    scheduledBox: {
      display: "flex",
      padding: "6px 6px 6px 6px",
      alignItems: "center",
    },
    queuedBackgroundColor: {
      backgroundColor: theme.palette.warning.lighter,
    },
    errorBackgroundColor: {
      backgroundColor: theme.palette.error.lightest,
    },
    statusBox: {
      display: "flex",
      backgroundColor: theme.palette.common.white,
      height: "20px",
      padding: "2px 8px 2px 8px",
      border: `1px solid ${theme.colors.grey.lighter}`,
      letterSpacing: "0.5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    contentBox: {
      display: "flex",
      color: theme.colors.grey.darker,
      letterSpacing: "0.16px",
      marginLeft: "5px",
      marginRight: "5px",
      height: "28px",
      padding: "0px 6px 0px 4px",
      alignItems: "center",
    },
  }));
  const { classes } = useStyles();

  const getActivationStatus = (status: string) => {
    if (status === "Queued") {
      return "Scheduled";
    } else if (status === "Error") {
      return "Failed";
    } else {
      return status;
    }
  };

  const handleCancelSchedule = () => {
    props.onCancelSchedule(activationLogId, versionId);
    setOpenCancel(false);
  };

  const handleAcknowledgeSchedule = () => {
    props.onAcknowledgeSchedule(activationLogId, versionId);
  };

  return (
    <div
      data-testid="activation-status-bar"
      id="activation-status-bar"
      className={`${classes.scheduledBox} ${
        activationStatus === "Queued"
          ? classes.queuedBackgroundColor
          : classes.errorBackgroundColor
      } `}
    >
      <Grid
        data-testid="activation-status"
        id="activation-status"
        className={classes.statusBox}
      >
        <Typography variant="small">
          {getActivationStatus(activationStatus).toUpperCase()}
        </Typography>
      </Grid>
      <Grid
        data-testid="activation-datetime"
        id="activation-datetime"
        className={classes.contentBox}
      >
        <Typography variant="small">
          v{versionId} |{" "}
          {(activationStatus === "Queued" ? scheduled : failed) +
            " " +
            timezoneAbbr}
        </Typography>
      </Grid>
      {activationStatus === "Queued" && !readOnly && (
        <Grid>
          <Button
            data-testid="btn-activation-cancel"
            id="btn-activation-cancel"
            variant="outlined"
            size="small"
            onClick={() => setOpenCancel(true)}
          >
            Cancel
          </Button>
        </Grid>
      )}
      {activationStatus === "Error" && !readOnly && (
        <Grid>
          <Button
            data-testid="btn-activation-acknowledge"
            id="btn-activation-acknowledged"
            variant="outlined"
            size="small"
            onClick={handleAcknowledgeSchedule}
          >
            Acknowledged
          </Button>
        </Grid>
      )}
      <PanelExDialog
        id="cancel-dialog"
        data-testid="cancel-dialog"
        open={openCancel}
        title="Are you sure you want to cancel?"
        submitText="Confirm"
        submitEnabled={true}
        onClose={() => setOpenCancel(false)}
        onSubmit={handleCancelSchedule}
        showAction={true}
        maxWidth={"sm"}
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            padding: "20px",
          }}
        >
          <span>
            Cancelling the scheduled activation will return the version to Draft
            status
          </span>
        </Box>
      </PanelExDialog>
      <Dialog
        id="fail-cancel-dialog"
        data-testid="fail-cancel-dialog"
        fullWidth={true}
        maxWidth="sm"
        open={props.openFailModal}
        onClose={(event, reason) =>
          handleClose(event, reason, props.onCloseFailModal)
        }
      >
        <DialogTitle>
          <Grid
            size={12}
            id={"fail-cancel-dialog-title"}
            data-testid={"fail-cancel-dialog-title"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3">Activation Processed</Typography>
            <CloseIcon
              id={"fail-cancel-dialog-close"}
              data-testid={"fail-cancel-dialog-close"}
              onClick={props.onCloseFailModal}
              color="info"
            />
          </Grid>
        </DialogTitle>
        <DialogContent
          id={"fail-cancel-dialog-content"}
          data-testid={"fail-cancel-dialog-content"}
          dividers={true}
        >
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              padding: "20px",
            }}
          >
            <span>
              This scheduled activation has been processed and cannot be
              cancelled.
            </span>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            id={"fail-cancel-dialog-move-tab"}
            data-testid={"fail-cancel-dialog-move-tab"}
            onClick={() => {
              props.onMoveTab();
              props.onCloseFailModal();
            }}
            variant="text"
          >
            Go To Version Tab
          </Button>
          <Button
            color="primary"
            variant="contained"
            id={"fail-cancel-dialog-ok"}
            data-testid={"fail-cancel-dialog-ok"}
            onClick={() => props.onCloseFailModal()}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
