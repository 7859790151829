import {
  ATTEMPT_FETCH_FEES,
  SUCCEED_FETCH_FEES,
  FAIL_FETCH_FEES,
  RESET_PAGINATION,
  ATTEMPT_FETCH_FEE_SET,
  SUCCEED_FETCH_FEE_SET,
  FAIL_FETCH_FEE_SET,
  ATTEMPT_FETCH_FEE_DETAIL,
  SUCCEED_FETCH_FEE_DETAIL,
  FAIL_FETCH_FEE_DETAIL,
  CHANGE_FEE_TAB,
  SUCCEED_FETCH_FEE_VERSION_DETAIL,
  SUCCEED_FETCH_LATEST_FEE_ACTIVATION_LOG,
  FAIL_FETCH_LATEST_FEE_ACTIVATION_LOG,
  ATTEMPT_FETCH_FEE_VERSIONS,
  SUCCEED_FETCH_FEE_VERSIONS,
  FAIL_FETCH_FEE_VERSIONS,
  CHANGE_FEE_VERSION_ID,
  FAIL_FETCH_FEE_ACTIVATION_LOGS,
  SUCCEED_FETCH_FEE_ACTIVATION_LOGS,
  ATTEMPT_FETCH_FEE_ACTIVATION_LOGS,
  UPDATE_LOCATION_OPTION,
  ATTEMPT_FETCH_LOCATION_SUGGESTION,
  SUCCEED_FETCH_LOCATION_SUGGESTION,
  FAIL_FETCH_LOCATION_SUGGESTION,
  CLEAR_LOCATION_SUGGESTION,
  SUCCEED_UPDATE_FEE_VERSION_NOTES,
  OPEN_FEE_SET_NAME_MODAL,
  SUCCEED_UPDATE_FEE_SET_NAME,
  FAIL_DUPLICATE_UPDATE_FEE_SET_NAME,
  RESET_UPDATE_NAME_ERROR,
  SUCCEED_UPDATE_FEE_DETAIL,
  SUCCEED_REMOVE_FEE_LOCATION,
  SUCCEED_DISCARD_FEE_VERSION,
  SUCCEED_UPDATE_FEE_BRACKETS,
  SUCCEED_SCHEDULE_FEE_VERSION_ACTIVATION,
  FAIL_SCHEDULE_FEE_VERSION_ACTIVATION,
  ATTEMPT_COPY_FEE_VERSION,
  SUCCEED_COPY_FEE_VERSION,
  FAIL_COPY_FEE_VERSION,
  SUCCEED_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION,
  CLEAR_VERSIONS_TO_COMPARE,
  SAVE_VERSIONS_TO_COMPARE,
  SHOW_VERSIONS_COMPARISON,
  ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS,
  SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS,
  FAIL_FETCH_VERSIONS_COMPARISON_DETAILS,
  OPEN_IMPORT_MODAL,
  SUCCEED_IMPORT_FEE_VERSION,
  FAIL_VALIDATION_IMPORT_FEE_VERSION,
  RESET_VALIDATION_ERRORS,
  SHOW_CREATE_NEW_FEE,
  UPDATE_FEENAME_NEW_FEE,
  UPDATE_LOCATION_OPTION_NEW_FEE,
  ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_FEE,
  CLEAR_LOCATION_SUGGESTION_NEW_FEE,
  FAIL_FETCH_LOCATION_SUGGESTION_NEW_FEE,
  SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_FEE,
  UPDATE_FEE_BRACKETS_NEW_FEE,
  UPDATE_LOCATION_NEW_FEE,
  REMOVE_FEE_LOCATION_NEW_FEE,
  ATTEMPT_IMPORT_FEE_VERSION,
  ERROR_CREATE_NEW_FEE,
  FAIL_CREATE_NEW_FEE,
  SUCCEED_CREATE_NEW_FEE,
  ATTEMPT_FETCH_ASSOCIATED_PANELS,
  SUCCEED_FETCH_ASSOCIATED_PANELS,
  FAIL_FETCH_ASSOCIATED_PANELS,
  ATTEMPT_FETCH_FILTER_NAME_SUGGESTION,
  SUCCEED_FETCH_FILTER_NAME_SUGGESTION,
  FAIL_FETCH_FILTER_NAME_SUGGESTION,
  CLEAR_FILTER_NAME_SUGGESTION,
  SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION,
  FAIL_FETCH_FILTER_LOCATION_SUGGESTION,
  CLEAR_FILTER_LOCATION_SUGGESTION,
  ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION,
  ADD_FILTER_LOCATION,
  DELETE_FILTER_LOCATION,
  CHANGE_FILTER_COUNTRY,
  CLEAR_ALL_FILTER,
  OPEN_FILTER,
  COPY_FILTER,
  UPDATE_FEE_STATUS_FILTER,
  SUCCEED_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION,
} from "../staticSagas/sagaFee";
import { UPDATE_SEARCH_OPTION } from "../staticSagas/sagaUI";

const initialFeeDetail = {
  locations: [],
  locationCount: 0,
  currentPage: 0,
  pageSize: 15,
  suggestion: [],
  searchOption: 1,
  searchText: null,
  editedLocation: null,
  feePanelBrackets: [],
};
const initialVersionCompare = {
  versionCompare: [],
  versionCompareDetails: {},
  showVersionCompare: false,
  versionCompareLoading: false,
};
const initialFeeVersion = {
  feeVersions: [],
  feeVersionCount: 0,
  currentPage: 0,
  pageSize: 15,
  loading: false,
  ...initialVersionCompare,
};

const initialFilterOptions = {
  nameText: "",
  nameSuggestion: [],
  countryId: 1,
  locationText: "",
  locationSuggestion: [],
  locations: [],
  feeSetStatus: "In Use",
  isFiltered: false,
  isOpenFilter: false,
};

const initialFilteredOptions = {
  name: "",
  locations: [],
  status: "In Use",
  nameSuggestion: [],
  countryId: 1,
  locationText: "",
  locationSuggestion: [],
};

const initialUpdateName = {
  isOpen: false,
  message: null,
};

const initialFeeSet = {
  feePanelId: 0,
  name: "",
  feeRevId: 0,
  lastModified: null,
  notes: "",
  status: "",
  hasEditable: false,
  editableRevId: 0,
};

const initialNewFeeSet = {
  searchOption: null,
  searchText: null,
  suggestion: [],
  feeName: "",
  feeVersionCount: 0,
  currentPage: 0,
  pageSize: 15,
  loading: false,
  feePanelBrackets: [],
  locations: [],
  newFeeId: 0,
  error: null,
};

export const initialState = {
  fees: [],
  feeCount: 0,
  currentPage: 0,
  pageSize: 15,
  loading: false,
  validationErrors: [],
  feeTab: 0,
  feeSet: initialFeeSet,
  updateName: initialUpdateName,
  feeDetail: initialFeeDetail,
  feeVersionId: 0,
  feeVersionDetail: {},
  latestFeeActivationLog: {},
  feeVersion: initialFeeVersion,
  activationLog: {
    activationLogs: [],
    activationLogCount: 0,
    currentPage: 0,
    pageSize: 15,
    sortBy: "requested",
    sortDir: "desc",
    loading: false,
  },
  showCreateNewFeeSet: false,
  newFeeSet: initialNewFeeSet,
  isOpenImportModal: false,
  isUploading: false,
  associatedPanel: {
    panels: [],
    panelCount: 0,
    currentPage: 0,
    pageSize: 15,
    sortBy: "name",
    sortDir: "asc",
    loading: false,
  },
  filterOptions: initialFilterOptions,
  filteredOptions: initialFilteredOptions,
  openBackdrop: false,
  latestVersionLocationCount: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SEARCH_OPTION:
      return {
        ...state,
        filterOptions: initialFilterOptions,
        filteredOptions: initialFilteredOptions,
      };
    case ATTEMPT_FETCH_FEES:
      return {
        ...state,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        loading: true,
        sortBy: action.data.sortBy,
        sortDir: action.data.sortDir,
        filterOptions: {
          ...state.filterOptions,
          isFiltered: action.data.isFiltered,
        },
      };
    case SUCCEED_FETCH_FEES:
      return {
        ...state,
        fees: action.data.feePanels,
        feeCount: action.data.totalCount,
        loading: false,
        filteredOptions: {
          ...state.filteredOptions,
          name: action.data.name,
          locations: action.data.locations,
          status: action.data.status,
          nameSuggestion: action.data.nameSuggestion,
          countryId: action.data.countryId,
          locationText: action.data.locationText,
          locationSuggestion: action.data.locationSuggestion,
        },
      };
    case FAIL_FETCH_FEES:
      return {
        ...state,
        fees: [],
        feeCount: 0,
        loading: false,
      };
    case RESET_PAGINATION:
      return {
        ...state,
        currentPage: 0,
        pageSize: 15,
      };
    case ATTEMPT_FETCH_FEE_SET:
      return {
        ...state,
        feeSet: initialFeeSet,
        feeDetail: initialFeeDetail,
        newFeeSet: initialNewFeeSet,
      };
    case SUCCEED_FETCH_FEE_SET:
      return {
        ...state,
        feeSet: action.data,
        feeVersionId: action.data.feeRevId,
        feeVersion: {
          ...state.feeVersion,
          feeVersionCount: 0,
        },
      };
    case FAIL_FETCH_FEE_SET:
      return {
        ...state,
        feeSet: initialFeeSet,
      };
    case ATTEMPT_FETCH_FEE_DETAIL:
      return {
        ...state,
        loading: true,
        feeDetail: {
          ...state.feeDetail,
          locations: [],
          locationCount: 0,
          feePanelBrackets: [],
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          editedLocation: null,
        },
        feeVersion: {
          ...state.feeVersion,
          ...initialVersionCompare,
        },
      };
    case SUCCEED_FETCH_FEE_DETAIL:
      return {
        ...state,
        loading: false,
        feeDetail: {
          ...state.feeDetail,
          locations: action.data.feeDetails,
          locationCount: action.data.totalCount,
          feePanelBrackets: action.data.feePanelBrackets,
        },
        latestVersionLocationCount:
          action.payload.locationFilter === ""
            ? action.data.totalCount
            : state.latestVersionLocationCount,
      };
    case FAIL_FETCH_FEE_DETAIL:
      return {
        ...state,
        loading: false,
        feeDetail: {
          ...state.feeDetail,
          locations: [],
          locationCount: 0,
          feePanelBrackets: [],
        },
      };
    case CHANGE_FEE_TAB:
      return {
        ...state,
        feeTab: action.index,
      };
    case SUCCEED_FETCH_FEE_VERSION_DETAIL: {
      const latestActivationLogId = Math.max(
        ...action.data.feePanelActivationLogs.map(
          (p) => p.feePanelActivationLogId
        ),
        0
      );
      return {
        ...state,
        feeVersionDetail: {
          ...action.data,
          latestActivationLogId,
        },
      };
    }
    case SUCCEED_FETCH_LATEST_FEE_ACTIVATION_LOG:
      const { status, feePanelRevId } = action.data;
      return {
        ...state,
        latestFeeActivationLog: action.data,
        feeSet: {
          ...state.feeSet,
          feeRevId:
            status === "Processed" ? feePanelRevId : state.feeSet.feeRevId,
          hasEditable:
            status === "Processed" &&
            feePanelRevId === state.feeSet.editableRevId
              ? false
              : state.feeSet.hasEditable,
          editableRevId:
            status === "Processed" &&
            feePanelRevId === state.feeSet.editableRevId
              ? 0
              : state.feeSet.editableRevId,
        },
      };
    case FAIL_FETCH_LATEST_FEE_ACTIVATION_LOG:
      return {
        ...state,
        latestFeeActivationLog: {},
      };
    case ATTEMPT_FETCH_FEE_VERSIONS:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
      };
    case SUCCEED_FETCH_FEE_VERSIONS:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          loading: false,
          feeVersions: action.data.feePanelRevs,
          feeVersionCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_FEE_VERSIONS:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          loading: false,
          feeVersions: [],
          feeVersionCount: 0,
        },
      };
    case CHANGE_FEE_VERSION_ID:
      return {
        ...state,
        feeVersionId: action.id,
      };
    case ATTEMPT_FETCH_FEE_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
        feeVersion: {
          ...state.feeVersion,
          ...initialVersionCompare,
        },
      };
    case SUCCEED_FETCH_FEE_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: false,
          activationLogs: action.data.activationLogs,
          activationLogCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_FEE_ACTIVATION_LOGS:
      return {
        ...state,
        activationLog: {
          ...state.activationLog,
          loading: false,
          activationLogs: [],
          activationLogCount: 0,
        },
      };
    case UPDATE_LOCATION_OPTION:
      return {
        ...state,
        feeDetail: {
          ...state.feeDetail,
          searchOption: action.option,
          searchText: null,
          suggestion: [],
        },
      };
    case ATTEMPT_FETCH_LOCATION_SUGGESTION:
      return {
        ...state,
        feeDetail: {
          ...state.feeDetail,
          searchText: action.text,
        },
      };
    case SUCCEED_FETCH_LOCATION_SUGGESTION:
      return {
        ...state,
        feeDetail: {
          ...state.feeDetail,
          suggestion: action.data,
        },
      };
    case FAIL_FETCH_LOCATION_SUGGESTION:
      return {
        ...state,
        feeDetail: {
          ...state.feeDetail,
          suggestion: [],
        },
      };
    case CLEAR_LOCATION_SUGGESTION:
      return {
        ...state,
        feeDetail: {
          ...state.feeDetail,
          suggestion: [],
        },
      };
    case SUCCEED_UPDATE_FEE_VERSION_NOTES:
      const feeVersions = state.feeVersion.feeVersions.map((version) => {
        if (version.feePanelRevId === action.data.feeVersionId) {
          version.notes = action.data.notes;
        }
        return version;
      });
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          feeVersions,
        },
      };
    case OPEN_FEE_SET_NAME_MODAL:
      return {
        ...state,
        updateName: {
          ...state.updateName,
          isOpen: action.data.isOpen,
        },
      };
    case SUCCEED_UPDATE_FEE_SET_NAME:
      return {
        ...state,
        feeSet: {
          ...state.feeSet,
          name: action.data.name,
        },
        updateName: {
          ...state.updateName,
          isOpen: false,
          message: "",
        },
      };
    case FAIL_DUPLICATE_UPDATE_FEE_SET_NAME:
      return {
        ...state,
        updateName: {
          ...state.updateName,
          message: action.data.message,
        },
      };
    case RESET_UPDATE_NAME_ERROR:
      return {
        ...state,
        updateName: {
          ...state.updateName,
          message: null,
        },
      };
    case SUCCEED_UPDATE_FEE_DETAIL: {
      const updatedLocation = action.payload;
      const otherLocationDetails = state.feeDetail.locations.filter(
        (location) =>
          location.feePanelFilterId !== updatedLocation.feePanelFilterId
      );
      return {
        ...state,
        loading: false,
        feeDetail: {
          ...state.feeDetail,
          locations: [updatedLocation, ...otherLocationDetails],
          locationCount: otherLocationDetails.length + 1,
          editedLocation: updatedLocation.feePanelFilterId,
          suggestion: [],
          searchText: null,
        },
        latestVersionLocationCount: otherLocationDetails.length + 1,
      };
    }
    case SUCCEED_REMOVE_FEE_LOCATION:
      return {
        ...state,
        feeDetail: {
          ...state.feeDetail,
          locations: action.data.feeDetails,
          locationCount: action.data.totalCount,
          feePanelBrackets: action.data.feePanelBrackets,
        },
        latestVersionLocationCount:
          action.payload.locationFilter === ""
            ? action.data.totalCount
            : state.latestVersionLocationCount,
      };
    case SUCCEED_DISCARD_FEE_VERSION:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          feeVersions: state.feeVersion.feeVersions.filter(
            (version) => version.feePanelRevId !== action.data.feeRevId
          ),
          feeVersionCount: state.feeVersion.feeVersionCount - 1,
        },
        feeVersionId: state.feeSet.feeRevId,
        feeSet: {
          ...state.feeSet,
          hasEditable: false,
          editableRevId: null,
        },
      };
    case SUCCEED_UPDATE_FEE_BRACKETS:
      const updatedFeeBrackets = action.payload;
      return {
        ...state,
        loading: false,
        feeDetail: {
          ...state.feeDetail,
          feePanelBrackets: updatedFeeBrackets,
        },
      };
    case SUCCEED_SCHEDULE_FEE_VERSION_ACTIVATION:
      return {
        ...state,
        latestFeeActivationLog: action.data,
      };
    case FAIL_SCHEDULE_FEE_VERSION_ACTIVATION:
      return {
        ...state,
        latestFeeActivationLog: {},
      };
    case ATTEMPT_COPY_FEE_VERSION:
      return {
        ...state,
        openBackdrop: true,
      };
    case SUCCEED_COPY_FEE_VERSION:
      return {
        ...state,
        feeVersionId: action.data.feeVersionId,
        feeTab: 0,
        feeSet: {
          ...state.feeSet,
          hasEditable: true,
          editableRevId: action.data.feeVersionId,
        },
        openBackdrop: false,
      };
    case FAIL_COPY_FEE_VERSION:
      return {
        ...state,
        openBackdrop: false,
      };
    case SUCCEED_CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION:
      return {
        ...state,
        latestFeeActivationLog: {
          ...state.latestFeeActivationLog,
          status: "Canceled",
        },
      };
    case SUCCEED_ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION:
      return {
        ...state,
        latestFeeActivationLog: {
          ...state.latestFeeActivationLog,
          status: "Acknowledged",
        },
      };
    case SAVE_VERSIONS_TO_COMPARE: {
      const { feeVersionId, selected } = action.payload;
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          versionCompare: selected
            ? [...state.feeVersion.versionCompare, feeVersionId]
            : [
                ...state.feeVersion.versionCompare.filter(
                  (v) => v !== feeVersionId
                ),
              ],
        },
      };
    }
    case CLEAR_VERSIONS_TO_COMPARE: {
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          versionCompare: [],
        },
      };
    }
    case SHOW_VERSIONS_COMPARISON:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          showVersionCompare: action.show,
        },
      };
    case ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          versionCompareLoading: true,
          versionCompareDetails: {},
        },
      };
    case SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          versionCompareLoading: false,
          versionCompareDetails: action.data,
        },
      };
    case FAIL_FETCH_VERSIONS_COMPARISON_DETAILS:
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          versionCompareLoading: false,
          versionCompareDetails: {},
        },
      };
    case SHOW_CREATE_NEW_FEE:
      return {
        ...state,
        showCreateNewFeeSet: action.show,
        newFeeSet: initialNewFeeSet,
      };
    case UPDATE_FEENAME_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          feeName: action.payload.feeName,
          error: null,
        },
      };
    case UPDATE_LOCATION_OPTION_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          searchOption: action.option,
          searchText: null,
          suggestion: [],
        },
      };
    case ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          searchText: action.text,
        },
      };
    case SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_FEE:
      const existingLocKeys = state.newFeeSet.locations.map(
        (v) => v.locationId
      );

      let newSuggestions = action.data.map((v) => {
        let locationIdNew;
        if (v.type === "Postcode") {
          locationIdNew = v.id + "-null-null-" + v.countryId;
        } else if (v.type === "Region") {
          locationIdNew = "null" + "-" + v.id + "-null-" + v.countryId;
        } else if (v.type === "State") {
          locationIdNew = "null-null-" + v.id + "-" + v.countryId;
        } else {
          locationIdNew = "null-null-null-" + v.countryId;
        }
        return {
          ...v,
          isExisting: existingLocKeys.includes(locationIdNew),
        };
      });
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          suggestion: newSuggestions,
        },
      };
    case FAIL_FETCH_LOCATION_SUGGESTION_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          suggestion: [],
        },
      };
    case CLEAR_LOCATION_SUGGESTION_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          suggestion: [],
        },
      };
    case UPDATE_FEE_BRACKETS_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          feePanelBrackets: action.feeBrackets,
        },
      };
    case UPDATE_LOCATION_NEW_FEE:
      const { id, name, type, countryId, countryName } =
        action.payload.location;
      let newLocation = {
        feePanelFilterId: 0,
        feePanelId: 0,
        feePanelRevId: 0,
        ignoreFee: 0,
        countryId,
        countryName,
        stateId: null,
        stateName: null,
        regionId: null,
        regionName: null,
        postcodeId: null,
        postcodeName: null,
      };
      if (type === "Postcode") {
        newLocation = {
          ...newLocation,
          postcodeId: id,
          postcodeName: name,
        };
      } else if (type === "Region") {
        newLocation = {
          ...newLocation,
          regionId: id,
          regionName: name,
        };
      } else {
        newLocation = {
          ...newLocation,
          stateId: id,
          stateName: name,
        };
      }

      const locationId =
        newLocation.postcodeId +
        "-" +
        newLocation.regionId +
        "-" +
        newLocation.stateId +
        "-" +
        newLocation.countryId;

      const fees = action.payload.fees
        .filter((item) => item.fee)
        .map(function (item) {
          return {
            lower: item.lower,
            upper: item.upper,
            fee: item.fee,
          };
        });

      const otherLocationDetails = state.newFeeSet.locations.filter(
        (location) => location.locationId !== locationId
      );
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          locations: [
            {
              ...newLocation,
              locationId,
              fees,
            },
            ...otherLocationDetails,
          ],
          locationCount: otherLocationDetails.length + 1,
          editedLocation: locationId,
          suggestion: [],
          searchText: null,
        },
      };
    case REMOVE_FEE_LOCATION_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          locations: state.newFeeSet.locations.filter(
            (location) => location.locationId !== action.payload.locationId
          ),
        },
      };
    case OPEN_IMPORT_MODAL:
      return {
        ...state,
        isOpenImportModal: action.data.isOpen,
      };
    case ATTEMPT_IMPORT_FEE_VERSION:
      return {
        ...state,
        isUploading: true,
      };
    case SUCCEED_IMPORT_FEE_VERSION:
      let editableVersionCount = 0;
      let feeVersionCount = state.feeVersion.feeVersionCount;
      const newFeeVersions = state.feeVersion.feeVersions.filter((version) => {
        if (!version.editable) {
          return version;
        }
      });
      editableVersionCount =
        state.feeVersion.feeVersions.length - newFeeVersions.length;
      newFeeVersions.unshift(action.data.feePanelRev);
      if (newFeeVersions.length > state.feeVersion.pageSize) {
        newFeeVersions.pop();
      }
      return {
        ...state,
        feeVersion: {
          ...state.feeVersion,
          feeVersions:
            newFeeVersions.length === 0
              ? [action.data.feePanelRev]
              : newFeeVersions,
          feeVersionCount: feeVersionCount - editableVersionCount + 1,
        },
        feeSet: {
          ...state.feeSet,
          hasEditable: true,
        },
        isUploading: false,
      };
    case FAIL_VALIDATION_IMPORT_FEE_VERSION:
      return {
        ...state,
        validationErrors: action.data.validationErrors,
        isUploading: false,
      };
    case RESET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: [],
      };
    case SUCCEED_CREATE_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          newFeeId: action.payload.id,
        },
        showCreateNewFeeSet: false,
        feeVersionId: action.payload.revId,
      };
    case FAIL_CREATE_NEW_FEE:
      return {
        ...state,
        newFeeSet: initialNewFeeSet,
      };
    case ERROR_CREATE_NEW_FEE:
      return {
        ...state,
        newFeeSet: {
          ...state.newFeeSet,
          error: action.error,
        },
      };
    case ATTEMPT_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        associatedPanel: {
          ...state.associatedPanel,
          loading: true,
          currentPage: action.data.currentPage,
          pageSize: action.data.pageSize,
          sortBy: action.data.sortBy,
          sortDir: action.data.sortDir,
        },
      };
    case SUCCEED_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        associatedPanel: {
          ...state.associatedPanel,
          loading: false,
          panels: action.data.panels,
          panelCount: action.data.totalCount,
        },
      };
    case FAIL_FETCH_ASSOCIATED_PANELS:
      return {
        ...state,
        associatedPanel: {
          ...state.associatedPanel,
          loading: false,
          panels: [],
          panelCount: 0,
        },
      };
    case ATTEMPT_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: action.data,
        },
      };
    case FAIL_FETCH_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: [],
        },
      };
    case CLEAR_FILTER_NAME_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameSuggestion: [],
        },
      };
    case ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationText: action.text,
        },
      };
    case SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION:
      const existingKeys = state.filterOptions.locations.map(
        (v) => v.id + "-" + v.type + "-" + v.countryId
      );

      const suggestion = action.data.map((v) => {
        const locationIdNew = v.id + "-" + v.type + "-" + v.countryId;
        return {
          ...v,
          isExisting: existingKeys.includes(locationIdNew),
        };
      });

      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationSuggestion: suggestion,
        },
      };
    case FAIL_FETCH_FILTER_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationSuggestion: [],
        },
      };
    case CLEAR_FILTER_LOCATION_SUGGESTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locationSuggestion: [],
        },
      };
    case ADD_FILTER_LOCATION:
      const updatedSuggestion = state.filterOptions.locationSuggestion.map(
        (v) => {
          return {
            ...v,
            isExisting: !v.isExisting
              ? v.id === action.location.id &&
                v.type === action.location.type &&
                v.countryId === action.location.countryId
              : v.isExisting,
          };
        }
      );
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locations: [
            ...state.filterOptions.locations,
            { ...action.location, isExisting: true },
          ],
          locationSuggestion: updatedSuggestion,
        },
      };
    case DELETE_FILTER_LOCATION:
      const deletedSuggestion = state.filterOptions.locationSuggestion.map(
        (v) => {
          return {
            ...v,
            isExisting:
              v.id === action.payload.id &&
              v.type === action.payload.type &&
              v.countryId === action.payload.countryId
                ? false
                : v.isExisting,
          };
        }
      );
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          locations: state.filterOptions.locations.filter(
            (location) =>
              location.countryId !== action.payload.countryId ||
              location.type !== action.payload.type ||
              location.id !== action.payload.id
          ),
          locationSuggestion: deletedSuggestion,
        },
      };
    case CHANGE_FILTER_COUNTRY:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          countryId: action.countryId,
          locationText: "",
          locationSuggestion: [],
        },
      };
    case CLEAR_ALL_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameText: "",
          nameSuggestion: [],
          countryId: 1,
          locationText: "",
          locationSuggestion: [],
          locations: [],
          feeSetStatus: "In Use",
        },
      };
    case OPEN_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          isOpenFilter: action.isOpen,
        },
      };
    case COPY_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          nameText: state.filteredOptions.name,
          feeSetStatus: state.filteredOptions.status,
          locations: state.filteredOptions.locations,
          countryId: state.filteredOptions.countryId,
          locationText: state.filteredOptions.locationText,
          nameSuggestion: state.filteredOptions.nameSuggestion,
          locationSuggestion: state.filteredOptions.locationSuggestion,
        },
      };
    case UPDATE_FEE_STATUS_FILTER:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          feeSetStatus: action.payload,
        },
      };
    case "persist/REHYDRATE":
      // clear error on refresh. but weird rehydrate errors come in all the time, so don't clear it on those
      return {
        ...state,
      };
    default:
      return state;
  }
}
