import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  Button,
  Typography,
  Tooltip,
  Toolbar,
  TextField,
  Stack,
  Chip,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridCellParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "tss-react/mui";
import {
  getUsers,
  showEditUser,
  showSearchNewUser,
  removeUser,
  reActivateUser,
} from "../../store/action/actionUser";
import EditUserModal from "./EditUserModal";
import SearchNewUser from "./SearchNewUser";
import PanelExSnackbar from "../shared/PanelExSnackbar";
import PanelExDialog from "../shared/PanelExDialog";

export default function Users() {
  const dispatch = useDispatch();
  const [openRemove, setOpenRemove] = useState(false);
  const [openReactivate, setOpenReactivate] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const {
    users,
    searchText,
    currentPage,
    pageSize,
    rowCount,
    loading,
    showEditUserModal,
    showSearchNewUserDialog,
    showAddNewUserModal,
  } = useSelector((state: RootState) => {
    return {
      users: state.user.users,
      searchText: state.user.searchText,
      currentPage: state.user.currentPage,
      pageSize: state.user.pageSize,
      rowCount: state.user.userCount,
      loading: state.user.loading,
      showEditUserModal: state.user.showEditUser,
      showSearchNewUserDialog: state.user.showSearchNewUser,
      showAddNewUserModal: state.user.showAddNewUser,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
      sortable: false,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { userId, first, last, isNab } = params.row;
        return (
          <Grid alignItems={"center"} justifyContent={"center"}>
            {first + " " + last}
            {isNab && (
              <Chip
                id={`chip-nab-${userId}`}
                data-testid={`chip-nab-${userId}`}
                className={classes.chip}
                label="NAB"
                size="small"
                sx={{ marginLeft: "10px" }}
              />
            )}
          </Grid>
        );
      },
    },
    {
      field: "username",
      headerName: "UserName",
      width: 170,
      editable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "organisations",
      headerName: "Organisations",
      width: 230,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "roles",
      headerName: "Role",
      editable: false,
      sortable: false,
      width: 170,
    },
    {
      field: "active",
      headerName: "Status",
      editable: false,
      sortable: false,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const { isActive } = params.row;
        return (
          <Stack
            spacing={2}
            direction="row"
            className={classes.stack}
            justifyContent={"center"}
          >
            {isActive ? "Active" : "Inactive"}
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 170,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { userId, isActive } = params.row;
        const index = params.api.getRowIndexRelativeToVisibleRows(userId);
        return (
          <>
            <Button
              id={`btn-user-edit-${index}`}
              data-testid={`btn-user-edit-${index}`}
              variant="text"
              onClick={() => handleEditUser(userId)}
            >
              Edit
            </Button>

            {isActive ? (
              <Tooltip title={"Deactivate User"}>
                <span>
                  <Button
                    id={`btn-user-deactivate-${index}`}
                    data-testid={`btn-user-deactivate-${index}`}
                    variant="text"
                    onClick={(e) => {
                      handleOpenRemove(userId);
                      e.stopPropagation();
                    }}
                  >
                    Deactivate
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={"Activate User"}>
                <span>
                  <Button
                    id={`btn-user-activate-${index}`}
                    data-testid={`btn-user-activate-${index}`}
                    variant="text"
                    onClick={(e) => {
                      handleOpenReactivate(userId);
                      e.stopPropagation();
                    }}
                  >
                    Activate
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    button: {
      color: theme.palette.common.white,
      "& .MuiButton-startIcon": {
        color: theme.palette.common.white,
      },
    },
    exportButton: {
      color: theme.colors.tertiary.main,
      "& .MuiButton-startIcon": {
        color: theme.colors.tertiary.main,
      },
    },
    select: {
      borderRadius: "4px",
    },
    noResult: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "28px",
      letter: "0.15px",
      color: "#949494",
    },
    warning: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21.98px",
      letterSpacing: "0.1px",
      color: theme.palette.text.primary,
      paddingBottom: "10px",
    },
    itemCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      justifyContent: "flex-start",
    },
    detailsHeader: {
      display: "flex",
      alignItems: "flex-end",
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "26px",
        fontWeight: "500",

        color: theme.colors.grey.darker,
      },
      "& .MuiButton-root": {
        marginLeft: "15px",
      },
    },
    location: {
      color: theme.palette.tertiary.main,
    },
    tblUsers: {
      ".odd.MuiDataGrid-row": {
        backgroundColor: theme.colors.grey.lightest,
      },
    },
    searchInput: {
      width: "480px",
      fontSize: "15px",
    },
    chip: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginLeft: "10px",
      height: "18px",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "12px",
      letterSpacing: "0.14px",
    },
  }));
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  useEffect(() => {
    setPaginationModel({ page: currentPage, pageSize: pageSize });
  }, [currentPage, pageSize]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchUsers(searchText, newPage, newPageSize);
  };

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const fetchUsers = (
    searchText: string,
    currentPage: number,
    pageSize: number
  ) => {
    return dispatch(getUsers(searchText, currentPage, pageSize));
  };
  useEffect(() => {
    fetchUsers(searchText, currentPage, pageSize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditUser = (userId: number) => {
    dispatch(showEditUser(userId, true));
  };

  const openSearchNewUser = () => {
    dispatch(showSearchNewUser(true));
  };

  const handleSearchInputChange = (value: string) => {
    setPaginationModel({ page: 0, pageSize: pageSize });
    fetchUsers(value, 0, pageSize);
  };

  const handleOpenRemove = (userId: number) => {
    setSelectedUserId(userId);
    setOpenRemove(true);
  };

  const handleRemoveUser = () => {
    dispatch(removeUser(selectedUserId));
    setOpenRemove(false);
  };
  const handleOpenReactivate = (userId: number) => {
    setSelectedUserId(userId);
    setOpenReactivate(true);
  };
  const handleReactivateUser = () => {
    dispatch(reActivateUser(selectedUserId));
    setOpenReactivate(false);
  };
  const { classes } = useStyles();
  return (
    <Grid container>
      <Toolbar variant="dense" />
      <Grid
        container
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          p: 3,
          paddingLeft: "calc(3px + 1.5625vw)",
          paddingRight: "calc(3px + 1.5625vw)",
        }}
      >
        <Grid
          container
          size={12}
          sx={{
            display: "flex",
            overflowY: "auto",
            borderRadius: "4px",
            border: "1px",
            padding: "28px 28px 16px 28px",
            justifyContent: "center",
            backgroundColor: theme.colors.alpha.trueWhite[100],
            height: "100%",
            overflowX: "hidden",
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          }}
          data-testid="userList"
        >
          <Grid container size={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <Grid size={5} className={classes.detailsHeader}>
              <Typography data-testid="user-title">Manage Users</Typography>
              <Button
                id="btn-user-create"
                data-testid="btn-user-create"
                variant="text"
                startIcon={
                  <AddIcon
                    sx={{ width: "16px", height: "20px", marginBottom: "2px" }}
                  />
                }
                size="small"
                onClick={() => openSearchNewUser()}
              >
                Add New User
              </Button>
            </Grid>

            <Grid size={7} display={"flex"} justifyContent={"flex-end"}>
              <TextField
                id="users-filter"
                data-testid="users-filter"
                variant="standard"
                className={classes.searchInput}
                value={searchText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearchInputChange(event.target.value);
                }}
                label="Search by typing any text. E.g. Username or email address"
              />
            </Grid>
            <Grid size={12}>
              <Typography
                id="tbl-info"
                data-testid="tbl-info"
                variant="body2"
                sx={{ color: theme.palette.text.secondary }}
              >
                Displaying {users.length} of {rowCount} user
                {rowCount > 1 ? "s" : ""}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              flexDirection: "column",
              height: "auto",
              width: "100%",
              display: `${rowCount === 0 ? "flex" : ""}`,
              justifyContent: `${rowCount === 0 ? "center" : ""}`,
              alignItems: `${rowCount === 0 ? "center" : ""}`,
            }}
          >
            {loading ? (
              <Grid size={12} sx={{ height: "400px" }}>
                <Box
                  id="user-sets-progress"
                  data-testid="user-sets-progress"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CircularProgress />
                </Box>
              </Grid>
            ) : rowCount === 0 ? (
              <Grid container>
                <Grid
                  size={12}
                  sx={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="no-result"
                    data-testid="no-result"
                    variant="subtitle1"
                    className={classes.noResult}
                  >
                    No Result found
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <DataGridPro
                loading={loading}
                style={{ cursor: "pointer", overflowX: "hidden" }}
                className={classes.tblUsers}
                getRowId={(row) => row.userId}
                rows={users}
                rowCount={rowCountState}
                columns={columns}
                disableColumnFilter={true}
                pagination
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={[15, 20, 50]}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 1 ? "odd" : ""
                }
                onCellClick={(
                  params: GridCellParams,
                  event: MuiEvent<React.MouseEvent>
                ) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <PanelExSnackbar />
      <PanelExDialog
        id="deactivate-user-dialog"
        data-testid="deactivate-user-dialog"
        open={openRemove}
        title="Are you sure you want to deactivate the user?"
        submitText="Yes, Deactivate"
        submitEnabled={true}
        onClose={() => setOpenRemove(false)}
        onSubmit={handleRemoveUser}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
        >
          <Typography variant="body1">
            The user will not be able to login to the application
          </Typography>
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="activate-user-dialog"
        data-testid="activate-user-dialog"
        open={openReactivate}
        title="Are you sure you want to activate the user?"
        submitText="Yes, Activate"
        submitEnabled={true}
        onClose={() => setOpenReactivate(false)}
        onSubmit={handleReactivateUser}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
        >
          <Typography variant="body1">
            The user will be able to login to the application
          </Typography>
        </Box>
      </PanelExDialog>
      {(showAddNewUserModal || showEditUserModal) && <EditUserModal />}
      {showSearchNewUserDialog && <SearchNewUser />}
    </Grid>
  );
}
