import moment from "moment";

export const PAGE_CATEGORY = {
  ALLOCATION: "Allocations",
  FEES: "Fees",
  Panels: "Panels",
};

export const segmentPage = (category, pageInfo) => {
  window.analytics.page(category, pageInfo);
};

export const segmentIdentify = (segmentIdentity) => {
  // Add Details to the user
  const details = {
    ...segmentIdentity,
  };
  details.loginDate = moment().format("MMMM Do YYYY, h:mm:ss a");

  window.analytics.identify(segmentIdentity.username, details);
};

export const segmentTrack = (eventName, details) => {
  window.analytics.track(eventName, details);
};
