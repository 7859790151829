import axios from "../../utilities/axiosInstance";

export const fetchToken = (code) => {
  const url = `/admin/access/oauth/token/${code}`;
  return axios.get(url);
};

export const refreshToken = (token) => {
  const url = `/admin/access/refreshToken/${token}`;
  return axios.get(url);
};

export const fetchSearchSuggestion = (text, option) => {
  const url = `/api/${option}/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const fetchLocationSuggestion = (text, option) => {
  const url = `/api/locations/suggestion?q=${encodeURIComponent(
    text
  )}&country=${option}`;
  return axios.get(url);
};

export const fetchOrgSuggestion = (text) => {
  const url = `/api/orgs/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const fetchPanelOrgSuggestion = (text, orgType) => {
  const url = `/api/orgs/${orgType}/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const fetchPropTypeSuggestion = (text) => {
  const url = `/api/propertyTypes/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const fetchServiceTypeSuggestion = (text) => {
  const url = `/api/serviceTypes/suggestion?q=${encodeURIComponent(text)}`;
  return axios.get(url);
};

export const fetchActiveValfirmSuggestion = (text) => {
  const url = `/api/orgs/Valfirm/active/suggestion?q=${encodeURIComponent(
    text
  )}`;
  return axios.get(url);
};

export const fetchActiveOrgSuggestionByTypeAndCountry = (
  orgType,
  countryCode,
  text
) => {
  const url = `/api/orgs/${orgType}/active/${countryCode}/suggestion/?q=${encodeURIComponent(
    text
  )}`;
  return axios.get(url);
};

export const fetchValfirmSuggestion = (text, excludeValfirmId) => {
  const url = `/api/orgs/valfirm/suggestionwithoutvalfirm?q=${encodeURIComponent(
    text
  )}&exclude=${excludeValfirmId}`;
  return axios.get(url);
};
