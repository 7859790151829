import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid2 as Grid,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import logo from "../../img/logo.svg";
import smallLogo from "../../img/smallLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import Search from "./Search";
import { expireSession } from "../../store/action/actionToken";
import { CLAUD_LOGOUT_URL } from "../../../config/claudConfig";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import {
  clearFilter as allocationClearFilter,
  getAllocations,
  openFilter as allocationOpenFilter,
} from "../../store/action/actionAllocation";
import {
  clearFilter as feeClearFilter,
  getFees,
  openFilter as feeOpenFilter,
} from "../../store/action/actionFee";
import {
  clearFilter as panelClearFilter,
  getPanels,
  openFilter as panelOpenFilter,
} from "../../store/action/actionPanel";
import PanelFilterOptions from "./PanelFilterOptions";
import AllocationFilterOptions from "./AllocationFilterOptions";
import FeeFilterOptions from "./FeeFilterOptions";

export default function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const divRef = React.useRef(null);

  const listenToScroll = () => {
    const heightToHideFrom = 220;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      !isVisible && setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const useStyles = makeStyles()(() => ({
    popper: {
      width: "300px",
      marginTop: "5px !important",
      zIndex: "1202",
      background: theme.palette.common.black,
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      borderRadius: "5px",
      fontSize: "13px",
    },
    gridPadding: {
      padding: "10px 15px 10px 15px",
      borderRadius: "5px 5px 0px 0px",
    },
    gridPaddingMiddle: {
      padding: "10px 15px 10px 15px",
      borderRadius: "0px",
    },
    gridPaddingBottom: {
      padding: "10px 15px 10px 15px",
      borderRadius: "0px 0px 5px 5px",
    },
    userEmail: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "none",
      display: "block",
    },
    logout: {
      cursor: "pointer",
    },
    filter: {
      "& .MuiPaper-elevation": {
        width: "100%",
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
  }));
  const { classes } = useStyles();

  const {
    roleName,
    currentOption,
    allocationName,
    allocationLocations,
    allocationValfirms,
    allocationCountryId,
    allocationLocationText,
    allocationValfirmText,
    allocationNameSuggestion,
    allocationLocationSuggestion,
    allocationValfirmSuggestion,
    allocationSetStatus,
    allocKeywordValfirms,
    feeName,
    feeLocations,
    feeCountryId,
    feeLocationText,
    feeNameSuggestion,
    feeLocationSuggestion,
    feeSetStatus,
    panelName,
    panelNameSuggestion,
    panelCountryId,
    panelFunderText,
    panelFunderSuggestion,
    panelFunders,
    panelClientText,
    panelClientSuggestion,
    panelClients,
    panelValfirmText,
    panelValfirmSuggestion,
    panelValfirms,
    panelKeywordValfirms,
    panelPropTypeSearchText,
    panelPropTypeSuggestion,
    panelPropertyTypes,
    panelServiceTypeSearchText,
    panelServiceTypeSuggestion,
    panelServiceTypes,
    panelType,
    panelSetStatus,
    panelBracketMin,
    panelBracketMax,
    panelShowMore,
    panelAllocationNameText,
    panelAllocationNameSuggestion,
    panelAllocationCountryId,
    panelAllocationLocationText,
    panelAllocationLocationSuggestion,
    panelAllocationLocations,
    panelFeeNameText,
    panelFeeNameSuggestion,
    panelFeeCountryId,
    panelFeeLocationText,
    panelFeeLocationSuggestion,
    panelFeeLocations,
    isOpenFilter,
    firstName,
    lastName,
    email,
    username,
    initials,
  } = useSelector((state: RootState) => {
    return {
      roleName: state.tokenDetails.userContactDetails.roleName,
      currentOption: state.ui.searchOption,
      searchText: state.ui.searchText,
      suggestions: state.ui.suggestion,
      allocationName: state.allocation.filterOptions.nameText,
      allocationLocations: state.allocation.filterOptions.locations,
      allocationValfirms: state.allocation.filterOptions.valfirms,
      allocationCountryId: state.allocation.filterOptions.countryId,
      allocationLocationText: state.allocation.filterOptions.locationText,
      allocationValfirmText: state.allocation.filterOptions.valfirmText,
      allocationNameSuggestion: state.allocation.filterOptions.nameSuggestion,
      allocationLocationSuggestion:
        state.allocation.filterOptions.locationSuggestion,
      allocationValfirmSuggestion:
        state.allocation.filterOptions.valfirmSuggestion,
      allocationSetStatus: state.allocation.filterOptions.allocationSetStatus,
      allocKeywordValfirms: state.allocation.filterOptions.keywordValfirms,
      feeName: state.fee.filterOptions.nameText,
      feeLocations: state.fee.filterOptions.locations,
      feeCountryId: state.fee.filterOptions.countryId,
      feeLocationText: state.fee.filterOptions.locationText,
      feeNameSuggestion: state.fee.filterOptions.nameSuggestion,
      feeLocationSuggestion: state.fee.filterOptions.locationSuggestion,
      feeSetStatus: state.fee.filterOptions.feeSetStatus,
      panelName: state.panel.filterOptions.nameText,
      panelNameSuggestion: state.panel.filterOptions.nameSuggestion,
      panelCountryId: state.panel.filterOptions.countryId,
      panelFunderText: state.panel.filterOptions.funderText,
      panelFunderSuggestion: state.panel.filterOptions.funderSuggestion,
      panelFunders: state.panel.filterOptions.funders,
      panelClientText: state.panel.filterOptions.clientText,
      panelClientSuggestion: state.panel.filterOptions.clientSuggestion,
      panelClients: state.panel.filterOptions.clients,
      panelValfirmText: state.panel.filterOptions.valfirmText,
      panelValfirmSuggestion: state.panel.filterOptions.valfirmSuggestion,
      panelValfirms: state.panel.filterOptions.valfirms,
      panelKeywordValfirms: state.panel.filterOptions.keywordValfirms,
      panelPropTypeSearchText:
        state.panel.filterOptions.propTypeSearchText || "",
      panelPropTypeSuggestion: state.panel.filterOptions.propTypeSuggestion,
      panelPropertyTypes: state.panel.filterOptions.propertyTypes,
      panelServiceTypeSearchText:
        state.panel.filterOptions.serviceTypeSearchText,
      panelServiceTypeSuggestion:
        state.panel.filterOptions.serviceTypeSuggestion,
      panelServiceTypes: state.panel.filterOptions.serviceTypes,
      panelType: state.panel.filterOptions.panelType,
      panelSetStatus: state.panel.filterOptions.panelSetStatus,
      panelBracketMin: state.panel.filterOptions.bracketMin,
      panelBracketMax: state.panel.filterOptions.bracketMax,
      panelShowMore: state.panel.filterOptions.showMore,
      panelAllocationNameText: state.panel.filterOptions.allocationNameText,
      panelAllocationNameSuggestion:
        state.panel.filterOptions.allocationNameSuggestion,
      panelAllocationCountryId: state.panel.filterOptions.allocationCountryId,
      panelAllocationLocationText:
        state.panel.filterOptions.allocationLocationText,
      panelAllocationLocationSuggestion:
        state.panel.filterOptions.allocationLocationSuggestion,
      panelAllocationLocations: state.panel.filterOptions.allocationLocations,
      panelFeeNameText: state.panel.filterOptions.feeNameText,
      panelFeeNameSuggestion: state.panel.filterOptions.feeNameSuggestion,
      panelFeeCountryId: state.panel.filterOptions.feeCountryId,
      panelFeeLocationText: state.panel.filterOptions.feeLocationText,
      panelFeeLocationSuggestion:
        state.panel.filterOptions.feeLocationSuggestion,
      panelFeeLocations: state.panel.filterOptions.feeLocations,
      isOpenFilter:
        state.fee.filterOptions.isOpenFilter ||
        state.allocation.filterOptions.isOpenFilter ||
        state.panel.filterOptions.isOpenFilter ||
        false,
      firstName: state.tokenDetails.userContactDetails.firstName,
      lastName: state.tokenDetails.userContactDetails.lastName,
      email: state.tokenDetails.userContactDetails.emailAddress,
      username: state.tokenDetails.tokenContents.username,
      initials:
        `${state.tokenDetails.userContactDetails.firstName} ${state.tokenDetails.userContactDetails.lastName}`
          .split(" ")
          .map((n) => n[0])
          .join(""),
    };
  });

  const handleLogout = async () => {
    await dispatch(expireSession(username));
    window.location.replace(CLAUD_LOGOUT_URL);
  };

  const handleManageUsers = async () => {
    setAnchorEl(null);
    history.push("/users");
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const closeUserPref = () => {
    setAnchorEl(null);
  };

  const handleUserPrefClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const displayUserPrefs = Boolean(anchorEl);
  const userPrefPopper = displayUserPrefs ? "user-pref-popper" : undefined;

  const filterPrefPopper = isOpenFilter ? "filter-pref-popper" : undefined;

  const handleCloseFilter = () => {
    if (currentOption === "allocations") {
      dispatch(allocationClearFilter());
      dispatch(allocationOpenFilter(false));
    } else if (currentOption === "fees") {
      dispatch(feeClearFilter());
      dispatch(feeOpenFilter(false));
    } else {
      dispatch(panelClearFilter());
      dispatch(panelOpenFilter(false));
    }
  };

  const handleClearFilter = () => {
    if (currentOption === "allocations") {
      dispatch(allocationClearFilter());
    } else if (currentOption === "fees") {
      dispatch(feeClearFilter());
    } else {
      dispatch(panelClearFilter());
    }
  };

  const renderFilterPopup = () => {
    if (currentOption === "allocations") {
      return <AllocationFilterOptions />;
    } else if (currentOption === "fees") {
      return <FeeFilterOptions />;
    } else {
      return <PanelFilterOptions />;
    }
  };

  const handleApplyFilter = () => {
    if (currentOption === "allocations") {
      let valfirmKeyword = "";
      if (allocationValfirms.find((v: any) => v.id === 0) !== undefined) {
        valfirmKeyword = allocationValfirms
          .find((v: any) => v.id === 0)
          ["name"].replace("Add ", "")
          .replace('"', "")
          .replace('"', "");
      }
      const filter = {
        name: allocationName,
        locations: allocationLocations,
        valfirms: allocationValfirms,
        valfirmKeyword,
        status: allocationSetStatus,
        currentPage: 0,
        pageSize: 15,
        isFiltered: true,
        countryId: allocationCountryId,
        locationText: allocationLocationText,
        valfirmText: allocationValfirmText,
        nameSuggestion: allocationNameSuggestion,
        locationSuggestion: allocationLocationSuggestion,
        valfirmSuggestion: allocationValfirmSuggestion,
        keywordValfirms: allocKeywordValfirms,
      };
      dispatch(getAllocations(filter));
      dispatch(allocationOpenFilter(false));
    } else if (currentOption === "fees") {
      const filter = {
        name: feeName,
        locations: feeLocations,
        status: feeSetStatus,
        currentPage: 0,
        pageSize: 15,
        isFiltered: true,
        countryId: feeCountryId,
        locationText: feeLocationText,
        nameSuggestion: feeNameSuggestion,
        locationSuggestion: feeLocationSuggestion,
      };
      dispatch(getFees(filter));
      dispatch(feeOpenFilter(false));
      if (window.location.pathname !== "/fees") {
        history.push("/fees");
      }
    } else {
      let valfirmKeyword = "";
      if (panelValfirms.find((v: any) => v.id === 0) !== undefined) {
        valfirmKeyword = panelValfirms
          .find((v: any) => v.id === 0)
          ["name"].replace("Add ", "")
          .replace('"', "")
          .replace('"', "");
      }
      const filter = {
        name: panelName,
        status: panelSetStatus,
        currentPage: 0,
        pageSize: 15,
        isFiltered: true,
        nameSuggestion: panelNameSuggestion,
        countryId: panelCountryId,
        funderText: panelFunderText,
        funderSuggestion: panelFunderSuggestion,
        funders: panelFunders,
        clientText: panelClientText,
        clientSuggestion: panelClientSuggestion,
        clients: panelClients,
        valfirmText: panelValfirmText,
        valfirmSuggestion: panelValfirmSuggestion,
        valfirms: panelValfirms,
        valfirmKeyword,
        keywordValfirms: panelKeywordValfirms,
        propTypeSearchText: panelPropTypeSearchText,
        propTypeSuggestion: panelPropTypeSuggestion,
        propertyTypes: panelPropertyTypes,
        serviceTypeSearchText: panelServiceTypeSearchText,
        serviceTypeSuggestion: panelServiceTypeSuggestion,
        serviceTypes: panelServiceTypes,
        panelType: panelType,
        bracketMin: panelBracketMin,
        bracketMax: panelBracketMax,
        showMore: panelShowMore,
        allocationNameText: panelAllocationNameText,
        allocationNameSuggestion: panelAllocationNameSuggestion,
        allocationCountryId: panelAllocationCountryId,
        allocationLocationText: panelAllocationLocationText,
        allocationLocationSuggestion: panelAllocationLocationSuggestion,
        allocationLocations: panelAllocationLocations,
        feeNameText: panelFeeNameText,
        feeNameSuggestion: panelFeeNameSuggestion,
        feeCountryId: panelFeeCountryId,
        feeLocationText: panelFeeLocationText,
        feeLocationSuggestion: panelFeeLocationSuggestion,
        feeLocations: panelFeeLocations,
      };
      dispatch(getPanels(filter));
      dispatch(panelOpenFilter(false));
    }
  };

  return (
    <div data-testid="header-container" id="header-container">
      <AppBar
        ref={divRef}
        position="fixed"
        style={{
          height: "64px",
          background: "#38424A",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: "14px",
          paddingRight: "32px",
          zIndex: `${theme.zIndex.drawer + 1}`,
        }}
      >
        <Box
          id="header-logo"
          data-testid="header-logo"
          component="img"
          sx={{
            content: {
              xs: `url(${smallLogo})`, //img src from xs up to md
              md: `url(${logo})`, //img src from md and up
            },
          }}
          alt=""
          onClick={() => history.push("/panels")}
        />
        {(isVisible || isOpenFilter) && <Search />}
        <Avatar
          id="header-avatar"
          data-testid="header-avatar"
          aria-describedby={userPrefPopper}
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            border: "1px solid #FFFFFF",
          }}
          onClick={handleUserPrefClick}
        >
          {initials}
        </Avatar>
      </AppBar>

      <Popper
        id={userPrefPopper}
        data-testid={userPrefPopper}
        open={displayUserPrefs}
        anchorEl={anchorEl}
        className={classes.popper}
        modifiers={[
          {
            name: "preventOverflow",
            options: {
              padding: 25,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={closeUserPref}>
          <Box component="main" sx={{ border: "0px" }}>
            <Grid
              container
              sx={{ backgroundColor: "#fafafa" }}
              className={classes.gridPadding}
            >
              <Grid size={2}>
                <Avatar
                  id="user-pref-avatar"
                  data-testid="user-pref-avatar"
                  sx={{
                    background: "rgba(56, 66, 74)",
                    border: "1px solid #FFFFFF",
                  }}
                >
                  {initials}
                </Avatar>
              </Grid>
              <Grid size={10}>
                <div
                  id="user-pref-username"
                  data-testid="user-pref-username"
                  className="userName"
                >
                  {firstName || ""} {lastName || ""}
                </div>
                <div
                  id="user-pref-email"
                  data-testid="user-pref-email"
                  className={classes.userEmail}
                  title={email || ""}
                >
                  {email || ""}
                </div>
              </Grid>
            </Grid>

            {roleName === "Admin" ? (
              <Grid
                container
                sx={{ backgroundColor: "#ffffff" }}
                className={classes.gridPaddingMiddle}
              >
                <Grid size={12}>
                  <a
                    id="user-pref-manageusers"
                    data-testid="user-pref-manageusers"
                    className={classes.logout}
                    onClick={handleManageUsers}
                    role="link"
                  >
                    <span>Manage Users</span>
                  </a>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              container
              sx={{ backgroundColor: "#fafafa" }}
              className={classes.gridPaddingBottom}
            >
              <Grid size={12}>
                <a
                  id="user-pref-logout"
                  data-testid="user-pref-logout"
                  className={classes.logout}
                  onClick={handleLogout}
                  role="link"
                >
                  <span>LOG OUT</span>
                </a>
              </Grid>
            </Grid>
          </Box>
        </ClickAwayListener>
      </Popper>
      <Popper
        id={filterPrefPopper}
        data-testid={filterPrefPopper}
        open={isOpenFilter}
        anchorEl={isOpenFilter ? divRef.current : null}
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{
          zIndex: 1200,
          width: "100%",
          backgroundColor: "white",
          marginTop: "60px",
          borderRadius: "0px 0px 12px 12px",
          boxShadow:
            "box-shadow: 0px 5px 26px 4px #0000001F;\n" +
            "\n" +
            "box-shadow: 0px 14px 21px 2px #00000024;\n" +
            "\n" +
            "box-shadow: 0px 7px 9px -4px #00000070;\n",
          overflow: "auto",
        }}
        placement={"bottom-start"}
        transition
      >
        <Box position="sticky" top={0} sx={{ padding: "5px 10px 0px 0px" }}>
          <Grid
            container
            size={12}
            sx={{ height: "40px", padding: "0px 80px" }}
          >
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button
                id="btn-clear-all"
                data-testid="btn-clear-all"
                variant="text"
                onClick={(ev) => {
                  handleClearFilter();
                  ev.stopPropagation();
                }}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Paper
          sx={{
            maxHeight: "620px",
            overflow: "auto",
            borderBottom: `1px solid ${theme.colors.grey.lighter}`,
          }}
        >
          <Grid container size={12} justifyContent="center" alignItems="center">
            {renderFilterPopup()}
          </Grid>
        </Paper>
        <Box position="sticky" bottom={0} p={1} boxShadow={2}>
          <Grid
            container
            size={12}
            sx={{ marginBottom: "10px" }}
            justifyContent="center"
          >
            <Stack direction={"row"} spacing={0}>
              <Button
                id="header-cancel-button"
                data-testid="header-cancel-button"
                variant="text"
                sx={{ width: "70px" }}
                onClick={handleCloseFilter}
              >
                Cancel
              </Button>
              <Button
                id="header-search-button"
                data-testid="header-search-button"
                variant="contained"
                sx={{ width: "190px", marginLeft: "32px" }}
                onClick={handleApplyFilter}
              >
                Search
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Popper>
    </div>
  );
}
