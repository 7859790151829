import {
  CREATE_PANEL_SET,
  CHANGE_PANEL_TAB,
  FETCH_PANEL_SET,
  FETCH_PANEL_VERSION_DETAIL,
  FETCH_PANELS,
  SHOW_PANEL_SET_FORM,
  ADD_FUNDER_PANEL_FORM,
  ADD_CLIENT_PANEL_FORM,
  DELETE_FUNDER_PANEL_FORM,
  DELETE_CLIENT_PANEL_FORM,
  ADD_PROP_TYPE_PANEL_FORM,
  DELETE_PROP_TYPE_PANEL_FORM,
  ADD_SERVICE_TYPE_PANEL_FORM,
  DELETE_SERVICE_TYPE_PANEL_FORM,
  UPDATE_PANEL_NAME_PANEL_FORM,
  UPDATE_PANEL_NOTES_PANEL_FORM,
  UPDATE_PANEL_TYPE_PANEL_FORM,
  UPDATE_PANEL_ORDER_PANEL_FORM,
  UPDATE_USE_RULEX_PANEL_FORM,
  UPDATE_BRACKET_MIN_PANEL_FORM,
  UPDATE_BRACKET_MAX_PANEL_FORM,
  EDIT_PANEL_SET,
  RESET_PAGINATION,
  UPDATE_ALLOCATION_TEXT_CHANGE,
  UPDATE_PANEL_ALLOCATION,
  FETCH_PANEL_ACTIVATION_LOGS,
  FETCH_LATEST_PANEL_ACTIVATION_LOG,
  CHANGE_PANEL_VERSION_ID,
  UPDATE_PANEL_FEE,
  UPDATE_FEE_TEXT_CHANGE,
  FETCH_PANEL_VERSIONS,
  UPDATE_PANEL_VERSION_NOTES,
  COPY_PANEL_VERSION,
  SCHEDULE_PANEL_VERSION_ACTIVATION,
  CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION,
  UPDATE_PANEL_IN_USE_PANEL_FORM,
  DISCARD_PANEL_VERSION,
  FETCH_VERSIONS_COMPARISON_DETAILS,
  RESET_VERSIONS_TO_COMPARE,
  SET_VERSIONS_TO_COMPARE,
  SHOW_VERSIONS_COMPARISON,
  FILTER_NAME_CHANGE,
  CLEAR_ALL_FILTER,
  OPEN_FILTER,
  COPY_FILTER,
  UPDATE_ORG_TEXT_CHANGE_FIlTER,
  ADD_PROP_TYPE_FILTER,
  DELETE_PROP_TYPE_FILTER,
  ADD_SERVICE_TYPE_FILTER,
  DELETE_SERVICE_TYPE_FILTER,
  ADD_VALFIRM_FILTER,
  DELETE_VALFIRM_FILTER,
  ADD_CLIENT_FILTER,
  DELETE_CLIENT_FILTER,
  ADD_FUNDER_FILTER,
  DELETE_FUNDER_FILTER,
  UPDATE_PROP_TYPE_TEXT_CHANGE_FILTER,
  UPDATE_SERVICE_TYPE_TEXT_CHANGE_FILTER,
  UPDATE_PANEL_TYPE_FILTER,
  UPDATE_BRACKET_MIN_FILTER,
  UPDATE_BRACKET_MAX_FILTER,
  UPDATE_SHOW_MORE_FILTER,
  ADD_ALLOCATION_LOCATION_FILTER,
  DELETE_ALLOCATION_LOCATION_FILTER,
  CHANGE_ALLOCATION_FILTER_COUNTRY,
  FILTER_ALLOCATION_NAME_CHANGE,
  FILTER_ALLOCATION_LOCATION_CHANGE,
  ADD_FEE_LOCATION_FILTER,
  DELETE_FEE_LOCATION_FILTER,
  CHANGE_FEE_FILTER_COUNTRY,
  FILTER_FEE_NAME_CHANGE,
  FILTER_FEE_LOCATION_CHANGE,
  ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION,
  EXPORT_PANELS,
  UPDATE_PANEL_STATUS_FILTER,
  SET_PANEL_TO_BULK_UPDATE,
  OPEN_BULK_UPDATE,
  BULK_UPDATE_BACK_TO_PREVIOUS,
  BULK_UPDATE_SELECT_VERSION,
  BULK_UPDATE_PREVIEW_DATA,
  CANCEL_BULK_UPDATE,
  FETCH_BULK_UPDATE_INFO,
  UPDATE_BULK_UPDATE_SELECTED_VERSION,
  SET_BULK_UPDATE_CHANGE_TYPE,
  FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
  REPLACE_VALFIRM_FETCH_EXISTING_VALFIRM_SUGGESTION,
  REPLACE_VALFIRM_SET_EXISTING_VALFIRM,
  REPLACE_VALFIRM_NEW_VALFIRM_TEXT_CHANGE,
  REPLACE_VALFIRM_SET_NEW_VALFIRM,
  SET_DISTRIBUTION_METHOD,
  SET_IS_INCLUDE_BACKUP,
  ADD_VALFIRM_SET_ALLOCATION,
  ADD_VALFIRM_SET_VALFIRM,
  ADD_VALFIRM_TEXT_CHANGE,
  REMOVE_VALFIRM_FETCH_SUGGESTION,
  REMOVE_VALFIRM_SET_VALFIRM,
  CHANGE_ALLOCATION_FETCH_VALFIRM_SUGGESTION,
  CHANGE_ALLOCATION_SET_VALFIRM,
  CHANGE_ALLOCATION_SET_BY,
  CHANGE_ALLOCATION_SET_ALLOCATION,
  BULK_UPDATE_SAVE_AS_DRAFT,
  BULK_UPDATE_SELECT_LOCATIONS,
  FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
  UPDATE_BULK_UPDATE_LOCATION_TEXT,
  ADD_BULK_UPDATE_LOCATION,
  DELETE_BULK_UPDATE_LOCATION,
  DELETE_ALL_BULK_UPDATE_LOCATION,
  ADD_ALL_BULK_UPDATE_LOCATION,
  SET_SELECTED_PANEL,
  OPEN_ASSOCIATED_PANELS,
  FETCH_ASSOCIATED_PANELS,
  ADD_PREVIEW_PANEL_ID,
} from "../staticSagas/sagaPanel";

export function getPanels(filter) {
  return {
    type: FETCH_PANELS,
    payload: {
      filter,
    },
  };
}

export function resetPagination() {
  return {
    type: RESET_PAGINATION,
  };
}

export function showPanelSetForm(open, isNewPanel) {
  return {
    type: SHOW_PANEL_SET_FORM,
    payload: {
      open,
      isNewPanel,
    },
  };
}

export function createPanelSet(panelSetForm) {
  return {
    type: CREATE_PANEL_SET,
    payload: {
      panelSetForm,
    },
  };
}

export function changePanelTab(index) {
  return {
    type: CHANGE_PANEL_TAB,
    index,
  };
}

export function getPanelSet(panelId) {
  return { type: FETCH_PANEL_SET, panelId };
}

export function getPanelVersionDetail(panelId, panelVersionId) {
  return {
    type: FETCH_PANEL_VERSION_DETAIL,
    payload: {
      panelId,
      panelVersionId,
    },
  };
}

export function deleteFunderPanelForm(orgId) {
  return {
    type: DELETE_FUNDER_PANEL_FORM,
    payload: {
      orgId,
    },
  };
}

export function deleteClientPanelForm(orgId) {
  return {
    type: DELETE_CLIENT_PANEL_FORM,
    payload: {
      orgId,
    },
  };
}

export function addFunderPanelForm(orgId, orgName) {
  return {
    type: ADD_FUNDER_PANEL_FORM,
    payload: {
      orgId,
      orgName,
    },
  };
}

export function addClientPanelForm(orgId, orgName) {
  return {
    type: ADD_CLIENT_PANEL_FORM,
    payload: {
      orgId,
      orgName,
    },
  };
}

export function addPropTypePanelForm(propertyTypeId, propertyTypeName) {
  return {
    type: ADD_PROP_TYPE_PANEL_FORM,
    payload: {
      propertyTypeId,
      propertyTypeName,
    },
  };
}
export function deletePropTypePanelForm(propertyTypeId) {
  return {
    type: DELETE_PROP_TYPE_PANEL_FORM,
    propertyTypeId,
  };
}

export function addServiceTypePanelForm(serviceId, serviceName) {
  return {
    type: ADD_SERVICE_TYPE_PANEL_FORM,
    payload: {
      serviceId,
      serviceName,
    },
  };
}

export function deleteServiceTypePanelForm(serviceId) {
  return {
    type: DELETE_SERVICE_TYPE_PANEL_FORM,
    serviceId,
  };
}

export function updatePanelNamePanelForm(panelName) {
  return {
    type: UPDATE_PANEL_NAME_PANEL_FORM,
    payload: panelName,
  };
}

export function updatePanelNotesPanelForm(panelNotes) {
  return {
    type: UPDATE_PANEL_NOTES_PANEL_FORM,
    payload: panelNotes,
  };
}

export function updatePanelTypePanelForm(panelType) {
  return {
    type: UPDATE_PANEL_TYPE_PANEL_FORM,
    payload: panelType,
  };
}

export function updatePanelOrderPanelForm(panelOrder) {
  return {
    type: UPDATE_PANEL_ORDER_PANEL_FORM,
    payload: panelOrder,
  };
}

export function updateUseRulexPanelForm(useRuleEx) {
  return {
    type: UPDATE_USE_RULEX_PANEL_FORM,
    payload: useRuleEx,
  };
}

export function updatePanelInUsePanelForm(statusBool) {
  return {
    type: UPDATE_PANEL_IN_USE_PANEL_FORM,
    payload: statusBool,
  };
}

export function updateBracketMinPanelForm(bracketMin) {
  return {
    type: UPDATE_BRACKET_MIN_PANEL_FORM,
    payload: bracketMin,
  };
}

export function updateBracketMaxPanelForm(bracketMax) {
  return {
    type: UPDATE_BRACKET_MAX_PANEL_FORM,
    payload: bracketMax,
  };
}

export function editPanelSet(panelSetForm) {
  return {
    type: EDIT_PANEL_SET,
    payload: {
      panelSetForm,
    },
  };
}

export function updateAllocationTextChange(text) {
  return {
    type: UPDATE_ALLOCATION_TEXT_CHANGE,
    text,
  };
}

export function updatePanelAllocation(id, name, panelId, panelVersionId) {
  return {
    type: UPDATE_PANEL_ALLOCATION,
    payload: {
      id,
      name,
      panelId,
      panelVersionId,
    },
  };
}

export function getPanelActivationLogs(
  panelSetId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) {
  return {
    type: FETCH_PANEL_ACTIVATION_LOGS,
    payload: {
      panelSetId,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
    },
  };
}

export function getLatestPanelActivationLog(panelSetId) {
  return { type: FETCH_LATEST_PANEL_ACTIVATION_LOG, panelSetId };
}

export function changePanelVersionId(id) {
  return {
    type: CHANGE_PANEL_VERSION_ID,
    id,
  };
}

export function getPanelVersions(panelSetId, currentPage, pageSize) {
  return {
    type: FETCH_PANEL_VERSIONS,
    payload: {
      panelSetId,
      currentPage,
      pageSize,
    },
  };
}

export function updatePanelVersionNotes(panelSetId, panelVersionId, notes) {
  return {
    type: UPDATE_PANEL_VERSION_NOTES,
    payload: {
      panelSetId,
      panelVersionId,
      notes,
    },
  };
}

export function updateFeeTextChange(text) {
  return {
    type: UPDATE_FEE_TEXT_CHANGE,
    text,
  };
}

export function updatePanelFee(feeId, name, panelId, panelVersionId) {
  return {
    type: UPDATE_PANEL_FEE,
    payload: {
      feeId,
      name,
      panelId,
      panelVersionId,
    },
  };
}

export function copyPanelVersion(panelId, panelVersionId) {
  return {
    type: COPY_PANEL_VERSION,
    payload: {
      panelId,
      panelVersionId,
    },
  };
}

export function schedulePanelVersionActivation(
  panelId,
  panelVersionId,
  activateDate
) {
  return {
    type: SCHEDULE_PANEL_VERSION_ACTIVATION,
    payload: {
      panelId,
      panelVersionId,
      activateDate,
    },
  };
}

export function cancelPanelActivationLog(
  panelId,
  panelActivationLogId,
  panelVersionId
) {
  return {
    type: CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION,
    payload: {
      panelId,
      panelActivationLogId,
      panelVersionId,
    },
  };
}

export function discardPanelVersion(panelSetId, panelSetRevId) {
  return {
    type: DISCARD_PANEL_VERSION,
    payload: {
      panelSetId,
      panelSetRevId,
    },
  };
}

export function setVersionsToCompare(panelVersionId, selected, count) {
  return {
    type: SET_VERSIONS_TO_COMPARE,
    payload: {
      panelVersionId,
      selected,
      count,
    },
  };
}

export function clearVersionsToCompare() {
  return {
    type: RESET_VERSIONS_TO_COMPARE,
  };
}

export function showVersionsCompare(show) {
  return {
    type: SHOW_VERSIONS_COMPARISON,
    show,
  };
}

export function getPanelVersionComparisonDetails(panelId, versionsList) {
  return {
    type: FETCH_VERSIONS_COMPARISON_DETAILS,
    payload: {
      panelId,
      versionsList,
    },
  };
}

export function filterNameChange(text) {
  return { type: FILTER_NAME_CHANGE, text };
}

export function clearFilter() {
  return {
    type: CLEAR_ALL_FILTER,
  };
}

export function openFilter(isOpen) {
  return {
    type: OPEN_FILTER,
    isOpen,
  };
}

export function copyFilter() {
  return {
    type: COPY_FILTER,
  };
}

export function updateOrgTextChangeFilter(text, orgTypeOption) {
  return {
    type: UPDATE_ORG_TEXT_CHANGE_FIlTER,
    payload: {
      orgTypeOption,
      text,
    },
  };
}

export function deleteFunderFilter(id) {
  return {
    type: DELETE_FUNDER_FILTER,
    payload: {
      id,
    },
  };
}

export function addFunderFilter(id, name) {
  return {
    type: ADD_FUNDER_FILTER,
    payload: {
      id,
      name,
    },
  };
}

export function deleteClientFilter(id) {
  return {
    type: DELETE_CLIENT_FILTER,
    payload: {
      id,
    },
  };
}

export function addClientFilter(id, name) {
  return {
    type: ADD_CLIENT_FILTER,
    payload: {
      id,
      name,
    },
  };
}

export function deleteValfirmFilter(id) {
  return {
    type: DELETE_VALFIRM_FILTER,
    payload: {
      id,
    },
  };
}

export function addValfirmFilter(id, name) {
  return {
    type: ADD_VALFIRM_FILTER,
    payload: {
      id,
      name,
    },
  };
}

export function updatePropTypeTextChangeFilter(text) {
  return {
    type: UPDATE_PROP_TYPE_TEXT_CHANGE_FILTER,
    text,
  };
}

export function updateServiceTypeTextChangeFilter(text) {
  return {
    type: UPDATE_SERVICE_TYPE_TEXT_CHANGE_FILTER,
    text,
  };
}

export function addPropTypeFilter(id, name) {
  return {
    type: ADD_PROP_TYPE_FILTER,
    payload: {
      id,
      name,
    },
  };
}
export function deletePropTypeFilter(id) {
  return {
    type: DELETE_PROP_TYPE_FILTER,
    id,
  };
}

export function addServiceTypeFilter(id, name) {
  return {
    type: ADD_SERVICE_TYPE_FILTER,
    payload: {
      id,
      name,
    },
  };
}

export function deleteServiceTypeFilter(id) {
  return {
    type: DELETE_SERVICE_TYPE_FILTER,
    id,
  };
}

export function updatePanelTypeFilter(panelType) {
  return {
    type: UPDATE_PANEL_TYPE_FILTER,
    payload: panelType,
  };
}

export function updatePanelStatusFilter(panelStatus) {
  return {
    type: UPDATE_PANEL_STATUS_FILTER,
    payload: panelStatus,
  };
}

export function updateBracketMinFilter(bracketMin) {
  return {
    type: UPDATE_BRACKET_MIN_FILTER,
    payload: bracketMin,
  };
}

export function updateBracketMaxFilter(bracketMax) {
  return {
    type: UPDATE_BRACKET_MAX_FILTER,
    payload: bracketMax,
  };
}
export function updateShowMore(showMore) {
  return {
    type: UPDATE_SHOW_MORE_FILTER,
    payload: showMore,
  };
}

export function addAllocationFilterLocation(location) {
  return {
    type: ADD_ALLOCATION_LOCATION_FILTER,
    location,
  };
}

export function deleteAllocationFilterLocation(id, countryId, type) {
  return {
    type: DELETE_ALLOCATION_LOCATION_FILTER,
    payload: {
      id,
      countryId,
      type,
    },
  };
}

export function changeAllocationFilterCountry(countryId) {
  return {
    type: CHANGE_ALLOCATION_FILTER_COUNTRY,
    countryId,
  };
}

export function filterAllocationNameChange(text) {
  return { type: FILTER_ALLOCATION_NAME_CHANGE, text };
}

export function filterAllocationLocationChange(text, countryId) {
  return {
    type: FILTER_ALLOCATION_LOCATION_CHANGE,
    text,
    countryId,
  };
}

export function addFeeFilterLocation(location) {
  return {
    type: ADD_FEE_LOCATION_FILTER,
    location,
  };
}

export function deleteFeeFilterLocation(id, countryId, type) {
  return {
    type: DELETE_FEE_LOCATION_FILTER,
    payload: {
      id,
      countryId,
      type,
    },
  };
}

export function changeFeeFilterCountry(countryId) {
  return {
    type: CHANGE_FEE_FILTER_COUNTRY,
    countryId,
  };
}

export function filterFeeNameChange(text) {
  return { type: FILTER_FEE_NAME_CHANGE, text };
}

export function filterFeeLocationChange(text, countryId) {
  return {
    type: FILTER_FEE_LOCATION_CHANGE,
    text,
    countryId,
  };
}

export function acknowledgePanelActivationLog(
  panelId,
  panelActivationLogId,
  panelVersionId
) {
  return {
    type: ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION,
    payload: {
      panelId,
      panelActivationLogId,
      panelVersionId,
    },
  };
}

export function exportPanels(filter) {
  return {
    type: EXPORT_PANELS,
    payload: {
      filter,
    },
  };
}

export function setPanelToBulkUpdate(panelIds) {
  return {
    type: SET_PANEL_TO_BULK_UPDATE,
    payload: {
      panelIds,
    },
  };
}

export function setSelectedPanel(panel) {
  return {
    type: SET_SELECTED_PANEL,
    panel,
  };
}

export function openBulkUpdate(isOpen, triggerFrom, panelCount) {
  let objAttempt = {
    type: OPEN_BULK_UPDATE,
    isOpen,
  };
  if (isOpen) {
    objAttempt.meta = {
      analytics: {
        triggerFrom,
        panelCount,
      },
    };
  }
  return objAttempt;
}

export function setSelectVersion(allocationVersionCount) {
  return {
    type: BULK_UPDATE_SELECT_VERSION,
    meta: {
      analytics: {
        allocationVersionCount,
      },
    },
  };
}

export function setPreviewData(data) {
  return {
    type: BULK_UPDATE_PREVIEW_DATA,
    payload: data,
  };
}

export function setSelectLocations(changeType, distributionMethod) {
  return {
    type: BULK_UPDATE_SELECT_LOCATIONS,
    meta: {
      analytics: {
        changeType,
        distributionMethod,
      },
    },
  };
}

export function backToPreviousStep(step, stepData) {
  return {
    type: BULK_UPDATE_BACK_TO_PREVIOUS,
    step,
    meta: {
      analytics: stepData,
    },
  };
}

export function cancelBulkUpdate(cancelStep, cancelStepData) {
  return {
    type: CANCEL_BULK_UPDATE,
    meta: {
      analytics: {
        cancelStep,
        cancelStepData,
      },
    },
  };
}

export function getBulkUpdateInfo(panelSets) {
  return { type: FETCH_BULK_UPDATE_INFO, panelSets };
}

export function updateSelectedVersion(allocationPanelId, selectedVersion) {
  return {
    type: UPDATE_BULK_UPDATE_SELECTED_VERSION,
    allocationPanelId,
    selectedVersion,
  };
}

export function setBulkUpdateChangeType(bulkUpdateChangeType) {
  return {
    type: SET_BULK_UPDATE_CHANGE_TYPE,
    payload: bulkUpdateChangeType,
  };
}
export function getExistingValfirmsByRevIds(allocRevIds) {
  return {
    type: FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
    allocRevIds,
  };
}
export function replaceValfirmUpdateExistingValfirmTextChange(text) {
  return {
    type: REPLACE_VALFIRM_FETCH_EXISTING_VALFIRM_SUGGESTION,
    text,
  };
}
export function replaceValfirmSetExistingValfirm(orgId, orgName) {
  return {
    type: REPLACE_VALFIRM_SET_EXISTING_VALFIRM,
    orgId,
    orgName,
  };
}
export function replaceValfirmUpdateNewValfirmTextChange(
  text,
  excludeValfirmId
) {
  return {
    type: REPLACE_VALFIRM_NEW_VALFIRM_TEXT_CHANGE,
    text,
    excludeValfirmId,
  };
}

export function replaceValfirmSetNewValfirm(orgId, orgName) {
  return {
    type: REPLACE_VALFIRM_SET_NEW_VALFIRM,
    orgId,
    orgName,
  };
}

export function setDistributionMethod(method) {
  return {
    type: SET_DISTRIBUTION_METHOD,
    payload: method,
  };
}

export function setIsIncludeBackup(includeBackup) {
  return {
    type: SET_IS_INCLUDE_BACKUP,
    payload: includeBackup,
  };
}

export function setAddValfirmAllocation(allocation) {
  return {
    type: ADD_VALFIRM_SET_ALLOCATION,
    payload: allocation,
  };
}

export function addValfirmTextChange(text) {
  return {
    type: ADD_VALFIRM_TEXT_CHANGE,
    text,
  };
}

export function addValfirmSetValfirm(orgId, orgName) {
  return {
    type: ADD_VALFIRM_SET_VALFIRM,
    orgId,
    orgName,
  };
}

export function removeValfirmTextChange(text) {
  return {
    type: REMOVE_VALFIRM_FETCH_SUGGESTION,
    text,
  };
}
export function removeValfirmSetValfirm(orgId, orgName) {
  return {
    type: REMOVE_VALFIRM_SET_VALFIRM,
    orgId,
    orgName,
  };
}

export function changeAllocationValfirmTextChange(text) {
  return {
    type: CHANGE_ALLOCATION_FETCH_VALFIRM_SUGGESTION,
    text,
  };
}
export function changeAllocationSetValfirm(orgId, orgName) {
  return {
    type: CHANGE_ALLOCATION_SET_VALFIRM,
    orgId,
    orgName,
  };
}

export function changeAllocationSetBy(value) {
  return {
    type: CHANGE_ALLOCATION_SET_BY,
    payload: value,
  };
}

export function changeAllocationSetAllocation(allocation) {
  return {
    type: CHANGE_ALLOCATION_SET_ALLOCATION,
    payload: allocation,
  };
}

export function setBulkUpdateSaveAsDraft(data) {
  return {
    type: BULK_UPDATE_SAVE_AS_DRAFT,
    payload: data,
  };
}

export function getAllocationsLocations(allocRevIds, valfirmId) {
  return {
    type: FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
    allocRevIds,
    valfirmId,
  };
}

export function updateBulkUpdateLocationText(text) {
  return {
    type: UPDATE_BULK_UPDATE_LOCATION_TEXT,
    text,
  };
}

export function addBulkUpdateLocation(location) {
  return {
    type: ADD_BULK_UPDATE_LOCATION,
    location,
  };
}

export function deleteBulkUpdateLocation(id, locationType) {
  return {
    type: DELETE_BULK_UPDATE_LOCATION,
    id,
    locationType,
  };
}

export function addAllBulkUpdateLocation(locations) {
  return {
    type: ADD_ALL_BULK_UPDATE_LOCATION,
    locations,
  };
}

export function deleteAllBulkUpdateLocation(locations) {
  return {
    type: DELETE_ALL_BULK_UPDATE_LOCATION,
    locations,
  };
}

export function getAssociatedPanels(
  allocationId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) {
  return {
    type: FETCH_ASSOCIATED_PANELS,
    payload: {
      allocationId,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
    },
  };
}

export function openAssociatedPanels(isOpen) {
  return {
    type: OPEN_ASSOCIATED_PANELS,
    isOpen,
  };
}

export function addReviewPanelId(id) {
  return {
    type: ADD_PREVIEW_PANEL_ID,
    id,
  };
}
