import React, { useCallback, useEffect } from "react";
import "./App.css";
import Login from "./login/Login";
import Allocations from "./allocation/Allocations";
import Fees from "./fee/Fees";
import Panels from "./panel/Panels";
import AllocationSet from "./allocation/AllocationSet";
import FeeSet from "./fee/FeeSet";
import PanelSet from "./panel/PanelSet";
import Users from "./user/Users";
import { Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/staticReducers/reducers";
import moment from "moment";
import {
  closeFailAuthentication,
  expireSession,
  refreshToken as actionRefreshToken,
  setTimerForExpiryModal,
} from "../store/action/actionToken";
import Modal from "./modal/CustomModal";
import { useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  Box,
  Button,
} from "@mui/material";
import { CLTheme as theme } from "./theme/CLTheme";
import Header from "./header/Header";
import { fetchFeatureFlags } from "../store/action/actionLaunchDarkly";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isUserLoggedIn,
    tokenExpiry,
    refreshToken,
    isSessionExpiryModalOpen,
    authenticationError,
    roleName,
    userName,
  } = useSelector((state: RootState) => {
    return {
      isUserLoggedIn: !!state.tokenDetails.claudToken.access_token,
      refreshToken: state.tokenDetails.claudToken.refresh_token,
      tokenExpiry: state.tokenDetails.tokenContents.exp || null,
      isSessionExpiryModalOpen: state.tokenDetails.showSessionExpiryModal,
      authenticationError: state.tokenDetails.authenticationError,
      roleName: state.tokenDetails.userContactDetails.roleName,
      userName: state.tokenDetails.tokenContents.username || "",
    };
  });
  const ldClient = useLDClient();
  const flags = useFlags();

  React.useEffect(() => {
    if (userName !== "") {
      ldClient?.identify({ key: userName });
    }
  }, [ldClient, userName]);

  React.useEffect(() => {
    dispatch(fetchFeatureFlags(flags));
  }, [flags, dispatch]);

  const onUserAction = useCallback(() => {
    const expiryTime = moment.unix(tokenExpiry);
    const currentTime = moment();
    const refreshTime = expiryTime.clone().subtract("30", "minute");
    if (refreshTime.diff(currentTime) <= 0) {
      dispatch(
        actionRefreshToken(refreshToken, () => {
          document.body.removeEventListener("click", onUserAction);
        })
      );
    }
  }, [tokenExpiry, refreshToken, dispatch]);

  useEffect(() => {
    const expiryTime = moment.unix(tokenExpiry);
    const currentTime = moment();

    if (isUserLoggedIn && !isSessionExpiryModalOpen) {
      document.body.addEventListener("click", onUserAction);
      dispatch(setTimerForExpiryModal(expiryTime.diff(currentTime)));
    } else if (isSessionExpiryModalOpen) {
      document.body.removeEventListener("click", onUserAction);
    }
    return () => {
      document.body.removeEventListener("click", onUserAction);
    };
  }, [
    isUserLoggedIn,
    tokenExpiry,
    isSessionExpiryModalOpen,
    onUserAction,
    dispatch,
  ]);

  if (
    roleName != "Admin" &&
    isUserLoggedIn &&
    (history.location.pathname === "/users" ||
      history.location.pathname === "/fees" ||
      history.location.pathname.match(/^\/fees\/*/) ||
      history.location.pathname === "/allocations" ||
      history.location.pathname.match(/^\/allocations\/*/))
  ) {
    history.push("/panels");
  }

  const redirectTo = () => {
    dispatch(closeFailAuthentication());
    if (roleName === "Admin") {
      const path = "/" + history.location.pathname.split("/")[1];
      history.push(path);
    } else {
      history.push("/panels");
    }
  };

  return (
    <div className="app">
      {isUserLoggedIn ? (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>
              <Route exact path="/" component={Panels} />
              <Route exact path="/allocations" component={Allocations} />
              <Route exact path="/panels" component={Panels} />
              <Route exact path="/fees" component={Fees} />
              <Route exact path="/users" component={Users} />
              <Route
                exact
                path="/allocations/:allocationId"
                render={({ match }) => (
                  <AllocationSet
                    allocationId={match.params.allocationId as string}
                  />
                )}
              />
              <Route
                exact
                path="/fees/:feeId"
                render={({ match }) => (
                  <FeeSet feeId={match.params.feeId as string} />
                )}
              />
              <Route
                exact
                path="/panels/:panelId"
                render={({ match }) => (
                  <PanelSet panelId={match.params.panelId as string} />
                )}
              />
              <Modal
                id="session-expiry-modal"
                open={!!isSessionExpiryModalOpen}
                onClose={() => dispatch(expireSession())}
                title="Your session has expired due to inactivity"
              >
                <Button
                  data-testid="btn-re-login"
                  id="btn-re-login"
                  size="medium"
                  variant="contained"
                  onClick={() => dispatch(expireSession())}
                  sx={{
                    color: theme.palette.primary.light,
                    padding: "6px 32px",
                    marginTop: "14px",
                  }}
                >
                  Login Again
                </Button>
              </Modal>
            </div>
            <Box sx={{ display: "flex" }}>
              <Header />
            </Box>
          </ThemeProvider>
        </StyledEngineProvider>
      ) : (
        <Login />
      )}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Modal
            id="access-denied-modal"
            open={authenticationError && !isSessionExpiryModalOpen}
            title="Access Denied"
            description={`You are not authorised to view this data. \n Please contact CoreLogic to update your access.`}
          >
            <Button
              data-testid="btn-ok"
              id="btn-ok"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => redirectTo()}
            >
              Ok
            </Button>
          </Modal>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
