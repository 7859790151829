import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import { makeStyles } from "tss-react/mui";
import { RootState } from "../../store/staticReducers/reducers";
import {
  changeFeeTab,
  changeFeeVersionId,
  getFeeVersions,
  getLatestFeeActivationLog,
  updateFeeVersionNotes,
  discardFeeVersion,
  exportFeeVersion,
  copyFeeVersion,
  scheduleFeeVersionActivation,
  setVersionsToCompare,
} from "../../store/action/actionFee";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import {
  Button,
  Checkbox,
  FormControl,
  Grid2 as Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { FileDownloadOutlined, RemoveRedEye } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import copyEditOutlined from "../../img/copyEditOutlined.svg";
import Typography from "@mui/material/Typography";
import PanelExDialog from "../shared/PanelExDialog";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import DialogContentText from "@mui/material/DialogContentText";
import PanelExActionSnackbar from "../shared/PanelExActionSnackbar";
import FeeVersionComparison from "../fee/FeeVersionComparison";

interface FeeVersionsProps {
  feeVersionId: number;
}

export default function FeeVersions(props: FeeVersionsProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const timezone = moment.tz.guess();
  const timezoneAbbr = moment().tz(timezone).format("z");
  const feeVersionId = props.feeVersionId;

  const [openNotes, setOpenNotes] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(0);
  const [versionNote, setVersionNotes] = useState("");
  const [versionStatus, setVersionStatus] = useState("");
  const [openDiscard, setOpenDiscard] = useState(false);
  const [openActivation, setOpenActivation] = useState(false);
  const [activateDate, setActivateDate] = React.useState<Dayjs | undefined>(
    dayjs()
  );
  const [minDateTime, setMinDateTime] = React.useState<Dayjs | undefined>(
    dayjs().set("second", 0).set("millisecond", 0)
  );

  const useStyles = makeStyles()(() => ({
    versionsHeader: {
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.15px",
        color: "rgba(0,0,0,0.8)",
      },
    },
    versionId: {
      color: theme.palette.primary.main,
    },
  }));
  const { classes } = useStyles();

  const getVersionStatus = (
    feePanelRevId: number,
    editable: boolean,
    schedularRun: boolean,
    discard: boolean,
    activateStatus?: string
  ) => {
    let status = "Archived";
    if (feeRevId === feePanelRevId) {
      status = "Active";
    } else if (editable && !schedularRun && !discard) {
      if (activateStatus === "Queued") {
        status = "Scheduled";
      } else if (
        activateStatus === "Error" &&
        activateRevId === feePanelRevId
      ) {
        status = "Failed";
      } else {
        status = "Draft";
      }
    }
    return status;
  };

  const {
    feeSet,
    feeVersions,
    versionLoading,
    rowCount,
    currentPage,
    pageSize,
    versionCompare,
    showVersionCompare,
    requestedActivationLog,
    readOnly,
  } = useSelector((state: RootState) => {
    return {
      feeSet: state.fee.feeSet,
      feeVersions: state.fee.feeVersion.feeVersions,
      versionLoading: state.fee.feeVersion.loading,
      rowCount: state.fee.feeVersion.feeVersionCount || 0,
      currentPage: state.fee.feeVersion.currentPage || 0,
      pageSize: state.fee.feeVersion.pageSize || 15,
      versionCompare: state.fee.feeVersion.versionCompare || [],
      showVersionCompare: state.fee.feeVersion.showVersionCompare || false,
      requestedActivationLog: state.fee.latestFeeActivationLog,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const {
    scheduled: activateVersionDate,
    status: activateStatus,
    feePanelRevId: activateRevId,
  } = requestedActivationLog;
  const feeId = feeSet?.feePanelId;
  const feeRevId = feeSet?.feeRevId;
  const hasEditable = !!feeSet.hasEditable;
  const feeSetName = feeSet?.name;
  useEffect(() => {
    fetchFeeVersions(feeId, currentPage, pageSize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (feeId) {
      dispatch(getLatestFeeActivationLog(feeId));
    }
  }, [dispatch, feeId]);

  const handleOpenActivation = (feeRevId: number) => {
    setSelectedVersion(feeRevId);
    setMinDateTime(dayjs().set("second", 0).set("millisecond", 0));
    setActivateDate(dayjs().set("second", 0).set("millisecond", 0));
    setOpenActivation(true);
  };

  const handleExport = (feePanelRevId: number) => {
    dispatch(exportFeeVersion(feeId, feePanelRevId, feeSetName));
  };

  const columns: GridColDef[] = [
    {
      field: "compare",
      headerName: "",
      width: 75,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const feePanelRevId = params.row.feePanelRevId;
        const index =
          params.api.getRowIndexRelativeToVisibleRows(feePanelRevId);
        const compareSelected =
          versionCompare.filter((v: any) => v === feePanelRevId).length > 0;
        return (
          <>
            <Checkbox
              id={`checkbox-compare-${index}`}
              data-testid={`checkbox-compare-${index}`}
              checked={compareSelected}
              disabled={versionCompare.length === 2 && !compareSelected}
              onChange={(ev) =>
                handleCompare(ev, feePanelRevId, versionCompare.length)
              }
            />
          </>
        );
      },
    },
    {
      field: "feePanelRevId",
      headerName: "ID",
      width: 90,
      editable: false,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={`Load to preview v${params.row.feePanelRevId}`}
              arrow
            >
              <span
                data-testid={`id-${params.row.feePanelRevId}`}
                id={`id-${params.row.feePanelRevId}`}
                className={classes.versionId}
                onClick={() => {
                  dispatch(changeFeeVersionId(params.row.feePanelRevId));
                  dispatch(changeFeeTab(0));
                }}
              >
                {params.row.feePanelRevId}
              </span>
            </Tooltip>
            {params.row.feePanelRevId === feeVersionId && (
              <RemoveRedEye
                data-testid="icon-selected-id"
                id="icon-selected-id"
                sx={{ paddingLeft: "8px", color: theme.palette.primary.main }}
              />
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const status = getVersionStatus(
          params.row.feePanelRevId,
          params.row.editable,
          params.row.schedularRun,
          params.row.discard,
          activateStatus
        );
        const timezone = moment.tz.guess();
        const scheduled = moment(activateVersionDate)
          .tz(timezone)
          .format("DD-MM-YYYY hh:mm:ss A");
        return (
          <>
            <span style={{ display: "flex" }}>
              <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
                <li style={{ textAlign: "center" }}>{status}</li>
                {status === "Scheduled" && (
                  <li style={{ textAlign: "center", fontSize: "12px" }}>
                    {scheduled}
                  </li>
                )}
              </ul>
            </span>
          </>
        );
      },
    },
    {
      field: "lastModified",
      headerName: "Last Modified",
      minWidth: 230,
      editable: false,
      sortable: false,
      valueFormatter: (value) => {
        const dateTime = moment(value)
          .tz(timezone)
          .format("DD-MM-YYYY hh:mm:ss A");

        return dateTime + " " + timezoneAbbr;
      },
    },
    {
      field: "user",
      headerName: "User",
      minWidth: 200,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return params.row.first + " " + params.row.last;
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1.0,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        const feePanelRevId = row.feePanelRevId;
        const notes = params.row.notes;
        const index =
          params.api.getRowIndexRelativeToVisibleRows(feePanelRevId);
        const status = getVersionStatus(
          feePanelRevId,
          row.editable,
          row.schedularRun,
          row.disabled,
          activateStatus
        );
        return (
          <>
            {status === "Archived" || readOnly ? (
              <span>{notes}</span>
            ) : notes ? (
              <>
                <EditIcon
                  data-testid={`btnOpenNotes-${index}`}
                  id={`btnOpenNotes-${index}`}
                  color="primary"
                  sx={{ paddingRight: "10px" }}
                  onClick={() => handleOpenNotes(feePanelRevId, notes, status)}
                />
                <span>{notes}</span>
              </>
            ) : (
              <>
                <Button
                  data-testid={`btnOpenNotes-${index}`}
                  id={`btnOpenNotes-${index}`}
                  onClick={() => handleOpenNotes(feePanelRevId, notes, status)}
                  variant="text"
                  size="small"
                  sx={{ padding: 0, minWidth: 0 }}
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </>
            )}
          </>
        );
      },
    },
    {
      field: "parent",
      headerName: "Parent",
      width: 80,
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return params.row.parentFeePanelRevId > 0
          ? params.row.parentFeePanelRevId
          : "";
      },
    },
    {
      field: "action",
      headerName: "",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 190,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        const feePanelRevId = params.row.feePanelRevId;
        const index =
          params.api.getRowIndexRelativeToVisibleRows(feePanelRevId);
        let isDisabled = true;
        const status = getVersionStatus(
          params.row.feePanelRevId,
          params.row.editable,
          params.row.schedularRun,
          params.row.discard,
          activateStatus
        );
        if (status === "Draft" || status === "Failed") {
          isDisabled = false;
        }
        const canDiscard =
          (status === "Draft" || status === "Failed") && !!feeSet.feeRevId;
        return (
          <>
            {!readOnly && (
              <IconButton
                id={`btn-discard-${index}`}
                data-testid={`btn-discard-${index}`}
                aria-label="Discard"
                disabled={!canDiscard}
                color="primary"
                onClick={(e) => {
                  handleOpenDiscard(feePanelRevId);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="Discard version">
                  <DeleteOutlineIcon sx={{ fontSize: 19 }} />
                </Tooltip>
              </IconButton>
            )}
            {!readOnly && (
              <IconButton
                id={`btn-version-copy-edit-${index}`}
                data-testid={`btn-version-copy-edit-${index}`}
                aria-label="Copy & Edit"
                disabled={hasEditable}
                onClick={(e) => {
                  handleCopyNEdit(feeId, feePanelRevId);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="Copy version">
                  <img src={copyEditOutlined} alt="" />
                </Tooltip>
              </IconButton>
            )}
            <IconButton
              color="primary"
              id={`btn-version-export-${index}`}
              data-testid={`btn-version-export-${index}`}
              aria-label="Export latest"
              onClick={(e) => {
                handleExport(feePanelRevId);
                e.preventDefault();
              }}
            >
              <Tooltip title={`Export Version ID${feePanelRevId}`}>
                <FileDownloadOutlined sx={{ fontSize: 19 }} />
              </Tooltip>
            </IconButton>
            {!readOnly && (
              <Tooltip title={!isDisabled && "Activate this fee version"}>
                <span>
                  <Button
                    id={`btn-active-${index}`}
                    data-testid={`btn-active-${index}`}
                    variant="text"
                    onClick={() => handleOpenActivation(feePanelRevId)}
                    disabled={isDisabled}
                  >
                    Activate
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  useEffect(() => {
    setPaginationModel({ page: currentPage, pageSize: pageSize });
  }, [currentPage, pageSize]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchFeeVersions(feeId, newPage, newPageSize);
  };

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const fetchFeeVersions = (
    id: number,
    currentPage: number,
    pageSize: number
  ) => {
    return dispatch(getFeeVersions(id, currentPage, pageSize));
  };

  const handleCompare = (
    event: React.ChangeEvent<HTMLInputElement>,
    versionId: number,
    compareCount: number
  ) => {
    const isChecked = event.target.checked;
    dispatch(
      setVersionsToCompare(
        versionId,
        isChecked,
        isChecked ? compareCount + 1 : compareCount - 1,
        550
      )
    );
  };

  const handleOpenNotes = (feeRevId: number, notes: string, status: string) => {
    setSelectedVersion(feeRevId);
    setVersionNotes(notes);
    setVersionStatus(status);
    setOpenNotes(true);
  };

  const handleNotesClose = () => {
    setSelectedVersion(0);
    setOpenNotes(false);
  };

  const handleUpdateNotes = () => {
    dispatch(updateFeeVersionNotes(feeId, selectedVersion, versionNote));
    setOpenNotes(false);
  };

  const handleOpenDiscard = (feeRevId: number) => {
    setSelectedVersion(feeRevId);
    setOpenDiscard(true);
  };

  const handleDiscardVersion = () => {
    dispatch(discardFeeVersion(feeId, selectedVersion));
    setOpenDiscard(false);
  };
  const changeDates = (newValue: any) => {
    if (newValue != null) {
      setActivateDate(newValue);
    }
  };

  const handleActivationClose = () => {
    setSelectedVersion(0);
    setOpenActivation(false);
  };

  const handleFeeActivation = () => {
    if (activateDate != null) {
      setActivateDate(
        activateDate
          .set("second", 0)
          .set("milliseconds", 0)
          .set("millisecond", 0)
      );
    }
    dispatch(
      scheduleFeeVersionActivation(feeId, selectedVersion, activateDate)
    );
    setOpenActivation(false);
  };

  const handleCopyNEdit = (feeId: number, feePanelRevId: number) => {
    dispatch(copyFeeVersion(feeId, feePanelRevId));
  };

  return (
    <>
      <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Grid className={classes.versionsHeader}>
          <Typography id="tbl-info" data-testid="tbl-info">
            {rowCount} Versions
          </Typography>
        </Grid>
        <Grid sx={{ width: 289 }}></Grid>
      </Grid>
      <Box
        display={"flex"}
        sx={{
          minHeight: 400,
          width: "100%",
          "& .status-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.55)",
            color: "#1a3e72",
            fontWeight: "600",
          },
          "& .Active": {
            backgroundColor: theme.palette.success.lightest,
            "&:hover": {
              backgroundColor: `${theme.palette.success.lightest} !important`,
            },
          },
          "& .Scheduled": {
            backgroundColor: theme.palette.warning.lighter,
            "&:hover": {
              backgroundColor: `${theme.palette.warning.lighter} !important`,
            },
          },
          "& .Draft": {
            backgroundColor: theme.palette.draft.light,
            "&:hover": {
              backgroundColor: `${theme.palette.draft.light} !important`,
            },
          },
          "& .Failed": {
            backgroundColor: theme.palette.error.lightest,
            "&:hover": {
              backgroundColor: `${theme.palette.error.lightest} !important`,
            },
          },
        }}
      >
        <DataGridPro
          loading={versionLoading}
          style={{ cursor: "pointer" }}
          className="tblFeeVersions"
          getRowId={(row) => row.feePanelRevId}
          rows={feeVersions}
          rowCount={rowCountState}
          columns={columns}
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[15, 20, 50]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          getRowClassName={(params) => {
            const status = getVersionStatus(
              params.row.feePanelRevId,
              params.row.editable,
              params.row.schedularRun,
              params.row.discard,
              activateStatus
            );
            return status !== "Archived"
              ? status
              : params.indexRelativeToCurrentPage % 2 === 0
              ? "even"
              : "";
          }}
          onCellClick={(
            params: GridCellParams,
            event: MuiEvent<React.MouseEvent>
          ) => {
            const row = params.row;
            const status = getVersionStatus(
              row.feePanelRevId,
              row.editable,
              row.schedularRun,
              row.disabled
            );
            if (
              params.field === "notes" &&
              row.notes !== "" &&
              row.notes !== null
            ) {
              handleOpenNotes(row.feePanelRevId, row.notes, status);
            }
            event.defaultMuiPrevented = true;
          }}
        />
      </Box>
      <PanelExDialog
        id="notes-dialog"
        data-testid="notes-dialog"
        open={openNotes}
        title={`Notes for v${selectedVersion}`}
        submitText="Update"
        submitEnabled={!readOnly}
        onClose={handleNotesClose}
        onSubmit={handleUpdateNotes}
        showAction={versionStatus !== "Archived" && !readOnly ? true : false}
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            padding: "20px",
          }}
        >
          <TextField
            id="textarea-notes"
            data-testid="textarea-notes"
            multiline
            fullWidth={true}
            rows={4}
            value={versionNote}
            disabled={versionStatus === "Archived"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setVersionNotes(event.target.value);
            }}
          />
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="discard-dialog"
        data-testid="discard-dialog"
        open={openDiscard}
        title="Are you sure you want to discard this draft?"
        submitText="Yes, Discard"
        submitEnabled={true}
        onClose={() => setOpenDiscard(false)}
        onSubmit={handleDiscardVersion}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
        >
          <Typography variant="body1">
            Discarding this draft will remove it. A new draft will need to be
            created if required.
          </Typography>
        </Box>
      </PanelExDialog>
      <PanelExDialog
        id="activate-dialog"
        data-testid="activate-dialog"
        open={openActivation}
        title={`Activate version ${selectedVersion}`}
        submitText="Agree and Activate"
        submitEnabled={true}
        onClose={handleActivationClose}
        onSubmit={handleFeeActivation}
        showAction={versionStatus !== "Archived"}
        maxWidth="xs"
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "10px",
            maxWidth: "sm",
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 420 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1}>
                <Grid size={6}>
                  <DesktopDateTimePicker
                    sx={{ mt: 0, minWidth: 240 }}
                    data-testid="fee-activate-dialog-date"
                    format="DD-MM-YYYY hh:mm a"
                    value={activateDate}
                    onChange={(newValue) => changeDates(newValue)}
                    minDateTime={minDateTime}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </FormControl>
          <DialogContentText
            id="fee-activate-dialog-text"
            data-testid="fee-activate-dialog-text"
          >
            <br />I agree and acknowledge that all the fee details are verified
            and correct
          </DialogContentText>
        </Box>
      </PanelExDialog>
      {versionCompare.length > 0 ? (
        <PanelExActionSnackbar width={550} readOnly={readOnly} />
      ) : null}
      {showVersionCompare && <FeeVersionComparison />}
    </>
  );
}
