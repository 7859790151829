import React, { useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid2 as Grid,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import { RootState } from "../../../store/staticReducers/reducers";
import moment from "moment-timezone";
import {
  openAssociatedPanels,
  updateSelectedVersion,
} from "../../../store/action/actionPanel";
import { exportAllocationVersion } from "../../../store/action/actionAllocation";
import LinkIcon from "@mui/icons-material/Link";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import AssociatedPanels from "./AssociatedPanels";

export const strDatetime = (date: any) => {
  const timezone = moment.tz.guess();
  const timezoneAbbr = moment().tz(timezone).format("z");

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!date) {
    const dateTime = moment(date).tz(timezone).format("DD-MM-YYYY hh:mm:ss A");
    return dateTime + " " + timezoneAbbr;
  } else {
    return "";
  }
};

export default function SelectVersion() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: "32px",
    },
    panelListContainer: {
      paddingBottom: "4px",
      alignItems: "center",
      color: theme.colors.neutral.light,
    },
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "18px 18px 18px 24px",

      borderRadius: "16px",
      background: theme.palette.common.white,
      border: `1px solid ${theme.colors.grey.light}`,
    },
    cardTitle: {
      paddingBottom: "8px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      color: theme.colors.grey.darker,
    },
    warningContainer: {
      marginTop: "12px",
      padding: "14px 16px 14px 16px",
      background: theme.palette.warning.lightest,
      color: theme.palette.warning.darker,
    },
    assocButton: {
      border: `1px solid ${theme.colors.warning.light}`,
      backgroundColor: theme.palette.common.white,
    },
    versionContainer: {
      padding: "24px 0px 8px 0px",
    },
    versionRadioContainer: {
      width: "28px",
      padding: "4px 32px 9px 0px",
      "& .MuiRadio-root,.Mui-checked": {
        padding: "0px",
        color: theme.colors.primary.dark,
      },
    },
    describeStack: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "start",
      color: theme.colors.grey.darker,

      "& .MuiTypography-root": {
        paddingTop: "5px",
      },
    },
  }));

  const { classes } = useStyles();

  const { allocations, isOpenAssociatedPanels, versionLoading } = useSelector(
    (state: RootState) => {
      return {
        allocations: state.panel.bulkUpdate.allocations || [],
        isOpenAssociatedPanels: state.panel.bulkUpdate.isOpenAssociatedPanels,
        versionLoading: state.panel.bulkUpdate.versionLoading || false,
      };
    }
  );

  const [allocationModel, setAllocationModel] = useState({
    allocationId: 0,
    allocationName: "",
  });

  const renderCard = (allocation: any, index: number) => {
    const {
      allocationPanelId,
      allocationName,
      allocActiveRevId,
      allocActiveLastModified,
      allocActiveLastModifiedBy,
      allocDraftRevId,
      allocDraftLastModified,
      allocDraftLastModifiedBy,
      panels,
    } = allocation;

    const selectedVersion =
      allocation.selectedVersion || (allocDraftRevId ? "draft" : "active");

    const assocPanels = panels.filter((panel: any) => !panel.selected);
    return (
      <Grid
        key={`grid-allocation-${index}`}
        id={`grid-allocation-${index}`}
        data-testid={`grid-allocation-${index}`}
        container
        size={12}
        className={classes.mainContainer}
      >
        {panels
          .filter((panel: any) => panel.selected)
          .map((panel: any, panelIndex: number) => (
            <Grid
              container
              size={12}
              key={`panel-${panelIndex}`}
              id={`panel-${panelIndex}`}
              data-testid={`panel-${panelIndex}`}
              className={classes.panelListContainer}
            >
              <Checkbox
                id={`checkbox-panel-${panelIndex}`}
                data-testid={`checkbox-panel-${panelIndex}`}
                checked={true}
                disabled={true}
                size={"small"}
              />
              <Typography variant="h6">
                {panel.panelName} ID: {panel.panelSetId}
              </Typography>
            </Grid>
          ))}

        <Grid container size={12} className={classes.cardContainer}>
          <Grid
            container
            size={12}
            className={classes.cardTitle}
            id={`title-section-${index}`}
            data-testid={`title-section-${index}`}
          >
            <Stack spacing={1} direction={"column"}>
              <Typography variant="h4">{allocationName}</Typography>
              <Typography
                variant="body3"
                sx={{ color: `${theme.colors.grey.main}`, marginTop: "4px" }}
              >
                Allocation
              </Typography>
            </Stack>
          </Grid>

          {assocPanels.length > 0 && (
            <Grid
              container
              size={12}
              className={classes.warningContainer}
              id={`assoc-section-${index}`}
              data-testid={`assoc-section-${index}`}
            >
              <Grid size={8}>
                <Stack spacing={1} direction={"row"}>
                  <ReportProblemOutlinedIcon
                    sx={{ color: "rgb(242, 122, 49)" }}
                  />
                  <Stack spacing={1} direction={"column"}>
                    <Typography
                      id={`assoc-text-1-${index}`}
                      data-testid={`assoc-text-1-${index}`}
                      variant="h5"
                    >
                      Changes will impact all linked associated panels
                    </Typography>
                    <Typography
                      id={`assoc-text-2-${index}`}
                      data-testid={`assoc-text-2-${index}`}
                      variant="body2"
                    >
                      The selected panel has the same allocation being used
                      elsewhere.
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                size={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  id={`btn-assoc-panel-${index}`}
                  data-testid={`btn-assoc-panel-${index}`}
                  variant="outlined"
                  color="info"
                  className={classes.assocButton}
                  startIcon={
                    <Badge badgeContent={assocPanels.length} color="warning">
                      <LinkIcon color="action" />
                    </Badge>
                  }
                  onClick={() => {
                    setAllocationModel({
                      allocationId: allocationPanelId,
                      allocationName: allocationName,
                    });
                    dispatch(openAssociatedPanels(true));
                  }}
                >
                  View Associated Panels
                </Button>
              </Grid>
            </Grid>
          )}

          {allocDraftRevId > 0 && (
            <Grid
              container
              size={12}
              className={classes.versionContainer}
              id="draft-section"
              data-testid="draft-section"
            >
              <Grid className={classes.versionRadioContainer}>
                <Radio
                  checked={selectedVersion === "draft"}
                  value="draft"
                  id="radio-btn-draft"
                  data-testid="radio-btn-draft"
                  name="radio-btn-draft"
                  sx={{ padding: "0px", color: theme.colors.primary.dark }}
                  onClick={() => {
                    if (selectedVersion !== "draft") {
                      dispatch(
                        updateSelectedVersion(allocationPanelId, "draft")
                      );
                    }
                  }}
                />
              </Grid>

              <Grid container size={11} sx={{ paddingTop: "2px" }}>
                <Grid size={12} sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    id="radio-btn-draft-label"
                    data-testid="radio-btn-draft-label"
                    variant="h3"
                    onClick={() => {
                      if (selectedVersion !== "draft") {
                        dispatch(
                          updateSelectedVersion(allocationPanelId, "draft")
                        );
                      }
                    }}
                  >
                    Draft Version
                  </Typography>
                  <Button
                    data-testid="btn-draft-export"
                    id="btn-draft-export"
                    size="medium"
                    variant="text"
                    startIcon={<FileDownloadOutlinedIcon />}
                    sx={{
                      marginRight: "8px",
                      color: theme.colors.primary.dark,
                    }}
                    onClick={() => {
                      dispatch(
                        exportAllocationVersion(
                          allocationPanelId,
                          allocDraftRevId,
                          allocationName
                        )
                      );
                    }}
                  >
                    Export
                  </Button>
                </Grid>
                <Grid container size={12}>
                  <Stack
                    spacing={0}
                    direction="column"
                    className={classes.describeStack}
                  >
                    <Typography
                      id="draft-desc"
                      data-testid="draft-desc"
                      variant="body3Strong"
                    >
                      Edit existing Draft ID {allocDraftRevId}
                    </Typography>
                    <Typography
                      id="draft-version-modify-desc"
                      data-testid="draft-version-modify-desc"
                      variant="body3Strong"
                      sx={{ color: theme.colors.grey.main }}
                    >
                      Last Modified by {allocDraftLastModifiedBy}{" "}
                      {strDatetime(allocDraftLastModified)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            size={12}
            className={classes.versionContainer}
            id="active-section"
            data-testid="active-section"
          >
            <Grid className={classes.versionRadioContainer}>
              <Radio
                checked={selectedVersion === "active"}
                value="active"
                id="radio-btn-active"
                data-testid="radio-btn-active"
                name="radio-btn-active"
                sx={{ padding: "0px", color: theme.colors.primary.dark }}
                onClick={() => {
                  if (selectedVersion !== "active") {
                    dispatch(
                      updateSelectedVersion(allocationPanelId, "active")
                    );
                  }
                }}
              />
            </Grid>
            <Grid container size={11} sx={{ paddingTop: "2px" }}>
              <Grid size={12} sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  id="radio-btn-active-label"
                  data-testid="radio-btn-active-label"
                  variant="h3"
                  onClick={() => {
                    if (selectedVersion !== "active") {
                      dispatch(
                        updateSelectedVersion(allocationPanelId, "active")
                      );
                    }
                  }}
                >
                  Active Version
                </Typography>
                <Button
                  id="btn-active-export"
                  data-testid="btn-active-export"
                  size="medium"
                  variant="text"
                  startIcon={<FileDownloadOutlinedIcon />}
                  sx={{
                    marginRight: "8px",
                    color: theme.colors.primary.dark,
                  }}
                  onClick={() => {
                    dispatch(
                      exportAllocationVersion(
                        allocationPanelId,
                        allocActiveRevId,
                        allocationName
                      )
                    );
                  }}
                >
                  Export
                </Button>
              </Grid>
              <Grid container size={12}>
                <Stack
                  spacing={0}
                  direction="column"
                  className={classes.describeStack}
                >
                  <Typography
                    id="active-desc"
                    data-testid="active-desc"
                    variant="body3Strong"
                  >
                    Create Draft from Active ID {allocActiveRevId}
                  </Typography>
                  <Typography
                    id="active-version-modify-desc"
                    data-testid="active-version-modify-desc"
                    variant="body3Strong"
                    sx={{ color: theme.colors.grey.main }}
                  >
                    Last Modified by {allocActiveLastModifiedBy}{" "}
                    {strDatetime(allocActiveLastModified)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      size={12}
      id="select-version"
      data-testid="select-version"
      sx={{ width: "100%", maxWidth: "1080px", padding: "0px 10px 0px 10px" }}
    >
      <Grid size={12}>
        <Typography
          id="title-select-version"
          data-testid="title-select-version"
          variant="h1"
          sx={{ paddingBottom: "16px" }}
        >
          Select Versions
        </Typography>
      </Grid>
      {versionLoading ? (
        <Grid size={12}>
          <Box
            data-testid="select-version-progress"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <React.Fragment>
          {allocations.map((allocation: any, index: number) =>
            renderCard(allocation, index)
          )}
          {isOpenAssociatedPanels && (
            <AssociatedPanels
              allocationId={allocationModel.allocationId}
              allocationName={allocationModel.allocationName}
            />
          )}
        </React.Fragment>
      )}
    </Grid>
  );
}
