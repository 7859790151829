import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import ReplyAllOutlined from "@mui/icons-material/ReplyAllOutlined";
import AddLinkIcon from "@mui/icons-material/AddLink";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import {
  updateFeeTextChange,
  updatePanelFee,
} from "../../store/action/actionPanel";
import FeeSet from "../fee/FeeSet";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

export default function PanelFeeSet() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    detailContent: {
      width: "380px",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      minHeight: "400px",
    },
    detailMessage: {
      fontSize: "20px",
      fontWeight: "500",
      paddingTop: "12px",
      paddingBottom: "22px",
      color: theme.palette.error.main,
    },
    autocomplete: {
      padding: "2px 0px 2px 0px",
      width: "550px",
      height: "38px",
      background: "#FFFFFF",
      borderRadius: "4px",
      "&:focus": {
        border: "2px solid #3399FF",
      },
      "& .MuiTextField-root": {
        marginTop: "-3px",
      },
      "& .MuiInputBase-root": {
        height: "38px",
      },
      "& .MuiOutlinedInput-root": {
        paddingRight: "9px !important",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
    feeBox: {
      background: "#FFFFFF",
      width: "100%",
      padding: "7px 16px 8px 16px",
    },
  }));
  const { classes } = useStyles();

  const { panelVersionDetail, searchText, suggestions, roleName } = useSelector(
    (state: RootState) => {
      return {
        panelVersionDetail: state.panel.panelVersionDetail,
        searchText: state.panel.feeSearchText,
        suggestions: state.panel.feeSuggestion,
        roleName: state.tokenDetails.userContactDetails.roleName,
      };
    }
  );
  const {
    id: panelId,
    panelSetRevId: panelVersionId,
    feeSummaryDto: feeDetail,
    editable: isEditablePanel,
  } = panelVersionDetail;

  const handleInputChange = (input: string) => {
    if (input !== searchText) {
      dispatch(updateFeeTextChange(input));
    }
  };

  const handleAddFee = (id: number, name: string) => {
    dispatch(updatePanelFee(id, name, panelId, panelVersionId));
  };

  return (
    <Box
      id="fee-set-component"
      data-testid="fee-set-component"
      className={classes.feeBox}
    >
      {feeDetail && feeDetail.id ? (
        <FeeSet
          feeId={feeDetail.id}
          panelId={panelId}
          panelVersionId={panelVersionId}
          panelEditable={isEditablePanel}
        />
      ) : isEditablePanel ? (
        <Grid container data-testid={"fee-details-container"}>
          <Grid
            size={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              id="fee-details-content"
              data-testid="fee-details-content"
              className={classes.detailContent}
            >
              {roleName === "Admin" ? (
                <>
                  <ReplyAllOutlined
                    sx={{
                      width: "60px",
                      height: "60px",
                      transform: `rotate(-90deg)`,
                      color: theme.colors.grey.lighter,
                    }}
                    id="fee-details-icon"
                    data-testid="fee-details-icon"
                  />
                  <Typography
                    id="fee-details-message"
                    data-testid="fee-details-message"
                    className={classes.detailMessage}
                  >
                    Search & Link a Fee
                  </Typography>
                  <PanelExAutocomplete
                    id="panel-fee-search"
                    style={{ width: "750px" }}
                    options={suggestions}
                    value={searchText}
                    getOptionLabel={(option: any) =>
                      option.name || searchText || ""
                    }
                    placeholder="Search for an fee"
                    onInputChange={(value: any) => {
                      handleInputChange(value);
                    }}
                    renderOption={(props: any, option: any) => {
                      const { id, name } = option;
                      const regex = new RegExp(
                        escapedSpecialCharacters(searchText),
                        "gi"
                      );
                      const response = name.replace(regex, function (str: any) {
                        return (
                          "<span style='font-weight:500'>" + str + "</span>"
                        );
                      });
                      return (
                        <li
                          data-testid={`add-fee-label-${id}`}
                          {...props}
                          style={{ justifyContent: "space-between" }}
                          onClick={(ev) => {
                            handleAddFee(id, name);
                            ev.stopPropagation();
                          }}
                        >
                          <div
                            style={{ textAlign: "start" }}
                            dangerouslySetInnerHTML={{ __html: `${response}` }}
                          ></div>
                          <AddLinkIcon
                            data-testid={`add-fee-icon-${id}`}
                            style={{ color: "#757575" }}
                          />
                        </li>
                      );
                    }}
                  />
                </>
              ) : (
                <Grid>
                  <Typography
                    data-testid="warning-linked"
                    variant="body3Strong"
                    sx={{ color: theme.palette.grey["400"] }}
                  >
                    The admin team is configuring the panel
                  </Typography>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
}
