import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  showEditUser,
  updateUserRoleEditUser,
  updateFirstNameEditUser,
  updateLastNameEditUser,
  updateEmailFormEditUser,
  addFunderEditUser,
  addClientEditUser,
  removeFunderEditUser,
  removeClientEditUser,
  updateOrgTextChange,
  updateUser,
  addNewUser,
  showAddNewUser,
  updateIsNab,
  updateIsReadOnly,
  getUserDetail,
} from "../../store/action/actionUser";
import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { grey } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function EditUserModal() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    select: {
      borderRadius: "4px",
      marginRight: "4px",
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      alignItems: "center",
    },
    bubble: {
      display: "flex",
      margin: "3px",
      padding: "3px 4px",
      alignItems: "center",
      borderRadius: "16px",
      background: grey[200],
    },
    bubbleText: {
      paddingLeft: "4px",
      color: theme.palette.text.primary,
    },
    bubbleIcon: {
      padding: "4px",
      "& .MuiSvgIcon-root": {
        color: `${theme.colors.grey.main}`,
        width: "16px",
        height: "16px",
      },
    },
    filterTitle: {
      color: theme.palette.text.primary,
    },
    userGrid: {
      width: "100%",
      cursor: "pointer",
      "& .MuiTableCell-root": {
        padding: "8px",
        height: "50px",
      },
      "& .MuiFormControl-root": {
        height: "auto",
      },
    },
    userHeader: {
      fontSize: "13px",
      fontWeight: 500,
    },
    roleText: {
      width: "100%",
      "& .MuiInputBase-input": {
        padding: "12px 0px 8px 12px",
        height: "10px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
        marginBottom: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    userText: {
      width: "100%",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.error.main,
      },
    },
    tableCell: {
      border: "0px",
    },
  }));

  const isEmail = (email: string) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(
      email
    );
  };

  const { classes } = useStyles();

  const { user, showAddNewUserModal, showEditUserModal } = useSelector(
    (state: RootState) => {
      return {
        user: state.user.showAddNewUser
          ? state.user.newUser
          : state.user.editUser,
        showAddNewUserModal: state.user.showAddNewUser || false,
        showEditUserModal: state.user.showEditUser || false,
      };
    }
  );

  const {
    funderText = "",
    funderSuggestion = [],
    clientText = "",
    clientSuggestion = [],
    userId = 0,
    userDetail,
    loading = false,
    error = "",
  } = user;

  const {
    username = "",
    email = "",
    first = "",
    last = "",
    roleName = "",
    funderOrgDtoSet = [],
    clientOrgDtoSet = [],
    isNab = false,
    isReadOnly = false,
  } = userDetail || {};

  const isEdit = showEditUserModal;

  useEffect(() => {
    if (isEdit) {
      dispatch(getUserDetail(userId));
    }
  }, [dispatch, userId, isEdit]);

  const handleSubmit = () => {
    if (isEdit) {
      dispatch(updateUser(userDetail));
    } else {
      dispatch(addNewUser(userDetail));
    }
  };
  const handleOrgInputChange = (input: string, orgType: string) => {
    if (input !== funderText) {
      dispatch(updateOrgTextChange(input, orgType, isEdit));
    }
  };
  const handleUpdateFunder = (orgId: number, orgName: string) => {
    dispatch(addFunderEditUser(orgId, orgName, isEdit));
  };
  const handleUpdateClient = (orgId: number, orgName: string) => {
    dispatch(addClientEditUser(orgId, orgName, isEdit));
  };
  const handleEdiUserClose = () => {
    if (isEdit) {
      dispatch(showEditUser(null, false));
    } else {
      dispatch(showAddNewUser(false));
    }
  };

  const handleUserRoleChange = (value: string) => {
    dispatch(updateUserRoleEditUser(value, isEdit));
  };
  const handleFirstNameChange = (value: string) => {
    dispatch(updateFirstNameEditUser(value, isEdit));
  };
  const handleLastNameChange = (value: string) => {
    dispatch(updateLastNameEditUser(value, isEdit));
  };
  const handleEmailChange = (value: string) => {
    dispatch(updateEmailFormEditUser(value, isEdit));
  };

  const handleDeleteFunder = (orgId: number) => {
    dispatch(removeFunderEditUser(orgId, isEdit));
  };
  const handleDeleteClient = (orgId: number) => {
    dispatch(removeClientEditUser(orgId, isEdit));
  };
  const handleIsNabChange = (value: boolean) => {
    dispatch(updateIsNab(value, isEdit));
  };
  const handleIsReadOnlyChange = (value: boolean) => {
    dispatch(updateIsReadOnly(value, isEdit));
  };

  const isvalidOrg = () => {
    return roleName != "Admin"
      ? funderOrgDtoSet.length > 0 || clientOrgDtoSet.length > 0
      : true;
  };
  const userSubmitEnabled =
    isEmail(email) &&
    first.length > 0 &&
    last.length > 0 &&
    roleName &&
    isvalidOrg();

  const createBubble = (
    type: string,
    id: any,
    displayText: string,
    index: number
  ) => {
    return (
      <Grid
        key={`grid-${type}-${index}`}
        data-testid={`grid-${type}-${index}`}
        className={classes.bubble}
      >
        <Typography variant="body3Strong" className={classes.bubbleText}>
          {displayText}
        </Typography>
        <IconButton
          id={`btn-delete-${type}-${index}`}
          data-testid={`btn-delete-${type}-${index}`}
          className={classes.bubbleIcon}
          onClick={(ev) => {
            if (type === "funder-type") {
              handleDeleteFunder(id);
            } else {
              handleDeleteClient(id);
            }
            ev.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <PanelExDialog
      id="edit-user-dialog"
      data-testid="edit-user-dialog"
      open={showEditUserModal || showAddNewUserModal}
      title={isEdit ? "Edit User" : "Add New User"}
      submitText={isEdit ? "Save User" : "Add User to PanelEx"}
      onClose={() => handleEdiUserClose()}
      onSubmit={handleSubmit}
      dividers={false}
      submitEnabled={userSubmitEnabled}
      showAction={true}
      editUser={true}
    >
      <Box
        noValidate
        component="form"
        id={"editUser"}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "28px 28px 16px 28px",
        }}
      >
        {loading ? (
          <Grid container size={12} sx={{ paddingBottom: "3px" }} spacing={1}>
            <Grid size={12} sx={{ height: "400px" }}>
              <Box
                id="user-sets-progress"
                data-testid="user-sets-progress"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container size={12} sx={{ paddingBottom: "3px" }} spacing={1}>
            <Grid size={12} sx={{ paddingBottom: "3px" }}>
              <Typography
                id="user-error"
                data-testid="user-error"
                variant="body2"
                className={classes.error}
              >
                {error}
              </Typography>
            </Grid>
            <Grid container size={12} sx={{ paddingBottom: "3px" }} spacing={1}>
              <Grid size={6}>
                <Typography
                  variant="subtitle2"
                  data-testid={`user-first-name-title`}
                  className={classes.filterTitle}
                >
                  First Name
                </Typography>
              </Grid>

              <Grid size={6}>
                <Stack direction="row" className={classes.stack}>
                  <Typography
                    variant="subtitle2"
                    className={classes.filterTitle}
                    data-testid={"user-last-name-title"}
                  >
                    Surname
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container size={12} sx={{ paddingBottom: "3px" }} spacing={1}>
              <Grid size={6}>
                <TextField
                  id={`user-first-name`}
                  data-testid={`user-first-name`}
                  placeholder="Enter First Name"
                  onClick={(e: any) => e.stopPropagation()}
                  className={`${classes.userText} ${
                    first && first.trim().length > 0 ? null : classes.error
                  }`}
                  value={first}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFirstNameChange(e.target.value as string)
                  }
                />
              </Grid>

              <Grid size={6}>
                <Stack spacing={1} direction="row" className={classes.stack}>
                  <TextField
                    id={`user-last-name`}
                    data-testid={`user-last-name`}
                    className={`${classes.userText} ${
                      last && last.trim().length > 0 ? null : classes.error
                    }`}
                    placeholder="Enter Surname"
                    onClick={(e: any) => e.stopPropagation()}
                    value={last}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleLastNameChange(e.target.value as string)
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container size={12} sx={{ paddingBottom: "3px" }} spacing={1}>
              <Grid size={6}>
                <Typography
                  variant="subtitle2"
                  data-testid={`user-email-title`}
                  className={classes.filterTitle}
                >
                  Email
                </Typography>
              </Grid>

              <Grid size={6}>
                <Stack direction="row" className={classes.stack}>
                  <Typography
                    variant="subtitle2"
                    className={classes.filterTitle}
                    data-testid={`user-name-title`}
                  >
                    Username
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container size={12} sx={{ paddingBottom: "3px" }} spacing={1}>
              <Grid size={6}>
                <TextField
                  id={`user-email`}
                  data-testid={`user-email`}
                  className={`${classes.userText} 
                  ${isEmail(email) ? false : classes.error} 
                  ${error.startsWith("Email address ") ? classes.error : null}`}
                  placeholder="Enter Email"
                  onClick={(e: any) => e.stopPropagation()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEmailChange(e.target.value as string)
                  }
                  value={email}
                />
              </Grid>
              <Grid size={6}>
                <Stack spacing={2} direction="row" className={classes.stack}>
                  <TextField
                    id={`user-name`}
                    data-testid={`user-name`}
                    disabled={true}
                    className={`${classes.userText} `}
                    placeholder="Enter username"
                    value={username}
                  />
                </Stack>
              </Grid>
              <Grid size={6}>
                <TextField
                  select
                  label="Role"
                  defaultValue=""
                  id="edit-user-role"
                  data-testid="edit-user-role"
                  value={roleName}
                  className={`${classes.roleText} ${
                    roleName ? false : classes.error
                  } `}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleUserRoleChange(e.target.value as string)
                  }
                >
                  <MenuItem key={1} value="Admin">
                    Admin
                  </MenuItem>
                  <MenuItem key={2} value="User">
                    User
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid size={6}>
                <Stack spacing={1} direction="row" className={classes.stack}>
                  <Typography
                    variant="subtitle2"
                    className={classes.filterTitle}
                  >
                    NAB User?&nbsp;&nbsp;
                  </Typography>
                  <FormControlLabel
                    data-testid="is-nab"
                    id="is-nab"
                    control={
                      <Switch
                        checked={isNab || false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleIsNabChange(e.target.checked as boolean)
                        }
                      />
                    }
                    label={isNab ? "Yes" : "No"}
                  />
                </Stack>
                <Stack spacing={1} direction="row" className={classes.stack}>
                  <Typography
                    variant="subtitle2"
                    className={classes.filterTitle}
                  >
                    Read Only?
                  </Typography>
                  <FormControlLabel
                    data-testid="is-readonly"
                    id="is-readonly"
                    control={
                      <Switch
                        checked={isReadOnly || false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleIsReadOnlyChange(e.target.checked as boolean)
                        }
                      />
                    }
                    label={isReadOnly ? "Yes" : "No"}
                  />
                </Stack>
              </Grid>

              <Grid size={12}>
                <Typography
                  variant="subtitle2"
                  data-testid={`user-org-title`}
                  className={classes.filterTitle}
                >
                  Access to organisations
                </Typography>
              </Grid>
              <Grid size={12}>
                <Stack direction="row" className={classes.stack}>
                  <PanelExAutocomplete
                    id="funder-filter"
                    data-testid="funder-filter"
                    style={{ width: "100%" }}
                    validInput={isvalidOrg()}
                    isDisabled={roleName === "Admin"}
                    options={funderSuggestion}
                    value={funderText}
                    getOptionLabel={(option: any) =>
                      option.name || funderText || ""
                    }
                    placeholder="Search for Funders"
                    onInputChange={(value: any) => {
                      handleOrgInputChange(value, "Funder");
                    }}
                    renderOption={(props: any, option: any) => {
                      const { id, name } = option;

                      const isExisting =
                        funderOrgDtoSet.find((v: any) => v.orgId === id) !==
                        undefined;
                      const regex = new RegExp(
                        escapedSpecialCharacters(funderText),
                        "gi"
                      );
                      const response = name.replace(regex, function (str: any) {
                        return (
                          "<span style='font-weight:500'>" + str + "</span>"
                        );
                      });
                      return (
                        <li
                          {...props}
                          data-testid={`add-org-label-${id}`}
                          id={`add-org-label-${id}`}
                          onClick={(ev) => {
                            !isExisting && handleUpdateFunder(id, name);
                            ev.stopPropagation();
                          }}
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: `${response}` }}
                          ></div>
                          <AddCircleIcon
                            data-testid={`add-org-icon-${id}`}
                            id={`add-org-icon-${id}`}
                            style={{
                              ...(isExisting
                                ? {
                                    color: "rgba(0, 0, 0, 0.12)",
                                  }
                                : {
                                    color: "#757575",
                                  }),
                            }}
                          />
                        </li>
                      );
                    }}
                  />
                </Stack>
              </Grid>
              <Grid
                container
                size={12}
                sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                {funderOrgDtoSet.map((org: any, index: number) =>
                  createBubble("funder-type", org.orgId, org.orgName, index)
                )}
              </Grid>
              <Grid size={12}>
                <Stack direction="row" className={classes.stack}>
                  <PanelExAutocomplete
                    id="client-filter"
                    data-testid="client-filter"
                    style={{ width: "100%" }}
                    options={clientSuggestion}
                    isDisabled={roleName === "Admin"}
                    value={clientText}
                    validInput={isvalidOrg()}
                    getOptionLabel={(option: any) =>
                      option.name || clientText || ""
                    }
                    placeholder="Search for Clients"
                    onInputChange={(value: any) => {
                      handleOrgInputChange(value, "Client");
                    }}
                    renderOption={(props: any, option: any) => {
                      const { id, name } = option;

                      const isExisting =
                        clientOrgDtoSet.find((v: any) => v.orgId === id) !==
                        undefined;
                      const regex = new RegExp(
                        escapedSpecialCharacters(clientText),
                        "gi"
                      );
                      const response = name.replace(regex, function (str: any) {
                        return (
                          "<span style='font-weight:500'>" + str + "</span>"
                        );
                      });
                      return (
                        <li
                          {...props}
                          data-testid={`add-org-label-${id}`}
                          id={`add-org-label-${id}`}
                          onClick={(ev) => {
                            !isExisting && handleUpdateClient(id, name);
                            ev.stopPropagation();
                          }}
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: `${response}` }}
                          ></div>
                          <AddCircleIcon
                            data-testid={`add-org-icon-${id}`}
                            id={`add-org-icon-${id}`}
                            style={{
                              ...(isExisting
                                ? {
                                    color: "rgba(0, 0, 0, 0.12)",
                                  }
                                : {
                                    color: "#757575",
                                  }),
                            }}
                          />
                        </li>
                      );
                    }}
                  />
                </Stack>
              </Grid>
              <Grid
                container
                size={12}
                sx={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                {clientOrgDtoSet.map((org: any, index: number) =>
                  createBubble("client-type", org.orgId, org.orgName, index)
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </PanelExDialog>
  );
}
