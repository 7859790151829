import React from "react";
import { makeStyles } from "tss-react/mui";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

interface CustomModalProps {
  id: string;
  onClose?: () => void;
  open: boolean;
  title: string;
  description?: string;
  children?: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  id,
  onClose,
  open,
  title,
  description,
  children,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&#session-expiry-modal .makeStyles-paper-2": {
        width: "535px",
        height: "173px",
      },
    },
    paper: {
      backgroundColor: theme.palette.common.white,
      boxShadow:
        "0px 9px 46px 8px #0000001F, 0px 24px 38px 3px #00000024, 0px 11px 15px -7px #00000033",
      padding: theme.spacing(2, 4, 3),
      textAlign: "center",
      zIndex: 2,
      borderRadius: "4px",
    },
  }));

  const { classes } = useStyles();

  return (
    <Modal
      id={id}
      data-testid={id}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      open={Boolean(open)}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography id="modal-title" data-testid="modal-title" variant="h3">
            {title}
          </Typography>
          <p className="modal-description">{description}</p>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
