import React, { useState } from "react";
import { MenuItem, Snackbar, SnackbarContent, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import { closeActionSnackbar } from "../../store/action/actionUI";
import Button from "@mui/material/Button";
import {
  exportMultiAllocations,
  showVersionsCompare,
} from "../../store/action/actionAllocation";
import {
  exportMultiFees,
  showVersionsCompare as showFeeVersionsCompare,
} from "../../store/action/actionFee";
import {
  openBulkUpdate,
  showVersionsCompare as showPanelVersionsCompare,
} from "../../store/action/actionPanel";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "tss-react/mui";

interface PanelExActionSnackbarProps {
  onClearSection?: () => void;
  width: number;
  readOnly: boolean;
}

export default function PanelExActionSnackbar(
  props: PanelExActionSnackbarProps
) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    button: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      marginRight: "8px",
      "&.Mui-disabled": {
        borderColor: "#827c7c",
        color: "#827c7c",
      },
    },
    menu: {
      "& .MuiMenu-paper": {
        backgroundColor: theme.colors.grey.dark,
        color: theme.palette.common.white,
        borderRadius: "4px",
      },
    },
  }));
  const { classes } = useStyles();

  const { actionSnackbar, selectedPanels } = useSelector((state: RootState) => {
    return {
      actionSnackbar: state.ui.actionSnackbar,
      selectedPanels: state.panel.selectedPanels || [],
    };
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeActionSnackbar());
    if (props.onClearSection) {
      props.onClearSection();
    }
  };

  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );

  const getAction = (actionType: string) => {
    if (actionType === "compareVersions") {
      return (
        <React.Fragment>
          <Button
            data-testid="btn-compare-versions"
            id="btn-compare-versions"
            size="small"
            onClick={(ev) => {
              dispatch(showVersionsCompare(true));
              handleClose(ev);
            }}
            sx={{
              color: theme.colors.grey.darker,
              backgroundColor: theme.colors.alpha.white["100"],
              marginRight: "8px",
            }}
          >
            Compare
          </Button>
        </React.Fragment>
      );
    } else if (actionType === "compareFeeVersions") {
      return (
        <React.Fragment>
          <Button
            data-testid="btn-compare-versions"
            id="btn-compare-versions"
            size="small"
            onClick={(ev) => {
              dispatch(showFeeVersionsCompare(true));
              handleClose(ev);
            }}
            sx={{
              color: theme.colors.grey.darker,
              backgroundColor: theme.colors.alpha.white["100"],
              marginRight: "8px",
            }}
          >
            Compare
          </Button>
        </React.Fragment>
      );
    } else if (actionType === "comparePanelVersions") {
      return (
        <React.Fragment>
          <Button
            data-testid="btn-compare-versions"
            id="btn-compare-versions"
            size="medium"
            onClick={(ev) => {
              dispatch(showPanelVersionsCompare(true));
              handleClose(ev);
            }}
            sx={{
              color: theme.colors.grey.darker,
              backgroundColor: theme.colors.alpha.white["100"],
              marginRight: "8px",
            }}
          >
            Compare Versions
          </Button>
        </React.Fragment>
      );
    } else if (actionType === "bulkUpdate") {
      const allocationIds = Array.from(
        new Set(selectedPanels?.map((p: any) => p.allocationPanelId) || [])
      ).filter((id) => id != null);
      const feeIds = Array.from(
        new Set(
          selectedPanels
            ?.filter((p: any) => p.type != "Quote")
            .map((p: any) => p.feePanelId) || []
        )
      ).filter((id) => id != null);

      const openExport = Boolean(anchorElExport);
      const handleClickExport = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElExport(openExport ? null : event.currentTarget);
      };
      const handleCloseExport = () => {
        setAnchorElExport(null);
      };

      const handleExportAlloc = (event: React.SyntheticEvent | Event) => {
        dispatch(exportMultiAllocations(allocationIds));
        handleCloseExport();
        handleClose(event);
      };
      const handleExportFee = (event: React.SyntheticEvent | Event) => {
        dispatch(exportMultiFees(feeIds));
        handleCloseExport();
        handleClose(event);
      };

      return (
        <React.Fragment>
          <Button
            data-testid="btn-export"
            id="btn-export"
            size="medium"
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            className={classes.button}
            onClick={handleClickExport}
            aria-controls={openExport ? "menu-export" : undefined}
            aria-haspopup="true"
            aria-expanded={openExport ? "true" : undefined}
            disableElevation
            disabled={selectedPanels.length < 1 || selectedPanels.length > 5}
          >
            Export
          </Button>
          <Menu
            id="menu-export"
            data-testid="menu-export"
            className={classes.menu}
            MenuListProps={{
              "aria-labelledby": "btn-export",
            }}
            anchorEl={anchorElExport}
            open={openExport}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={handleCloseExport}
          >
            <MenuItem
              id="menu-export-alloc"
              data-testid="menu-export-alloc"
              onClick={(ev) => {
                handleExportAlloc(ev);
              }}
              disableRipple
            >
              Allocation
            </MenuItem>
            <MenuItem
              id="menu-export-fee"
              data-testid="menu-export-fee"
              onClick={(ev) => {
                handleExportFee(ev);
              }}
              disableRipple
            >
              Fee
            </MenuItem>
          </Menu>
          {/*<Button*/}
          {/*  data-testid="btn-import"*/}
          {/*  id="btn-import"*/}
          {/*  size="medium"*/}
          {/*  variant="outlined"*/}
          {/*  startIcon={<FileDownloadOutlinedIcon sx={{ rotate: "180" }} />}*/}
          {/*  endIcon={<KeyboardArrowDownIcon />}*/}
          {/*  className={classes.button}*/}
          {/*  disabled={true}*/}
          {/*>*/}
          {/*  Import*/}
          {/*</Button>*/}
          {!props.readOnly && (
            <Button
              data-testid="btn-bulk-update"
              id="btn-bulk-update"
              size="medium"
              variant="contained"
              startIcon={<EditIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              disabled={selectedPanels.length < 1}
              sx={{ marginRight: "8px" }}
              onClick={(ev) => {
                dispatch(
                  openBulkUpdate(true, "Panel List", selectedPanels.length)
                );
                handleClose(ev);
              }}
            >
              Bulk Update
            </Button>
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Snackbar
        id="snackbar-action-alert"
        data-testid="snackbar-action-alert"
        variant={actionSnackbar.variant}
        open={actionSnackbar.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <SnackbarContent
          sx={{ padding: "6px 10px 6px 5px", width: `${props.width}px` }}
          message={
            <React.Fragment>
              <IconButton
                data-testid="icon-btn-close"
                id="icon-btn-close"
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="body2Strong">
                {actionSnackbar.message}
              </Typography>
            </React.Fragment>
          }
          action={getAction(actionSnackbar.actionType)}
        />
      </Snackbar>
    </>
  );
}
