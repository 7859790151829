import {
  call,
  cancel,
  delay,
  fork,
  put,
  takeLeading,
} from "redux-saga/effects";

import { fetchToken, refreshToken } from "../apis/api";
import { getIn } from "../../utilities/objectUtils";
import { segmentIdentify } from "../../utilities/segmentation/segmentationUtils";
import atob from "atob";

export const ATTEMPT_FETCH_TOKEN_ASYNC = "token/ATTEMPT_FETCH_TOKEN_ASYNC";
export const ATTEMPT_REFRESH_TOKEN_ASYNC = "token/ATTEMPT_REFRESH_TOKEN_ASYNC";

export const ATTEMPT_FETCH_TOKEN = "token/ATTEMPT_FETCH_TOKEN";
export const SUCCEED_FETCH_TOKEN = "token/SUCCEED_FETCH_TOKEN";
export const SUCCEED_REFRESH_TOKEN = "token/SUCCEED_REFRESH_TOKEN";
export const FAIL_FETCH_TOKEN = "token/FAIL_FETCH_TOKEN";
export const EXPIRE_SESSION_ASYNC = "token/EXPIRE_SESSION_ASYNC";

export const SET_TIMER_FOR_EXPIRY_MODAL_ASYNC =
  "token/SET_TIMER_FOR_EXPIRY_MODAL_ASYNC";
export const SHOW_SESSION_EXPIRY_MODAL = "token/SHOW_SESSION_EXPIRY_MODAL";
export const FAIL_AUTHENTICATION = "token/FAIL_AUTHENTICATION";
export const CLOSE_FAIL_AUTHENTICATION = "token/CLOSE_FAIL_AUTHENTICATION";

let expiryForkedProccess;

function* showExpiryModal(delayTime) {
  yield delay(delayTime);
  yield put({
    type: SHOW_SESSION_EXPIRY_MODAL,
  });
}

export function* setTimerForExpiryModal({ delayTime }) {
  expiryForkedProccess = yield fork(showExpiryModal, delayTime);
}

export function* attemptFetchToken({ requestBody: unproccessedCode }) {
  yield clearTimeout();

  yield put({
    type: ATTEMPT_FETCH_TOKEN,
  });

  let tokenContents, name, email;
  try {
    const {
      data: { token: claudToken, launchDarklyApiKey },
    } = yield call(fetchToken, unproccessedCode);

    tokenContents = JSON.parse(atob(claudToken.access_token.split(".")[1]));
    const userContactDetails = claudToken.userContactDetails;
    name = [userContactDetails.firstName, userContactDetails.lastName].join(
      " "
    );
    email = userContactDetails.emailAddress;

    yield put({
      type: SUCCEED_FETCH_TOKEN,
      data: {
        claudToken,
        tokenContents,
        launchDarklyApiKey,
      },
      meta: {
        analytics: {
          username: tokenContents.username,
          name,
          email,
        },
      },
    });
  } catch (e) {
    const status = getIn(e, "response.status");
    switch (status) {
      case 401:
      case 403:
      case 404:
        const errorMessage = getIn(e, "response.data.message");
        yield put({
          type: FAIL_AUTHENTICATION,
          payload: errorMessage,
          meta: {
            analytics: {
              username:
                errorMessage && errorMessage.indexOf(":") > 0
                  ? errorMessage.split(":")[1].trim()
                  : "",
            },
          },
        });
        break;
      default:
        yield put({
          type: FAIL_FETCH_TOKEN,
          payload: e,
        });
        break;
    }
  }

  if (tokenContents) {
    const segmentIdentityBody = {
      username: tokenContents.username,
      name,
      email,
    };
    yield segmentIdentify(segmentIdentityBody);
  }
}

export function* attemptRefreshToken({ token, removeListener }) {
  try {
    const {
      data: { token: claudToken },
    } = yield call(refreshToken, token);
    const tokenContents = JSON.parse(
      atob(claudToken.access_token.split(".")[1])
    );

    yield cancel(expiryForkedProccess);
    yield removeListener();

    yield put({
      type: SUCCEED_REFRESH_TOKEN,
      data: {
        claudToken,
        tokenContents,
      },
    });
  } catch (e) {
    yield put({
      type: FAIL_FETCH_TOKEN,
      payload: e,
    });
  }
}

export function* expireSession(param) {
  yield put({
    type: EXPIRE_SESSION_ASYNC,
    meta: {
      analytics: {
        username: param.username,
      },
    },
  });
}

export default function* sagaToken() {
  yield takeLeading(ATTEMPT_FETCH_TOKEN_ASYNC, attemptFetchToken);
  yield takeLeading(ATTEMPT_REFRESH_TOKEN_ASYNC, attemptRefreshToken);
  yield takeLeading(SET_TIMER_FOR_EXPIRY_MODAL_ASYNC, setTimerForExpiryModal);
  yield takeLeading(EXPIRE_SESSION_ASYNC, expireSession);
}
