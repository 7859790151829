import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import { updateSearchOption } from "../../store/action/actionUI";
import {
  Box,
  Divider,
  Grid2 as Grid,
  FormControl,
  Button,
  Tooltip,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridCellParams,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import copyEditOutlined from "../../img/copyEditOutlined.svg";
import {
  FileDownloadOutlined,
  FileUploadOutlined,
  FileCopyOutlined,
  AddOutlined,
} from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import moment from "moment-timezone";
import {
  changeFeeVersionId,
  copyFeeVersion,
  exportFeeVersion,
  getFees,
  importFeeVersion,
  openFilter,
  openImportModal,
  resetValidationErrors,
  showCreateNewFeeSet,
} from "../../store/action/actionFee";
import FeesIcon from "@mui/icons-material/RequestQuoteOutlined";
import FileUploadModal from "../modal/FileUploadModal";
import PanelExSnackbar from "../shared/PanelExSnackbar";
import CreateNewFeeSet from "./CreateNewFeeSet";
import FilterListIcon from "@mui/icons-material/FilterList";
import PanelExLanding from "../shared/PanelExLanding";

export default function Fees() {
  const dispatch = useDispatch();
  const history = useHistory();
  const timezone = moment.tz.guess();
  const timezoneAbbr = moment().tz(timezone).format("z");
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    button: {
      color: theme.palette.common.white,
      "& .MuiButton-startIcon": {
        color: theme.palette.common.white,
      },
    },
    exportButton: {
      color: theme.colors.tertiary.main,
      "& .MuiButton-startIcon": {
        color: theme.colors.tertiary.main,
      },
    },
    select: {
      borderRadius: "4px",
    },
    noResult: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "28px",
      letter: "0.15px",
      color: "#949494",
    },
    warning: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21.98px",
      letterSpacing: "0.1px",
      color: theme.palette.text.primary,
      paddingBottom: "10px",
    },
    location: {
      color: theme.palette.tertiary.main,
    },
  }));

  const handleExport = (
    feePanelId: number,
    feePanelRevId: number,
    feeSetName: string
  ) => {
    dispatch(exportFeeVersion(feePanelId, feePanelRevId, feeSetName));
  };

  const {
    fees,
    rowCount,
    searchText,
    currentPage,
    pageSize,
    sortBy,
    sortDir,
    validationErrors,
    isOpenImportModal,
    isUploading,
    showNewFeeSetDialog,
    newFeeId,
    isFiltered,
    feeSetStatus,
    locations,
    countryId,
    locationText,
    nameSuggestion,
    locationSuggestion,
    loading,
    readOnly,
  } = useSelector((state: RootState) => {
    return {
      fees: state.fee.fees,
      rowCount: state.fee.feeCount,
      currentPage: state.fee.currentPage,
      pageSize: state.fee.pageSize,
      sortBy: state.fee.sortBy || "name",
      sortDir: state.fee.sortDir || "asc",
      validationErrors: state.fee.validationErrors,
      isOpenImportModal: state.fee.isOpenImportModal || false,
      isUploading: state.fee.isUploading || false,
      showNewFeeSetDialog: state.fee.showCreateNewFeeSet || false,
      newFeeId: state.fee.newFeeSet.newFeeId || 0,
      searchText: state.fee.filteredOptions.name || "",
      isFiltered: state.fee.filterOptions.isFiltered,
      locations: state.fee.filteredOptions.locations,
      feeSetStatus: state.fee.filteredOptions.status || "",
      countryId: state.allocation.filteredOptions.countryId || 1,
      locationText: state.allocation.filteredOptions.locationText || "",
      nameSuggestion: state.allocation.filteredOptions.nameSuggestion || [],
      locationSuggestion:
        state.allocation.filteredOptions.locationSuggestion || [],
      loading: state.fee.loading || false,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      hideable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "lastModified",
      headerName: "Last Modified",
      width: 230,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "action",
      headerName: "",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      hideable: false,
      width: 170,
      renderCell: (params: GridRenderCellParams) => {
        const { feePanelId, feeRevId, name, editableRevId } = params.row;
        const index = params.api.getRowIndexRelativeToVisibleRows(feePanelId);
        const hasEditableVersion = !!editableRevId;
        return (
          <>
            <Tooltip title={`Export Version ID${feeRevId}`}>
              <span>
                <IconButton
                  color="primary"
                  id={`btn-fee-export-${index}`}
                  data-testid={`btn-fee-export-${index}`}
                  aria-label="Export latest"
                  disabled={feeRevId === null}
                  onClick={(e) => {
                    handleExport(feePanelId, feeRevId, name);
                    e.preventDefault();
                  }}
                >
                  <FileDownloadOutlined sx={{ fontSize: 19 }} />
                </IconButton>
              </span>
            </Tooltip>
            {!readOnly && (
              <>
                <Tooltip title="Import data">
                  <IconButton
                    color="primary"
                    id={`btn-fee-import-${index}`}
                    data-testid={`btn-fee-import-${index}`}
                    aria-label="Import fee data"
                    onClick={() => {
                      dispatch(openImportModal(true));
                      setSelectedFeeId(feePanelId);
                      setSelectedFeeName(name);
                    }}
                  >
                    <FileUploadOutlined sx={{ fontSize: 19 }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Duplicate | save as">
                  <IconButton
                    color="primary"
                    id={`btn-fee-duplicate-${index}`}
                    data-testid={`btn-fee-duplicate-${index}`}
                    aria-label="Duplicate"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <FileCopyOutlined sx={{ fontSize: 19 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`${
                    hasEditableVersion
                      ? "Go to editable version"
                      : "Copy & edit active version"
                  }`}
                >
                  <IconButton
                    id={`btn-fee-copy-edit-${index}`}
                    data-testid={`btn-fee-copy-edit-${index}`}
                    aria-label="Copy & Edit"
                    onClick={(e) => {
                      if (!hasEditableVersion) {
                        handleCopyNEdit(feePanelId, feeRevId);
                      } else {
                        handleGoToVersion(feePanelId, editableRevId);
                      }
                      e.stopPropagation();
                    }}
                  >
                    <img src={copyEditOutlined} alt="" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  useEffect(() => {
    if (newFeeId > 0) {
      history.push(`/fees/${newFeeId}`);
    }
  }, [newFeeId, history]);

  useEffect(() => {
    setPaginationModel({ page: currentPage, pageSize: pageSize });
  }, [currentPage, pageSize]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  const [selectedFeeId, setSelectedFeeId] = useState(0);
  const [selectedFeeName, setSelectedFeeName] = useState("");
  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchFees(newPage, newPageSize, sortBy, sortDir);
  };

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const fetchFees = (
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDir: string
  ) => {
    const filter = {
      name: searchText,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
      locations,
      status: isFiltered ? feeSetStatus : "",
      isFiltered,
      countryId,
      locationText,
      nameSuggestion,
      locationSuggestion,
    };
    return dispatch(getFees(filter));
  };

  useEffect(() => {
    dispatch(updateSearchOption("fees"));
    fetchFees(currentPage, pageSize, sortBy, sortDir);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: sortBy,
      sort: sortDir,
    },
  ]);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    let field = sortBy;
    let sort = sortDir;
    if (typeof newSortModel[0] !== "undefined") {
      field = newSortModel[0].field;
      sort = newSortModel[0].sort || "desc";
    }
    setSortModel(newSortModel);
    setPaginationModel({ page: 0, pageSize: pageSize });
    fetchFees(0, pageSize, field, sort);
  };

  const handleCopyNEdit = (feeId: number, feePanelRevId: number) => {
    dispatch(copyFeeVersion(feeId, feePanelRevId));
    history.push(`/fees/${feeId}`);
  };

  const handleGoToVersion = (feeId: number, feePanelRevId: number) => {
    dispatch(changeFeeVersionId(feePanelRevId));
    history.push(`/fees/${feeId}`);
  };

  const handleFileUpload = (file: File) => {
    dispatch(importFeeVersion(selectedFeeId, selectedFeeName, file, true));
  };

  const handleClearValidationError = () => {
    dispatch(resetValidationErrors());
  };

  const openCreateNewFeeSet = () => {
    dispatch(showCreateNewFeeSet(true));
  };

  const renderResultTitle = () => {
    return (
      "Showing results for " +
      rowCount +
      (feeSetStatus !== "" ? " " + feeSetStatus : "") +
      " Fee" +
      (rowCount > 1 ? "s " : " ") +
      (searchText.length > 0 ? "matching '" + searchText + "'" : "")
    );
  };

  const renderLocation = (locations: any) => {
    let label = "";
    locations.forEach(
      (location: any, index: number) =>
        (label =
          label + location.name + (index < locations.length - 1 ? "/" : ""))
    );
    return (
      <Typography
        id="filter-location-label"
        data-testid="filter-location-label"
        variant="body2Strong"
        className={classes.location}
      >
        {label.length > 112 ? label.substring(0, 112) + "..." : label}
      </Typography>
    );
  };

  const { classes } = useStyles();
  return (
    <Grid container>
      <PanelExLanding />
      <Grid
        container
        size={12}
        sx={{ display: "flex", justifyContent: "center" }}
        data-testid="feeList"
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            paddingLeft: "calc(3px + 1.5625vw)",
            paddingRight: "calc(3px + 1.5625vw)",
            width: "100%",
          }}
        >
          <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <Grid
              size={6}
              display="flex"
              justifyContent="left"
              alignItems="center"
            >
              <FeesIcon
                sx={{ width: "24px", height: "24px", paddingRight: "6px" }}
                id="fee-title-icon"
                data-testid="fee-title-icon"
              />
              <Typography
                id="fee-title"
                data-testid="fee-title"
                variant="h3"
                component="h3"
              >
                {renderResultTitle()}
              </Typography>
            </Grid>
            <Grid size={6}>
              <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
                {!readOnly && (
                  <FormControl size="small">
                    <Button
                      id="btn-fee-create"
                      data-testid="btn-fee-create"
                      className={classes.button}
                      variant="contained"
                      startIcon={<AddOutlined />}
                      onClick={() => openCreateNewFeeSet()}
                    >
                      Create Fee
                    </Button>
                  </FormControl>
                )}
              </Stack>
            </Grid>
            <Grid size={12}>
              {locations.length > 0 && (
                <Typography
                  id="filter-location"
                  data-testid="filter-location"
                  variant="body2Strong"
                >
                  Location{locations.length > 1 ? "s" : ""}:{" "}
                </Typography>
              )}
              {renderLocation(locations)}
            </Grid>
          </Grid>
          <Divider />
          <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <Grid size={10}>
              <Typography
                id="tbl-info"
                data-testid="tbl-info"
                variant="body2"
                component="h6"
                sx={{ color: theme.palette.text.secondary }}
              >
                Displaying {fees.length} of {rowCount} fee
                {rowCount > 1 ? "s" : ""}
              </Typography>
            </Grid>
            <Grid size={2} sx={{ display: "flex", justifyContent: "end" }}>
              {isFiltered && rowCount > 0 && (
                <Button
                  id="btn-export"
                  data-testid="btn-export"
                  variant="text"
                  className={classes.exportButton}
                  startIcon={<FileDownloadOutlined />}
                >
                  Export Result
                </Button>
              )}
            </Grid>
          </Grid>
          <Box
            sx={{
              minHeight: 400,
              width: "100%",
              display: `${rowCount === 0 ? "flex" : ""}`,
              flexDirection: "column",
              justifyContent: `${rowCount === 0 ? "center" : ""}`,
              alignItems: `${rowCount === 0 ? "center" : ""}`,
            }}
          >
            {loading ? (
              <Grid size={12}>
                <Box
                  id="fee-sets-progress"
                  data-testid="fee-sets-progress"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CircularProgress />
                </Box>
              </Grid>
            ) : rowCount === 0 ? (
              <Grid container>
                <Grid
                  size={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="no-result"
                    data-testid="no-result"
                    variant="subtitle1"
                    className={classes.noResult}
                  >
                    No Result found
                  </Typography>
                </Grid>
                <Grid
                  size={12}
                  sx={{
                    paddingTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="edit-message"
                    data-testid="edit-message"
                    variant="subtitle2"
                  >
                    Try changing or removing same of your filters
                  </Typography>
                </Grid>
                <Grid
                  size={12}
                  sx={{
                    paddingTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    id="filter-button"
                    data-testid="filter-button"
                    variant="contained"
                    startIcon={<FilterListIcon />}
                    size="medium"
                    onClick={() => dispatch(openFilter(true))}
                  >
                    <span>Edit Filters</span>
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <DataGridPro
                loading={loading}
                style={{ cursor: "pointer" }}
                className="tbl-fees"
                getRowId={(row) => row.feePanelId}
                rows={fees}
                rowCount={rowCountState}
                columns={columns}
                disableColumnFilter={true}
                pagination
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={[15, 20, 50]}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : ""
                }
                onCellClick={(
                  params: GridCellParams,
                  event: MuiEvent<React.MouseEvent>
                ) => {
                  params.field !== "action" &&
                    history.push(`/fees/${params.row.feePanelId}`);
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </Box>
        </Box>
        <PanelExSnackbar />
        <FileUploadModal
          title="Import Fee File"
          submitText="Import Fee File"
          open={isOpenImportModal}
          onClose={() => {
            dispatch(openImportModal(false));
            dispatch(resetValidationErrors());
          }}
          onFileUpload={handleFileUpload}
          validationErrors={validationErrors}
          clearValidationError={handleClearValidationError}
          isUploading={isUploading}
        />
      </Grid>
      {showNewFeeSetDialog && <CreateNewFeeSet />}
    </Grid>
  );
}
