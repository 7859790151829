import { FETCH_FEATURE_FLAGS } from "../staticSagas/sagaLaunchDarkly";
export const initialState = {
  featureFlags: {},
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS: {
      return {
        ...state,
        featureFlags: { ...action.payload },
      };
    }
    case "persist/REHYDRATE":
      // clear error on refresh. but weird rehydrate errors come in all the time, so don't clear it on those
      return {
        ...state,
      };
    default:
      return state;
  }
}
