import React from "react";
import {
  Button,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useDispatch, useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import { RootState } from "../../store/staticReducers/reducers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  updateOrgTextChangeFilter,
  addFunderFilter,
  deleteClientFilter,
  addValfirmFilter,
  addClientFilter,
  deleteFunderFilter,
  deleteValfirmFilter,
  updatePropTypeTextChangeFilter,
  updateServiceTypeTextChangeFilter,
  addServiceTypeFilter,
  deleteServiceTypeFilter,
  addPropTypeFilter,
  deletePropTypeFilter,
  updatePanelTypeFilter,
  updateBracketMinFilter,
  updateBracketMaxFilter,
  updateShowMore,
  addAllocationFilterLocation,
  deleteAllocationFilterLocation,
  changeAllocationFilterCountry,
  filterAllocationNameChange,
  filterAllocationLocationChange,
  addFeeFilterLocation,
  deleteFeeFilterLocation,
  changeFeeFilterCountry,
  filterFeeNameChange,
  filterFeeLocationChange,
  updatePanelStatusFilter,
} from "../../store/action/actionPanel";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

export default function PanelFilterOptions() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    select: {
      borderRadius: "4px",
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      alignItems: "center",
    },
    bubble: {
      display: "flex",
      margin: "3px",
      padding: "3px 4px",
      alignItems: "center",
      borderRadius: "16px",
      background: grey[200],
    },
    bubbleText: {
      paddingLeft: "4px",
      color: theme.palette.text.primary,
    },
    bubbleIcon: {
      padding: "4px",
      "& .MuiSvgIcon-root": {
        color: `${theme.colors.grey.main}`,
        width: "16px",
        height: "16px",
      },
    },
    filterTitle: {
      color: theme.palette.text.primary,
      paddingBottom: "8px",
    },
    autocomplete: {
      padding: "2px 0px 2px 0px",
      width: "550px",
      height: "38px",
      background: "#FFFFFF",
      borderRadius: "4px",
      "&:focus": {
        border: "2px solid #3399FF",
      },
      "& .MuiTextField-root": {
        marginTop: "-3px",
      },
      "& .MuiInputBase-root": {
        height: "38px",
      },
      "& .MuiOutlinedInput-root": {
        paddingRight: "9px !important",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
    infoIcon: {
      width: "18px",
      height: "18px",
      marginLeft: "4px",
    },
    textBox: {
      background: theme.palette.common.white,
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #panel-name-helper-text": {
        textTransform: "none",
      },
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.error.main,
      },
    },
    setTabs: {
      alignItems: "center",
      padding: "4px 6px",
      background: theme.colors.grey.lightest,
      height: "44px",
      borderRadius: "4px",

      "& .MuiTabs-indicator": {
        height: "0px",
      },
      "& .MuiTabs-flexContainer": {
        padding: "0px 6px",
        justifyContent: "space-between",
      },
      "& .MuiTab-root": {
        color: theme.colors.grey.dark,
        padding: "7px 6px",
        height: "30px",
        minHeight: "30px",
        borderRadius: "4px",

        "&.Mui-selected": {
          background: theme.palette.common.white,
          color: theme.palette.tertiary.main,
        },
      },
    },
    section: {
      padding: "24px 80px 24px 80px",
    },
  }));
  const { classes } = useStyles();

  const {
    panelSetStatus,
    funderText,
    funderSuggestion,
    funders,
    clientText,
    clientSuggestion,
    clients,
    valfirmText,
    valfirmSuggestion,
    valfirms,
    propTypeSearchText,
    propTypeSuggestion,
    propertyTypes,
    serviceTypeSearchText,
    serviceTypeSuggestion,
    serviceTypes,
    panelType,
    bracketMin,
    bracketMax,
    showMore,
    allocationNameText,
    allocationNameSuggestion,
    allocationCountryId,
    allocationLocationText,
    allocationLocationSuggestion,
    allocationLocations,
    feeNameText,
    feeNameSuggestion,
    feeCountryId,
    feeLocationText,
    feeLocationSuggestion,
    feeLocations,
    roleName,
  } = useSelector((state: RootState) => {
    return {
      panelSetStatus: state.panel.filterOptions.panelSetStatus || "In Use",
      funderText: state.panel.filterOptions.funderText || "",
      funderSuggestion: state.panel.filterOptions.funderSuggestion || [],
      funders: state.panel.filterOptions.funders || [],
      clientText: state.panel.filterOptions.clientText || "",
      clientSuggestion: state.panel.filterOptions.clientSuggestion || [],
      clients: state.panel.filterOptions.clients || [],
      valfirmText: state.panel.filterOptions.valfirmText || "",
      valfirmSuggestion: state.panel.filterOptions.valfirmSuggestion || [],
      valfirms: state.panel.filterOptions.valfirms || [],
      propTypeSearchText: state.panel.filterOptions.propTypeSearchText || "",
      propTypeSuggestion: state.panel.filterOptions.propTypeSuggestion || [],
      propertyTypes: state.panel.filterOptions.propertyTypes || [],
      serviceTypeSearchText:
        state.panel.filterOptions.serviceTypeSearchText || "",
      serviceTypeSuggestion:
        state.panel.filterOptions.serviceTypeSuggestion || [],
      serviceTypes: state.panel.filterOptions.serviceTypes || [],
      panelType: state.panel.filterOptions.panelType || "Any",
      bracketMin: state.panel.filterOptions.bracketMin || "",
      bracketMax: state.panel.filterOptions.bracketMax || "",
      showMore: state.panel.filterOptions.showMore || false,
      allocationNameText: state.panel.filterOptions.allocationNameText || "",
      allocationNameSuggestion:
        state.panel.filterOptions.allocationNameSuggestion || [],
      allocationCountryId: state.panel.filterOptions.allocationCountryId || 1,
      allocationLocationText:
        state.panel.filterOptions.allocationLocationText || "",
      allocationLocationSuggestion:
        state.panel.filterOptions.allocationLocationSuggestion || [],
      allocationLocations: state.panel.filterOptions.allocationLocations || [],
      feeNameText: state.panel.filterOptions.feeNameText || "",
      feeNameSuggestion: state.panel.filterOptions.feeNameSuggestion || [],
      feeCountryId: state.panel.filterOptions.feeCountryId || 1,
      feeLocationText: state.panel.filterOptions.feeLocationText || "",
      feeLocationSuggestion:
        state.panel.filterOptions.feeLocationSuggestion || [],
      feeLocations: state.panel.filterOptions.feeLocations || [],
      roleName: state.tokenDetails.userContactDetails.roleName,
    };
  });

  const handleOrgInputChange = (input: string, orgType: string) => {
    if (input !== funderText && orgType === "Funder") {
      dispatch(updateOrgTextChangeFilter(input, orgType));
    }
    if (input !== clientText && orgType === "Client") {
      dispatch(updateOrgTextChangeFilter(input, orgType));
    }
    if (input !== valfirmText && orgType === "Valfirm") {
      dispatch(updateOrgTextChangeFilter(input, orgType));
    }
  };

  const handleDeleteFunder = (orgId: number) => {
    dispatch(deleteFunderFilter(orgId));
  };

  const handleDeleteClient = (orgId: number) => {
    dispatch(deleteClientFilter(orgId));
  };

  const handleDeleteValfirm = (orgId: number) => {
    dispatch(deleteValfirmFilter(orgId));
  };

  const handleUpdateFunder = (orgId: number, orgName: string) => {
    dispatch(addFunderFilter(orgId, orgName));
  };

  const handleUpdateClient = (orgId: number, orgName: string) => {
    dispatch(addClientFilter(orgId, orgName));
  };

  const handleUpdateValfirm = (orgId: number, orgName: string) => {
    dispatch(addValfirmFilter(orgId, orgName));
  };

  const handleBracketMinChange = (value: string) => {
    dispatch(updateBracketMinFilter(value));
  };

  const handleBracketMaxChange = (value: string) => {
    dispatch(updateBracketMaxFilter(value));
  };

  const handlePropTypeInputChange = (input: string) => {
    if (input !== propTypeSearchText) {
      dispatch(updatePropTypeTextChangeFilter(input));
    }
  };

  const handleAddPropType = (id: number, name: string) => {
    dispatch(addPropTypeFilter(id, name));
  };

  const handleDeletePropType = (id: number) => {
    dispatch(deletePropTypeFilter(id));
  };

  const handleServiceTypeInputChange = (input: string) => {
    if (input !== serviceTypeSearchText) {
      dispatch(updateServiceTypeTextChangeFilter(input));
    }
  };

  const handleAddServiceType = (id: number, name: string) => {
    dispatch(addServiceTypeFilter(id, name));
  };

  const handleDeleteServiceType = (id: number) => {
    dispatch(deleteServiceTypeFilter(id));
  };

  const handlePanelTypeChange = (value: string) => {
    dispatch(updatePanelTypeFilter(value));
  };

  const handlePanelStatusChange = (value: string) => {
    dispatch(updatePanelStatusFilter(value));
  };

  const handleAllocationCountryChange = (event: SelectChangeEvent) => {
    dispatch(changeAllocationFilterCountry(event.target.value as string));
  };

  const handleAllocationNameInputChange = (text: string) => {
    dispatch(filterAllocationNameChange(text));
  };

  const handleAllocationLocationInputChange = (input: string) => {
    if (input !== allocationLocationText) {
      dispatch(filterAllocationLocationChange(input, allocationCountryId));
    }
  };
  const handleAddAllocationLocation = (location: any) => {
    dispatch(addAllocationFilterLocation(location));
  };

  const handleDeleteAllocationLocation = (
    id: number,
    countryId: number,
    type: string
  ) => {
    dispatch(deleteAllocationFilterLocation(id, countryId, type));
  };

  const handleFeeCountryChange = (event: SelectChangeEvent) => {
    dispatch(changeFeeFilterCountry(event.target.value as string));
  };

  const handleFeeNameInputChange = (text: string) => {
    dispatch(filterFeeNameChange(text));
  };

  const handleFeeLocationInputChange = (input: string) => {
    if (input !== feeLocationText) {
      dispatch(filterFeeLocationChange(input, feeCountryId));
    }
  };
  const handleAddFeeLocation = (location: any) => {
    dispatch(addFeeFilterLocation(location));
  };

  const handleDeleteFeeLocation = (
    id: number,
    countryId: number,
    type: string
  ) => {
    dispatch(deleteFeeFilterLocation(id, countryId, type));
  };

  const createBubble = (
    type: string,
    id: any,
    displayText: string,
    index: number
  ) => {
    return (
      <Grid
        key={`grid-${type}-${index}`}
        data-testid={`grid-${type}-${index}`}
        className={classes.bubble}
      >
        <Typography variant="body3Strong" className={classes.bubbleText}>
          {displayText}
        </Typography>
        <IconButton
          id={`btn-delete-${type}-${index}`}
          data-testid={`btn-delete-${type}-${index}`}
          className={classes.bubbleIcon}
          onClick={(ev) => {
            if (type === "funder-type") {
              handleDeleteFunder(id);
            } else if (type === "valfirm-type") {
              handleDeleteValfirm(id);
            } else if (type === "client-type") {
              handleDeleteClient(id);
            } else if (type === "property-type") {
              handleDeletePropType(id);
            } else {
              handleDeleteServiceType(id);
            }
            ev.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  };

  const createLocationBubble = (
    type: string,
    id: any,
    displayText: string,
    index: number,
    countryId: any,
    locationType: any
  ) => {
    return (
      <Grid
        key={`grid-${type}-${locationType}-${index}`}
        data-testid={`grid-${type}-${locationType}-${index}`}
        className={classes.bubble}
      >
        <Typography variant="body3Strong" className={classes.bubbleText}>
          {displayText}
        </Typography>
        <IconButton
          id={`btn-delete-${type}-${locationType}-${index}`}
          data-testid={`btn-delete-${type}-${locationType}-${index}`}
          className={classes.bubbleIcon}
          onClick={(ev) => {
            if (type === "alloc-location") {
              handleDeleteAllocationLocation(id, countryId, locationType);
            } else {
              handleDeleteFeeLocation(id, countryId, locationType);
            }
            ev.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid container size={12} sx={{ paddingBottom: "10px" }}>
      <Grid size={12} sx={{ padding: "0px 80px 24px 80px" }}>
        <PanelExAutocomplete
          id="valfirm-filter"
          data-testid="valfirm-filter"
          style={{ width: "100%", marginBottom: "10px" }}
          options={valfirmSuggestion}
          value={valfirmText}
          popperMaxHeight={400}
          getOptionLabel={(option: any) => option.name || valfirmText || ""}
          placeholder="All Valfirms"
          isFilterOptions={true}
          onInputChange={(value: any) => {
            handleOrgInputChange(value, "Valfirm");
          }}
          renderOption={(props: any, option: any) => {
            const { id, name } = option;

            const isExisting =
              valfirms.find((v: any) => v.id === id) !== undefined;
            const regex = new RegExp(
              escapedSpecialCharacters(valfirmText),
              "gi"
            );
            const response = name.replace(regex, function (str: any) {
              return "<span style='font-weight:500'>" + str + "</span>";
            });
            return (
              !isExisting && (
                <li
                  {...props}
                  data-testid={`add-org-label-${id}`}
                  id={`add-org-label-${id}`}
                  onClick={(ev) => {
                    !isExisting && handleUpdateValfirm(id, name);
                    ev.stopPropagation();
                  }}
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: `${response}` }}
                  ></div>
                  <AddCircleIcon
                    data-testid={`add-org-icon-${id}`}
                    id={`add-org-icon-${id}`}
                    style={{
                      ...{ color: "#757575" },
                    }}
                  />
                </li>
              )
            );
          }}
        />
        {valfirms.length > 0 && (
          <Grid container size={12} sx={{ padding: "0px 0px 10px 0px" }}>
            {valfirms.map((org: any, index: number) =>
              createBubble("valfirm-type", org.id, org.name, index)
            )}
          </Grid>
        )}
        <Stack direction="row" className={classes.stack}>
          <Select
            labelId="alloc-location-filter-country-label"
            id="alloc-location-filter-country"
            data-testid="alloc-location-filter-country"
            value={allocationCountryId}
            className={classes.select}
            onChange={handleAllocationCountryChange}
            sx={{ maxHeight: "38px", marginRight: "4px" }}
          >
            <MenuItem value={"1"}>AU</MenuItem>
            <MenuItem value={"2"}>NZ</MenuItem>
          </Select>
          <PanelExAutocomplete
            id="alloc-location-filter"
            data-testid="alloc-location-filter"
            options={allocationLocationSuggestion}
            style={{ width: "100%" }}
            value={allocationLocationText}
            getOptionLabel={(option: any) =>
              option.name || allocationLocationText || ""
            }
            placeholder="Search Allocations States, Regions or Postcodes"
            onInputChange={(value: any) => {
              handleAllocationLocationInputChange(value);
            }}
            renderOption={(props: any, option: any) => {
              const { id, name, type, countryName, isExisting } = option;
              const displayName =
                type === "Country"
                  ? [name, type].join(" ")
                  : [countryName, type, name].join(" ");
              const regex = new RegExp(
                escapedSpecialCharacters(allocationLocationText),
                "gi"
              );
              const response = displayName.replace(regex, function (str: any) {
                return "<span style='font-weight:500'>" + str + "</span>";
              });
              return (
                !isExisting && (
                  <li
                    id={`alloc-add-location-label-${id}`}
                    data-testid={`alloc-add-location-label-${id}`}
                    {...props}
                    style={{ justifyContent: "space-between" }}
                    onClick={(ev) => {
                      !isExisting && handleAddAllocationLocation(option);
                      ev.stopPropagation();
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: `${response}` }}
                    ></div>
                    <AddCircleIcon
                      id={`alloc-add-location-icon-${id}`}
                      data-testid={`alloc-add-location-icon-${id}`}
                      style={{
                        ...{ color: "#757575" },
                      }}
                    />
                  </li>
                )
              );
            }}
          />
        </Stack>
        {allocationLocations.length > 0 && (
          <Grid container size={12} sx={{ padding: "10px 0px 0px 0px" }}>
            {allocationLocations.map((location: any, index: number) =>
              createLocationBubble(
                "alloc-location",
                location.id,
                location.name,
                index,
                location.countryId,
                location.type
              )
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        size={12}
        sx={{ padding: "0px 80px 0px 80px", maxHeight: "700px" }}
      >
        <Grid size={12}>
          <Button
            id="showmore"
            data-testid="showmore"
            variant="text"
            size="small"
            onClick={(ev) => {
              dispatch(updateShowMore(!showMore));
              ev.stopPropagation();
            }}
          >
            {!showMore ? "Show More Filters" : "Show Less Filters"}
          </Button>
        </Grid>
      </Grid>

      {showMore && (
        <Grid size={12}>
          <Grid container className={classes.section}>
            <PanelExAutocomplete
              id="alloc-filter-name"
              data-testid="alloc-filter-name"
              style={{ width: "100%" }}
              options={allocationNameSuggestion}
              value={allocationNameText}
              getOptionLabel={(option) =>
                option.name || allocationNameText || ""
              }
              placeholder="All Allocations Names"
              onInputChange={(newInputValue) => {
                handleAllocationNameInputChange(newInputValue);
              }}
              renderOption={(props, option) => {
                const displayName = option.name;
                const regex = new RegExp(
                  escapedSpecialCharacters(allocationNameText),
                  "gi"
                );
                const response = displayName.replace(
                  regex,
                  function (str: any) {
                    return "<span style='font-weight:500'>" + str + "</span>";
                  }
                );
                return (
                  <li
                    {...props}
                    dangerouslySetInnerHTML={{
                      __html: `<div>${response}</div>`,
                    }}
                  ></li>
                );
              }}
            />
          </Grid>
          <Grid
            container
            className={classes.section}
            sx={{ backgroundColor: theme.colors.neutral.lightest }}
          >
            <Typography variant="subtitle2" className={classes.filterTitle}>
              Service Type & Property Type
            </Typography>
            <PanelExAutocomplete
              data-testid="service-type"
              id="service-type"
              style={{ width: "100%", marginBottom: "10px" }}
              options={serviceTypeSuggestion}
              value={serviceTypeSearchText}
              popperMaxHeight={400}
              getOptionLabel={(option: any) =>
                option.name || serviceTypeSearchText || ""
              }
              placeholder="All Service Types"
              onInputChange={(value: any) => {
                handleServiceTypeInputChange(value);
              }}
              renderOption={(props: any, option: any) => {
                const { id, name } = option;
                const isExistingServiceType =
                  serviceTypes.find((v: any) => v.id === id) !== undefined;
                const regex = new RegExp(
                  escapedSpecialCharacters(serviceTypeSearchText),
                  "gi"
                );
                const response = name.replace(regex, function (str: any) {
                  return "<span style='font-weight:500'>" + str + "</span>";
                });
                return (
                  !isExistingServiceType && (
                    <li
                      {...props}
                      data-testid={`add-service-type-label-${id}`}
                      id={`add-service-type-label-${id}`}
                      onClick={(ev) => {
                        !isExistingServiceType &&
                          handleAddServiceType(id, name);
                        ev.stopPropagation();
                      }}
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: `${response}` }}
                      ></div>
                      <AddCircleIcon
                        data-testid={`add-service-type-icon-${id}`}
                        id={`add-service-type-icon-${id}`}
                        style={{
                          ...{ color: "#757575" },
                        }}
                      />
                    </li>
                  )
                );
              }}
            />
            {serviceTypes.length > 0 && (
              <Grid container size={12} sx={{ padding: "0px 0px 10px 0px" }}>
                {serviceTypes.map((service: any, index: number) =>
                  createBubble("service-type", service.id, service.name, index)
                )}
              </Grid>
            )}
            <Stack direction="row" className={classes.stack}>
              <PanelExAutocomplete
                id="prop-type"
                data-testid="prop-type"
                style={{ width: "100%" }}
                options={propTypeSuggestion}
                value={propTypeSearchText}
                popperMaxHeight={400}
                getOptionLabel={(option: any) =>
                  option.name || propTypeSearchText || ""
                }
                placeholder="All Property Types"
                onInputChange={(value: any) => {
                  handlePropTypeInputChange(value);
                }}
                renderOption={(props: any, option: any) => {
                  const { id, name } = option;
                  const isExistingPropType =
                    propertyTypes.find((v: any) => v.id === id) !== undefined;
                  const regex = new RegExp(
                    escapedSpecialCharacters(propTypeSearchText),
                    "gi"
                  );
                  const response = name.replace(regex, function (str: any) {
                    return "<span style='font-weight:500'>" + str + "</span>";
                  });
                  return (
                    !isExistingPropType && (
                      <li
                        {...props}
                        data-testid={`add-prop-type-label-${id}`}
                        id={`add-prop-type-label-${id}`}
                        onClick={(ev) => {
                          !isExistingPropType && handleAddPropType(id, name);
                          ev.stopPropagation();
                        }}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: `${response}` }}
                        ></div>
                        <AddCircleIcon
                          data-testid={`add-prop-type-icon-${id}`}
                          id={`add-prop-type-icon-${id}`}
                          style={{
                            ...{ color: "#757575" },
                          }}
                        />
                      </li>
                    )
                  );
                }}
              />
            </Stack>
            {propertyTypes.length > 0 && (
              <Grid container size={12} sx={{ paddingTop: "10px" }}>
                {propertyTypes.map((propType: any, index: number) =>
                  createBubble(
                    "property-type",
                    propType.id,
                    propType.name,
                    index
                  )
                )}
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.section} spacing={1}>
            <Grid size={roleName === "Admin" ? 6 : 12}>
              <Typography className={classes.filterTitle}>
                Estimated Property Value Range
              </Typography>
              <Stack direction="row" className={classes.stack}>
                <TextField
                  className={classes.textBox}
                  id="panel-value-from"
                  data-testid="panel-value-from"
                  placeholder="$Any"
                  size="small"
                  value={bracketMin}
                  fullWidth={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const input = e.target.value;
                    const regex = /^[0-9\b]+$/;
                    if (input === "" || regex.test(input)) {
                      handleBracketMinChange(input);
                    }
                  }}
                />
                <Typography
                  variant="subtitle2"
                  className={classes.filterTitle}
                  sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  to
                </Typography>
                <TextField
                  className={`${classes.textBox} ${
                    parseInt(bracketMax) < parseInt(bracketMin)
                      ? classes.error
                      : null
                  }`}
                  id="panel-value-to"
                  data-testid="panel-value-to"
                  placeholder="$Any"
                  size="small"
                  value={bracketMax}
                  fullWidth={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const input = e.target.value;
                    const regex = /^[0-9\b]+$/;
                    if (input === "" || regex.test(input)) {
                      handleBracketMaxChange(input);
                    }
                  }}
                />
              </Stack>
            </Grid>
            {roleName === "Admin" && (
              <Grid size={6}>
                <Stack direction="row" className={classes.stack}>
                  <Typography
                    variant="subtitle2"
                    className={classes.filterTitle}
                  >
                    Panel Status
                  </Typography>
                  <Tooltip
                    id={"filter-status-tooltip"}
                    data-testid={"filter-status-tooltip"}
                    title='"In Use" means the set is attached to a live panel'
                    placement="right"
                    arrow
                  >
                    <InfoOutlinedIcon
                      id={"filter-status-tooltip-icon"}
                      data-testid={"filter-status-tooltip-icon"}
                      className={classes.infoIcon}
                      sx={{ color: theme.colors.grey.light }}
                    />
                  </Tooltip>
                </Stack>
                <Select
                  labelId="filter-status-label"
                  id="filter-status"
                  data-testid="filter-status"
                  fullWidth={true}
                  value={panelSetStatus}
                  className={classes.select}
                  onChange={(e: SelectChangeEvent) =>
                    handlePanelStatusChange(e.target.value as string)
                  }
                  sx={{
                    maxHeight: "38px",
                  }}
                >
                  <MenuItem value={"In Use"}>In Use</MenuItem>
                  <MenuItem value={"Not Used"}>Not Used</MenuItem>
                </Select>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            className={classes.section}
            sx={{ backgroundColor: theme.colors.neutral.lightest }}
          >
            <Typography variant="subtitle2" className={classes.filterTitle}>
              Fees
            </Typography>
            <PanelExAutocomplete
              id="fee-filter-name"
              data-testid="fee-filter-name"
              style={{ width: "100%", marginBottom: "10px" }}
              options={feeNameSuggestion}
              value={feeNameText}
              getOptionLabel={(option) => option.name || feeNameText || ""}
              placeholder="All Fees Names"
              onInputChange={(newInputValue) => {
                handleFeeNameInputChange(newInputValue);
              }}
              renderOption={(props, option) => {
                const displayName = option.name;
                const regex = new RegExp(
                  escapedSpecialCharacters(feeNameText),
                  "gi"
                );
                const response = displayName.replace(
                  regex,
                  function (str: any) {
                    return "<span style='font-weight:500'>" + str + "</span>";
                  }
                );
                return (
                  <li
                    {...props}
                    dangerouslySetInnerHTML={{
                      __html: `<div>${response}</div>`,
                    }}
                  ></li>
                );
              }}
            />
            <Stack
              direction="row"
              className={classes.stack}
              sx={{ marginBottom: "10px" }}
            >
              <Select
                labelId="fee-location-filter-country-label"
                id="fee-location-filter-country"
                data-testid="fee-location-filter-country"
                value={feeCountryId}
                className={classes.select}
                onChange={handleFeeCountryChange}
                sx={{
                  maxHeight: "38px",
                  backgroundColor: theme.palette.common.white,
                  marginRight: "4px",
                }}
              >
                <MenuItem value={"1"}>AU</MenuItem>
                <MenuItem value={"2"}>NZ</MenuItem>
              </Select>
              <PanelExAutocomplete
                id="fee-location-filter"
                data-testid="fee-location-filter"
                options={feeLocationSuggestion}
                style={{ width: "100%" }}
                value={feeLocationText}
                getOptionLabel={(option: any) =>
                  option.name || feeLocationText || ""
                }
                placeholder="All Fees States, Regions or Postcodes"
                onInputChange={(value: any) => {
                  handleFeeLocationInputChange(value);
                }}
                renderOption={(props: any, option: any) => {
                  const { id, name, type, countryName, isExisting } = option;
                  const displayName =
                    type === "Country"
                      ? [name, type].join(" ")
                      : [countryName, type, name].join(" ");
                  const regex = new RegExp(
                    escapedSpecialCharacters(feeLocationText),
                    "gi"
                  );
                  const response = displayName.replace(
                    regex,
                    function (str: any) {
                      return "<span style='font-weight:500'>" + str + "</span>";
                    }
                  );
                  return (
                    !isExisting && (
                      <li
                        id={`fee-add-location-label-${id}`}
                        data-testid={`fee-add-location-label-${id}`}
                        {...props}
                        style={{ justifyContent: "space-between" }}
                        onClick={(ev) => {
                          !isExisting && handleAddFeeLocation(option);
                          ev.stopPropagation();
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: `${response}` }}
                        ></div>
                        <AddCircleIcon
                          id={`fee-add-location-icon-${id}`}
                          data-testid={`fee-add-location-icon-${id}`}
                          style={{
                            ...{ color: "#757575" },
                          }}
                        />
                      </li>
                    )
                  );
                }}
              />
            </Stack>
            {feeLocations.length > 0 && (
              <Grid container size={12} sx={{ padding: "0px 0px 10px 0px" }}>
                {feeLocations.map((location: any, index: number) =>
                  createLocationBubble(
                    "fee-location",
                    location.id,
                    location.name,
                    index,
                    location.countryId,
                    location.type
                  )
                )}
              </Grid>
            )}
            <Stack direction="row" className={classes.stack}>
              <Select
                labelId="panel-type-label"
                id="panel-type"
                data-testid="panel-type"
                value={panelType}
                className={classes.select}
                fullWidth={true}
                onChange={(e: SelectChangeEvent) =>
                  handlePanelTypeChange(e.target.value as string)
                }
                sx={{
                  maxHeight: "38px",
                  backgroundColor: theme.palette.common.white,
                }}
              >
                <MenuItem value={"Any"}>All Fee Types</MenuItem>
                <MenuItem value={"Normal"}>Fee Based Panel</MenuItem>
                <MenuItem value={"Quote"}>Quote Panel</MenuItem>
              </Select>
            </Stack>
          </Grid>
          {roleName === "Admin" && (
            <Grid container className={classes.section}>
              <Typography variant="subtitle2" className={classes.filterTitle}>
                Funder & Client
              </Typography>
              <PanelExAutocomplete
                id="funder-filter"
                data-testid="funder-filter"
                style={{ width: "100%", marginBottom: "10px" }}
                options={funderSuggestion}
                value={funderText}
                popperMaxHeight={400}
                getOptionLabel={(option: any) =>
                  option.name || funderText || ""
                }
                placeholder="All Funders"
                onInputChange={(value: any) => {
                  handleOrgInputChange(value, "Funder");
                }}
                renderOption={(props: any, option: any) => {
                  const { id, name } = option;

                  const isExisting =
                    funders.find((v: any) => v.id === id) !== undefined;
                  const regex = new RegExp(
                    escapedSpecialCharacters(funderText),
                    "gi"
                  );
                  const response = name.replace(regex, function (str: any) {
                    return "<span style='font-weight:500'>" + str + "</span>";
                  });
                  return (
                    !isExisting && (
                      <li
                        {...props}
                        data-testid={`add-org-label-${id}`}
                        id={`add-org-label-${id}`}
                        onClick={(ev) => {
                          !isExisting && handleUpdateFunder(id, name);
                          ev.stopPropagation();
                        }}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: `${response}` }}
                        ></div>
                        <AddCircleIcon
                          data-testid={`add-org-icon-${id}`}
                          id={`add-org-icon-${id}`}
                          style={{
                            ...{ color: "#757575" },
                          }}
                        />
                      </li>
                    )
                  );
                }}
              />
              {funders.length > 0 && (
                <Grid container size={12} sx={{ padding: "0px 0px 10px 0px" }}>
                  {funders.map((org: any, index: number) =>
                    createBubble("funder-type", org.id, org.name, index)
                  )}
                </Grid>
              )}
              <PanelExAutocomplete
                id="client-filter"
                data-testid="client-filter"
                style={{ width: "100%" }}
                options={clientSuggestion}
                value={clientText}
                popperMaxHeight={400}
                getOptionLabel={(option: any) =>
                  option.name || clientText || ""
                }
                placeholder="All Clients"
                onInputChange={(value: any) => {
                  handleOrgInputChange(value, "Client");
                }}
                renderOption={(props: any, option: any) => {
                  const { id, name } = option;

                  const isExisting =
                    clients.find((v: any) => v.id === id) !== undefined;
                  const regex = new RegExp(
                    escapedSpecialCharacters(clientText),
                    "gi"
                  );
                  const response = name.replace(regex, function (str: any) {
                    return "<span style='font-weight:500'>" + str + "</span>";
                  });
                  return (
                    !isExisting && (
                      <li
                        {...props}
                        data-testid={`add-org-label-${id}`}
                        id={`add-org-label-${id}`}
                        onClick={(ev) => {
                          !isExisting && handleUpdateClient(id, name);
                          ev.stopPropagation();
                        }}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: `${response}` }}
                        ></div>
                        <AddCircleIcon
                          data-testid={`add-org-icon-${id}`}
                          id={`add-org-icon-${id}`}
                          style={{
                            ...{ color: "#757575" },
                          }}
                        />
                      </li>
                    )
                  );
                }}
              />
              {clients.length > 0 && (
                <Grid container size={12} sx={{ paddingTop: "10px" }}>
                  {clients.map((org: any, index: number) =>
                    createBubble("client-type", org.id, org.name, index)
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
