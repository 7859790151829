import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { parse } from "qs";
import { CLAUD_LOGIN_URL } from "../../../config/claudConfig";
import { fetchToken } from "../../store/action/actionToken";
import { RootState } from "../../store/staticReducers/reducers";
import Modal from "../modal/CustomModal";

function getCode() {
  const query = parse(window.location.search.substr(1));
  return query && query.code;
}

function getError() {
  return parse(window.location.search.substr(1));
}

function goToLogin() {
  window.location.replace(CLAUD_LOGIN_URL);
}

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = useSelector((state: RootState) => {
    const loggedIn = !!state.tokenDetails.claudToken.access_token;
    if (loggedIn) {
      history.push("/panels");
    }
    return {
      error: state.tokenDetails.claudToken.error,
    };
  });

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  if (error) {
    goToLogin();
  }

  useEffect(() => {
    const code = getCode();
    if (code) {
      dispatch(fetchToken(code));
    } else {
      const claudError: any = getError();
      if (claudError && "error" in claudError) {
        let msg = claudError.error;
        if ("error_description" in claudError) {
          msg = msg + " : " + claudError.error_description;
        }
        setErrorMessage(msg);
        setOpenErrorModal(true);
      } else {
        goToLogin();
      }
    }
  }, [dispatch, history]);

  return (
    <div data-testid="loginContent" className="login-manager">
      <Modal
        id="access-denied-modal"
        data-testid="access-denied-modal"
        open={openErrorModal}
        title="Access Denied"
        description={`${errorMessage}.\n Please contact Corelogic if you require access.`}
      />
    </div>
  );
}
