import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import PanelExBreadcrumbs from "../shared/PanelExBreadcrumbs";
import PanelExSnackbar from "../shared/PanelExSnackbar";
import {
  Button,
  Divider,
  FormControl,
  Grid2 as Grid,
  Tab,
  Tabs,
  Box,
  Typography,
  Toolbar,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import DialogContentText from "@mui/material/DialogContentText";
import EditIcon from "@mui/icons-material/Edit";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import {
  getPanelSet,
  getPanelVersionDetail,
  schedulePanelVersionActivation,
  cancelPanelActivationLog,
  changePanelTab,
  copyPanelVersion,
  acknowledgePanelActivationLog,
  getLatestPanelActivationLog,
} from "../../store/action/actionPanel";

import PanelAllocationSet from "./PanelAllocationSet";
import PanelFeeSet from "./PanelFeeSet";
import PanelSettings from "./PanelSettings/PanelSettings";
import moment from "moment-timezone";
import VersionStatusbar from "../shared/VersionStatusbar";
import PanelsIcon from "@mui/icons-material/LanOutlined";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import PanelExDialog from "../shared/PanelExDialog";
import ActivationStatusbar from "../shared/ActivationStatusbar";
import { openFailActivationCancel } from "../../store/action/actionUI";

export default function PanelSet(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    error: {
      color: `${theme.palette.error.main} !important`,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiOutlinedInput-input": {
        color: theme.palette.error.main,
      },
    },
    panelBox: {
      background: "#FFFFFF",
      borderRadius: "6px",
      border: "2px solid #0000001F",
      boxShadow:
        "0px 1px 10px 0px #0000001F, 0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000033",
      padding: "7px 16px 8px 16px",
      display: "flex",
      width: "100%",
    },
  }));
  const { classes } = useStyles();

  const panelId = parseInt(props.panelId);

  useEffect(() => {
    dispatch(getPanelSet(panelId));
  }, [dispatch, panelId]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    panelRevId,
    panelVersionDetail,
    panelSet,
    requestedActivationLog,
    openBackdrop,
    openFailCancel,
    roleName,
    readOnly,
  } = useSelector((state: RootState) => {
    return {
      panelRevId: state.panel.panelVersionId || 0,
      panelVersionDetail: state.panel.panelVersionDetail,
      panelSet: state.panel.panelSet || {},
      requestedActivationLog: state.panel.latestPanelActivationLog,
      panelActivationLog: state.panel.panelActivationLog || {},
      openBackdrop: state.panel.openBackdrop || false,
      openFailCancel: state.ui.openFailCancel || false,
      roleName: state.tokenDetails.userContactDetails.roleName,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const { panelSetRevId: activeVersionId, editableRevId } = panelSet;
  const panelVersionId = panelRevId || activeVersionId || editableRevId || 0;
  const hasEditable = !!panelSet.editableRevId;

  const {
    scheduled,
    lastErrorTime,
    status: activateStatus,
    panelSetRevId: activateRevId,
  } = requestedActivationLog;

  useEffect(() => {
    if (panelVersionId !== 0 && panelId === panelSet.panelSetId) {
      dispatch(getPanelVersionDetail(panelId, panelVersionId));
      dispatch(getLatestPanelActivationLog(panelId));
    }
  }, [dispatch, panelVersionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [openActivation, setOpenActivation] = useState(false);
  const [activateDate, setActivateDate] = React.useState<Dayjs | undefined>(
    dayjs()
  );
  const [minDateTime, setMinDateTime] = React.useState<Dayjs | undefined>(
    dayjs()
  );

  const [tabIndex, setTabIndex] = React.useState("allocation");

  const handleTabIndexChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabIndex(newValue);
  };

  const renderTabPanel = () => {
    switch (tabIndex) {
      case "panel":
        return <PanelSettings panelVersionId={panelVersionId} />;
      case "fee":
        return <PanelFeeSet />;
      default: {
        return <PanelAllocationSet />;
      }
    }
  };

  const getPanelVersionStatus = (
    activeVersionId: number,
    versionId: number,
    editable: boolean
  ) => {
    let status = "Archived";
    if (versionId === activeVersionId) {
      status = "Active";
    } else if (editable) {
      if (activateStatus === "Queued" && versionId === activateRevId) {
        status = "Scheduled";
      } else if (activateStatus === "Error" && activateRevId === versionId) {
        status = "Failed";
      } else {
        status = "Draft";
      }
    }
    return status;
  };

  const {
    panelSetRevId: versionId,
    lastModified,
    editable,
    panelSetActivationLogs,
    allocationSummaryDto: linkedAllocation,
    feeSummaryDto: linkedFee,
    panelType,
  } = panelVersionDetail;

  const versionStatus = getPanelVersionStatus(
    activeVersionId,
    versionId,
    editable
  );

  const fetchVersionTags = () => {
    const timezone = moment.tz.guess();
    const timezoneAbbr = moment().tz(timezone).format("z");
    const lastActivated =
      versionStatus === "Active" &&
      panelSetActivationLogs &&
      panelSetActivationLogs.length
        ? panelSetActivationLogs.find((log: any) => log.status === "Processed")
            .processed
        : null;
    let datetimeFormatted = moment(lastModified)
      .tz(timezone)
      .format("DD-MM-YYYY hh:mm:ss A");
    let dateType = "Last Modified";
    if (versionStatus === "Active") {
      dateType = "Processed";
      datetimeFormatted = moment(lastActivated)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    } else if (versionStatus === "Scheduled") {
      dateType = "For Date";
      datetimeFormatted = moment(scheduled)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    } else if (versionStatus === "Failed") {
      dateType = "Missed Date";
      datetimeFormatted = moment(lastErrorTime)
        .tz(timezone)
        .format("DD-MM-YYYY hh:mm:ss A");
    }

    return (
      <VersionStatusbar
        versionId={versionId}
        versionStatus={versionStatus}
        datetimeFormatted={datetimeFormatted + " " + timezoneAbbr}
        versionType="Panel"
        dateType={dateType}
      />
    );
  };

  const enableActivate = () => {
    if (
      activateRevId !== versionId ||
      (activateRevId === versionId &&
        (activateStatus === "Canceled" || activateStatus === "Error"))
    ) {
      if (panelVersionDetail.panelType === "Normal") {
        return (
          !!panelVersionDetail.feeSummaryDto &&
          !!panelVersionDetail.feeSummaryDto.revId &&
          !!panelVersionDetail.allocationSummaryDto &&
          !!panelVersionDetail.allocationSummaryDto.revId
        );
      } else {
        return (
          !!panelVersionDetail.allocationSummaryDto &&
          !!panelVersionDetail.allocationSummaryDto.revId
        );
      }
    } else {
      return false;
    }
  };

  const changeDates = (newValue: any) => {
    if (newValue != null) {
      setActivateDate(newValue);
    }
  };

  const handlePanelActivation = () => {
    if (activateDate != null) {
      setActivateDate(
        activateDate
          .set("second", 0)
          .set("milliseconds", 0)
          .set("millisecond", 0)
      );
    }
    dispatch(
      schedulePanelVersionActivation(panelId, panelVersionId, activateDate)
    );
    setOpenActivation(false);
  };

  const handleOpenActivation = () => {
    setMinDateTime(dayjs().set("second", 0).set("millisecond", 0));
    setActivateDate(dayjs().set("second", 0).set("millisecond", 0));
    setOpenActivation(true);
  };

  const showActivationStatusbar = (panelRevId: number, status: string) => {
    let show = false;
    if (
      panelRevId === editableRevId &&
      (status === "Queued" || status === "Error")
    ) {
      show = true;
    }
    return show;
  };

  const handleCancelSchedule = (
    activationLogId: number,
    activationVersionId: number
  ) => {
    dispatch(
      cancelPanelActivationLog(panelId, activationLogId, activationVersionId)
    );
  };

  const handleAcknowledgeSchedule = (
    activationLogId: number,
    activationVersionId: number
  ) => {
    dispatch(
      acknowledgePanelActivationLog(
        panelId,
        activationLogId,
        activationVersionId
      )
    );
  };

  const handleVersionTabChange = () => {
    dispatch(changePanelTab(0));
  };

  const handleCloseFailActivationCancel = () => {
    dispatch(openFailActivationCancel(false));
  };

  const handleCreateDraft = () => {
    dispatch(copyPanelVersion(panelId, activeVersionId));
  };

  return (
    <Grid container>
      <Grid
        container
        size={12}
        display="flex"
        justifyContent="center"
        data-testid="panel-set"
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            padding: "27px 45px 27px 45px",
            maxWidth: "1400px",
          }}
        >
          <Toolbar variant="dense" />
          {panelId !== panelSet.panelSetId ? (
            <Grid size={12}>
              <Box
                data-testid="panel-set-progress"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </Box>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid
                container
                data-testid="panel-set-content"
                sx={{
                  paddingBottom: "3px",
                  height: "47px",
                  alignItems: "center",
                }}
              >
                <Grid size={6}>
                  <PanelExBreadcrumbs currentPage={panelVersionDetail.name} />
                </Grid>
                <Grid size={6} sx={{ display: "flex", justifyContent: "end" }}>
                  {requestedActivationLog &&
                    showActivationStatusbar(
                      requestedActivationLog.panelSetRevId,
                      requestedActivationLog.status
                    ) && (
                      <ActivationStatusbar
                        activationLogId={
                          requestedActivationLog.panelSetActivationLogId
                        }
                        versionId={requestedActivationLog.panelSetRevId}
                        activationStatus={requestedActivationLog.status}
                        scheduledDatetime={requestedActivationLog.scheduled}
                        failedDatetime={
                          requestedActivationLog.lastErrorTime || ""
                        }
                        onCancelSchedule={handleCancelSchedule}
                        onAcknowledgeSchedule={handleAcknowledgeSchedule}
                        onMoveTab={handleVersionTabChange}
                        onCloseFailModal={handleCloseFailActivationCancel}
                        openFailModal={openFailCancel}
                        readOnly={false}
                      />
                    )}
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
                <Grid container size={8}>
                  <Grid sx={{ display: "flex" }}>
                    <PanelsIcon
                      sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: "4px",
                      }}
                      id="panel-title-icon"
                      data-testid="panel-title-icon"
                    />
                    <Typography
                      id="panel-set-title"
                      data-testid="panel-set-title"
                      variant="h3"
                      component="h3"
                    >
                      {panelVersionDetail.name}
                    </Typography>
                  </Grid>
                  <Grid data-testid="panel-version-status-bar">
                    {fetchVersionTags()}
                  </Grid>
                  <Grid>
                    {roleName === "Admin" &&
                      panelVersionDetail.editable &&
                      enableActivate() &&
                      !readOnly && (
                        <Button
                          id="btn-activate-panel"
                          data-testid="btn-activate-panel"
                          size="small"
                          variant="contained"
                          onClick={() => handleOpenActivation()}
                          startIcon={<PowerSettingsNewIcon />}
                          sx={{ paddingLeft: "15px" }}
                        >
                          Activate Panel
                        </Button>
                      )}
                    {roleName === "Admin" &&
                      !hasEditable &&
                      versionStatus === "Active" &&
                      !readOnly && (
                        <Button
                          id="btn-create-draft"
                          data-testid="btn-create-draft"
                          size="small"
                          variant="text"
                          onClick={() => handleCreateDraft()}
                          startIcon={<EditIcon />}
                          sx={{ paddingLeft: "15px" }}
                        >
                          Create Panel Draft
                        </Button>
                      )}
                  </Grid>
                </Grid>
                <Grid size={4} display="flex" justifyContent="flex-end"></Grid>
              </Grid>
              <Grid container>
                <Grid size={12} display="flex" justifyContent="center">
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabIndexChange}
                    textColor="primary"
                  >
                    <Tab
                      data-testid="panel-set-tab-allocation"
                      id="panel-set-tab-allocation"
                      value="allocation"
                      label="Allocation"
                      className={`${
                        linkedAllocation && linkedAllocation.id
                          ? null
                          : classes.error
                      }`}
                    />
                    <Tab
                      data-testid="panel-set-tab-fee"
                      id="panel-set-tab-fee"
                      value="fee"
                      label="Fee"
                      disabled={panelVersionDetail.panelType === "Quote"}
                      className={`${
                        panelType === "Normal" && linkedFee === null
                          ? classes.error
                          : null
                      }`}
                    />
                    <Tab
                      data-testid="panel-set-tab-settings"
                      id="panel-tab-settings"
                      value="panel"
                      label="Panel Settings"
                    />
                  </Tabs>
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ paddingTop: "10px" }}>
                <Grid className={classes.panelBox}>{renderTabPanel()}</Grid>
              </Grid>
            </React.Fragment>
          )}
        </Box>
        <PanelExSnackbar />
        <Backdrop
          id="backdrop"
          data-testid="backdrop"
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <PanelExDialog
          id="activate-dialog"
          data-testid="activate-dialog"
          open={openActivation}
          title={`Activate version ${panelVersionId}`}
          submitText="Agree and Activate"
          submitEnabled={true}
          onClose={() => setOpenActivation(false)}
          onSubmit={handlePanelActivation}
          showAction={versionStatus !== "Archived"}
          maxWidth="xs"
        >
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "5",
              padding: "10px",
              maxWidth: "sm",
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 420 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={1}>
                  <Grid size={6}>
                    <DesktopDateTimePicker
                      sx={{ mt: 0, minWidth: 240 }}
                      data-testid="panel-activate-dialog-date"
                      format="DD-MM-YYYY hh:mm a"
                      value={activateDate}
                      onChange={(newValue) => changeDates(newValue)}
                      minDateTime={minDateTime}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormControl>
            <DialogContentText
              id="panel-activate-dialog-text"
              data-testid="panel-activate-dialog-text"
            >
              <br />I agree and acknowledge that all the allocation details are
              verified and correct
            </DialogContentText>
          </Box>
        </PanelExDialog>
      </Grid>
    </Grid>
  );
}
