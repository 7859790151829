import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { Chip } from "@mui/material";
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined";
import FmdBadIcon from "@mui/icons-material/FmdBad";

interface ValfirmAdditionalStatusProps {
  additionalStatus: string;
}

export default function ValfirmAdditionalStatus(
  props: ValfirmAdditionalStatusProps
) {
  const { additionalStatus } = props;
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    notTrading: {
      backgroundColor: theme.palette.neutral.lightest,
      color: theme.colors.grey.main,
    },
    atCapacity: {
      backgroundColor: theme.palette.error.lightest,
      color: theme.colors.error.darker,
    },
  }));

  const getStatusLabel = (additionalStatus: string) => {
    let statusLabel = "";
    switch (additionalStatus) {
      case "NOTTRADING":
        statusLabel = "No Longer Trading";
        break;
      case "ATCAPACITY":
        statusLabel = "At Capacity";
        break;
    }
    return statusLabel;
  };

  const getStatusStyle = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return classes.notTrading;
      case "ATCAPACITY":
        return classes.atCapacity;
    }
  };

  const getStatusIcon = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return (
          <WorkOffOutlinedIcon
            id="no-longer-trading-icon"
            data-testid="no-longer-trading-icon"
            className={classes.notTrading}
          />
        );
      case "ATCAPACITY":
        return (
          <FmdBadIcon id="at-capacity-icon" data-testid="at-capacity-icon" />
        );
    }
  };

  const { classes } = useStyles();

  return (
    <Chip
      label={getStatusLabel(additionalStatus)}
      className={getStatusStyle(additionalStatus)}
      variant={additionalStatus === "ATCAPACITY" ? "filled" : "outlined"}
      icon={getStatusIcon(additionalStatus)}
    />
  );
}
