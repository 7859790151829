import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { Grid2 as Grid, Typography, Tooltip } from "@mui/material";

interface VersionStatusbarProps {
  versionId: number;
  versionStatus: string;
  datetimeFormatted: string;
  versionType: string;
  dateType: string;
}
export default function VersionStatusbar(props: VersionStatusbarProps) {
  const { versionId, versionStatus, datetimeFormatted, versionType, dateType } =
    props;
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    scheduledBox: {
      display: "flex",
      padding: "6px 6px 6px 6px",
      alignItems: "center",
    },
    statusBox: {
      display: "flex",
      height: "20px",
      padding: "2px 8px 2px 8px",
      border: `1px solid ${theme.colors.grey.lighter}`,
      color: theme.palette.common.black,
      letterSpacing: "0.5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    contentBox: {
      display: "flex",
      color: theme.palette.common.black,
      letterSpacing: "0.16px",
      marginLeft: "5px",
      marginRight: "5px",
      height: "28px",
      padding: "0px 6px 0px 4px",
      alignItems: "center",
    },
  }));
  const { classes } = useStyles();

  return (
    <>
      <Grid
        container
        data-testid="version-status-bar"
        id="version-status-bar"
        className={classes.scheduledBox}
      >
        <Grid
          className={classes.statusBox}
          sx={{
            backgroundColor: `${
              versionStatus === "Draft"
                ? theme.palette.draft.light
                : versionStatus === "Active"
                ? theme.palette.success.lightest
                : versionStatus === "Scheduled"
                ? theme.palette.warning.lighter
                : versionStatus === "Failed"
                ? theme.palette.error.lightest
                : theme.palette.common.white
            }`,
          }}
        >
          <Tooltip
            data-testid="version-datetime"
            id="version-datetime"
            title={
              <Typography variant="body3Strong">
                V{versionId} | {dateType + " : " + datetimeFormatted}
              </Typography>
            }
            arrow={true}
          >
            <Typography
              data-testid="version-status"
              id="version-status"
              variant="small"
            >
              {versionStatus.toUpperCase() + " " + versionType.toUpperCase()}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}
