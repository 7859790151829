import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  deleteValfirm,
  updateActiveValfirmTextChange,
  updateSelectedLocation,
  updateValfirm,
  updateNewLocationTextChange,
} from "../../store/action/actionUI";
import { updateAllocationDetail } from "../../store/action/actionAllocation";
import {
  Box,
  FormControl,
  Grid2 as Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import PanelExDialog from "../shared/PanelExDialog";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";
import ValfirmAdditionalStatus from "../shared/ValfirmAdditionalStatus";

interface LocationModalProps {
  allocationId: number;
  allocationVersionId: number;
  location: LocationProps | null;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  newLocation?: boolean | false;
}

interface LocationProps {
  id: number;
  type: string;
  name: string;
  isExisting?: boolean;
  countryId: number;
  countryName: string;
}

const LocationModal: React.FC<LocationModalProps> = ({
  allocationId,
  allocationVersionId,
  location,
  open,
  setOpen,
  newLocation,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    headerGrid: {
      "& .MuiAutocomplete-root": {
        width: "100%",
      },
    },
    valfirmGrid: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
      "& .MuiTableCell-root": {
        padding: "8px",
        height: "50px",
      },
      "& .MuiFormControl-root": {
        height: "auto",
      },
    },
    allocationText: {
      width: "70px",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    warning: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.warning.main,
      },
    },
    notTrading: {
      color: theme.palette.text.disabled,
    },
    atCapacity: {
      color: theme.colors.error.darker,
    },
  }));
  const { classes } = useStyles();

  const {
    orgSearchText,
    orgSuggestions,
    valfirms,
    quote,
    maxProviders,
    locationSearchText,
    locationSuggestions,
    countryId,
  } = useSelector((state: RootState) => {
    return {
      orgSearchText: state.ui.orgSearchText,
      orgSuggestions: state.ui.orgSuggestion,
      valfirms: state.ui.valfirms,
      quote: state.allocation.allocationVersionDetail.quote,
      maxProviders: state.allocation.allocationVersionDetail.maxProviders,
      locationSearchText: state.ui.locationSearchText || "",
      locationSuggestions: state.ui.locationSuggestion || [],
      countryId: state.allocation.allocationSet.countryId || 1,
    };
  });

  const valfirmsWithoutDeleted = valfirms.filter(
    (row: any) => !(!!row.allocationPanelAllocationsId && row.isDeleted)
  );
  let noOfFirms = 1;
  if (quote) {
    if (
      valfirmsWithoutDeleted.length === 0 ||
      valfirmsWithoutDeleted.length > maxProviders
    ) {
      noOfFirms = maxProviders;
    } else {
      noOfFirms = valfirmsWithoutDeleted.length;
    }
  }
  const maxPercentage = quote ? noOfFirms * 100 : 100;
  const allocationSum = valfirmsWithoutDeleted
    .filter(
      (row: any) => !(!!row.allocationPanelAllocationsId && row.isDeleted)
    )
    .reduce((acc: any, v: any) => acc + parseInt(v.value || 0), 0);

  const hasEmptyAllocation =
    valfirmsWithoutDeleted.filter(
      (v: any) => v.value === undefined || !String(v.value)
    ).length > 0;

  const locationSubmitEnabled =
    allocationSum === maxPercentage && !hasEmptyAllocation;

  const handleOrgInputChange = (input: string) => {
    if (input !== orgSearchText) {
      dispatch(
        updateActiveValfirmTextChange(location && location.countryName, input)
      );
    }
  };

  const handleUpdateValfirm = (
    allocationPanelAllocationsId: any,
    valfirmId: number,
    valfirmName: string,
    value: any,
    addNew: boolean
  ) => {
    dispatch(
      updateValfirm(
        allocationPanelAllocationsId,
        valfirmId,
        valfirmName,
        value,
        displayName,
        addNew,
        allocationVersionId
      )
    );
  };

  const handleDeleteValfirm = (
    allocationPanelAllocationsId: any,
    valfirmId: number,
    valfirmName: string
  ) => {
    dispatch(
      deleteValfirm(
        allocationPanelAllocationsId,
        valfirmId,
        valfirmName,
        displayName,
        allocationVersionId
      )
    );
  };

  const handleSubmit = () => {
    dispatch(
      updateAllocationDetail(
        allocationId,
        allocationVersionId,
        location,
        valfirms
      )
    );
    setOpen(false);
  };

  const displayName = location
    ? location.type === "Country"
      ? location.name
      : [location.countryName, location.type, location.name].join(" ")
    : "";

  const [rowToDelete, setRowToDelete] = useState(null);

  const getStatusStyle = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return classes.notTrading;
      case "ATCAPACITY":
        return classes.atCapacity;
    }
  };

  const handleInputChange = (input: string) => {
    if (input !== locationSearchText) {
      dispatch(
        updateNewLocationTextChange(
          input,
          countryId,
          allocationId,
          allocationVersionId,
          false
        )
      );
    }
  };

  const locationAnchorEl =
    document.getElementById("add-location-filter") || undefined;

  const handleEditLocation = (
    id: number,
    type: string,
    name: string,
    isExisting: boolean,
    countryId: number,
    countryName: string,
    allocationPanelFilterId: any
  ) => {
    dispatch(
      updateSelectedLocation(
        id,
        type,
        name,
        isExisting,
        countryId,
        countryName,
        allocationPanelFilterId,
        allocationVersionId
      )
    );
  };

  return (
    <PanelExDialog
      id="location-dialog"
      data-testid="location-dialog"
      open={open}
      title={newLocation ? "Add Location" : displayName}
      submitText={
        location && location.isExisting ? "Update Allocation" : "Add Location"
      }
      submitEnabled={locationSubmitEnabled}
      onClose={() => setOpen(false)}
      onSubmit={handleSubmit}
      showAction={true}
      headerContent={
        <Grid container size={12} className={classes.headerGrid}>
          {newLocation && (
            <Grid size={12} sx={{ paddingBottom: "12px" }}>
              <PanelExAutocomplete
                id="add-location-filter"
                options={locationSuggestions}
                value={locationSearchText}
                getOptionLabel={(option: any) =>
                  option.name || locationSearchText || ""
                }
                placeholder="Search State, Region or Postcodes"
                onInputChange={(value: any) => {
                  handleInputChange(value);
                }}
                anchor={locationAnchorEl}
                popperPlacement={"bottom-start"}
                renderOption={(props: any, option: any) => {
                  const { id, name, type, countryId, countryName, isExisting } =
                    option;
                  const locationDisplayName =
                    type === "Country"
                      ? [name, type].join(" ")
                      : [countryName, type, name].join(" ");
                  const regex = new RegExp(
                    escapedSpecialCharacters(locationSearchText),
                    "gi"
                  );
                  const response = locationDisplayName.replace(
                    regex,
                    function (str: any) {
                      return "<span style='font-weight:500'>" + str + "</span>";
                    }
                  );
                  return (
                    <li
                      data-testid={`add-location-label-${id}`}
                      {...props}
                      style={{ justifyContent: "space-between" }}
                      onClick={(ev) => {
                        handleEditLocation(
                          id,
                          type,
                          name,
                          isExisting,
                          countryId,
                          countryName,
                          null
                        );
                        ev.stopPropagation();
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: `${response}` }}
                      />
                      <AddCircleIcon
                        data-testid={`add-location-icon-${id}`}
                        style={{ color: "#757575" }}
                      />
                    </li>
                  );
                }}
              />
            </Grid>
          )}
          <Grid size={12}>
            <PanelExAutocomplete
              id="valfirm-filter"
              options={orgSuggestions}
              value={orgSearchText}
              isDisabled={newLocation && displayName === ""}
              getOptionLabel={(option: any) =>
                option.name || orgSearchText || ""
              }
              placeholder="Search Valuation Firms to Allocate"
              onInputChange={(value: any) => {
                handleOrgInputChange(value);
              }}
              renderOption={(props: any, option: any) => {
                const { id, name } = option;
                const isExistingValfirm =
                  valfirmsWithoutDeleted.find(
                    (v: any) => v.valfirmId === id
                  ) !== undefined;
                const regex = new RegExp(
                  escapedSpecialCharacters(orgSearchText),
                  "gi"
                );
                const response = name.replace(regex, function (str: any) {
                  return "<span style='font-weight:500'>" + str + "</span>";
                });
                return (
                  <li
                    {...props}
                    data-testid={`add-valfirm-label-${id}`}
                    id={`add-valfirm-label-${id}`}
                    onClick={(ev) => {
                      !isExistingValfirm &&
                        handleUpdateValfirm(null, id, name, "", true);
                      ev.stopPropagation();
                    }}
                    style={{ justifyContent: "space-between" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: `${response}` }}
                    ></div>
                    <AddCircleIcon
                      data-testid={`add-valfirm-icon-${id}`}
                      id={`add-valfirm-icon-${id}`}
                      style={{
                        ...(isExistingValfirm
                          ? {
                              color: "rgba(0, 0, 0, 0.12)",
                            }
                          : {
                              color: "#757575",
                            }),
                      }}
                    />
                  </li>
                );
              }}
            />
          </Grid>
        </Grid>
      }
    >
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px 20px 30px",
        }}
      >
        <FormControl
          sx={{
            mt: 2,
            mb: 2,
            minWidth: 120,
            minHeight: 591,
            maxHeight: 591,
          }}
        >
          {valfirmsWithoutDeleted.length === 0 ? (
            <Grid
              container
              sx={{
                minHeight: 591,
              }}
              id="search-message"
              data-testid="search-message"
            >
              <Grid
                size={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "150px",
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {newLocation ? (
                    <Typography
                      variant="h3"
                      sx={{
                        paddingBottom: "22px",
                      }}
                    >
                      Get started by adding locations & valuation firms
                    </Typography>
                  ) : (
                    <React.Fragment>
                      <Typography
                        variant="h3"
                        sx={{
                          paddingBottom: "22px",
                        }}
                      >
                        Search to Add Valuation Firms
                      </Typography>
                      <Typography variant="h5">
                        Search for valuation firms to add to this location
                      </Typography>
                    </React.Fragment>
                  )}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <TableContainer component={Paper}>
              <Table
                className={classes.valfirmGrid}
                id="valfirm-table"
                data-testid="valfirm-table"
              >
                <TableBody>
                  {valfirmsWithoutDeleted.map((row: any, index: any) => {
                    const {
                      allocationPanelAllocationsId,
                      valfirmId,
                      valfirmName,
                      value,
                      additionalStatus,
                    } = row;
                    const emptyValue = value === "";
                    return (
                      <TableRow
                        key={valfirmId}
                        style={{
                          backgroundColor:
                            valfirmId === rowToDelete
                              ? theme.palette.info.lighter
                              : "",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          data-testid={`valfirm-table-name-${index}`}
                          className={getStatusStyle(additionalStatus)}
                        >
                          {valfirmName}
                        </TableCell>
                        <TableCell width={100} align="left">
                          {additionalStatus &&
                            additionalStatus != "NONE" &&
                            additionalStatus != "REVIEW" && (
                              <ValfirmAdditionalStatus
                                additionalStatus={additionalStatus}
                              />
                            )}
                        </TableCell>
                        <TableCell width={100} align="right">
                          <TextField
                            id={`valfirm-table-value-${index}`}
                            data-testid={`valfirm-table-value-${index}`}
                            className={`${classes.allocationText} ${
                              emptyValue ? classes.warning : null
                            }`}
                            onClick={(e: any) => e.stopPropagation()}
                            value={value}
                            onChange={(event: any) => {
                              const input = event.target.value;
                              const regex = /^[0-9\b]+$/;
                              if (
                                input === "" ||
                                (regex.test(input) && parseInt(input) < 101)
                              ) {
                                handleUpdateValfirm(
                                  allocationPanelAllocationsId,
                                  valfirmId,
                                  valfirmName,
                                  input,
                                  false
                                );
                              }
                            }}
                            slotProps={{
                              input: {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          data-testid={`delete-valfirm-${index}`}
                          sx={{ width: "32px" }}
                          onClick={(ev) => {
                            handleDeleteValfirm(
                              allocationPanelAllocationsId,
                              valfirmId,
                              valfirmName
                            );
                            ev.stopPropagation();
                          }}
                          onMouseOver={() => setRowToDelete(valfirmId)}
                          onMouseOut={() => setRowToDelete(null)}
                        >
                          <DeleteOutlineIcon
                            style={{
                              color:
                                valfirmId === rowToDelete
                                  ? theme.palette.primary.dark
                                  : theme.palette.info.main,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {valfirmsWithoutDeleted &&
            valfirmsWithoutDeleted.length > 0 &&
            !hasEmptyAllocation &&
            allocationSum !== maxPercentage && (
              <Box
                data-testid="allocation-error"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  padding: "4px 44px 10px 24px",
                }}
              >
                <Typography color="error">
                  {allocationSum > maxPercentage
                    ? `+${
                        allocationSum - maxPercentage
                      }% Over Total of Allocation`
                    : `-${
                        maxPercentage - allocationSum
                      }% Below Total of Allocation`}
                </Typography>
              </Box>
            )}
        </FormControl>
      </Box>
    </PanelExDialog>
  );
};
export default LocationModal;
