import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import ValfirmAdditionalStatusIcon from "../../shared/ValfirmAdditionalStatusIcon";
import PanelExAutocomplete from "../../shared/PanelExAutocomplete";
import AssociatedPanels from "./AssociatedPanels";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import LinkIcon from "@mui/icons-material/Link";
import {
  addReviewPanelId,
  openAssociatedPanels,
} from "../../../store/action/actionPanel";

export default function PreviewData() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    previewContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "0px",
      width: "100%",
    },
    previewTitleContainer: {
      background: theme.colors.neutral.lightest,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "20px",
    },
    previewTitle: {
      maxWidth: "1080px !important",
      padding: "0px 10px 0px 10px",
    },
    previewDesc: {
      color: theme.colors.primary.dark,
      fontWeight: 500,
    },
    previewContent: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100%",
      padding: "12px 60px 12px 60px",
      fontSize: "14px",
      fontWeight: 400,

      "& .MuiDataGrid-columnHeader": {
        height: "36px !important",
        "&.current-data-header": {
          backgroundColor: theme.palette.background.paper,
        },
        "&.new-data-header": {
          backgroundColor: "rgba(247, 247, 250, 1)",
        },
      },
      "& .MuiDataGrid-row": {
        height: "32px !important",
        "& .current-data-cell": {
          backgroundColor: theme.colors.draft.lightest,
        },
        "& .new-data-cell": {
          backgroundColor: "rgba(240, 247, 176, 1)",
        },
      },
    },
    emptyPreview: {
      height: "400px",
      paddingTop: "32px",
      backgroundColor: "rgba(247, 247, 250, 1)",
    },
    notTrading: {
      color: theme.palette.text.disabled,
    },
    atCapacity: {
      color: theme.colors.error.darker,
    },
    warningContainer: {
      marginTop: "-14px",
      padding: "14px 16px 14px 16px",
      background: theme.palette.warning.lightest,
      color: theme.palette.warning.darker,
    },
    assocButton: {
      border: `1px solid ${theme.colors.warning.light}`,
      backgroundColor: theme.palette.common.white,
    },
  }));

  const { classes } = useStyles();

  const {
    allocations,
    previewData,
    previewLoading,
    isOpenAssociatedPanels,
    previewPanelIds,
  } = useSelector((state: RootState) => {
    return {
      allocations: state.panel.bulkUpdate.allocations || [],
      previewData: state.panel.bulkUpdate.previewData || [],
      previewLoading: state.panel.bulkUpdate.previewLoading || false,
      isOpenAssociatedPanels: state.panel.bulkUpdate.isOpenAssociatedPanels,
      previewPanelIds: state.panel.bulkUpdate.previewPanelIds,
    };
  });

  const getStatusStyle = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return classes.notTrading;
      case "ATCAPACITY":
        return classes.atCapacity;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "country",
      headerName: "Country",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      headerClassName: "current-data-header",
    },
    {
      field: "state",
      headerName: "State",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.4,
      headerClassName: "current-data-header",
    },
    {
      field: "region",
      headerName: "Region",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      headerClassName: "current-data-header",
    },
    {
      field: "postcode",
      headerName: "Postcode",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      headerClassName: "current-data-header",
    },
    {
      field: "currentValfirmName",
      headerName: "Valuation Firm",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      headerClassName: "current-data-header",
      renderCell: (params: GridRenderCellParams) => {
        const { additionalStatus, currentValfirmName } = params.row;
        return (
          <>
            <ValfirmAdditionalStatusIcon additionalStatus={additionalStatus} />
            <div className={getStatusStyle(additionalStatus)}>
              {currentValfirmName}
            </div>
          </>
        );
      },
    },
    {
      field: "currentAllocation",
      headerName: "Current Allocation",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.8,
      headerClassName: "current-data-header",
      renderCell: (params: GridRenderCellParams) => {
        return params.row.currentAllocation != null
          ? params.row.currentAllocation + "%"
          : "N/A";
      },
    },
    {
      field: "newAllocation",
      headerName: "New Allocation",
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.8,
      headerClassName: "current-data-header",
      cellClassName: "new-data-cell",
      renderCell: (params: GridRenderCellParams) => {
        return params.row.newAllocation != null
          ? params.row.newAllocation + "%"
          : "N/A";
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
  };

  const [panelToPreview, setPanelToPreview] = useState(null);
  const [assocPanelToPreview, setAssocPanelToPreview] = useState([]);
  const [previewDataToDisplay, setPreviewDataToDisplay] = useState([]);
  const [allocNameToPreview, setAllocNameToPreview] = useState("");
  const [allocationId, setAllocationId] = useState(0);

  const handlePreviewSelected = (option: any) => {
    const {
      selectedVersion,
      allocDraftRevId,
      allocActiveRevId,
      panels,
      allocationName,
      allocationPanelId,
    } = option;
    setPanelToPreview(option);
    setAllocNameToPreview(allocationName);
    setAllocationId(allocationPanelId);

    const assocPanels = panels.filter((panel: any) => !panel.selected) || [];
    setAssocPanelToPreview(assocPanels);

    panels
      .filter(
        (panel: any) =>
          panel.selected && !previewPanelIds.includes(panel.panelSetId)
      )
      .forEach((panel: any) => dispatch(addReviewPanelId(panel.panelSetId)));

    const allocRevId =
      selectedVersion === "draft" ? allocDraftRevId : allocActiveRevId;
    const allocPreviewData = previewData.find(
      (data: any) => data.allocationPanelRevId === allocRevId
    );

    const data =
      allocPreviewData?.bulkUpdateChanges?.filter(
        (response: any) => response.preview === true
      ) || [];

    setPreviewDataToDisplay(data);
  };

  useEffect(() => {
    if (allocations.length > 0) {
      handlePreviewSelected(allocations[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocations, previewData]);

  return (
    <Grid
      container
      size={12}
      spacing={2}
      id="preview-data"
      data-testid="preview-data"
      className={classes.previewContainer}
    >
      <Grid container size={12} className={classes.previewTitleContainer}>
        <Grid container size={12} className={classes.previewTitle}>
          <Grid size={12}>
            <Typography
              id="title-preview-data"
              data-testid="title-preview-data"
              variant="h1"
            >
              Review Changes
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography
              id="preview-data-desc"
              data-testid="preview-data-desc"
              variant={"h6"}
            >
              Select Preview
            </Typography>
          </Grid>

          <Grid size={12}>
            <PanelExAutocomplete
              id="preview-allocation-filter"
              data-testid="preview-allocation-filter"
              options={allocations}
              style={{ width: "100%" }}
              value={panelToPreview}
              getOptionLabel={(option) =>
                `${option.panels
                  .filter((panel: any) => panel.selected)
                  .map(
                    (panel: any) => `${panel.panelName} ID ${panel.panelSetId}`
                  )
                  .join(", ")}`
              }
              placeholder="Select Panel"
              showDropdownIcon={true}
              onChange={(newValue: any) => {
                handlePreviewSelected(newValue);
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <Grid container>
                    <Grid size={12}>
                      <Typography variant="h5">
                        {option.panels
                          .filter((panel: any) => panel.selected)
                          .map(
                            (panel: any) =>
                              `${panel.panelName} ID ${panel.panelSetId}`
                          )
                          .join(", ")}
                      </Typography>
                    </Grid>
                    <Grid size={12}>
                      <Typography variant="body2" color="textSecondary">
                        {option.allocationName}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )}
            />
          </Grid>
        </Grid>

        {assocPanelToPreview.length > 0 && (
          <Grid container size={12} className={classes.previewTitle}>
            <Grid
              container
              size={12}
              className={classes.warningContainer}
              id="assoc-section"
              data-testid="assoc-section"
            >
              <Grid size={8}>
                <Stack spacing={1} direction={"row"}>
                  <ReportProblemOutlinedIcon
                    sx={{ color: "rgb(242, 122, 49)" }}
                  />
                  <Typography
                    variant="h5"
                    id="assoc-section-text"
                    data-testid="assoc-section-text"
                  >
                    Changes will impact all linked associated panels
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                size={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  id="btn-assoc-panel"
                  data-testid="btn-assoc-panel"
                  variant="outlined"
                  color="info"
                  className={classes.assocButton}
                  startIcon={
                    <Badge
                      badgeContent={assocPanelToPreview.length}
                      color="warning"
                    >
                      <LinkIcon color="action" />
                    </Badge>
                  }
                  onClick={() => {
                    dispatch(openAssociatedPanels(true));
                  }}
                >
                  View Associated Panels
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {previewLoading ? (
        <Grid size={12}>
          <Box
            data-testid="preview-data-progress"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <Grid
          container
          size={12}
          className={classes.previewContent}
          display={"flex"}
        >
          <Grid size={12}>
            <Typography
              id="preview-data-allocation"
              data-testid="preview-data-allocation"
              variant={"h5"}
            >
              {allocNameToPreview}
            </Typography>
          </Grid>
          {previewDataToDisplay.length === 0 ? (
            <Grid size={12} className={classes.emptyPreview}>
              <Typography
                id="preview-data-empty-message"
                data-testid="preview-data-empty-message"
                variant={"h3"}
                align="center"
              >
                There are no changes for the allocation linked to this panel.
              </Typography>
            </Grid>
          ) : (
            <DataGridPro
              style={{ cursor: "pointer" }}
              className="tbl-preview"
              getRowId={(row) => row.index}
              rows={previewDataToDisplay}
              columns={columns}
              pagination
              paginationModel={paginationModel}
              paginationMode="client"
              onPaginationModelChange={handlePaginationModelChange}
              pageSizeOptions={[15, 20, 50]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : ""
              }
              sx={{
                backgroundColor: theme.palette.common.white,
              }}
            />
          )}
        </Grid>
      )}
      {isOpenAssociatedPanels && (
        <AssociatedPanels
          allocationId={allocationId}
          allocationName={allocNameToPreview}
        ></AssociatedPanels>
      )}
    </Grid>
  );
}
