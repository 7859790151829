import {
  FETCH_FEES,
  RESET_PAGINATION,
  FETCH_FEE_SET,
  FETCH_FEE_DETAIL,
  CHANGE_FEE_TAB,
  CHANGE_FEE_VERSION_ID,
  FETCH_FEE_VERSION_DETAIL,
  FETCH_LATEST_FEE_ACTIVATION_LOG,
  FETCH_FEE_VERSIONS,
  FETCH_FEE_ACTIVATION_LOGS,
  UPDATE_LOCATION_OPTION,
  UPDATE_LOCATION_TEXT_CHANGE,
  UPDATE_FEE_VERSION_NOTES,
  OPEN_FEE_SET_NAME_MODAL,
  UPDATE_FEE_SET_NAME,
  RESET_UPDATE_NAME_ERROR,
  UPDATE_FEE_DETAIL,
  REMOVE_FEE_LOCATION,
  DISCARD_FEE_VERSION,
  UPDATE_FEE_BRACKETS,
  SCHEDULE_FEE_VERSION_ACTIVATION,
  EXPORT_FEE_VERSION,
  COPY_FEE_VERSION,
  CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION,
  SET_VERSIONS_TO_COMPARE,
  FETCH_VERSIONS_COMPARISON_DETAILS,
  RESET_VERSIONS_TO_COMPARE,
  SHOW_VERSIONS_COMPARISON,
  OPEN_IMPORT_MODAL,
  RESET_VALIDATION_ERRORS,
  IMPORT_FEE_VERSION,
  SHOW_CREATE_NEW_FEE,
  UPDATE_FEENAME_NEW_FEE,
  UPDATE_LOCATION_OPTION_NEW_FEE,
  UPDATE_LOCATION_TEXT_CHANGE_NEW_FEE,
  UPDATE_FEE_BRACKETS_NEW_FEE,
  UPDATE_LOCATION_NEW_FEE,
  REMOVE_FEE_LOCATION_NEW_FEE,
  CREATE_NEW_FEE,
  RESET_PANEL_FEE,
  FETCH_ASSOCIATED_PANELS,
  FILTER_NAME_CHANGE,
  FILTER_LOCATION_CHANGE,
  ADD_FILTER_LOCATION,
  DELETE_FILTER_LOCATION,
  CHANGE_FILTER_COUNTRY,
  CLEAR_ALL_FILTER,
  OPEN_FILTER,
  COPY_FILTER,
  UPDATE_FEE_STATUS_FILTER,
  ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION,
  EXPORT_MULTI_FEES,
} from "../staticSagas/sagaFee";

export function getFees(filter) {
  return {
    type: FETCH_FEES,
    payload: {
      filter,
    },
  };
}

export function resetPagination() {
  return {
    type: RESET_PAGINATION,
  };
}

export function getFeeSet(feeId, feeName) {
  return { type: FETCH_FEE_SET, feeId, feeName };
}

export function getFeeDetail(
  feeId,
  feeRevId,
  currentPage,
  pageSize,
  locationFilter,
  countryFilter
) {
  return {
    type: FETCH_FEE_DETAIL,
    feeId,
    feeRevId,
    currentPage,
    pageSize,
    locationFilter,
    countryFilter,
  };
}

export function changeFeeTab(index) {
  return {
    type: CHANGE_FEE_TAB,
    index,
  };
}

export function getFeeVersionDetail(feeId, feeRevId) {
  return {
    type: FETCH_FEE_VERSION_DETAIL,
    payload: {
      feeId,
      feeRevId,
    },
  };
}

export function getLatestFeeActivationLog(feeId) {
  return { type: FETCH_LATEST_FEE_ACTIVATION_LOG, feeId };
}

export function getFeeVersions(feeId, currentPage, pageSize) {
  return {
    type: FETCH_FEE_VERSIONS,
    payload: {
      feeId,
      currentPage,
      pageSize,
    },
  };
}

export function changeFeeVersionId(id) {
  return {
    type: CHANGE_FEE_VERSION_ID,
    id,
  };
}

export function getFeeActivationLogs(
  feeId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) {
  return {
    type: FETCH_FEE_ACTIVATION_LOGS,
    payload: {
      feeId,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
    },
  };
}

export function updateLocationOption(option) {
  return { type: UPDATE_LOCATION_OPTION, option };
}

export function updateLocationTextChange(
  text,
  option,
  feeId,
  feeRevId,
  isExisting
) {
  return {
    type: UPDATE_LOCATION_TEXT_CHANGE,
    text,
    option,
    feeId,
    feeRevId,
    isExisting,
  };
}

export function updateFeeVersionNotes(feeId, feeVersionId, notes) {
  return {
    type: UPDATE_FEE_VERSION_NOTES,
    payload: {
      feeId,
      feeVersionId,
      notes,
    },
  };
}

export function openFeeSetNameModal(isOpen) {
  return {
    type: OPEN_FEE_SET_NAME_MODAL,
    data: {
      isOpen,
    },
  };
}

export function updateFeeSetName(feeId, name) {
  return {
    type: UPDATE_FEE_SET_NAME,
    payload: {
      feeId,
      name,
    },
  };
}

export function resetUpdateNameError() {
  return {
    type: RESET_UPDATE_NAME_ERROR,
  };
}

export function updateFeeDetail(feeId, feeVersionId, location, fees) {
  return {
    type: UPDATE_FEE_DETAIL,
    payload: {
      feeId,
      feeVersionId,
      location,
      fees,
    },
  };
}

export function removeFeeLocation(
  feeId,
  feeRevId,
  feeFilterId,
  locationName,
  pageSize,
  locationFilter,
  countryId
) {
  return {
    type: REMOVE_FEE_LOCATION,
    payload: {
      feeId,
      feeRevId,
      feeFilterId,
      locationName,
      pageSize,
      locationFilter,
      countryId,
    },
  };
}

export function discardFeeVersion(feeId, feeRevId) {
  return {
    type: DISCARD_FEE_VERSION,
    payload: {
      feeId,
      feeRevId,
    },
  };
}

export function updateFeeBrackets(feeId, feeRevId, feeBrackets) {
  return {
    type: UPDATE_FEE_BRACKETS,
    payload: {
      feeId,
      feeRevId,
      feeBrackets,
    },
  };
}

export function scheduleFeeVersionActivation(
  feeId,
  feeVersionId,
  activateDate
) {
  return {
    type: SCHEDULE_FEE_VERSION_ACTIVATION,
    payload: {
      feeId,
      feeVersionId,
      activateDate,
    },
  };
}

export function exportFeeVersion(feeId, feeVersionId, feeSetName) {
  return {
    type: EXPORT_FEE_VERSION,
    payload: {
      feeId,
      feeVersionId,
      feeSetName,
    },
  };
}

export function copyFeeVersion(feeId, feeVersionId) {
  return {
    type: COPY_FEE_VERSION,
    payload: {
      feeId,
      feeVersionId,
    },
  };
}

export function cancelFeeActivationLog(
  feeId,
  feePanelActivationLogId,
  feePanelActivationVersionId
) {
  return {
    type: CANCEL_SCHEDULE_FEE_VERSION_ACTIVATION,
    payload: {
      feeId,
      feePanelActivationLogId,
      feePanelActivationVersionId,
    },
  };
}

export function setVersionsToCompare(feeVersionId, selected, count, width) {
  return {
    type: SET_VERSIONS_TO_COMPARE,
    payload: {
      feeVersionId,
      selected,
      count,
      width,
    },
  };
}

export function getFeeVersionComparisonDetails(feeId, versionsList) {
  return {
    type: FETCH_VERSIONS_COMPARISON_DETAILS,
    payload: {
      feeId,
      versionsList,
    },
  };
}

export function clearVersionsToCompare() {
  return {
    type: RESET_VERSIONS_TO_COMPARE,
  };
}

export function showVersionsCompare(show) {
  return {
    type: SHOW_VERSIONS_COMPARISON,
    show,
  };
}

export function openImportModal(isOpen) {
  return {
    type: OPEN_IMPORT_MODAL,
    data: {
      isOpen,
    },
  };
}

export function resetValidationErrors() {
  return { type: RESET_VALIDATION_ERRORS };
}

export function importFeeVersion(id, name, file, isRedirect = false) {
  return {
    type: IMPORT_FEE_VERSION,
    payload: {
      id,
      name,
      file,
      isRedirect,
    },
  };
}

export function showCreateNewFeeSet(show) {
  return {
    type: SHOW_CREATE_NEW_FEE,
    show,
  };
}

export function updateFeeNameNewFeeSet(feeName) {
  return {
    type: UPDATE_FEENAME_NEW_FEE,
    payload: {
      feeName,
    },
  };
}

export function updateLocationOptionNewFeeSet(option) {
  return { type: UPDATE_LOCATION_OPTION_NEW_FEE, option };
}

export function updateLocationTextChangeNewFeeSet(text, option) {
  return {
    type: UPDATE_LOCATION_TEXT_CHANGE_NEW_FEE,
    text,
    option,
  };
}

export function updateFeeBracketsNewFeeSet(feeBrackets) {
  return {
    type: UPDATE_FEE_BRACKETS_NEW_FEE,
    feeBrackets,
  };
}

export function updateLocationNewFeeSet(feeId, feeVersionId, location, fees) {
  return {
    type: UPDATE_LOCATION_NEW_FEE,
    payload: {
      feeId,
      feeVersionId,
      location,
      fees,
    },
  };
}

export function removeFeeLocationForNewFeeSet(locationId, locationName) {
  return {
    type: REMOVE_FEE_LOCATION_NEW_FEE,
    payload: {
      locationId,
      locationName,
    },
  };
}

export function createNewFeeSet(newFeeSet) {
  return {
    type: CREATE_NEW_FEE,
    payload: newFeeSet,
  };
}

export function resetPanelFee(feeId, feeName, panelId, panelVersionId) {
  return {
    type: RESET_PANEL_FEE,
    payload: {
      feeId,
      feeName,
      panelId,
      panelVersionId,
    },
  };
}

export function getAssociatedPanels(
  feeId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) {
  return {
    type: FETCH_ASSOCIATED_PANELS,
    payload: {
      feeId,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
    },
  };
}

export function filterNameChange(text) {
  return { type: FILTER_NAME_CHANGE, text };
}

export function filterLocationChange(text, countryId) {
  return {
    type: FILTER_LOCATION_CHANGE,
    text,
    countryId,
  };
}

export function addFilterLocation(location) {
  return {
    type: ADD_FILTER_LOCATION,
    location,
  };
}

export function deleteFilterLocation(id, countryId, type) {
  return {
    type: DELETE_FILTER_LOCATION,
    payload: {
      id,
      countryId,
      type,
    },
  };
}

export function changeFilterCountry(countryId) {
  return {
    type: CHANGE_FILTER_COUNTRY,
    countryId,
  };
}

export function clearFilter() {
  return {
    type: CLEAR_ALL_FILTER,
  };
}

export function openFilter(isOpen) {
  return {
    type: OPEN_FILTER,
    isOpen,
  };
}

export function copyFilter() {
  return {
    type: COPY_FILTER,
  };
}

export function updateFeeStatusFilter(feeStatus) {
  return {
    type: UPDATE_FEE_STATUS_FILTER,
    payload: feeStatus,
  };
}

export function acknowledgeFeeActivationLog(
  feeId,
  feePanelActivationLogId,
  feePanelActivationVersionId
) {
  return {
    type: ACKNOWLEDGE_SCHEDULE_FEE_VERSION_ACTIVATION,
    payload: {
      feeId,
      feePanelActivationLogId,
      feePanelActivationVersionId,
    },
  };
}

export function exportMultiFees(feeIds) {
  return {
    type: EXPORT_MULTI_FEES,
    payload: {
      feeIds,
    },
  };
}
