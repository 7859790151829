import { fork } from "redux-saga/effects";
import sagaToken from "./sagaToken";
import ui from "./sagaUI";
import allocation from "./sagaAllocation";
import fee from "./sagaFee";
import panel from "./sagaPanel";
import user from "./sagaUser";
import launchDarkly from "./sagaLaunchDarkly";
export default function* sagas() {
  yield fork(sagaToken);
  yield fork(ui);
  yield fork(allocation);
  yield fork(fee);
  yield fork(panel);
  yield fork(user);
  yield fork(launchDarkly);
}
