import * as React from "react";
import {
  Button,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2 as Grid,
  SvgIcon,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import ProgressCircle from "../../img/ProgressCircle.svg";

interface PanelExDialogProps {
  id: string;
  open: boolean;
  title?: string;
  contentText?: any;
  headerContent?: any;
  submitText?: string;
  cancelText?: string;
  submitEnabled?: boolean;
  editUser?: boolean;
  dividers?: boolean;
  children?: any;
  onClose: () => void;
  onSubmit?: () => void;
  showAction?: boolean;
  showSubmit?: boolean;
  showCancel?: boolean;
  showSubmitIcon?: boolean;
  showDivider?: boolean;
  maxWidth?: Breakpoint;
}

export const handleClose = (event: any, reason: any, closeHandler: any) => {
  if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
    return;
  closeHandler();
};

const PanelExDialog: React.FC<PanelExDialogProps> = ({
  id,
  open,
  title,
  contentText,
  headerContent,
  submitText = "Submit",
  cancelText = "Cancel",
  submitEnabled = true,
  editUser = false,
  children,
  onClose,
  onSubmit,
  showAction = true,
  showSubmit = true,
  showCancel = true,
  showSubmitIcon = false,
  showDivider = true,
  maxWidth = "md",
}) => {
  const useStyles = makeStyles()(() => ({
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleContent: {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: "23px",
    },
    action: {
      paddingRight: "16px",
    },
    content: {
      padding: 0,
    },
    icon: {
      width: "62.5px",
      height: "78px",
      color: `rgba(0, 0, 0, 0.54)`,
    },
  }));
  const { classes } = useStyles();

  return (
    <>
      <Dialog
        id={id}
        data-testid={id}
        fullWidth={true}
        maxWidth={maxWidth}
        open={open}
        onClose={(event, reason) => handleClose(event, reason, onClose)}
        aria-labelledby="draggable-dialog-title"
        sx={{
          border: "1px",
          borderRadius: "4px",
          ...(editUser && {
            "& .MuiDialog-paper": {
              width: "748px",
              height: "580px",
              borderRadius: "4px",
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.20),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            },
          }),
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid
              size={12}
              className={classes.title}
              id={`${id}-title`}
              data-testid={`${id}-title`}
            >
              <Typography
                variant="h3"
                id={`${id}-title-text`}
                data-testid={`${id}-title-text`}
              >
                {title}
              </Typography>
              <CloseIcon
                id={`${id}-close`}
                data-testid={`${id}-close`}
                onClick={onClose}
                color="info"
              />
            </Grid>
            {headerContent && (
              <Grid
                size={12}
                className={classes.titleContent}
                id={`${id}-title-content`}
                data-testid={`${id}-title-content`}
              >
                {headerContent}
              </Grid>
            )}
          </Grid>
        </DialogTitle>

        <DialogContent
          id={`${id}-content`}
          data-testid={`${id}-content`}
          className={classes.content}
          dividers={showDivider}
        >
          {contentText && (
            <DialogContentText
              id={`${id}-content-text`}
              data-testid={`${id}-content-text`}
            >
              {contentText}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
        {showAction && (
          <DialogActions className={classes.action}>
            {showCancel && (
              <Button
                color="info"
                id={`${id}-cancel`}
                data-testid={`${id}-cancel`}
                onClick={onClose}
              >
                {cancelText}
              </Button>
            )}
            {showSubmit && (
              <Button
                color="primary"
                variant="contained"
                id={`${id}-submit`}
                data-testid={`${id}-submit`}
                onClick={onSubmit}
                disabled={!submitEnabled}
                startIcon={
                  showSubmitIcon ? <SvgIcon>{ProgressCircle}</SvgIcon> : null
                }
              >
                {submitText}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default PanelExDialog;
