import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  Button,
  InputAdornment,
  MenuItem,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "tss-react/mui";
import { RootState } from "../../store/staticReducers/reducers";
import {
  updateSearchOption,
  searchTextChange,
} from "../../store/action/actionUI";
import {
  getAllocations,
  clearFilter as allocationClearFilter,
  resetPagination as allocationPagination,
  openFilter as allocationOpenFilter,
  copyFilter as allocationCopyFilter,
  filterNameChange as allocationFilterNameChange,
} from "../../store/action/actionAllocation";
import {
  getFees,
  clearFilter as feeClearFilter,
  openFilter as feeOpenFilter,
  copyFilter as feeCopyFilter,
  resetPagination as feePagination,
  filterNameChange as feeFilterNameChange,
} from "../../store/action/actionFee";
import {
  getPanels,
  clearFilter as panelClearFilter,
  openFilter as panelOpenFilter,
  copyFilter as panelCopyFilter,
  resetPagination as panelPagination,
  filterNameChange as panelFilterNameChange,
} from "../../store/action/actionPanel";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

const searchOptions = [
  {
    label: "Panels",
    value: "panels",
  },
  {
    label: "Allocations",
    value: "allocations",
  },
  {
    label: "Fees",
    value: "fees",
  },
];

export default function Search() {
  const history = useHistory();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    stack: {
      [theme.breakpoints.up("lg")]: {
        width: "1068px",
      },
      [theme.breakpoints.only("md")]: {
        width: "960px",
      },
      [theme.breakpoints.down("md")]: {
        width: "898px",
      },
      maxHeight: "38px",
      alignItems: "center",
      justifyContent: "center",
    },
    input: {
      height: "38px",
      backgroundColor: "#3399FF",
      padding: "7px 0px",
      borderRadius: "4px",
      color: "#FFFFFF",
      "& .MuiSvgIcon-root": {
        color: "#FFFFFF",
      },
      [theme.breakpoints.up("md")]: {
        width: "164px",
      },
      [theme.breakpoints.down("md")]: {
        width: "142px",
      },
      "&:focus": {
        borderRadius: "4px",
        borderColor: "rgba(255,255,255,0.2)",
        boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
        backgroundColor: "#006CCB",
      },
    },
    autocomplete: {
      padding: "2px 0px 2px 0px",
      width: "550px",
      height: "38px",
      background: "#FFFFFF",
      borderRadius: "4px",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "18px",
      color: theme.palette.common.black,
      [theme.breakpoints.up("md")]: {
        width: "630px",
      },
      [theme.breakpoints.down("md")]: {
        width: "360px",
      },
      "&:focus": {
        border: "2px solid #3399FF",
      },
      "& .MuiTextField-root": {
        marginTop: "-3px",
      },
      "& .MuiInputBase-root": {
        height: "38px",
      },
      "& .MuiOutlinedInput-root": {
        paddingRight: "9px !important",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      zIndex: "1401",

      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
    button: {
      minWidth: "38px",
      height: "38px",
      backgroundColor: "#3399FF",
      borderRadius: "4px",
      color: "#FFFFFF",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
    },
    filterButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "38px",
      border: `1px solid ${theme.palette.grey["500"]}`,
      borderRadius: "4px",
      background: "#38424A",
      [theme.breakpoints.up("lg")]: {
        minWidth: "110px",
      },
      [theme.breakpoints.down("lg")]: {
        minWidth: "38px",
        "& .MuiButton-startIcon": {
          margin: "0px",
        },
      },
      "& span": {
        color: theme.palette.common.white,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
      },
      "& .MuiTypography-root": {
        color: theme.palette.common.white,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        [theme.breakpoints.up("lg")]: {
          display: "block",
        },
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
      },
      "&:hover": {
        border: `1px solid ${theme.palette.grey["500"]}`,
        background: "#38424A",
        "& span": {
          color: theme.palette.common.white,
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
          letterSpacing: "0.4px",
        },
      },
    },
    filteredButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "38px",
      border: `1px solid ${theme.palette.grey["500"]}`,
      borderRadius: "4px",
      background: "#EEF6FF",
      [theme.breakpoints.up("lg")]: {
        minWidth: "110px",
      },
      [theme.breakpoints.down("lg")]: {
        minWidth: "38px",
        "& .MuiButton-startIcon": {
          margin: "0px",
        },
      },
      "& span": {
        color: "#3399FF",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
      },
      "& .MuiTypography-root": {
        color: "#3399FF",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        [theme.breakpoints.up("lg")]: {
          display: "block",
        },
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
      },
      "&:hover": {
        border: `1px solid ${theme.palette.grey["500"]}`,
        background: "#38424A",
        "& span": {
          color: theme.palette.common.white,
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
          letterSpacing: "0.4px",
        },
      },
    },
  }));

  const {
    roleName,
    currentOption,
    searchText,
    suggestions,
    allocationFilter,
    feeFilter,
    panelFilter,
    isFiltered,
  } = useSelector((state: RootState) => {
    return {
      roleName: state.tokenDetails.userContactDetails.roleName,
      currentOption: state.ui.searchOption,
      searchText: state.ui.searchText,
      suggestions: state.ui.suggestion,
      allocationFilter: state.allocation.filterOptions,
      feeFilter: state.fee.filterOptions,
      panelFilter: state.panel.filterOptions,
      isFiltered:
        state.fee.filterOptions.isFiltered ||
        state.allocation.filterOptions.isFiltered ||
        state.panel.filterOptions.isFiltered ||
        false,
    };
  });
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleSelectChange = (event: SelectChangeEvent) => {
    const option = event.target.value;
    dispatch(updateSearchOption(option));
    switch (option) {
      case "allocations": {
        dispatch(allocationPagination());
        dispatch(searchTextChange("", option));
        if (window.location.pathname !== "/allocations") {
          history.push("/allocations");
        }
        break;
      }
      case "panels": {
        dispatch(panelPagination());
        dispatch(panelOpenFilter(false));
        dispatch(searchTextChange("", option));

        if (window.location.pathname !== "/panels") {
          history.push("/panels");
        }
        break;
      }
      case "fees": {
        dispatch(feePagination());
        dispatch(searchTextChange("", option));
        if (window.location.pathname !== "/fees") {
          history.push("/fees");
        }
        break;
      }
    }
  };

  const handleInputChange = (input: string) => {
    if (input !== searchText) {
      dispatch(searchTextChange(input, currentOption));
      if (currentOption === "allocations") {
        dispatch(allocationFilterNameChange(input));
      } else if (currentOption === "fees") {
        dispatch(feeFilterNameChange(input));
      } else {
        dispatch(panelFilterNameChange(input));
      }
    }
  };

  const handleSuggestionSelect = (suggestion: any) => {
    if (suggestion != null) {
      history.push(`/${currentOption}/${suggestion.id}`);
      dispatch(searchTextChange("", currentOption));
      setIsOpen(false);
      handleCloseFilter();
    }
  };

  const handleTextFieldKeyDown = (event: any) => {
    if (event.keyCode == 13) {
      //if enter key pressed
      setIsOpen(false);
      const input = event.target.value;
      handleCloseFilter();
      searchByText(input);
    }
  };

  const searchByText = (text: string) => {
    if (currentOption === "allocations") {
      if (window.location.pathname !== "/allocations") {
        history.push("/allocations");
      } else {
        let valfirmKeyword = "";
        if (
          allocationFilter.valfirms.find((v: any) => v.id === 0) !== undefined
        ) {
          valfirmKeyword = allocationFilter.valfirms
            .find((v: any) => v.id === 0)
            ["name"].replace("Add ", "")
            .replace('"', "")
            .replace('"', "");
        }
        const filter = {
          name: text,
          locations: allocationFilter.locations,
          valfirms: allocationFilter.valfirms,
          valfirmKeyword,
          status: allocationFilter.allocationSetStatus,
          currentPage: 0,
          pageSize: 15,
          isFiltered: true,
          countryId: allocationFilter.countryId,
          locationText: allocationFilter.locationText,
          valfirmText: allocationFilter.valfirmText,
          nameSuggestion: allocationFilter.nameSuggestion,
          locationSuggestion: allocationFilter.locationSuggestion,
          valfirmSuggestion: allocationFilter.valfirmSuggestion,
          keywordValfirms: allocationFilter.keywordValfirms,
        };
        dispatch(getAllocations(filter));
      }
    } else if (currentOption === "fees") {
      if (window.location.pathname !== "/fees") {
        history.push("/fees");
      } else {
        const filter = {
          name: text,
          currentPage: 0,
          pageSize: 15,
          isFiltered: true,
          countryId: feeFilter.countryId,
          locations: feeFilter.locations,
          locationText: feeFilter.locationText,
          nameSuggestion: feeFilter.nameSuggestion,
          locationSuggestion: feeFilter.locationSuggestion,
          status: feeFilter.feeSetStatus,
        };
        dispatch(getFees(filter));
      }
    } else {
      if (window.location.pathname !== "/panels") {
        history.push("/panels");
      } else {
        let valfirmKeyword = "";
        if (panelFilter.valfirms.find((v: any) => v.id === 0) !== undefined) {
          valfirmKeyword = panelFilter.valfirms
            .find((v: any) => v.id === 0)
            ["name"].replace("Add ", "")
            .replace('"', "")
            .replace('"', "");
        }
        const filter = {
          name: text,
          status: panelFilter.panelSetStatus,
          currentPage: 0,
          pageSize: 15,
          isFiltered: true,
          nameSuggestion: panelFilter.nameSuggestion,
          countryId: panelFilter.countryId,
          funderText: panelFilter.funderText,
          funderSuggestion: panelFilter.funderSuggestion,
          funders: panelFilter.funders,
          clientText: panelFilter.clientText,
          clientSuggestion: panelFilter.clientSuggestion,
          clients: panelFilter.clients,
          valfirmText: panelFilter.valfirmText,
          valfirmSuggestion: panelFilter.valfirmSuggestion,
          valfirms: panelFilter.valfirms,
          valfirmKeyword,
          keywordValfirms: panelFilter.keywordValfirms,
          propTypeSearchText: panelFilter.propTypeSearchText,
          propTypeSuggestion: panelFilter.propTypeSuggestion,
          propertyTypes: panelFilter.propertyTypes,
          serviceTypeSearchText: panelFilter.serviceTypeSearchText,
          serviceTypeSuggestion: panelFilter.serviceTypeSuggestion,
          serviceTypes: panelFilter.serviceTypes,
          panelType: panelFilter.panelType,
          bracketMin: panelFilter.bracketMin,
          bracketMax: panelFilter.bracketMax,
          showMore: panelFilter.showMore,
          allocationNameText: panelFilter.allocationNameText,
          allocationNameSuggestion: panelFilter.allocationNameSuggestion,
          allocationCountryId: panelFilter.allocationCountryId,
          allocationLocationText: panelFilter.allocationLocationText,
          allocationLocationSuggestion:
            panelFilter.allocationLocationSuggestion,
          allocationLocations: panelFilter.allocationLocations,
          feeNameText: panelFilter.feeNameText,
          feeNameSuggestion: panelFilter.feeNameSuggestion,
          feeCountryId: panelFilter.feeCountryId,
          feeLocationText: panelFilter.feeLocationText,
          feeLocationSuggestion: panelFilter.feeLocationSuggestion,
          feeLocations: panelFilter.feeLocations,
        };
        dispatch(getPanels(filter));
      }
    }
  };

  const selectedOption = searchOptions.find(
    (option) => option.value === currentOption
  );

  const handleCloseFilter = () => {
    if (currentOption === "allocations") {
      dispatch(allocationClearFilter());
      dispatch(allocationOpenFilter(false));
    } else if (currentOption === "fees") {
      dispatch(feeClearFilter());
      dispatch(feeOpenFilter(false));
    } else {
      dispatch(panelClearFilter());
      dispatch(panelOpenFilter(false));
    }
  };

  const handleOpenFilter = () => {
    if (currentOption === "allocations") {
      dispatch(allocationCopyFilter());
      dispatch(allocationOpenFilter(true));
    } else if (currentOption === "fees") {
      dispatch(feeCopyFilter());
      dispatch(feeOpenFilter(true));
    } else {
      dispatch(panelCopyFilter());
      dispatch(panelOpenFilter(true));
    }
  };

  return (
    <Stack
      data-testid="search-component"
      id="search-component"
      spacing={1}
      direction="row"
      className={classes.stack}
    >
      {roleName === "Admin" && (
        <Select
          id="header-search-select"
          data-testid="header-search-select"
          className={classes.input}
          value={currentOption}
          onChange={handleSelectChange}
        >
          {searchOptions.map((option) => (
            <MenuItem
              id={`header-search-select-${option.value}`}
              data-testid={`header-search-select-${option.value}`}
              key={option.value}
              value={option.value}
            >
              <Typography variant="body3">{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      )}

      <Autocomplete
        id="header-search-input"
        data-testid="header-search-input"
        sx={{ width: 650 }}
        className={classes.autocomplete}
        options={suggestions}
        value={searchText}
        getOptionLabel={(option) => option.name || searchText || ""}
        autoComplete={false}
        filterSelectedOptions
        freeSolo
        clearOnBlur
        disableClearable={true}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={(event: any, newValue: string | null) => {
          handleSuggestionSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            id="header-search-textfield"
            fullWidth
            placeholder={`Search for ${
              (selectedOption && selectedOption.label) || "Panel"
            }`}
            onKeyDown={handleTextFieldKeyDown}
            onClick={handleOpenFilter}
            autoFocus
            slotProps={{
              ...params.slotProps,
              input: {
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: null,
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          const displayName = option.name;
          const regex = new RegExp(escapedSpecialCharacters(searchText), "gi");
          const response = displayName.replace(regex, function (str: any) {
            return "<span style='font-weight:500'>" + str + "</span>";
          });
          return (
            <li
              {...props}
              dangerouslySetInnerHTML={{ __html: `<div>${response}</div>` }}
            ></li>
          );
        }}
        PopperComponent={(props: any) => (
          <Popper
            data-testid="header-search-input-popper"
            {...props}
            className={classes.popper}
            placement="bottom"
          ></Popper>
        )}
      />
      <Button
        id="header-filter-button"
        data-testid="header-filter-button"
        variant="outlined"
        className={`${
          isFiltered ? classes.filteredButton : classes.filterButton
        }`}
        startIcon={<FilterListIcon />}
        size="medium"
        onClick={() => handleOpenFilter()}
      >
        <Typography>Filters</Typography>
      </Button>
    </Stack>
  );
}
