import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid2 as Grid, Stack, Typography } from "@mui/material";
import { RootState } from "../../../store/staticReducers/reducers";
import PanelExDialog from "../../shared/PanelExDialog";
import {
  openAssociatedPanels,
  getAssociatedPanels,
} from "../../../store/action/actionPanel";

interface AssoicatedPanelsProps {
  allocationId: number;
  allocationName: string;
}

export default function AssociatedPanels(props: AssoicatedPanelsProps) {
  const dispatch = useDispatch();

  const { associatedPanels, isOpenAssociatedPanels } = useSelector(
    (state: RootState) => {
      return {
        associatedPanels: state.panel.bulkUpdate.associatedPanels,
        isOpenAssociatedPanels: state.panel.bulkUpdate.isOpenAssociatedPanels,
      };
    }
  );

  useEffect(() => {
    dispatch(
      getAssociatedPanels(props.allocationId, 0, 10000, "panel_set_id", "asc")
    );
  }, [dispatch, props.allocationId]);

  return (
    <PanelExDialog
      id="associated-panels-dialog"
      data-testid="associated-panels-dialog"
      open={isOpenAssociatedPanels}
      title="Associated Panels"
      showCancel={false}
      submitText="Close"
      onClose={() => dispatch(openAssociatedPanels(false))}
      onSubmit={() => dispatch(openAssociatedPanels(false))}
      showAction={true}
      maxWidth="sm"
    >
      <Grid container sx={{ padding: "16px 24px 32px 24px", gap: "24px" }}>
        <Grid size={12} sx={{ gap: "4px" }}>
          <Typography
            id={"allocation-name"}
            data-testid={"allocation-name"}
            variant="h5"
          >
            {props.allocationName}
          </Typography>
          <Typography
            id={"desc-label"}
            data-testid={"desc-label"}
            variant="body2"
            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
          >
            Changes to this allocation will impact the panels below:
          </Typography>
        </Grid>
        {associatedPanels.map((panel: any, index: number) => (
          <Grid
            container
            size={12}
            key={"panel-" + panel.panelSetId}
            sx={{ gap: "12px" }}
          >
            <Grid size={12}>
              <Stack direction="row" spacing={1}>
                <Typography
                  id={"panel-name-label-" + panel.panelSetId}
                  data-testid={"panel-name-label-" + panel.panelSetId}
                  variant="body2Strong"
                >
                  Panel Name:
                </Typography>
                <Typography
                  id={"panel-name-" + panel.panelSetId}
                  data-testid={"panel-name-" + panel.panelSetId}
                  variant="body2"
                >
                  {panel.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid size={12}>
              <Stack direction="row" spacing={1}>
                <Typography
                  id={"panel-id-label-" + panel.panelSetId}
                  data-testid={"panel-id-label-" + panel.panelSetId}
                  variant="body2Strong"
                >
                  ID:
                </Typography>
                <Typography
                  id={"panel-id-" + panel.panelSetId}
                  data-testid={"panel-id-" + panel.panelSetId}
                  variant="body2"
                >
                  {panel.panelSetId}
                </Typography>
              </Stack>
            </Grid>
            <Grid size={12}>
              <Stack direction="row" spacing={1}>
                <Typography
                  id={"panel-type-label-" + panel.panelSetId}
                  data-testid={"panel-type-label-" + panel.panelSetId}
                  variant="body2Strong"
                >
                  Type:
                </Typography>
                <Typography
                  id={"panel-type-" + panel.panelSetId}
                  data-testid={"panel-type-" + panel.panelSetId}
                  variant="body2"
                >
                  {panel.type}
                </Typography>
              </Stack>
            </Grid>
            <Grid size={12}>
              <Stack direction="row" spacing={1}>
                <Typography
                  id={"panel-order-label-" + panel.panelSetId}
                  data-testid={"panel-order-label-" + panel.panelSetId}
                  variant="body2Strong"
                >
                  Panel Order:
                </Typography>
                <Typography
                  id={"panel-order-" + panel.panelSetId}
                  data-testid={"panel-order-" + panel.panelSetId}
                  variant="body2"
                >
                  {panel.processOrder}
                </Typography>
              </Stack>
            </Grid>
            <Grid size={12}>
              <Stack direction="row" spacing={1}>
                <Typography
                  id={"service-type-label-" + panel.panelSetId}
                  data-testid={"service-type-label-" + panel.panelSetId}
                  variant="body2Strong"
                >
                  Service Type:
                </Typography>
                <Typography
                  id={"service-type-" + panel.panelSetId}
                  data-testid={"service-type-" + panel.panelSetId}
                  variant="body2"
                >
                  {panel.serviceTypes}
                </Typography>
              </Stack>
            </Grid>
            {associatedPanels.length - 1 > index && (
              <Grid size={12}>
                <Divider
                  id={"panel-divider-" + panel.panelSetId}
                  data-testid={"panel-divider-" + panel.panelSetId}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </PanelExDialog>
  );
}
