import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  deleteValfirmNewAllocation,
  updateActiveValfirmTextChangeNewAllocation,
  updateValfirmNewAllocation,
} from "../../store/action/actionUI";
import { updateLocationNewAllocationSet } from "../../store/action/actionAllocation";
import {
  Box,
  FormControl,
  Grid2 as Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import PanelExDialog from "../shared/PanelExDialog";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

interface LocationModalProps {
  allocationId: number;
  allocationVersionId: number;
  location: LocationProps;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

interface LocationProps {
  id: number;
  type: string;
  name: string;
  isExisting?: boolean;
  countryId: number;
  countryName: string;
}

const LocationModalNewAllocation: React.FC<LocationModalProps> = ({
  allocationId,
  allocationVersionId,
  location,
  open,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    valfirmGrid: {
      width: "100%",
      cursor: "pointer",
      "& .MuiTableCell-root": {
        padding: "8px",
        height: "50px",
      },
      "& .MuiFormControl-root": {
        height: "auto",
      },
    },
    allocationText: {
      width: "70px",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    warning: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.warning.main,
      },
    },
  }));
  const { classes } = useStyles();

  const { orgSearchText, orgSuggestions, valfirms, isQuote, noOfQuotes } =
    useSelector((state: RootState) => {
      return {
        orgSearchText: state.ui.newAllocation.orgSearchText,
        orgSuggestions: state.ui.newAllocation.orgSuggestion || [],
        valfirms: state.ui.newAllocation.valfirms || [],
        isQuote: state.allocation.newAllocationSet.isQuote || false,
        noOfQuotes: state.allocation.newAllocationSet.noOfQuotes || 0,
      };
    });

  const percentageValue = isQuote
    ? noOfQuotes === 0
      ? 100
      : noOfQuotes * 100
    : 100;
  const valfirmsWithoutDeleted = valfirms.filter(
    (row: any) => !(!!row.allocationPanelAllocationsId && row.isDeleted)
  );
  const allocationSum = valfirmsWithoutDeleted
    .filter(
      (row: any) => !(!!row.allocationPanelAllocationsId && row.isDeleted)
    )
    .reduce((acc: any, v: any) => acc + parseInt(v.value || 0), 0);

  const hasEmptyAllocation =
    valfirmsWithoutDeleted.filter(
      (v: any) => v.value === undefined || !String(v.value)
    ).length > 0;

  const locationSubmitEnabled =
    allocationSum === percentageValue && !hasEmptyAllocation;

  const handleOrgInputChange = (input: string) => {
    if (input !== orgSearchText) {
      dispatch(
        updateActiveValfirmTextChangeNewAllocation(location.countryName, input)
      );
    }
  };

  const handleUpdateValfirm = (
    allocationPanelAllocationsId: any,
    valfirmId: number,
    valfirmName: string,
    value: any
  ) => {
    dispatch(
      updateValfirmNewAllocation(
        allocationPanelAllocationsId,
        valfirmId,
        valfirmName,
        value
      )
    );
  };

  const handleDeleteValfirm = (
    allocationPanelAllocationsId: any,
    valfirmId: number
  ) => {
    dispatch(
      deleteValfirmNewAllocation(allocationPanelAllocationsId, valfirmId)
    );
  };

  const handleSubmit = () => {
    dispatch(
      updateLocationNewAllocationSet(
        allocationId,
        allocationVersionId,
        location,
        valfirms
      )
    );
    setOpen(false);
  };

  const displayName =
    location.type === "Country"
      ? location.name
      : [location.countryName, location.type, location.name].join(" ");

  const [rowToDelete, setRowToDelete] = useState(null);

  return (
    <PanelExDialog
      id="location-dialog"
      data-testid="location-dialog"
      open={open}
      title={"Add to New Allocation: " + displayName}
      submitText={location.isExisting ? "Update Allocation" : "Add Location"}
      cancelText={"Cancel and Go Back"}
      submitEnabled={locationSubmitEnabled}
      onClose={() => setOpen(false)}
      onSubmit={handleSubmit}
      showAction={true}
      headerContent={
        <PanelExAutocomplete
          id="valfirm-filter"
          style={{ width: "560px" }}
          options={orgSuggestions}
          value={orgSearchText}
          getOptionLabel={(option: any) => option.name || orgSearchText || ""}
          placeholder="Search Valfirm to Add"
          onInputChange={(value: any) => {
            handleOrgInputChange(value);
          }}
          renderOption={(props: any, option: any) => {
            const { id, name } = option;
            const isExistingValfirm =
              valfirmsWithoutDeleted.find((v: any) => v.valfirmId === id) !==
              undefined;
            const regex = new RegExp(
              escapedSpecialCharacters(orgSearchText),
              "gi"
            );
            const response = name.replace(regex, function (str: any) {
              return "<span style='font-weight:500'>" + str + "</span>";
            });
            return (
              <li
                {...props}
                data-testid={`add-valfirm-label-${id}`}
                id={`add-valfirm-label-${id}`}
                onClick={(ev) => {
                  !isExistingValfirm && handleUpdateValfirm(null, id, name, "");
                  ev.stopPropagation();
                }}
                style={{ justifyContent: "space-between" }}
              >
                <div dangerouslySetInnerHTML={{ __html: `${response}` }}></div>
                <AddCircleIcon
                  data-testid={`add-valfirm-icon-${id}`}
                  id={`add-valfirm-icon-${id}`}
                  style={{
                    ...(isExistingValfirm
                      ? {
                          color: "rgba(0, 0, 0, 0.12)",
                        }
                      : {
                          color: "#757575",
                        }),
                  }}
                />
              </li>
            );
          }}
        />
      }
    >
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px 20px 30px",
        }}
      >
        <FormControl
          sx={{
            mt: 2,
            mb: 2,
            minWidth: 120,
            minHeight: 591,
            maxHeight: 591,
          }}
        >
          {valfirmsWithoutDeleted.length === 0 ? (
            <Grid
              container
              sx={{ minHeight: 591 }}
              id="search-message"
              data-testid="search-message"
            >
              <Grid
                size={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    marginBottom: "150px",
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      paddingBottom: "22px",
                    }}
                  >
                    Search to Add Valuation Firms
                  </Typography>
                  <Typography variant="h5">
                    Search for valuation firms to add to this location
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <TableContainer component={Paper}>
              <Table
                className={classes.valfirmGrid}
                data-testid="valfirm-table"
              >
                <TableBody>
                  {valfirmsWithoutDeleted.map((row: any, index: any) => {
                    const {
                      allocationPanelAllocationsId,
                      valfirmId,
                      valfirmName,
                      value,
                    } = row;
                    const emptyValue = !value;

                    return (
                      <TableRow
                        key={valfirmId}
                        style={{
                          backgroundColor:
                            valfirmId === rowToDelete
                              ? theme.palette.info.lighter
                              : "",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          data-testid={`valfirm-table-name-${index}`}
                        >
                          {valfirmName}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            id={`valfirm-table-value-${index}`}
                            data-testid={`valfirm-table-value-${index}`}
                            className={`${classes.allocationText} ${
                              emptyValue ? classes.warning : null
                            }`}
                            value={value}
                            onChange={(event: any) => {
                              const input = event.target.value;
                              const regex = /^[0-9\b]+$/;
                              if (input === "" || regex.test(input)) {
                                handleUpdateValfirm(
                                  allocationPanelAllocationsId,
                                  valfirmId,
                                  valfirmName,
                                  input
                                );
                              }
                            }}
                            slotProps={{
                              input: {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          data-testid={`delete-valfirm-${index}`}
                          sx={{ width: "32px" }}
                          onClick={(ev) => {
                            handleDeleteValfirm(
                              allocationPanelAllocationsId,
                              valfirmId
                            );
                            ev.stopPropagation();
                          }}
                          onMouseOver={() => setRowToDelete(valfirmId)}
                          onMouseOut={() => setRowToDelete(null)}
                        >
                          <DeleteOutlineIcon
                            style={{
                              color:
                                valfirmId === rowToDelete
                                  ? theme.palette.primary.dark
                                  : theme.palette.info.main,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {valfirmsWithoutDeleted &&
            valfirmsWithoutDeleted.length > 0 &&
            !hasEmptyAllocation &&
            allocationSum !== percentageValue && (
              <Box
                data-testid="allocation-error"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  padding: "4px 44px 10px 24px",
                }}
              >
                <Typography color="error">
                  {allocationSum > percentageValue
                    ? `+${
                        allocationSum - percentageValue
                      }% Over Total of Allocation`
                    : `-${
                        percentageValue - allocationSum
                      }% Below Total of Allocation`}
                </Typography>
              </Box>
            )}
        </FormControl>
      </Box>
    </PanelExDialog>
  );
};
export default LocationModalNewAllocation;
