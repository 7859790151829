import { call, put, takeLatest } from "redux-saga/effects";

import {
  fetchUsers,
  fetchUserByEmail,
  fetchUserDetail,
  fetchUserOrgSuggestion,
  updateUserDetails,
  createNewUser,
  deActivateUser,
  activateUser,
} from "../apis/apiUser";

import { OPEN_SNACKBAR } from "./sagaUI";

export const RESET_PAGINATION = "user/RESET_PAGINATION";
export const FETCH_USERS = "user/FETCH_USERS";
export const ATTEMPT_FETCH_USERS = "user/ATTEMPT_FETCH_USERS";
export const SUCCEED_FETCH_USERS = "user/SUCCEED_FETCH_USERS";
export const FAIL_FETCH_USERS = "user/FAIL_FETCH_USERS";
export const SHOW_EDIT_USER = "user/SHOW_EDIT_USER";
export const FETCH_USER_DETAIL = "user/FETCH_USER_DETAIL";
export const ATTEMPT_FETCH_USER_DETAIL = "user/ATTEMPT_FETCH_USER_DETAIL";
export const SUCCEED_FETCH_USER_DETAIL = "user/SUCCEED_FETCH_USER_DETAIL";
export const FAIL_FETCH_USER_DETAIL = "user/FAIL_FETCH_USER_DETAIL";
export const UPDATE_ROLE_EDIT_USER = "user/UPDATE_ROLE_EDIT_USER";
export const UPDATE_FIRST_NAME_EDIT_USER = "user/UPDATE_FIRST_NAME_EDIT_USER";
export const UPDATE_LAST_NAME_EDIT_USER = "user/UPDATE_LAST_NAME_EDIT_USER";
export const UPDATE_EMAIL_EDIT_USER = "user/UPDATE_EMAIL_EDIT_USER";
export const UPDATE_IS_NAB = "user/UPDATE_IS_NAB";
export const UPDATE_IS_READONLY = "user/UPDATE_IS_READONLY";

export const ATTEMPT_FETCH_ORG_SUGGESTION = "user/ATTEMPT_FETCH_ORG_SUGGESTION";
export const SUCCEED_FETCH_ORG_SUGGESTION = "user/SUCCEED_FETCH_ORG_SUGGESTION";
export const FAIL_FETCH_ORG_SUGGESTION = "user/FAIL_FETCH_ORG_SUGGESTION";
export const ADD_FUNDER_EDIT_USER = "user/ADD_FUNDER_EDIT_USER";
export const ADD_CLIENT_EDIT_USER = "user/ADD_CLIENT_EDIT_USER";
export const DELETE_FUNDER_EDIT_USER = "user/REMOVE_FUNDER_EDIT_USER";
export const DELETE_CLIENT_EDIT_USER = "user/REMOVE_CLIENT_EDIT_USER";
export const UPDATE_ORG_TEXT_CHANGE = "user/UPDATE_ORG_TEXT_CHANGE";
export const UPDATE_USER = "user/UPDATE_USER";
export const ATTEMPT_UPDATE_USER = "user/ATTEMPT_UPDATE_USER";
export const SUCCEED_UPDATE_USER = "user/SUCCEED_UPDATE_USER";
export const ERROR_UPDATE_USER = "user/ERROR_UPDATE_USER";
export const FAIL_UPDATE_USER = "user/FAIL_UPDATE_USER";
export const SHOW_SEARCH_NEW_USER = "user/SHOW_SEARCH_NEW_USER";
export const SEARCH_NEW_USER = "user/SEARCH_NEW_USER";
export const ATTEMPT_SEARCH_NEW_USER = "user/ATTEMPT_SEARCH_NEW_USER";
export const SUCCEED_SEARCH_NEW_USER = "user/SUCCEED_SEARCH_NEW_USER";
export const FAIL_SEARCH_NEW_USER = "user/FAIL_SEARCH_NEW_USER";
export const SHOW_ADD_NEW_USER = "user/SHOW_ADD_NEW_USER";
export const ADD_NEW_USER = "user/ADD_NEW_USER";
export const ATTEMPT_ADD_NEW_USER = "user/ATTEMPT_ADD_NEW_USER";
export const SUCCEED_ADD_NEW_USER = "user/SUCCEED_ADD_NEW_USER";
export const FAIL_ADD_NEW_USER = "user/FAIL_ADD_NEW_USER";

export const REMOVE_USER = "user/REMOVE_USER";
export const ATTEMPT_REMOVE_USER = "user/ATTEMPT_REMOVE_USER";
export const SUCCEED_REMOVE_USER = "user/SUCCEED_REMOVE_USER";
export const FAIL_REMOVE_USER = "user/FAIL_REMOVE_USER";

export const REACTIVATE_USER = "user/REACTIVATE_USER";
export const ATTEMPT_REACTIVATE_USER = "user/ATTEMPT_REACTIVATE_USER";
export const SUCCEED_REACTIVATE_USER = "user/SUCCEED_REACTIVATE_USER";
export const FAIL_REACTIVATE_USER = "user/FAIL_REACTIVATE_USER";

export function* getUsers(param) {
  yield put({
    type: ATTEMPT_FETCH_USERS,
    data: {
      searchText: param.payload.searchText,
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
    },
  });
  try {
    const { data: response } = yield call(
      fetchUsers,
      param.payload.searchText,
      param.payload.currentPage,
      param.payload.pageSize
    );
    yield put({
      type: SUCCEED_FETCH_USERS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_USERS,
    });
  }
}

export function* getUserDetail(param) {
  yield put({
    type: ATTEMPT_FETCH_USER_DETAIL,
    data: {
      userId: param.payload.userId,
    },
  });
  try {
    const { data: response } = yield call(
      fetchUserDetail,
      param.payload.userId
    );
    yield put({
      type: SUCCEED_FETCH_USER_DETAIL,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_USER_DETAIL,
    });
  }
}

export function* updateOrgTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_ORG_SUGGESTION,
    text: data.payload.text,
    orgType: data.payload.orgTypeOption,
    isEdit: data.payload.isEdit,
  });

  try {
    if (data.payload.text.length > 2) {
      const { data: list } = yield call(
        fetchUserOrgSuggestion,
        data.payload.text,
        data.payload.orgTypeOption
      );

      yield put({
        type: SUCCEED_FETCH_ORG_SUGGESTION,
        data: list,
        orgType: data.payload.orgTypeOption,
        isEdit: data.payload.isEdit,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ORG_SUGGESTION,
      orgType: data.payload.orgTypeOption,
      isEdit: data.payload.isEdit,
    });
  }
}

export function* updateUser(param) {
  yield put({
    type: ATTEMPT_UPDATE_USER,
  });

  try {
    const userDetails = {
      userId: param.payload.userDetail.userId,
      appUserId: param.payload.userDetail.appUserId,
      email: param.payload.userDetail.email,
      first: param.payload.userDetail.first,
      last: param.payload.userDetail.last,
      isNab: param.payload.userDetail.isNab,
      isReadOnly: param.payload.userDetail.isReadOnly,
      username: param.payload.userDetail.username,
      roleName: param.payload.userDetail.roleName,
      funderOrgDtoSet: param.payload.userDetail.funderOrgDtoSet,
      clientOrgDtoSet: param.payload.userDetail.clientOrgDtoSet,
      isUpdateOrgs: param.payload.userDetail.isUpdateOrgs,
      isUpdateUserDetails: param.payload.userDetail.isUpdateUserDetails,
    };
    const { data } = yield call(
      updateUserDetails,
      userDetails.userId,
      userDetails
    );

    if (data.error) {
      yield put({
        type: FAIL_UPDATE_USER,
        error: data.error,
        meta: {
          analytics: {
            updateUserId: param.payload.userDetail.userId,
            updateUsername: param.payload.userDetail.username,
          },
        },
      });
    } else {
      const updateUser = {
        userId: data.userId,
        email: data.email,
        first: data.first,
        last: data.last,
        username: data.username,
        isNab: data.isNab,
        isReadOnly: data.isReadOnly,
        isActive: data.isActive,
        funderOrgDtoSet: data.funderOrgDtoSet,
        clientOrgDtoSet: data.clientOrgDtoSet,
        roleName: data.roleName,
        isUpdateUserDetails: false,
        isUpdateOrgs: false,
      };
      yield put({
        type: SUCCEED_UPDATE_USER,
        payload: updateUser,
        meta: {
          analytics: {
            updateUserId: updateUser.userId,
            updateUsername: updateUser.username,
          },
        },
      });
      yield put({
        type: OPEN_SNACKBAR,
        payload: {
          message:
            "Successfully Updated User " + param.payload.userDetail.username,
          variant: "info",
          isIcon: true,
        },
      });
    }
  } catch (err) {
    console.warn(err);
    yield put({
      type: FAIL_UPDATE_USER,
      error: err.message,
      meta: {
        analytics: {
          updateUserId: param.payload.userDetail.userId,
          updateUsername: param.payload.userDetail.username,
        },
      },
    });
  }
}

export function* searchNewUser(param) {
  yield put({
    type: ATTEMPT_SEARCH_NEW_USER,
    email: param.email,
  });
  try {
    const { data: response } = yield call(fetchUserByEmail, param.email);
    yield put({
      type: SUCCEED_SEARCH_NEW_USER,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_SEARCH_NEW_USER,
    });
  }
}

export function* addNewUser(param) {
  yield put({
    type: ATTEMPT_ADD_NEW_USER,
  });
  try {
    const { data: response } = yield call(createNewUser, param.payload);
    yield put({
      type: SUCCEED_ADD_NEW_USER,
      payload: {
        ...response,
      },
      meta: {
        analytics: {
          addUserId: response.userId,
          addUsername: response.username,
        },
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message:
          param.payload.first +
          " " +
          param.payload.last +
          " has now access to PanelEx",
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_ADD_NEW_USER,
      meta: {
        analytics: {
          addUserId: param.payload.userId,
          addUsername: param.payload.username,
        },
      },
    });
  }
}

export function* removeUser(param) {
  yield put({
    type: ATTEMPT_REMOVE_USER,
  });
  try {
    const { data: response } = yield call(deActivateUser, param.payload.userId);
    yield put({
      type: SUCCEED_REMOVE_USER,
      payload: {
        userId: param.payload.userId,
        ...response,
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Deactivated the user from PanelEx",
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_REMOVE_USER,
    });
  }
}
export function* reActivateUser(param) {
  yield put({
    type: ATTEMPT_REACTIVATE_USER,
  });
  try {
    const { data: response } = yield call(activateUser, param.payload.userId);
    yield put({
      type: SUCCEED_REACTIVATE_USER,
      payload: {
        userId: param.payload.userId,
        ...response,
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Activated the user",
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_REACTIVATE_USER,
    });
  }
}
export default function* sagaUser() {
  yield takeLatest(FETCH_USERS, getUsers);
  yield takeLatest(SEARCH_NEW_USER, searchNewUser);
  yield takeLatest(FETCH_USER_DETAIL, getUserDetail);
  yield takeLatest(UPDATE_ORG_TEXT_CHANGE, updateOrgTextChange);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(ADD_NEW_USER, addNewUser);
  yield takeLatest(REMOVE_USER, removeUser);
  yield takeLatest(REACTIVATE_USER, reActivateUser);
}
