import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  getAllocationVersionComparisonDetails,
  showVersionsCompare,
  clearVersionsToCompare,
} from "../../store/action/actionAllocation";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";

export default function AllocationVersionComparison() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    valfirmGrid: {
      width: "100%",
      "& .MuiTableCell-root": {
        padding: "5px 13px 5px 13px",
        height: "33px",
      },
      "& .MuiFormControl-root": {
        height: "auto",
      },
    },
    version: {
      fontSize: "13px",
      fontWeight: 600,
      backgroundColor: theme.colors.alpha.white["100"],
    },
    location: {
      fontSize: "14px",
      fontWeight: 600,
      "&.MuiTableCell-root": {
        padding: "13px",
        height: "50px",
      },
    },
    locationRow: {
      backgroundColor: theme.colors.neutral.lightest,
      height: "47px",
      fontSize: "13px",
    },
    allocation: {
      fontSize: "13px",
    },
    noDiffText: {
      fontSize: "16px",
      padding: "32px 24px 32px 24px",
    },
  }));
  const { classes } = useStyles();

  const {
    allocationId,
    versionCompare,
    showVersionCompare,
    versionCompareDetails,
    versionCompareLoading,
  } = useSelector((state: RootState) => {
    const allocationSet = state.allocation.allocationSet;
    return {
      allocationId: allocationSet.allocPanelId,
      versionCompare: state.allocation.allocationVersion.versionCompare,
      showVersionCompare: state.allocation.allocationVersion.showVersionCompare,
      versionCompareDetails:
        state.allocation.allocationVersion.versionCompareDetails,
      versionCompareLoading:
        state.allocation.allocationVersion.versionCompareLoading,
    };
  });

  useEffect(() => {
    if (!!allocationId && versionCompare.length === 2) {
      dispatch(
        getAllocationVersionComparisonDetails(allocationId, versionCompare)
      );
    }
  }, [dispatch, versionCompare]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeVersionCompare = () => {
    dispatch(clearVersionsToCompare());
    dispatch(showVersionsCompare(false));
  };

  const differenceCount = versionCompareDetails.differenceCount;
  const locations = versionCompareDetails.locations || [];

  const renderLocationRows = (
    locationName: any,
    leftExist: any,
    rightExist: any,
    leftSum: any,
    rightSum: any,
    index: any
  ) => {
    return (
      <TableRow key={`location-row-${index}`} className={classes.locationRow}>
        <TableCell
          component="td"
          scope="row"
          data-testid={`compare-location-name-${index}`}
          className={classes.location}
        >
          {locationName}
        </TableCell>
        <TableCell
          component="td"
          data-testid={`compare-location-left-${index}`}
        >
          {leftExist ? leftSum + "% Allocated" : "N/A"}
        </TableCell>
        <TableCell
          component="td"
          data-testid={`compare-location-right-${index}`}
        >
          {leftExist
            ? rightExist
              ? rightSum + "% Allocated"
              : "Removed"
            : "Added"}
        </TableCell>
      </TableRow>
    );
  };

  const renderAllocationRows = (
    allocations: any,
    rightExist: any,
    locationIndex: any
  ) => {
    return allocations.map((allocation: any, index2: number) => {
      const { orgName, leftAllocation, rightAllocation } = allocation;
      return (
        <TableRow key={`allocation-row-${locationIndex}-${index2}`}>
          <TableCell
            component="td"
            scope="row"
            data-testid={`compare-allocation-org-${locationIndex}-${index2}`}
            className={classes.allocation}
          >
            {orgName}
          </TableCell>
          <TableCell
            component="td"
            data-testid={`compare-allocation-left-${locationIndex}-${index2}`}
            className={classes.allocation}
          >
            {leftAllocation !== null ? leftAllocation + "%" : "N/A"}
          </TableCell>
          <TableCell
            component="td"
            data-testid={`compare-allocation-right-${locationIndex}-${index2}`}
            className={classes.allocation}
          >
            {rightExist
              ? rightAllocation !== null
                ? rightAllocation + "%"
                : "Removed"
              : "N/A"}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <PanelExDialog
      id="version-compare-dialog"
      data-testid="version-compare-dialog"
      open={showVersionCompare}
      title={`${
        versionCompareLoading
          ? ""
          : differenceCount > 1
          ? "Comparison: " + differenceCount + " locations with differences"
          : differenceCount > 0
          ? "Comparison: " + differenceCount + " location with differences"
          : "Comparison: No differences found"
      }`}
      showCancel={false}
      submitText="Done"
      onClose={closeVersionCompare}
      onSubmit={closeVersionCompare}
      maxWidth="sm"
    >
      {versionCompareLoading ? (
        <Grid size={12}>
          <Box
            data-testid="version-compare-progress"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "10px",
            maxWidth: "sm",
          }}
        >
          {differenceCount > 0 ? (
            <FormControl
              sx={{
                mt: 2,
                mb: 2,
                minWidth: 500,
                minHeight: 591,
                maxHeight: 591,
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  className={classes.valfirmGrid}
                  data-testid="version-compare-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        component="th"
                        className={classes.version}
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        data-testid={"compare-version-0"}
                        className={classes.version}
                      >
                        v{versionCompareDetails.leftVersionId}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        data-testid={"compare-version-1"}
                        className={classes.version}
                      >
                        v{versionCompareDetails.rightVersionId}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {locations
                      .filter((location: any) => location.isDifferent)
                      .map((location: any, index: number) => {
                        const {
                          locationName,
                          leftExist,
                          rightExist,
                          leftSum,
                          rightSum,
                          allocations,
                        } = location;
                        const locationRows = renderLocationRows(
                          locationName,
                          leftExist,
                          rightExist,
                          leftSum,
                          rightSum,
                          index
                        );
                        const allocationRows = renderAllocationRows(
                          allocations,
                          rightExist,
                          index
                        );

                        return (
                          <React.Fragment key={`location-${index}`}>
                            {locationRows}
                            {allocationRows}
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </FormControl>
          ) : (
            <FormControl
              sx={{
                mt: 2,
                mb: 2,
                minWidth: 500,
                minHeight: 100,
                maxHeight: 100,
              }}
            >
              <Typography
                className={classes.noDiffText}
                data-testid="version-compare-no-diff"
              >
                No differences found between versions{" "}
                {versionCompareDetails.leftVersionId} &{" "}
                {versionCompareDetails.rightVersionId}
              </Typography>
            </FormControl>
          )}
        </Box>
      )}
    </PanelExDialog>
  );
}
