import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  showCreateNewFeeSet,
  updateLocationOptionNewFeeSet,
  updateLocationTextChangeNewFeeSet,
  updateFeeNameNewFeeSet,
  updateFeeBracketsNewFeeSet,
  createNewFeeSet,
  removeFeeLocationForNewFeeSet,
} from "../../store/action/actionFee";
import {
  updateSelectedFeesNewFee,
  updateSelectedLocationNewFee,
} from "../../store/action/actionUI";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid2 as Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PanelExAutocomplete from "../shared/PanelExAutocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FeesIcon from "@mui/icons-material/RequestQuoteOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LocationModalNewFee from "./LocationModalNewFee";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

interface ExpandMoreProps extends IconButtonProps {
  expand: string;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: theme.colors.grey.main,
  transform: expand === "false" ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CreateNewFeeSet() {
  const dispatch = useDispatch();
  const useStyles = makeStyles()(() => ({
    button: {
      minWidth: "150px",
      color: theme.palette.common.white,
      "& .MuiButton-startIcon": {
        color: theme.palette.common.white,
      },
      marginLeft: "4px",
    },
    doneButton: {
      minWidth: "150px",
      borderColor: theme.palette.primary.darker,
      color: theme.palette.primary.darker,
      "& .MuiButton-startIcon": {
        color: theme.palette.common.white,
      },
      marginLeft: "4px",
    },
    select: {
      borderRadius: "4px",
      marginRight: "4px",
    },
    versionDetailLabel: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "14px",
    },
    locationLabel: {
      fontSize: "16px",
      lineHeight: "26px",
    },
    versionDetailSwitchLabel: {
      "& span": {
        fontSize: "12px",
        fontWeight: 500,
      },
    },
    feeSetName: {
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #fee-set-name-helper-text": {
        textTransform: "none",
      },
    },
    versionDetailText: {
      width: "150px",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    detailsGrid: {
      width: "100%",
      cursor: "pointer",
      "& .MuiDataGrid-withBorderColor": {
        border: "0px",
      },
      "& .MuiDataGrid-cell": {
        padding: "0px",
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "0px",
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "0px",
      },
    },
    feeGrid: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "12px",
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      justifyContent: "flex-end",
    },
    feeValue: {
      paddingRight: "12px",
      display: "flex",
      justifyContent: "end",
    },
    locationHeader: {
      height: "32px",
      background: "#f5f5f5",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 400,
        color: theme.palette.common.black,
        padding: "6px 12px",
      },
    },
    feeCardContent: {
      padding: "0px",
      "&:last-child": {
        padding: "0px",
      },
    },
    locationIcon: {
      height: "32px",
      background: "#f5f5f5",
      display: "flex",
      justifyContent: "end",
      paddingRight: "12px",
    },
    card: {
      width: "100%",
      padding: "12px 0 0 0",
      background: "#FAFBFC",
    },
    cardContent: {
      padding: "0px",
      background: theme.palette.common.white,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "0px",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      "&:last-child": {
        paddingBottom: "0px",
      },
    },
    expandHeader: {
      height: "32px",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 400,
        color: theme.palette.common.black,
        padding: "6px 12px",
      },
    },
    expandIcon: {
      height: "32px",
      display: "flex",
      justifyContent: "end",
      paddingRight: "12px",
    },
    feeBracketCard: {
      width: "100%",
      marginTop: "12px",
      padding: "0px",
    },
    feeBracketCardContent: {
      background: theme.palette.info.lighter,
      paddingBottom: "24px",
    },
    feeBracketLocationHeader: {
      background: theme.palette.info.lighter,
    },
    feeBracketLocationIcon: {
      background: theme.palette.info.lighter,
    },
    feeBracketGridHeader: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "12px",
      "& .MuiTypography-root": {
        paddingLeft: "8px",
        color: theme.colors.grey.main,
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
      },
    },
    feeBracketGrid: {
      borderTop: "0px",
      padding: "12px",
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.8)",
      },
    },
    feeText: {
      width: "100%",
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    feeTextUpper: {
      width: "100%",
      backgroundColor: theme.palette.common.white,
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
    },
    feeBracketMessage: {
      height: "327px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiTypography-root": {
        "&:first-of-type": {
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "28px",
          color: theme.colors.grey.dark,
        },
        "&:last-of-type": {
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: theme.colors.grey.main,
          marginTop: "20px",
        },
      },
    },
    feeBracketGridAction: {
      paddingTop: "0px",
      "& .MuiGrid-item": {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    feeDetailMessage: {
      width: "380px",
      marginTop: "10px",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",

      "& .MuiTypography-root": {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colors.grey.main,

        "&:first-of-type": {
          paddingBottom: "22px",
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "28px",
          color: theme.colors.grey.dark,
        },
      },
    },
  }));

  const {
    locations,
    newFeeSearchOption,
    newFeeSearchText,
    newFeeSuggestions,
    openCreateFeeSet,
    feeName,
    feePanelBrackets,
    error,
    selectedLocation,
    editedLocation,
    newFeeSet,
    currentPage,
    pageSize,
  } = useSelector((state: RootState) => {
    return {
      locations: state.fee.newFeeSet.locations || [],
      newFeeSearchOption: state.fee.newFeeSet.searchOption || 1,
      newFeeSearchText: state.fee.newFeeSet.searchText || "",
      newFeeSuggestions: state.fee.newFeeSet.suggestion || [],
      openCreateFeeSet: state.fee.showCreateNewFeeSet || false,
      feeName: state.fee.newFeeSet.feeName || "",
      feePanelBrackets: state.fee.newFeeSet.feePanelBrackets || [],
      error: state.fee.newFeeSet.error,
      selectedLocation: (state.ui.newFee && state.ui.newFee.location) || "",
      editedLocation: state.fee.newFeeSet.editedLocation,
      newFeeSet: state.fee.newFeeSet,
      currentPage: state.fee.newFeeSet.currentPage || 0,
      pageSize: state.fee.newFeeSet.pageSize || 15,
    };
  });
  const theme = useTheme();
  const { classes } = useStyles();

  const createNewFeeSubmitEnabled =
    locations.length >= 1 && feeName.length >= 3;

  const closeCreateNewFeeSet = () => {
    dispatch(showCreateNewFeeSet(false));
  };

  const handleCreateFeeSet = () => {
    dispatch(createNewFeeSet(newFeeSet));
  };

  const handleFeeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFeeNameNewFeeSet(event.target.value));
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    dispatch(updateLocationOptionNewFeeSet(event.target.value as string));
  };

  const handleInputChange = (input: string) => {
    if (input !== newFeeSearchText) {
      dispatch(updateLocationTextChangeNewFeeSet(input, newFeeSearchOption));
    }
  };

  const initialFeeBracket: any = [
    {
      index: 0,
      lower: 0,
      upper: null,
      added: false,
    },
  ];
  const [feeBracketsLocal, setFeeBracketsLocal] =
    React.useState(initialFeeBracket);

  const [feeBracketExpanded, setFeeBracketExpanded] = React.useState(true);
  const handleFeeBracketExpandClick = () => {
    setFeeBracketExpanded(!feeBracketExpanded);
  };

  const updateLower = (index: number, lower: any) => {
    setFeeBracketsLocal(
      feeBracketsLocal.map((b: any) =>
        b.index === index ? { ...b, lower: lower } : b
      )
    );
  };
  const updateUpper = (index: number, upper: any) => {
    setFeeBracketsLocal(
      feeBracketsLocal.map((b: any) =>
        b.index === index
          ? { ...b, upper: upper }
          : b.index === index + 1
          ? { ...b, lower: parseInt(upper) }
          : b
      )
    );
  };

  const addFeeBracket = (index: number) => {
    setFeeBracketsLocal([
      ...feeBracketsLocal.map((b: any) =>
        b.index === index
          ? {
              ...b,
              lower: parseInt(b.lower),
              upper: parseInt(b.upper),
              added: true,
            }
          : b
      ),
      {
        index: index + 1,
        lower: parseInt(feeBracketsLocal[index].upper) + 1,
        upper: null,
        added: false,
      },
    ]);
  };

  const deleteFeeBracket = (index: number) => {
    setFeeBracketsLocal(
      feeBracketsLocal
        .filter((b: any) => b.index !== index)
        .map((b: any) =>
          b.index === index + 1
            ? {
                ...b,
                index: parseInt(b.index) - 1,
                lower:
                  index === 0 ? 0 : parseInt(feeBracketsLocal[index - 1].upper),
              }
            : b.index > index
            ? { ...b, index: parseInt(b.index) - 1 }
            : b
        )
    );
  };

  const handleUpdateFeeBrackets = () => {
    const addedFeeBrackets = feeBracketsLocal.filter((b: any) => b.added);
    dispatch(updateFeeBracketsNewFeeSet(addedFeeBrackets));
    setFeeBracketExpanded(false);
  };

  const feeBracketReadonly = feePanelBrackets.length > 0;
  const feeBrackets = feeBracketReadonly ? feePanelBrackets : feeBracketsLocal;

  const toLocaleInputFormat = (input: any) => {
    return input.toLocaleString();
  };

  const toLocaleNumberFormat = (input: any, hasDecimal: boolean) => {
    return hasDecimal
      ? Number(input).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : Number(input).toLocaleString();
  };

  const [openRemove, setOpenRemove] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedLocationId, setSelectedLocationId] = React.useState(null);
  const [isLocationModalOpen, setLocationModalOpen] = React.useState(false);
  const [selectedLocationName, setSelectedLocationName] = React.useState("");

  const handleExpandClick = (locationId: any) => {
    setSelectedLocationId(locationId);
    if (selectedLocationId === locationId) {
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
  };

  const handleEditLocation = (
    id: number,
    type: string,
    name: string,
    isExisting: boolean,
    countryId: number,
    countryName: string,
    locationId: any
  ) => {
    dispatch(
      updateSelectedLocationNewFee(
        id,
        type,
        name,
        isExisting,
        countryId,
        countryName,
        locationId
      )
    );
    locations
      .filter((loc: any) => loc.locationId === locationId)
      .map((location: any) => {
        const fees = location.fees.reduce((acc: any, v: any) => {
          acc.push({
            lower: v.lower,
            upper: v.upper,
            fee: v.fee,
          });
          return acc;
        }, []);
        dispatch(updateSelectedFeesNewFee(fees));
      });
    setLocationModalOpen(true);
  };

  const handleRemoveLocation = () => {
    dispatch(
      removeFeeLocationForNewFeeSet(selectedLocationId, selectedLocationName)
    );
    setOpenRemove(false);
  };

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
  };

  useEffect(() => {
    if (editedLocation) {
      setExpanded(editedLocation);
      setSelectedLocationId(editedLocation);
    }
  }, [editedLocation]);

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      flex: 8,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const locationId = params.row.locationId;
        const index = params.api.getRowIndexRelativeToVisibleRows(locationId);
        const shouldExpandCard = expanded && selectedLocationId === locationId;
        const {
          postcodeId,
          postcodeName,
          regionId,
          regionName,
          stateId,
          stateName,
          countryName,
          countryId,
          fees,
        } = params.row;
        const type = postcodeName
          ? "Postcode"
          : regionName
          ? "Region"
          : stateName
          ? "State"
          : "Country";
        const name = postcodeName || regionName || stateName || countryName;
        const id = postcodeId || regionId || stateId || countryId;

        const locationName = [
          countryName,
          type === "Country" ? null : type + " " + name,
        ]
          .filter(Boolean)
          .join(" ");

        return (
          <>
            <Card
              key={`card-${index}`}
              data-testid={`card-${index}`}
              className={classes.card}
            >
              <CardContent className={classes.cardContent}>
                <Grid container key={`location-${index}`}>
                  <Grid size={10} className={classes.locationHeader}>
                    <Typography
                      variant="body2"
                      data-testid={`location-${index}`}
                    >
                      {locationName}
                    </Typography>
                  </Grid>
                  <Grid size={2} className={classes.locationIcon}>
                    <div>
                      <IconButton
                        id={`btn-del-loc-${index}`}
                        data-testid={`btn-del-loc-${index}`}
                        sx={{ padding: "5px", marginRight: "4px" }}
                        onClick={(ev) => {
                          setOpenRemove(true);
                          setSelectedLocationName(locationName);
                          setSelectedLocationId(locationId);
                          ev.stopPropagation();
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            color: `${theme.colors.grey.main}`,
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        id={`btn-edit-loc-${index}`}
                        data-testid={`btn-edit-loc-${index}`}
                        sx={{ padding: "5px", marginRight: "4px" }}
                        onClick={(ev) => {
                          handleEditLocation(
                            id,
                            type,
                            name,
                            true,
                            countryId,
                            countryName,
                            locationId
                          );
                          ev.stopPropagation();
                        }}
                      >
                        <EditIcon
                          color="primary"
                          sx={{ width: "18px", height: "18px" }}
                        />
                      </IconButton>
                    </div>

                    <ExpandMore
                      sx={{ padding: "2px" }}
                      data-testid={`expand-${index}`}
                      expand={shouldExpandCard.toString()}
                      aria-expanded={shouldExpandCard}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Grid>
                </Grid>

                <Collapse in={shouldExpandCard} timeout="auto" unmountOnExit>
                  <CardContent className={classes.feeCardContent}>
                    {fees
                      .sort((a: any, b: any) => a.lower - b.lower)
                      .map((fee: any, indexFee: any) => {
                        const { lower, upper, fee: value } = fee;
                        return (
                          <Grid
                            container
                            key={`fee-${index}-${indexFee}`}
                            className={classes.feeGrid}
                          >
                            <Grid
                              size={8}
                              key={`fee-name-${index}-${indexFee}`}
                              data-testid={`fee-name-${index}-${indexFee}`}
                            >
                              <Typography>
                                ${" "}
                                {lower === 0
                                  ? "Any"
                                  : toLocaleNumberFormat(lower, false)}{" "}
                                - $ {toLocaleNumberFormat(upper, false)}
                              </Typography>
                            </Grid>
                            <Grid
                              size={4}
                              key={`fee-value-${index}-${indexFee}`}
                              data-testid={`fee-value-${index}-${indexFee}`}
                              className={classes.feeValue}
                            >
                              <Typography>
                                $ {toLocaleNumberFormat(value, true)} + GST
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </CardContent>
                </Collapse>
              </CardContent>
            </Card>
          </>
        );
      },
    },
  ];

  return (
    <PanelExDialog
      id="create-feeSet-dialog"
      data-testid="create-feeSet-dialog"
      open={openCreateFeeSet}
      title={`New Fee`}
      submitText="Create Fee"
      submitEnabled={createNewFeeSubmitEnabled}
      onClose={closeCreateNewFeeSet}
      onSubmit={handleCreateFeeSet}
      showAction={true}
    >
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "5",
          padding: "20px",
          paddingTop: "5px",
          minWidth: 620,
          minHeight: 400,
        }}
      >
        <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Grid size={12}>
            <TextField
              className={classes.feeSetName}
              id="fee-set-name"
              data-testid="fee-set-name"
              placeholder="Enter Fee Name"
              size="small"
              value={feeName}
              onChange={handleFeeNameChange}
              fullWidth={true}
              error={!!error}
              helperText={error}
            />
          </Grid>
          <Grid size={12}>
            <Card
              id="card-fee-bracket"
              data-testid="card-fee-bracket"
              className={classes.feeBracketCard}
            >
              <CardContent
                className={`${classes.cardContent} ${classes.feeBracketCardContent}`}
              >
                <Grid container onClick={handleFeeBracketExpandClick}>
                  <Grid
                    size={10}
                    className={`${classes.expandHeader} ${classes.feeBracketLocationHeader}`}
                  >
                    <Typography variant="body2" data-testid="fee-bracket-title">
                      Setup Estimated Property Value Range
                    </Typography>
                  </Grid>
                  <Grid
                    size={2}
                    className={`${classes.expandIcon} ${classes.feeBracketLocationIcon}`}
                  >
                    <ExpandMore
                      sx={{ padding: "2px" }}
                      data-testid="expand-fee-bracket"
                      expand={feeBracketExpanded.toString()}
                      aria-expanded={feeBracketExpanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Grid>
                </Grid>

                <Collapse in={feeBracketExpanded} timeout="auto" unmountOnExit>
                  <CardContent className={classes.feeCardContent}>
                    {feeBrackets && (
                      <React.Fragment>
                        <Grid
                          container
                          id="fee-bracket-grid-header"
                          data-testid="fee-bracket-grid-header"
                          className={classes.feeBracketGridHeader}
                        >
                          <Grid size={3}>
                            <Typography>{"Lower >="} </Typography>
                          </Grid>
                          <Grid size={3}>
                            <Typography>{"Upper <"}</Typography>
                          </Grid>
                        </Grid>
                        {feeBrackets
                          .sort((a: any, b: any) => a.lower - b.lower)
                          .map((fee: any, index: number) => (
                            <Grid
                              container
                              key={`fee-bracket-${index}`}
                              className={`${classes.feeGrid} ${classes.feeBracketGrid}`}
                            >
                              <Grid
                                size={3}
                                key={`fee-lower-${index}`}
                                data-testid={`fee-lower-${index}`}
                              >
                                <TextField
                                  id={`fee-lower-value-${index}`}
                                  data-testid={`fee-lower-value-${index}`}
                                  className={
                                    feeBrackets.length == 1 &&
                                    !feeBracketReadonly
                                      ? classes.feeTextUpper
                                      : classes.feeText
                                  }
                                  label="From"
                                  placeholder={
                                    fee.lower ? fee.lower.toString() : "Any"
                                  }
                                  disabled={
                                    feeBracketReadonly ||
                                    index != 0 ||
                                    fee.added
                                  }
                                  onClick={(e: any) => e.stopPropagation()}
                                  value={
                                    fee.lower
                                      ? toLocaleInputFormat(fee.lower)
                                      : ""
                                  }
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    const regex = /^[0-9\b]+$/;
                                    if (input === "" || regex.test(input)) {
                                      updateLower(index, input);
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid
                                size={3}
                                sx={{ paddingLeft: "5px" }}
                                key={`fee-upper-${index}`}
                                data-testid={`fee-upper-${index}`}
                              >
                                <TextField
                                  id={`fee-upper-value-${index}`}
                                  data-testid={`fee-upper-value-${index}`}
                                  className={classes.feeTextUpper}
                                  label="To"
                                  disabled={feeBracketReadonly || fee.added}
                                  placeholder="Enter value"
                                  onClick={(e: any) => e.stopPropagation()}
                                  value={
                                    fee.upper
                                      ? toLocaleInputFormat(fee.upper)
                                      : ""
                                  }
                                  onChange={(event: any) => {
                                    const input = event.target.value;
                                    const regex = /^[0-9\b]+$/;
                                    if (input === "" || regex.test(input)) {
                                      updateUpper(index, input);
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid
                                size={3}
                                sx={{ paddingLeft: "5px", width: "32px" }}
                                key={`fee-action-${index}`}
                                data-testid={`fee-action-${index}`}
                              >
                                {feeBracketReadonly ? null : fee.added ? (
                                  <IconButton
                                    id={`fee-delete-${index}`}
                                    data-testid={`fee-delete-${index}`}
                                    disabled={index < feeBrackets.length - 2}
                                    onClick={() => deleteFeeBracket(index)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    id={`fee-add-${index}`}
                                    data-testid={`fee-add-${index}`}
                                    onClick={() => addFeeBracket(index)}
                                    disabled={
                                      fee.upper
                                        ? parseInt(fee.upper) < fee.lower
                                        : true
                                    }
                                  >
                                    <AddOutlinedIcon />
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        {!feeBracketReadonly ? (
                          feeBrackets.filter((b: any) => b.added).length > 0 ? (
                            <Grid
                              container
                              className={`${classes.feeGrid} ${classes.feeBracketGrid} ${classes.feeBracketGridAction}`}
                            >
                              <Grid size={6}>
                                <Button
                                  id="btn-add-brackets"
                                  data-testid="btn-add-brackets"
                                  className={classes.doneButton}
                                  variant="outlined"
                                  onClick={handleUpdateFeeBrackets}
                                >
                                  Done
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <Box
                              id="fee-bracket-message"
                              data-testid="fee-bracket-message"
                              className={classes.feeBracketMessage}
                            >
                              <Typography>
                                Create an Estimated Property Value Range
                              </Typography>
                              <Typography>
                                Set up a range to determine valuation fees.
                              </Typography>
                            </Box>
                          )
                        ) : null}
                      </React.Fragment>
                    )}
                  </CardContent>
                </Collapse>
              </CardContent>
            </Card>
          </Grid>
          {feePanelBrackets.length > 0 && (
            <Grid container size={12}>
              <Grid container size={12} sx={{ paddingTop: "10px" }}>
                <Grid size={5}>
                  <Typography
                    data-testid="fee-detail-title"
                    className={classes.locationLabel}
                  >
                    {locations.length} Locations
                  </Typography>
                </Grid>
                <Grid size={7}>
                  <Stack direction="row" className={classes.stack}>
                    <Select
                      labelId="location-filter-country-label"
                      id="location-filter-country"
                      data-testid="location-filter-country"
                      value={newFeeSearchOption}
                      className={classes.select}
                      onChange={handleCountryChange}
                    >
                      <MenuItem value={"1"}>AU</MenuItem>
                      <MenuItem value={"2"}>NZ</MenuItem>
                    </Select>
                    <PanelExAutocomplete
                      id="location-filter"
                      options={newFeeSuggestions}
                      value={newFeeSearchText}
                      getOptionLabel={(option: any) =>
                        option.name || newFeeSearchText || ""
                      }
                      placeholder="Search Location"
                      onInputChange={(value: any) => {
                        handleInputChange(value);
                      }}
                      renderOption={(props: any, option: any) => {
                        const {
                          id,
                          name,
                          type,
                          countryId,
                          countryName,
                          isExisting,
                        } = option;
                        const displayName =
                          type === "Country"
                            ? [name, type].join(" ")
                            : [countryName, type, name].join(" ");
                        const regex = new RegExp(
                          escapedSpecialCharacters(newFeeSearchText),
                          "gi"
                        );
                        const response = displayName.replace(
                          regex,
                          function (str: any) {
                            return (
                              "<span style='font-weight:500'>" + str + "</span>"
                            );
                          }
                        );
                        return (
                          <li
                            {...props}
                            data-testid={`add-location-label-${id}`}
                            id={`add-location-label-${id}`}
                            onClick={(ev) => {
                              !isExisting &&
                                feePanelBrackets.length > 0 &&
                                handleEditLocation(
                                  id,
                                  type,
                                  name,
                                  isExisting,
                                  countryId,
                                  countryName,
                                  "locationId"
                                );
                              ev.stopPropagation();
                            }}
                            style={{ justifyContent: "space-between" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${response}`,
                              }}
                            ></div>
                            <AddCircleIcon
                              id={`add-location-icon-${id}`}
                              data-testid={`add-location-icon-${id}`}
                              style={{
                                ...(!isExisting && feePanelBrackets.length > 0
                                  ? {
                                      color: "#757575",
                                    }
                                  : {
                                      color: "rgba(0, 0, 0, 0.12)",
                                    }),
                              }}
                            />
                          </li>
                        );
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {locations.length > 0 ? (
                <Grid
                  size={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "400",
                    width: "100%",
                  }}
                >
                  <DataGridPro
                    data-testid="tbl-fee-details"
                    className={classes.detailsGrid}
                    getRowId={(row) => row.locationId}
                    rows={locations}
                    rowCount={locations.length}
                    getRowHeight={() => "auto"}
                    columns={columns}
                    pagination
                    columnHeaderHeight={0}
                    paginationModel={paginationModel}
                    paginationMode="client"
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={[15, 20, 50]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                    onCellClick={(
                      params: GridCellParams,
                      event: MuiEvent<React.MouseEvent>
                    ) => {
                      handleExpandClick(params.row.locationId);
                      event.defaultMuiPrevented = true;
                    }}
                  />
                </Grid>
              ) : (
                <Grid
                  size={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    id="fee-details-message"
                    data-testid="fee-details-message"
                    className={classes.feeDetailMessage}
                  >
                    <FeesIcon
                      sx={{
                        width: "45px",
                        height: "64px",
                        color: theme.colors.grey.lighter,
                      }}
                      id="fee-details-message-icon"
                      data-testid="fee-details-message-icon"
                    />
                    <Typography>Add a location to set valuation fee</Typography>
                    <Typography>
                      Valuation fees may vary depending on the property's
                      estimated value and location.
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
          <PanelExDialog
            id="remove-dialog"
            data-testid="remove-dialog"
            open={openRemove}
            title={`Are you sure you want to remove ${selectedLocationName}?`}
            submitText="Yes, Remove"
            submitEnabled={true}
            onClose={() => setOpenRemove(false)}
            onSubmit={handleRemoveLocation}
            showAction={true}
            maxWidth="sm"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: "5",
                padding: "32px 24px 32px 24px",
              }}
            >
              <Typography variant="body1">
                Removing this location will remove any associated valuation
                firms. This action cannot be undone.
              </Typography>
            </Box>
          </PanelExDialog>
          {selectedLocation && (
            <LocationModalNewFee
              feeId={0}
              feeVersionId={0}
              location={selectedLocation}
              open={isLocationModalOpen}
              setOpen={setLocationModalOpen}
            />
          )}
        </Grid>
      </Box>
    </PanelExDialog>
  );
}
