import * as React from "react";
import { Snackbar, SnackbarContent, SvgIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import { closeSnackbar } from "../../store/action/actionUI";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import { changeAllocationTab } from "../../store/action/actionAllocation";
import { changeFeeTab } from "../../store/action/actionFee";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as ProgressCircleWhite } from "../../img/ProgressCircleWhite.svg";

declare module "@mui/material/Snackbar" {
  interface SnackbarProps {
    variant: "info" | "success" | "error";
  }
}

export default function PanelExSnackbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const snackbar = useSelector((state: RootState) => state.ui.snackbar);
  const getAction = (actionType: string) => {
    if (actionType === "previewDetail") {
      return (
        <React.Fragment>
          <Button
            data-testid="btn-preview-detail"
            id="btn-preview-detail"
            size="small"
            onClick={() => {
              dispatch(changeAllocationTab(0));
              dispatch(closeSnackbar());
            }}
            sx={{ color: theme.palette.primary.light }}
          >
            Preview on Details
          </Button>
          <IconButton
            data-testid="icon-btn-close"
            id="icon-btn-close"
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => dispatch(closeSnackbar())}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      );
    } else if (actionType === "redirect") {
      return (
        <React.Fragment>
          <Button
            data-testid="btn-preview-detail"
            id="btn-preview-detail"
            size="small"
            onClick={() => {
              history.push(`/allocations/${snackbar.id}`);
              dispatch(closeSnackbar());
            }}
            sx={{ color: theme.palette.primary.light }}
          >
            Preview on Details
          </Button>
          <IconButton
            data-testid="icon-btn-close"
            id="icon-btn-close"
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => dispatch(closeSnackbar())}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      );
    } else if (actionType === "previewDetailFee") {
      return (
        <React.Fragment>
          <Button
            data-testid="btn-preview-detail"
            id="btn-preview-detail"
            size="small"
            onClick={() => {
              dispatch(changeFeeTab(0));
              dispatch(closeSnackbar());
            }}
            sx={{ color: theme.palette.primary.light }}
          >
            Preview on Details
          </Button>
          <IconButton
            data-testid="icon-btn-close"
            id="icon-btn-close"
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => dispatch(closeSnackbar())}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      );
    } else if (actionType === "redirectFee") {
      return (
        <React.Fragment>
          <Button
            data-testid="btn-preview-detail"
            id="btn-preview-detail"
            size="small"
            onClick={() => {
              history.push(`/fees/${snackbar.id}`);
              dispatch(closeSnackbar());
            }}
            sx={{ color: theme.palette.primary.light }}
          >
            Preview on Details
          </Button>
          <IconButton
            data-testid="icon-btn-close"
            id="icon-btn-close"
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={() => dispatch(closeSnackbar())}
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      <Snackbar
        id="snackbar-alert"
        data-testid="snackbar-alert"
        variant={snackbar.variant}
        open={snackbar.open}
        autoHideDuration={snackbar.duration}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => dispatch(closeSnackbar())}
      >
        <SnackbarContent
          message={
            <React.Fragment>
              {snackbar.isIcon && (
                <CheckIcon
                  data-testid="icon-check"
                  sx={{ margin: "0", paddingRight: "8px" }}
                />
              )}
              {snackbar.showLoading && (
                <SvgIcon
                  data-testid="icon-loading"
                  sx={{ margin: "0", paddingRight: "8px" }}
                >
                  <ProgressCircleWhite />
                </SvgIcon>
              )}
              {snackbar.message}
            </React.Fragment>
          }
          action={getAction(snackbar.actionType)}
        />
      </Snackbar>
    </>
  );
}
