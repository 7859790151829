import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  getAllocationDetail,
  updateLocationTextChange,
  removeAllocationLocation,
  getLatestAllocationActivationLog,
  addValfirmSelectedValfirms,
  deleteValfirmSelectedValfirms,
  setAddValfirmText,
  addLocationSelectedLocations,
  deleteLocationSelectedLocations,
  clearAllSelectedValfirmsLocations,
  getAllocationVersionExistingValfirms,
} from "../../store/action/actionAllocation";
import {
  updateSelectedLocation,
  updateSelectedAllocations,
  clearLocationSelection,
} from "../../store/action/actionUI";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid2 as Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReplyAllOutlined from "@mui/icons-material/ReplyAllOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { escapedSpecialCharacters } from "../../utilities/stringUtil";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridCellParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";

import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import LocationModal from "./LocationModal";
import PanelExAutocomplete2 from "../shared/PanelExAutocomplete2";
import PanelExDialog from "../shared/PanelExDialog";
import ValfirmAdditionalStatus from "../shared/ValfirmAdditionalStatus";
import ValfirmAdditionalStatusIcon from "../shared/ValfirmAdditionalStatusIcon";

interface ExpandMoreProps extends IconButtonProps {
  expand: string;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: theme.colors.grey.main,
  transform: expand === "false" ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface AllocationDetailProps {
  allocationVersionId: number;
}

export default function AllocationDetail(props: AllocationDetailProps) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    button: {
      minWidth: "150px",
      color: theme.palette.common.white,
      "& .MuiButton-startIcon": {
        color: theme.palette.common.white,
      },
      marginLeft: "4px",
    },
    select: {
      borderRadius: "4px",
      marginRight: "4px",
      width: "90px",
      paddingBottom: "0px",
    },
    detailsHeader: {
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.15px",
        color: "rgba(0,0,0,0.8)",
      },
    },
    detailsGrid: {
      width: "100%",
      cursor: "pointer",
      "& .MuiDataGrid-withBorderColor": {
        border: "0px",
      },
      "& .MuiDataGrid-cell": {
        padding: "0px",
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "0px",
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "0px",
      },
    },
    card: {
      width: "100%",
      padding: "12px 0 0 0",
      background: "#FAFBFC",
    },
    cardContent: {
      padding: "0px",
      background: theme.palette.common.white,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "0px",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      "&:last-child": {
        paddingBottom: "0px",
      },
    },
    locationHeader: {
      height: "32px",
      background: "#f5f5f5",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 400,
        color: theme.palette.common.black,
        padding: "6px 12px",
      },
    },
    locationIcon: {
      height: "32px",
      background: "#f5f5f5",
      display: "flex",
      justifyContent: "end",
      paddingRight: "12px",
    },
    allocationCardContent: {
      padding: "0px",
      "&:last-child": {
        padding: "0px",
      },
    },
    allocationGrid: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "12px",
      "& .MuiTypography-root": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
      },
    },
    allocationValue: {
      paddingRight: "12px",
      display: "flex",
      justifyContent: "end",
    },
    valfirmStatus: {
      paddingLeft: "20px",
      display: "flex",
      justifyContent: "end",
    },
    stack: {
      width: "100%",
      maxHeight: "38px",
      justifyContent: "flex-end",
    },
    allocationDetailMessage: {
      width: "520px",
      marginTop: "85px",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",

      "& .MuiTypography-root": {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.colors.grey.main,

        "&:first-of-type": {
          paddingBottom: "22px",
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "28px",
          color: theme.colors.grey.dark,
        },
        "&:last-of-type": {
          paddingTop: "8px",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "21px",
          color: theme.colors.grey.light,
        },
      },
    },
    searchButton: {
      minWidth: "38px",
      height: "38px",
      marginLeft: "4px",
      backgroundColor: "#3399FF",
      color: "#FFFFFF",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
    },
    addButton: {
      height: "38px",
      marginLeft: "4px",
      color: "#FFFFFF",
      "& .MuiButton-startIcon": {
        margin: "0px",
      },
    },
    notTrading: {
      color: theme.palette.text.disabled,
    },
    atCapacity: {
      color: theme.colors.error.darker,
    },
    autocomplete: {
      padding: "2px 0px 2px 0px",
      width: "550px",
      height: "38px",
      background: "#FFFFFF",
      borderRadius: "4px",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "18px",
      color: theme.palette.common.black,
      [theme.breakpoints.up("md")]: {
        width: "630px",
      },
      [theme.breakpoints.down("md")]: {
        width: "360px",
      },
      "&:focus": {
        border: "2px solid #3399FF",
      },
      "& .MuiTextField-root": {
        marginTop: "-3px",
      },
      "& .MuiInputBase-root": {
        height: "38px",
      },
      "& .MuiOutlinedInput-root": {
        paddingRight: "9px !important",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
    },
    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "4px",
      zIndex: "1401",

      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
      },
    },
  }));
  const { classes } = useStyles();

  const allocationVersionId = props.allocationVersionId;
  const {
    allocationSet,
    allocationId,
    loading,
    requestedActivationLog,
    latestVersionLocationCount,
    reloadDetail,
    locations,
    rowCount,
    currentPage,
    pageSize,
    searchText,
    locationSuggestion,
    selectedLocations,
    editedLocation,
    countryId,
    valfirmText,
    selectedValfirms,
    existingValfirms,
    filteredOptions,
    readOnly,
  } = useSelector((state: RootState) => {
    const allocationSet = state.allocation.allocationSet || {};
    return {
      allocationSet: allocationSet,
      allocationId: allocationSet.allocPanelId,
      loading: state.allocation.loading,
      requestedActivationLog: state.allocation.latestAllocationActivationLog,
      latestVersionLocationCount: state.allocation.latestVersionLocationCount,
      reloadDetail: state.allocation.reloadDetail,
      locations: state.allocation.allocationDetail.locations,
      rowCount: state.allocation.allocationDetail.locationCount || 0,
      currentPage: state.allocation.allocationDetail.currentPage || 0,
      pageSize: state.allocation.allocationDetail.pageSize || 15,
      searchText: state.allocation.allocationDetail.searchText || "",
      locationSuggestion:
        state.allocation.allocationDetail.locationSuggestion || [],
      selectedLocations: state.allocation.allocationDetail.selectedLocations,
      editedLocation: state.allocation.allocationDetail.editedLocation,
      countryId: state.allocation.allocationSet.countryId || 1,
      valfirmText: state.allocation.allocationDetail.valfirmText,
      selectedValfirms: state.allocation.allocationDetail.selectedValfirms,
      existingValfirms: state.allocation.allocationDetail.existingValfirms,
      filteredOptions: state.allocation.allocationDetail.filteredOptions,
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  const { editable } = useSelector((state: RootState) => {
    const allocationVersionDetail = state.allocation.allocationVersionDetail;
    return {
      editable:
        allocationSet.allocRevId != allocationVersionId &&
        allocationVersionDetail.editable &&
        !allocationVersionDetail.schedularRun &&
        !allocationVersionDetail.discard &&
        requestedActivationLog &&
        requestedActivationLog.status !== "Queued",
    };
  });

  useEffect(() => {
    if ((!!allocationId && !!allocationVersionId) || !!reloadDetail) {
      dispatch(
        getAllocationDetail(
          allocationId,
          allocationVersionId,
          [],
          [],
          0,
          paginationModel.pageSize
        )
      );
      dispatch(clearAllSelectedValfirmsLocations());
      dispatch(
        getAllocationVersionExistingValfirms(allocationId, allocationVersionId)
      );
    }
    paginationModel.page = 0;
    setPaginationModel(paginationModel);
  }, [dispatch, allocationId, allocationVersionId, reloadDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allocationId) {
      dispatch(getLatestAllocationActivationLog(allocationId));
    }
  }, [dispatch, allocationId]);

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    dispatch(
      getAllocationDetail(
        allocationId,
        allocationVersionId,
        selectedValfirms,
        selectedLocations,
        newPage,
        newPageSize
      )
    );
  };

  const [expanded, setExpanded] = React.useState(true);
  const [selectedLocationId, setSelectedLocationId] = React.useState(null);
  const [isLocationModalOpen, setLocationModalOpen] = React.useState(false);
  const [isNewLocationToAdd, setNewLocationToAdd] = React.useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [selectedLocationName, setSelectedLocationName] = React.useState("");

  useEffect(() => {
    if (editedLocation) {
      setExpanded(true);
      setSelectedLocationId(editedLocation);
    }
  }, [editedLocation]);

  useEffect(() => {
    if (locations.length > 0) {
      setExpanded(true);
      setSelectedLocationId(locations[0].allocationPanelFilterId);
    }
  }, [locations]);

  const handleExpandClick = (locationId: any) => {
    setSelectedLocationId(locationId);
    if (selectedLocationId === locationId) {
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
  };

  const handleInputChange = (input: string) => {
    if (input !== searchText) {
      dispatch(
        updateLocationTextChange(
          input,
          countryId,
          allocationId,
          allocationVersionId,
          true
        )
      );
    }
  };

  const handleEditLocation = (
    id: number,
    type: string,
    name: string,
    isExisting: boolean,
    countryId: number,
    countryName: string,
    allocationPanelFilterId: any
  ) => {
    dispatch(
      updateSelectedLocation(
        id,
        type,
        name,
        isExisting,
        countryId,
        countryName,
        allocationPanelFilterId,
        allocationVersionId
      )
    );
    if (isExisting) {
      //copy existing allocations to ui state
      locations
        .filter(
          (loc: any) => loc.allocationPanelFilterId === allocationPanelFilterId
        )
        .map((location: any) => {
          const allocations = location.allocations.reduce(
            (acc: any, v: any) => {
              acc.push({
                allocationPanelAllocationsId: v.allocationPanelAllocationsId,
                valfirmId: v.orgId,
                valfirmName: v.orgName,
                value: v.allocation,
                isEdited: null,
                isDeleted: null,
                additionalStatus: v.additionalStatus,
              });
              return acc;
            },
            []
          );
          dispatch(updateSelectedAllocations(allocations));
        });
    }
    setNewLocationToAdd(false);
    setLocationModalOpen(true);
  };

  const handleRemoveLocation = () => {
    dispatch(
      removeAllocationLocation(
        allocationId,
        allocationVersionId,
        selectedLocationId,
        selectedLocationName,
        selectedValfirms,
        selectedLocations,
        pageSize
      )
    );
    setOpenRemove(false);
  };

  const handleAddLocation = (event: any) => {
    dispatch(clearLocationSelection());
    setNewLocationToAdd(true);
    setLocationModalOpen(true);
    event.stopPropagation();
  };

  const { selectedLocation } = useSelector((state: RootState) => {
    return {
      selectedLocation: state.ui.location,
    };
  });

  const getStatusStyle = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return classes.notTrading;
      case "ATCAPACITY":
        return classes.atCapacity;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const allocPanelFilterId = params.row.allocationPanelFilterId;
        const index =
          params.api.getRowIndexRelativeToVisibleRows(allocPanelFilterId);
        const shouldExpandCard =
          filteredOptions.selectedValfirms.length > 0 ||
          filteredOptions.selectedLocations.length > 0
            ? true
            : expanded && selectedLocationId === allocPanelFilterId;
        const {
          postcodeId,
          postcodeName,
          regionId,
          regionName,
          stateId,
          stateName,
          countryName,
          countryId,
          allocations,
        } = params.row;
        const type = postcodeName
          ? "Postcode"
          : regionName
          ? "Region"
          : stateName
          ? "State"
          : "Country";
        const name = postcodeName || regionName || stateName || countryName;
        const id = postcodeId || regionId || stateId || countryId;

        const locationName = [
          countryName,
          type === "Country" ? null : type + " " + name,
        ]
          .filter(Boolean)
          .join(" ");

        return (
          <Card
            key={`card-${index}`}
            data-testid={`card-${index}`}
            className={classes.card}
          >
            <CardContent className={classes.cardContent}>
              <Grid container key={`location-${index}`}>
                <Grid size={10} className={classes.locationHeader}>
                  <Typography variant="body2" data-testid={`location-${index}`}>
                    {locationName}
                  </Typography>
                </Grid>
                <Grid size={2} className={classes.locationIcon}>
                  {editable && !readOnly && (
                    <div>
                      <IconButton
                        id={`btn-del-loc-${index}`}
                        data-testid={`btn-del-loc-${index}`}
                        sx={{ padding: "5px", marginRight: "4px" }}
                        onClick={(ev) => {
                          setOpenRemove(true);
                          setSelectedLocationName(locationName);
                          setSelectedLocationId(allocPanelFilterId);
                          ev.stopPropagation();
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            color: `${theme.colors.grey.main}`,
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        id={`btn-edit-org-${index}`}
                        data-testid={`btn-edit-org-${index}`}
                        sx={{ padding: "5px", marginRight: "4px" }}
                        onClick={(ev) => {
                          handleEditLocation(
                            id,
                            type,
                            name,
                            true,
                            countryId,
                            countryName,
                            allocPanelFilterId
                          );
                          ev.stopPropagation();
                        }}
                      >
                        <EditIcon
                          color="primary"
                          sx={{ width: "18px", height: "18px" }}
                        />
                      </IconButton>
                    </div>
                  )}
                  <ExpandMore
                    sx={{ padding: "2px" }}
                    data-testid={`expand-${index}`}
                    expand={shouldExpandCard.toString()}
                    aria-expanded={shouldExpandCard}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Grid>
              </Grid>

              <Collapse in={shouldExpandCard} timeout="auto" unmountOnExit>
                <CardContent className={classes.allocationCardContent}>
                  {allocations.map((alloc: any) => {
                    const filterdValfirm =
                      filteredOptions.selectedValfirms.find(
                        (valfirm: any) => valfirm.id === alloc.orgId
                      );
                    return (
                      <Grid
                        container
                        size={12}
                        key={`allocation-${index}-${alloc.orgId}`}
                        className={classes.allocationGrid}
                        sx={{
                          backgroundColor:
                            filterdValfirm !== undefined
                              ? "#FBFFD2"
                              : "#FFFFFF",
                        }}
                      >
                        <Grid
                          size={{ xs: 8, sm: 9, md: 9, lg: 9, xl: 9 }}
                          key={`alloc-name-${alloc.orgId}`}
                          data-testid={`alloc-name-${alloc.orgId}`}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <Stack direction="row">
                            <ValfirmAdditionalStatusIcon
                              additionalStatus={alloc.additionalStatus}
                            />
                            <Typography
                              variant="h5"
                              className={getStatusStyle(alloc.additionalStatus)}
                            >
                              {alloc.orgName}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          container
                          size={{ xs: 4, sm: 3, md: 3, lg: 3, xl: 3 }}
                          alignItems={"center"}
                        >
                          <Grid
                            size={9}
                            key={`alloc-status-${alloc.orgId}`}
                            data-testid={`alloc-status-${alloc.orgId}`}
                            className={classes.valfirmStatus}
                          >
                            {alloc.additionalStatus &&
                              alloc.additionalStatus != "NONE" &&
                              alloc.additionalStatus != "REVIEW" && (
                                <ValfirmAdditionalStatus
                                  additionalStatus={alloc.additionalStatus}
                                />
                              )}
                          </Grid>
                          <Grid
                            size={3}
                            key={`alloc-value-${alloc.orgId}`}
                            data-testid={`alloc-value-${alloc.orgId}`}
                            className={classes.allocationValue}
                          >
                            <Typography
                              variant="h5"
                              className={getStatusStyle(alloc.additionalStatus)}
                            >
                              {alloc.allocation} %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </CardContent>
              </Collapse>
            </CardContent>
          </Card>
        );
      },
    },
  ];

  const handleValfirmInputChange = (input: string) => {
    if (input !== valfirmText) {
      dispatch(setAddValfirmText(input));
    }
  };

  const handleAddValFirmInFilter = (valfirm: any) => {
    dispatch(addValfirmSelectedValfirms(valfirm));
  };

  const handleAddLocationInFilter = (location: any) => {
    dispatch(addLocationSelectedLocations(location));
  };

  const isClearAllDisabled = () => {
    return selectedLocations.length === 0 && selectedValfirms.length === 0;
  };

  const isDisabled = () => {
    return (
      JSON.stringify(filteredOptions.selectedLocations) ===
        JSON.stringify(selectedLocations) &&
      JSON.stringify(filteredOptions.selectedValfirms) ===
        JSON.stringify(selectedValfirms)
    );
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      data-testid="allocation-detail"
    >
      <Grid container>
        {loading ? (
          <Grid size={12}>
            <Box
              data-testid="allocation-detail-progress"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container size={12}>
            <Grid container size={12} paddingBottom={"19px"}>
              <Grid size={5}>
                <Typography data-testid="allocation-detail-title" variant="h2">
                  Locations
                </Typography>
              </Grid>
              <Grid size={7}>
                <Stack direction="row" className={classes.stack}>
                  {!readOnly && (
                    <Tooltip
                      id="tooltip-add-location"
                      data-testid="tooltip-add-location"
                      title="Edit in Draft to Add Location"
                    >
                      <span>
                        <Button
                          id="add-location-button"
                          data-testid="add-location-button"
                          variant="contained"
                          className={classes.addButton}
                          startIcon={<AddIcon />}
                          disabled={!editable}
                          onClick={(ev) => {
                            handleAddLocation(ev);
                          }}
                        >
                          Add Location
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Grid size={12}>
              <Stack direction={"column"} spacing={1}>
                <PanelExAutocomplete2
                  id="allocation-valfirm-filter"
                  data-testid="allocation-valfirm-filter"
                  options={existingValfirms}
                  value={selectedValfirms}
                  inputValue={valfirmText}
                  limitTags={2}
                  getOptionLabel={(option: any) =>
                    option.name || valfirmText || ""
                  }
                  placeholder="Search Valfirm"
                  isFilterOptions={true}
                  onInputChange={(value: any) => {
                    handleValfirmInputChange(value);
                  }}
                  onChange={(valfirm: any) => {
                    dispatch(deleteValfirmSelectedValfirms(valfirm));
                  }}
                  renderOption={(props: any, option: any) => {
                    const { id, name, additionalStatus } = option;

                    const isExisting =
                      selectedValfirms.find((v: any) => v.id === id) !==
                      undefined;
                    const regex = new RegExp(
                      escapedSpecialCharacters(valfirmText),
                      "gi"
                    );
                    const response = name.replace(regex, function (str: any) {
                      return "<span style='font-weight:500'>" + str + "</span>";
                    });
                    return (
                      <li
                        {...props}
                        data-testid={`valfirm-search-label-${id}`}
                        id={`valfirm-search-label-${id}`}
                        onClick={(ev) => {
                          !isExisting && handleAddValFirmInFilter(option);
                          ev.stopPropagation();
                        }}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div style={{ justifyContent: "right" }}>
                          <Stack direction={"row"}>
                            <ValfirmAdditionalStatusIcon
                              additionalStatus={additionalStatus}
                            />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${response}`,
                              }}
                            ></div>
                          </Stack>
                        </div>
                        <AddCircleIcon
                          data-testid={`add-valfirm-search-icon-${id}`}
                          id={`add-valfirm-search-icon-${id}`}
                          style={{
                            ...(!isExisting
                              ? {
                                  color: "#757575",
                                }
                              : {
                                  color: "rgba(0, 0, 0, 0.12)",
                                }),
                          }}
                        />
                      </li>
                    );
                  }}
                />
                <PanelExAutocomplete2
                  id="location-filter"
                  data-testid="location-filter"
                  options={locationSuggestion}
                  value={selectedLocations}
                  inputValue={searchText}
                  limitTags={5}
                  getOptionLabel={(option: any) =>
                    option.name || searchText || ""
                  }
                  placeholder="Search Location"
                  onChange={(value: any) => {
                    dispatch(deleteLocationSelectedLocations(value));
                  }}
                  onInputChange={(value: any) => {
                    handleInputChange(value);
                  }}
                  popperPlacement={"bottom-start"}
                  renderOption={(props: any, option: any) => {
                    const { id, name, type, countryName } = option;
                    const isExisting =
                      selectedLocations.find((v: any) => v.id === id) !==
                      undefined;

                    const displayName =
                      type === "Country"
                        ? [name, type].join(" ")
                        : [countryName, type, name].join(" ");
                    const regex = new RegExp(
                      escapedSpecialCharacters(searchText),
                      "gi"
                    );
                    const response = displayName.replace(
                      regex,
                      function (str: any) {
                        return (
                          "<span style='font-weight:500'>" + str + "</span>"
                        );
                      }
                    );
                    return (
                      <li
                        data-testid={`add-location-label-${id}`}
                        {...props}
                        style={{ justifyContent: "space-between" }}
                        onClick={(ev) => {
                          !isExisting && handleAddLocationInFilter(option);
                          ev.stopPropagation();
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: `${response}` }}
                        ></div>
                        <AddCircleIcon
                          data-testid={`add-location-icon-${id}`}
                          style={{
                            ...(!isExisting
                              ? {
                                  color: "#757575",
                                }
                              : {
                                  color: "rgba(0, 0, 0, 0.12)",
                                }),
                          }}
                        />
                      </li>
                    );
                  }}
                />
                <Grid size={12} display="flex" justifyContent="center">
                  <Stack direction={"row"} spacing={2}>
                    <Button
                      id="btn-clear-all"
                      data-testid="btn-clear-all"
                      variant="text"
                      disabled={isClearAllDisabled()}
                      onClick={() => {
                        dispatch(clearAllSelectedValfirmsLocations());
                      }}
                    >
                      Clear All
                    </Button>
                    <Button
                      id="btn-apply-filter"
                      data-testid="btn-apply-filter"
                      variant="outlined"
                      disabled={isDisabled()}
                      onClick={() => {
                        dispatch(
                          getAllocationDetail(
                            allocationId,
                            allocationVersionId,
                            selectedValfirms,
                            selectedLocations,
                            paginationModel.page,
                            paginationModel.pageSize
                          )
                        );
                      }}
                      startIcon={<FilterListIcon />}
                    >
                      Apply Filter
                    </Button>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>

            <Grid container size={12}>
              <Grid size={5} className={classes.detailsHeader}>
                <Typography data-testid="allocation-detail-count">
                  {rowCount} Locations
                </Typography>
              </Grid>
            </Grid>
            {rowCount > 0 ? (
              <Grid
                size={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 400,
                  width: "100%",
                }}
              >
                <DataGridPro
                  data-testid="tbl-allocation-details"
                  className={classes.detailsGrid}
                  getRowId={(row) => row.allocationPanelFilterId}
                  rows={locations}
                  rowCount={rowCount}
                  getRowHeight={() => "auto"}
                  columns={columns}
                  columnHeaderHeight={0}
                  pagination
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  pageSizeOptions={[15, 20, 50]}
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                  onCellClick={(
                    params: GridCellParams,
                    event: MuiEvent<React.MouseEvent>
                  ) => {
                    handleExpandClick(params.row.allocationPanelFilterId);
                    event.defaultMuiPrevented = true;
                  }}
                />
              </Grid>
            ) : (
              <Grid
                size={12}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  id="allocation-details-message"
                  data-testid="allocation-details-message"
                  className={classes.allocationDetailMessage}
                >
                  {latestVersionLocationCount === 0 ? (
                    <>
                      <ReplyAllOutlined
                        sx={{
                          width: "45px",
                          height: "64px",
                          transform: `rotate(-90deg)`,
                          color: theme.colors.grey.lighter,
                        }}
                        id="allocation-details-message-icon"
                        data-testid="allocation-details-message-icon"
                      />
                      <Typography>Empty Allocation</Typography>
                      <Typography>Get started by adding a location.</Typography>
                      <Typography>
                        Allocate valuation jobs to valuation firms based on
                        their coverage area.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography paddingBottom={"5px"}>
                        No results found.
                      </Typography>
                      <Button
                        id="back-to-search"
                        data-testid="back-to-search"
                        variant="text"
                        onClick={() => {
                          handleInputChange("");
                        }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back to all locations
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            )}
            {isLocationModalOpen && (
              <LocationModal
                allocationId={allocationId}
                allocationVersionId={allocationVersionId}
                location={selectedLocation}
                open={isLocationModalOpen}
                setOpen={setLocationModalOpen}
                newLocation={isNewLocationToAdd}
              />
            )}
          </Grid>
        )}
      </Grid>
      <PanelExDialog
        id="remove-dialog"
        data-testid="remove-dialog"
        open={openRemove}
        title={`Are you sure you want to remove ${selectedLocationName}?`}
        submitText="Yes, Remove"
        submitEnabled={true}
        onClose={() => setOpenRemove(false)}
        onSubmit={handleRemoveLocation}
        showAction={true}
        maxWidth="sm"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "5",
            padding: "32px 24px 32px 24px",
          }}
        >
          <Typography variant="body1">
            Removing this location will remove any associated valuation firms.
            This action cannot be undone.
          </Typography>
        </Box>
      </PanelExDialog>
    </Box>
  );
}
