import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { RootState } from "../../store/staticReducers/reducers";
import { getAssociatedPanels } from "../../store/action/actionFee";
import { DataGridPro, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { Tooltip, Box, Grid2 as Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function FeePanels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    panelsHeader: {
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.15px",
        color: "rgba(0,0,0,0.8)",
      },
    },
    versionId: {
      color: theme.palette.primary.main,
    },
  }));
  const { classes } = useStyles();

  const {
    feeSet,
    panels,
    loading,
    rowCount,
    currentPage,
    pageSize,
    sortBy,
    sortDir,
  } = useSelector((state: RootState) => {
    return {
      feeSet: state.fee.feeSet,
      panels: state.fee.associatedPanel.panels,
      loading: state.fee.associatedPanel.loading,
      rowCount: state.fee.associatedPanel.panelCount || 0,
      currentPage: state.fee.associatedPanel.currentPage || 0,
      pageSize: state.fee.associatedPanel.pageSize || 15,
      sortBy: state.fee.associatedPanel.sortBy || "name",
      sortDir: state.fee.associatedPanel.sortDir || "asc",
    };
  });

  const feeId = feeSet?.feePanelId;

  useEffect(() => {
    fetchAssociatedPanels(feeId, currentPage, pageSize, sortBy, sortDir);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAssociatedPanels = (
    id: number,
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDir: string
  ) => {
    return dispatch(
      getAssociatedPanels(id, currentPage, pageSize, sortBy, sortDir)
    );
  };

  const columns: GridColDef[] = [
    {
      field: "panelSetId",
      headerName: "ID",
      width: 90,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      hideable: false,
      renderCell: (params) => {
        const panelId = params.row.panelSetId;
        return (
          <>
            <Tooltip title="Load to preview Panel" arrow>
              <span
                data-testid={`id-${panelId}`}
                id={`id-${panelId}`}
                className={classes.versionId}
                onClick={() => {
                  history.push(`/panels/${panelId}`);
                }}
              >
                {panelId}
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Panel Name",
      flex: 1,
      editable: false,
      sortable: true,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "processOrder",
      headerName: "Panel Order",
      width: 150,
      editable: false,
      sortable: false,
      align: "center",
    },
    {
      field: "serviceTypes",
      headerName: "Service Types",
      flex: 1.4,
      editable: false,
      sortable: false,
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: sortBy,
      sort: sortDir,
    },
  ]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setPaginationModel({ page: currentPage, pageSize: pageSize });
  }, [currentPage, pageSize]);

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchAssociatedPanels(feeId, newPage, newPageSize, sortBy, sortDir);
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const field =
      newSortModel[0].field === "panelSetId"
        ? "panel_set_id"
        : newSortModel[0].field;
    const sort = newSortModel[0].sort || "asc";
    setSortModel(newSortModel);
    setPaginationModel({ page: 0, pageSize: pageSize });
    fetchAssociatedPanels(feeId, 0, pageSize, field, sort);
  };

  return (
    <>
      <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Grid size={11} className={classes.panelsHeader}>
          <Typography id="tbl-info" data-testid="tbl-info">
            {rowCountState} Associated Panels
          </Typography>
        </Grid>
        <Grid size={1} sx={{ width: 289 }}></Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: 400,
          width: "100%",
        }}
      >
        <DataGridPro
          loading={loading}
          style={{ cursor: "pointer" }}
          className="tblFeePanels"
          getRowId={(row) => row.panelSetId}
          rows={panels}
          rowCount={rowCountState}
          columns={columns}
          disableColumnFilter={true}
          pagination
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[15, 20, 50]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : ""
          }
        />
      </Box>
    </>
  );
}
