import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./app/component/App";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import config from "./config/launchDarklyConfig";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import { persistor, store } from "./app/store/store";

import { LicenseInfo } from "@mui/x-license";

export const render = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.LAUNCH_DARKLY_API_KEY || "",
    flags: config.flagsToFetch.reduce(
      (obj: any, flag: any) => ({ ...obj, [flag]: false }),
      {}
    ),
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY);

  const container = document.getElementById("root");
  const root = createRoot(container!);
  return root.render(
    <Provider store={store}>
      <LDProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <App />
          </Router>
        </PersistGate>
      </LDProvider>
    </Provider>
  );
};
render();
