import { put, call, takeLatest } from "redux-saga/effects";
import {
  fetchOrgSuggestion,
  fetchPropTypeSuggestion,
  fetchServiceTypeSuggestion,
  fetchSearchSuggestion,
  fetchPanelOrgSuggestion,
  fetchActiveValfirmSuggestion,
  fetchActiveOrgSuggestionByTypeAndCountry,
} from "../apis/api";
import { fetchLocationSuggestionByVersionId } from "../apis/apiAllocation";

export const UPDATE_SEARCH_OPTION = "ui/UPDATE_SEARCH_OPTION";
export const SEARCH_TEXT_CHANGE = "ui/SEARCH_TEXT_CHANGE";
export const SEARCH_TEXT_CHANGE_ONLY = "ui/SEARCH_TEXT_CHANGE_ONLY";
export const ATTEMPT_FETCH_SUGGESTION = "ui/ATTEMPT_FETCH_SUGGESTION";
export const SUCCEED_FETCH_SUGGESTION = "ui/SUCCEED_FETCH_SUGGESTION";
export const FAIL_FETCH_SUGGESTION = "ui/FAIL_FETCH_SUGGESTION";
export const CLEAR_SUGGESTION = "ui/CLEAR_FETCH_SUGGESTION";
export const OPEN_SNACKBAR = "ui/OPEN_SNACKBAR";
export const FETCH_OPEN_SNACKBAR = "ui/FETCH_OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "ui/CLOSE_SNACKBAR";
export const FETCH_CLOSE_SNACKBAR = "ui/FETCH_CLOSE_SNACKBAR";
export const OPEN_ACTION_SNACKBAR = "ui/OPEN_ACTION_SNACKBAR";
export const FETCH_OPEN_ACTION_SNACKBAR = "ui/FETCH_OPEN_ACTION_SNACKBAR";
export const CLOSE_ACTION_SNACKBAR = "ui/CLOSE_ACTION_SNACKBAR";
export const FETCH_CLOSE_ACTION_SNACKBAR = "ui/FETCH_CLOSE_ACTION_SNACKBAR";
export const UPDATE_SELECTED_LOCATION = "ui/UPDATE_SELECTED_LOCATION";
export const UPDATE_SELECTED_LOCATION_NEW_ALLOCATION =
  "ui/UPDATE_SELECTED_LOCATION_NEW_ALLOCATION";

export const UPDATE_SELECTED_ALLOCATIONS = "ui/UPDATE_SELECTED_ALLOCATIONS";
export const UPDATE_SELECTED_ALLOCATIONS_NEW_ALLOCATION =
  "ui/UPDATE_SELECTED_ALLOCATIONS_NEW_ALLOCATION";

export const UPDATE_ORG_TEXT_CHANGE = "ui/UPDATE_ORG_TEXT_CHANGE";
export const ATTEMPT_FETCH_ORG_SUGGESTION = "ui/ATTEMPT_FETCH_ORG_SUGGESTION";
export const SUCCEED_FETCH_ORG_SUGGESTION = "ui/SUCCEED_FETCH_ORG_SUGGESTION";
export const FAIL_FETCH_ORG_SUGGESTION = "ui/FAIL_FETCH_ORG_SUGGESTION";
export const CLEAR_ORG_SUGGESTION = "ui/CLEAR_FETCH_ORG_SUGGESTION";

export const RESET_LOCATION_SELECTION = "ui/RESET_LOCATION_SELECTION";

export const UPDATE_NEW_LOCATION_TEXT_CHANGE =
  "ui/UPDATE_NEW_LOCATION_TEXT_CHANGE";
export const ATTEMPT_FETCH_NEW_LOCATION_SUGGESTION =
  "ui/ATTEMPT_FETCH_NEW_LOCATION_SUGGESTION";
export const SUCCEED_FETCH_NEW_LOCATION_SUGGESTION =
  "ui/SUCCEED_FETCH_NEW_LOCATION_SUGGESTION";
export const FAIL_FETCH_NEW_LOCATION_SUGGESTION =
  "ui/FAIL_FETCH_NEW_LOCATION_SUGGESTION";
export const CLEAR_NEW_LOCATION_SUGGESTION = "ui/CLEAR_NEW_LOCATION_SUGGESTION";

export const UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE_NEW_ALLOCATION =
  "ui/UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE_NEW_ALLOCATION";
export const ATTEMPT_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION =
  "ui/ATTEMPT_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION";
export const SUCCEED_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION =
  "ui/SUCCEED_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION";
export const FAIL_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION =
  "ui/FAIL_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION";
export const CLEAR_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION =
  "ui/CLEAR_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION";

export const UPDATE_VALFIRM_ALLOCATION = "ui/UPDATE_VALFIRM_ALLOCATION";
export const DELETE_VALFIRM_ALLOCATION = "ui/DELETE_VALFIRM_ALLOCATION";

export const UPDATE_VALFIRM_ALLOCATION_NEW_ALLOCATION =
  "ui/UPDATE_VALFIRM_ALLOCATION_NEW_ALLOCATION";
export const DELETE_VALFIRM_ALLOCATION_NEW_ALLOCATION =
  "ui/DELETE_VALFIRM_ALLOCATION_NEW_ALLOCATION";

export const UPDATE_SELECTED_FEES = "ui/UPDATE_SELECTED_FEES";
export const UPDATE_SELECTED_FEE_LOCATION = "ui/UPDATE_SELECTED_FEE_LOCATION";
export const UPDATE_FEE_BRACKET_FEE = "ui/UPDATE_FEE_BRACKET_FEE";

export const UPDATE_SELECTED_LOCATION_NEW_FEE =
  "ui/UPDATE_SELECTED_LOCATION_NEW_FEE";
export const UPDATE_SELECTED_FEES_NEW_FEE = "ui/UPDATE_SELECTED_FEES_NEW_FEE";
export const UPDATE_FEE_BRACKET_FEE_NEW_FEE =
  "ui/UPDATE_FEE_BRACKET_FEE_NEW_FEE";

export const UPDATE_PROP_TYPE_TEXT_CHANGE_PANEL_FORM =
  "ui/UPDATE_PROP_TYPE_TEXT_CHANGE_PANEL_FORM";
export const ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM =
  "ui/ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM";
export const SUCCEED_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM =
  "ui/SUCCEED_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM";
export const FAIL_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM =
  "ui/FAIL_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM";
export const CLEAR_PROP_TYPE_SUGGESTION_PANEL_FORM =
  "ui/CLEAR_PROP_TYPE_SUGGESTION_PANEL_FORM";

export const UPDATE_SERVICE_TYPE_TEXT_CHANGE_PANEL_FORM =
  "ui/UPDATE_SERVICE_TYPE_TEXT_CHANGE_PANEL_FORM";
export const ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM =
  "ui/ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM";
export const SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM =
  "ui/SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM";
export const FAIL_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM =
  "ui/FAIL_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM";
export const CLEAR_SERVICE_TYPE_SUGGESTION_PANEL_FORM =
  "ui/CLEAR_SERVICE_TYPE_SUGGESTION_PANEL_FORM";

export const OPEN_FAIL_ACTIVATION_CANCEL = "ui/OPEN_FAIL_ACTIVATION_CANCEL";

export const UPDATE_FUNDER_TEXT_CHANGE_PANEL_FORM =
  "ui/UPDATE_FUNDER_TEXT_CHANGE_PANEL_FORM";
export const UPDATE_CLIENT_TEXT_CHANGE_PANEL_FORM =
  "ui/UPDATE_CLIENT_TEXT_CHANGE_PANEL_FORM";

export const ATTEMPT_FETCH_FUNDER_SUGGESTION_PANEL_FORM =
  "ui/ATTEMPT_FETCH_FUNDER_SUGGESTION_PANEL_FORM";
export const SUCCEED_FETCH_FUNDER_SUGGESTION_PANEL_FORM =
  "ui/SUCCEED_FETCH_FUNDER_SUGGESTION_PANEL_FORM";
export const FAIL_FETCH_FUNDER_SUGGESTION_PANEL_FORM =
  "ui/FAIL_FETCH_FUNDER_SUGGESTION_PANEL_FORM";
export const CLEAR_FUNDER_SUGGESTION_PANEL_FORM =
  "ui/CLEAR_FETCH_FUNDER_SUGGESTION_PANEL_FORM";

export const ATTEMPT_FETCH_CLIENT_SUGGESTION_PANEL_FORM =
  "ui/ATTEMPT_FETCH_CLIENT_SUGGESTION_PANEL_FORM";
export const SUCCEED_FETCH_CLIENT_SUGGESTION_PANEL_FORM =
  "ui/SUCCEED_FETCH_CLIENT_SUGGESTION_PANEL_FORM";
export const FAIL_FETCH_CLIENT_SUGGESTION_PANEL_FORM =
  "ui/FAIL_FETCH_CLIENT_SUGGESTION_PANEL_FORM";
export const CLEAR_CLIENT_SUGGESTION_PANEL_FORM =
  "ui/CLEAR_FETCH_CLIENT_SUGGESTION_PANEL_FORM";

export const UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE =
  "ui/UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE";

export const UPDATE_ORG_TEXT_CHANGE_WITH_TYPE_COUNTRY =
  "ui/UPDATE_ORG_TEXT_CHANGE_WITH_TYPE_COUNTRY";

export function* searchTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchSearchSuggestion,
        data.text,
        data.option
      );

      yield put({
        type: SUCCEED_FETCH_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_SUGGESTION,
    });
  }
}
export function* searchTextChangeOnly(data) {
  yield put({
    type: ATTEMPT_FETCH_SUGGESTION,
    text: data.text,
  });
}
export function* openSnackbar(data) {
  yield put({
    type: FETCH_OPEN_SNACKBAR,
    payload: {
      message: data.payload.message,
      variant: data.payload.variant,
      isIcon: data.payload.isIcon,
      showLoading: data.payload.showLoading,
      duration: data.payload.duration,
      actionType: data.payload.actionType,
      id: data.payload.id,
    },
  });
}
export function* closeSnackbar() {
  yield put({
    type: FETCH_CLOSE_SNACKBAR,
  });
}

export function* openActionSnackbar(data) {
  yield put({
    type: FETCH_OPEN_ACTION_SNACKBAR,
    payload: {
      message: data.payload.message,
      variant: data.payload.variant,
      actionType: data.payload.actionType,
      width: data.payload.width,
    },
  });
}
export function* closeActionSnackbar() {
  yield put({
    type: FETCH_CLOSE_ACTION_SNACKBAR,
  });
}
export function* updateOrgTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_ORG_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(fetchOrgSuggestion, data.text);

      yield put({
        type: SUCCEED_FETCH_ORG_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_ORG_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ORG_SUGGESTION,
    });
  }
}

export function* updateNewLocationTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_NEW_LOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestionByVersionId,
        data.text,
        data.option,
        data.allocationId,
        data.allocRevId,
        data.isExisting
      );

      yield put({
        type: SUCCEED_FETCH_NEW_LOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_NEW_LOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_NEW_LOCATION_SUGGESTION,
    });
  }
}

export function* updateAcitveValfirmTextChangeNewAllocation(data) {
  yield put({
    type: ATTEMPT_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
    text: data.payload.text,
  });

  try {
    if (data.payload.text.length > 2) {
      const { data: list } = yield call(
        fetchActiveOrgSuggestionByTypeAndCountry,
        data.payload.orgType,
        data.payload.countryCode,
        data.payload.text
      );

      yield put({
        type: SUCCEED_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ACTIVE_VALFIRM_SUGGESTION_NEW_ALLOCATION,
    });
  }
}

export function* updatePropTypeTextChangePanelForm(data) {
  yield put({
    type: ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(fetchPropTypeSuggestion, data.text);

      yield put({
        type: SUCCEED_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_PROP_TYPE_SUGGESTION_PANEL_FORM,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_PROP_TYPE_SUGGESTION_PANEL_FORM,
    });
  }
}

export function* updateServiceTypeTextChangePanelForm(data) {
  yield put({
    type: ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(fetchServiceTypeSuggestion, data.text);

      yield put({
        type: SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_SERVICE_TYPE_SUGGESTION_PANEL_FORM,
    });
  }
}

export function* updateFunderTextChangePanelForm(data) {
  yield put({
    type: ATTEMPT_FETCH_FUNDER_SUGGESTION_PANEL_FORM,
    text: data.payload.text,
  });

  try {
    if (data.payload.text.length > 2) {
      const { data: list } = yield call(
        fetchPanelOrgSuggestion,
        data.payload.text,
        data.payload.orgTypeOption
      );

      yield put({
        type: SUCCEED_FETCH_FUNDER_SUGGESTION_PANEL_FORM,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FUNDER_SUGGESTION_PANEL_FORM,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FUNDER_SUGGESTION_PANEL_FORM,
    });
  }
}

export function* updateClientTextChangePanelForm(data) {
  yield put({
    type: ATTEMPT_FETCH_CLIENT_SUGGESTION_PANEL_FORM,
    text: data.payload.text,
  });

  try {
    if (data.payload.text.length > 2) {
      const { data: list } = yield call(
        fetchPanelOrgSuggestion,
        data.payload.text,
        data.payload.orgTypeOption
      );

      yield put({
        type: SUCCEED_FETCH_CLIENT_SUGGESTION_PANEL_FORM,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_CLIENT_SUGGESTION_PANEL_FORM,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_CLIENT_SUGGESTION_PANEL_FORM,
    });
  }
}

export function* updateActiveValfirmTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_ORG_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchActiveValfirmSuggestion,
        data.text
      );

      yield put({
        type: SUCCEED_FETCH_ORG_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_ORG_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ORG_SUGGESTION,
    });
  }
}

export function* updateOrgTextChangeByTypeAndCountry(data) {
  yield put({
    type: ATTEMPT_FETCH_ORG_SUGGESTION,
    text: data.payload.text,
  });

  try {
    if (data.payload.text.length > 2) {
      const { data: list } = yield call(
        fetchActiveOrgSuggestionByTypeAndCountry,
        data.payload.orgType,
        data.payload.countryCode,
        data.payload.text
      );

      yield put({
        type: SUCCEED_FETCH_ORG_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_ORG_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ORG_SUGGESTION,
    });
  }
}

export default function* sagaUI() {
  yield takeLatest(SEARCH_TEXT_CHANGE, searchTextChange);
  yield takeLatest(SEARCH_TEXT_CHANGE_ONLY, searchTextChangeOnly);
  yield takeLatest(OPEN_SNACKBAR, openSnackbar);
  yield takeLatest(CLOSE_SNACKBAR, closeSnackbar);
  yield takeLatest(OPEN_ACTION_SNACKBAR, openActionSnackbar);
  yield takeLatest(CLOSE_ACTION_SNACKBAR, closeActionSnackbar);
  yield takeLatest(UPDATE_ORG_TEXT_CHANGE, updateOrgTextChange);
  yield takeLatest(
    UPDATE_NEW_LOCATION_TEXT_CHANGE,
    updateNewLocationTextChange
  );
  yield takeLatest(
    UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE_NEW_ALLOCATION,
    updateAcitveValfirmTextChangeNewAllocation
  );
  yield takeLatest(
    UPDATE_PROP_TYPE_TEXT_CHANGE_PANEL_FORM,
    updatePropTypeTextChangePanelForm
  );
  yield takeLatest(
    UPDATE_SERVICE_TYPE_TEXT_CHANGE_PANEL_FORM,
    updateServiceTypeTextChangePanelForm
  );
  yield takeLatest(
    UPDATE_FUNDER_TEXT_CHANGE_PANEL_FORM,
    updateFunderTextChangePanelForm
  );
  yield takeLatest(
    UPDATE_CLIENT_TEXT_CHANGE_PANEL_FORM,
    updateClientTextChangePanelForm
  );
  yield takeLatest(
    UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE,
    updateActiveValfirmTextChange
  );
  yield takeLatest(
    UPDATE_ORG_TEXT_CHANGE_WITH_TYPE_COUNTRY,
    updateOrgTextChangeByTypeAndCountry
  );
}
