import React, { useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  setBulkUpdateChangeType,
  getExistingValfirmsByRevIds,
  setDistributionMethod,
  setIsIncludeBackup,
} from "../../../store/action/actionPanel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReplaceValfirm from "./ReplaceValfirm";
import AddValfirm from "./AddValfirm";
import RemoveValfirm from "./RemoveValfirm";
import ChangeAllocation from "./ChangeAllocation";

const bulkUpdateChangeTypes = [
  {
    label: "Change Allocation %",
    value: "change-allocation",
  },
  {
    label: "Add Valuation Firm",
    value: "add-valfirm",
  },
  {
    label: "Remove Valuation Firm",
    value: "remove-valfirm",
  },
  {
    label: "Replace Valuation Firm",
    value: "replace-valfirm",
  },
];

const distributionMethods = [
  {
    label: "Proportional to Existing",
    value: "proportional",
  },
  {
    label: "Evenly Across All Firms",
    value: "evenly-all",
  },
  {
    label: "Evenly Across Remaining Firms",
    value: "evenly-remaining",
  },
  /*
  {
    label: "Take from Specific Firm",
    value: "specific-firm",
  },
  */
];

export default function MakeChanges() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: "24px",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    stack: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    radioGroup: {
      fontSize: "18px",
      fontWeight: "500",
      paddingTop: "18px",
      paddingLeft: "9px",
      "& .MuiFormControlLabel-root": {
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    },
    formGroup: {
      fontSize: "18px",
      fontWeight: "500",
      paddingTop: "12px",
    },
    formRow: {
      paddingTop: "22px",
      "& .MuiTypography-root": {
        paddingBottom: "12px",
      },
    },
    infoIcon: {
      width: "20px",
      height: "20px",
      color: "#0000008A",
    },
    tooltip: {
      minWidth: "427px",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      boxShadow:
        "0px 1px 10px 0px #0000001F, 0px 4px 5px 0px #00000024, 0px 2px 4px -1px #00000033",
      "& .MuiTooltip-popper": {
        width: "500px",
      },
      "& .MuiTooltip-arrow": {
        color: theme.palette.common.white,
      },
    },
  }));
  const { classes } = useStyles();

  const { allocations, changeType, isIncludeBackup, distributionMethod } =
    useSelector((state: RootState) => {
      return {
        allocations: state.panel.bulkUpdate.allocations || [],
        changeType: state.panel.bulkUpdate.changeType || "",
        isIncludeBackup: state.panel.bulkUpdate.isIncludeBackup,
        distributionMethod: state.panel.bulkUpdate.distributionMethod,
      };
    });

  useEffect(() => {
    const allocRevIdsToBulkUpdate = allocations.map((allocation: any) => {
      const { allocActiveRevId, allocDraftRevId, selectedVersion } = allocation;
      const version = selectedVersion || (allocDraftRevId ? "draft" : "active");
      return version === "draft" ? allocDraftRevId : allocActiveRevId;
    });

    if (allocRevIdsToBulkUpdate.length > 0) {
      dispatch(getExistingValfirmsByRevIds(allocRevIdsToBulkUpdate));
    }
  }, [dispatch, allocations]);

  const handleChangeTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setBulkUpdateChangeType((event.target as HTMLInputElement).value));
  };

  const handleDistributionMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setDistributionMethod((event.target as HTMLInputElement).value));
  };

  const renderDescription = (method: string) => {
    if (method === "proportional") {
      return (
        <Stack direction={"column"}>
          <Typography variant={"body2Strong"}>
            Handled to preserve existing proportions
          </Typography>
          <Typography variant={"body2"}>
            Add/Remove from existing firms, to give to the target,
            <br />
            preserving their proportion of allocation.
            <br />
            ie. target firm set to 10% on existing 80% & 20%;
            <br />
            results: target 10%, and existing 75% & 15%
          </Typography>
          <Typography variant={"body2Strong"}>
            (5% equally to keep existing proportion)
          </Typography>
        </Stack>
      );
    } else if (method === "evenly-remaining") {
      return (
        <Stack direction={"column"}>
          <Typography variant={"body2Strong"}>
            Total evenly distributed across remaining valuation firms
          </Typography>
          <Typography variant={"body2"}>
            if target is set, reallocate remainder evenly across others.
            <br />
            ie. target firm set to 10% on existing 80% & 20%;
            <br />
            results: target 10%, others 45% & 45%
          </Typography>
          <Typography variant={"body2Strong"}>
            (90% total evenly distributed across remaining)
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Stack direction={"column"}>
          <Typography variant={"body2Strong"}>
            Total evenly distributed across all valuation firms
          </Typography>
          <Typography variant={"body2"}>
            Allocate evenly across all valuation firms.
            <br />
            ie. target firm on existing 80% & 20%;
            <br />
            results: target 10%, and existing 70% & 20%
          </Typography>
          <Typography variant={"body2Strong"}>
            (100% total evenly distributed across all)
          </Typography>
        </Stack>
      );
    } /*  else if (method === "specific-firm") {
      return (
        <Stack direction={"column"}>
          <Typography variant={"body2Strong"}>
            Take from a valuation firm to give to target firm.
          </Typography>
          <Typography variant={"body2"}>
            ie. target valuation firm set to 10% <br />
            on existing 80% & 20%;
            <br />
            results: target 10%, and existing 70% & 20%
          </Typography>
          <Typography variant={"body2Strong"}>
            (10% removed from specific valuation firm)
          </Typography>
        </Stack>
      );
    }*/
  };

  const renderChangeTypeDetails = () => {
    if (changeType === "replace-valfirm") {
      return <ReplaceValfirm />;
    } else if (changeType === "add-valfirm") {
      return <AddValfirm />;
    } else if (changeType === "remove-valfirm") {
      return <RemoveValfirm />;
    } else {
      return <ChangeAllocation />;
    }
  };

  return (
    <Grid
      container
      size={12}
      id="make-changes"
      data-testid="make-changes"
      sx={{ width: "100%", maxWidth: "1080px", padding: "0px 10px 0px 10px" }}
    >
      <Grid size={12} paddingBottom={"24px"}>
        <Typography
          id="title-make-changes"
          data-testid="title-make-changes"
          variant="h1"
        >
          Make Changes
        </Typography>
      </Grid>

      <Grid container size={12} className={classes.cardContainer}>
        <Grid
          size={12}
          id="change-type"
          data-testid="change-type"
          className={classes.card}
        >
          <Typography
            id="change-type-title"
            data-testid="change-type-title"
            variant="h4"
          >
            Change Type
          </Typography>

          <RadioGroup
            row={false}
            aria-labelledby="change-type-group-label"
            name="change-type-group"
            className={classes.radioGroup}
            onChange={handleChangeTypeChange}
          >
            {bulkUpdateChangeTypes.map((option) => (
              <FormControlLabel
                key={`change-type-key-${option.value}`}
                id={`change-type-${option.value}`}
                data-testid={`change-type-${option.value}`}
                label={<Typography variant="h6">{option.label}</Typography>}
                value={option.value}
                control={<Radio size="medium" />}
                checked={changeType === option.value}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>

      {changeType !== "" && changeType !== "replace-valfirm" && (
        <Grid container size={12} className={classes.cardContainer}>
          <Grid
            size={12}
            id="distribution-method"
            data-testid="distribution-method"
            className={classes.card}
          >
            <Typography
              id="distribution-method-title"
              data-testid="distribution-method-title"
              variant="h4"
            >
              Distribution Method
            </Typography>

            <RadioGroup
              key="distribution-method-group"
              row={false}
              aria-labelledby="distribution-method-group-label"
              name="distribution-method-group"
              className={classes.radioGroup}
              onChange={handleDistributionMethodChange}
            >
              {distributionMethods.map((option: any) => {
                if (
                  option.value !== "evenly-all" ||
                  (option.value === "evenly-all" &&
                    changeType === "add-valfirm")
                ) {
                  return (
                    <Stack
                      key={`distribution-method-group-${option.value}`}
                      direction="row"
                      className={classes.stack}
                    >
                      <FormControlLabel
                        id={`distribution-method-${option.value}`}
                        data-testid={`distribution-method-${option.value}`}
                        label={
                          <Typography variant="h6">{option.label}</Typography>
                        }
                        value={option.value}
                        control={<Radio size="medium" />}
                        checked={distributionMethod === option.value}
                      />
                      <Tooltip
                        id={`distribution-method-tooltip-${option.value}`}
                        data-testid={`distribution-method-tooltip-${option.value}`}
                        title={renderDescription(option.value)}
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                        placement="right"
                        arrow
                      >
                        <InfoOutlinedIcon
                          id={`distribution-method-tooltip-icon-${option.value}`}
                          data-testid={`distribution-method-tooltip-icon-${option.value}`}
                          className={classes.infoIcon}
                          sx={{ color: theme.colors.grey.light }}
                        />
                      </Tooltip>
                    </Stack>
                  );
                }
              })}
            </RadioGroup>
            <Grid container size={12} sx={{ backgroundColor: "#F7F7FA" }}>
              <Stack spacing={1} direction="row" className={classes.stack}>
                <FormControlLabel
                  id="check-include-backup"
                  data-testid="check-include-backup"
                  label={
                    <Typography variant="h6">
                      include backup valuation firms (set to 0%)
                    </Typography>
                  }
                  control={
                    <Checkbox
                      size="small"
                      disabled={distributionMethod === ""}
                      onChange={() =>
                        dispatch(setIsIncludeBackup(!isIncludeBackup))
                      }
                    />
                  }
                  checked={isIncludeBackup}
                />
                <Tooltip
                  id="include-backup-tooltip"
                  data-testid="include-backup-tooltip"
                  title={
                    <Typography variant="body2">
                      If your allocations include valuation firms set at 0%,
                      this configuration can either include or exclude them when
                      reweighting allocation percentages due to the changes
                      being made.
                    </Typography>
                  }
                  placement="right"
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <InfoOutlinedIcon
                    id="include-backup-tooltip-icon"
                    data-testid="include-backup-tooltip-icon"
                    className={classes.infoIcon}
                    sx={{ backgroundColor: "#F7F7FA" }}
                  />
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container size={12} className={classes.cardContainer}>
        {(distributionMethod !== "" || changeType === "replace-valfirm") &&
          renderChangeTypeDetails()}
      </Grid>
    </Grid>
  );
}
