import { getIn } from "./objectUtils";
import axios from "axios";
import { FAIL_AUTHENTICATION } from "../store/staticSagas/sagaToken";

let storeInstance;

function addBearerToConfig(currentConfig) {
  const token = storeInstance.getState().tokenDetails.claudToken.access_token;
  const userId =
    storeInstance.getState().tokenDetails.userContactDetails.userId;
  return {
    ...currentConfig,
    headers: {
      ...currentConfig.headers,
      Authorization: `Bearer ${token}`,
      userId: userId,
    },
  };
}

const axiosInstance = axios.create({
  baseURL: "/",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

async function initStoreInstance() {
  if (!storeInstance) {
    const { store } = await import("../store/store");
    storeInstance = store;
  }
}

axiosInstance.interceptors.request.use(async (config) => {
  await initStoreInstance();
  if (!/^https?:/.test(config.url)) {
    return addBearerToConfig(config);
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (r) => r,
  async (e) => {
    await initStoreInstance();
    const payload = getIn(e, "response.data.message") || "";
    if (
      getIn(e, "response.status") === 401 ||
      (getIn(e, "response.status") === 500 &&
        payload.startsWith("ID not found"))
    ) {
      storeInstance.dispatch({
        type: FAIL_AUTHENTICATION,
        payload,
      });
    }
    throw e;
  }
);

export default axiosInstance;
