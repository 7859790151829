import { BASE_URL } from "./claudConfig";
export function getLaunchDarklyAPIKey() {
  if (
    BASE_URL.includes("panel-ui-dev.apps.ad.corelogic.asia") ||
    BASE_URL.includes("localhost")
  ) {
    return "641b7eb39c7557131165ea65";
  } else if (BASE_URL.includes("panel-uat.corelogic.com.au")) {
    return "641b7e97f1ea35134c5df201";
  } else if (BASE_URL.includes("panel.corelogic.com.au")) {
    return "641b7e97f1ea35134c5df202";
  }
}
export default {
  LAUNCH_DARKLY_API_KEY: getLaunchDarklyAPIKey(),
  flagsToFetch: ["bulk-update"],
};
