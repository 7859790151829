import React from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import PanelExAutocomplete from "../../shared/PanelExAutocomplete";
import { escapedSpecialCharacters } from "../../../utilities/stringUtil";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/staticReducers/reducers";
import {
  removeValfirmSetValfirm,
  removeValfirmTextChange,
} from "../../../store/action/actionPanel";
import ValfirmAdditionalStatusIcon from "../../shared/ValfirmAdditionalStatusIcon";

export default function RemoveValfirm() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    card: {
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      justifyContent: "space-between",
      alignItems: "flex-start",

      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 2px 2px 0px rgba(17, 11, 28, 0.08)",
    },
    formGroup: {
      fontSize: "18px",
      fontWeight: "500",
      paddingTop: "12px",
    },
    formRow: {
      paddingTop: "22px",
      "& .MuiTypography-root": {
        paddingBottom: "12px",
      },
    },
    notTrading: {
      color: theme.palette.text.disabled,
    },
    atCapacity: {
      color: theme.colors.error.darker,
    },
  }));
  const { classes } = useStyles();

  const { removeValfirm } = useSelector((state: RootState) => {
    return {
      removeValfirm: state.panel.bulkUpdate.changeTypeRemoveValfirm,
    };
  });

  const handleRemoveValfirmInputChange = (input: string) => {
    if (input !== removeValfirm.valfirmText) {
      dispatch(removeValfirmTextChange(input));
    }
  };

  const handleUpdateRemoveValfirm = (orgId: number, orgName: string) => {
    dispatch(removeValfirmSetValfirm(orgId, orgName));
  };

  const getStatusStyle = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "NOTTRADING":
        return classes.notTrading;
      case "ATCAPACITY":
        return classes.atCapacity;
    }
  };

  return (
    <Grid
      container
      size={12}
      data-testid="remove-valfirm-form"
      className={classes.card}
    >
      <Typography
        id="remove-valfirm-form-title"
        data-testid="remove-valfirm-form-title"
        variant="h4"
      >
        Remove Valuation Firm
      </Typography>

      <Grid container size={12} className={classes.formGroup}>
        <Grid size={12} className={classes.formRow}>
          <Typography
            id="remove-valfirm-label"
            data-testid="remove-valfirm-label"
            variant="h4"
          >
            Valuation Firm
          </Typography>
          <PanelExAutocomplete
            id="remove-valfirm"
            data-testid="remove-valfirm"
            style={{ width: "100%" }}
            options={removeValfirm.valfirmSuggestion}
            value={removeValfirm.valfirmText}
            popperMaxHeight={400}
            getOptionLabel={(option: any) =>
              option.name || removeValfirm.valfirmText || ""
            }
            placeholder="Enter Valuation Firm"
            onInputChange={(value: any) => {
              handleRemoveValfirmInputChange(value);
            }}
            renderOption={(props: any, option: any) => {
              const { id, name, additionalStatus } = option;
              const regex = new RegExp(
                escapedSpecialCharacters(removeValfirm.valfirmText),
                "gi"
              );
              const response = name.replace(regex, function (str: any) {
                return "<span style='font-weight:500'>" + str + "</span>";
              });
              return (
                <li
                  {...props}
                  data-testid={`remove-valfirm-label-${id}`}
                  id={`remove-valfirm-label-${id}`}
                  onClick={(ev) => {
                    handleUpdateRemoveValfirm(id, name);
                    ev.stopPropagation();
                  }}
                >
                  <ValfirmAdditionalStatusIcon
                    additionalStatus={additionalStatus}
                  />
                  <div
                    className={getStatusStyle(additionalStatus)}
                    dangerouslySetInnerHTML={{ __html: `${response}` }}
                  ></div>
                </li>
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
