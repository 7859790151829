import PanelsIcon from "@mui/icons-material/LanOutlined";
import AllocationsIcon from "@mui/icons-material/ReplyAllOutlined";
import FeesIcon from "@mui/icons-material/RequestQuoteOutlined";

export interface Menu {
  title: string;
  value: string;
  icon: any;
  rotate: number;
  url: string;
  breadcrumb: string;
}

interface MenuMap {
  [key: string]: Menu;
}

export const menus: MenuMap = {
  panels: {
    title: "Panels",
    value: "panels",
    icon: PanelsIcon,
    rotate: 0,
    url: "/panels",
    breadcrumb: "PANEL RESULTS",
  },
  allocations: {
    title: "Allocations",
    value: "allocations",
    icon: AllocationsIcon,
    rotate: -90,
    url: "/allocations",
    breadcrumb: "ALLOCATION RESULTS",
  },
  fees: {
    title: "Fees",
    value: "fees",
    icon: FeesIcon,
    rotate: 0,
    url: "/fees",
    breadcrumb: "FEE RESULTS",
  },
};
