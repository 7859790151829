import {
  UPDATE_SEARCH_OPTION,
  SEARCH_TEXT_CHANGE,
  SEARCH_TEXT_CHANGE_ONLY,
  CLOSE_SNACKBAR,
  OPEN_SNACKBAR,
  CLOSE_ACTION_SNACKBAR,
  UPDATE_ORG_TEXT_CHANGE,
  UPDATE_SELECTED_LOCATION,
  UPDATE_VALFIRM_ALLOCATION,
  DELETE_VALFIRM_ALLOCATION,
  UPDATE_SELECTED_ALLOCATIONS,
  UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE_NEW_ALLOCATION,
  UPDATE_SELECTED_LOCATION_NEW_ALLOCATION,
  UPDATE_VALFIRM_ALLOCATION_NEW_ALLOCATION,
  DELETE_VALFIRM_ALLOCATION_NEW_ALLOCATION,
  UPDATE_SELECTED_ALLOCATIONS_NEW_ALLOCATION,
  UPDATE_SELECTED_FEES,
  UPDATE_SELECTED_FEE_LOCATION,
  UPDATE_FEE_BRACKET_FEE,
  UPDATE_FEE_BRACKET_FEE_NEW_FEE,
  UPDATE_SELECTED_LOCATION_NEW_FEE,
  UPDATE_SELECTED_FEES_NEW_FEE,
  OPEN_FAIL_ACTIVATION_CANCEL,
  UPDATE_PROP_TYPE_TEXT_CHANGE_PANEL_FORM,
  UPDATE_SERVICE_TYPE_TEXT_CHANGE_PANEL_FORM,
  UPDATE_CLIENT_TEXT_CHANGE_PANEL_FORM,
  UPDATE_FUNDER_TEXT_CHANGE_PANEL_FORM,
  UPDATE_ORG_TEXT_CHANGE_WITH_TYPE_COUNTRY,
  UPDATE_NEW_LOCATION_TEXT_CHANGE,
  RESET_LOCATION_SELECTION,
} from "../staticSagas/sagaUI";

export function updateSearchOption(option) {
  return { type: UPDATE_SEARCH_OPTION, option };
}

export function searchTextChange(text, option) {
  return { type: SEARCH_TEXT_CHANGE, text, option };
}

export function searchTextChangeOnly(text) {
  return { type: SEARCH_TEXT_CHANGE_ONLY, text };
}

export function openSnackbar(
  message,
  variant,
  isIcon,
  showLoading,
  duration,
  actionType
) {
  return {
    type: OPEN_SNACKBAR,
    payload: {
      message,
      variant,
      isIcon,
      showLoading,
      duration,
      actionType,
    },
  };
}

export function closeSnackbar() {
  return { type: CLOSE_SNACKBAR };
}

export function closeActionSnackbar() {
  return { type: CLOSE_ACTION_SNACKBAR };
}

export function updateOrgTextChange(text) {
  return {
    type: UPDATE_ORG_TEXT_CHANGE,
    text,
  };
}

export function updateNewLocationTextChange(
  text,
  option,
  allocationId,
  allocRevId,
  isExisting
) {
  return {
    type: UPDATE_NEW_LOCATION_TEXT_CHANGE,
    text,
    option,
    allocationId,
    allocRevId,
    isExisting,
  };
}
export function clearLocationSelection() {
  return {
    type: RESET_LOCATION_SELECTION,
  };
}

export function updateSelectedLocation(
  id,
  type,
  name,
  isExisting,
  countryId,
  countryName,
  allocationPanelFilterId,
  allocationVersionId
) {
  return {
    type: UPDATE_SELECTED_LOCATION,
    payload: {
      id,
      type,
      name,
      isExisting,
      countryId,
      countryName,
      allocationPanelFilterId,
    },
    meta: {
      analytics: {
        versionId: allocationVersionId,
        location: [countryName, type === "Country" ? null : type + " " + name]
          .filter(Boolean)
          .join(" "),
        action: isExisting ? "Update" : "Add",
      },
    },
  };
}

export function updateSelectedAllocations(allocations) {
  return {
    type: UPDATE_SELECTED_ALLOCATIONS,
    payload: allocations,
  };
}

export function updateValfirm(
  allocationPanelAllocationsId,
  valfirmId,
  valfirmName,
  value,
  locationName,
  isAdded,
  allocationVersionId
) {
  return {
    type: UPDATE_VALFIRM_ALLOCATION,
    payload: {
      allocationPanelAllocationsId,
      valfirmId,
      valfirmName,
      value,
    },
    meta: {
      analytics: {
        location: locationName,
        valfirm: valfirmName,
        allocation: value,
        action: isAdded ? "Add" : "Update",
        versionId: allocationVersionId,
      },
    },
  };
}

export function deleteValfirm(
  allocationPanelAllocationsId,
  valfirmId,
  valfirmName,
  locationName,
  allocationVersionId
) {
  return {
    type: DELETE_VALFIRM_ALLOCATION,
    payload: {
      allocationPanelAllocationsId,
      valfirmId,
    },
    meta: {
      analytics: {
        versionId: allocationVersionId,
        location: locationName,
        valfirm: valfirmName,
      },
    },
  };
}

export function updateActiveValfirmTextChangeNewAllocation(countryCode, text) {
  return {
    type: UPDATE_ACTIVE_VALFIRM_TEXT_CHANGE_NEW_ALLOCATION,
    payload: {
      orgType: "Valfirm",
      countryCode,
      text,
    },
  };
}

export function updateSelectedLocationNewAllocation(
  id,
  type,
  name,
  isExisting,
  countryId,
  countryName,
  locationId
) {
  return {
    type: UPDATE_SELECTED_LOCATION_NEW_ALLOCATION,
    payload: {
      id,
      type,
      name,
      isExisting,
      countryId,
      countryName,
      locationId,
    },
  };
}

export function updateSelectedAllocationsNewAllocation(allocations) {
  return {
    type: UPDATE_SELECTED_ALLOCATIONS_NEW_ALLOCATION,
    payload: allocations,
  };
}

export function updateValfirmNewAllocation(
  allocationPanelAllocationsId,
  valfirmId,
  valfirmName,
  value
) {
  return {
    type: UPDATE_VALFIRM_ALLOCATION_NEW_ALLOCATION,
    payload: {
      allocationPanelAllocationsId,
      valfirmId,
      valfirmName,
      value,
    },
  };
}
export function deleteValfirmNewAllocation(
  allocationPanelAllocationsId,
  valfirmId
) {
  return {
    type: DELETE_VALFIRM_ALLOCATION_NEW_ALLOCATION,
    payload: {
      allocationPanelAllocationsId,
      valfirmId,
    },
  };
}

/*************** FEES **********************/
export function updateSelectedFees(fees) {
  return {
    type: UPDATE_SELECTED_FEES,
    payload: fees,
  };
}

export function updateSelectedFeeLocation(
  id,
  type,
  name,
  isExisting,
  countryId,
  countryName,
  feePanelFilterId,
  feeVersionId
) {
  return {
    type: UPDATE_SELECTED_FEE_LOCATION,
    payload: {
      id,
      type,
      name,
      isExisting,
      countryId,
      countryName,
      feePanelFilterId,
    },
    meta: {
      analytics: {
        versionId: feeVersionId,
        location: [countryName, type === "Country" ? null : type + " " + name]
          .filter(Boolean)
          .join(" "),
        action: isExisting ? "Update" : "Add",
      },
    },
  };
}

export function updateFeeBrackets(
  feePanelFeesId,
  feePanelBracketId,
  lower,
  upper,
  fee,
  locationName,
  feeVersionId
) {
  return {
    type: UPDATE_FEE_BRACKET_FEE,
    payload: {
      feePanelFeesId,
      feePanelBracketId,
      lower,
      upper,
      fee,
    },
    meta: {
      analytics: {
        location: locationName,
        feeBracket: lower + "-" + upper,
        fee,
        action: feePanelFeesId ? "Update" : "Add",
        versionId: feeVersionId,
      },
    },
  };
}

export function updateSelectedLocationNewFee(
  id,
  type,
  name,
  isExisting,
  countryId,
  countryName,
  locationId
) {
  return {
    type: UPDATE_SELECTED_LOCATION_NEW_FEE,
    payload: {
      id,
      type,
      name,
      isExisting,
      countryId,
      countryName,
      locationId,
    },
  };
}

export function updateSelectedFeesNewFee(fees) {
  return {
    type: UPDATE_SELECTED_FEES_NEW_FEE,
    payload: fees,
  };
}

export function updateFeeBracketsFeeNewFee(lower, upper, fee) {
  return {
    type: UPDATE_FEE_BRACKET_FEE_NEW_FEE,
    payload: {
      lower,
      upper,
      fee,
    },
  };
}

export function updatePropTypeTextChangePanelForm(text) {
  return {
    type: UPDATE_PROP_TYPE_TEXT_CHANGE_PANEL_FORM,
    text,
  };
}

export function updateServiceTypeTextChangePanelForm(text) {
  return {
    type: UPDATE_SERVICE_TYPE_TEXT_CHANGE_PANEL_FORM,
    text,
  };
}

export function openFailActivationCancel(openFailCancel) {
  return {
    type: OPEN_FAIL_ACTIVATION_CANCEL,
    payload: {
      openFailCancel,
    },
  };
}

export function updateFunderTextChangePanelForm(text, orgTypeOption) {
  return {
    type: UPDATE_FUNDER_TEXT_CHANGE_PANEL_FORM,
    payload: {
      orgTypeOption,
      text,
    },
  };
}

export function updateClientTextChangePanelForm(text, orgTypeOption) {
  return {
    type: UPDATE_CLIENT_TEXT_CHANGE_PANEL_FORM,
    payload: {
      orgTypeOption,
      text,
    },
  };
}

export function updateActiveValfirmTextChange(countryCode, text) {
  return {
    type: UPDATE_ORG_TEXT_CHANGE_WITH_TYPE_COUNTRY,
    payload: {
      orgType: "Valfirm",
      countryCode,
      text,
    },
  };
}

export function updateOrgTextChangeWithTypeAndCountry(
  orgType,
  countryCode,
  text
) {
  return {
    type: UPDATE_ORG_TEXT_CHANGE_WITH_TYPE_COUNTRY,
    payload: {
      orgType,
      countryCode,
      text,
    },
  };
}
