import {
  ATTEMPT_FETCH_TOKEN_ASYNC,
  ATTEMPT_REFRESH_TOKEN_ASYNC,
  CLOSE_FAIL_AUTHENTICATION,
  EXPIRE_SESSION_ASYNC,
  SET_TIMER_FOR_EXPIRY_MODAL_ASYNC,
} from "../staticSagas/sagaToken";

export function fetchToken(requestBody) {
  return { type: ATTEMPT_FETCH_TOKEN_ASYNC, requestBody };
}

export function refreshToken(token, removeListener) {
  return { type: ATTEMPT_REFRESH_TOKEN_ASYNC, token, removeListener };
}

export function expireSession(username) {
  return { type: EXPIRE_SESSION_ASYNC, username };
}

export function setTimerForExpiryModal(delayTime) {
  return { type: SET_TIMER_FOR_EXPIRY_MODAL_ASYNC, delayTime };
}

export function closeFailAuthentication() {
  return { type: CLOSE_FAIL_AUTHENTICATION };
}
