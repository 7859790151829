import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import { Tooltip } from "@mui/material";

interface ValfirmAdditionalStatusIconProps {
  additionalStatus: string;
}

export default function ValfirmAdditionalStatusIcon(
  props: ValfirmAdditionalStatusIconProps
) {
  const { additionalStatus } = props;
  const theme = useTheme();

  const useStyles = makeStyles()(() => ({
    notTrading: {
      color: theme.palette.text.disabled,
      marginRight: "5px",
    },
    atCapacity: {
      color: theme.colors.error.darker,
      marginRight: "5px",
    },
    none: {
      marginRight: "5px",
    },
  }));

  const renderIcon = (additionalStatus: string) => {
    switch (additionalStatus) {
      case "ATCAPACITY":
        return (
          <Tooltip title={"At Capacity"}>
            <FmdBadIcon
              id="at-capacity-icon"
              data-testid="at-capacity-icon"
              className={classes.atCapacity}
            />
          </Tooltip>
        );
      case "NOTTRADING":
        return (
          <Tooltip title={"No Longer Trading"}>
            <WorkOffOutlinedIcon
              id="no-longer-trading-icon"
              data-testid="no-longer-trading-icon"
              className={classes.notTrading}
            />
          </Tooltip>
        );
      default:
        return (
          <WhereToVoteOutlinedIcon
            id="none-icon"
            data-testid="none-icon"
            className={classes.none}
          />
        );
    }
  };

  const { classes } = useStyles();

  return renderIcon(additionalStatus);
}
