import axios from "../../utilities/axiosInstance";

export const fetchFees = (filter) => {
  const url = `/api/fees`;
  return axios.post(url, filter);
};

export const fetchFeeSet = (feeId) => {
  const url = `/api/fees/${feeId}`;
  return axios.get(url);
};

export const fetchFeeDetail = (
  feeId,
  feeRevId,
  currentPage,
  pageSize,
  locationFilter,
  countryFilter
) => {
  const url =
    `/api/fees/${feeId}/versions/${feeRevId}/locations` +
    `?currentPage=${currentPage}&pageSize=${pageSize}` +
    `&locationName=${locationFilter}&country=${countryFilter}`;
  return axios.get(url);
};

export const fetchFeeVersionDetail = (feeId, feeRevId) => {
  const url = `/api/fees/${feeId}/versions/${feeRevId}`;
  return axios.get(url);
};

export const fetchLatestFeeActivationLog = (feeId) => {
  const url = `/api/fees/${feeId}/latestActivationLog`;
  return axios.get(url);
};

export const fetchFeeVersions = (feeSetId, currentPage, pageSize) => {
  const url = `/api/fees/${feeSetId}/versions?currentPage=${currentPage}&pageSize=${pageSize}`;
  return axios.get(url);
};

export const fetchFeeActivationLogs = (
  feeId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) => {
  const url = `/api/fees/${feeId}/activationLogs?currentPage=${currentPage}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
  return axios.get(url);
};

export const fetchLocationSuggestionByVersionId = (
  text,
  option,
  feeId,
  feeRevId,
  isExisting
) => {
  const url = `/api/locations/suggestionbyfeeversionid?q=${encodeURIComponent(
    text
  )}&country=${option}&feeId=${feeId}&feeRevId=${feeRevId}&isExisting=${isExisting}`;
  return axios.get(url);
};

export const fetchUpdateFeeVersionNotes = (
  feeId,
  feeVersionId,
  userId,
  notes
) => {
  const url = `/api/fees/${feeId}/versions/${feeVersionId}/updateNotes`;
  return axios.post(url, { userId: userId, notes: notes });
};

export const fetchUpdateFeeSetName = (feeId, name) => {
  const url = `/api/fees/${feeId}/updateName`;
  return axios.post(url, { value: name });
};

export const updateFeeDetails = (
  feeId,
  feeVersionId,
  userId,
  locationDetail
) => {
  const url = `/api/fees/${feeId}/versions/${feeVersionId}/locations`;
  return axios.post(url, { userId: userId, locationDetail: locationDetail });
};

export const fetchRemoveFeeLocation = (
  feeId,
  feeRevId,
  feeFilterId,
  userId,
  pageSize,
  locationFilter,
  countryId
) => {
  const url =
    `/api/fees/${feeId}/versions/${feeRevId}/locations/${feeFilterId}/delete` +
    `?pageSize=${pageSize}&locationName=${locationFilter}&country=${countryId}`;
  return axios.post(url, { value: userId });
};

export const fetchDiscardFeeVersion = (feeId, feeRevId, userId) => {
  const url = `/api/fees/${feeId}/versions/${feeRevId}/discard`;
  return axios.post(url, { value: userId });
};

export const updateFeeBrackets = (feeId, feeVersionId, feeBrackets, userId) => {
  const url = `/api/fees/${feeId}/versions/${feeVersionId}/feebrackets`;
  return axios.post(url, { userId: userId, feeBrackets: feeBrackets });
};

export const scheduleFeeVersionActivationApi = (
  feeId,
  feeVersionId,
  userId,
  scheduled
) => {
  const url = `/api/fees/${feeId}/versions/${feeVersionId}/scheduleFeeActivation`;
  return axios.post(url, { userId: userId, scheduled: scheduled });
};

export const fetchExportFeeVersion = (feeId, feeVersionId) => {
  const url = `/api/fees/${feeId}/versions/${feeVersionId}/export`;
  return axios.get(url, {
    responseType: "blob",
  });
};

export const copyVersion = (feeId, feeVersionId, userId) => {
  const url = `/api/fees/${feeId}/versions/${feeVersionId}/copy`;
  return axios.post(url, { value: userId });
};

export const fetchCancelFeeActivationLog = (
  feeId,
  feePanelActivationLogId,
  userId
) => {
  const url = `/api/fees/${feeId}/activationLogs/${feePanelActivationLogId}/cancel`;
  return axios.post(url, { value: userId });
};

export const fetchFeeVersionComparisonDetails = (feeId, versionsList) => {
  const url = `/api/fees/${feeId}/versions/compare`;
  return axios.post(url, versionsList);
};

export const importFeeFile = (param, config) => {
  let formData = new FormData();
  formData.append("id", param.id);
  formData.append("userId", param.userId);
  formData.append("file", param.file);
  return axios.post(`/api/fees/${param.id}/import`, formData, config);
};

export const createNewFeeSetAPI = (locationDetail) => {
  const url = `/api/fees/newfee`;
  return axios.post(url, locationDetail);
};

export const fetchAssociatePanels = (
  feeId,
  currentPage,
  pageSize,
  sortBy,
  sortDir
) => {
  const url = `/api/fees/${feeId}/panels?currentPage=${currentPage}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`;
  return axios.get(url);
};

export const acknowledgeFeeActivationLog = (
  feeId,
  feePanelActivationLogId,
  userId
) => {
  const url = `/api/fees/${feeId}/activationLogs/${feePanelActivationLogId}/acknowledge`;
  return axios.post(url, { value: userId });
};

export const fetchExportMultiFees = (feeIds) => {
  const url = `/api/fees/export`;
  return axios.post(url, feeIds, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
