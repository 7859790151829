const getInArray = (o, [p1, ...p]) => {
  if (o == null || typeof o !== "object") {
    return undefined;
  }
  return p.length ? getInArray(o[p1], p) : o[p1];
};
export function getIn(o, path) {
  const pathArray =
    typeof path === "string" ? path.split(".").filter((i) => i) : path;
  return pathArray.length ? getInArray(o, pathArray) : o;
}
