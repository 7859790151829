import { call, put, takeLeading, takeLatest, select } from "redux-saga/effects";
import {
  fetchLocationSuggestion,
  fetchPanelOrgSuggestion,
  fetchSearchSuggestion,
} from "../apis/api";
import {
  fetchAllocations,
  fetchAllocationSet,
  fetchAllocationVersions,
  fetchAllocationDetail,
  fetchLocationSuggestionByVersionId,
  fetchExportAllocationVersion,
  fetchUpdateAllocationVersionNotes,
  updateAllocationDetails,
  importAllocationFile,
  fetchAllocationVersionDetail,
  copyVersion,
  fetchAllocationActivationLogs,
  scheduleAllocationVersionActivationApi,
  fetchCancelAllocationActivationLog,
  fetchAllocationVersionComparisonDetails,
  fetchRemoveAllocationLocation,
  fetchLatestAllocationActivationLog,
  createNewAllocationSetAPI,
  updateAllocationNameAPI,
  updateAllowAutoUpdate,
  updateMaxProviders,
  updateQuote,
  fetchDiscardAllocationVersion,
  fetchAssociatedPanels,
  acknowledgeAllocationActivationLog,
  fetchExportMultiAllocations,
  fetchExistingValfirms,
} from "../apis/apiAllocation";

import {
  OPEN_SNACKBAR,
  OPEN_ACTION_SNACKBAR,
  OPEN_FAIL_ACTIVATION_CANCEL,
} from "./sagaUI";
import { getUserId } from "./selectors";
import { resetPanelAllocationAPI } from "../apis/apiPanel";
import moment from "moment-timezone";

export const RESET_PAGINATION = "allocation/RESET_PAGINATION";
export const FETCH_ALLOCATIONS = "allocation/FETCH_ALLOCATIONS";
export const ATTEMPT_FETCH_ALLOCATIONS = "allocation/ATTEMPT_FETCH_ALLOCATIONS";
export const SUCCEED_FETCH_ALLOCATIONS = "allocation/SUCCEED_FETCH_ALLOCATIONS";
export const FAIL_FETCH_ALLOCATIONS = "allocation/FAIL_FETCH_ALLOCATIONS";

export const FETCH_ALLOCATION_VERSIONS = "allocation/FETCH_ALLOCATION_VERSIONS";
export const ATTEMPT_FETCH_ALLOCATION_VERSIONS =
  "allocation/ATTEMPT_FETCH_ALLOCATION_VERSIONS";
export const SUCCEED_FETCH_ALLOCATION_VERSIONS =
  "allocation/SUCCEED_FETCH_ALLOCATION_VERSIONS";
export const FAIL_FETCH_ALLOCATION_VERSIONS =
  "allocation/FAIL_FETCH_ALLOCATION_VERSIONS";

export const FETCH_ALLOCATION_SET = "allocation/FETCH_ALLOCATION_SET";
export const ATTEMPT_FETCH_ALLOCATION_SET =
  "allocation/ATTEMPT_FETCH_ALLOCATION_SET";
export const SUCCEED_FETCH_ALLOCATION_SET =
  "allocation/SUCCEED_FETCH_ALLOCATION_SET";
export const FAIL_FETCH_ALLOCATION_SET = "allocation/FAIL_FETCH_ALLOCATION_SET";

export const FETCH_ALLOCATION_DETAIL = "allocation/FETCH_ALLOCATION_DETAIL";
export const ATTEMPT_FETCH_ALLOCATION_DETAIL =
  "allocation/ATTEMPT_FETCH_ALLOCATION_DETAIL";
export const SUCCEED_FETCH_ALLOCATION_DETAIL =
  "allocation/SUCCEED_FETCH_ALLOCATION_DETAIL";
export const FAIL_FETCH_ALLOCATION_DETAIL =
  "allocation/FAIL_FETCH_ALLOCATION_DETAIL";

export const UPDATE_LOCATION_TEXT_CHANGE =
  "allocation/UPDATE_LOCATION_TEXT_CHANGE";
export const ATTEMPT_FETCH_LOCATION_SUGGESTION =
  "allocation/ATTEMPT_FETCH_LOCATION_SUGGESTION";
export const SUCCEED_FETCH_LOCATION_SUGGESTION =
  "allocation/SUCCEED_FETCH_LOCATION_SUGGESTION";
export const FAIL_FETCH_LOCATION_SUGGESTION =
  "allocation/FAIL_FETCH_LOCATION_SUGGESTION";
export const CLEAR_LOCATION_SUGGESTION =
  "allocation/CLEAR_FETCH_LOCATION_SUGGESTION";

export const UPDATE_LOCATION_OPTION_NEW_ALLOCATION =
  "allocation/UPDATE_LOCATION_OPTION_NEW_ALLOCATION";
export const UPDATE_LOCATION_TEXT_CHANGE_NEW_ALLOCATION =
  "allocation/UPDATE_LOCATION_TEXT_CHANGE_NEW_ALLOCATION";
export const ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION =
  "allocation/ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION";
export const SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION =
  "allocation/SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION";
export const FAIL_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION =
  "allocation/FAIL_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION";
export const CLEAR_LOCATION_SUGGESTION_NEW_ALLOCATION =
  "allocation/CLEAR_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION";

export const EXPORT_ALLOCATION_VERSION = "allocation/EXPORT_ALLOCATION_VERSION";
export const ATTEMPT_EXPORT_ALLOCATION_VERSION =
  "allocation/ATTEMPT_EXPORT_ALLOCATION_VERSION";
export const SUCCEED_EXPORT_ALLOCATION_VERSION =
  "allocation/SUCCEED_EXPORT_ALLOCATION_VERSION";
export const FAIL_EXPORT_ALLOCATION_VERSION =
  "allocation/FAIL_EXPORT_ALLOCATION_VERSION";

export const UPDATE_ALLOCATION_VERSION_NOTES =
  "allocation/UPDATE_ALLOCATION_VERSION_NOTES";
export const ATTEMPT_UPDATE_ALLOCATION_VERSION_NOTES =
  "allocation/ATTEMPT_UPDATE_ALLOCATION_VERSION_NOTES";
export const SUCCEED_UPDATE_ALLOCATION_VERSION_NOTES =
  "allocation/SUCCEED_UPDATE_ALLOCATION_VERSION_NOTES";
export const FAIL_UPDATE_ALLOCATION_VERSION_NOTES =
  "allocation/FAIL_UPDATE_ALLOCATION_VERSION_NOTES";

export const SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const ATTEMPT_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/ATTEMPT_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const SUCCEED_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/SUCCEED_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const FAIL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/FAIL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";

export const UPDATE_ALLOCATION_DETAIL = "allocation/UPDATE_ALLOCATION_DETAIL";
export const ATTEMPT_UPDATE_ALLOCATION_DETAIL =
  "allocation/ATTEMPT_UPDATE_ALLOCATION_DETAIL";
export const SUCCEED_UPDATE_ALLOCATION_DETAIL =
  "allocation/SUCCEED_UPDATE_ALLOCATION_DETAIL";
export const FAIL_UPDATE_ALLOCATION_DETAIL =
  "allocation/FAIL_UPDATE_ALLOCATION_DETAIL";

export const CREATE_NEW_ALLOCATION = "allocation/CREATE_NEW_ALLOCATION";
export const ERROR_CREATE_NEW_ALLOCATION =
  "allocation/ERROR_CREATE_NEW_ALLOCATION";

export const ATTEMPT_CREATE_NEW_ALLOCATION =
  "allocation/ATTEMPT_CREATE_NEW_ALLOCATION";
export const SUCCEED_CREATE_NEW_ALLOCATION =
  "allocation/SUCCEED_CREATE_NEW_ALLOCATION";
export const FAIL_CREATE_NEW_ALLOCATION =
  "allocation/FAIL_CREATE_NEW_ALLOCATION";

export const OPEN_IMPORT_MODAL = "allocation/OPEN_IMPORT_MODAL";
export const IMPORT_ALLOCATION_VERSION = "allocation/IMPORT_ALLOCATION_VERSION";
export const ATTEMPT_IMPORT_ALLOCATION_VERSION =
  "allocation/ATTEMPT_IMPORT_ALLOCATION_VERSION";
export const SUCCEED_IMPORT_ALLOCATION_VERSION =
  "allocation/SUCCEED_IMPORT_ALLOCATION_VERSION";
export const FAIL_VALIDATION_IMPORT_ALLOCATION_VERSION =
  "allocation/FAIL_VALIDATION_IMPORT_ALLOCATION_VERSION";
export const FAIL_IMPORT_ALLOCATION_VERSION =
  "allocation/FAIL_IMPORT_ALLOCATION_VERSION";
export const RESET_VALIDATION_ERRORS = "allocation/RESET_VALIDATION_ERRORS";
export const CHANGE_ALLOCATION_TAB = "allocation/CHANGE_ALLOCATION_TAB";
export const CHANGE_ALLOCATION_VERSION_ID =
  "allocation/CHANGE_ALLOCATION_VERSION_ID";

export const FETCH_ALLOCATION_VERSION_DETAIL =
  "allocation/FETCH_ALLOCATION_VERSION_DETAIL";
export const ATTEMPT_FETCH_ALLOCATION_VERSION_DETAIL =
  "allocation/ATTEMPT_FETCH_ALLOCATION_VERSION_DETAIL";
export const SUCCEED_FETCH_ALLOCATION_VERSION_DETAIL =
  "allocation/SUCCEED_FETCH_ALLOCATION_VERSION_DETAIL";
export const FAIL_FETCH_ALLOCATION_VERSION_DETAIL =
  "allocation/FAIL_FETCH_ALLOCATION_VERSION_DETAIL";

export const COPY_ALLOCATION_VERSION = "allocation/COPY_ALLOCATION_VERSION";
export const ATTEMPT_COPY_ALLOCATION_VERSION =
  "allocation/ATTEMPT_COPY_ALLOCATION_VERSION";
export const SUCCEED_COPY_ALLOCATION_VERSION =
  "allocation/SUCCEED_COPY_ALLOCATION_VERSION";
export const FAIL_COPY_ALLOCATION_VERSION =
  "allocation/FAIL_COPY_ALLOCATION_VERSION";

export const FETCH_ALLOCATION_ACTIVATION_LOGS =
  "allocation/FETCH_ALLOCATION_ACTIVATION_LOGS";
export const ATTEMPT_FETCH_ALLOCATION_ACTIVATION_LOGS =
  "allocation/ATTEMPT_FETCH_ALLOCATION_ACTIVATION_LOGS";
export const SUCCEED_FETCH_ALLOCATION_ACTIVATION_LOGS =
  "allocation/SUCCEED_FETCH_ALLOCATION_ACTIVATION_LOGS";
export const FAIL_FETCH_ALLOCATION_ACTIVATION_LOGS =
  "allocation/FAIL_FETCH_ALLOCATION_ACTIVATION_LOGS";

export const CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const ATTEMPT_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/ATTEMPT_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const SUCCEED_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/SUCCEED_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const FAIL_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/FAIL_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";

export const SET_VERSIONS_TO_COMPARE = "allocation/SET_VERSIONS_TO_COMPARE";
export const SAVE_VERSIONS_TO_COMPARE = "allocation/SAVE_VERSIONS_TO_COMPARE";
export const RESET_VERSIONS_TO_COMPARE = "allocation/RESET_VERSIONS_TO_COMPARE";
export const CLEAR_VERSIONS_TO_COMPARE = "allocation/CLEAR_VERSIONS_TO_COMPARE";
export const SHOW_VERSIONS_COMPARISON = "allocation/SHOW_VERSIONS_COMPARISON";
export const SHOW_CREATE_NEW_ALLOCATION =
  "allocation/SHOW_CREATE_NEW_ALLOCATION";
export const UPDATE_LOCATION_NEW_ALLOCATION =
  "allocation/UPDATE_LOCATION_NEW_ALLOCATION";

export const FETCH_VERSIONS_COMPARISON_DETAILS =
  "allocation/FETCH_VERSIONS_COMPARISON_DETAILS";
export const ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS =
  "allocation/ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS";
export const SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS =
  "allocation/SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS";
export const FAIL_FETCH_VERSIONS_COMPARISON_DETAILS =
  "allocation/FAIL_FETCH_VERSIONS_COMPARISON_DETAILS";

export const REMOVE_ALLOCATION_LOCATION =
  "allocation/REMOVE_ALLOCATION_LOCATION";
export const ATTEMPT_REMOVE_ALLOCATION_LOCATION =
  "allocation/ATTEMPT_REMOVE_ALLOCATION_LOCATION";
export const SUCCEED_REMOVE_ALLOCATION_LOCATION =
  "allocation/SUCCEED_REMOVE_ALLOCATION_LOCATION";
export const FAIL_REMOVE_ALLOCATION_LOCATION =
  "allocation/FAIL_REMOVE_ALLOCATION_LOCATION";
export const REMOVE_ALLOCATION_LOCATION_NEW_ALLOCATION =
  "allocation/REMOVE_ALLOCATION_LOCATION_NEW_ALLOCATION";
export const FETCH_LATEST_ALLOCATION_ACTIVATION_LOG =
  "allocation/FETCH_LATEST_ALLOCATION_ACTIVATION_LOG";
export const ATTEMPT_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG =
  "allocation/ATTEMPT_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG";
export const SUCCEED_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG =
  "allocation/SUCCEED_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG";
export const FAIL_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG =
  "allocation/FAIL_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG";

export const UPDATE_ISQUOTE_NEW_ALLOCATION =
  "allocation/UPDATE_ISQUOTE_NEW_ALLOCATION";
export const UPDATE_ALLOCATIONAME_NEW_ALLOCATION =
  "allocation/UPDATE_ALLOCATIONAME_NEW_ALLOCATION";
export const UPDATE_ISSCORECARD_NEW_ALLOCATION =
  "allocation/UPDATE_ISSCORECARD_NEW_ALLOCATION";
export const UPDATE_NOOFQUOTES_NEW_ALLOCATION =
  "allocation/UPDATE_NOOFQUOTES_NEW_ALLOCATION";

export const UPDATE_ISQUOTE_EDIT_ALLOCATION =
  "allocation/UPDATE_ISQUOTE_EDIT_ALLOCATION";
export const ATTEMPT_UPDATE_ISQUOTE_EDIT_ALLOCATION =
  "allocation/ATTEMPT_UPDATE_ISQUOTE_EDIT_ALLOCATION";
export const SUCCEED_UPDATE_ISQUOTE_EDIT_ALLOCATION =
  "allocation/SUCCEED_UPDATE_ISQUOTE_EDIT_ALLOCATION";
export const FAIL_UPDATE_ISQUOTE_EDIT_ALLOCATION =
  "allocation/FAIL_UPDATE_ISQUOTE_EDIT_ALLOCATION";

export const UPDATE_ISSCORECARD_EDIT_ALLOCATION =
  "allocation/UPDATE_ISSCORECARD_EDIT_ALLOCATION";
export const ATTEMPT_UPDATE_ISSCORECARD_EDIT_ALLOCATION =
  "allocation/ATTEMPT_UPDATE_ISSCORECARD_EDIT_ALLOCATION";
export const SUCCEED_UPDATE_ISSCORECARD_EDIT_ALLOCATION =
  "allocation/SUCCEED_UPDATE_ISSCORECARD_EDIT_ALLOCATION";
export const FAIL_UPDATE_ISSCORECARD_EDIT_ALLOCATION =
  "allocation/UPDATE_ISSCORECARD_EDIT_ALLOCATION";

export const UPDATE_NOOFQUOTES_EDIT_ALLOCATION =
  "allocation/UPDATE_NOOFQUOTES_EDIT_ALLOCATION";
export const ATTEMPT_UPDATE_NOOFQUOTES_EDIT_ALLOCATION =
  "allocation/ATTEMPT_UPDATE_NOOFQUOTES_EDIT_ALLOCATION";
export const SUCCEED_UPDATE_NOOFQUOTES_EDIT_ALLOCATION =
  "allocation/SUCCEED_UPDATE_NOOFQUOTES_EDIT_ALLOCATION";
export const FAIL_UPDATE_NOOFQUOTES_EDIT_ALLOCATION =
  "allocation/FAIL_UPDATE_NOOFQUOTES_EDIT_ALLOCATION";

export const UPDATE_ALLOCATION_NAME = "allocation/UPDATE_ALLOCATION_NAME";
export const ATTEMPT_UPDATE_ALLOCATION_NAME =
  "allocation/ATTEMPT_UPDATE_ALLOCATION_NAME";
export const SUCCEED_UPDATE_ALLOCATION_NAME =
  "allocation/SUCCEED_UPDATE_ALLOCATION_NAME";
export const FAIL_DUPLICATE_UPDATE_ALLOCATION_NAME =
  "allocation/FAIL_DUPLICATE_UPDATE_ALLOCATION_NAME";
export const FAIL_UPDATE_ALLOCATION_NAME =
  "allocation/FAIL_UPDATE_ALLOCATION_NAME";
export const RESET_UPDATE_ALLOCATION_NAME_ERROR =
  "allocation/RESET_UPDATE_ALLOCATION_NAME_ERROR";

export const OPEN_ALLOCATION_SET_NAME_MODAL =
  "allocation/OPEN_ALLOCATION_SET_NAME_MODAL";
export const RESET_UPDATE_NAME_ERROR = "allocation/RESET_UPDATE_NAME_ERROR";

export const DISCARD_ALLOCATION_VERSION =
  "allocation/DISCARD_ALLOCATION_VERSION";
export const ATTEMPT_DISCARD_ALLOCATION_VERSION =
  "allocation/ATTEMPT_DISCARD_ALLOCATION_VERSION";
export const SUCCEED_DISCARD_ALLOCATION_VERSION =
  "allocation/SUCCEED_DISCARD_ALLOCATION_VERSION";
export const FAIL_DISCARD_ALLOCATION_VERSION =
  "allocation/FAIL_DISCARD_ALLOCATION_VERSION";

export const RESET_PANEL_ALLOCATION = "allocation/RESET_PANEL_ALLOCATION";
export const ATTEMPT_RESET_PANEL_ALLOCATION =
  "allocation/ATTEMPT_RESET_PANEL_ALLOCATION";
export const SUCCEED_RESET_PANEL_ALLOCATION =
  "allocation/SUCCEED_RESET_PANEL_ALLOCATION";
export const FAIL_RESET_PANEL_ALLOCATION =
  "allocation/FAIL_RESET_PANEL_ALLOCATION";

export const FETCH_ASSOCIATED_PANELS = "allocation/FETCH_ASSOCIATED_PANELS";
export const ATTEMPT_FETCH_ASSOCIATED_PANELS =
  "allocation/ATTEMPT_FETCH_ASSOCIATED_PANELS";
export const SUCCEED_FETCH_ASSOCIATED_PANELS =
  "allocation/SUCCEED_FETCH_ASSOCIATED_PANELS";
export const FAIL_FETCH_ASSOCIATED_PANELS =
  "allocation/FAIL_FETCH_ASSOCIATED_PANELS";

export const FILTER_NAME_CHANGE = "allocation/FILTER_NAME_CHANGE";
export const ATTEMPT_FETCH_FILTER_NAME_SUGGESTION =
  "allocation/ATTEMPT_FETCH_FILTER_NAME_SUGGESTION";
export const SUCCEED_FETCH_FILTER_NAME_SUGGESTION =
  "allocation/SUCCEED_FETCH_FILTER_NAME_SUGGESTION";
export const FAIL_FETCH_FILTER_NAME_SUGGESTION =
  "allocation/FAIL_FETCH_FILTER_NAME_SUGGESTION";
export const CLEAR_FILTER_NAME_SUGGESTION =
  "allocation/CLEAR_FILTER_NAME_SUGGESTION";

export const CHANGE_FILTER_COUNTRY = "allocation/CHANGE_FILTER_COUNTRY";
export const FILTER_LOCATION_CHANGE = "allocation/FILTER_LOCATION_CHANGE";
export const ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION =
  "allocation/ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION";
export const SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION =
  "allocation/SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION";
export const FAIL_FETCH_FILTER_LOCATION_SUGGESTION =
  "allocation/FAIL_FETCH_FILTER_LOCATION_SUGGESTION";
export const CLEAR_FILTER_LOCATION_SUGGESTION =
  "allocation/CLEAR_FILTER_LOCATION_SUGGESTION";
export const ADD_FILTER_LOCATION = "allocation/ADD_FILTER_LOCATION";
export const DELETE_FILTER_LOCATION = "allocation/DELETE_FILTER_LOCATION";
export const FILTER_CLEAR_ALL = "allocation/FILTER_CLEAR_ALL";

export const ADD_FILTER_VALFIRM = "allocation/ADD_FILTER_VALFIRM";
export const DELETE_FILTER_VALFIRM = "allocation/DELETE_FILTER_VALFIRM";

export const FILTER_VALFIRM_CHANGE = "allocation/FILTER_VALFIRM_CHANGE";
export const ATTEMPT_FETCH_FILTER_VALFIRM_SUGGESTION =
  "allocation/ATTEMPT_FETCH_FILTER_VALFIRM_SUGGESTION";
export const SUCCEED_FETCH_FILTER_VALFIRM_SUGGESTION =
  "allocation/SUCCEED_FETCH_FILTER_VALFIRM_SUGGESTION";
export const FAIL_FETCH_FILTER_VALFIRM_SUGGESTION =
  "allocation/FAIL_FETCH_FILTER_VALFIRM_SUGGESTION";
export const CLEAR_FILTER_VALFIRM_SUGGESTION =
  "allocation/CLEAR_FILTER_VALFIRM_SUGGESTION";
export const OPEN_FILTER = "allocation/OPEN_FILTER";
export const COPY_FILTER = "allocation/COPY_FILTER";
export const UPDATE_ALLOCATION_STATUS_FILTER =
  "allocation/UPDATE_ALLOCATION_STATUS_FILTER";

export const ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const ATTEMPT_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/ATTEMPT_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const SUCCEED_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/SUCCEED_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";
export const FAIL_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION =
  "allocation/FAIL_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION";

export const EXPORT_MULTI_ALLOCATIONS = "allocation/EXPORT_MULTI_ALLOCATIONS";
export const ATTEMPT_EXPORT_MULTI_ALLOCATIONS =
  "allocation/ATTEMPT_EXPORT_MULTI_ALLOCATIONS";
export const SUCCEED_EXPORT_MULTI_ALLOCATIONS =
  "allocation/SUCCEED_EXPORT_MULTI_ALLOCATIONS";
export const FAIL_EXPORT_MULTI_ALLOCATIONS =
  "allocation/FAIL_EXPORT_MULTI_ALLOCATIONS";

export const FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS =
  "allocation/FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS";
export const ATTEMPT_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS =
  "allocation/ATTEMPT_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS";
export const SUCCEED_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS =
  "allocation/SUCCEED_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS";
export const FAIL_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS =
  "allocation/FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS";

export const SET_ADD_VALFIRM_TEXT_SELECTED_VALFIRMS =
  "allocation/SET_ADD_VALFIRM_TEXT_SELECTED_VALFIRMS";
export const ADD_VALFIRM_SELECTED_VALFIRMS =
  "allocation/ADD_VALFIRM_SELECTED_VALFIRMS";
export const DELETE_VALFIRM_SELECTED_VALFIRMS =
  "allocation/DELETE_VALFIRM_SELECTED_VALFIRMS";
export const ADD_LOCATION_SELECTED_LOCATIONS =
  "allocation/ADD_LOCATION_SELECTED_LOCATIONS";
export const DELETE_LOCATION_SELECTED_LOCATIONS =
  "allocation/DELETE_LOCATION_SELECTED_LOCATIONS";
export const CLEAR_ALL_SELECTED_VALFIRMS_LOCATIONS =
  "allocation/CLEAR_ALL_SELECTED_VALFIRMS_LOCATIONS";

export function* getAllocations(param) {
  let objAttempt = {
    type: ATTEMPT_FETCH_ALLOCATIONS,
    data: {
      currentPage: param.payload.filter.currentPage,
      pageSize: param.payload.filter.pageSize,
      isFiltered: param.payload.filter.isFiltered,
      sortBy: param.payload.filter.sortBy,
      sortDir: param.payload.filter.sortDir,
    },
  };
  if (param.payload.filter.isFiltered) {
    objAttempt["meta"] = {
      analytics: {
        allocationName: param.payload.filter.name,
        valfirms: param.payload.filter.valfirms
          .map((valfirm) => valfirm.name)
          .join(","),
        valfirmKeyword: param.payload.filter.valfirmKeyword,
        allocationStatus: param.payload.filter.status,
        allocationLocations: param.payload.filter.locations
          .map((loc) => loc.name)
          .join(","),
      },
    };
  }
  yield put(objAttempt);
  try {
    const { data: response } = yield call(
      fetchAllocations,
      param.payload.filter
    );
    yield put({
      type: SUCCEED_FETCH_ALLOCATIONS,
      data: {
        ...response,
        name: param.payload.filter.name,
        status: param.payload.filter.status,
        locations: param.payload.filter.locations,
        valfirms: param.payload.filter.valfirms,
        keywordValfirms: param.payload.filter.keywordValfirms,
        countryId: param.payload.filter.countryId,
        locationText: param.payload.filter.locationText,
        valfirmText: param.payload.filter.valfirmText,
        nameSuggestion: param.payload.filter.nameSuggestion,
        locationSuggestion: param.payload.filter.locationSuggestion,
        valfirmSuggestion: param.payload.filter.valfirmSuggestion,
        sortBy: param.payload.filter.sortBy,
        sortDir: param.payload.filter.sortDir,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ALLOCATIONS,
    });
  }
}

export function* getAllocationSet(param) {
  yield put({
    type: ATTEMPT_FETCH_ALLOCATION_SET,
  });

  try {
    const { data: detail } = yield call(fetchAllocationSet, param.allocationId);
    yield put({
      type: SUCCEED_FETCH_ALLOCATION_SET,
      data: {
        ...detail,
      },
      meta: {
        analytics: {
          allocationSetId: detail.allocPanelId,
          allocationSetName: detail.name,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ALLOCATION_SET,
      meta: {
        analytics: {
          allocationSetId: param.allocationId,
          allocationSetName: param.allocationName,
        },
      },
    });
  }
}

export function* getLatestAllocationActivationLog(param) {
  yield put({
    type: ATTEMPT_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG,
  });

  try {
    const { data: detail } = yield call(
      fetchLatestAllocationActivationLog,
      param.allocationId
    );

    yield put({
      type: SUCCEED_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG,
      data: {
        ...detail,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_LATEST_ALLOCATION_ACTIVATION_LOG,
    });
  }
}

export function* getAllocationDetail(param) {
  yield put({
    type: ATTEMPT_FETCH_ALLOCATION_DETAIL,
    data: {
      currentPage: param.currentPage,
      pageSize: param.pageSize,
    },
  });

  try {
    const { data: detail } = yield call(
      fetchAllocationDetail,
      param.allocationId,
      param.allocRevId,
      param.valfirmFilter,
      param.locationFilter,
      param.currentPage,
      param.pageSize
    );
    yield put({
      type: SUCCEED_FETCH_ALLOCATION_DETAIL,
      data: {
        ...detail,
      },
      payload: {
        ...param,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ALLOCATION_DETAIL,
    });
  }
}

export function* updateLocationTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_LOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestionByVersionId,
        data.text,
        data.option,
        data.allocationId,
        data.allocRevId,
        data.isExisting
      );

      yield put({
        type: SUCCEED_FETCH_LOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_LOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_LOCATION_SUGGESTION,
    });
  }
}

export function* updateLocationTextChangeNewAllocation(data) {
  yield put({
    type: ATTEMPT_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestion,
        data.text,
        data.option
      );

      yield put({
        type: SUCCEED_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_LOCATION_SUGGESTION_NEW_ALLOCATION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_LOCATION_SUGGESTION_NEW_ALLOCATION,
    });
  }
}

export function* getAllocationVersions(param) {
  yield put({
    type: ATTEMPT_FETCH_ALLOCATION_VERSIONS,
    data: {
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
    },
  });
  try {
    const { data: response } = yield call(
      fetchAllocationVersions,
      param.payload.allocationId,
      param.payload.currentPage,
      param.payload.pageSize
    );
    yield put({
      type: SUCCEED_FETCH_ALLOCATION_VERSIONS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ALLOCATION_VERSIONS,
    });
  }
}

export function* exportAllocationVersion(param) {
  yield put({ type: ATTEMPT_EXPORT_ALLOCATION_VERSION });

  try {
    const { data: response } = yield call(
      fetchExportAllocationVersion,
      param.payload.allocationId,
      param.payload.allocationVersionId
    );
    const fileName =
      param.payload.allocationSetName +
      "-V" +
      param.payload.allocationVersionId +
      ".xlsx";
    // Create a temporary URL for the file blob
    const url = window.URL.createObjectURL(new Blob([response]));
    const element = document.createElement("a");
    element.style.display = "none";
    element.setAttribute("href", url);
    element.setAttribute("download", fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    yield put({
      type: SUCCEED_EXPORT_ALLOCATION_VERSION,
      meta: {
        analytics: {
          allocationSetId: param.payload.allocationId,
          allocationSetName: param.payload.allocationSetName,
          versionId: param.payload.allocationVersionId,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully downloaded " + fileName,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_EXPORT_ALLOCATION_VERSION,
      meta: {
        analytics: {
          allocationSetId: param.payload.allocationId,
          allocationSetName: param.payload.allocationSetName,
          versionId: param.payload.allocationVersionId,
        },
      },
    });
  }
}

export function* updateAllocationVersionNotes(param) {
  yield put({ type: ATTEMPT_UPDATE_ALLOCATION_VERSION_NOTES });
  try {
    const allocationId = param.payload.allocationId;
    const allocationVersionId = param.payload.allocationVersionId;
    const userId = yield select(getUserId);
    const notes = param.payload.notes;
    yield call(
      fetchUpdateAllocationVersionNotes,
      allocationId,
      allocationVersionId,
      userId,
      notes
    );
    yield put({
      type: SUCCEED_UPDATE_ALLOCATION_VERSION_NOTES,
      data: {
        allocationVersionId,
        notes,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({ type: FAIL_UPDATE_ALLOCATION_VERSION_NOTES });
  }
}

export function* scheduleAllocationVersionActivation(param) {
  const { allocationPanelId, allocationVersionId, activateDate } =
    param.payload;
  const userId = yield select(getUserId);
  yield put({
    type: ATTEMPT_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  });
  try {
    const { data } = yield call(
      scheduleAllocationVersionActivationApi,
      allocationPanelId,
      allocationVersionId,
      userId,
      activateDate
    );

    const timezone = moment.tz.guess();
    const scheduled = moment(data.scheduled)
      .tz(timezone)
      .format("DD-MM-YYYY hh:mm:ss A");

    yield put({
      type: SUCCEED_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
      data,
      meta: {
        analytics: {
          versionId: param.payload.allocationVersionId,
          scheduledDatetime: scheduled,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message:
          "Successfully Scheduled Version " +
          allocationVersionId +
          " for " +
          scheduled,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: param.payload.allocationVersionId,
          scheduledDatetime: activateDate[`$d`].toString(),
        },
      },
    });
  }
}
export function* updateAllocationDetail(param) {
  yield put({
    type: ATTEMPT_UPDATE_ALLOCATION_DETAIL,
  });
  const { allocationId, allocationVersionId, location, valfirms } =
    param.payload;
  const userId = yield select(getUserId);
  const displayName =
    location &&
    (location.type === "Country"
      ? location.name
      : [location.countryName, location.type, location.name].join(" "));

  try {
    const locationDetail = {
      allocationPanelId: allocationId,
      allocationPanelRevId: allocationVersionId,
      allocationPanelFilterId: location.allocationPanelFilterId,
      userId,
      countryId: location.countryId,
      countryName: location.countryName,
      stateId: location.type === "State" ? location.id : null,
      stateName: location.type === "State" ? location.name : null,
      regionId: location.type === "Region" ? location.id : null,
      regionName: location.type === "Region" ? location.name : null,
      postcodeId: location.type === "Postcode" ? location.id : null,
      postcodeName: location.type === "Postcode" ? location.name : null,
      allocations: valfirms.map((v) => {
        return {
          allocationPanelAllocationsId: v.allocationPanelAllocationsId,
          allocation: v.value,
          orgId: v.valfirmId,
          orgName: v.valfirmName,
          isEdited: v.isEdited,
          isDeleted: v.isDeleted,
        };
      }),
    };

    const { data: updatedLocation } = yield call(
      updateAllocationDetails,
      allocationId,
      allocationVersionId,
      userId,
      locationDetail
    );

    yield put({
      type: SUCCEED_UPDATE_ALLOCATION_DETAIL,
      payload: updatedLocation,
      meta: {
        analytics: {
          versionId: allocationVersionId,
          location: displayName,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully Updated " + displayName,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_ALLOCATION_DETAIL,
      meta: {
        analytics: {
          versionId: allocationVersionId,
          location: displayName,
        },
      },
    });
  }
}

export function* createNewAllocationSet(param) {
  yield put({
    type: ATTEMPT_CREATE_NEW_ALLOCATION,
  });

  const userId = yield select(getUserId);
  try {
    const locationDetail = {
      name: param.payload.newAllocationSet.allocationName,
      notes: param.payload.newAllocationSet.notes,
      quote: param.payload.newAllocationSet.isQuote,
      scoreCard: param.payload.newAllocationSet.isScoreCard,
      maxProviders: param.payload.newAllocationSet.noOfQuotes,
      userId,
      locations: param.payload.newAllocationSet.locations,
      countryId: param.payload.newAllocationSet.allocationCountryId,
    };

    const { data: updatedLocation } = yield call(
      createNewAllocationSetAPI,
      locationDetail
    );
    yield put({
      type: SUCCEED_CREATE_NEW_ALLOCATION,
      payload: updatedLocation,
      meta: {
        analytics: {
          allocationSetId: updatedLocation.id,
          allocationSetName: locationDetail.name,
          versionId: updatedLocation.revId,
        },
      },
    });
  } catch (err) {
    console.warn(err);
    if (err.response.data.message === "Name Already Exists") {
      yield put({
        type: ERROR_CREATE_NEW_ALLOCATION,
        error: "Please select another name as this one exists",
        meta: {
          analytics: {
            allocationSetName: param.payload.newAllocationSet.allocationName,
            message: err.response.data.message,
          },
        },
      });
    } else {
      yield put({
        type: FAIL_CREATE_NEW_ALLOCATION,
        meta: {
          analytics: {
            allocationSetName: param.payload.newAllocationSet.allocationName,
          },
        },
      });
    }
  }
}

export function* importAllocationVersion(param) {
  yield put({ type: ATTEMPT_IMPORT_ALLOCATION_VERSION });

  try {
    const axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    param.payload.userId = yield select(getUserId);

    const { data: response } = yield call(
      importAllocationFile,
      param.payload,
      axiosConfig
    );
    const validationErrors = response.validationErrors;
    if (validationErrors && validationErrors.length > 0) {
      yield put({
        type: FAIL_VALIDATION_IMPORT_ALLOCATION_VERSION,
        data: {
          validationErrors,
        },
        meta: {
          analytics: {
            allocationSetId: param.payload.id,
            allocationSetName: param.payload.name,
          },
        },
      });
    } else {
      yield put({ type: RESET_VALIDATION_ERRORS });
      const allocationPanelRev = response.allocationPanelRev;
      yield put({
        type: SUCCEED_IMPORT_ALLOCATION_VERSION,
        data: {
          allocationPanelRev,
        },
        meta: {
          analytics: {
            allocationSetId: param.payload.id,
            allocationSetName: param.payload.name,
            versionId: allocationPanelRev.allocationPanelRevId,
          },
        },
      });
      yield put({ type: OPEN_IMPORT_MODAL, data: { isOpen: false } });
      yield put({
        type: CHANGE_ALLOCATION_VERSION_ID,
        id: allocationPanelRev.allocationPanelRevId,
      });
      if (param.payload.isRedirect) {
        yield put({
          type: OPEN_SNACKBAR,
          payload: {
            message: "Successfully Imported " + param.payload.file.name,
            variant: "info",
            isIcon: true,
            duration: 5000,
            actionType: "redirect",
            id: param.payload.id,
          },
        });
      } else {
        yield put({ type: CHANGE_ALLOCATION_TAB, index: 2 });
        yield put({
          type: OPEN_SNACKBAR,
          payload: {
            message: "Successfully Imported " + param.payload.file.name,
            variant: "info",
            isIcon: true,
            duration: 5000,
            actionType: "previewDetail",
            id: param.payload.id,
          },
        });
      }
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_IMPORT_ALLOCATION_VERSION,
      meta: {
        analytics: {
          allocationSetId: param.payload.id,
          allocationSetName: param.payload.name,
        },
      },
    });
  }
}

export function* getAllocationVersionDetail(param) {
  yield put({ type: ATTEMPT_FETCH_ALLOCATION_VERSION_DETAIL });

  try {
    const { data: detail } = yield call(
      fetchAllocationVersionDetail,
      param.payload.allocationId,
      param.payload.allocRevId
    );

    yield put({
      type: SUCCEED_FETCH_ALLOCATION_VERSION_DETAIL,
      data: {
        ...detail,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({ type: FAIL_FETCH_ALLOCATION_VERSION_DETAIL });
  }
}

export function* copyAllocationVersion(param) {
  yield put({ type: ATTEMPT_COPY_ALLOCATION_VERSION });
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      copyVersion,
      param.payload.allocationId,
      param.payload.allocationVersionId,
      userId
    );
    yield put({
      type: SUCCEED_COPY_ALLOCATION_VERSION,
      data: {
        allocationVersionId: response.allocationVersionId,
      },
      meta: {
        analytics: {
          allocationSetId: param.payload.allocationId,
          versionId: param.payload.allocationVersionId,
          newVersionId: response.allocationVersionId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_COPY_ALLOCATION_VERSION,
      meta: {
        analytics: {
          allocationSetId: param.payload.allocationId,
          versionId: param.payload.allocationVersionId,
        },
      },
    });
  }
}

export function* getAllocationActivationLogs(param) {
  yield put({
    type: ATTEMPT_FETCH_ALLOCATION_ACTIVATION_LOGS,
    data: {
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
      sortBy: param.payload.sortBy,
      sortDir: param.payload.sortDir,
    },
  });
  try {
    const { data: response } = yield call(
      fetchAllocationActivationLogs,
      param.payload.allocationId,
      param.payload.currentPage,
      param.payload.pageSize,
      param.payload.sortBy,
      param.payload.sortDir
    );
    yield put({
      type: SUCCEED_FETCH_ALLOCATION_ACTIVATION_LOGS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ALLOCATION_ACTIVATION_LOGS,
    });
  }
}

export function* cancelScheduleAllocationVersionActivation(param) {
  yield put({ type: ATTEMPT_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION });
  const {
    allocationId,
    allocationPanelActivationLogId,
    allocationPanelActivationVersionId,
  } = param.payload;
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      fetchCancelAllocationActivationLog,
      allocationId,
      allocationPanelActivationLogId,
      userId
    );
    if (response.canceled) {
      yield put({
        type: SUCCEED_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
        data: {
          allocationPanelActivationLogId,
        },
        meta: {
          analytics: {
            versionId: allocationPanelActivationVersionId,
            logId: allocationPanelActivationLogId,
          },
        },
      });
    } else {
      yield put({
        type: OPEN_FAIL_ACTIVATION_CANCEL,
        payload: {
          openFailCancel: true,
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: allocationPanelActivationVersionId,
          logId: allocationPanelActivationLogId,
        },
      },
    });
  }
}

export function* setVersionsToCompare(param) {
  yield put({ type: SAVE_VERSIONS_TO_COMPARE, payload: param.payload });
  const count = param.payload.count;
  const message =
    count === 1
      ? "1 Version Selected | Select Another Version To Compare"
      : count + " Versions Selected";

  yield put({
    type: OPEN_ACTION_SNACKBAR,
    payload: {
      message,
      variant: "info",
      isIcon: true,
      actionType: count === 2 ? "compareVersions" : "",
      width: param.payload.width,
    },
  });
}

export function* clearVersionsToCompare() {
  yield put({ type: CLEAR_VERSIONS_TO_COMPARE });
}

export function* getAllocationVersionComparisonDetails(param) {
  yield put({
    type: ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS,
  });

  try {
    const { data: detail } = yield call(
      fetchAllocationVersionComparisonDetails,
      param.payload.allocationId,
      param.payload.versionsList
    );
    yield put({
      type: SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS,
      data: {
        ...detail,
      },
      meta: {
        analytics: {
          version1: param.payload.versionsList[0],
          version2: param.payload.versionsList[1],
        },
      },
    });
  } catch (e) {
    yield put({
      type: FAIL_FETCH_VERSIONS_COMPARISON_DETAILS,
      meta: {
        analytics: {
          version1: param.payload.versionsList[0],
          version2: param.payload.versionsList[1],
        },
      },
    });
  }
}

export function* removeAllocationLocation(param) {
  yield put({ type: ATTEMPT_REMOVE_ALLOCATION_LOCATION });
  try {
    const allocationFilterId = param.payload.allocationFilterId;
    const userId = yield select(getUserId);
    const { data: detail } = yield call(
      fetchRemoveAllocationLocation,
      param.payload.allocationId,
      param.payload.allocationRevId,
      allocationFilterId,
      param.payload.valfirmFilter,
      param.payload.locationFilter,
      param.payload.pageSize,
      userId
    );

    yield put({
      type: SUCCEED_REMOVE_ALLOCATION_LOCATION,
      data: {
        ...detail,
      },
      payload: {
        ...param.payload,
      },
      meta: {
        analytics: {
          versionId: param.payload.allocationRevId,
          location: param.payload.locationName,
        },
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully Removed " + param.payload.locationName,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_REMOVE_ALLOCATION_LOCATION,
      meta: {
        analytics: {
          versionId: param.payload.allocationRevId,
          location: param.payload.locationName,
        },
      },
    });
  }
}

export function* updateAllocationName(param) {
  yield put({ type: ATTEMPT_UPDATE_ALLOCATION_NAME });
  try {
    const allocationPanelId = param.payload.allocationPanelId;
    const name = param.payload.name;

    const { data: response } = yield call(
      updateAllocationNameAPI,
      allocationPanelId,
      name
    );

    if (response.isUpdated) {
      yield put({
        type: SUCCEED_UPDATE_ALLOCATION_NAME,
        data: {
          name,
        },
        meta: {
          analytics: {
            updatedName: name,
          },
        },
      });
    } else {
      yield put({
        type: FAIL_DUPLICATE_UPDATE_ALLOCATION_NAME,
        data: {
          message: response.message,
        },
        meta: {
          analytics: {
            updatedName: name,
          },
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_ALLOCATION_NAME,
      meta: {
        analytics: {
          updatedName: param.payload.name,
        },
      },
    });
  }
}

export function* updateAllocationVersionAllowAutoUpdate(param) {
  yield put({ type: ATTEMPT_UPDATE_ISSCORECARD_EDIT_ALLOCATION });
  const allocationId = param.payload.allocationId;
  const allocationVersionId = param.payload.allocationVersionId;
  const isScoreCard = param.payload.isScoreCard;
  try {
    const userId = yield select(getUserId);
    yield call(
      updateAllowAutoUpdate,
      allocationId,
      allocationVersionId,
      userId,
      isScoreCard
    );
    yield put({
      type: SUCCEED_UPDATE_ISSCORECARD_EDIT_ALLOCATION,
      data: {
        allocationVersionId,
        isScoreCard,
      },
      meta: {
        analytics: {
          versionId: allocationVersionId,
          scoreCard: isScoreCard,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_ISSCORECARD_EDIT_ALLOCATION,
      meta: {
        analytics: {
          versionId: allocationVersionId,
          scoreCard: isScoreCard,
        },
      },
    });
  }
}

export function* updateAllocationVersionMaxProviders(param) {
  yield put({ type: ATTEMPT_UPDATE_NOOFQUOTES_EDIT_ALLOCATION });
  const allocationId = param.payload.allocationId;
  const allocationVersionId = param.payload.allocationVersionId;
  const maxProviders = param.payload.noOfQuotes;
  try {
    const userId = yield select(getUserId);
    yield call(
      updateMaxProviders,
      allocationId,
      allocationVersionId,
      userId,
      maxProviders
    );
    yield put({
      type: SUCCEED_UPDATE_NOOFQUOTES_EDIT_ALLOCATION,
      data: {
        allocationVersionId,
        maxProviders,
      },
      meta: {
        analytics: {
          versionId: allocationVersionId,
          maxProviders: maxProviders,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_NOOFQUOTES_EDIT_ALLOCATION,
      meta: {
        analytics: {
          versionId: allocationVersionId,
          maxProviders: maxProviders,
        },
      },
    });
  }
}

export function* updateAllocationVersionQuote(param) {
  yield put({ type: ATTEMPT_UPDATE_ISQUOTE_EDIT_ALLOCATION });
  const allocationId = param.payload.allocationId;
  const allocationVersionId = param.payload.allocationVersionId;
  const isQuote = param.payload.isQuote;
  try {
    const userId = yield select(getUserId);

    yield call(updateQuote, allocationId, allocationVersionId, userId, isQuote);
    yield put({
      type: SUCCEED_UPDATE_ISQUOTE_EDIT_ALLOCATION,
      data: {
        allocationVersionId,
        isQuote,
      },
      meta: {
        analytics: {
          versionId: allocationVersionId,
          quote: isQuote,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_ISQUOTE_EDIT_ALLOCATION,
      meta: {
        analytics: {
          versionId: allocationVersionId,
          quote: isQuote,
        },
      },
    });
  }
}

export function* discardAllocationVersion(param) {
  yield put({ type: ATTEMPT_DISCARD_ALLOCATION_VERSION });
  try {
    const allocRevId = param.payload.allocRevId;
    const userId = yield select(getUserId);
    yield call(
      fetchDiscardAllocationVersion,
      param.payload.allocationId,
      allocRevId,
      userId
    );
    yield put({
      type: SUCCEED_DISCARD_ALLOCATION_VERSION,
      data: {
        allocRevId,
      },
      meta: {
        analytics: {
          versionId: allocRevId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_DISCARD_ALLOCATION_VERSION,
      meta: {
        analytics: {
          versionId: param.payload.allocRevId,
        },
      },
    });
  }
}

export function* resetPanelAllocation(param) {
  const { allocationId, allocationName, panelId, panelVersionId } =
    param.payload;
  yield put({
    type: ATTEMPT_RESET_PANEL_ALLOCATION,
  });

  try {
    const userId = yield select(getUserId);
    yield call(resetPanelAllocationAPI, panelId, panelVersionId, userId);
    yield put({
      type: SUCCEED_RESET_PANEL_ALLOCATION,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          allocationId,
          allocationName,
        },
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully Unlinked from Panel",
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_RESET_PANEL_ALLOCATION,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          allocationId,
          allocationName,
        },
      },
    });
  }
}

export function* getAssociatedPanels(param) {
  const { allocationId, currentPage, pageSize, sortBy, sortDir } =
    param.payload;
  yield put({
    type: ATTEMPT_FETCH_ASSOCIATED_PANELS,
    data: {
      currentPage,
      pageSize,
      sortBy,
      sortDir,
    },
  });
  try {
    const { data: response } = yield call(
      fetchAssociatedPanels,
      allocationId,
      currentPage,
      pageSize,
      sortBy,
      sortDir
    );
    yield put({
      type: SUCCEED_FETCH_ASSOCIATED_PANELS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ASSOCIATED_PANELS,
    });
  }
}

export function* filterNameChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_NAME_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchSearchSuggestion,
        data.text,
        "allocations"
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_NAME_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_NAME_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_NAME_SUGGESTION,
    });
  }
}

export function* filterLocationChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_LOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestion,
        data.text,
        data.countryId
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_LOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_LOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_LOCATION_SUGGESTION,
    });
  }
}

export function* filterValFirmChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_VALFIRM_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchPanelOrgSuggestion,
        data.text,
        "Valfirm"
      );
      yield put({
        type: SUCCEED_FETCH_FILTER_VALFIRM_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_VALFIRM_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_VALFIRM_SUGGESTION,
    });
  }
}

export function* acknowledgeScheduleAllocationVersionActivation(param) {
  yield put({
    type: ATTEMPT_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
  });
  const {
    allocationId,
    allocationPanelActivationLogId,
    allocationPanelActivationVersionId,
  } = param.payload;
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      acknowledgeAllocationActivationLog,
      allocationId,
      allocationPanelActivationLogId,
      userId
    );
    if (response.updated) {
      yield put({
        type: SUCCEED_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
        data: {
          allocationPanelActivationLogId,
        },
        meta: {
          analytics: {
            versionId: allocationPanelActivationVersionId,
            logId: allocationPanelActivationLogId,
          },
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: allocationPanelActivationVersionId,
          logId: allocationPanelActivationLogId,
        },
      },
    });
  }
}

export function* exportMultiAllocations(param) {
  yield put({ type: ATTEMPT_EXPORT_MULTI_ALLOCATIONS });

  try {
    const { data: response } = yield call(
      fetchExportMultiAllocations,
      param.payload.allocationIds
    );
    const timezone = moment.tz.guess();
    const currentDate = moment.tz(new Date(), timezone).format("DD/MM/YYYY");

    const fileName = "Multi Allocation Download " + currentDate + ".xlsx";

    // Create a temporary URL for the file blob
    const url = window.URL.createObjectURL(new Blob([response]));
    const element = document.createElement("a");
    element.style.display = "none";
    element.setAttribute("href", url);
    element.setAttribute("download", fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    yield put({
      type: SUCCEED_EXPORT_MULTI_ALLOCATIONS,
      meta: {
        analytics: {
          allocationSetIds: param.payload.allocationIds,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully downloaded " + fileName,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_EXPORT_MULTI_ALLOCATIONS,
      meta: {
        analytics: {
          allocationSetIds: param.payload.allocationIds,
        },
      },
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Failed download " + e.message,
        variant: "info",
        isIcon: true,
      },
    });
  }
}

export function* getAllocationVersionExistingValfirms(data) {
  yield put({
    type: ATTEMPT_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
  });

  try {
    const { data: list } = yield call(
      fetchExistingValfirms,
      data.allocationId,
      data.allocRevId
    );

    yield put({
      type: SUCCEED_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
      data: list,
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
    });
  }
}

export default function* sagaAllocation() {
  yield takeLatest(FETCH_ALLOCATIONS, getAllocations);
  yield takeLeading(FETCH_ALLOCATION_SET, getAllocationSet);
  yield takeLatest(FETCH_ALLOCATION_DETAIL, getAllocationDetail);
  yield takeLatest(UPDATE_LOCATION_TEXT_CHANGE, updateLocationTextChange);
  yield takeLatest(
    UPDATE_LOCATION_TEXT_CHANGE_NEW_ALLOCATION,
    updateLocationTextChangeNewAllocation
  );
  yield takeLatest(FETCH_ALLOCATION_VERSIONS, getAllocationVersions);
  yield takeLeading(EXPORT_ALLOCATION_VERSION, exportAllocationVersion);
  yield takeLeading(
    UPDATE_ALLOCATION_VERSION_NOTES,
    updateAllocationVersionNotes
  );
  yield takeLeading(UPDATE_ALLOCATION_DETAIL, updateAllocationDetail);
  yield takeLeading(IMPORT_ALLOCATION_VERSION, importAllocationVersion);
  yield takeLatest(FETCH_ALLOCATION_VERSION_DETAIL, getAllocationVersionDetail);
  yield takeLeading(COPY_ALLOCATION_VERSION, copyAllocationVersion);
  yield takeLatest(
    FETCH_ALLOCATION_ACTIVATION_LOGS,
    getAllocationActivationLogs
  );
  yield takeLeading(
    SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
    scheduleAllocationVersionActivation
  );
  yield takeLeading(
    CANCEL_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
    cancelScheduleAllocationVersionActivation
  );
  yield takeLeading(SET_VERSIONS_TO_COMPARE, setVersionsToCompare);
  yield takeLeading(RESET_VERSIONS_TO_COMPARE, clearVersionsToCompare);
  yield takeLeading(
    FETCH_VERSIONS_COMPARISON_DETAILS,
    getAllocationVersionComparisonDetails
  );
  yield takeLeading(REMOVE_ALLOCATION_LOCATION, removeAllocationLocation);
  yield takeLatest(
    FETCH_LATEST_ALLOCATION_ACTIVATION_LOG,
    getLatestAllocationActivationLog
  );
  yield takeLeading(CREATE_NEW_ALLOCATION, createNewAllocationSet);
  yield takeLeading(UPDATE_ALLOCATION_NAME, updateAllocationName);
  yield takeLeading(
    UPDATE_ISSCORECARD_EDIT_ALLOCATION,
    updateAllocationVersionAllowAutoUpdate
  );
  yield takeLeading(
    UPDATE_NOOFQUOTES_EDIT_ALLOCATION,
    updateAllocationVersionMaxProviders
  );
  yield takeLeading(
    UPDATE_ISQUOTE_EDIT_ALLOCATION,
    updateAllocationVersionQuote
  );
  yield takeLeading(DISCARD_ALLOCATION_VERSION, discardAllocationVersion);
  yield takeLeading(RESET_PANEL_ALLOCATION, resetPanelAllocation);
  yield takeLatest(FETCH_ASSOCIATED_PANELS, getAssociatedPanels);
  yield takeLatest(FILTER_NAME_CHANGE, filterNameChange);
  yield takeLatest(FILTER_LOCATION_CHANGE, filterLocationChange);
  yield takeLatest(FILTER_VALFIRM_CHANGE, filterValFirmChange);
  yield takeLeading(
    ACKNOWLEDGE_SCHEDULE_ALLOCATION_VERSION_ACTIVATION,
    acknowledgeScheduleAllocationVersionActivation
  );
  yield takeLeading(EXPORT_MULTI_ALLOCATIONS, exportMultiAllocations);
  yield takeLatest(
    FETCH_ALLOCATION_VERSION_EXISTING_VALFIRMS,
    getAllocationVersionExistingValfirms
  );
}
