import { segmentTrack } from "../../utilities/segmentation/segmentationUtils";

const excludeActions = ["persist/PERSIST", "persist/REHYDRATE"];
const allocationActions = [
  "ui/UPDATE_SELECTED_LOCATION",
  "ui/UPDATE_VALFIRM_ALLOCATION",
  "ui/DELETE_VALFIRM_ALLOCATION",
];

const feeActions = [
  "ui/UPDATE_SELECTED_FEE_LOCATION",
  "ui/UPDATE_FEE_BRACKET_FEE",
  "ui/DELETE_FEE_BRACKET_FEE",
];

export default (store) => (next) => (action) => {
  const actionType = action.type;
  if (excludeActions.indexOf(actionType) !== -1) {
    return next(action);
  }

  const analytics = action.meta?.analytics;
  if (analytics) {
    const state = store.getState();
    const id = state.tokenDetails.userContactDetails.userId;
    const username = state.tokenDetails.tokenContents.username;

    let stateData = {
      userId: username,
      id,
    };

    //add allocation details from state
    if (
      actionType.startsWith("allocation/") ||
      allocationActions.indexOf(actionType) !== -1
    ) {
      stateData = {
        ...stateData,
        allocationSetId: state.allocation.allocationSet?.allocPanelId,
        allocationSetName: state.allocation.allocationSet?.name,
      };
    } else if (
      actionType.startsWith("fee/") ||
      feeActions.indexOf(actionType) !== -1
    ) {
      stateData = {
        ...stateData,
        feeSetId: state.fee.feeSet?.feePanelId,
        feeSetName: state.fee.feeSet?.name,
      };
    } else if (actionType.startsWith("panel/")) {
      stateData = {
        ...stateData,
        panelSetId: state.panel.panelSet?.panelSetId,
        panelSetName: state.panel.panelSet?.name,
      };
    }
    segmentTrack(actionType, { ...stateData, ...analytics });
  }
  return next(action);
};
