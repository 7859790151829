export const BASE_URL = window.location.origin;

const CLAUD_BASE_URL = `${
  BASE_URL.startsWith("https://panel.corelogic.com.au")
    ? "https://auth.corelogic.asia"
    : "https://auth-uat.corelogic.asia"
}`;

const LOGOUT_REDIRECT_URL = `${
  BASE_URL.startsWith("https")
    ? BASE_URL
    : "https://panel-ui-dev.apps.ad.corelogic.asia"
}`;

export const CLAUD_LOGIN_URL = `${CLAUD_BASE_URL}/as/authorization.oauth2?response_type=code&client_id=QF8Qy38RmtZNnJCv8fGdDDGIRQK299G0&redirect_uri=${BASE_URL}/&scope=openid`;
export const CLAUD_LOGOUT_URL = `${CLAUD_BASE_URL}/idp/startSLO.ping?LogoutType=SyncOnly&TargetResource=${LOGOUT_REDIRECT_URL}`;
