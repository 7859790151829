import React, { useEffect, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { makeStyles } from "tss-react/mui";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab/";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { useTheme } from "@mui/material/styles";

interface FileUploadModalProps {
  title?: string;
  submitText?: string;
  open: boolean;
  onClose: () => void;
  onFileUpload: (file: File) => void;
  validationErrors?: string[];
  clearValidationError: () => void;
  isUploading: boolean;
}

export const handleClose = (event: any, reason: any, closeHandler: any) => {
  if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
    return;
  closeHandler();
};

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  title = "Import Allocation File",
  submitText = "Import Allocation File",
  open,
  onClose,
  onFileUpload,
  validationErrors,
  clearValidationError,
  isUploading,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    title: {
      borderBottom: `1px solid ${theme.colors.grey.lighter}`,
    },
    action: {
      borderTop: `1px solid ${theme.colors.grey.lighter}`,
    },
    icon: {
      width: "62.5px",
      height: "78px",
      color: `rgba(0,0,0,0.54)`,
    },
    smallIcon: {
      margin: "20px",
      Width: "19.23px",
      Height: "24px",
      color: "#3399FF",
    },
    smallIconError: {
      margin: "20px",
      Width: "19.23px",
      Height: "24px",
      color: theme.palette.error.main,
    },
    validationError: {
      color: "#FF9800",
      "& .title": {
        fontSize: "16px",
        fontFamily: "Poppins",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.15px",
        alignItems: "center",
        "& .icon": {
          verticalAlign: "middle",
          paddingRight: "5px",
        },
      },
      "& .content": {
        fontSize: "13px",
        fontFamily: "Poppins",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        "& .error": {
          margin: 0,
          whiteSpace: "pre-wrap",
        },
      },
    },
    submitButton: {
      "& .MuiLoadingButton-loadingIndicator": {
        width: "100%",
        justifyContent: "center",
      },
    },
  }));

  useEffect(() => {
    setSelectedFile(null);
  }, [open]);

  const dropzoneOptions: DropzoneOptions = {
    accept: {
      "application/vnd.ms-excel": [".xlsx"],
    }, // Specify accepted file types
    onDrop: (acceptedFiles) => {
      clearValidationError();
      setSelectedFile(acceptedFiles[0]);
    },
    multiple: false,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);
  const handleUpload = () => {
    if (selectedFile) {
      onFileUpload(selectedFile);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    onClose();
  };

  const { classes } = useStyles();

  return (
    <Dialog
      data-testid="upload-file-dialog"
      id="upload-file-dialog"
      open={open}
      onClose={(event, reason) => handleClose(event, reason, handleCancel)}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle
        data-testid="upload-file-dialog-title"
        id="upload-file-dialog-title"
        className={classes.title}
      >
        <div>
          <Typography variant="h6">{title}</Typography>
        </div>
      </DialogTitle>
      <DialogContent
        data-testid="upload-file-dialog-content"
        id="upload-file-dialog-content"
      >
        <div {...getRootProps()}>
          <input data-testid="drop-input" {...getInputProps()} />
          {selectedFile ? (
            <>
              <div style={{ textAlign: "center", padding: "20" }}>
                <span>
                  <InsertDriveFileIcon
                    fontSize="large"
                    className={
                      validationErrors && validationErrors.length > 0
                        ? classes.smallIconError
                        : classes.smallIcon
                    }
                  />
                </span>
                <br />
                <Button
                  id="btn-select-file"
                  data-testid="btn-select-file"
                  type="button"
                  variant="outlined"
                >
                  Re-Upload a File
                </Button>
                <span>
                  <Typography
                    variant="body1"
                    style={{
                      textAlign: "center",
                      paddingTop: "20px",
                      paddingBottom: "30px",
                    }}
                  >
                    Supported file types XLSX.
                  </Typography>
                </span>
                <p>
                  <b>{selectedFile.name} selected for import</b>
                </p>
              </div>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center", padding: "20" }}>
                <span>
                  <InsertDriveFileIcon
                    fontSize="large"
                    className={classes.icon}
                  />
                </span>
                <span>
                  <Typography
                    variant="h6"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    Click here to upload
                  </Typography>
                </span>
                <Button
                  id="btn-select-file"
                  data-testid="btn-select-file"
                  type="button"
                  variant="contained"
                >
                  Upload File
                </Button>
                <span>
                  <Typography
                    variant="body1"
                    style={{
                      textAlign: "center",
                      paddingTop: "20px",
                      paddingBottom: "30px",
                    }}
                  >
                    Supported file types XLSX. <br />
                    Importing a file creates a new editable version and replaces
                    existing.
                  </Typography>
                </span>
              </div>
            </>
          )}
        </div>
        {validationErrors && validationErrors.length > 0 && (
          <div data-testid="validation-errors" id="validation-errors">
            <div className={classes.validationError}>
              <div className="title">
                <ReportProblemOutlinedIcon className="icon" />
                {validationErrors.length} Errors found
              </div>
              <div className="content">
                {validationErrors.map((error, index) => {
                  return (
                    <p key={index} className="error">
                      {error}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button data-testid="btn-cancel" id="btn-cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          data-testid="btn-import"
          id="btn-import"
          className={classes.submitButton}
          onClick={handleUpload}
          loading={isUploading}
          loadingIndicator="Validating File…"
          variant="contained"
          disabled={!selectedFile}
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadModal;
