import * as React from "react";
import { InputAdornment, Popper, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { HTMLAttributes, ReactNode } from "react";

interface PanelExAutocompleteProps {
  id: string;
  options: any;
  value?: string | null;
  style?: any;
  isDisabled?: boolean;
  validInput?: boolean;
  showBorder?: boolean;
  showIcon?: boolean;
  placeholder?: string;
  anchor?: HTMLElement;
  popperWidth?: number;
  popperMaxHeight?: number;
  popperPlacement?: string;
  isFilterOptions?: boolean;
  getOptionLabel?: (value: any) => string;
  onChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: any
  ) => ReactNode;
  showResetIcon?: boolean;
  onReset?: () => void;
  showDropdownIcon?: boolean;
}

const filter = createFilterOptions<OptionType>();

const PanelExAutocomplete: React.FC<PanelExAutocompleteProps> = ({
  id,
  options,
  value,
  style,
  isDisabled = false,
  validInput = true,
  showBorder = true,
  showIcon = true,
  placeholder,
  anchor,
  popperWidth = 0,
  popperMaxHeight = 0,
  popperPlacement = "bottom",
  isFilterOptions = false,
  getOptionLabel,
  onChange,
  onInputChange,
  onKeyDown,
  renderOption,
  showResetIcon = false,
  onReset,
  showDropdownIcon = false,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    autocomplete: {
      padding: "0px",
      width: "359px",
      height: "38px",
      background: "#FFFFFF",
      border: "1px solid",
      borderColor: theme.colors.grey.light,
      borderRadius: "4px",
      "&:focus": {
        border: "2px solid #3399FF",
      },
      "& .MuiInputBase-root": {
        height: "36px",
      },
      "& .MuiOutlinedInput-root": {
        paddingRight: "9px !important",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        height: "38px",
        border: "0px",
      },
      "& #users-filter-input": {
        fontSize: "13px",
      },
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiTextField-root": {
        borderColor: theme.palette.error.main,
      },
    },
    noBorder: {
      "& .MuiTextField-root": {
        border: "0px",
      },
    },
    disabled: {
      borderColor: theme.colors.grey.lighter,
      "& .MuiInputAdornment-root": {
        color: theme.colors.grey.lighter,
      },
    },

    popper: {
      background: "transparent",
      border: "0px",
      paddingTop: "0px",
      margin: "0px",
      zIndex: "5",

      "& .MuiAutocomplete-paper": {
        background: "transparent",
        border: "0px",
      },
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
        width: `${popperWidth === 0 ? "100%" : popperWidth + "px"}`,

        "& .MuiAutocomplete-listbox": {
          maxHeight: `${popperMaxHeight > 0 && popperMaxHeight + "px"}`,
        },
      },
    },
    addCircle: {
      color: "#757575",
      "&.disabled": {
        color: "rgba(0, 0, 0, 0.12)",
      },
    },
  }));
  const { classes } = useStyles();
  const anchorRef = React.useRef();

  const [open, setOpen] = React.useState(false);

  return (
    <Autocomplete
      ref={anchorRef}
      id={`${id}-input`}
      data-testid={`${id}-input`}
      className={`${classes.autocomplete} ${validInput ? null : classes.error} 
      ${showBorder ? null : classes.noBorder} ${
        isDisabled ? classes.disabled : null
      }`}
      style={style}
      options={options}
      value={value}
      getOptionLabel={getOptionLabel}
      autoComplete={false}
      filterSelectedOptions
      filterOptions={(options, params) => {
        if (isFilterOptions && options.length > 0) {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) =>
              value === option.name.replace('Add "', "").replace('"', "")
          );
          if (value !== "" && !isExisting) {
            filtered.unshift({
              id: 0,
              name: `Add "${value}"`,
              inputValue: value,
            });
          }
          return filtered;
        } else {
          return options;
        }
      }}
      freeSolo
      clearOnBlur
      disablePortal
      disabled={isDisabled}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event: any, newValue: string | null) => {
        if (newValue != null) {
          onChange && onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        onInputChange && onInputChange(newInputValue);
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          id={`${id}-textfield`}
          data-testid={`${id}-textfield`}
          fullWidth
          placeholder={placeholder}
          variant={`${showIcon ? "outlined" : "standard"}`}
          onClick={(e: any) => e.stopPropagation()}
          onKeyDown={(event: any) => {
            if (event.keyCode == 13) {
              //if enter key pressed
              setOpen(false);
              const input = event.target.value;
              onKeyDown && onKeyDown(input);
            }
          }}
          slotProps={{
            ...params.slotProps,
            input: {
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {showIcon && (
                    <SearchIcon
                      id={`${id}-textfield-start-icon`}
                      data-testid={`${id}-textfield-start-icon`}
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {showResetIcon && (
                    <CancelIcon
                      id={`${id}-textfield-end-icon`}
                      data-testid={`${id}-textfield-end-icon`}
                      cursor={"pointer"}
                      sx={{ fontSize: "18px" }}
                      onClick={() => {
                        if (onReset !== null && onReset !== undefined) {
                          onReset();
                        }
                      }}
                    />
                  )}
                  {showDropdownIcon && (
                    <ArrowDropDownIcon
                      id={`${id}-textfield-down-icon`}
                      data-testid={`${id}-textfield-down-icon`}
                      cursor={"pointer"}
                      sx={{ fontSize: "18px" }}
                    />
                  )}
                </InputAdornment>
              ),
            },
          }}
        />
      )}
      renderOption={renderOption}
      PopperComponent={(props: any) => (
        <Popper
          data-testid={`${id}-popper`}
          {...props}
          className={classes.popper}
          placement={popperPlacement}
          anchorEl={anchor || anchorRef.current}
          modifiers={[
            {
              name: "preventOverflow",
              options: {
                padding: 25,
              },
            },
          ]}
        ></Popper>
      )}
    />
  );
};

interface OptionType {
  inputValue?: string | null;
  id: number;
  name?: string;
}

export default PanelExAutocomplete;
