import {
  call,
  delay,
  put,
  takeLatest,
  takeLeading,
  select,
} from "redux-saga/effects";
import { getUserId } from "./selectors";
import {
  createNewPanelSetAPI,
  fetchAllocationSuggestion,
  fetchPanels,
  fetchPanelSet,
  fetchPanelVersionDetail,
  updatePanelSet,
  updatePanelAllocationAPI,
  fetchPanelActivationLogs,
  fetchLatestPanelActivationLog,
  updatePanelFeeAPI,
  fetchFeeSuggestion,
  fetchPanelVersions,
  fetchUpdatePanelVersionNotes,
  copyVersion,
  schedulePanelVersionActivationApi,
  cancelPanelActivationLog,
  fetchDiscardPanelVersion,
  fetchPanelVersionComparisonDetails,
  acknowledgePanelActivationLog,
  exportPanels,
  fetchBulkUpdateInfo,
} from "../apis/apiPanel";
import moment from "moment-timezone";
import {
  CLOSE_ACTION_SNACKBAR,
  OPEN_ACTION_SNACKBAR,
  OPEN_FAIL_ACTIVATION_CANCEL,
  OPEN_SNACKBAR,
} from "./sagaUI";

import {
  fetchLocationSuggestion,
  fetchPanelOrgSuggestion,
  fetchPropTypeSuggestion,
  fetchSearchSuggestion,
  fetchServiceTypeSuggestion,
  fetchValfirmSuggestion,
  fetchActiveValfirmSuggestion,
} from "../apis/api";
import {
  fetchAllocationsLocations,
  fetchAssociatedPanels,
  fetchBulkUpdatePreviewData,
  fetchExistingValfirmsByRevIds,
  saveBulkUpdate,
} from "../apis/apiAllocation";

export const RESET_PAGINATION = "panel/RESET_PAGINATION";
export const CREATE_PANEL_SET = "panel/CREATE_PANEL_SET";
export const ATTEMPT_CREATE_PANEL_SET = "panel/ATTEMPT_CREATE_PANEL_SET";
export const SUCCEED_CREATE_PANEL_SET = "panel/SUCCEED_CREATE_PANEL_SET";
export const FAIL_CREATE_PANEL_SET = "panel/FAIL_CREATE_PANEL_SET";
export const ERROR_CREATE_PANEL_SET = "panel/ERROR_CREATE_PANEL_SET";

export const CHANGE_PANEL_TAB = "panel/CHANGE_PANEL_TAB";

export const FETCH_PANEL_SET = "panel/FETCH_PANEL_SET";
export const ATTEMPT_FETCH_PANEL_SET = "panel/ATTEMPT_FETCH_PANEL_SET";
export const SUCCEED_FETCH_PANEL_SET = "panel/SUCCEED_FETCH_PANEL_SET";
export const FAIL_FETCH_PANEL_SET = "panel/FAIL_FETCH_PANEL_SET";

export const FETCH_PANEL_VERSION_DETAIL = "panel/FETCH_PANEL_VERSION_DETAIL";
export const ATTEMPT_FETCH_PANEL_VERSION_DETAIL =
  "panel/ATTEMPT_FETCH_PANEL_VERSION_DETAIL";
export const SUCCEED_FETCH_PANEL_VERSION_DETAIL =
  "panel/SUCCEED_FETCH_PANEL_VERSION_DETAIL";
export const FAIL_FETCH_PANEL_VERSION_DETAIL =
  "panel/FAIL_FETCH_PANEL_VERSION_DETAIL";

export const FETCH_PANELS = "panel/FETCH_PANELS";
export const ATTEMPT_FETCH_PANELS = "panel/ATTEMPT_FETCH_PANELS";
export const SUCCEED_FETCH_PANELS = "panel/SUCCEED_FETCH_PANELS";
export const FAIL_FETCH_PANELS = "panel/FAIL_FETCH_PANELS";

export const SHOW_PANEL_SET_FORM = "panel/SHOW_PANEL_SET_FORM";
export const EDIT_PANEL_SET = "panel/EDIT_PANEL_SET";
export const ATTEMPT_EDIT_PANEL_SET = "panel/ATTEMPT_EDIT_PANEL_SET";
export const SUCCEED_EDIT_PANEL_SET = "panel/SUCCEED_EDIT_PANEL_SET";
export const FAIL_EDIT_PANEL_SET = "panel/FAIL_EDIT_PANEL_SET";
export const ERROR_EDIT_PANEL_SET = "panel/ERROR_EDIT_PANEL_SET";

export const ADD_PROP_TYPE_PANEL_FORM = "panel/ADD_PROP_TYPE_PANEL_FORM";
export const DELETE_PROP_TYPE_PANEL_FORM = "panel/DELETE_PROP_TYPE_PANEL_FORM";
export const ADD_SERVICE_TYPE_PANEL_FORM = "panel/ADD_SERVICE_TYPE_PANEL_FORM";
export const DELETE_SERVICE_TYPE_PANEL_FORM =
  "panel/DELETE_SERVICE_TYPE_PANEL_FORM";
export const ADD_FUNDER_PANEL_FORM = "panel/ADD_FUNDER_PANEL_FORM";
export const ADD_CLIENT_PANEL_FORM = "panel/ADD_CLIENT_PANEL_FORM";
export const DELETE_FUNDER_PANEL_FORM = "panel/DELETE_FUNDER_PANEL_FORM";
export const DELETE_CLIENT_PANEL_FORM = "panel/DELETE_CLIENT_PANEL_FORM";
export const UPDATE_PANEL_NAME_PANEL_FORM =
  "panel/UPDATE_PANEL_NAME_PANEL_FORM";
export const UPDATE_PANEL_NOTES_PANEL_FORM =
  "panel/UPDATE_PANEL_NOTES_PANEL_FORM";
export const UPDATE_PANEL_TYPE_PANEL_FORM =
  "panel/UPDATE_PANEL_TYPE_PANEL_FORM";
export const UPDATE_PANEL_ORDER_PANEL_FORM =
  "panel/UPDATE_PANEL_ORDER_PANEL_FORM";
export const UPDATE_USE_RULEX_PANEL_FORM = "panel/UPDATE_USE_RULEX_PANEL_FORM";
export const UPDATE_PANEL_IN_USE_PANEL_FORM =
  "panel/UPDATE_PANEL_IN_USE_PANEL_FORM";
export const UPDATE_BRACKET_MIN_PANEL_FORM =
  "panel/UPDATE_BRACKET_MIN_PANEL_FORM";
export const UPDATE_BRACKET_MAX_PANEL_FORM =
  "panel/UPDATE_BRACKET_MAX_PANEL_FORM";

export const UPDATE_ALLOCATION_TEXT_CHANGE =
  "panel/UPDATE_ALLOCATION_TEXT_CHANGE";
export const ATTEMPT_FETCH_ALLOCATION_SUGGESTION =
  "panel/ATTEMPT_FETCH_ALLOCATION_SUGGESTION";
export const SUCCEED_FETCH_ALLOCATION_SUGGESTION =
  "panel/SUCCEED_FETCH_ALLOCATION_SUGGESTION";
export const FAIL_FETCH_ALLOCATION_SUGGESTION =
  "panel/FAIL_FETCH_ALLOCATION_SUGGESTION";
export const CLEAR_ALLOCATION_SUGGESTION =
  "panel/CLEAR_FETCH_ALLOCATION_SUGGESTION";

export const UPDATE_PANEL_ALLOCATION = "ui/UPDATE_PANEL_ALLOCATION";
export const ATTEMPT_UPDATE_PANEL_ALLOCATION =
  "ui/ATTEMPT_UPDATE_PANEL_ALLOCATION";
export const SUCCEED_UPDATE_PANEL_ALLOCATION =
  "ui/SUCCEED_UPDATE_PANEL_ALLOCATION";
export const FAIL_UPDATE_PANEL_ALLOCATION = "ui/FAIL_UPDATE_PANEL_ALLOCATION";

export const FETCH_PANEL_ACTIVATION_LOGS = "panel/FETCH_PANEL_ACTIVATION_LOGS";
export const ATTEMPT_FETCH_PANEL_ACTIVATION_LOGS =
  "panel/ATTEMPT_FETCH_PANEL_ACTIVATION_LOGS";
export const SUCCEED_FETCH_PANEL_ACTIVATION_LOGS =
  "panel/SUCCEED_FETCH_PANEL_ACTIVATION_LOGS";
export const FAIL_FETCH_PANEL_ACTIVATION_LOGS =
  "panel/FAIL_FETCH_PANEL_ACTIVATION_LOGS";
export const FETCH_LATEST_PANEL_ACTIVATION_LOG =
  "panel/FETCH_LATEST_PANEL_ACTIVATION_LOG";
export const ATTEMPT_FETCH_LATEST_PANEL_ACTIVATION_LOG =
  "panel/ATTEMPT_FETCH_LATEST_PANEL_ACTIVATION_LOG";
export const SUCCEED_FETCH_LATEST_PANEL_ACTIVATION_LOG =
  "panel/SUCCEED_FETCH_LATEST_PANEL_ACTIVATION_LOG";
export const FAIL_FETCH_LATEST_PANEL_ACTIVATION_LOG =
  "panel/FAIL_FETCH_LATEST_PANEL_ACTIVATION_LOG";
export const CHANGE_PANEL_VERSION_ID = "panel/CHANGE_PANEL_VERSION_ID";

export const UPDATE_FEE_TEXT_CHANGE = "panel/UPDATE_FEE_TEXT_CHANGE";
export const ATTEMPT_FETCH_FEE_SUGGESTION =
  "panel/ATTEMPT_FETCH_FEE_SUGGESTION";
export const SUCCEED_FETCH_FEE_SUGGESTION =
  "panel/SUCCEED_FETCH_FEE_SUGGESTION";
export const FAIL_FETCH_FEE_SUGGESTION = "panel/FAIL_FETCH_FEE_SUGGESTION";
export const CLEAR_FEE_SUGGESTION = "panel/CLEAR_FETCH_FEE_SUGGESTION";

export const UPDATE_PANEL_FEE = "ui/UPDATE_PANEL_FEE";
export const ATTEMPT_UPDATE_PANEL_FEE = "ui/ATTEMPT_UPDATE_PANEL_FEE";
export const SUCCEED_UPDATE_PANEL_FEE = "ui/SUCCEED_UPDATE_PANEL_FEE";
export const FAIL_UPDATE_PANEL_FEE = "ui/FAIL_UPDATE_PANEL_FEE";

export const FETCH_PANEL_VERSIONS = "panel/FETCH_PANEL_VERSIONS";
export const ATTEMPT_FETCH_PANEL_VERSIONS =
  "panel/ATTEMPT_FETCH_PANEL_VERSIONS";
export const SUCCEED_FETCH_PANEL_VERSIONS =
  "panel/SUCCEED_FETCH_PANEL_VERSIONS";
export const FAIL_FETCH_PANEL_VERSIONS = "panel/FAIL_FETCH_PANEL_VERSIONS";

export const UPDATE_PANEL_VERSION_NOTES = "panel/UPDATE_PANEL_VERSION_NOTES";
export const ATTEMPT_UPDATE_PANEL_VERSION_NOTES =
  "panel/ATTEMPT_UPDATE_PANEL_VERSION_NOTES";
export const SUCCEED_UPDATE_PANEL_VERSION_NOTES =
  "panel/SUCCEED_UPDATE_PANEL_VERSION_NOTES";
export const FAIL_UPDATE_PANEL_VERSION_NOTES =
  "panel/FAIL_UPDATE_PANEL_VERSION_NOTES";

export const COPY_PANEL_VERSION = "panel/COPY_PANEL_VERSION";
export const ATTEMPT_COPY_PANEL_VERSION = "panel/ATTEMPT_COPY_PANEL_VERSION";
export const SUCCEED_COPY_PANEL_VERSION = "panel/SUCCEED_COPY_PANEL_VERSION";
export const FAIL_COPY_PANEL_VERSION = "panel/FAIL_COPY_PANEL_VERSION";

export const SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/SCHEDULE_PANEL_VERSION_ACTIVATION";
export const ATTEMPT_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/ATTEMPT_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const SUCCEED_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/SUCCEED_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const FAIL_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/FAIL_SCHEDULE_PANEL_VERSION_ACTIVATION";

export const CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const ATTEMPT_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/ATTEMPT_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const SUCCEED_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/SUCCEED_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const FAIL_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/FAIL_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION";

export const DISCARD_PANEL_VERSION = "panel/DISCARD_PANEL_VERSION";
export const ATTEMPT_DISCARD_PANEL_VERSION =
  "panel/ATTEMPT_DISCARD_PANEL_VERSION";
export const SUCCEED_DISCARD_PANEL_VERSION =
  "panel/SUCCEED_DISCARD_PANEL_VERSION";
export const FAIL_DISCARD_PANEL_VERSION = "panel/FAIL_DISCARD_PANEL_VERSION";

export const SET_VERSIONS_TO_COMPARE = "panel/SET_VERSIONS_TO_COMPARE";
export const SAVE_VERSIONS_TO_COMPARE = "panel/SAVE_VERSIONS_TO_COMPARE";
export const RESET_VERSIONS_TO_COMPARE = "panel/RESET_VERSIONS_TO_COMPARE";
export const CLEAR_VERSIONS_TO_COMPARE = "panel/CLEAR_VERSIONS_TO_COMPARE";
export const SHOW_VERSIONS_COMPARISON = "panel/SHOW_VERSIONS_COMPARISON";

export const FETCH_VERSIONS_COMPARISON_DETAILS =
  "panel/FETCH_VERSIONS_COMPARISON_DETAILS";
export const ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS =
  "panel/ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS";
export const SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS =
  "panel/SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS";
export const FAIL_FETCH_VERSIONS_COMPARISON_DETAILS =
  "panel/FAIL_FETCH_VERSIONS_COMPARISON_DETAILS";

export const FILTER_NAME_CHANGE = "panel/FILTER_NAME_CHANGE";
export const ATTEMPT_FETCH_FILTER_NAME_SUGGESTION =
  "panel/ATTEMPT_FETCH_FILTER_NAME_SUGGESTION";
export const SUCCEED_FETCH_FILTER_NAME_SUGGESTION =
  "panel/SUCCEED_FETCH_FILTER_NAME_SUGGESTION";
export const FAIL_FETCH_FILTER_NAME_SUGGESTION =
  "panel/FAIL_FETCH_FILTER_NAME_SUGGESTION";
export const CLEAR_FILTER_NAME_SUGGESTION =
  "panel/CLEAR_FILTER_NAME_SUGGESTION";

export const CLEAR_ALL_FILTER = "panel/CLEAR_ALL_FILTER";
export const OPEN_FILTER = "panel/OPEN_FILTER";
export const COPY_FILTER = "panel/COPY_FILTER";

export const UPDATE_ORG_TEXT_CHANGE_FIlTER =
  "panel/UPDATE_ORG_TEXT_CHANGE_FIlTER";

export const ATTEMPT_FETCH_ORG_SUGGESTION_FILTER =
  "panel/ATTEMPT_FETCH_ORG_SUGGESTION_FILTER";
export const SUCCEED_FETCH_ORG_SUGGESTION_FILTER =
  "panel/SUCCEED_FETCH_ORG_SUGGESTION_FILTER";
export const FAIL_FETCH_ORG_SUGGESTION_FILTER =
  "panel/FAIL_FETCH_ORG_SUGGESTION_FILTER";
export const CLEAR_ORG_SUGGESTION_FILTER = "panel/CLEAR_ORG_SUGGESTION_FILTER";

export const UPDATE_PANEL_TYPE_FILTER = "panel/UPDATE_PANEL_TYPE_FILTER";
export const UPDATE_PANEL_STATUS_FILTER = "panel/UPDATE_PANEL_STATUS_FILTER";

export const ADD_VALFIRM_FILTER = "panel/ADD_VALFIRM_FILTER";
export const DELETE_VALFIRM_FILTER = "panel/DELETE_VALFIRM_FILTER";
export const ADD_CLIENT_FILTER = "panel/ADD_CLIENT_FILTER";
export const DELETE_CLIENT_FILTER = "panel/DELETE_CLIENT_FILTER";
export const ADD_FUNDER_FILTER = "panel/ADD_FUNDER_FILTER";
export const DELETE_FUNDER_FILTER = "panel/DELETE_FUNDER_FILTER";

export const UPDATE_PROP_TYPE_TEXT_CHANGE_FILTER =
  "panel/UPDATE_PROP_TYPE_TEXT_CHANGE_FILTER";
export const ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_FILTER =
  "panel/ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_FILTER";
export const SUCCEED_FETCH_PROP_TYPE_SUGGESTION_FILTER =
  "panel/SUCCEED_FETCH_PROP_TYPE_SUGGESTION_FILTER";
export const CLEAR_PROP_TYPE_SUGGESTION_FILTER =
  "panel/CLEAR_PROP_TYPE_SUGGESTION_FILTER";
export const FAIL_FETCH_PROP_TYPE_SUGGESTION_FILTER =
  "panel/FAIL_FETCH_PROP_TYPE_SUGGESTION_FILTER";

export const UPDATE_SERVICE_TYPE_TEXT_CHANGE_FILTER =
  "panel/UPDATE_SERVICE_TYPE_TEXT_CHANGE_FILTER";
export const ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_FILTER =
  "panel/ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_FILTER";
export const SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_FILTER =
  "panel/SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_FILTER";
export const CLEAR_SERVICE_TYPE_SUGGESTION_FILTER =
  "panel/CLEAR_SERVICE_TYPE_SUGGESTION_FILTER";
export const FAIL_FETCH_SERVICE_TYPE_SUGGESTION_FILTER =
  "panel/FAIL_FETCH_SERVICE_TYPE_SUGGESTION_FILTER";

export const ADD_PROP_TYPE_FILTER = "panel/ADD_PROP_TYPE_FILTER";
export const DELETE_PROP_TYPE_FILTER = "panel/DELETE_PROP_TYPE_FILTER";
export const ADD_SERVICE_TYPE_FILTER = "panel/ADD_SERVICE_TYPE_FILTER";
export const DELETE_SERVICE_TYPE_FILTER = "panel/DELETE_SERVICE_TYPE_FILTER";
export const UPDATE_BRACKET_MIN_FILTER = "panel/UPDATE_BRACKET_MIN_FILTER";
export const UPDATE_BRACKET_MAX_FILTER = "panel/UPDATE_BRACKET_MAX_FILTER";
export const UPDATE_SHOW_MORE_FILTER = "panel/UPDATE_SHOW_MORE_FILTER";

export const FILTER_ALLOCATION_NAME_CHANGE =
  "panel/FILTER_ALLOCATION_NAME_CHANGE";
export const ATTEMPT_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION =
  "panel/ATTEMPT_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION";
export const SUCCEED_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION =
  "panel/SUCCEED_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION";
export const FAIL_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION =
  "panel/FAIL_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION";
export const CLEAR_FILTER_ALLOCATION_NAME_SUGGESTION =
  "panel/CLEAR_FILTER_ALLOCATION_NAME_SUGGESTION";
export const CHANGE_ALLOCATION_FILTER_COUNTRY =
  "panel/CHANGE_ALLOCATION_FILTER_COUNTRY";
export const FILTER_ALLOCATION_LOCATION_CHANGE =
  "panel/FILTER_ALLOCATION_LOCATION_CHANGE";
export const ATTEMPT_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION =
  "panel/ATTEMPT_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION";
export const SUCCEED_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION =
  "panel/SUCCEED_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION";
export const FAIL_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION =
  "panel/FAIL_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION";
export const CLEAR_FILTER_ALLOCATION_LOCATION_SUGGESTION =
  "panel/CLEAR_FILTER_ALLOCATION_LOCATION_SUGGESTION";
export const ADD_ALLOCATION_LOCATION_FILTER =
  "panel/ADD_ALLOCATION_LOCATION_FILTER";
export const DELETE_ALLOCATION_LOCATION_FILTER =
  "panel/DELETE_ALLOCATION_LOCATION_FILTER";

export const FILTER_FEE_NAME_CHANGE = "panel/FILTER_FEE_NAME_CHANGE";
export const ATTEMPT_FETCH_FILTER_FEE_NAME_SUGGESTION =
  "fee/ATTEMPT_FETCH_FILTER_FEE_NAME_SUGGESTION";
export const SUCCEED_FETCH_FILTER_FEE_NAME_SUGGESTION =
  "fee/SUCCEED_FETCH_FILTER_FEE_NAME_SUGGESTION";
export const FAIL_FETCH_FILTER_FEE_NAME_SUGGESTION =
  "fee/FAIL_FETCH_FILTER_FEE_NAME_SUGGESTION";
export const CLEAR_FILTER_FEE_NAME_SUGGESTION =
  "fee/CLEAR_FILTER_FEE_NAME_SUGGESTION";
export const CHANGE_FEE_FILTER_COUNTRY = "panel/CHANGE_FEE_FILTER_COUNTRY";
export const FILTER_FEE_LOCATION_CHANGE = "panel/FILTER_FEE_LOCATION_CHANGE";
export const ATTEMPT_FETCH_FILTER_FEE_LOCATION_SUGGESTION =
  "fee/ATTEMPT_FETCH_FILTER_FEE_LOCATION_SUGGESTION";
export const SUCCEED_FETCH_FILTER_FEE_LOCATION_SUGGESTION =
  "fee/SUCCEED_FETCH_FILTER_FEE_LOCATION_SUGGESTION";
export const FAIL_FETCH_FILTER_FEE_LOCATION_SUGGESTION =
  "fee/FAIL_FETCH_FILTER_FEE_LOCATION_SUGGESTION";
export const CLEAR_FILTER_FEE_LOCATION_SUGGESTION =
  "fee/CLEAR_FILTER_FEE_LOCATION_SUGGESTION";
export const ADD_FEE_LOCATION_FILTER = "panel/ADD_FEE_LOCATION_FILTER";
export const DELETE_FEE_LOCATION_FILTER = "panel/DELETE_FEE_LOCATION_FILTER";

export const ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const ATTEMPT_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/ATTEMPT_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const SUCCEED_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/SUCCEED_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION";
export const FAIL_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION =
  "panel/FAIL_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION";

export const EXPORT_PANELS = "panel/EXPORT_PANELS";
export const ATTEMPT_EXPORT_PANELS = "panel/ATTEMPT_EXPORT_PANELS";
export const SUCCEED_EXPORT_PANELS = "panel/SUCCEED_EXPORT_PANELS";
export const FAIL_EXPORT_PANELS = "panel/FAIL_EXPORT_PANELS";

export const SET_PANEL_TO_BULK_UPDATE = "panel/SET_PANEL_TO_BULK_UPDATE";
export const SAVE_PANEL_TO_BULK_UPDATE = "panel/SAVE_PANEL_TO_BULK_UPDATE";
export const OPEN_BULK_UPDATE = "panel/OPEN_BULK_UPDATE";
export const BULK_UPDATE_SELECT_VERSION = "panel/BULK_UPDATE_SELECT_VERSION";
export const BULK_UPDATE_SELECT_LOCATIONS =
  "panel/BULK_UPDATE_SELECT_LOCATIONS";
export const BULK_UPDATE_BACK_TO_PREVIOUS =
  "panel/BULK_UPDATE_BACK_TO_PREVIOUS";
export const CANCEL_BULK_UPDATE = "panel/CANCEL_BULK_UPDATE";
export const SET_BULK_UPDATE_CHANGE_TYPE = "panel/SET_BULK_UPDATE_CHANGE_TYPE";
export const SET_SELECTED_PANEL = "panel/SET_SELECTED_PANEL";

export const BULK_UPDATE_PREVIEW_DATA = "panel/BULK_UPDATE_PREVIEW_DATA";
export const ATTEMPT_BULK_UPDATE_PREVIEW_DATA =
  "panel/ATTEMPT_BULK_UPDATE_PREVIEW_DATA";
export const SUCCEED_BULK_UPDATE_PREVIEW_DATA =
  "panel/SUCCEED_BULK_UPDATE_PREVIEW_DATA";
export const FAIL_BULK_UPDATE_PREVIEW_DATA =
  "panel/FAIL_BULK_UPDATE_PREVIEW_DATA";

export const FETCH_BULK_UPDATE_EXISTING_VALFIRMS =
  "panel/FETCH_BULK_UPDATE_EXISTING_VALFIRMS";
export const ATTEMPT_FETCH_BULK_UPDATE_EXISTING_VALFIRMS =
  "panel/ATTEMPT_FETCH_BULK_UPDATE_EXISTING_VALFIRMS";
export const SUCCEED_FETCH_BULK_UPDATE_EXISTING_VALFIRMS =
  "panel/SUCCEED_FETCH_BULK_UPDATE_EXISTING_VALFIRMS";
export const FAIL_FETCH_BULK_UPDATE_EXISTING_VALFIRMS =
  "panel/FAIL_FETCH_BULK_UPDATE_EXISTING_VALFIRMS";

export const REPLACE_VALFIRM_FETCH_EXISTING_VALFIRM_SUGGESTION =
  "panel/REPLACE_VALFIRM_FETCH_EXISTING_VALFIRM_SUGGESTION";
export const REPLACE_VALFIRM_SET_EXISTING_VALFIRM =
  "panel/REPLACE_VALFIRM_SET_EXISTING_VALFIRM";

export const REPLACE_VALFIRM_NEW_VALFIRM_TEXT_CHANGE =
  "panel/REPLACE_VALFIRM_NEW_VALFIRM_TEXT_CHANGE";
export const ATTEMPT_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION =
  "panel/ATTEMPT_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION";
export const SUCCEED_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION =
  "panel/SUCCEED_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION";
export const CLEAR_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION =
  "panel/CLEAR_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION";
export const FAIL_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION =
  "panel/FAIL_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION";
export const REPLACE_VALFIRM_SET_NEW_VALFIRM =
  "panel/REPLACE_VALFIRM_SET_NEW_VALFIRM";

export const FETCH_BULK_UPDATE_INFO = "panel/FETCH_BULK_UPDATE_INFO";
export const ATTEMPT_FETCH_BULK_UPDATE_INFO =
  "panel/ATTEMPT_FETCH_BULK_UPDATE_INFO";
export const SUCCEED_FETCH_BULK_UPDATE_INFO =
  "panel/SUCCEED_FETCH_BULK_UPDATE_INFO";
export const FAIL_FETCH_BULK_UPDATE_INFO = "panel/FAIL_FETCH_BULK_UPDATE_INFO";

export const UPDATE_BULK_UPDATE_SELECTED_VERSION =
  "panel/UPDATE_BULK_UPDATE_SELECTED_VERSION";

export const SET_DISTRIBUTION_METHOD = "panel/SET_DISTRIBUTION_METHOD";
export const SET_IS_INCLUDE_BACKUP = "panel/SET_IS_INCLUDE_BACKUP";

export const ADD_VALFIRM_SET_ALLOCATION = "panel/ADD_VALFIRM_SET_ALLOCATION";
export const ADD_VALFIRM_TEXT_CHANGE = "panel/ADD_VALFIRM_TEXT_CHANGE";
export const ATTEMPT_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION =
  "panel/ATTEMPT_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION";
export const SUCCEED_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION =
  "panel/SUCCEED_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION";
export const CLEAR_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION =
  "panel/CLEAR_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION";
export const FAIL_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION =
  "panel/FAIL_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION";
export const ADD_VALFIRM_SET_VALFIRM = "panel/ADD_VALFIRM_SET_VALFIRM";

export const REMOVE_VALFIRM_FETCH_SUGGESTION =
  "panel/REMOVE_VALFIRM_FETCH_SUGGESTION";
export const REMOVE_VALFIRM_SET_VALFIRM = "panel/REMOVE_VALFIRM_SET_VALFIRM";

export const CHANGE_ALLOCATION_FETCH_VALFIRM_SUGGESTION =
  "panel/CHANGE_ALLOCATION_FETCH_VALFIRM_SUGGESTION";
export const CHANGE_ALLOCATION_SET_VALFIRM =
  "panel/CHANGE_ALLOCATION_SET_VALFIRM";
export const CHANGE_ALLOCATION_SET_BY = "panel/CHANGE_ALLOCATION_SET_BY";
export const CHANGE_ALLOCATION_SET_ALLOCATION =
  "panel/CHANGE_ALLOCATION_SET_ALLOCATION";

export const BULK_UPDATE_SAVE_AS_DRAFT = "panel/BULK_UPDATE_SAVE_AS_DRAFT";
export const ATTEMPT_BULK_UPDATE_SAVE_AS_DRAFT =
  "panel/ATTEMPT_BULK_UPDATE_SAVE_AS_DRAFT";
export const SUCCEED_BULK_UPDATE_SAVE_AS_DRAFT =
  "panel/SUCCEED_BULK_UPDATE_SAVE_AS_DRAFT";
export const CLEAR_BULK_UPDATE_SAVE_AS_DRAFT =
  "panel/CLEAR_BULK_UPDATE_SAVE_AS_DRAFT";
export const FAIL_BULK_UPDATE_SAVE_AS_DRAFT =
  "panel/FAIL_BULK_UPDATE_SAVE_AS_DRAFT";

export const FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS =
  "allocation/FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS";
export const ATTEMPT_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS =
  "allocation/ATTEMPT_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS";
export const SUCCEED_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS =
  "allocation/SUCCEED_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS";
export const FAIL_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS =
  "allocation/FAIL_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS";

export const UPDATE_BULK_UPDATE_LOCATION_TEXT =
  "panel/UPDATE_BULK_UPDATE_LOCATION_TEXT";
export const ADD_BULK_UPDATE_LOCATION = "panel/ADD_BULK_UPDATE_LOCATION";
export const DELETE_BULK_UPDATE_LOCATION = "panel/DELETE_BULK_UPDATE_LOCATION";
export const ADD_ALL_BULK_UPDATE_LOCATION =
  "panel/ADD_ALL_BULK_UPDATE_LOCATION";
export const DELETE_ALL_BULK_UPDATE_LOCATION =
  "panel/DELETE_ALL_BULK_UPDATE_LOCATION";

export const FETCH_ASSOCIATED_PANELS = "panel/FETCH_ASSOCIATED_PANELS";
export const ATTEMPT_FETCH_ASSOCIATED_PANELS =
  "panel/ATTEMPT_FETCH_ASSOCIATED_PANELS";
export const SUCCEED_FETCH_ASSOCIATED_PANELS =
  "panel/SUCCEED_FETCH_ASSOCIATED_PANELS";
export const FAIL_FETCH_ASSOCIATED_PANELS =
  "panel/FAIL_FETCH_ASSOCIATED_PANELS";

export const ADD_PREVIEW_PANEL_ID = "panel/ADD_PREVIEW_PANEL_ID";

export const OPEN_ASSOCIATED_PANELS = "panel/OPEN_ASSOCIATED_PANELS";

export function* getPanels(param) {
  let objAttempt = {
    type: ATTEMPT_FETCH_PANELS,
    data: {
      currentPage: param.payload.filter.currentPage,
      pageSize: param.payload.filter.pageSize,
      isFiltered: param.payload.filter.isFiltered,
      sortBy: param.payload.filter.sortBy,
      sortDir: param.payload.filter.sortDir,
    },
  };
  if (param.payload.filter.isFiltered) {
    objAttempt["meta"] = {
      analytics: {
        panelName: param.payload.filter.name,
        funders: param.payload.filter.funders
          .map((funder) => funder.name)
          .join(","),
        clients: param.payload.filter.clients
          .map((client) => client.name)
          .join(","),
        valfirms: param.payload.filter.valfirms
          .map((valfirm) => valfirm.name)
          .join(","),
        valfirmKeyword: param.payload.filter.valfirmKeyword,
        propertyTypes: param.payload.filter.propertyTypes
          .map((propertyType) => propertyType.name)
          .join(","),
        serviceTypes: param.payload.filter.serviceTypes
          .map((serviceType) => serviceType.name)
          .join(","),
        panelType: param.payload.filter.panelType,
        panelStatus: param.payload.filter.status,
        estimatedPropertyValueLower: param.payload.filter.bracketMin,
        estimatedPropertyValueUpper: param.payload.filter.bracketMax,
        linkedAllocationName: param.payload.filter.allocationNameText,
        allocationLocations: param.payload.filter.allocationLocations
          .map((loc) => loc.name)
          .join(","),
        linkedFeeName: param.payload.filter.feeNameText,
        feeLocations: param.payload.filter.feeLocations
          .map((loc) => loc.name)
          .join(","),
      },
    };
  }
  yield put(objAttempt);
  try {
    const { data: response } = yield call(fetchPanels, param.payload.filter);
    yield put({
      type: SUCCEED_FETCH_PANELS,
      data: {
        ...response,
        name: param.payload.filter.name,
        status: param.payload.filter.status,
        nameSuggestion: param.payload.filter.nameSuggestion,
        countryId: param.payload.filter.countryId,
        funderText: param.payload.filter.funderText,
        funderSuggestion: param.payload.filter.funderSuggestion,
        funders: param.payload.filter.funders,
        clientText: param.payload.filter.clientText,
        clientSuggestion: param.payload.filter.clientSuggestion,
        clients: param.payload.filter.clients,
        valfirmText: param.payload.filter.valfirmText,
        valfirmSuggestion: param.payload.filter.valfirmSuggestion,
        valfirms: param.payload.filter.valfirms,
        valfirmKeyword: param.payload.filter.valfirmKeyword,
        keywordValfirms: param.payload.filter.keywordValfirms,
        propTypeSearchText: param.payload.filter.propTypeSearchText,
        propTypeSuggestion: param.payload.filter.propTypeSuggestion,
        propertyTypes: param.payload.filter.propertyTypes,
        serviceTypeSearchText: param.payload.filter.serviceTypeSearchText,
        serviceTypeSuggestion: param.payload.filter.serviceTypeSuggestion,
        serviceTypes: param.payload.filter.serviceTypes,
        panelType: param.payload.filter.panelType,
        bracketMin: param.payload.filter.bracketMin,
        bracketMax: param.payload.filter.bracketMax,
        showMore: param.payload.filter.showMore,
        allocationNameText: param.payload.filter.allocationNameText,
        allocationNameSuggestion: param.payload.filter.allocationNameSuggestion,
        allocationCountryId: param.payload.filter.allocationCountryId,
        allocationLocationText: param.payload.filter.allocationLocationText,
        allocationLocationSuggestion:
          param.payload.filter.allocationLocationSuggestion,
        allocationLocations: param.payload.filter.allocationLocations,
        feeNameText: param.payload.filter.feeNameText,
        feeNameSuggestion: param.payload.filter.feeNameSuggestion,
        feeCountryId: param.payload.filter.feeCountryId,
        feeLocationText: param.payload.filter.feeLocationText,
        feeLocationSuggestion: param.payload.filter.feeLocationSuggestion,
        feeLocations: param.payload.filter.feeLocations,
        sortBy: param.payload.filter.sortBy,
        sortDir: param.payload.filter.sortDir,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_PANELS,
    });
  }
}

export function* createPanelSet(param) {
  yield put({
    type: ATTEMPT_CREATE_PANEL_SET,
  });

  const userId = yield select(getUserId);
  try {
    const panelDetails = {
      name: param.payload.panelSetForm.name,
      notes: param.payload.panelSetForm.notes,
      panelType: param.payload.panelSetForm.panelType,
      order: param.payload.panelSetForm.order,
      useRuleEx: param.payload.panelSetForm.useRuleEx,
      statusBool: param.payload.panelSetForm.statusBool,
      bracketMin: param.payload.panelSetForm.bracketMin,
      bracketMax: param.payload.panelSetForm.bracketMax,
      panelSetFunderDtoSet: param.payload.panelSetForm.panelSetFunderDtoSet,
      panelSetClientDtoSet: param.payload.panelSetForm.panelSetClientDtoSet,
      panelSetPropertyTypeDto:
        param.payload.panelSetForm.panelSetPropertyTypeDto,
      panelSetServiceDtoSet: param.payload.panelSetForm.panelSetServiceDtoSet,
      userId,
    };

    const { data: updatedPanel } = yield call(
      createNewPanelSetAPI,
      panelDetails
    );

    yield put({
      type: SUCCEED_CREATE_PANEL_SET,
      payload: updatedPanel,
      meta: {
        analytics: {
          panelSetId: updatedPanel.id,
          panelSetName: panelDetails.name,
          versionId: updatedPanel.panelSetRevId,
        },
      },
    });
  } catch (err) {
    console.warn(err);
    if (err.response.data.message === "Name Already Exists") {
      yield put({
        type: ERROR_CREATE_PANEL_SET,
        error: "Please select another name as this one exists",
        meta: {
          analytics: {
            panelSetName: param.payload.panelSetForm.name,
            message: err.response.data.message,
          },
        },
      });
    } else {
      yield put({
        type: FAIL_CREATE_PANEL_SET,
        meta: {
          analytics: {
            panelSetName: param.payload.panelSetForm.name,
          },
        },
      });
    }
  }
}

export function* getPanelSet(param) {
  yield put({
    type: ATTEMPT_FETCH_PANEL_SET,
  });

  try {
    const { data: detail } = yield call(fetchPanelSet, param.panelId);
    yield put({
      type: SUCCEED_FETCH_PANEL_SET,
      data: {
        ...detail,
      },
      meta: {
        analytics: {
          panelId: detail.panelSetId,
          panelSetName: detail.name,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_PANEL_SET,
      meta: {
        analytics: {
          panelId: param.panelId,
        },
      },
    });
  }
}

export function* getPanelVersionDetail(param) {
  yield put({
    type: ATTEMPT_FETCH_PANEL_VERSION_DETAIL,
  });

  try {
    const { data: detail } = yield call(
      fetchPanelVersionDetail,
      param.payload.panelId,
      param.payload.panelVersionId
    );
    yield put({
      type: SUCCEED_FETCH_PANEL_VERSION_DETAIL,
      data: {
        ...detail,
      },
      meta: {
        analytics: {
          panelId: detail.id,
          panelVersionId: detail.panelSetRevId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_PANEL_VERSION_DETAIL,
      meta: {
        analytics: {
          panelId: param.payload.panelId,
          panelVersionId: param.payload.panelVersionId,
        },
      },
    });
  }
}

export function* editPanelSet(param) {
  yield put({
    type: ATTEMPT_EDIT_PANEL_SET,
  });

  const userId = yield select(getUserId);
  try {
    const panelDetails = {
      id: param.payload.panelSetForm.id,
      panelSetRevId: param.payload.panelSetForm.panelSetRevId,
      name: param.payload.panelSetForm.name,
      notes: param.payload.panelSetForm.notes,
      panelType: param.payload.panelSetForm.panelType,
      order: param.payload.panelSetForm.order,
      useRuleEx: param.payload.panelSetForm.useRuleEx,
      statusBool: param.payload.panelSetForm.statusBool,
      bracketMin: param.payload.panelSetForm.bracketMin,
      bracketMax: param.payload.panelSetForm.bracketMax,
      panelSetFunderDtoSet: param.payload.panelSetForm.panelSetFunderDtoSet,
      panelSetClientDtoSet: param.payload.panelSetForm.panelSetClientDtoSet,
      panelSetPropertyTypeDto:
        param.payload.panelSetForm.panelSetPropertyTypeDto,
      panelSetServiceDtoSet: param.payload.panelSetForm.panelSetServiceDtoSet,
      userId,
    };
    const { data } = yield call(updatePanelSet, panelDetails);
    const updatedPanel = {
      id: data.id,
      panelSetRevId: data.panelSetRevId,
      name: data.name,
      notes: data.notes,
      panelType: data.panelType,
      order: data.order,
      useRuleEx: data.useRuleEx,
      statusBool: data.statusBool,
      bracketMin: data.bracketMin,
      bracketMax: data.bracketMax,
      panelSetFunderDtoSet: data.panelSetFunderDtoSet,
      panelSetClientDtoSet: data.panelSetClientDtoSet,
      panelSetPropertyTypeDto: data.panelSetPropertyTypeDto,
      panelSetServiceDtoSet: data.panelSetServiceDtoSet,
      userId: data.userId,
    };
    yield put({
      type: SUCCEED_EDIT_PANEL_SET,
      payload: updatedPanel,
      meta: {
        analytics: {
          panelSetId: updatedPanel.id,
          panelSetName: updatedPanel.name,
          versionId: updatedPanel.panelSetRevId,
        },
      },
    });
  } catch (err) {
    console.warn(err);
    if (err.response.data.message === "Name Already Exists") {
      yield put({
        type: ERROR_EDIT_PANEL_SET,
        error: "Please select another name as this one exists",
        meta: {
          analytics: {
            panelId: param.payload.panelSetForm.id,
            versionId: param.payload.panelSetForm.panelSetRevId,
            panelSetName: param.payload.panelSetForm.name,
            message: err.response.data.message,
          },
        },
      });
    } else {
      yield put({
        type: FAIL_EDIT_PANEL_SET,
        meta: {
          analytics: {
            panelId: param.payload.panelSetForm.id,
            versionId: param.payload.panelSetForm.panelSetRevId,
            panelSetName: param.payload.panelSetForm.name,
          },
        },
      });
    }
  }
}

export function* updateAllocationTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_ALLOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(fetchAllocationSuggestion, data.text);

      yield put({
        type: SUCCEED_FETCH_ALLOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_ALLOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ALLOCATION_SUGGESTION,
    });
  }
}

export function* updatePanelAllocation(param) {
  const { id, name, panelId, panelVersionId } = param.payload;
  yield put({
    type: ATTEMPT_UPDATE_PANEL_ALLOCATION,
  });

  try {
    const userId = yield select(getUserId);
    const { data: panelDetail } = yield call(
      updatePanelAllocationAPI,
      id,
      panelId,
      panelVersionId,
      userId
    );
    yield put({
      type: SUCCEED_UPDATE_PANEL_ALLOCATION,
      data: panelDetail,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          allocationId: id,
          allocationName: name,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_PANEL_ALLOCATION,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          allocationId: id,
          allocationName: name,
        },
      },
    });
  }
}

export function* getPanelActivationLogs(param) {
  yield put({
    type: ATTEMPT_FETCH_PANEL_ACTIVATION_LOGS,
    data: {
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
      sortBy: param.payload.sortBy,
      sortDir: param.payload.sortDir,
    },
  });
  try {
    const { data: response } = yield call(
      fetchPanelActivationLogs,
      param.payload.panelSetId,
      param.payload.currentPage,
      param.payload.pageSize,
      param.payload.sortBy,
      param.payload.sortDir
    );
    yield put({
      type: SUCCEED_FETCH_PANEL_ACTIVATION_LOGS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_PANEL_ACTIVATION_LOGS,
    });
  }
}

export function* getLatestPanelActivationLog(param) {
  yield put({
    type: ATTEMPT_FETCH_LATEST_PANEL_ACTIVATION_LOG,
  });

  try {
    const { data: detail } = yield call(
      fetchLatestPanelActivationLog,
      param.panelSetId
    );

    yield put({
      type: SUCCEED_FETCH_LATEST_PANEL_ACTIVATION_LOG,
      data: {
        ...detail,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_LATEST_PANEL_ACTIVATION_LOG,
    });
  }
}

export function* updateFeeTextChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FEE_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(fetchFeeSuggestion, data.text);

      yield put({
        type: SUCCEED_FETCH_FEE_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FEE_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FEE_SUGGESTION,
    });
  }
}

export function* updatePanelFee(param) {
  const { feeId, name, panelId, panelVersionId } = param.payload;
  yield put({
    type: ATTEMPT_UPDATE_PANEL_FEE,
  });

  try {
    const userId = yield select(getUserId);
    const { data: panelDetail } = yield call(
      updatePanelFeeAPI,
      feeId,
      panelId,
      panelVersionId,
      userId
    );
    yield put({
      type: SUCCEED_UPDATE_PANEL_FEE,
      data: panelDetail,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          feeId,
          feeName: name,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_UPDATE_PANEL_FEE,
      meta: {
        analytics: {
          panelId,
          panelVersionId,
          feeId,
          feeName: name,
        },
      },
    });
  }
}

export function* getPanelVersions(param) {
  yield put({
    type: ATTEMPT_FETCH_PANEL_VERSIONS,
    data: {
      currentPage: param.payload.currentPage,
      pageSize: param.payload.pageSize,
    },
  });
  try {
    const { data: response } = yield call(
      fetchPanelVersions,
      param.payload.panelSetId,
      param.payload.currentPage,
      param.payload.pageSize
    );
    yield put({
      type: SUCCEED_FETCH_PANEL_VERSIONS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_PANEL_VERSIONS,
    });
  }
}

export function* updatePanelVersionNotes(param) {
  yield put({ type: ATTEMPT_UPDATE_PANEL_VERSION_NOTES });
  try {
    const panelSetId = param.payload.panelSetId;
    const panelVersionId = param.payload.panelVersionId;
    const userId = yield select(getUserId);
    const notes = param.payload.notes;
    yield call(
      fetchUpdatePanelVersionNotes,
      panelSetId,
      panelVersionId,
      userId,
      notes
    );
    yield put({
      type: SUCCEED_UPDATE_PANEL_VERSION_NOTES,
      data: {
        panelVersionId,
        notes,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({ type: FAIL_UPDATE_PANEL_VERSION_NOTES });
  }
}

export function* copyPanelVersion(param) {
  yield put({ type: ATTEMPT_COPY_PANEL_VERSION });
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      copyVersion,
      param.payload.panelId,
      param.payload.panelVersionId,
      userId
    );
    yield put({
      type: SUCCEED_COPY_PANEL_VERSION,
      data: {
        panelVersionId: response.panelVersionId,
      },
      meta: {
        analytics: {
          panelId: param.payload.panelId,
          versionId: param.payload.panelVersionId,
          newVersionId: response.panelVersionId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_COPY_PANEL_VERSION,
      meta: {
        analytics: {
          panelId: param.payload.panelId,
          versionId: param.payload.panelVersionId,
        },
      },
    });
  }
}

export function* schedulePanelVersionActivation(param) {
  const { panelId, panelVersionId, activateDate } = param.payload;
  const userId = yield select(getUserId);
  yield put({
    type: ATTEMPT_SCHEDULE_PANEL_VERSION_ACTIVATION,
  });
  try {
    const { data } = yield call(
      schedulePanelVersionActivationApi,
      panelId,
      panelVersionId,
      userId,
      activateDate
    );

    const timezone = moment.tz.guess();
    const scheduled = moment(data.scheduled)
      .tz(timezone)
      .format("DD-MM-YYYY hh:mm:ss A");

    yield put({
      type: SUCCEED_SCHEDULE_PANEL_VERSION_ACTIVATION,
      data,
      meta: {
        analytics: {
          versionId: param.payload.panelVersionId,
          scheduledDatetime: scheduled,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message:
          "Successfully Scheduled Version " +
          panelVersionId +
          " for " +
          scheduled,
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_SCHEDULE_PANEL_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: param.payload.panelVersionId,
          scheduledDatetime: activateDate[`$d`].toString(),
        },
      },
    });
  }
}

export function* cancelSchedulePanelVersionActivation(param) {
  yield put({ type: ATTEMPT_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION });
  const { panelId, panelActivationLogId, panelVersionId } = param.payload;
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      cancelPanelActivationLog,
      panelId,
      panelActivationLogId,
      userId
    );
    if (response.canceled) {
      yield put({
        type: SUCCEED_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION,
        data: {
          panelActivationLogId,
        },
        meta: {
          analytics: {
            versionId: panelVersionId,
            logId: panelActivationLogId,
          },
        },
      });
    } else {
      yield put({
        type: OPEN_FAIL_ACTIVATION_CANCEL,
        payload: {
          openFailCancel: true,
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: panelVersionId,
          logId: panelActivationLogId,
        },
      },
    });
  }
}

export function* discardPanelVersion(param) {
  yield put({ type: ATTEMPT_DISCARD_PANEL_VERSION });
  try {
    const panelSetRevId = param.payload.panelSetRevId;
    const userId = yield select(getUserId);
    yield call(
      fetchDiscardPanelVersion,
      param.payload.panelSetId,
      panelSetRevId,
      userId
    );
    yield put({
      type: SUCCEED_DISCARD_PANEL_VERSION,
      data: {
        panelSetRevId,
      },
      meta: {
        analytics: {
          versionId: panelSetRevId,
        },
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_DISCARD_PANEL_VERSION,
      meta: {
        analytics: {
          versionId: param.payload.panelSetRevId,
        },
      },
    });
  }
}

export function* setVersionsToCompare(param) {
  yield put({ type: SAVE_VERSIONS_TO_COMPARE, payload: param.payload });
  const count = param.payload.count;
  const message =
    count === 1
      ? "1 Version Selected | Select Another Version To Compare"
      : count + " Versions Selected";

  yield put({
    type: OPEN_ACTION_SNACKBAR,
    payload: {
      message,
      variant: "info",
      isIcon: true,
      actionType: count === 2 ? "comparePanelVersions" : "",
    },
  });
}

export function* clearVersionsToCompare() {
  yield put({ type: CLEAR_VERSIONS_TO_COMPARE });
}

export function* getPanelVersionComparisonDetails(param) {
  yield put({
    type: ATTEMPT_FETCH_VERSIONS_COMPARISON_DETAILS,
  });

  try {
    const { data: detail } = yield call(
      fetchPanelVersionComparisonDetails,
      param.payload.panelId,
      param.payload.versionsList
    );
    yield put({
      type: SUCCEED_FETCH_VERSIONS_COMPARISON_DETAILS,
      data: {
        ...detail,
      },
      meta: {
        analytics: {
          version1: param.payload.versionsList[0],
          version2: param.payload.versionsList[1],
        },
      },
    });
  } catch (e) {
    yield put({
      type: FAIL_FETCH_VERSIONS_COMPARISON_DETAILS,
      meta: {
        analytics: {
          version1: param.payload.versionsList[0],
          version2: param.payload.versionsList[1],
        },
      },
    });
  }
}

export function* filterNameChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_NAME_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchSearchSuggestion,
        data.text,
        "panels"
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_NAME_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_NAME_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_NAME_SUGGESTION,
    });
  }
}

export function* updateOrgTextChangeFilter(data) {
  yield put({
    type: ATTEMPT_FETCH_ORG_SUGGESTION_FILTER,
    text: data.payload.text,
    orgType: data.payload.orgTypeOption,
  });

  try {
    if (data.payload.text.length > 2) {
      const { data: list } = yield call(
        fetchPanelOrgSuggestion,
        data.payload.text,
        data.payload.orgTypeOption
      );

      yield put({
        type: SUCCEED_FETCH_ORG_SUGGESTION_FILTER,
        data: list,
        orgType: data.payload.orgTypeOption,
      });
    } else {
      yield put({
        type: CLEAR_ORG_SUGGESTION_FILTER,
        orgType: data.payload.orgTypeOption,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ORG_SUGGESTION_FILTER,
      orgType: data.payload.orgTypeOption,
    });
  }
}

export function* updatePropTypeTextChangeFilter(data) {
  yield put({
    type: ATTEMPT_FETCH_PROP_TYPE_SUGGESTION_FILTER,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(fetchPropTypeSuggestion, data.text);

      yield put({
        type: SUCCEED_FETCH_PROP_TYPE_SUGGESTION_FILTER,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_PROP_TYPE_SUGGESTION_FILTER,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_PROP_TYPE_SUGGESTION_FILTER,
    });
  }
}

export function* updateServiceTypeTextChangeFilter(data) {
  yield put({
    type: ATTEMPT_FETCH_SERVICE_TYPE_SUGGESTION_FILTER,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(fetchServiceTypeSuggestion, data.text);

      yield put({
        type: SUCCEED_FETCH_SERVICE_TYPE_SUGGESTION_FILTER,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_SERVICE_TYPE_SUGGESTION_FILTER,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_SERVICE_TYPE_SUGGESTION_FILTER,
    });
  }
}

export function* filterAllocationNameChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchSearchSuggestion,
        data.text,
        "allocations"
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_ALLOCATION_NAME_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_ALLOCATION_NAME_SUGGESTION,
    });
  }
}

export function* filterAllocationLocationChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestion,
        data.text,
        data.countryId
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_ALLOCATION_LOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_ALLOCATION_LOCATION_SUGGESTION,
    });
  }
}

export function* filterFeeNameChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_FEE_NAME_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchSearchSuggestion,
        data.text,
        "fees"
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_FEE_NAME_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_FEE_NAME_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_FEE_NAME_SUGGESTION,
    });
  }
}

export function* filterFeeLocationChange(data) {
  yield put({
    type: ATTEMPT_FETCH_FILTER_FEE_LOCATION_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 1) {
      const { data: list } = yield call(
        fetchLocationSuggestion,
        data.text,
        data.countryId
      );

      yield put({
        type: SUCCEED_FETCH_FILTER_FEE_LOCATION_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_FILTER_FEE_LOCATION_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_FILTER_FEE_LOCATION_SUGGESTION,
    });
  }
}

export function* acknowledgeSchedulePanelVersionActivation(param) {
  yield put({ type: ATTEMPT_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION });
  const { panelId, panelActivationLogId, panelVersionId } = param.payload;
  const userId = yield select(getUserId);

  try {
    const { data: response } = yield call(
      acknowledgePanelActivationLog,
      panelId,
      panelActivationLogId,
      userId
    );
    if (response.updated) {
      yield put({
        type: SUCCEED_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION,
        data: {
          panelActivationLogId,
        },
        meta: {
          analytics: {
            versionId: panelVersionId,
            logId: panelActivationLogId,
          },
        },
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION,
      meta: {
        analytics: {
          versionId: panelVersionId,
          logId: panelActivationLogId,
        },
      },
    });
  }
}

export function* exportPanelList(param) {
  yield put({ type: ATTEMPT_EXPORT_PANELS });
  try {
    const { data: response } = yield call(exportPanels, param.payload.filter);

    const fileName = "panel_list.xlsx";
    // Create a temporary URL for the file blob
    const url = window.URL.createObjectURL(new Blob([response]));
    const element = document.createElement("a");
    element.style.display = "none";
    element.setAttribute("href", url);
    element.setAttribute("download", fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    yield put({ type: SUCCEED_EXPORT_PANELS });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully downloaded search results",
        variant: "info",
        isIcon: true,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_EXPORT_PANELS,
    });
  }
}

export function* setPanelToBulkUpdate(param) {
  const panelIds = param.payload.panelIds || [];

  yield put({
    type: SAVE_PANEL_TO_BULK_UPDATE,
    payload: {
      panelIds,
    },
  });

  if (panelIds.length > 0) {
    const message =
      panelIds.length +
      " Panel" +
      (panelIds.length > 1 ? "s" : "") +
      " Selected";

    yield put({
      type: OPEN_ACTION_SNACKBAR,
      payload: {
        message,
        variant: "info",
        isIcon: true,
        actionType: "bulkUpdate",
      },
    });
  } else {
    yield put({ type: CLOSE_ACTION_SNACKBAR });
  }
}

export function* getBulkUpdateInfo(param) {
  yield put({ type: ATTEMPT_FETCH_BULK_UPDATE_INFO });
  try {
    const { data: response } = yield call(fetchBulkUpdateInfo, param.panelSets);
    yield put({
      type: SUCCEED_FETCH_BULK_UPDATE_INFO,
      data: response,
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_BULK_UPDATE_INFO,
    });
  }
}

export function* getBulkUpdateExistingValfirms(data) {
  yield put({
    type: ATTEMPT_FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
  });

  try {
    const { data: list } = yield call(
      fetchExistingValfirmsByRevIds,
      data.allocRevIds
    );
    yield put({
      type: SUCCEED_FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
      data: list,
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
    });
  }
}

export function* replaceValfirmNewValfirmTextChange(data) {
  yield put({
    type: ATTEMPT_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchValfirmSuggestion,
        data.text,
        data.excludeValfirmId
      );

      yield put({
        type: SUCCEED_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_REPLACE_VALFIRM_FETCH_NEW_VALFIRM_SUGGESTION,
    });
  }
}

export function* addValfirmTextChange(data) {
  yield put({
    type: ATTEMPT_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION,
    text: data.text,
  });

  try {
    if (data.text.length > 2) {
      const { data: list } = yield call(
        fetchActiveValfirmSuggestion,
        data.text
      );

      yield put({
        type: SUCCEED_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION,
        data: list,
      });
    } else {
      yield put({
        type: CLEAR_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION,
      });
    }
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_ADD_VALFIRM_TEXT_CHANGE_SUGGESTION,
    });
  }
}

export function* getBulkUpdatePreviewData(data) {
  let allocationDesc = "";
  const { allocations, changeType, distributionMethod, locations } =
    data.payload;
  allocations.forEach((alloc, allocIndex) => {
    const allocInfo =
      allocIndex +
      1 +
      ". " +
      alloc.allocationName +
      ": " +
      alloc.allocationPanelId +
      ", v" +
      (alloc.selectedVersion === "draft"
        ? alloc.allocDraftRevId
        : alloc.allocActiveRevId);
    let panelInfo = "";
    alloc.panels.forEach((panel, index) => {
      panelInfo +=
        panel.panelName +
        " (" +
        panel.panelSetId +
        ")" +
        (alloc.panels.length - 1 > index ? ", " : "");
    });
    allocationDesc +=
      allocInfo +
      " (Panels: " +
      panelInfo +
      " )" +
      (allocations.length - 1 > allocIndex ? ", " : "");
  });
  yield put({
    type: ATTEMPT_BULK_UPDATE_PREVIEW_DATA,
    meta: {
      analytics: {
        changeType,
        distributionMethod,
        allocations: allocationDesc,
        locationCount: locations.length,
      },
    },
  });
  try {
    const { data: list } = yield call(fetchBulkUpdatePreviewData, data.payload);

    yield put({
      type: SUCCEED_BULK_UPDATE_PREVIEW_DATA,
      data: list,
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_BULK_UPDATE_PREVIEW_DATA,
    });
  }
}

export function* setBulkUpdateSaveAsDraft(data) {
  yield put({
    type: ATTEMPT_BULK_UPDATE_SAVE_AS_DRAFT,
  });

  const {
    allocations,
    addAnotherBulkUpdate,
    changeType,
    distributionMethod,
    previewPanelIds,
    selectedPanels,
    locations,
  } = data.payload;
  let allocationDesc = "";
  allocations.forEach((alloc, allocIndex) => {
    const allocInfo =
      allocIndex +
      1 +
      ". " +
      alloc.allocationName +
      ": " +
      alloc.allocationPanelId +
      ", v" +
      (alloc.selectedVersion === "draft"
        ? alloc.allocDraftRevId
        : alloc.allocActiveRevId);
    let panelInfo = "";
    alloc.panels.forEach((panel, index) => {
      panelInfo +=
        panel.panelName +
        " (" +
        panel.panelSetId +
        ")" +
        (alloc.panels.length - 1 > index ? ", " : "");
    });
    allocationDesc +=
      allocInfo +
      " (Panels: " +
      panelInfo +
      " )" +
      (allocations.length - 1 > allocIndex ? ", " : "");
  });

  try {
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "It might take a few seconds...",
        variant: "info",
        showLoading: true,
      },
    });

    const { data: response } = yield call(saveBulkUpdate, data.payload);

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Allocating to valuation firms...",
        variant: "info",
        showLoading: true,
      },
    });
    yield delay(500);

    yield put({
      type: SUCCEED_BULK_UPDATE_SAVE_AS_DRAFT,
      addAnotherBulkUpdate: addAnotherBulkUpdate,
      allocNewDraftInfo: response,
      meta: {
        analytics: {
          changeType: changeType,
          distributionMethod: distributionMethod,
          allocations: allocationDesc,
          addAnotherBulkUpdate: addAnotherBulkUpdate,
          previewPanels:
            previewPanelIds.length + " of " + selectedPanels.length,
          locationCount: locations.length,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Saving All as Draft...",
        variant: "info",
        showLoading: true,
      },
    });
    yield delay(500);

    yield put({
      type: CLEAR_BULK_UPDATE_SAVE_AS_DRAFT,
    });
    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Successfully Updated and Saved as Draft",
        variant: "info",
        isIcon: true,
        duration: 3000,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_BULK_UPDATE_SAVE_AS_DRAFT,
      meta: {
        analytics: {
          changeType: changeType,
          distributionMethod: distributionMethod,
          allocations: allocationDesc,
          addAnotherBulkUpdate: addAnotherBulkUpdate,
        },
      },
    });

    yield put({
      type: OPEN_SNACKBAR,
      payload: {
        message: "Bulk update failed",
        variant: "info",
      },
    });
  }
}

export function* getAllocationsLocations(data) {
  yield put({
    type: ATTEMPT_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
  });

  try {
    const { data: list } = yield call(
      fetchAllocationsLocations,
      data.allocRevIds,
      data.valfirmId
    );

    yield put({
      type: SUCCEED_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
      data: list,
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
    });
  }
}

export function* getAssociatedPanels(param) {
  const { allocationId, currentPage, pageSize, sortBy, sortDir } =
    param.payload;
  yield put({
    type: ATTEMPT_FETCH_ASSOCIATED_PANELS,
  });
  try {
    const { data: response } = yield call(
      fetchAssociatedPanels,
      allocationId,
      currentPage,
      pageSize,
      sortBy,
      sortDir
    );
    yield put({
      type: SUCCEED_FETCH_ASSOCIATED_PANELS,
      data: {
        ...response,
      },
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FAIL_FETCH_ASSOCIATED_PANELS,
    });
  }
}

export default function* sagaPanel() {
  yield takeLatest(FETCH_PANELS, getPanels);
  yield takeLeading(CREATE_PANEL_SET, createPanelSet);
  yield takeLeading(FETCH_PANEL_SET, getPanelSet);
  yield takeLatest(FETCH_PANEL_VERSION_DETAIL, getPanelVersionDetail);
  yield takeLeading(EDIT_PANEL_SET, editPanelSet);
  yield takeLatest(UPDATE_ALLOCATION_TEXT_CHANGE, updateAllocationTextChange);
  yield takeLeading(UPDATE_PANEL_ALLOCATION, updatePanelAllocation);
  yield takeLatest(FETCH_PANEL_ACTIVATION_LOGS, getPanelActivationLogs);
  yield takeLatest(
    FETCH_LATEST_PANEL_ACTIVATION_LOG,
    getLatestPanelActivationLog
  );
  yield takeLatest(UPDATE_FEE_TEXT_CHANGE, updateFeeTextChange);
  yield takeLeading(UPDATE_PANEL_FEE, updatePanelFee);
  yield takeLatest(FETCH_PANEL_VERSIONS, getPanelVersions);
  yield takeLeading(UPDATE_PANEL_VERSION_NOTES, updatePanelVersionNotes);
  yield takeLeading(COPY_PANEL_VERSION, copyPanelVersion);
  yield takeLeading(
    SCHEDULE_PANEL_VERSION_ACTIVATION,
    schedulePanelVersionActivation
  );
  yield takeLeading(
    CANCEL_SCHEDULE_PANEL_VERSION_ACTIVATION,
    cancelSchedulePanelVersionActivation
  );
  yield takeLeading(DISCARD_PANEL_VERSION, discardPanelVersion);
  yield takeLeading(SET_VERSIONS_TO_COMPARE, setVersionsToCompare);
  yield takeLeading(RESET_VERSIONS_TO_COMPARE, clearVersionsToCompare);
  yield takeLeading(
    FETCH_VERSIONS_COMPARISON_DETAILS,
    getPanelVersionComparisonDetails
  );
  yield takeLatest(FILTER_NAME_CHANGE, filterNameChange);
  yield takeLatest(UPDATE_ORG_TEXT_CHANGE_FIlTER, updateOrgTextChangeFilter);
  yield takeLatest(
    UPDATE_PROP_TYPE_TEXT_CHANGE_FILTER,
    updatePropTypeTextChangeFilter
  );
  yield takeLatest(
    UPDATE_SERVICE_TYPE_TEXT_CHANGE_FILTER,
    updateServiceTypeTextChangeFilter
  );
  yield takeLatest(FILTER_ALLOCATION_NAME_CHANGE, filterAllocationNameChange);
  yield takeLatest(
    FILTER_ALLOCATION_LOCATION_CHANGE,
    filterAllocationLocationChange
  );
  yield takeLatest(FILTER_FEE_NAME_CHANGE, filterFeeNameChange);
  yield takeLatest(FILTER_FEE_LOCATION_CHANGE, filterFeeLocationChange);
  yield takeLeading(
    ACKNOWLEDGE_SCHEDULE_PANEL_VERSION_ACTIVATION,
    acknowledgeSchedulePanelVersionActivation
  );
  yield takeLatest(EXPORT_PANELS, exportPanelList);
  yield takeLeading(SET_PANEL_TO_BULK_UPDATE, setPanelToBulkUpdate);
  yield takeLeading(FETCH_BULK_UPDATE_INFO, getBulkUpdateInfo);
  yield takeLatest(
    FETCH_BULK_UPDATE_EXISTING_VALFIRMS,
    getBulkUpdateExistingValfirms
  );
  yield takeLatest(
    REPLACE_VALFIRM_NEW_VALFIRM_TEXT_CHANGE,
    replaceValfirmNewValfirmTextChange
  );
  yield takeLatest(ADD_VALFIRM_TEXT_CHANGE, addValfirmTextChange);
  yield takeLeading(BULK_UPDATE_PREVIEW_DATA, getBulkUpdatePreviewData);
  yield takeLeading(BULK_UPDATE_SAVE_AS_DRAFT, setBulkUpdateSaveAsDraft);
  yield takeLatest(
    FETCH_BULK_UPDATE_ALLOCATIONS_LOCATIONS,
    getAllocationsLocations
  );
  yield takeLatest(FETCH_ASSOCIATED_PANELS, getAssociatedPanels);
}
