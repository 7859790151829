import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import { Tooltip, Box } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { RemoveRedEye } from "@mui/icons-material";
import moment from "moment-timezone";
import {
  getAllocationActivationLogs,
  changeAllocationTab,
  changeAllocationVersionId,
  getLatestAllocationActivationLog,
} from "../../store/action/actionAllocation";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import PanelExDialog from "../shared/PanelExDialog";

interface AllocationActivationsProps {
  allocationId: number;
  allocationVersionId: number;
}
export default function AllocationActivationLogs(
  props: AllocationActivationsProps
) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const timezone = moment.tz.guess();
  const timezoneAbbr = moment().tz(timezone).format("z");
  const allocationId = props.allocationId;
  const allocationVersionId = props.allocationVersionId;

  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleErrorClose = () => {
    setErrorMessage("");
    setOpenError(false);
  };

  const useStyles = makeStyles()(() => ({
    versionsHeader: {
      paddingBottom: "20px",
      "& .MuiTypography-root": {
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.15px",
        color: "rgba(0,0,0,0.8)",
      },
    },
    versionId: {
      color: theme.palette.primary.main,
    },
    errorButton: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  }));
  const { classes } = useStyles();

  const {
    activationLogs,
    loading,
    rowCount,
    currentPage,
    pageSize,
    sortBy,
    sortDir,
    allocationVersionDetail,
  } = useSelector((state: RootState) => {
    return {
      activationLogs: state.allocation.activationLog.activationLogs,
      loading: state.allocation.activationLog.loading,
      rowCount: state.allocation.activationLog.activationLogCount || 0,
      currentPage: state.allocation.activationLog.currentPage || 0,
      pageSize: state.allocation.activationLog.pageSize || 15,
      sortBy: state.allocation.activationLog.sortBy || "requested",
      sortDir: state.allocation.activationLog.sortDir || "desc",
      allocationVersionDetail: state.allocation.allocationVersionDetail,
    };
  });

  useEffect(() => {
    if (allocationId) {
      dispatch(getLatestAllocationActivationLog(allocationId));
    }
  }, [dispatch, allocationId]);

  const columns: GridColDef[] = [
    {
      field: "allocationPanelRevId",
      headerName: "ID",
      width: 90,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      hideable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={`Load to preview v${params.row.allocationPanelRevId}`}
              arrow
            >
              <span
                data-testid={`id-${params.row.allocationPanelRevId}`}
                id={`id-${params.row.allocationPanelRevId}`}
                className={classes.versionId}
                onClick={() => {
                  dispatch(
                    changeAllocationVersionId(params.row.allocationPanelRevId)
                  );
                  dispatch(changeAllocationTab(0));
                }}
              >
                {params.row.allocationPanelRevId}
              </span>
            </Tooltip>
            {params.row.allocationPanelRevId === allocationVersionId &&
              params.row.allocationPanelActivationLogId ===
                allocationVersionDetail.latestActivationLogId && (
                <RemoveRedEye
                  data-testid="icon-selected-id"
                  id="icon-selected-id"
                  sx={{ paddingLeft: "8px", color: theme.palette.primary.main }}
                />
              )}
          </>
        );
      },
    },
    {
      field: "requested",
      headerName: "Requested",
      width: 230,
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "scheduled",
      headerName: "Scheduled",
      width: 240,
      editable: false,
      sortable: false,
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "processed",
      headerName: "Processed",
      width: 240,
      editable: false,
      sortable: false,
      valueFormatter: (value) => {
        if (value) {
          const dateTime = moment(value)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm:ss A");

          return dateTime + " " + timezoneAbbr;
        } else {
          return "";
        }
      },
    },
    {
      field: "user",
      headerName: "User",
      width: 230,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.user
          ? params.row.user.first + " " + params.row.user.last
          : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const allocationPanelActivationLogId =
          params.row.allocationPanelActivationLogId;
        const index = params.api.getRowIndexRelativeToVisibleRows(
          allocationPanelActivationLogId
        );
        return params.row.status === "Error" ||
          params.row.status === "Acknowledged" ? (
          <>
            <div
              className={classes.errorButton}
              data-testid={`btn-error-${index}`}
              id={`btn-error-${index}`}
              onClick={(e) => {
                setErrorMessage(params.row.errorMessage);
                setOpenError(true);
                e.preventDefault();
              }}
            >
              Error
            </div>
          </>
        ) : (
          params.row.status
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: sortBy,
      sort: sortDir,
    },
  ]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchAllocationActivationLogs(
      allocationId,
      newPage,
      newPageSize,
      sortBy,
      sortDir
    );
  };

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const field =
      newSortModel[0].field === "allocationPanelRevId"
        ? "allocation_panel_rev_id"
        : newSortModel[0].field;
    const sort = newSortModel[0].sort || "desc";
    setSortModel(newSortModel);
    setPaginationModel({ page: 0, pageSize: pageSize });
    fetchAllocationActivationLogs(allocationId, 0, pageSize, field, sort);
  };

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const fetchAllocationActivationLogs = (
    id: number,
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDir: string
  ) => {
    return dispatch(
      getAllocationActivationLogs(id, currentPage, pageSize, sortBy, sortDir)
    );
  };

  useEffect(() => {
    fetchAllocationActivationLogs(
      allocationId,
      currentPage,
      pageSize,
      sortBy,
      sortDir
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        sx={{
          minHeight: 400,
          width: "100%",
          "& .status-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.55)",
            color: "#1a3e72",
            fontWeight: "600",
          },
        }}
      >
        <DataGridPro
          loading={loading}
          style={{ cursor: "pointer" }}
          className="tbl-activation-logs"
          getRowId={(row) => row.allocationPanelActivationLogId}
          rows={activationLogs}
          rowCount={rowCountState}
          columns={columns}
          pagination
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          disableColumnFilter={true}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[15, 20, 50]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : ""
          }
        />
      </Box>
      <PanelExDialog
        id="errorMessage-dialog"
        data-testid="errorMessage-dialog"
        open={openError}
        title="Error"
        cancelText="Close"
        submitEnabled={false}
        showSubmit={false}
        onClose={handleErrorClose}
      >
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            padding: "20px",
          }}
        >
          {errorMessage}
        </Box>
      </PanelExDialog>
    </>
  );
}
