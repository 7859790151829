import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";
import {
  closeActionSnackbar,
  updateSearchOption,
} from "../../store/action/actionUI";
import {
  Button,
  Box,
  Divider,
  FormControl,
  Grid2 as Grid,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import PanelsIcon from "@mui/icons-material/LanOutlined";
import { AddOutlined, FileDownloadOutlined } from "@mui/icons-material";
import PanelSetForm from "./PanelSetForm";
import {
  showPanelSetForm,
  getPanels,
  openFilter,
  exportPanels,
  setPanelToBulkUpdate,
} from "../../store/action/actionPanel";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import PanelExLanding from "../shared/PanelExLanding";
import FilterListIcon from "@mui/icons-material/FilterList";
import PanelExSnackbar from "../shared/PanelExSnackbar";
import { grey } from "@mui/material/colors";
import PanelExActionSnackbar from "../shared/PanelExActionSnackbar";
import BulkUpdate from "./BulkUpdate";

export default function Panels() {
  const searchOption = useSelector((state: RootState) => state.ui.searchOption);
  const dispatch = useDispatch();
  const history = useHistory();

  if (!searchOption) {
    dispatch(updateSearchOption("panels"));
  }

  const {
    openPanelSetForm,
    newPanelId,
    panels,
    rowCount,
    searchText,
    currentPage,
    pageSize,
    sortBy,
    sortDir,
    nameSuggestion,
    countryId,
    panelSetStatus,
    isFiltered,
    funderText,
    funderSuggestion,
    funders,
    clientText,
    clientSuggestion,
    clients,
    valfirmText,
    valfirmSuggestion,
    valfirms,
    keywordValfirms,
    propTypeSearchText,
    propTypeSuggestion,
    propertyTypes,
    serviceTypeSearchText,
    serviceTypeSuggestion,
    serviceTypes,
    panelType,
    bracketMin,
    bracketMax,
    showMore,
    allocationNameText,
    allocationNameSuggestion,
    allocationCountryId,
    allocationLocationText,
    allocationLocationSuggestion,
    allocationLocations,
    feeNameText,
    feeNameSuggestion,
    feeCountryId,
    feeLocationText,
    feeLocationSuggestion,
    feeLocations,
    loading,
    roleName,
    isBulkUpdate,
    isOpenBulkUpdate,
    addAnotherBulkUpdate,
    bulkUpdateSuccess,
    bulkUpdateFocusPanels,
    selectedPanels,
    readOnly,
  } = useSelector((state: RootState) => {
    return {
      openPanelSetForm: state.panel.openPanelSetForm || false,
      newPanelId: state.panel.panelSetForm.newPanelId || 0,
      panels: state.panel.panels || [],
      rowCount: state.panel.panelCount || 0,
      searchText: state.panel.filteredOptions.name || "",
      currentPage: state.panel.currentPage || 0,
      pageSize: state.panel.pageSize || 15,
      sortBy: state.panel.sortBy || "name",
      sortDir: state.panel.sortDir || "asc",
      isFiltered: state.panel.filterOptions.isFiltered || false,
      nameSuggestion: state.panel.filteredOptions.nameSuggestion || [],
      countryId: state.panel.filteredOptions.countryId || 1,
      panelSetStatus: state.panel.filteredOptions.status || "",
      funderText: state.panel.filteredOptions.funderText || "",
      funderSuggestion: state.panel.filteredOptions.funderSuggestion || [],
      funders: state.panel.filteredOptions.funders || [],
      clientText: state.panel.filteredOptions.clientText || "",
      clientSuggestion: state.panel.filteredOptions.clientSuggestion || [],
      clients: state.panel.filteredOptions.clients || [],
      valfirmText: state.panel.filteredOptions.valfirmText || "",
      valfirmSuggestion: state.panel.filteredOptions.valfirmSuggestion || [],
      valfirms: state.panel.filteredOptions.valfirms || [],
      keywordValfirms: state.panel.filteredOptions.keywordValfirms || [],
      propTypeSearchText: state.panel.filteredOptions.propTypeSearchText || "",
      propTypeSuggestion: state.panel.filteredOptions.propTypeSuggestion || [],
      propertyTypes: state.panel.filteredOptions.propertyTypes || [],
      serviceTypeSearchText:
        state.panel.filteredOptions.serviceTypeSearchText || "",
      serviceTypeSuggestion:
        state.panel.filteredOptions.serviceTypeSuggestion || [],
      serviceTypes: state.panel.filteredOptions.serviceTypes || [],
      panelType: state.panel.filteredOptions.panelType || "Any",
      bracketMin: state.panel.filteredOptions.bracketMin || "",
      bracketMax: state.panel.filteredOptions.bracketMax || "",
      showMore: state.panel.filteredOptions.showMore || false,
      allocationNameText: state.panel.filteredOptions.allocationNameText || "",
      allocationNameSuggestion:
        state.panel.filteredOptions.allocationNameSuggestion || [],
      allocationCountryId: state.panel.filteredOptions.allocationCountryId || 1,
      allocationLocationText:
        state.panel.filteredOptions.allocationLocationText || "",
      allocationLocationSuggestion:
        state.panel.filteredOptions.allocationLocationSuggestion || [],
      allocationLocations:
        state.panel.filteredOptions.allocationLocations || [],
      feeNameText: state.panel.filteredOptions.feeNameText || "",
      feeNameSuggestion: state.panel.filteredOptions.feeNameSuggestion || [],
      feeCountryId: state.panel.filteredOptions.feeCountryId || 1,
      feeLocationText: state.panel.filteredOptions.feeLocationText || "",
      feeLocationSuggestion:
        state.panel.filteredOptions.feeLocationSuggestion || [],
      feeLocations: state.panel.filteredOptions.feeLocations || [],
      loading: state.panel.loading || false,
      roleName: state.tokenDetails.userContactDetails.roleName,
      isBulkUpdate: state.config.featureFlags.bulkUpdate,
      isOpenBulkUpdate: state.panel.bulkUpdate.isOpenBulkUpdate,
      addAnotherBulkUpdate: state.panel.bulkUpdate.addAnotherBulkUpdate,
      bulkUpdateSuccess: state.panel.bulkUpdate.success,
      bulkUpdateFocusPanels: state.panel.bulkUpdate.focusPanels,
      selectedPanels: state.panel.selectedPanels || [],
      readOnly: state.tokenDetails.userContactDetails.readOnly,
    };
  });

  useEffect(() => {
    if (newPanelId > 0) {
      history.push(`/panels/${newPanelId}`);
    }
  }, [newPanelId, history]);

  useEffect(() => {
    const bulkUpdatePanelSetId =
      selectedPanels.length === 1 ? selectedPanels[0]?.panelSetId ?? 0 : 0;
    if (!addAnotherBulkUpdate && bulkUpdateSuccess && bulkUpdatePanelSetId) {
      history.push(`/panels/${bulkUpdatePanelSetId}`);
    }
  }, [addAnotherBulkUpdate, bulkUpdateSuccess, selectedPanels, history]);

  const openCreateNewPanelSet = () => {
    dispatch(showPanelSetForm(true, true));
  };

  const columns: GridColDef[] = [
    {
      field: "panelSetId",
      headerName: "ID",
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      width: 70,
    },
    {
      field: "name",
      headerName: "Panel Name",
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      flex: 1,
    },
    {
      field: "orgNames",
      headerName: "Org",
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "active",
      headerName: "Status",
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      width: 90,
      renderCell: (params: GridRenderCellParams) => {
        const { active } = params.row;
        return <>{active ? "In Use" : "Not Used"}</>;
      },
    },

    {
      field: "type",
      headerName: "Type",
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      width: 90,
      renderCell: (params: GridRenderCellParams) => {
        const { type } = params.row;
        return <>{type === "Normal" ? "Fee Based" : "Quote"}</>;
      },
    },
    {
      field: "processOrder",
      headerName: "Order",
      editable: false,
      sortable: true,
      sortingOrder: ["desc", "asc"],
      width: 90,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "serviceTypes",
      headerName: "Service Type",
      flex: 1,
      editable: false,
      sortable: false,
    },
  ];

  const theme = useTheme();
  const useStyles = makeStyles()(() => ({
    button: {
      color: theme.palette.common.white,
      "& .MuiButton-startIcon": {
        color: theme.palette.common.white,
      },
    },
    exportButton: {
      color: theme.colors.tertiary.main,
      "& .MuiButton-startIcon": {
        color: theme.colors.tertiary.main,
      },
    },
    select: {
      borderRadius: "4px",
    },
    noResult: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "28px",
      letter: "0.15px",
      color: "#949494",
    },
    warning: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21.98px",
      letterSpacing: "0.1px",
      color: theme.palette.text.primary,
      paddingBottom: "10px",
    },
    location: {
      color: theme.palette.tertiary.main,
    },
    bubble: {
      display: "flex",
      margin: "3px",
      padding: "3px 4px",
      alignItems: "center",
      borderRadius: "16px",
      background: grey[200],
    },
    bubbleText: {
      paddingLeft: "4px",
      color: theme.palette.text.primary,
    },
  }));

  const { classes } = useStyles();

  const [paginationModel, setPaginationModel] = useState({
    page: currentPage,
    pageSize: pageSize,
  });

  useEffect(() => {
    setPaginationModel({ page: currentPage, pageSize: pageSize });
  }, [currentPage, pageSize]);

  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  const handlePaginationModelChange = (newModel: any) => {
    const newPageSize = newModel.pageSize;
    const newPage =
      paginationModel.pageSize !== newPageSize ? 0 : newModel.page;
    setPaginationModel({ page: newPage, pageSize: newPageSize });
    fetchPanels(newPage, newPageSize, sortBy, sortDir);
  };

  useEffect(() => {
    setRowCountState(
      (prevRowCountState: number) => rowCount || prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const fetchPanels = (
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDir: string
  ) => {
    let valfirmKeyword = "";
    if (valfirms.find((v: any) => v.id === 0) !== undefined) {
      valfirmKeyword = valfirms
        .find((v: any) => v.id === 0)
        ["name"].replace("Add ", "")
        .replace('"', "")
        .replace('"', "");
    }
    const filter = {
      name: searchText,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
      status: isFiltered ? panelSetStatus : "",
      isFiltered,
      nameSuggestion,
      countryId,
      funderText,
      funderSuggestion,
      funders,
      clientText,
      clientSuggestion,
      clients,
      valfirmText,
      valfirmSuggestion,
      valfirms,
      valfirmKeyword,
      keywordValfirms,
      propTypeSearchText,
      propTypeSuggestion,
      propertyTypes,
      serviceTypeSearchText,
      serviceTypeSuggestion,
      serviceTypes,
      panelType,
      bracketMin,
      bracketMax,
      showMore,
      allocationNameText,
      allocationNameSuggestion,
      allocationCountryId,
      allocationLocationText,
      allocationLocationSuggestion,
      allocationLocations,
      feeNameText,
      feeNameSuggestion,
      feeCountryId,
      feeLocationText,
      feeLocationSuggestion,
      feeLocations,
    };
    return dispatch(getPanels(filter));
  };

  useEffect(() => {
    dispatch(updateSearchOption("panels"));
    dispatch(closeActionSnackbar());
    fetchPanels(currentPage, pageSize, sortBy, sortDir);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: sortBy,
      sort: sortDir,
    },
  ]);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    let field = sortBy;
    let sort = sortDir;
    if (typeof newSortModel[0] !== "undefined") {
      field =
        newSortModel[0].field === "panelSetId"
          ? "panel_set_id"
          : newSortModel[0].field === "processOrder"
          ? "processorder"
          : newSortModel[0].field;
      sort = newSortModel[0].sort || "desc";
    }
    setSortModel(newSortModel);
    setPaginationModel({ page: 0, pageSize: pageSize });
    fetchPanels(0, pageSize, field, sort);
  };

  const renderResultTitle = () => {
    let filters = false;
    if (
      serviceTypes.length > 0 ||
      propertyTypes.length > 0 ||
      funders.length > 0 ||
      clients.length > 0 ||
      valfirms.length > 0 ||
      allocationLocations.length > 0 ||
      allocationNameText.length > 0 ||
      feeLocations.length > 0 ||
      feeNameText.length > 0
    ) {
      filters = true;
    }
    if (bracketMin > 0 || bracketMax > 0 || panelType != "Any") {
      filters = true;
    }
    return (
      "Showing results for " +
      rowCount +
      " Panel" +
      (rowCount > 1 ? "s " : " ") +
      (searchText.length > 0
        ? "matching '" +
          searchText +
          "'" +
          (filters
            ? (searchText.length > 0 ? " and" : "") + " any of the below:"
            : "")
        : filters
        ? "matching any of the below:"
        : "")
    );
  };
  const renderLocations = (type: string, index: number, name: string) => {
    if (index < 20) {
      return (
        <Grid
          key={`grid-${type}-${index}`}
          data-testid={`grid-${type}-${index}`}
          className={classes.bubble}
        >
          <Typography variant="body3Strong" className={classes.bubbleText}>
            {name}
          </Typography>
        </Grid>
      );
    }
  };

  const renderListItem = (type: string, index: number, name: string) => {
    if (index < 10) {
      return (
        <Grid
          key={`grid-${type}-${index}`}
          data-testid={`grid-${type}-${index}`}
          className={classes.bubble}
        >
          <Typography variant="body3Strong" className={classes.bubbleText}>
            {name}
          </Typography>
        </Grid>
      );
    }
  };

  const bracketLabel =
    "from " +
    (bracketMin > 0 ? bracketMin : "any ") +
    " to " +
    (bracketMax > 0 ? bracketMax : "any ");

  const exportPanelList = () => {
    let valfirmKeyword = "";
    if (valfirms.find((v: any) => v.id === 0) !== undefined) {
      valfirmKeyword = valfirms
        .find((v: any) => v.id === 0)
        ["name"].replace("Add ", "")
        .replace('"', "")
        .replace('"', "");
    }
    const filter = {
      name: searchText,
      sortBy,
      sortDir,
      status: isFiltered ? panelSetStatus : "",
      isFiltered,
      nameSuggestion,
      countryId,
      funderText,
      funderSuggestion,
      funders,
      clientText,
      clientSuggestion,
      clients,
      valfirmText,
      valfirmSuggestion,
      valfirms,
      valfirmKeyword,
      keywordValfirms,
      propTypeSearchText,
      propTypeSuggestion,
      propertyTypes,
      serviceTypeSearchText,
      serviceTypeSuggestion,
      serviceTypes,
      panelType,
      bracketMin,
      bracketMax,
      showMore,
      allocationNameText,
      allocationNameSuggestion,
      allocationCountryId,
      allocationLocationText,
      allocationLocationSuggestion,
      allocationLocations,
      feeNameText,
      feeNameSuggestion,
      feeCountryId,
      feeLocationText,
      feeLocationSuggestion,
      feeLocations,
    };
    return dispatch(exportPanels(filter));
  };

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const clearSelectionModel = () => {
    setRowSelectionModel([]);
  };

  return (
    <Grid container>
      <PanelExLanding />
      <Grid
        container
        size={12}
        sx={{ display: "flex", justifyContent: "center" }}
        data-testid="panelList"
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            paddingLeft: "calc(3px + 1.5625vw)",
            paddingRight: "calc(3px + 1.5625vw)",
            maxWidth: "calc(100vw)",
          }}
        >
          <Grid
            container
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              backgroundColor: theme.colors.alpha.trueWhite[100],
            }}
          >
            <Grid
              size={6}
              display="flex"
              justifyContent="left"
              alignItems="center"
            >
              <PanelsIcon
                sx={{
                  width: "24px",
                  height: "24px",
                  paddingRight: "6px",
                }}
                id="panel-title-icon"
                data-testid="panel-title-icon"
              />
              <Typography
                id="panel-title"
                data-testid="panel-title"
                variant="h3"
                component="h3"
              >
                {renderResultTitle()}
              </Typography>
            </Grid>

            <Grid size={6}>
              <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
                <Button
                  id="btn-export"
                  data-testid="btn-export"
                  onClick={() => exportPanelList()}
                  startIcon={<FileDownloadOutlined />}
                >
                  Export Panel Lists
                </Button>
                {roleName === "Admin" && !readOnly && (
                  <FormControl size="small">
                    <Button
                      id="btn-panel-create"
                      data-testid="btn-panel-create"
                      className={classes.button}
                      variant="contained"
                      startIcon={<AddOutlined />}
                      onClick={() => openCreateNewPanelSet()}
                    >
                      Create New Panel
                    </Button>
                  </FormControl>
                )}
              </Stack>
            </Grid>

            {funders !== undefined && funders.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-funders-type-1"
                data-testid="pills-funders-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} /> Funders:
                {funders.map((type: any, index: number) =>
                  renderListItem("funders", index, type.name)
                )}
                {funders.length > 10
                  ? renderListItem("funders", -1, "+" + (funders.length - 10))
                  : ""}
              </Grid>
            )}
            {clients !== undefined && clients.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-clients-type-1"
                data-testid="pills-clients-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} /> Clients:
                {clients.map((type: any, index: number) =>
                  renderListItem("clients", index, type.name)
                )}
                {clients.length > 10
                  ? renderListItem("clients", -1, "+" + (clients.length - 10))
                  : ""}
              </Grid>
            )}
            {valfirms !== undefined && valfirms.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-valfirms-type-1"
                data-testid="pills-valfirms-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} /> Valfirms:
                {valfirms.map((type: any, index: number) =>
                  renderListItem("valfirms", index, type.name)
                )}
                {valfirms.length > 10
                  ? renderListItem("valfirms", -1, "+" + (valfirms.length - 10))
                  : ""}
              </Grid>
            )}
            {propertyTypes !== undefined && propertyTypes.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-propertyType-type-1"
                data-testid="pills-propertyType-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} /> Property Type:
                {propertyTypes.map((type: any, index: number) =>
                  renderListItem("propertyType", index, type.name)
                )}
                {propertyTypes.length > 10
                  ? renderListItem(
                      "propertyType",
                      -1,
                      "+" + (propertyTypes.length - 10)
                    )
                  : ""}
              </Grid>
            )}
            {serviceTypes !== undefined && serviceTypes.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-serviceTypes-type-1"
                data-testid="pills-serviceTypes-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} /> Service Type:
                {serviceTypes.map((type: any, index: number) =>
                  renderListItem("serviceType", index, type.name)
                )}
                {serviceTypes.length > 10
                  ? renderListItem(
                      "serviceType",
                      -1,
                      "+" + (serviceTypes.length - 10)
                    )
                  : ""}
              </Grid>
            )}
            {panelType != "Any" && (
              <Grid
                container
                size={12}
                id="pills-panelType-type-1"
                data-testid="pills-panelType-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} />
                Panel Type:{" "}
                {panelType === "Normal" ? "Fee based panel" : "Quote panel"}
              </Grid>
            )}
            {(bracketMin > 0 || bracketMax > 0) && (
              <Grid
                container
                size={12}
                id="pills-brackets-type-1"
                data-testid="pills-brackets-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} />
                Estimated Property Value Range: {bracketLabel}
              </Grid>
            )}
            {allocationNameText.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-allocationNameText-type-1"
                data-testid="pills-allocationNameText-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} />
                Allocation Name: {allocationNameText}
              </Grid>
            )}
            {allocationLocations.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-allocationLocations-type-1"
                data-testid="pills-allocationLocations-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} />
                Allocation Locations:
                {allocationLocations.map((type: any, index: number) =>
                  renderLocations(
                    "allocationLocations",
                    index,
                    type.countryName +
                      " " +
                      type.type +
                      " " +
                      (type.type != "Country" ? type.name : "")
                  )
                )}
                {allocationLocations.length > 20
                  ? renderListItem(
                      "allocationLocations",
                      -1,
                      "+" + (allocationLocations.length - 20)
                    )
                  : ""}
              </Grid>
            )}
            {feeNameText.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-feeNameText-type-1"
                data-testid="pills-feeNameText-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} />
                Fee Name: {feeNameText}
              </Grid>
            )}
            {feeLocations.length > 0 && (
              <Grid
                container
                size={12}
                id="pills-feeLocations-type-1"
                data-testid="pills-feeLocations-type-1"
              >
                <Grid size={12} sx={{ paddingTop: "10px" }} />
                Fee Locations:
                {feeLocations.map((type: any, index: number) =>
                  renderLocations(
                    "feeLocations",
                    index,
                    type.countryName +
                      " " +
                      type.type +
                      " " +
                      (type.type != "Country" ? type.name : "")
                  )
                )}
                {feeLocations.length > 20
                  ? renderListItem(
                      "feeLocations",
                      -1,
                      "+" + (feeLocations.length - 20)
                    )
                  : ""}
              </Grid>
            )}
          </Grid>
          <Divider />
          <Grid
            container
            sx={{
              paddingTop: 1,
              paddingBottom: 1,
              backgroundColor: theme.colors.alpha.trueWhite[100],
            }}
          >
            <Grid size={12}>
              <Typography
                id="tbl-info"
                data-testid="tbl-info"
                variant="body2"
                component="h6"
                sx={{ color: theme.palette.text.secondary }}
              >
                Displaying {panels.length} of {rowCount} Panel
                {rowCount > 1 ? "s" : ""}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              flexDirection: "column",
              minHeight: 400,
              width: "100%",
              display: `${rowCount === 0 ? "flex" : ""}`,
              justifyContent: `${rowCount === 0 ? "center" : ""}`,
              alignItems: `${rowCount === 0 ? "center" : ""}`,
              "& .highlight": {
                backgroundColor: theme.colors.draft.lightest,
              },
            }}
          >
            {loading ? (
              <Grid size={12}>
                <Box
                  id="panel-sets-progress"
                  data-testid="panel-sets-progress"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <CircularProgress />
                </Box>
              </Grid>
            ) : rowCount === 0 ? (
              <Grid container>
                <Grid
                  size={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="no-result"
                    data-testid="no-result"
                    variant="subtitle1"
                    className={classes.noResult}
                  >
                    No Result found
                  </Typography>
                </Grid>
                <Grid
                  size={12}
                  sx={{
                    paddingTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    id="edit-message"
                    data-testid="edit-message"
                    variant="subtitle2"
                  >
                    Try changing or removing some of your filters
                  </Typography>
                </Grid>
                <Grid
                  size={12}
                  sx={{
                    paddingTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    id="filter-button"
                    data-testid="filter-button"
                    variant="contained"
                    startIcon={<FilterListIcon />}
                    size="medium"
                    onClick={() => dispatch(openFilter(true))}
                  >
                    <span>Edit Filters</span>
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <DataGridPro
                style={{ cursor: "pointer" }}
                className="tbl-panels"
                getRowId={(row) => row.panelSetId}
                rows={panels}
                rowCount={rowCountState}
                columns={columns}
                disableMultipleColumnsFiltering={true}
                paginationModel={paginationModel}
                pagination
                pageSizeOptions={[15, 20, 50]}
                paginationMode="server"
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                onPaginationModelChange={handlePaginationModelChange}
                disableColumnFilter={true}
                checkboxSelection={isBulkUpdate}
                isRowSelectable={(params: GridRowParams) =>
                  params.row.allocationPanelId !== null
                }
                disableMultipleRowSelection={false}
                disableRowSelectionOnClick={true}
                rowSelectionModel={rowSelectionModel}
                getRowClassName={(params) => {
                  const isPanelSelected =
                    bulkUpdateFocusPanels &&
                    selectedPanels.some(
                      (p: any) => p.panelSetId === params.row.panelSetId
                    );
                  return isPanelSelected
                    ? "highlight"
                    : params.indexRelativeToCurrentPage % 2 === 0
                    ? "even"
                    : "";
                }}
                onRowSelectionModelChange={(
                  newRowSelectionModel: GridRowSelectionModel
                ) => {
                  setRowSelectionModel(newRowSelectionModel);
                  dispatch(setPanelToBulkUpdate(newRowSelectionModel));
                }}
                onCellClick={(
                  params: GridCellParams,
                  event: MuiEvent<React.MouseEvent>
                ) => {
                  if (params.field !== "__check__") {
                    history.push(`/panels/${params.row.panelSetId}`);
                    event.defaultMuiPrevented = true;
                  }
                }}
              />
            )}
          </Box>
        </Box>
        {openPanelSetForm && <PanelSetForm />}
      </Grid>
      <PanelExSnackbar />
      <PanelExActionSnackbar
        onClearSection={clearSelectionModel}
        width={869}
        readOnly={readOnly}
      />
      {isOpenBulkUpdate && <BulkUpdate />}
    </Grid>
  );
}
