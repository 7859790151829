import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/staticReducers/reducers";

import {
  Box,
  FormControl,
  Grid2 as Grid,
  TextField,
  Typography,
} from "@mui/material";
import PanelExDialog from "../shared/PanelExDialog";
import { makeStyles } from "tss-react/mui";
import {
  searchNewUser,
  showSearchNewUser,
} from "../../store/action/actionUser";

export default function SearchNewUser() {
  const dispatch = useDispatch();
  const useStyles = makeStyles()(() => ({
    existingUserLabel: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      paddingTop: "24px",
    },
    emailText: {
      "& .MuiInputBase-input": {
        padding: "8px 0px 8px 10px",
        height: "22px",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "4px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 4,
      },
      "& #textField-name-helper-text": {
        textTransform: "none",
      },
    },
  }));
  const { classes } = useStyles();

  const { showSearchNewUserDialog, isPanelUser, loading } = useSelector(
    (state: RootState) => {
      return {
        showSearchNewUserDialog: state.user.showSearchNewUser || false,
        isPanelUser: state.user.newUser.userDetail.isPanelUser || false,
        loading: state.user.newUser.userDetail.searchLoading || false,
      };
    }
  );
  const [email, setEmail] = React.useState("");

  const closeSearchNewUser = () => {
    dispatch(showSearchNewUser(false));
  };

  const handleSearchNewUser = () => {
    dispatch(searchNewUser(email));
  };

  const handleSearchNewEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const submitText = isPanelUser
    ? "Ok, Good to Know"
    : loading
    ? "Checking User"
    : "Add User to PanelEx";

  return (
    <PanelExDialog
      id="search-new-user-dialog"
      data-testid="search-new-user-dialog"
      open={showSearchNewUserDialog}
      title={`Add New User`}
      submitText={submitText}
      submitEnabled={email !== "" && !loading}
      showSubmitIcon={loading}
      onClose={closeSearchNewUser}
      onSubmit={isPanelUser ? closeSearchNewUser : handleSearchNewUser}
      showAction={true}
      showDivider={false}
      maxWidth="sm"
    >
      <Box
        noValidate
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "5",
          padding: "20px",
          paddingTop: "5px",
          minWidth: 150,
          minHeight: 100,
        }}
      >
        <FormControl sx={{ mt: 0 }}>
          <Grid container spacing={1}>
            <Grid size={6}></Grid>
          </Grid>
        </FormControl>

        <Grid container sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Grid size={12}>
            {isPanelUser ? (
              <Typography
                id="existing-user-message"
                data-testid="existing-user-message"
                className={classes.existingUserLabel}
              >
                {email} is already registered as a user in PanelEx
              </Typography>
            ) : (
              <TextField
                className={classes.emailText}
                id="search-new-user-email"
                data-testid="search-new-user-email"
                label="Email"
                size="small"
                value={email}
                onChange={handleSearchNewEmailChange}
                fullWidth={true}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </PanelExDialog>
  );
}
